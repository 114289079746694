import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SuccessSection from "./SuccessSection";

import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
} from "../../functions/editProfile/editProfile";
const SixsthSection = ({
  sectionHolder: {
    sheftSections,
    CurrentSection,
    PreviousSection,
    BackwordSection,
    errorSection,
    progressBar,
    from,
    setFunction,
  },
  userData: { is_consultant, user_name, gender, lang },
  userLangauges,
  mobile_no,
  county,
  userCredentials: { login_user_id, email, user_password },
  userCategories,
  briefCV,
}) => {
  const successRef = useRef(null);
  const url = process.env.REACT_APP_BACKEND_URL;

  const submitRegistration = async () => {
    // let langs = navigator.languages;

    // langs = navigator.languages.map((list) => {
    //   return list.split("-")[0];
    // });

    if (!lang) {
      alert("langauge is required browser");
    }

    const langs = [lang];

    progressBar.current.style.display = "block";

    let userCategory = userCategories.map((list) => list.id);
    try {
      const response = await fetch(`${url}/api/users/userRegister`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          login_user_id: login_user_id,
          mobile_no: mobile_no,
          user_password: user_password,
          user_name: user_name,
          country_id: county.id,
          gender: gender,
          userLangauges: langs,
          userCategory: userCategory,
          status_id: "5f6880d5465f604401ad1d5d",
          mail_verfy: "1",
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "something went wrong");
      } else {
        CurrentSection.current.style.display = "none";
        progressBar.current.style.display = "none";
        loginSubmit();

        if (from === "chatregister") {
          setFunction(false);
        }
      }
    } catch (err) {
      console.log(err);
      CurrentSection.current.style.display = "none";
      errorSection.current.style.display = "block";
    }
  };

  const loginSubmit = async () => {
    const url = process.env.REACT_APP_BACKEND_URL;
    successRef.current.style.display = "block";
    try {
      const response = await fetch(`${url}/api/users/userLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_user_id: login_user_id,
          password: user_password,
          isKeepLogin: false,
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "something went wrong");
      } else {
        if (typeof Storage !== "undefined") {
          localStorage.setItem(
            "userDataSessionway",
            JSON.stringify(responseData.body),
          );
          window.location.replace("/profile");
        } else {
          alert("please update your browser");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  return (
    <>
      <div
        className="registration-model-container"
        style={{ display: "none" }}
        ref={CurrentSection}
      >
        <p className="registration-otp_text">
          Please wait a while We Verify your Number
        </p>
        <div className=" border-bottom-white ">
          <input
            name="textinput"
            placeholder="Enter OTP"
            className="registration-form-control input-ht width-100"
            type="text"
          />
        </div>
        <div className="text-left margin-battom-15 ">
          Don,t Get the OTP
          <Link to="#" className="alert-imp margin-left-5">
            Resend OTP
          </Link>
        </div>
        <div>
          <button
            className="btn-previous push-left"
            onClick={() => {
              if (is_consultant === 1) {
                sheftSections(PreviousSection, CurrentSection);
              } else {
                sheftSections(BackwordSection, CurrentSection);
              }
            }}
          >
            Back
          </button>
          <button
            className="btn-proceed push-right"
            onClick={submitRegistration}
          >
            Submit
          </button>
        </div>
        <div className="clearfix"></div>
      </div>
      <div ref={successRef} style={{ display: "none" }}>
        <SuccessSection />
      </div>
    </>
  );
};

const MapStateToProps = ({
  RegFirstSection: { userLangauges, briefCV },
  RegSecondSection: { mobile_no, county },
  RegThirdSection: { userData },
  RegFourthSection: { userCredentials },
  RegFifthSection: { userCategories },
}) => {
  return {
    userLangauges,
    briefCV,
    mobile_no,
    county,
    userData,
    userCredentials,
    userCategories,
    userCategoriesLength: userCategories.length,
  };
};

export default connect(MapStateToProps)(SixsthSection);
