import React, { useEffect, useState } from "react";
import { BiMap } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";
import { LiaFaxSolid } from "react-icons/lia";
import { MdAlternateEmail } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { IoTimeOutline, IoLogoWhatsapp } from "react-icons/io5";

import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

const Locations = ({
  user_id,
  newLocationsAdded,
  setLocation,
  editLocations,
}) => {
  const [orgLocations, setOrgLocations] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState();
  useEffect(() => {
    getLocations(1);
  }, [newLocationsAdded]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    if (pageNo == 1) {
      setPageNo(pageNo + 1);
    }
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [pageNo, totalPage]);

  const handleScrolListner = () => {
    const tPage = totalPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (pageNo <= tPage) {
        getLocations(pageNo);
      }
    }
  };

  const getLocations = async (page_no) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getOrgLocations?user_id=${user_id}&page=${page_no}`;

      setPageNo(page_no + 1);
      const res = await getRequestedData(url);

      if (page_no > 1) {
        setOrgLocations((oldArray) => [
          ...res.data.body.locations,
          ...oldArray,
        ]);
      } else {
        setOrgLocations(res.data.body.locations);
      }
      setTotalPage(res.data.body.Total_Page);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="ont-Roboto-16">
      {orgLocations.length > 0 &&
        orgLocations.map((location, index) => {
          return (
            <>
              <div className="flex-end" style={{ width: "60%" }}></div>
              <div key={location._id}>
                {/* <div className="display-flex padding-5">
                  <div className="width-5">
                    <AiOutlinePhone size={20} style={{ color: "#696866" }}"" />
                  </div>
                  <div>{location.office_name}</div>
                </div> */}
                <div className="flex-space-btw">
                  <div className="display-flex padding-5 gap-5p">
                    <div>
                      <BiMap size={20} style={{ color: "#696866" }} />
                    </div>
                    <div>
                      <b>{location.country.country_english},</b>
                      <div>{location.address}</div>
                      {location.postal && <span>{location.postal}</span>}
                      {location.city && (
                        <span className="margin-left-5">{location.city}</span>
                      )}
                      {location.state && (
                        <span className="margin-left-10">{location.state}</span>
                      )}
                      {/* <span className="margin-left-10">
                        {location.country.country_english}
                      </span> */}
                    </div>
                  </div>
                  <AiOutlineEdit
                    size={18}
                    onClick={() => editLocations(location)}
                    className="mt-3 cursor-pointer"
                  />
                </div>
                <div className="display-flex padding-5 gap-5p">
                  <div>
                    <AiOutlinePhone size={20} style={{ color: "#696866" }} />
                  </div>
                  <div>{location.phone}</div>
                </div>

                {location.whatsapp && (
                  <div className="display-flex padding-5 gap-5p display-flex-align-center">
                    <div className="display-flex">
                      <IoLogoWhatsapp size={18} style={{ color: "#696866" }} />
                    </div>

                    <div>
                      <a
                        href={`https://wa.me/+${
                          location.country.dial_cd + location.whatsapp
                        }`}
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        {location.whatsapp}
                      </a>
                    </div>
                  </div>
                )}

                {location.fax && (
                  <div className="display-flex padding-5 gap-5p">
                    <div>
                      <LiaFaxSolid size={20} style={{ color: "#696866" }} />
                    </div>
                    <div>{location.fax}</div>
                  </div>
                )}

                <div className="display-flex padding-5 gap-5p">
                  <div>
                    <MdAlternateEmail size={20} style={{ color: "#696866" }} />
                  </div>
                  <div>{location.email}</div>
                </div>

                {location.office_hours?.start && (
                  <div className="display-flex padding-5 gap-5p">
                    <div>
                      <IoTimeOutline size={18} style={{ color: "#696866" }} />
                    </div>
                    <div>
                      {location.office_hours.start} to{" "}
                      {location.office_hours.end}
                    </div>
                  </div>
                )}
              </div>
              <hr style={{ background: "#807c7c" }} />
            </>
          );
        })}
    </div>
  );
};

export default Locations;
