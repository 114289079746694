import React, { useState, useRef, useEffect } from "react";

const url = process.env.REACT_APP_BACKEND_URL;
const VideoUpload = (props) => {
  useEffect(() => {
    if (props.selectedFile) {
      if (props.selectedFile.target.files[0].type.split("/")[0] === "video") {
        pickedHandler(props.selectedFile);
      }
    }
  }, [props.selectedFile]);

  const pickedHandler = (event) => {
    let pickedFile;
    let videourl = props.videourlref;
    let fileIsValid = false;
    if (event.target.files && event.target.files.length === 1) {
      let filExt = event.target.files[0].type.split("/")[0];

      if (filExt === "video") {
        pickedFile = event.target.files[0];
        let blobURL = URL.createObjectURL(pickedFile);
        if (props.videourlref) videourl.current.src = blobURL;
        fileIsValid = true;

        if (props.toggle) {
          videourl.current.style.display = "inline-block";
          props.uploadImgref.current.style.display = "none";
        }

        props.onvideofileInput(pickedFile);
      }
    }
  };

  return (
    <div>
      <input
        ref={props.videoFilePickerRef}
        style={{ display: "none" }}
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        onChange={pickedHandler}
      />
    </div>
  );
};

export default VideoUpload;
