import React, { useState } from "react";

import { connect } from "react-redux";
import SectionTypeModel from "./SectionTypeModel";
import StoryTypeModelCourse from "./StoryTypeModelCourse";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import { useSelector, useDispatch } from "react-redux";
const url = process.env.REACT_APP_BACKEND_URL;

const ViewUserSubscriptionGroup = ({
  group_info,
  setProfile,
  setIsCourseListClose,
}) => {
  const { BasicUserReducers } = useSelector((state) => state);
  const { user } = BasicUserReducers;

  const [isHecanUnPublish, setIsHecanUnPublish] = useState({});
  if (group_info.group_name) {
    const isHecanUnpublishOrStopCourse = async () => {
      const data = {
        subscription_id: group_info._id,
        user_id: user.loginUser._id,
      };

      try {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/isHecanUnpublishOrStopCourse`;
        let res = await getDataPostWithHeader(url, data, group_info.token);
        console.log(res.data);
        setIsHecanUnPublish(res.data.body);
      } catch (err) {
        console.log(err.message);
        alert(err.message);
      }
    };
    return (
      <>
        {group_info.section_type == 1 && (
          <SectionTypeModel
            group_info={group_info}
            setIsCourseListClose={setIsCourseListClose}
            setProfile={setProfile}
            isHecanUnPublish={isHecanUnPublish}
            isHecanUnpublishOrStopCourse={isHecanUnpublishOrStopCourse}
          />
        )}
        {group_info.section_type == 0 && (
          <StoryTypeModelCourse
            group_info={group_info}
            setIsCourseListClose={setIsCourseListClose}
            setProfile={setProfile}
            isHecanUnPublish={isHecanUnPublish}
            isHecanUnpublishOrStopCourse={isHecanUnpublishOrStopCourse}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

const MapStateToProps = ({ UserSubscriptionGroup: { group_info } }) => {
  return { group_info };
};

export default connect(MapStateToProps)(ViewUserSubscriptionGroup);
