import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import FooterMenu from "../navbar/FooterMenu";
import { toast } from "react-toastify";

import profileDefualt from "../images/default.jpeg";
import CourseCard2 from "../Components/cards/CourseCard2";

import {
  getRequestedData,
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";

const CategoriesCourse = (props) => {
  const params = useParams();
  const [categoryWithCourses, setCategoryWithCourses] = useState([]);
  const [coursePage, setCoursePage] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState();

  useEffect(() => {
    if (props.user.isLogin) {
      getCourses(1);
    }
  }, [props.user.isLogin]);

  const handleScrolListner = () => {
    const TotalPage = totalCoursePage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (coursePage <= TotalPage) {
        if (props.user.isLogin) {
          getCourses(coursePage);
        }
      }
    }
  };

  const getCourses = async (pageNo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getCoursesBasedOnCategory?user_id=${props.user.userData.loginUser._id}&category_id=${params.id}&page=${pageNo}`;
    try {
      let res = await getRequestedDataWithHeader(
        url,
        props.user.userData.token,
      );

      setCoursePage(pageNo + 1);

      if (pageNo > 1) {
        setCategoryWithCourses((courses) => [
          ...courses,
          ...res.data.body.courses,
        ]);
      } else {
        setCategoryWithCourses(res.data.body.catWithCourses);
      }

      setTotalCoursePage(res.data.body.Total_Page);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "60px" }}>
          <div className="row">
            {categoryWithCourses.length > 0 &&
              categoryWithCourses.map((course, index) => {
                return (
                  <div
                    className="col-sm-6 col-md-3 col-xl-3 col-xxl-2 "
                    key={course._id}
                  >
                    <CourseCard2
                      course={course.course_id}
                      userid={props.user.userData.loginUser._id}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="fixed-bottom d-block d-sm-none">
        <FooterMenu user={props.user} />
      </div>
    </div>
  );
};

export default CategoriesCourse;
