const defaultState = {
  profiles: [],
};

const profiles = (state = defaultState, action) => {
  switch (action.type) {
    case "PROFILE":
      return { ...state, profiles: action.payload };

    default:
      return state;
  }
};

export default profiles;
