import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import HlsPlayer from "../Components/players/HlsPlayer";
import VPlayer from "../Components/players/VPlayer";
import NormalVDPlayer from "../Components/players/NormalVDPlayer";
import "../css/videomodel.css";

const url = process.env.REACT_APP_BACKEND_URL;

const VideoModel = ({
  src,
  video_encode_process_status,
  video_encode_processed,
  onClose,
  hlsUrl,
  videoQaulityVersion,
  type,
  noClose,
}) => {
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [qualities, setQualities] = useState([]);

  useEffect(() => {
    if (hlsUrl) {
      setHLSVIDEOPLAYER(true);
    } else {
      setHLSVIDEOPLAYER(false);
    }
  }, [hlsUrl]);

  useEffect(() => {
    if (videoQaulityVersion.url_360) {
      let qualties = [];
      for (let i in videoQaulityVersion) {
        qualties.push({
          quality: i.split("_")[1] + "p",
          url: videoQaulityVersion[i],
        });
      }

      setQualities(qualties.reverse());
    }
  }, [videoQaulityVersion]);

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <div className="model-content" style={{ width: "100%" }}>
      {noClose && (
        <AiOutlineClose className="video-close" size="30px" onClick={onClose} />
      )}
      <div style={{ clear: "both" }}></div>

      {type === "VideoOrImage" && (
        <>
          {hlsUrl !== "" &&
            HLSVIDEOPLAYER &&
            video_encode_process_status === "completed" &&
            video_encode_processed && (
              <HlsPlayer url={hlsUrl} setHLSVIDEOPLAYER={setHLSVIDEOPLAYER} />
            )}
          {videoQaulityVersion.url_720 !== "" &&
            !HLSVIDEOPLAYER &&
            video_encode_processed && <VPlayer qualities={qualities} />}

          {!video_encode_processed && (
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <NormalVDPlayer story_url={src} />
            </div>
          )}

          {hlsUrl === "" &&
            videoQaulityVersion.url_720 === "" &&
            video_encode_processed && (
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <NormalVDPlayer story_url={src} />
              </div>
            )}
        </>
      )}
      {type === "video_url" && (
        <>
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <iframe
              src={`https://www.youtube.com/embed/${getId(src)}?autoplay=1`}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
              }}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            ></iframe>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoModel;
