import React, { useEffect, useState } from "react";
import { getRequestedData } from "../../functions/editProfile/editProfile";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const Finance = ({ user }) => {
  const [isLoading, setLoader] = useState(false);
  const [yearlyReports, setYearlyReports] = useState([]);
  const [monthlyreports, setMonthlyreports] = useState([]);
  const [year, setYear] = useState("");
  console.log(user);

  useEffect(() => {
    const getYearBasedFincialStatus = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/manage/getPaymentDetailsOfOwnerByYear?owner_id=${user.loginUser._id}`;
        const res = await getRequestedData(url);
        console.log(res.data.body);
        setYearlyReports(res.data.body);
      } catch (err) {
        alert(err.message);
      }
    };

    getYearBasedFincialStatus();
  }, []);

  const retriveMonth = async (year) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/manage/getPaymentDetailsOfOwnerByMonth?owner_id=${user.loginUser._id}&year=${year}`;
      const res = await getRequestedData(url);
      console.log("montyhly", res.data.body);
      setMonthlyreports(res.data.body);
    } catch (err) {
      alert(err.message);
    }
  };

  const monthnumberToString = (year) => {
    const givenDate = new Date(year);
    const monthIndex = givenDate.getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthString = monthNames[monthIndex];
    return monthString;
  };
  return (
    <div>
      {!year && (
        <table
          className="table table-striped mt-2"
          style={{ cursor: "pointer" }}
        >
          <tbody>
            <tr>
              <th>Year</th>
              <th>Already collected</th>
            </tr>
            {yearlyReports.length >= 0 &&
              yearlyReports.map((reports, index) => {
                return (
                  <tr
                    key={reports._id}
                    onClick={() => {
                      retriveMonth(reports._id);
                      setYear(reports._id);
                    }}
                  >
                    <td>{reports._id}</td>
                    <td>{reports.totalSum} in KWD</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {console.log("year", year)}
      {year && (
        <div>
          <div
            className="display-flex"
            style={{ gap: "3rem", fontWeight: "800" }}
          >
            <IoArrowBackCircleSharp
              size={20}
              className="cursor-pointer"
              onClick={() => setYear("")}
            />
            <div>{year}</div>
          </div>
          <div>
            <table className="table mt-2" style={{ cursor: "pointer" }}>
              <thead class="thead-dark">
                <tr>
                  <th>Month</th>
                  <th scope="col">Course</th>
                  <th>Private Course</th>
                  <th>Consultant</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {monthlyreports.length >= 0 &&
                  monthlyreports.map((reports, index) => {
                    return (
                      <tr key={reports._id}>
                        <td>{monthnumberToString(reports.createdAt)}</td>
                        <td>
                          <span> xdca</span>{" "}
                          <span> {reports.course_sum} in KWD </span>
                        </td>

                        <td>{reports.private_course_sum} in KWD</td>
                        <td>{reports.consult_payment_sum} in KWD</td>
                        <td>{reports.total_sum} in KWD</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Finance;
