import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeNavbarVerticle from "../navbar/HomeNavbarVerticle";
import SessionCardMobile from "../Components/cards/SessionCardMobile";
import FooterMenu from "../navbar/FooterMenu";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";
import StoryCard from "../Components/cards/StoryCard";
import profileDefualt from "../images/default.jpeg";
import AllSubscription from "../Components/UserSubscriptions/AllSubscription";

const Subscriptions = (props) => {
  const [AllUsers, setAllusers] = useState(false);
  const [storyPage, setStoryPage] = useState(1);
  const [storiesType, setStoriesType] = useState("ALL");
  const [totalStoryPage, setTotalStoryPage] = useState();
  const { Followers, SubscribedALLUserStories, SubscribedUserStories } =
    useSelector((state) => ({
      ...state,
    }));

  const dispatch = useDispatch({});

  useEffect(() => {
    getSubscribedUserStories(1);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;

    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        if (storyPage == 1) {
          setStoryPage(storyPage + 1);

          if (storiesType === "ALL") {
            getSubscribedUserStories(storyPage + 1);
          } else {
            getUserStory(props.user.userData.loginUser._id, storyPage + 1);
          }
          return;
        }
        if (storiesType === "ALL") {
          getSubscribedUserStories(storyPage);
        } else {
          getUserStory(props.user.userData.loginUser._id, storyPage);
        }
      }
    }
  };

  const getSubscribedUserStories = async (storyPage) => {
    setStoryPage(storyPage + 1);
    setStoriesType("ALL");
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscribedALLUserStories?user_id=${props.user.userData.loginUser._id}&page=${storyPage}`;

    const res = await getRequestedDataWithHeader(
      url,
      props.user.userData.token,
    );

    setTotalStoryPage(res.data.body.Total_Page);

    if (storyPage > 1) {
      dispatch({
        type: "ALL_USER_SUB_STORIES_APPEND",
        payload: res.data.body.getStoryModel,
      });
    } else {
      dispatch({
        type: "ALL_USER_SUB_STORIES",
        payload: res.data.body,
      });
    }

    dispatch({
      type: "A_USER_SUB_STORIES",
      payload: {
        getStoryModel: [],
      },
    });
  };

  const getUserStory = async (user_id, storyPage) => {
    setStoryPage(storyPage + 1);
    setStoriesType("USER");
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscribedUserStories?user_id=${user_id}&page=${storyPage}`;

    const res = await getRequestedDataWithHeader(
      url,
      props.user.userData.token,
    );

    setTotalStoryPage(res.data.body.Total_Page);

    if (storyPage > 1) {
      dispatch({
        type: "A_USER_SUB_STORIES_APPEND",
        payload: res.data.body.getStoryModel,
      });
    } else {
      dispatch({
        type: "A_USER_SUB_STORIES",
        payload: res.data.body,
      });
    }

    dispatch({
      type: "ALL_USER_SUB_STORIES",
      payload: {
        getStoryModel: [],
      },
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`d-md-block d-none d-sm-block ${
            props.homeNavbarcollapse ? "col-md-1" : "col-md-2"
          }`}
          style={{
            position: "fixed",
            top: "60px",
            margin: "0px",
            padding: "0px",
            zIndex: "500",
          }}
        >
          <HomeNavbarVerticle
            user={props.user}
            homeNavbarcollapse={props.homeNavbarcollapse}
            opionSelectedKey="2"
          />
        </div>
        <div
          className={`${props.homeNavbarcollapse ? "col-md-1" : "col-md-2"}`}
        ></div>

        {!AllUsers && (
          <>
            <div
              className="col-md-10"
              style={{ marginRight: "0", paddingRight: "0" }}
            >
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "90%",
                      overflow: "hidden",
                    }}
                  >
                    {Followers.user_followers.length > 0 &&
                      Followers.user_followers.map((list, index) => {
                        return (
                          <div
                            className="mt-3 mr-2 mb-2 cursor-pointer"
                            onClick={() => {
                              getUserStory(list.follower_user_id._id, 1);
                            }}
                            key={index}
                          >
                            <img
                              style={{
                                width: "3rem",
                                height: "3rem",
                                borderRadius: "50%",
                                float: "left",
                              }}
                              src={list.follower_user_id.imageUrl}
                              alt="im-story-profile"
                              onError={(e) => {
                                e.target.onError = null;
                                e.target.src = profileDefualt;
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                  {Followers.user_followers.length > 5 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        background: "white",
                        color: "#3440eb",
                        paddingLeft: "10px",
                        fontWeight: "600",
                        width: "10%",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setAllusers(true);
                      }}
                    >
                      ALL
                    </div>
                  )}
                </div>
              </>
            </div>
            <div
              className={`${
                props.homeNavbarcollapse ? "col-md-1" : "col-md-2"
              }`}
            ></div>

            <div className="col-md-10">
              <div className="row">
                {SubscribedALLUserStories.stories.getStoryModel.length > 0 &&
                  SubscribedUserStories.stories.getStoryModel.length <= 0 &&
                  SubscribedALLUserStories.stories.getStoryModel.map(
                    (story, i) => {
                      return (
                        <div
                          className={`${
                            story.story_type !== "mobile-card" && "mobile-card"
                          } col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5 padding-right-mobile-card padding-right-card`}
                          key={story._id}
                        >
                          <StoryCard
                            story={story}
                            userid={
                              props.user.userData
                                ? props.user.userData.loginUser._id
                                : ""
                            }
                            isLogin={props.user.isLogin}
                          />
                        </div>
                      );
                    },
                  )}
                {SubscribedUserStories.stories.getStoryModel.length > 0 &&
                  SubscribedALLUserStories.stories.getStoryModel.length <= 0 &&
                  SubscribedUserStories.stories.getStoryModel.map(
                    (story, i) => {
                      return (
                        <div
                          className={`${
                            story.story_type !== "mobile-card" && "mobile-card"
                          } col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5 padding-right-mobile-card padding-right-card`}
                          key={story._id}
                        >
                          <StoryCard
                            story={story}
                            userid={
                              props.user.userData
                                ? props.user.userData.loginUser._id
                                : ""
                            }
                            isLogin={props.user.isLogin}
                          />
                        </div>
                      );
                    },
                  )}
              </div>
            </div>
          </>
        )}

        {AllUsers && (
          <div className="col-md-10">
            <AllSubscription
              getUserStory={getUserStory}
              setAllusers={setAllusers}
            />
          </div>
        )}
      </div>
      <div className="fixed-bottom d-block d-sm-none">
        <FooterMenu user={props.user} />
      </div>
    </div>
  );
};

export default Subscriptions;
