let initialState = {
  user_followers: [],
};

const FollowersReducers = (state = initialState, action) => {
  switch (action.type) {
    case "user_followers":
      return { ...state, user_followers: action.payload };
    default:
      return state;
  }
};

export default FollowersReducers;
