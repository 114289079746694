import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

import { BsArrowLeft } from "react-icons/bs";
import profileDefualt from "../../images/default.jpeg";
import addcover from "../../images/uplaodVideo.png";

import HlsPlayer from "../../Components/players/HlsPlayer";

const CourseLessions = ({ story, group_info }) => {
  console.log("stroy preview", story);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const history = useHistory();
  return (
    <div id="profile_area" style={{ paddingBottom: "2.5rem" }}>
      <div className="mt-3">
        <div
          className="profile-image-div"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <BsArrowLeft
              size={20}
              className="cursor-pointer mr-2"
              onClick={() => {
                history.push("/courses");
              }}
            />
          </div>
          <div style={{ height: "80px" }}>
            <img
              className="user-image"
              src={group_info.group_image_url}
              onError={(e) => {
                e.target.onError = null;
                e.target.src = profileDefualt;
              }}
              alt="profile"
            />
          </div>
        </div>
        <div className="profile-basic-details-div">
          <div>
            <span
              style={{
                fontSize: "20px",
                marginTop: "10px",
                fontWeight: "600",
              }}
            >
              {group_info.group_name}
            </span>
            <div>
              {group_info.category_id && group_info.category_id.category_name}
            </div>
          </div>
        </div>
        <div
          className="push-right"
          style={{ margin: "20px", marginTop: "30px" }}
        >
          <div>
            <div style={{ textAlign: "center" }}>5</div>
            <div>Subscriber</div>
          </div>
        </div>

        <div className="clearfix"></div>
      </div>
      <div>
        <div
          id="thumbnail_div"
          className="text-center"
          style={{ position: "relative" }}
        >
          {story.story_type === "video" && story.story_url ? (
            <>
              {story.converted_url &&
                HLSVIDEOPLAYER &&
                story.video_encode_processed &&
                story.video_encode_process_status === "completed" && (
                  <>
                    <HlsPlayer
                      url={story.converted_url}
                      setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                    />
                  </>
                )}

              {!story.video_encode_processed && (
                <div className="video-warapper-16-9-center">
                  <video
                    id="play_video"
                    src={`${story.story_url}`}
                    poster={story.thumbnail_url}
                    className="video-16-9-center"
                    controls
                  ></video>
                </div>
              )}

              {!story.converted_url && story.video_encode_processed && (
                <div className="video-warapper-16-9-center">
                  <video
                    controls
                    className="video-16-9-center"
                    poster={story.thumbnail_url}
                    src={`${story.story_url}`}
                  ></video>
                </div>
              )}
            </>
          ) : (
            <>
              {story.thumbnail_url && (
                <img
                  id="thumnail_bio"
                  src={story.thumbnail_url}
                  className="width-100 cursor-pointer"
                  alt="thumbnail"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = addcover;
                  }}
                />
              )}
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "24px",
          }}
        >
          <div
            id="text_brief"
            className="language-bio"
            style={{ height: "50px" }}
          >
            {story.story_text && parse(story.story_text)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLessions;
