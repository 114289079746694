import React from "react";
import ReactDOM from "react-dom";
import "./Modal.css";

const ModalOverlay = (props) => {
  const content = <>{props.children}</>;
  return ReactDOM.createPortal(
    content,
    document.getElementById("crop-modal-hook"),
  );
};

const ModelNoDesign = (props) => {
  return (
    <React.Fragment>{props.show && <ModalOverlay {...props} />}</React.Fragment>
  );
};

export default ModelNoDesign;
