import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineBell, AiFillFilePdf, AiOutlineClose } from "react-icons/ai";
import { HiBellAlert } from "react-icons/hi2";
import { BsFillChatDotsFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

import { fetchStory, buySubscriptionGroup } from "../functions/story/story";
import { useQuery } from "../utils/sharedfunction";

import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedData,
} from "../functions/editProfile/editProfile";
import LoadingCard from "../Components/cards/LoadingCard";

import defualtImg from "../images/Read&ShareThumbnail.png";
import profileDefualt from "../images/default.jpeg";

import "../css/profile/stafStory.css";

const ViewStaffStory = ({ user }) => {
  const params = useParams();
  const dateoptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let query = useQuery();
  const history = useHistory();
  const [story, setStory] = useState({});
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [loading, setLoading] = useState();
  const [showSignin, setShowSignin] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [showDummyCard, setShowDummyCard] = useState(false);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [isFollow, setIsFollow] = useState(false);
  const [isNotifyMeAll, setIsNotifyMeAll] = useState(false);
  const dispatch = useDispatch();
  const { CatStories } = useSelector((state) => ({ ...state }));
  const playerRef = useRef(null);
  const LoginToSubRef = useRef(null);

  useEffect(() => {
    document.body.style.backgroundColor = "#f6f6f6";
    if (params.id) {
      storyDetails(params.id);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }, [params.id, user.userData]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;

    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        if (story.category_id) {
          loadStoriesBasedOnCategoryHandler(story.category_id, storyPage);
        }
      }
    }
  };

  const getAttachments = async (story_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/attachments?story_id=${story_id}`;
    let res = await getRequestedData(url);

    setAttachments(res.data.body);
  };

  const storyDetails = async (story_id) => {
    try {
      let url = "";
      if (user && user.userData) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getExpert?expert_id=${story_id}&user_id=${user.userData.loginUser._id}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getExpert?expert_id=${story_id}`;
      }

      const res = await getRequestedData(url);

      if (res.data.body.story.story_type === "video_url") {
        const videoId = getId(res.data.body.story.story_url);
        setYoutubeVideoID(videoId);
      }
      getAttachments(res.data.body.story._id);
      setStory(res.data.body.story);

      if (res.data.body.story.story_converted_url !== "") {
        setHLSVIDEOPLAYER(true);
      }
      setIsFollow(res.data.body.isFollow);
      setIsNotifyMeAll(res.data.body.isNotifyMeAll);
      loadStoriesBasedOnCategoryHandler(res.data.body.story.category_id, 1);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const loadStoriesBasedOnCategoryHandler = async (categoryId, storyPageNo) => {
    setLoading(true);
    let url = ``;
    if (user.userData) {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getCategoryBasedExperts?category_id=${categoryId}&user_id=${
        user.userData.loginUser._id
      }&storyIdToSkip=${params.id}&page=${storyPageNo}&storyType=${query.get(
        "type"
      )}`;
    } else {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getCategoryBasedExperts?category_id=${categoryId}&storyIdToSkip=${
        params.id
      }&page=${storyPageNo}&storyType=${query.get("type")}`;
    }

    try {
      let res = await getRequestedData(url);
      setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        dispatch({
          type: "cat_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "cat_stories", payload: res.data.body });
      }
      setTotalStoryPage(res.data.body.Total_Page);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const subscribeGroup = () => {
    setShowSignin(true);
  };

  const closeDummyCard = () => setShowDummyCard(false);

  const usubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === "Subscribed") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribe";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribed";
          console.log(res.data.body);
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      }
    }
  };

  const buysubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === "Subscribe") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);

          e.target.innerHTML = "Subscribed";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribe";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      }
    } else {
      //   console.log('notlogged in')
      //   responseMessageDispaly(
      //     LoginToSubRef,
      //     'Login to Subscribe',
      //     'red',
      //     '1',
      //     '10'
      //   )
      setShowSignin(true);
      setTimeout(function () {
        setShowSignin(false);
      }, 6000);
    }
  };

  const notifyHandler = async (notifyMeAll) => {
    if (user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/notifyMeWhenUserEachUpdates`;
      const data = {
        user_id: user.userData.loginUser._id,
        subscriber_user_id: story.user_id._id,
        notifyMeAll: notifyMeAll,
      };
      try {
        let res = await getDataPostWithHeader(url, data, user.userData.token);
        setIsNotifyMeAll(res.data.body.isNotifyMeAll);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  };

  return (
    <>
      <div className="container-fluid marginTopStores">
        <IoArrowBackSharp
          size={25}
          onClick={() => history.goBack()}
          className="cursor-pointer d-block d-sm-none"
        />
        <div className="row mt-3 mr-5 ml-5 no-margin-mobile-left no-margin-mobile-right">
          <div className="col-md-7">
            {story._id && story.story_type == "image" && (
              <>
                <div className="staf-warapper-ratio-adujestment staf-margin">
                  <img
                    className="staf-whereplayer-shows"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      position: "absolute",
                    }}
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}

            {story._id && story.story_type == "stafs" && (
              <>
                <div className="staf-warapper-ratio-adujestment staf-margin">
                  <img
                    className="staf-whereplayer-shows"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      position: "absolute !important",
                    }}
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}

            {story._id && (
              <div
                className="mt-2 mb-4"
                style={{
                  fontSize: "14px",
                }}
              >
                <div>
                  <div style={{ display: "flex" }}>
                    <Link
                      to={
                        user.userData &&
                        user.userData.loginUser._id === story.user_id._id
                          ? "/profile"
                          : `/consultant-profile?user_id=${story.user_id._id}&profile_id=${story.profile_id}`
                      }
                      style={{ color: "black" }}
                    >
                      <img
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                        src={story.user_id.imageUrl}
                        alt="im-story"
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = profileDefualt;
                        }}
                      />{" "}
                    </Link>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          fontWeight: "500",
                          color: "black",
                          marginLeft: "10px",
                        }}
                      >
                        {story.story_title}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "300",
                            color: "#737373",
                            marginLeft: "10px",
                            marginTop: "8px",
                          }}
                        >
                          {new Date(story.createdAt).toLocaleDateString(
                            "en-US",
                            dateoptions
                          )}
                        </div>
                        <div>
                          {user.isLogin &&
                            user.userData.loginUser.is_consultant == "1" && (
                              <BsFillChatDotsFill
                                size={20}
                                className="cursor-pointer mr-3"
                              />
                            )}

                          {!user.isLogin && (
                            <div ref={LoginToSubRef} className="mb-2"></div>
                          )}
                          {isFollow && (
                            <button
                              className="btn btn-light rounded pl-2 pr-2 pt-1 pb-1"
                              style={{ background: "#dbdbdb" }}
                              onClick={(e) =>
                                usubscribeGroup(story.user_id._id, e)
                              }
                            >
                              Subscribed
                            </button>
                          )}
                          {!isFollow && (
                            <button
                              className="btn btn-dark rounded pl-2 pr-2 pt-1 pb-1"
                              onClick={(e) =>
                                buysubscribeGroup(story.user_id._id, e)
                              }
                            >
                              Subscribe
                            </button>
                          )}
                          {isFollow && (
                            <>
                              {!isNotifyMeAll && (
                                <AiOutlineBell
                                  size={20}
                                  className="cursor-pointer ml-3 mr-1"
                                  onClick={() => notifyHandler(true)}
                                />
                              )}
                              {isNotifyMeAll && (
                                <HiBellAlert
                                  size={20}
                                  className="cursor-pointer ml-3 mr-1"
                                  onClick={() => notifyHandler(false)}
                                />
                              )}
                            </>
                          )}

                          {showSignin && !user.isLogin && (
                            <div
                              style={{
                                backgroundColor: "white",
                                position: "absolute",
                                zIndex: "1",
                                padding: "20px",
                                width: "230px",
                              }}
                            >
                              Sign in to subscribe this group
                              <hr />
                              <div
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  dispatch({ type: "OpenNavModel" });
                                }}
                              >
                                SIGN IN
                              </div>
                            </div>
                          )}
                          {showSignin && user.isLogin && (
                            <div
                              style={{
                                backgroundColor: "white",
                                position: "absolute",
                                zIndex: "1",
                                padding: "20px",
                                width: "230px",
                              }}
                            >
                              Purchase the group to subscribe
                              <hr />
                              <div
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  setShowDummyCard(true);
                                }}
                              >
                                Purchase
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div>
                  <div
                    className={`${
                      story.story_language_id.code === "ar"
                        ? "text-right"
                        : "text-left"
                    }`}
                  >
                    {parse(story.story_text)}
                  </div>
                  <hr />

                  {attachments.length > 0 && (
                    <div>
                      <div className="flex-space-btw">
                        <div className="text-bold">Certificates</div>
                      </div>

                      <table
                        className="table table-striped mt-2"
                        style={{ cursor: "pointer" }}
                      >
                        <tbody>
                          {attachments.length >= 0 &&
                            attachments.map((attachment, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Link
                                      to={attachment.url}
                                      download={attachment.url}
                                      target="_blank"
                                    >
                                      {attachment.display_doc_name}
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-md-5">
            {CatStories.stories.getStoryModel.length > 0 &&
              CatStories.stories.getStoryModel.map((story, i) => {
                if (story._id !== params.id)
                  if (story.story_type == "image") {
                    return (
                      <Link to={`/expert/${story._id}?type=image`}>
                        <div
                          className="pb-3 staf-margin display-flex-block"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <img
                            className="staf-img-category"
                            src={story.thumbnail_url}
                            alt="im-story"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defualtImg;
                            }}
                          />
                          <div
                            style={{
                              float: "left",
                              marginLeft: "6px",
                            }}
                          >
                            <div className="text-title-expert">
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  } else if (story.story_type == "stafs") {
                    return (
                      <Link to={`/expert/${story._id}?type=stafs`}>
                        <div
                          className="pb-3 display-flex-block"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <img
                            className="staf-img-category"
                            src={story.thumbnail_url}
                            alt="im-story"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defualtImg;
                            }}
                          />
                          <div
                            style={{
                              float: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <div className="text-title-expert">
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  }
              })}
            {loading && <LoadingCard count={3} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewStaffStory;
