const initialState = {
  stories: {
    getStoryModel: [],
  },
};

const SubscribedAUserStoriesReducers = (state = initialState, action) => {
  switch (action.type) {
    case "A_USER_SUB_STORIES":
      return { ...state, stories: action.payload };
    case "A_USER_SUB_STORIES_APPEND":
      let newgetStoryModel = [
        ...state.stories.getStoryModel,
        ...action.payload,
      ];
      let newState = { ...state };
      newState.stories.getStoryModel = newgetStoryModel;
      return newState;
    default:
      return state;
  }
};

export default SubscribedAUserStoriesReducers;
