import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const AddMoreResponse = ({
  addReponse,
  setAddResponse,
  inputBoxvisible,
  setBoxVisible,
  responsehandler,
  palceholder,
}) => {
  return (
    <div>
      {/* {!inputBoxvisible && (
        <div
          style={{ marginTop: '15px' }}
          onClick={() => {
            setBoxVisible(true)
          }}
        >
          <FaPlus style={{ fontSize: '20px', color: '#5624d0' }} />
          <span
            style={{
              marginLeft: '10px',
              color: '#5624d0',
              fontWeight: '600',
              marginTop: '15px',
              position: 'relative',
              top: '2px',
            }}
          >
            Add
          </span>
        </div>
      )} */}

      <div style={{ marginTop: "15px" }}>
        <input
          type="text"
          className="input_learn_text_field"
          placeholder={palceholder}
          value={addReponse}
          onChange={(e) => {
            setAddResponse(e.target.value);
          }}
          onBlur={responsehandler}
        />
      </div>
    </div>
  );
};

export default AddMoreResponse;
