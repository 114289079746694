import React, { useEffect, useState, useCallback, useRef } from "react";
import FooterMenu from "../navbar/FooterMenu";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CourseHomePurchaseCard from "../Components/cards/CourseHomePurchaseCard";
import CourseCategory from "../Components/home/CourseCategory";
import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
  getRequestedData,
} from "../functions/editProfile/editProfile";

import "../css/courses.css";

function useHookWithRefCallback() {
  const nodeRef = useRef(null);
  const [nodeLoaded, setNodeLoaded] = useState(false);
  const setRef = useCallback((node) => {
    if (nodeRef.current) {
      setNodeLoaded(false);
    }

    if (node) {
      setNodeLoaded(true);
    }
    nodeRef.current = node;
  }, []);

  return [setRef, nodeLoaded];
}

const HomeCourses = (props) => {
  const [categoryWithCourses, setCategoryWithCourses] = useState([]);
  const [coursePage, setCoursePage] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState(2);
  const [loading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [coursesLoding, setCourseLoading] = useState(false);
  const { cart } = useSelector((state) => state);
  const history = useHistory();
  useEffect(() => {
    getCourses(1);
  }, []);

  useEffect(() => {
    if (props.user.isLogin) {
      getMycourses(1);
    }
  }, [props.user.isLogin]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [coursePage, totalCoursePage]);

  const handleScrolListner = () => {
    const TotalPage = totalCoursePage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (coursePage <= TotalPage) {
        getCourses(coursePage);
      }
    }
  };

  const getCourses = async (pageNo) => {
    setIsLoading(true);
    let url = "";
    let userData = {};
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("userDataSessionWay")) {
        userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
      }
    } else {
      alert("Please update your browser");
    }

    try {
      if (userData.loginUser) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getAllCourses?courseType=${props.courseType}&user_id=${userData.loginUser._id}&page=${pageNo}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getAllCourses?courseType=${props.courseType}&page=${pageNo}`;
      }

      let res = await getRequestedData(url);

      setCoursePage(pageNo + 1);

      if (pageNo > 1) {
        setCategoryWithCourses((courses) => [...courses, ...res.data.body]);

        if (res.data.body.length >= 3) {
          setTotalCoursePage(totalCoursePage + 1);
        }
      } else {
        setCategoryWithCourses(res.data.body);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const getMycourses = async (pageNo) => {
    try {
      setCourseLoading(true);
      if (typeof Storage !== "undefined") {
        if (localStorage.getItem("userDataSessionWay")) {
          const userData = JSON.parse(
            localStorage.getItem("userDataSessionWay"),
          );
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscribedSubscriptionGroups?user_id=${userData.loginUser._id}&courseType=${props.courseType}&page=${pageNo}`;

          const res = await getRequestedDataWithHeader(url, userData.token);
          setCourses(res.data.body.subcriptions);
        } else {
          history.push("/");
        }
      } else {
        alert("Please update your browser");
      }
      setCourseLoading(false);
    } catch (errr) {
      console.log(errr);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "60px" }}>
          {courses.length > 0 && (
            <div className="m-3">
              <div className="title-learn">Let's Start Learning</div>
              <div className="home-course-card-wrapper">
                {courses.map((list, index) => {
                  if (index <= 2)
                    return (
                      <CourseHomePurchaseCard
                        course={list.subscription_id}
                        courseType={props.courseType}
                        key={list._id}
                      />
                    );
                })}
                <div className="my-training-link">
                  <Link
                    to={`${
                      props.courseType === 1 ? "mycourses" : "private-trainings"
                    }`}
                  >
                    {props.courseType === 1
                      ? "My Training"
                      : "My Privite Groups"}
                  </Link>
                </div>
              </div>
              <div className="my-training-link-mobile">
                <Link
                  to={`${
                    props.courseType === 1 ? "mycourses" : "private-trainings"
                  }`}
                >
                  {props.courseType === 1 ? "My Training" : "My Privite Groups"}
                </Link>
              </div>
            </div>
          )}

          {categoryWithCourses.length > 0 &&
            categoryWithCourses.map((categoryWithCourse, index) => {
              return (
                <CourseCategory
                  categoryWithCourse={categoryWithCourse}
                  key={categoryWithCourse._id}
                  index={index}
                  user={props.user}
                />
              );
            })}
        </div>
      </div>
      <div className="fixed-bottom d-block d-sm-none">
        <FooterMenu user={props.user} />
      </div>
    </div>
  );
};

export default HomeCourses;
