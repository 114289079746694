import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "./pages/Error";
import ConsultlotLogged from "./Components/uControles/ConsultlotLogged";
import ConsultlotCommon from "./Components/uControles/ConsultlotCommon";
import InputFieldDemo from "./pages/InputFieldDemo";

import Chat2 from "./pages/Chat2";
import Login from "./pages/Login";
import ToLogin from "./pages/ToLogin";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
let socket = io(process.env.REACT_APP_BACKEND_URL);

const App = () => {
  const [homeNavbarcollapse, setHomeNavbarcollapse] = useState(false);
  //const isCheckoutPage = window.location.pathname === "/payment/checkout";

  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          {/* Add other routes here */}
          <Route exact path="/">
            <ConsultlotCommon
              props={{
                target: "home",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/to-login/:url">
            <ToLogin />
          </Route>
          <Route exact path="/experts">
            <ConsultlotCommon
              props={{
                target: "cv",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route exact path="/articles">
            <ConsultlotCommon
              props={{
                target: "articles",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route exact path="/science-reasearch">
            <ConsultlotCommon
              props={{
                target: "science-research",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route exact path="/user/forgot-password">
            <ConsultlotCommon
              props={{
                target: "forgotpassword",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route exact path="/auth/email-password-change">
            <ConsultlotCommon
              props={{
                target: "emailpasswordchange",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/register">
            <ConsultlotCommon
              props={{
                target: "register",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/profile/story/:id">
            <ConsultlotLogged
              props={{
                target: "profilestory",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/expert/cv/:id">
            <ConsultlotLogged
              props={{
                target: "expertTeam",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/profile/article/:id">
            <ConsultlotLogged
              props={{
                target: "profilearticle",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/profile/science-reasearch/:id">
            <ConsultlotLogged
              props={{
                target: "profilescires",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/profile">
            <ConsultlotLogged
              props={{
                target: "profile",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/explore">
            <ConsultlotLogged
              props={{
                target: "explore",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/chat">
            <ConsultlotLogged
              props={{
                target: "chat",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/edit-profile">
            <ConsultlotLogged
              props={{
                target: "editprofile",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/consultant-profile">
            <ConsultlotCommon
              props={{
                target: "consultantProfile",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/session/:id">
            <ConsultlotLogged
              props={{
                target: "subscription_group",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/story/:id">
            <ConsultlotCommon
              props={{
                target: "story",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/expert/:id">
            <ConsultlotCommon
              props={{
                target: "stafstory",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/article/:id">
            <ConsultlotCommon
              props={{
                target: "article",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/science-research/:id">
            <ConsultlotCommon
              props={{
                target: "scienceresearch",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/subscription/stories/:id">
            <ConsultlotLogged
              props={{
                target: "story",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/user/story/:id">
            <ConsultlotLogged
              props={{
                target: "userstory",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/courses/category/:id">
            <ConsultlotLogged
              props={{
                target: "courseCategory",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/courses">
            <ConsultlotCommon
              props={{
                target: "courses",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/private-groups">
            <ConsultlotCommon
              props={{
                target: "private-groups",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/new/course/mange">
            <ConsultlotLogged
              props={{
                target: "newcourse",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/min/channel-list">
            <ConsultlotLogged
              props={{
                target: "minchannellist",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/min">
            <ConsultlotLogged
              props={{
                target: "min",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/course/mange/:id">
            <ConsultlotLogged
              props={{
                target: "editcourse",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/mycourses">
            <ConsultlotLogged
              props={{
                target: "mycourse",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/private-trainings">
            <ConsultlotLogged
              props={{
                target: "private-training",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/subscriptions">
            <ConsultlotLogged
              props={{
                target: "mysubscriptions",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/cart">
            <ConsultlotLogged
              props={{
                target: "cart",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/wishlist">
            <ConsultlotLogged
              props={{
                target: "wishlist",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          {/* 
          <Route path="/payment/checkout/:orderid">
            <ConsultlotLogged
              props={{
                target: "checkoutorderid",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route> */}
          <Route path="/payment/checkout/:orderid">
            <ConsultlotCommon
              props={{
                target: "checkoutorderid",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/payment/checkout">
            <ConsultlotLogged
              props={{
                target: "checkout",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/paymentknet/succes">
            <ConsultlotLogged
              props={{
                target: "knetsuccess",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/paymentknet/error">
            <ConsultlotLogged
              props={{
                target: "kneterror",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/course/:title/:id">
            <ConsultlotCommon
              props={{
                target: "courseIntro",
                homeNavbarcollapse: homeNavbarcollapse,
                setHomeNavbarcollapse: setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          </Route>
          <Route path="/rpt">
            <ConsultlotCommon props={{ target: "rpt" }} />
          </Route>

          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
export default connect()(App);
