import React from "react";

const SideBarMenus = ({
  header,
  sections,
  menu,
  setMenu,
  sectionType,
  setSectionType,
}) => {
  const menuSelect = (e) => {
    document.querySelectorAll(".checkbox").forEach((checkbox) => {
      checkbox.checked = false;
    });
    setMenu(e.target.name);
    e.target.checked = true;
    if (e.target.name === "Sections") {
      setSectionType(1);
    } else {
      setSectionType(0);
    }
  };
  return (
    <div>
      <div
        style={{
          padding: "0.8rem 0.8rem",
          fontWeight: "700",
          lineHeight: "1.2",
          fontSize: "16px",
          fontFamily:
            "sf pro display,-apple-system,BlinkMacSystemFont,Roboto,segoe ui,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;",
        }}
      >
        {header}
      </div>

      {sections.map((title, i) => {
        return (
          <div
            style={{
              padding: "0.6rem 0.6rem",
              lineHeight: "1.2",
              fontSize: "16px",
              fontFamily:
                "sf pro display,-apple-system,BlinkMacSystemFont,Roboto,segoe ui,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;",
            }}
            key={i}
          >
            {menu == 1 && (
              <>
                {sectionType == 1 && title === "Sections" && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={menuSelect}
                    name={title}
                    defaultChecked={true}
                  />
                )}
                {sectionType == 1 && title === "Stories" && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={menuSelect}
                    name={title}
                  />
                )}
                {sectionType == 0 && title === "Sections" && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={menuSelect}
                    name={title}
                  />
                )}
                {sectionType == 0 && title === "Stories" && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={menuSelect}
                    name={title}
                    defaultChecked={true}
                  />
                )}
              </>
            )}
            <span
              style={{ marginLeft: "5px" }}
              onClick={(e) => {
                document.querySelectorAll(".checkbox").forEach((checkbox) => {
                  checkbox.checked = false;
                });
                setMenu(e.target.innerHTML);

                if (menu == 1) {
                  e.target.previousSibling.checked = true;
                  if (title === "Sections") {
                    setSectionType(1);
                  } else {
                    setSectionType(0);
                  }
                }
              }}
            >
              {title}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default SideBarMenus;
