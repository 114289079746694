import React, { useState } from "react";

import { connect } from "react-redux";
import addStory from "../../images/add_blue.png";
import { toast } from "react-toastify";

import ShortGroupIntro from "./ShortGroupIntro";
import ShowSubscriptionStories from "./ShowSubscriptionStories";

import AddSectionModel from "./AddSectionModel";
import CourseMaterials from "./CourseMaterials";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";

import mice from "../../images/mice.png";

const url = process.env.REACT_APP_BACKEND_URL;

const SectionTypeModel = ({
  group_info,
  setIsCourseListClose,
  setProfile,
  isHecanUnPublish,
  isHecanUnpublishOrStopCourse,
}) => {
  const [showChatModel, setShowChatModel] = useState({
    isModelOpen: false,
  });

  const [sections, setSections] = useState([]);
  const closeModelHandler = () =>
    setShowChatModel({
      isModelOpen: false,
    });

  const getSections = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubSections?sub_id=${group_info._id}`;
    try {
      let res = await getRequestedDataWithHeader(url, group_info.token);
      setSections(res.data.body);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };
  return (
    <>
      <AddSectionModel
        group_info={group_info}
        onModelClose={closeModelHandler}
        showChatModel={showChatModel.isModelOpen}
        getSections={getSections}
      />
      {/* <ShortGroupIntro group_info={group_info} />
        <ShowSubscriptionStories group_info={group_info} /> */}
      <CourseMaterials
        group_info={group_info}
        getSections={getSections}
        setSections={setSections}
        sections={sections}
        setIsCourseListClose={setIsCourseListClose}
        setProfile={setProfile}
        isHecanUnPublish={isHecanUnPublish}
        isHecanUnpublishOrStopCourse={isHecanUnpublishOrStopCourse}
      />
    </>
  );
};

export default SectionTypeModel;
