import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import attachment from "../../images/attachment.png";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { dataURLtoBlob } from "../../utils/sharedfunction";
const url = process.env.REACT_APP_BACKEND_URL;
const StoryAttacment = ({ story, group_info }) => {
  const filePickerRef = useRef(null);
  const [file, setFile] = useState({ type: "text" });
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [attahments, setAttachemnt] = useState(
    story.attachment ? story.attachment : [],
  );
  const cancelFileUpload = useRef(null);

  useEffect(() => {
    setAttachemnt(story.attachment ? story.attachment : []);
  }, [story]);

  const selectFileHandler = () => {
    filePickerRef.current.click();
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const filePickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = false;

    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];

      console.log(fileExttype, filExt, event.target.files[0].type);

      if (fileExttype === "application" || fileExttype === "text") {
        if (filExt !== "x-msdownload") {
          console.log("hi");
          pickedFile = event.target.files[0];
          const reader = new FileReader();
          // reader.readAsDataURL(event.target.files[0])
          // reader.addEventListener('load', async () => {
          //   sendStory(reader.result)
          // })
          sendStory(pickedFile);
          fileIsValid = true;
        } else {
          toast.error("Select suitable file");
          return;
        }
      } else {
        toast.error("Select suitable file");
        return;
      }
    }
  };

  const sendStory = (file) => {
    let formData = new FormData();
    formData.append("story_id", story._id);
    formData.append("story_type", "anydoc");
    formData.append("display_doc_name", file.name);
    formData.append("file", file);

    const options = {
      headers: {
        Authorization: `Bearer ${group_info.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(`${url}/api/userProfile/sendAnyDoc`, formData, options)
      .then((res) => {
        setAttachemnt(res.data.body.attachment);
        setUploadPercentage(0);
        setFile({ type: "text" });

        //document.getElementById(res.data.body.subscription_id).click()
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentage(0);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={attachment}
            style={{ height: 20 + "px" }}
            alt="attachment"
            onClick={selectFileHandler}
          />
          <input
            ref={filePickerRef}
            style={{ display: "none" }}
            type="file"
            onChange={filePickedHandler}
          />
        </div>
      </div>

      {uploadPercentage > 0 && (
        <div className="row mt-3">
          <div className="col pt-1">
            <div className="progress">
              <div
                className="progress-bar bg-success progress-bar-striped"
                role="progressbar"
                style={{
                  width: `${uploadPercentage}%`,
                  height: "100%",
                }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div className="col-auto">
            <span
              className="text-primary cursor-pointer"
              onClick={() => cancelUpload()}
            >
              Abort- Uploading
            </span>
          </div>
        </div>
      )}
      <table className="table table-striped mt-2" style={{ cursor: "pointer" }}>
        <tbody>
          {attahments.length >= 0 &&
            attahments.map((attachment, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Link
                      to={attachment.attachment_url}
                      download={attachment.attachment_url}
                      target="_blank"
                    >
                      {attachment.display_doc_name}
                    </Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default StoryAttacment;
