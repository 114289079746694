import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Menu, Badge } from "antd";

import { MdOutlineSubscriptions, MdSubscriptions } from "react-icons/md";
import { BsChatLeftText, BsChatLeftTextFill } from "react-icons/bs";
import { HiOutlineLightBulb, HiLightBulb } from "react-icons/hi";
import { RiUser2Line, RiUser2Fill } from "react-icons/ri";
import { HomeOutlined, HomeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { AiFillHome } from "react-icons/ai";
import profileDefualt from "../images/default.jpeg";
import home from "../images/home.svg";
import homeSelected from "../images/home selected.svg";
import courses from "../images/courses.svg";
import coursesSelected from "../images/courses selected.svg";
import session from "../images/home.svg";
import chat from "../images/chat.svg";
import chatSelected from "../images/chat selected.svg";
const weburl = process.env.REACT_APP_BACKEND_URL;

const FooterMenu = ({ user, footwrapperclassname }) => {
  const [current, setCurrent] = useState("home");
  const dispatch = useDispatch();
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const history = useHistory();

  const toUrl = (url) => {
    let isLogin = JSON.parse(localStorage.getItem("userDataSessionWay"));
    if (isLogin) {
      if (isLogin.loginUser._id) {
        history.push(`/${url}`);
      } else {
        history.push(`/to-login/${url}`);
        //dispatch({ type: "OpenNavModel", payload: url });
      }
    } else {
      history.push(`/to-login/${url}`);
      //dispatch({ type: "OpenNavModel", payload: url });
    }
  };
  return (
    <>
      <div className={`footer-menu-bottom ${footwrapperclassname}`}>
        <div>
          <Link to="/" style={{ color: "unset" }}>
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => {
                dispatch({ type: "EMPTY_SEARCH_USER" });
                if (document.querySelectorAll(".list-category-carousel")[0]) {
                  document
                    .querySelectorAll(".list-category-carousel")[0]
                    .click();
                }
              }}
            >
              {history.location.pathname !== "/" && (
                <HomeOutlined style={{ fontSize: "22px" }} />
              )}
              {history.location.pathname === "/" && (
                <HomeFilled style={{ fontSize: "22px" }} />
              )}

              <br />
              <span style={{ fontSize: "10px" }}>Home</span>
            </div>
          </Link>
        </div>

        <div>
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              toUrl("subscriptions");
            }}
          >
            {history.location.pathname.split("/")[1] !== "subscriptions" && (
              <MdOutlineSubscriptions size={20} />
            )}

            {history.location.pathname.split("/")[1] === "subscriptions" && (
              <MdSubscriptions size={20} />
            )}
            <br />
            <span style={{ fontSize: "10px" }}>Subscription</span>
          </div>
        </div>

        <div>
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              // toUrl("mycourses");
              history.push(`/private-groups`);
            }}
          >
            {history.location.pathname.split("/")[1] !== "private-groups" && (
              <HiOutlineLightBulb size={22} />
            )}

            {history.location.pathname.split("/")[1] === "private-groups" && (
              <HiLightBulb size={22} />
            )}
            <br />
            <span style={{ fontSize: "10px" }}>Private Groups</span>
          </div>
        </div>

        <div>
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              // toUrl("mycourses");
              history.push(`/courses`);
            }}
          >
            {history.location.pathname.split("/")[1] !== "courses" && (
              <HiOutlineLightBulb size={22} />
            )}

            {history.location.pathname.split("/")[1] === "courses" && (
              <HiLightBulb size={22} />
            )}
            <br />
            <span style={{ fontSize: "10px" }}>Trainings</span>
          </div>
        </div>

        <div>
          <Link
            onClick={() => {
              toUrl("chat");
            }}
            style={{ color: "unset" }}
          >
            <div style={{ textAlign: "center", cursor: "pointer" }}>
              {history.location.pathname.split("/")[1] !== "chat" && (
                <BsChatLeftText size={18} />
              )}
              {history.location.pathname.split("/")[1] === "chat" && (
                <BsChatLeftTextFill size={18} />
              )}
              <br />
              <span style={{ fontSize: "10px" }}>Chats</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default FooterMenu;
