import React, { useState, useRef } from "react";
import { AiOutlineClose, AiFillCamera } from "react-icons/ai";
import axios, { CancelToken, isCancel } from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import profileDefualt from "../../../images/default.jpeg";
import profilecoverDefualt from "../../../images/profilecoverdefault.png";
import { getDataPostWithHeader } from "../../../functions/editProfile/editProfile";
import Modal from "../../../utils/models/Modal";
import ImageCrop from "../../ImageCrop";
import {
  dataURLtoBlob,
  responseMessageDispaly,
} from "../../../utils/sharedfunction";

const url = process.env.REACT_APP_BACKEND_URL;

const EditProfilePicSection = (props) => {
  const [showCrop, setShowCrop] = useState(false);
  const [showCoverCrop, setShowCoverCrop] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);
  const uploadMSG = useRef(null);
  const profileRef = useRef(null);
  const coverRef = useRef(null);
  const aiProfileCameraRef = useRef(null);
  const aiCoverCameraRef = useRef(null);
  const dispatch = useDispatch();

  const openProfileHandler = () => {
    setShowCrop(true);
  };
  const closeProfileHandler = () => setShowCrop(false);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const imagefileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    profileRef.current.src = file;
    var path = `${url}/api/users/updateProfileImage`;
    var type = "image";
    uploadProfileHandler(path, imgeFile, file);
  };

  const uploadProfileHandler = (path, file, base64) => {
    let formData = new FormData();
    formData.append("image", file);
    formData.append("user_id", props.user._id);

    const options = {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        setUploadPercentage(0);

        dispatch({
          type: "profileImage",
          payload: res.data.body.imageUrl,
        });
        //document.getElementById('profile_pic_small').src = base64

        //toast.success("Image Uploaded Success");
        responseMessageDispaly(uploadMSG, "Success", "green");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        profileRef.current.src = "";
        setUploadPercentage(0);
        if (isCancel(err)) {
          toast.success(err.message);
          responseMessageDispaly(uploadMSG, "Aborted", "red");
        } else {
          responseMessageDispaly(uploadMSG, "System Failed", "red");
        }
      });
  };

  const coverpictureInputHandler = (file, FileName) => {
    var imgeFile = dataURLtoBlob(file);
    coverRef.current.src = file;
    const data = {
      user_id: props.user._id,
      keyname: "coverimages/userFiles/usercover",
      filename: FileName,
      fileType: imgeFile.type,
    };
    getPresignedUrl("getPresignedURLForCoverUpload", data, imgeFile, "image");
  };

  const getPresignedUrl = async (apiName, data, pickedFile, type) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;
      const res = await getDataPostWithHeader(url, data, props.token);
      if (res.data.status === 200) {
        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setUploadPercentage(percent);
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setUploadPercentage(0);
            responseMessageDispaly(uploadMSG, "Success", "green");
            const data = {
              user_id: props.user._id,
              cover_url: fileUrl,
            };

            const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/updateProfileCover`;
            const rescover = await getDataPostWithHeader(
              url,
              data,
              props.token,
            );

            dispatch({
              type: "profileCover",
              payload: rescover.data.body.cover,
            });
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setUploadPercentage(0);
        }
      });
  };

  return (
    <>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 300, height: 300 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>
      <Modal
        show={showCoverCrop}
        onCancel={() => {
          setShowCoverCrop(false);
        }}
        headerClass="text-center"
        containerStyle={{ width: "700px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={() => {
              setShowCoverCrop(false);
            }}
            onSave={coverpictureInputHandler}
            aspectRatio={5 / 1}
            ratio="5:1"
            objectFit="horizontal-cover"
          />
        </div>
      </Modal>
      <div className="edit-content-widget">
        <h4 className="ui-tabs-title">Profile Picture</h4>

        <div
          style={{
            cursor: "pointer",
            margin: "5px",
          }}
          className="ups-bg"
        >
          <div
            style={{ position: "relative" }}
            onMouseOver={() => {
              aiCoverCameraRef.current.style.display = "flex";
            }}
            onMouseLeave={() => {
              aiCoverCameraRef.current.style.display = "none";
            }}
          >
            <img
              src={props.user.cover}
              style={{
                width: "100%",
                aspectRatio: "5/1",
              }}
              className="ups-bg-img"
              ref={coverRef}
              onError={(e) => {
                e.target.onError = null;
                e.target.src = profilecoverDefualt;
              }}
            />
            <div
              className="ups-bg-upload-btn"
              ref={aiCoverCameraRef}
              style={{ display: "none" }}
            >
              <AiFillCamera
                size="2.5rem"
                style={{ marginTop: "-2px", marginLeft: "1px" }}
                onClick={() => setShowCoverCrop(true)}
              />
            </div>
          </div>
          <div className="ups-profile">
            <div
              className="ups-profile-area"
              onMouseOver={() => {
                aiProfileCameraRef.current.style.display = "flex";
              }}
              onMouseLeave={() => {
                aiProfileCameraRef.current.style.display = "none";
              }}
            >
              <img
                src={props.user.imageUrl}
                ref={profileRef}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
                className="ups-profile-img"
                alt="group-profile"
              />
              <div
                className="ups-profile-upload-btn"
                ref={aiProfileCameraRef}
                style={{ display: "none" }}
              >
                <AiFillCamera
                  size="2.5rem"
                  style={{ marginTop: "-5px" }}
                  onClick={openProfileHandler}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="text-center tab-inner-content-padding">
          <img
            className="tab-profile-image"
            src={props.user.imageUrl}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = profileDefualt;
            }}
            alt="profile"
            ref={profileRef}
          />
        </div>
        <div className="text-center">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={openProfileHandler}
          >
            Change Profile Picture
          </Button>
        </div> */}
        <div ref={uploadMSG} className="text-center"></div>
        {uploadPercentage > 0 && (
          <div className="row mt-3">
            <div className="col pt-1">
              <div className="progress">
                <div
                  className="progress-bar bg-success progress-bar-striped"
                  role="progressbar"
                  style={{
                    width: `${uploadPercentage}%`,
                    height: "100%",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="col-auto">
              <span
                className="text-primary cursor-pointer"
                onClick={() => cancelUpload()}
              >
                <AiOutlineClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProfilePicSection;
