import { useLocation } from "react-router-dom";

export const errorToggleforTextInput = (validateRef, displayRef, error) => {
  if (validateRef.current.value === "") {
    displayRef.current.style.display = "block";
    displayRef.current.innerHTML = error;
    return false;
  } else {
    return true;
  }
};

export const selectIdValue = (select) => {
  return select.options[select.selectedIndex].id;
};

export const selectTextValue = (select) => {
  return select.options[select.selectedIndex].text;
};

export const selectValueValue = (select) => {
  return select.options[select.selectedIndex].value;
};

export const emialValidation = (value) => {
  return /^\S+@\S+\.\S+$/.test(value);
};

export const isValidEmialAddress = (value, displayDiv) => {
  const isValid = emialValidation(value);

  if (!isValid) displayDiv.style.display = "block";

  return isValid;
};

export const responseMessageClear = (ref) => {
  setTimeout(function () {
    ref.current.innerHTML = "";
  }, 3000);
};

export const responseMessage = (ref, msg, clr) => {
  ref.current.innerHTML = msg;
  ref.current.style.color = clr;
};

export const responseMessageDispaly = (ref, msg, clr, border, padding) => {
  if (ref.current) {
    ref.current.innerHTML = msg;
    ref.current.style.color = clr;
    if (border) ref.current.style.border = `${border}px solid`;
    if (padding) ref.current.style.padding = `${padding}px`;
    setTimeout(function () {
      ref.current.innerHTML = "";
      if (border) ref.current.style.border = "";
      if (padding) ref.current.style.padding = "";
    }, 3000);
  }
};
export const videoPickerHandler = (ref) => {
  ref.click();
};
export const dataURLtoBlob = (dataURL) => {
  var binary = atob(dataURL.split(",")[1]);
  // Create 8-bit unsigned array
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  // Return our Blob object
  return new Blob([new Uint8Array(array)], { type: "image/png" });
};

export function blobToDataUrl(blob) {
  return new Promise((r) => {
    let a = new FileReader();
    a.onload = r;
    a.readAsDataURL(blob);
  }).then((e) => e.target.result);
}

// To convert dataUrl (which we get from our blob) to a a file object
export const dataURLtoFile = (dataurl, filename) => {
  console.log(dataurl);
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) u8arr[n] = bstr.charCodeAt(n);

  return new File([u8arr], filename, { type: mime });
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export const hoursMinSec = (totalSeconds) => {
  console.log(totalSeconds);
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  console.log(hours, minutes, seconds);

  return { hours, minutes, seconds };
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export function calculateRatio(num_1, num_2) {
  for (let num = num_2; num > 1; num--) {
    if (num_1 % num == 0 && num_2 % num == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  var ratio = num_1 + ":" + num_2;
  return ratio;
}

export const calculateVideoRatio = async (videoElement, blobURL) => {
  return new Promise((resolve, reject) => {
    // Set the video source
    videoElement.src = blobURL;

    // Add event listener for 'loadedmetadata'
    videoElement.addEventListener(
      "loadedmetadata",
      function onMetadataLoaded() {
        // Remove the event listener once it's triggered
        videoElement.removeEventListener("loadedmetadata", onMetadataLoaded);

        // Get video dimensions
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;

        // Calculate the aspect ratio
        const ratio = calculateRatio(videoWidth, videoHeight);

        // Resolve the promise with the ratio
        resolve({
          ratio,
          width: videoWidth,
          height: videoHeight,
        });
      },
    );

    // Handle errors (optional)
    videoElement.addEventListener("error", function onError() {
      videoElement.removeEventListener("error", onError);
      reject(new Error("Error loading video metadata."));
    });
  });
};
