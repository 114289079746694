import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoginUser from "../Components/Login/LoginUser";
const ToLogin = () => {
  const params = useParams();
  const dispatch = useDispatch({});

  useEffect(() => {
    dispatch({
      type: "OpenNavModelFromLoginPage",
      payload: params.url,
    });

    return () => {
      dispatch({
        type: "CloseNavModel",
      });
    };
  }, [params]);

  return (
    <LoginUser
      redirect={params.url}
      speacialMessage={`Your are not logged in.! Please login to ${params.url}`}
    />
  );
};

export default ToLogin;
