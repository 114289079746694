import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { BsPlayFill } from "react-icons/bs";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import profileDefualt from "../../images/default.jpeg";
import parse from "html-react-parser";

const MyCourseCard = ({ course }) => {
  return (
    <div className="card-box-course">
      {course.group_video_thumbnail_url && (
        <Link to={`/session/${course._id}`}>
          <div className="thumb-wrapper">
            <img
              style={{
                height: "100%",
                objectFit: "cover",
                width: "100%",
              }}
              src={course.group_video_thumbnail_url}
              alt="im-story"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = defualtImg;
              }}
            />
            <div className="play-bar">
              <div className="play-icon-wrapper"></div>
              <div className="play-icon-sub-wrapper">
                <BsPlayFill size={30} className="play-icon" />
              </div>
            </div>
          </div>
        </Link>
      )}
      <div style={{ display: "flex" }}>
        <Link
          to={`/consultant-profile?user_id=${course.user_id._id}&profile_id=${course.profile_id}`}
        >
          <img
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              float: "left",
              marginTop: "12px",
            }}
            src={course.user_id.imageUrl}
            alt="im-story-profile"
            onError={(e) => {
              e.target.onError = null;
              e.target.src = profileDefualt;
            }}
          />
        </Link>
        <Link to={`/session/${course._id}`}>
          <div style={{ marginLeft: "8px" }}>
            <div className="titile-card-course ">
              {course.group_name.substring(0, 100)}
            </div>
          </div>
        </Link>
      </div>
      <Link to={`/session/${course._id}`}>
        {course.group_video_thumbnail_url && (
          <div
            className={`${
              course.language_id.code === "ar" ? "text-right" : "text-left"
            } description-box ml-2 mt-2`}
            style={{ color: "black" }}
          >
            {course.group_description &&
              course.group_description.length <= 50 &&
              parse(course.group_description)}
            {course.group_description &&
              course.group_description.length > 50 &&
              parse(course.group_description.substring(0, 200))}
          </div>
        )}
      </Link>
    </div>
  );
};

export default MyCourseCard;
