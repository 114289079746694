import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { FaShare, FaComment } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineMore } from "react-icons/ai";
import { BsSend, BsChatLeftText } from "react-icons/bs";
import { RiSendPlaneLine } from "react-icons/ri";

import { toast } from "react-toastify";

import { fetchStory, buySubscriptionGroup } from "../../functions/story/story";

import {
  getDataPostWithHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

import defualtImg from "../../images/Read&ShareThumbnail.png";
import chat from "../../images/chatwhite.svg";
import HlsMobileReelsPlayer from "../players/HlsMobileReelsPlayer";

import VideoCardReals from "./VideoCardReals";
import SearchResult from "../home/SearchResult";
import ReelNavbar from "../../navbar/ReelNavbar";

import "./viewstorymobile.css";

const ViewStoryReelsMobile = ({
  user,
  params_id,
  searchStories,
  homeNavbarcollapse,
  setHomeNavbarcollapse,
  setSearchStories,
  target,
  fromMobile,
  category,
}) => {
  const [story, setStory] = useState({});
  const [height, setHeight] = useState(window.innerHeight - 50);
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [loading, setLoading] = useState();
  const [showSignin, setShowSignin] = useState(false);
  const [showDummyCard, setShowDummyCard] = useState(false);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);

  const [isFollow, setIsFollow] = useState(false);
  const [isNotifyMeAll, setIsNotifyMeAll] = useState(false);
  const [catStories, setCatStories] = useState({});

  const { searchUsers } = useSelector((state) => ({
    ...state,
  }));
  const [darkBackgroundToRead, setDarkBackgroundToRead] = useState(false);

  useEffect(() => {
    // Function to update height
    const handleResize = () => {
      setHeight(window.innerHeight - 50); // Subtract 50px on resize
    };

    // Set initial height
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // document.body.style.backgroundColor = "#f6f6f6";
    // if (fromMobile) {
    //   if (params_id) {
    //     storyDetailsForMobile(params_id);
    //     if (user.userData) {
    //       loadAllStoriesBasedOnUserLangauge(1);
    //     } else {
    //       loadAllStoriesBasedOnMachineLangauge(1);
    //     }
    //   } else {
    //     if (user.userData) {
    //       loadAllStoriesBasedOnUserLangauge(1);
    //     } else {
    //       loadAllStoriesBasedOnMachineLangauge(1);
    //     }
    //   }
    // } else {
    if (params_id) {
      storyDetails(params_id);
    } else {
      if (!category) {
        if (user.userData) {
          loadAllStoriesBasedOnUserLangauge(1);
        } else {
          loadAllStoriesBasedOnMachineLangauge(1);
        }
      }
    }
    // }
  }, [params_id, user.userData, fromMobile]);

  useEffect(() => {
    if (category && category.categoryID && fromMobile) {
      console.log("category2", category.categoryID);
      loadStoriesBasedOnCategoryHandler(category.categoryID, 1);
    }
  }, [category, fromMobile]);

  const storyDetails = async (story_id) => {
    try {
      let url = "";
      if (user.userData) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}&user_id=${user.userData.loginUser._id}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}`;
      }

      const res = await getRequestedData(url);

      if (res.data.body.story.story_type === "video_url") {
        const videoId = getId(res.data.body.story.story_url);
        setYoutubeVideoID(videoId);
      }
      setStory(res.data.body.story);

      //createCard([res.data.body.story], 0);

      if (res.data.body.story.story_converted_url !== "") {
        setHLSVIDEOPLAYER(true);
      }
      setIsFollow(res.data.body.isFollow);
      setIsNotifyMeAll(res.data.body.isNotifyMeAll);
      loadStoriesBasedOnCategoryHandler(res.data.body.story.category_id, 1);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const storyDetailsForMobile = async (story_id) => {
    try {
      let url = "";
      if (user.userData) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}&user_id=${user.userData.loginUser._id}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}`;
      }

      const res = await getRequestedData(url);

      if (res.data.body.story.story_type === "video_url") {
        const videoId = getId(res.data.body.story.story_url);
        setYoutubeVideoID(videoId);
      }
      setStory(res.data.body.story);

      //createCard([res.data.body.story], 0);

      if (res.data.body.story.story_converted_url !== "") {
        setHLSVIDEOPLAYER(true);
      }
      setIsFollow(res.data.body.isFollow);
      setIsNotifyMeAll(res.data.body.isNotifyMeAll);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const usubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === "Subscribed") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribe";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribed";

          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      }
    }
  };

  const buysubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === "Subscribe") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);

          e.target.innerHTML = "Subscribed";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribe";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      }
    } else {
      e.target.innerHTML = "Login To Subscribe";
      e.target.style.fontSize = "12px";
      setTimeout(function () {
        e.target.innerHTML = "Subscribe";
        e.target.style.fontSize = "1rem";
      }, 3000);
    }
  };

  const notifyHandler = async (notifyMeAll) => {
    if (user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/notifyMeWhenUserEachUpdates`;
      const data = {
        user_id: user.userData.loginUser._id,
        subscriber_user_id: story.user_id._id,
        notifyMeAll: notifyMeAll,
      };
      try {
        let res = await getDataPostWithHeader(url, data, user.userData.token);
        setIsNotifyMeAll(res.data.body.isNotifyMeAll);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  };
  const loadStoriesBasedOnCategoryHandler = async (categoryId, storyPageNo) => {
    setLoading(true);
    let url = ``;
    if (user.userData) {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&user_id=${
        user.userData.loginUser._id
      }&page=${storyPageNo}&storyType=video${
        params_id ? `&storyIdToSkip=${params_id}` : ""
      }`;
    } else {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&page=${storyPageNo}&storyType=video${
        params_id ? `&storyIdToSkip=${params_id}` : ""
      }`;
    }
    try {
      let res = await getRequestedData(url);

      setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        setCatStories((prevStories) => [
          ...prevStories,
          ...res.data.body.getStoryModel,
        ]);
      } else {
        setCatStories([]);
        setCatStories(res.data.body.getStoryModel);
      }
      setTotalStoryPage(res.data.body.Total_Page);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const loadAllStoriesBasedOnMachineLangauge = async (storyPageNo) => {
    setLoading(true);
    var lang = navigator.languages[0].split("-")[0] || "en";

    let url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getAllFreeStories?language=${lang}&page=${storyPageNo}`;

    try {
      let res = await getRequestedData(url);

      setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        setCatStories((prevStories) => [
          ...prevStories,
          ...res.data.body.getStoryModel,
        ]);
      } else {
        setCatStories([]);
        setCatStories(res.data.body.getStoryModel);
      }
      setTotalStoryPage(res.data.body.Total_Page);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const loadAllStoriesBasedOnUserLangauge = async (storyPageNo) => {
    setLoading(true);

    if (user && user.userData) {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getAllFreeStories?user_id=${user.userData.loginUser._id}&page=${storyPageNo}`;
      try {
        let res = await getRequestedData(url);

        setStoryPage(storyPageNo + 1);
        if (storyPageNo > 1) {
          // setCatStories((oldArray) => [
          //   ...oldArray,
          //   ...res.data.body.getStoryModel,
          // ]);
          setCatStories((prevStories) => [
            ...prevStories,
            ...res.data.body.getStoryModel,
          ]);
        } else {
          // dispatch({ type: "cat_stories", payload: res.data.body });

          //createCard(res.data.body.getStoryModel, storyPageNo);
          setCatStories([]);
          setCatStories(res.data.body.getStoryModel);
        }
        setTotalStoryPage(res.data.body.Total_Page);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
        toast.error(err.message);
      }
    } else {
      alert("user must login");
    }
  };

  let currentCheck = 0;
  let index = 0;
  let stoped = false;

  const toggleDescription = (id) => {
    var element = document.getElementById(id);
    element.classList.toggle("active");
  };

  return (
    <>
      <div className={`navbar-home-reel`}>
        <ReelNavbar
          isLogin={user.isLogin}
          profileImage={
            user.userData &&
            user.userData.loginUser &&
            user.userData.loginUser.imageUrl
          }
          token={user.userData && user.userData.token}
          userId={user.userData && user.userData.loginUser._id}
          homeNavbarcollapse={homeNavbarcollapse}
          setHomeNavbarcollapse={setHomeNavbarcollapse}
          isSearchBar={true}
          target={target}
          setDarkBackgroundToRead={setDarkBackgroundToRead}
          darkBackgroundToRead={darkBackgroundToRead}
          setSearchStories={setSearchStories}
        />
      </div>

      <div className="player-wrapper">
        <div
          className="video-card-wrapper"
          style={{
            height: `${height}px`,
          }}
        >
          {params_id && story._id && story.story_type == "video" && (
            <VideoCardReals
              story={story}
              toggleDescription={toggleDescription}
              usubscribeGroup={usubscribeGroup}
              buysubscribeGroup={buysubscribeGroup}
              storyid={"s0"}
              setDarkBackgroundToRead={setDarkBackgroundToRead}
              darkBackgroundToRead={darkBackgroundToRead}
              loadAllStoriesBasedOnUserLangauge={
                loadAllStoriesBasedOnUserLangauge
              }
              loadAllStoriesBasedOnMachineLangauge={
                loadAllStoriesBasedOnMachineLangauge
              }
              loadStoriesBasedOnCategoryHandler={
                loadStoriesBasedOnCategoryHandler
              }
              user={user.userData && user.userData.loginUser}
              storyPage={storyPage}
              category={category}
              fromMobile={fromMobile}
            />
          )}

          {catStories.length > 0 &&
            catStories.map((story, i) => {
              if (story._id !== params_id)
                if (story.story_type === "video") {
                  return (
                    <VideoCardReals
                      story={story}
                      toggleDescription={toggleDescription}
                      usubscribeGroup={usubscribeGroup}
                      buysubscribeGroup={buysubscribeGroup}
                      storyid={params_id ? `s${i + 1}` : `s${i}`}
                      setDarkBackgroundToRead={setDarkBackgroundToRead}
                      darkBackgroundToRead={darkBackgroundToRead}
                      totalReelLength={catStories.length}
                      loadAllStoriesBasedOnUserLangauge={
                        loadAllStoriesBasedOnUserLangauge
                      }
                      loadAllStoriesBasedOnMachineLangauge={
                        loadAllStoriesBasedOnMachineLangauge
                      }
                      loadStoriesBasedOnCategoryHandler={
                        loadStoriesBasedOnCategoryHandler
                      }
                      user={user.userData && user.userData.loginUser}
                      storyPage={storyPage}
                      category={category}
                      fromMobile={fromMobile}
                    />
                  );
                }
            })}
        </div>
      </div>
    </>
  );
};

export default ViewStoryReelsMobile;
