import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SubscriptionCardsMobile from "../Components/cards/SubscriptionCardsMobile";
import HomeNavbarVerticle from "../navbar/HomeNavbarVerticle";
import FooterMenu from "../navbar/FooterMenu";
import { getRequestedDataWithHeader } from "../functions/editProfile/editProfile";
import MyCourseCard from "../Components/cards/MyCourseCard";

import "../css/mycourses.css";

const MyCourses = (props) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [coursePage, setCoursePage] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState(2);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    getMycourses(coursePage);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [coursePage, totalCoursePage]);

  const handleScrolListner = () => {
    const TotalPage = totalCoursePage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (coursePage <= TotalPage) {
        getMycourses(coursePage);
      }
    }
  };

  const getMycourses = async (pageNo) => {
    try {
      setLoading(true);
      if (typeof Storage !== "undefined") {
        if (localStorage.getItem("userDataSessionWay")) {
          const userData = JSON.parse(
            localStorage.getItem("userDataSessionWay"),
          );
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscribedSubscriptionGroups?user_id=${userData.loginUser._id}&courseType=${props.courseType}&page=${pageNo}`;
          const res = await getRequestedDataWithHeader(url, userData.token);
          if (pageNo > 1) {
            setCourses((courses) => [
              ...courses,
              ...res.data.body.subcriptions,
            ]);
          } else {
            setCourses(res.data.body.subcriptions);
            setTotalCoursePage(res.data.body.Total_Page);
          }

          setCoursePage(pageNo + 1);
        } else {
          history.push("/");
        }
      } else {
        alert("Please update your browser");
      }
      setLoading(false);
    } catch (errr) {
      console.log(errr);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`d-md-block d-none d-sm-block ${
            props.homeNavbarcollapse ? "col-md-1" : "col-md-2"
          }`}
          style={{
            position: "fixed",
            top: "60px",
            margin: "0px",
            padding: "0px",
            zIndex: "500",
          }}
        >
          <HomeNavbarVerticle
            user={props.user}
            homeNavbarcollapse={props.homeNavbarcollapse}
            opionSelectedKey={props.courseType === 1 ? "5" : "6"}
          />
        </div>
        <div
          className={`${props.homeNavbarcollapse ? "col-md-1" : "col-md-2"}`}
        ></div>

        <div
          className={`${
            props.homeNavbarcollapse ? "col-md-12 pl-70p" : "col-md-10"
          }`}
        >
          <div className="row course-wrapper ">
            {courses.length > 0 &&
              courses.map((list, index) => {
                return (
                  <div className="col-md-3 mt-3" key={list._id}>
                    <MyCourseCard course={list.subscription_id} />
                  </div>
                );
              })}
          </div>

          {loading && (
            <div className="flex-center">
              <span class="loader-spiner"></span>{" "}
            </div>
          )}
        </div>
      </div>
      <div className="fixed-bottom d-block d-sm-none">
        <FooterMenu user={props.user} />
      </div>
    </div>
  );
};

export default MyCourses;
