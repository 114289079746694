import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import RichText from "../editProfile/RichText";
import ReactPlayer from "react-player";
import { FaPlus } from "react-icons/fa";

import { toast } from "react-toastify";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import { BsArrowLeft, BsThreeDotsVertical, BsPencil } from "react-icons/bs";
import axios, { CancelToken, isCancel } from "axios";
import {
  AiFillCamera,
  AiOutlinePlayCircle,
  AiOutlineClose,
} from "react-icons/ai";
import profileDefualt from "../../images/default.jpeg";
import { useDispatch } from "react-redux";
import StoryAttacment from "../newCourse/StoryAttacment";
import addcover from "../../images/default-thumbnail.png";
import ModelV from "../../utils/models/DefaultModel";
import VideoModel from "../VideoModel";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";
import {
  dataURLtoBlob,
  videoPickerHandler,
  responseMessageDispaly,
  selectValueValue,
  selectIdValue,
  selectTextValue,
  setWithExpiry,
  getWithExpiry,
} from "../../utils/sharedfunction";

import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
import CourseStoryView from "./CourseStoryView";
const url = process.env.REACT_APP_BACKEND_URL;

const CourseSectionsLessons = ({
  group_info,
  videoUrl,
  story,
  handleStory,
  getSections,
  setProfile,
}) => {
  const history = useHistory();
  // const dispatch = useDispatch({});
  // const [editDescription, setEditDescription] = useState(false);
  // const [editDescriptionValue, setEditDescriptionValue] = useState(
  //   story.story_text,
  // );
  // const [attachemnt, setAttachment] = useState(false);
  // const [description, setDescription] = useState(true);
  // const [showCoverCrop, setShowCoverCrop] = useState(false);
  // const [uploadPercentage, setUploadPercentage] = useState(0);
  // const videoRef = useRef(null);
  // const bioImageRef = useRef(null);
  // const menuBoxBioRef = useRef(null);
  // const videoPickerRef = useRef(null);
  // const cancelFileUpload = useRef(null);
  // const videobioUpload = useRef(null);

  // useEffect(() => {
  //   videoRef.current.ontimeupdate = function () {
  //     myFunction()
  //   }

  //   function myFunction() {
  //     // Display the current position of the video in a p element with id="demo"

  //     if (videoRef.current.currentTime >= videoRef.current.duration - 30) {
  //       console.log('video finished')

  //       document.getElementById()
  //     }
  //   }
  // }, [])

  // const videofileInputHandler = (file, imgeval) => {
  //   var imgeblob = dataURLtoBlob(imgeval);
  //   var path = `${url}/api/userProfile/updateStoryVideo`;
  //   var type = "video";
  //   uploadVideoHandler(type, path, imgeblob, imgeval, file);
  // };

  // const uploadVideoHandler = (type, path, Cover, base64img, videoFile) => {
  //   let formData = new FormData();

  //   if (type === "video") {
  //     formData.append("video", videoFile);
  //     let isCoverPicBio = getWithExpiry("courseSectionsImage");
  //     if (isCoverPicBio === null) {
  //       formData.append("video_cover", Cover);
  //     } else {
  //       var imgeFile = dataURLtoBlob(isCoverPicBio);
  //       formData.append("video_cover", imgeFile);
  //     }
  //     formData.append("video_cover_key_name", story.thumbnail_url);
  //     formData.append("video_key_name", story.story_url);
  //   } else {
  //     formData.append("image", Cover);
  //     formData.append("Name", story.thumbnail_url);
  //     setWithExpiry("courseSectionsImage", base64img, 100000);
  //   }
  //   formData.append("story_id", story._id);
  //   const options = {
  //     headers: {
  //       Authorization: `Bearer ${group_info.token}`,
  //     },
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;
  //       let percent = Math.floor((loaded * 100) / total);
  //       if (percent <= 100) {
  //         setUploadPercentage(percent);
  //       }
  //     },
  //     cancelToken: new CancelToken(
  //       (cancel) => (cancelFileUpload.current = cancel),
  //     ),
  //   };

  //   axios
  //     .post(path, formData, options)
  //     .then((res) => {
  //       console.log(res);
  //       setUploadPercentage(0);
  //       if (type === "video") {
  //         //bioImageRef.current.src = base64img
  //         videoRef.current.poster = base64img;
  //         videobioUpload.current.src = `${res.data.body.story_url}`;
  //         videoRef.current.src = `${res.data.body.story_url}`;
  //       } else if (type === "cover") {
  //         videoRef.current.poster = `${res.data.body.thumbnail_url}`;
  //       }
  //       closeSubMenuHandler();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response) {
  //         if (err.response.status === 401) {
  //           localStorage.removeItem("userDataSessionway");
  //           window.location.replace("/");
  //         }
  //       }
  //       if (type === "video") {
  //         videobioUpload.current.src = "";
  //       } else {
  //         bioImageRef.current.src = "";
  //       }
  //       setUploadPercentage(0);
  //       if (isCancel(err)) {
  //         alert(err.message);
  //         toast.error("Aborted");
  //       } else {
  //         toast.error("System Failed");
  //       }
  //     });
  // };

  // const closeBioMenuHander = () => {
  //   menuBoxBioRef.current.style.display = "none";
  // };
  // const openCoverHandler = () => {
  //   closeSubMenuHandler();
  //   setShowCoverCrop(true);
  // };

  // const closeSubMenuHandler = () => {
  //   menuBoxBioRef.current.style.display = "none";
  // };

  // const coverfileInputHandler = (file) => {
  //   var imgeFile = dataURLtoBlob(file);
  //   var path = `${url}/api/userProfile/updateStoryThumbnail`;
  //   var type = "cover";
  //   uploadVideoHandler(type, path, imgeFile, file);
  // };

  // const closeCoverHandler = () => setShowCoverCrop(false);

  // const updateDescription = async (value) => {
  //   if (value !== "") {
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryDescription`;
  //     const data = {
  //       story_id: story._id,
  //       story_provider: story.story_provider,
  //       story_description: editDescriptionValue,
  //     };
  //     try {
  //       let res = await getDataPostWithHeader(url, data, group_info.token);
  //       //setEditDescription(false)
  //       handleStory(story._id);
  //       setEditDescription(false);
  //     } catch (err) {
  //       toast.error(err.message);
  //     }
  //   } else {
  //     setEditDescription(false);
  //   }
  // };

  // const selectTabs = (e, tab) => {
  //   document.querySelectorAll(".chapter-tabs").forEach((div) => {
  //     div.style.color = "#6a6f73";
  //     div.style.borderBottom = "none";
  //   });

  //   e.target.style.color = "black";
  //   e.target.style.borderBottom = "1px solid black";

  //   if (tab === "description") {
  //     setDescription(true);
  //     setAttachment(false);
  //   } else {
  //     setDescription(false);
  //     setAttachment(true);
  //   }
  // };

  // const cancelUpload = () => {
  //   if (cancelFileUpload.current)
  //     cancelFileUpload.current("User has canceled the file upload");
  // };

  // const closeModelHandler = () => setShowChatModel(false)
  return (
    <div>
      <div className="mt-3">
        <div className="profile-basic-details-div">
          <div>
            <BsArrowLeft
              size={20}
              className="cursor-pointer mr-2"
              onClick={() => {
                history.push("/profile");
                setProfile(true);
              }}
            />
          </div>
          <div>
            <span
              style={{
                fontSize: "20px",
                marginTop: "10px",
              }}
            >
              {group_info.group_name}
            </span>
            <div>
              {group_info.category_id && group_info.category_id.category_name}
            </div>
          </div>
        </div>

        <div className="clearfix"></div>
      </div>
      <CourseStoryView
        group_info={group_info}
        videoUrl={videoUrl}
        story={story}
        handleStory={handleStory}
        getSections={getSections}
      />
    </div>
  );
};

export default CourseSectionsLessons;
