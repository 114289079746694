import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { FcPhoneAndroid } from "react-icons/fc";
import ErrorSection from "./ErrorSection";
import {
  errorToggleforTextInput,
  selectIdValue,
  selectValueValue,
} from "../../utils/sharedfunction";

const weburl = process.env.REACT_APP_BACKEND_URL;

const SecondSection = ({
  sectionHolder: {
    sheftSections,
    CurrentSection,
    NextSection,
    PreviousSection,
    errorSection,
    progressBar,
  },
  mobile_no,
  county,
  dispatch,
}) => {
  const [isLoading, setLoading] = useState(false);
  const coutrySelectRef = useRef(null);
  const mobileCodeRef = useRef(null);
  const mobileNoRef = useRef(null);
  const enterNoRef = useRef(null);
  const selectCountryRef = useRef(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const url = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${url}/api/users/getCountries`);
        const responseData = await response.json();
        // console.log(responseData.body)
        // let countryList = `<option selected="selected" value="0">Select your country</option>`
        // responseData.body.forEach((list) => {
        //   countryList += `<option id=${list._id} value="${list.dial_cd}">${list.country_english}</option>`
        // })

        let options = responseData.body.map((country) => {
          return {
            value: country.dial_cd,
            label: country.country_english,
            _id: country._id,
            country_cd: country.country_cd,
            dial_cd: country.dial_cd,
            language_cd: country.language_cd,
          };
        });
        setOptions(options);

        if (!response.ok) {
          throw new Error(responseData.message || "something went wrong");
        } else {
          CurrentSection.current.style.pointerEvents = "auto";
          CurrentSection.current.style.opacity = "1";
          progressBar.current.style.display = "none";
          setLoading(true);
        }
      } catch (err) {
        CurrentSection.current.style.display = "none";
        errorSection.current.style.display = "block";
        progressBar.current.style.display = "block";
        console.log(err);
      }
    };
    if (!isLoading) {
      CurrentSection.current.style.pointerEvents = "None";
      CurrentSection.current.style.opacity = "0.7";
      progressBar.current.style.display = "block";
    }
    getCountries();
  }, []);

  const countrySelectHandler = (e) => {
    console.log(e, " e.language_cd");
    var obj = {};
    obj.id = e._id;
    obj.value = e.dial_cd;
    obj.language_cd = e.language_cd;

    console.log(obj, "country");

    selectCountryRef.current.style.display = "none";
    dispatch({ type: "county_info", payload: obj });

    if (e.dial_cd > 0) {
      mobileCodeRef.current.value = "+" + e.dial_cd;
    } else {
      mobileCodeRef.current.value = "";
    }
  };

  const textValidationHandler = () => {
    enterNoRef.current.style.display = "none";
    enterNoRef.current.innerHTML = "";
  };

  const updateSectionHandler = async () => {
    if (county.value > 0) {
      if (
        errorToggleforTextInput(mobileNoRef, enterNoRef, "Enter Mobile No.")
      ) {
        progressBar.current.style.display = "block";
        try {
          const url = process.env.REACT_APP_BACKEND_URL;
          const response = await fetch(`${url}/api/users/userMobileVerify`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ mobile_no: mobileNoRef.current.value }),
          });
          const responseData = await response.json();
          if (!response.ok) {
            const isMobileNoValid = responseData.message.split(":")[1].trim();
            if (
              isMobileNoValid.localeCompare("Mobile No already Existed!!") === 0
            ) {
              enterNoRef.current.innerHTML = responseData.message.split(":")[1];
              enterNoRef.current.style.display = "block";
              progressBar.current.style.display = "none";
            } else {
              CurrentSection.current.style.display = "none";
              errorSection.current.style.display = "block";
              progressBar.current.style.display = "none";
            }
          } else {
            progressBar.current.style.display = "none";
            dispatch({
              type: "mobile_no",
              payload: mobileNoRef.current.value,
            });
            sheftSections(NextSection, CurrentSection);
          }
        } catch (err) {
          console.log(err);
          CurrentSection.current.style.display = "none";
          errorSection.current.style.display = "block";
          progressBar.current.style.display = "none";
        }
      }
    } else {
      selectCountryRef.current.style.display = "block";
    }
  };

  return (
    <>
      {/* <marquee className='progress-bar' direction='right' scrollamount='30'>
        <div className='load-bar'>dsaddsadsadasdsadsa</div>
      </marquee> */}
      <div
        className="registration-model-container"
        //style={{ display: 'none' }}
        ref={CurrentSection}
      >
        <div className="registration-modal-header">
          <h5 className="registration-modal-title">Select Your Country</h5>
        </div>

        <div className="margin-battom-15 ">
          {/* <select
            className='form-control registration-from-control-border'
            ref={coutrySelectRef}
            onChange={countrySelectHandler}
          ></select> */}

          <Select
            options={options}
            formatOptionLabel={(country) => (
              <div className="country-option">
                <img
                  src={`${weburl}/userFiles/flags-mini/${country.country_cd.toLowerCase()}.png`}
                  alt="country-image"
                />
                <span style={{ marginLeft: "10px" }}>{country.label}</span>
              </div>
            )}
            onChange={(e) => {
              countrySelectHandler(e);
            }}
          />
        </div>
        <div className="danger-red display-none" ref={selectCountryRef}>
          Please select your country
        </div>
        {/* <div className="margin-battom-15 ">
          <input
            placeholder="Code"
            className="registration-form-control border-bottom-white input-ht push-left "
            type="text"
            disabled="disabled"
            ref={mobileCodeRef}
          ></input>
        </div> */}

        <div className="margin-battom-15 border-bottom-white display-flex">
          <div style={{ width: "15%" }}>
            <input
              placeholder="Code"
              className="registration-form-control input-ht"
              type="text"
              disabled="disabled"
              ref={mobileCodeRef}
            ></input>
          </div>
          <div>
            <input
              placeholder="Enter Mobile No."
              className="registration-form-control input-ht  "
              type="text"
              ref={mobileNoRef}
              onChange={textValidationHandler}
            />
          </div>
        </div>

        <div className="clearfix"></div>
        <div ref={enterNoRef} className="danger-red display-none">
          Enter Mobile No.
        </div>
        {/* <button
          className='btn-previous push-left'
          onClick={() => {
            sheftSections(PreviousSection, CurrentSection)
          }}
        >
          Back
        </button> */}
        <button
          className="btn-proceed push-right"
          onClick={updateSectionHandler}
        >
          Next
        </button>
        <div className="clearfix"></div>
      </div>
      <div ref={errorSection} style={{ display: "none" }}>
        <ErrorSection />
      </div>
    </>
  );
};
const MapStateToProps = ({ RegSecondSection: { mobile_no, county } }) => {
  return { mobile_no, county };
};
export default connect(MapStateToProps)(SecondSection);
