import React, { useState, useRef } from 'react'
import { GoPencil } from 'react-icons/go'
import { AiOutlineClose } from 'react-icons/ai'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'
import { Spin } from 'antd'
import { editPersonalDetails } from '../../../functions/editProfile/editProfile'
import { responseMessageDispaly } from '../../../utils/sharedfunction'
const EditChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showEdit, setShowEdit] = useState(false)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const uploadMSG = useRef(null)

  const handleSubmit = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/changePassword`
    const data = {
      new_password: newPassword,
      oldPassword: oldPassword,
      user_id: props.user._id,
    }
    try {
      let res = await editPersonalDetails(url, data, props.token)
      props.userDetails()
      setShowEdit(false)
      setLoading(false)
      setNewPassword('')
      setConfirmPassword('')
      setOldPassword('')
      //toast.success('Personal details has updated')
      responseMessageDispaly(
        uploadMSG,
        'Password has been updated successfully',
        'green'
      )
    } catch (err) {
      console.log(err.response)
      if (err.response.data.message.includes('old password mismatch')) {
        responseMessageDispaly(uploadMSG, 'old password mismatch', 'red')
        toast.error('old password mismatch')
      } else {
        responseMessageDispaly(uploadMSG, err.response.data.message, 'red')
        toast.error(err.message)
      }
      setLoading(false)
    }
  }
  return (
    <>
      <div className='edit-content-widget'>
        <div className='ui-tabs-title'>
          <h4 className='float-left'>Change Password</h4>
          {loading && (
            <>
              <Spin className='ml-3' />
              <span className='text-danger'>....</span>
            </>
          )}

          {showEdit || (
            <GoPencil
              className='float-right mt-2'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEdit(true)
              }}
            />
          )}
          {showEdit && (
            <AiOutlineClose
              className='float-right mt-2'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEdit(false)
              }}
            />
          )}
          <div style={{ clear: 'both' }}></div>
        </div>

        {showEdit || (
          <div className=' tab-inner-content-padding'>
            <table id='edit-profile-section'>
              <tbody>
                <tr>
                  <td className='font-bold padding-5'>Old Password</td>
                  <td className='padding-5' id='group_name_view'>
                    ****
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5'>New Password</td>
                  <td className='padding-5' id='group_name_view'>
                    ****
                  </td>
                </tr>
                <tr>
                  <td className='padding-5 font-bold'>Confirm Password</td>
                  <td className='padding-5' id='langauge_view'>
                    *****
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {showEdit && (
          <>
            {loading && <Spin />}
            <div className=' tab-inner-content-padding'>
              <table id='edit-profile-section' style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td
                      className='font-bold padding-5'
                      style={{ width: '10%' }}
                    >
                      Old Password
                    </td>
                    <td>
                      <input
                        name='name'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        value={oldPassword}
                        autoFocus
                        onChange={(e) => {
                          setOldPassword(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='font-bold padding-5'
                      style={{ width: '10%' }}
                    >
                      New Password
                    </td>
                    <td>
                      <input
                        name='email'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        autoFocus
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='padding-5 font-bold'
                      style={{ width: '10%' }}
                    >
                      Confirm Password
                    </td>
                    <td>
                      <input
                        name='email'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        autoFocus
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='col-sm-12 p-2'>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className='float-right'
                  onClick={handleSubmit}
                >
                  update
                </Button>
              </div>
            </div>
          </>
        )}
        <div ref={uploadMSG}></div>
      </div>
    </>
  )
}

export default EditChangePassword
