import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import parse from "html-react-parser";
import RichText from "../editProfile/RichText";

import { toast } from "react-toastify";
import {
  AiFillCamera,
  AiOutlinePlayCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";
import ReactPlayer from "react-player";
import { BsArrowLeft, BsThreeDotsVertical, BsPencil } from "react-icons/bs";
import { connect } from "react-redux";
import profileDefualt from "../../images/default.jpeg";
import defaultThumbnail from "../../images/default-thumbnail.png";
import addcover from "../../images/default-thumbnail.png";
import ModelV from "../../utils/models/DefaultModel";
import VideoModel from "../VideoModel";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";
import ResponseViewAndEdit from "./ResponseViewAndEdit";
import { useHistory } from "react-router-dom";
import VideoJS from "../../Components/VideoJS";
import {
  dataURLtoBlob,
  videoPickerHandler,
  responseMessageDispaly,
  selectValueValue,
  selectIdValue,
  selectTextValue,
  setWithExpiry,
  getWithExpiry,
  calculateVideoRatio,
} from "../../utils/sharedfunction";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";

import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";

import EditSubGropDetails from "./EditSubGropDetails";
import HlsPlayer from "../../Components/players/HlsPlayer";
const url = process.env.REACT_APP_BACKEND_URL;
const CourseIntroImageVideoEdit = ({
  group_info,
  UserRequiredDropDown,
  dispatch,
  showSubscription,
  setIsCourseListClose,
  setProfile,
}) => {
  const [showVideo, setshowVideo] = useState({ isShowVideo: false, url: "" });
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [videoProcessingUpload, setVideoProcessingUpload] = useState(false);
  const [imageProcessingUpload, setImageProcessingUpload] = useState(0);
  const uploadImgRef = useRef(null);
  const profileRefMedium = useRef(null);
  const uploadMSG = useRef(null);
  const [showCrop, setShowCrop] = useState(false);
  const [showCoverCrop, setShowCoverCrop] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const bioImageRef = useRef(null);
  const videoRef = useRef(null);
  const menuBoxBioRef = useRef(null);
  const videoPickerRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const videobioUpload = useRef(null);
  const [videoRatio, setVideoRatio] = useState("");
  const [editDescription, setEditDescription] = useState(false);
  const [addNewlearnText, setAddNewlearnText] = useState("");
  const [addNewRequireText, setAddNewRequireText] = useState("");
  const [inputBoxvisible, setBoxVisible] = useState(false);
  const [inputBoxPrevisible, setpreBoxVisible] = useState(false);

  const history = useHistory();

  const [editDescriptionValue, setEditDescriptionValue] = useState(
    group_info.group_description,
  );
  const openProfileHandler = () => {
    setShowCrop(true);
  };

  const closeProfileHandler = () => setShowCrop(false);

  const imagefileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    profileRefMedium.current.src = file;
    document.getElementById(group_info._id).childNodes[0].childNodes[0].src =
      file;
    var path = `${url}/api/userProfile/updateSubscriptionImage`;
    var type = "image";
    uploadVideoHandler(type, path, imgeFile, file);
  };

  const playVideo = (url) => {
    setshowVideo({ isShowVideo: true, url: url });
  };

  const closeVideoPlayer = () => {
    setshowVideo({ isShowVideo: false, url: "" });
  };

  const closeBioMenuHander = () => {
    menuBoxBioRef.current.style.display = "none";
  };

  const editSubDescHandler = () => {
    setEditDesc(true);
  };

  const videofileInputHandler = async (file) => {
    let blobURL = URL.createObjectURL(file);

    const dataVideo = {
      user_id: group_info.user_id._id,
      keyname: "userFiles/Courses",
      filename: file.name,
      fileType: file.type,
    };

    console.log("video", videobioUpload.current);
    const { ratio } = await calculateVideoRatio(
      videobioUpload.current,
      blobURL,
    );
    console.log("ratio", ratio);
    setVideoRatio(ratio);

    setUploadPercentage(1);
    getPresignedUrl("getPresignedURLForVideUpload", dataVideo, file, ratio);
  };

  const openCoverHandler = () => {
    closeSubMenuHandler();
    setShowCoverCrop(true);
  };

  const coverfileInputHandler = (file) => {
    var imgeblob = dataURLtoBlob(file);
    //bioImageRef.current.src = file;

    const dataImage = {
      user_id: group_info.user_id._id,
      keyname: "coverimages/userFiles/Courses",
      filename: `screnhot_${Math.round(Math.random() * 1e5)}.png`,
      fileType: imgeblob.type,
    };

    getPresignedUrl("getPresignedURLForCoverUpload", dataImage, imgeblob);
  };

  const getPresignedUrl = async (apiName, data, pickedFile, ratio) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, group_info.token);
      if (res.data.status === 200) {
        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
          ratio,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl, ratio) => {
    if (apiName !== "getPresignedURLForCoverUpload") {
      setUploadPercentage(1);
    }
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          console.log(percent);
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
              setImageUploadPercentage(percent);
            } else {
              setVideoProcessingUpload(true);
            }
          } else {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else {
              setUploadPercentage(percent + 1);
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            const dataUpdateImage = {
              subscription_id: group_info._id,
              path: fileUrl,
            };

            const res2 = await getDataPostWithHeader(
              `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateSubscriptionThumbnail`,
              dataUpdateImage,
              group_info.token,
            );

            dispatch({
              type: "group_details",
              payload: {
                ...group_info,
                group_video_thumbnail_url:
                  res2.data.body.group_video_thumbnail_url,
              },
            });
            //setImageUploadPercentage(0);
            //setImageProcessingUpload(false);
          } else {
            try {
              console.log("ratio22", ratio);
              const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
              const data = {
                url: fileUrl,
                user_id: group_info.user_id._id,
                whereToUpdate: "courseIntro",
                id: group_info._id,
                videoRatio: ratio,
              };

              const res1 = await getDataPostWithHeader(
                urlConvert,
                data,
                group_info.token,
              );
            } catch (err) {
              throw new Error("update error or suspended");
            }
            let name = fileUrl.split("/");
            name = name[name.length - 1];
            let keyNameFol = name.split(".")[0];
            let keyName = keyNameFol + ".m3u8";

            const dataUpdateVideo = {
              subscription_id: group_info._id,
              video_path: fileUrl,
              vidDuration: videobioUpload.current.duration,
            };
            //setHlsUrl(res1.data.body.url);

            const res2 = await getDataPostWithHeader(
              `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/SubscriptionGroupVideo`,
              dataUpdateVideo,
              group_info.token,
            );

            dispatch({
              type: "group_details",
              payload: {
                ...group_info,
                group_introduction_video_url:
                  res2.data.body.group_introduction_video_url,
                converted_url: res2.data.body.converted_url,
              },
            });

            setUploadPercentage(0);
            setVideoProcessingUpload(false);
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else {
          console.log(err.message);

          if (err.message === "update error or suspended") {
            alert(err.message);
          } else {
            if (err.message !== "User has canceled the file upload") {
              alert(
                "While uploading something went wrong ! try again uploading",
              );
            }
          }
          setUploadPercentage(0);
          setVideoProcessingUpload(false);
        }
      });
  };

  const closeCoverHandler = () => setShowCoverCrop(false);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const closeSubMenuHandler = () => {
    menuBoxBioRef.current.style.display = "none";
  };

  const uploadVideoHandler = (type, path, Cover, base64img, videoFile) => {
    let formData = new FormData();
    if (type === "video") {
      formData.append("video", videoFile);
      let isCoverPicBio = getWithExpiry("courseIntroVideo");
      if (isCoverPicBio === null) {
        formData.append("video_cover", Cover);
      } else {
        var imgeFile = dataURLtoBlob(isCoverPicBio);
        formData.append("video_cover", imgeFile);
      }
      formData.append(
        "video_cover_key_name",
        group_info.group_video_thumbnail_url,
      );
      formData.append(
        "video_key_name",
        group_info.group_introduction_video_url,
      );
    } else {
      formData.append("image", Cover);
      if (type === "cover") {
        formData.append("Name", group_info.group_video_thumbnail_url);
      } else if (type === "image") {
        formData.append("Name", group_info.group_image_url);
      }
      setWithExpiry("courseIntroVideo", base64img, 100000);
    }
    formData.append("subscription_id", group_info._id);
    const options = {
      headers: {
        Authorization: `Bearer ${group_info.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        setUploadPercentage(0);

        closeSubMenuHandler();

        dispatch({
          type: "group_details",
          payload: {
            ...group_info,
            group_introduction_video_url:
              res.data.body.group_introduction_video_url,
          },
        });

        if (type === "video") {
          //bioImageRef.current.src = base64img
          videoRef.current.poster = base64img;
          videobioUpload.current.src = "";
        }

        responseMessageDispaly(uploadMSG, "success", "green");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (type === "video") {
          videobioUpload.current.src = "";

          console.log(err.message);

          if (err.message === "update error or suspended") {
            alert(err.message);
          } else {
            if (err.message !== "User has canceled the file upload") {
              alert(
                "While uploading something went wrong ! try again uploading",
              );
            }
          }
        } else {
          bioImageRef.current.src = "";
        }
        setUploadPercentage(0);
      });
  };

  const updateDescription = (value) => {
    if (value !== group_info.group_description) {
      if (value !== "") {
        saveSubscriptionGroupHandler();
      } else {
        toast.error("enter the description");
      }
    }
    setEditDescription(false);
  };

  const learnTextUpdatehandler = async (id, oldValue, e) => {
    document.getElementById(`${id}_view`).style.display = "block";
    document.getElementById(`${id}_edit_button`).style.display = "block";
    document.getElementById(`${id}_edit`).style.display = "none";
    if (e.target.value !== oldValue) {
      if (e.target.value !== "") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateLearnText`;
        const data = {
          subscription_group_id: group_info._id,
          learn_text_response: e.target.value,
          response_id: id,
        };
        try {
          let res = await getDataPostWithHeader(url, data, group_info.token);

          dispatch({
            type: "group_details",
            payload: {
              ...res.data.body,
              user_id: group_info.user_id,
              token: group_info.token,
            },
          });
        } catch (err) {
          toast.error(err.message);
        }
      } else {
        toast.error("enter the description");
      }
    }
  };

  const learnTexthandler = async () => {
    if (addNewlearnText !== "") {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addLearnText`;
      const data = {
        subscription_group_id: group_info._id,
        learn_text: addNewlearnText,
      };
      try {
        let res = await getDataPostWithHeader(url, data, group_info.token);
        setBoxVisible(false);
        setAddNewlearnText("");
        dispatch({
          type: "group_details",
          payload: {
            ...res.data.body,
            user_id: group_info.user_id,
            token: group_info.token,
            category_id: group_info.category_id,
            currency_id: group_info.currency_id,
            language_id: group_info.language_id,
          },
        });
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      setBoxVisible(false);
    }
  };

  const preTextUpdatehandler = async (id, oldValue, e) => {
    document.getElementById(`${id}_view`).style.display = "block";
    document.getElementById(`${id}_edit_button`).style.display = "block";
    document.getElementById(`${id}_edit`).style.display = "none";
    if (e.target.value !== oldValue) {
      if (e.target.value !== "") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updatePreReq`;
        const data = {
          subscription_group_id: group_info._id,
          learn_text_response: e.target.value,
          response_id: id,
        };
        try {
          let res = await getDataPostWithHeader(url, data, group_info.token);

          console.log(res.data);

          dispatch({
            type: "group_details",
            payload: {
              ...res.data.body,
              user_id: group_info._id,
              token: group_info.token,
            },
          });
        } catch (err) {
          toast.error(err.message);
        }
      } else {
        toast.error("enter the description");
      }
    }
  };

  const preReqTexthandler = async () => {
    if (addNewRequireText !== "") {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addPrereq`;
      const data = {
        subscription_group_id: group_info._id,
        learn_text: addNewRequireText,
      };
      try {
        let res = await getDataPostWithHeader(url, data, group_info.token);
        setpreBoxVisible(false);
        setAddNewRequireText("");
        dispatch({
          type: "group_details",
          payload: {
            ...res.data.body,
            user_id: group_info._id,
            token: group_info.token,
            category_id: group_info.category_id,
            currency_id: group_info.currency_id,
            language_id: group_info.language_id,
          },
        });
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      setpreBoxVisible(false);
    }
  };

  const saveSubscriptionGroupHandler = async () => {
    const language_id = group_info.language_id._id;
    const currency = group_info.currency_id._id;
    const groupName = group_info.group_name;
    const subgroupPrice = group_info.subscription_price;
    const subgroupDecription = editDescriptionValue;
    const category = group_info.category_id._id;
    try {
      const response = await fetch(
        `${url}/api/userProfile/updateSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${group_info.token}`,
          },
          body: JSON.stringify({
            group_name: groupName,
            language_id: language_id,
            category_id: category,
            subscription_price: subgroupPrice,
            currency_id: currency,
            group_description: subgroupDecription,
            subscription_id: group_info._id,
          }),
        },
      );
      const responseData = await response.json();

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("userDataSessionway");
          window.location.replace("/");
        }

        throw new Error(responseData.message || "something went wrong");
      } else {
        console.log(responseData.body);
        //toast.success("updated success");
        setEditDescription(false);
        //responseMessageDispaly(errorMessage, 'Success', 'green')
        dispatch({
          type: "group_details",
          payload: {
            ...responseData.body,
            user_id: group_info.user_id._id,
            token: group_info.token,
          },
        });
      }
    } catch (err) {}
  };

  return (
    <>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 400, height: 400 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>
      <ModelV show={showVideo.isShowVideo}>
        <VideoModel src={showVideo.url} onClose={closeVideoPlayer} />
      </ModelV>
      <Modal
        show={showCoverCrop}
        onCancel={closeCoverHandler}
        headerClass="text-center"
        containerStyle={{ height: "550px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeCoverHandler}
            onSave={coverfileInputHandler}
            aspectRatio={16 / 9}
            objectFit="horizontal-cover"
            ratio="16:9"
          />
        </div>
      </Modal>
      {!editDesc && (
        <div id="profile_area">
          <div className="mt-3">
            {/* <div
              className="profile-image-div"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            ></div> */}
            <div>
              <BsArrowLeft
                size={20}
                className="cursor-pointer mr-2"
                onClick={() => {
                  history.push("/profile");
                  setProfile(true);
                }}
              />
            </div>
            <div className="profile-basic-details-div">
              <div>
                <span
                  style={{
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  {group_info.group_name}
                </span>
                <div>
                  {group_info.category_id &&
                    group_info.category_id.category_name}
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
          <div>
            <div ref={uploadMSG}></div>
            <div
              id="thumbnail_div"
              className="text-center"
              style={{ position: "relative" }}
            >
              {group_info.group_introduction_video_url ? (
                <>
                  {group_info.converted_url &&
                    HLSVIDEOPLAYER &&
                    group_info.video_encode_processed &&
                    group_info.video_encode_process_status === "completed" && (
                      <>
                        <HlsPlayer
                          url={group_info.converted_url}
                          setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                        />
                      </>
                    )}

                  {!group_info.video_encode_processed && (
                    <div className="video-warapper-16-9-center">
                      {console.log("video url sds", group_info.converted_url)}
                      <video
                        id="play_video"
                        src={`${group_info.group_introduction_video_url}`}
                        poster={group_info.group_video_thumbnail_url}
                        className="video-16-9-center"
                        controls
                        ref={videoRef}
                      ></video>
                    </div>
                  )}

                  {!group_info.converted_url &&
                    group_info.video_encode_processed && (
                      <video
                        controls
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <source
                          type="video/mp4"
                          src={group_info.group_introduction_video_url}
                        />
                      </video>
                    )}
                </>
              ) : (
                <img
                  id="thumnail_bio"
                  src={group_info.group_video_thumbnail_url}
                  className="width-100 cursor-pointer"
                  ref={bioImageRef}
                  alt="thumbnail"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = addcover;
                  }}
                />
              )}
            </div>
            <div ref={uploadMSG}></div>
            {uploadPercentage > 0 && uploadPercentage < 100 && (
              <div className="row mt-3">
                <div className="col pt-1">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success progress-bar-striped"
                      role="progressbar"
                      style={{ width: `${uploadPercentage}%`, height: "100%" }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {uploadPercentage}%
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => cancelUpload()}
                  >
                    <AiOutlineClose />
                  </span>
                </div>
              </div>
            )}
            {videoProcessingUpload && (
              <div className="loading mt-1 m-3">processing</div>
            )}
            <video
              width="100%"
              controls
              ref={videobioUpload}
              style={{ display: "none" }}
            >
              Your browser does not support the video tag.
            </video>
            <div style={{ textAlign: "right", display: "block" }}>
              <BsThreeDotsVertical
                onClick={() => {
                  menuBoxBioRef.current.style.display = "block";
                }}
                style={{ marginRight: -6 + "px" }}
              />
            </div>
            <div className="menu-box-bio display-none" ref={menuBoxBioRef}>
              <div className="menu-header">
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={closeBioMenuHander}
                />
              </div>
              <div
                className="menu-line cursor-pointer"
                onClick={openCoverHandler}
              >
                Select cover
              </div>
              <div
                className="menu-line cursor-pointer"
                onClick={() => {
                  videoPickerHandler(videoPickerRef.current);
                  menuBoxBioRef.current.style.display = "none";
                }}
              >
                Replace the video
              </div>

              <VideoUpload
                videoFilePickerRef={videoPickerRef}
                videourlref={videobioUpload}
                onvideofileInput={videofileInputHandler}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "24px",
              }}
            >
              {!editDescription ? (
                <>
                  <div
                    id="text_brief"
                    className="language-bio"
                    style={{ height: "50px" }}
                  >
                    {group_info.group_description ? (
                      parse(group_info.group_description)
                    ) : (
                      <span style={{ opacity: "0.5", height: "50px" }}>
                        Add Group Description
                      </span>
                    )}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditDescription(true);
                    }}
                  >
                    <BsPencil />
                  </div>
                </>
              ) : (
                <textarea
                  value={editDescriptionValue}
                  onChange={(e) => setEditDescriptionValue(e.target.value)}
                  onBlur={updateDescription}
                  placeholder="update description"
                  style={{ width: "100%", height: "8rem", resize: "none" }}
                />
                // <RichText
                //   data={editDescriptionValue}
                //   setData={setEditDescriptionValue}
                //   update={true}
                //   updateText={updateDescription}
                // />
              )}
            </div>
            <ResponseViewAndEdit
              addReponse={addNewlearnText}
              setAddResponse={setAddNewlearnText}
              inputBoxvisible={inputBoxvisible}
              setBoxVisible={setBoxVisible}
              responsehandler={learnTexthandler}
              responses={group_info.learn_text}
              responseUpdateHandler={learnTextUpdatehandler}
              responseTitle="Learning Obejectives"
              palceholder="Add Learning Obejectives"
              group_info={group_info}
            />
            <ResponseViewAndEdit
              addReponse={addNewRequireText}
              setAddResponse={setAddNewRequireText}
              inputBoxvisible={inputBoxPrevisible}
              setBoxVisible={setpreBoxVisible}
              responsehandler={preReqTexthandler}
              responses={group_info.requirements}
              responseUpdateHandler={preTextUpdatehandler}
              responseTitle="Pre Requisites"
              palceholder="Add Pre Requestes"
              group_info={group_info}
            />
          </div>
        </div>
      )}

      {editDesc && (
        <EditSubGropDetails group_info={group_info} setEditDesc={setEditDesc} />
      )}

      {/* {editPrice && (
        <EditSubGropDetails group_info={group_info} setEditDesc={setEditDesc} />
      )} */}
    </>
  );
};

const MapStateToProps = ({
  UserSubscriptionGroup: { group_info },
  UserRequiredDropDown,
}) => {
  return { group_info, UserRequiredDropDown };
};

export default connect(MapStateToProps)(CourseIntroImageVideoEdit);
