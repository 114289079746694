import React, { useState } from "react";
import { Card, Skeleton, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import ReactPlayer from "react-player";
import "../../css/ant.css";

const { Meta } = Card;

const weburl = process.env.REACT_APP_BACKEND_URL;

const CourseCard = ({ course }) => {
  let desc = parse(course.group_description.substring(0, 150));
  return (
    <>
      <Card
        hoverable
        cover={
          <Link to={`/course/${course.group_name}/${course._id}`}>
            <img
              style={{ height: "150px", objectFit: "cover", width: "100%" }}
              src={course.group_video_thumbnail_url}
              alt="im-story"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = defualtImg;
              }}
            />
          </Link>
        }
      >
        <Meta title={parse(course.group_name)} description={desc} />
      </Card>
    </>
  );
};

export default CourseCard;
