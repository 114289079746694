import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { getDataPostWithOutHeader } from "../functions/editProfile/editProfile";
import { useQuery, responseMessageDispaly } from "../utils/sharedfunction";

import "../css/forgotpassword.css";

const PasswordLinkChange = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState({ message: "", class: "" });
  const uploadMSG = useRef(null);

  let query = useQuery();

  const dispatch = useDispatch({});

  const resetLink = async (e) => {
    if (password !== confirmPassword) {
      responseMessageDispaly(uploadMSG, "Password mismatch", "red");
      return;
    }
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/changePasswordLink`;
    const data = {
      password: password,
      token: query.get("email"),
    };
    try {
      let res = await getDataPostWithOutHeader(url, data);

      setText({ message: res.data.body, class: "text-success" });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      responseMessageDispaly(uploadMSG, err.message, "red");
      console.log(err);
    }
  };
  return (
    <div className="forgot-password-cointainer">
      {text.message && (
        <div className="flex-center">
          <div className={`${text.class} success-message-box`}>
            {text.message}
          </div>
          <div className="margin-top-1 flex-center">
            <u
              className="or-login"
              onClick={() => {
                dispatch({ type: "OpenNavModel" });
              }}
            >
              Login
            </u>
          </div>
        </div>
      )}
      <div ref={uploadMSG} className="flex-center"></div>
      {text.message === "" && (
        <div className="forgot-box">
          <div className="forgot-form-wrapper">
            <div className="input-label">Password</div>
            <input
              type="password"
              className="input-email"
              placeholder="password"
              autoComplete="off"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <div className="input-label margin-top-1">Confirm password</div>
            <input
              type="password"
              className="input-email"
              placeholder="password"
              autoComplete="off"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <div className="button-wrapper">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="button-reset-send"
                onClick={resetLink}
              >
                Reset Password
              </Button>

              <div className="flex-center display-flex-align-center">
                Or
                <u
                  className="or-login"
                  onClick={() => {
                    dispatch({ type: "OpenNavModel" });
                  }}
                >
                  Login
                </u>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordLinkChange;
