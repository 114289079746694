import React, { useRef, useState, useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { connect } from "react-redux";
import {
  errorToggleforTextInput,
  selectValueValue,
} from "../../utils/sharedfunction";

const ThridSection = ({
  sectionHolder: {
    sheftSections,
    CurrentSection,
    NextSection,
    PreviousSection,
  },
  county,
  dispatch,
}) => {
  const fullNameRef = useRef(null);
  const genderRef = useRef(null);
  // const isExpertRef = useRef(null)
  const selectGenRef = useRef(null);
  const selectLangeRef = useRef(null);
  const enterFullNameRef = useRef(null);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState(county.language_cd);

  useEffect(() => {
    setLang(county.language_cd);
  }, [county.language_cd]);

  const validConultantHandler = () => {
    let selectValue = selectValueValue(genderRef.current);

    if (
      errorToggleforTextInput(fullNameRef, enterFullNameRef, "Enter Your Name")
    ) {
      if (selectValue === "0") {
        selectGenRef.current.style.display = "block";
      } else {
        let isConsultant = 0;
        // if (isExpertRef.current.checked === true) {
        //   isConsultant = 1
        // }
        const userData = {
          user_name: fullNameRef.current.value,
          gender: selectValue,
          is_consultant: isConsultant,
          lang: lang,
        };

        dispatch({ type: "userData", payload: userData });
        sheftSections(NextSection, CurrentSection);
      }
    }
  };

  useEffect(() => {
    const getLanguage = async () => {
      setLoading(true);
      try {
        const url = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${url}/api/users/getLanguages`);
        const responseData = await response.json();
        setLanguages(responseData.body);
        if (!response.ok) {
          throw new Error(responseData.message || "something went wrong");
        }
      } catch (err) {
        console.log(err);
        alert(err.message || "something went wrong");
      }
      setLoading(false);
    };

    getLanguage();
  }, []);

  return (
    <div
      className="registration-model-container"
      style={{ display: "none" }}
      ref={CurrentSection}
    >
      <div className="registration-modal-header">
        <h5 className="registration-modal-title">Tell About YourSelf</h5>
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader-spiner"></span>
        </div>
      )}
      {!loading && (
        <>
          <div className=" margin-battom-15 border-bottom-white">
            <span className="icon-size model-font-color">
              <BsFillPersonFill />
            </span>
            <input
              name="textinput"
              placeholder="Enter Full name"
              className="registration-form-control input-ht registration-input-90 "
              type="text"
              ref={fullNameRef}
              onChange={() => {
                enterFullNameRef.current.style.display = "none";
              }}
            />
          </div>
          <div className="display-none danger-red" ref={enterFullNameRef}>
            Enter your name
          </div>
          <div
            className="margin-battom-15 border-bottom-white"
            style={{ paddingBottom: "6px" }}
          >
            <span
              className="icon-size input-group-addon model-font-color "
              style={{ position: "relative", top: "1px" }}
            >
              <BsFillPersonFill />
            </span>
            <select
              className="form-control register-select push-right "
              style={{
                width: "93%",
                border: "none",
                height: "30px",
                paddingTop: "6px",
                paddingLeft: "4px",
              }}
              ref={genderRef}
              onChange={() => {
                selectGenRef.current.style.display = "none";
              }}
            >
              <option value="0">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <div className="display-none danger-red" ref={selectGenRef}>
              Select Gender
            </div>
          </div>
          <div
            className="margin-battom-15 border-bottom-white"
            style={{ paddingBottom: "6px" }}
          >
            {languages.length > 0 && (
              <select
                className="form-control register-select"
                style={{
                  width: "100%",
                  border: "none",
                  paddingTop: "6px",
                  paddingLeft: "4px",
                  paddingBottom: "6px",
                }}
                value={
                  languages.some((list) => list.code === lang) ? lang : "en"
                }
                onChange={(e) => setLang(e.target.value)}
              >
                {languages.map((list) => (
                  <option ket={list._id} value={list.code}>
                    {list.nativeName}
                  </option>
                ))}
              </select>
            )}
            <div className="display-none danger-red" ref={selectLangeRef}>
              Select Language
            </div>
          </div>
          {/* <div className='margin-battom-15 '>
        <input type='checkbox' ref={isExpertRef} />
        <i style={{ marginLeft: '10px' }}>Register as an Expert</i>
      </div> */}
          <button
            className="btn-previous push-left "
            onClick={() => {
              sheftSections(PreviousSection, CurrentSection);
            }}
          >
            Back
          </button>
          <button
            className="btn-proceed push-right "
            onClick={validConultantHandler}
          >
            Next
          </button>{" "}
        </>
      )}
      <div className="clearfix"></div>
    </div>
  );
};
const MapStateToProps = ({
  RegThirdSection: { userData },
  RegSecondSection: { mobile_no, county },
}) => {
  // console.log(user_name)
  return { userData, county };
};
export default connect(MapStateToProps)(ThridSection);
