import React, { useRef, useState } from "react";
import plusIcon from "../../SVG_images/plus.svg";
import checkIcon from "../../SVG_images/check.svg";
import deleteIcon from "../../SVG_images/delete.svg";
import editIcon from "../../SVG_images/edit.svg";
import closeIcon from "../../SVG_images/close.svg";
import saveIcon from "../../SVG_images/save.svg";
import { IoAddOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";

import {
  edit_learn_line,
  update_learn_line,
  show_new_learn_line,
  save_new_learn,
  close_learn_line,
  save_new_prerequired,
} from "../../utils/Learn";

import "../../css/tolearn.css";
import { colors } from "@material-ui/core";

function WhatToLearn2({ inputFields, setInputFields, placeholder }) {
  const handleFormChange = (event) => {
    if (event.target.value != "") {
      let data = { your_response: event.target.value };
      setInputFields([...inputFields, data]);
    }
  };

  // const addFields = () => {
  //   let newfield = { your_response: "" };
  //   setInputFields([...inputFields, newfield]);
  // };
  return (
    <>
      <div>
        <div id="input_box_for_learntext">
          {inputFields.length > 0 &&
            inputFields.map((input, index) => {
              return (
                <div id="learn_text" type="text" key={index}>
                  {`${index + 1}. ${input.your_response}`}
                </div>
              );
            })}
        </div>

        <div
          style={{ lineHeight: "28px", marginTop: "15px", cursor: "pointer" }}
        >
          <div id="learn_insert_new_line_icon">
            <input
              id="learn_text"
              type="text"
              className="input_learn_text_field"
              placeholder={"Example: you can build your own apps"}
              onBlur={(event) => {
                handleFormChange(event);
                event.target.value = "";
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleFormChange(event);
                  event.target.value = "";
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatToLearn2;
