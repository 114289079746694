const url = process.env.REACT_APP_BACKEND_URL;
export const categgoryBasedLangauge = async () => {
  try {
    const response = await fetch(`${url}/api/users/getCategories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData.message);
      throw new Error(responseData.message || "something went wrong");
    } else {
      return responseData.body;
    }
  } catch (err) {
    console.log(err);
    throw new Error(err || "something went wrong");
  }
};

export const categgoryBasedLangaugeBYID = async (search_cat) => {
  try {
    const response = await fetch(
      `${url}/api/users/getCatgories2?searchCat=${search_cat}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData.message);
      throw new Error(responseData.message || "something went wrong");
    } else {
      return responseData.body;
    }
  } catch (err) {
    console.log(err);
    throw new Error(err || "something went wrong");
  }
};
