import React, { useState, useRef, useEffect } from "react";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";

import Button from "@material-ui/core/Button";
import { editPersonalDetails } from "../../functions/editProfile/editProfile";
import { responseMessageDispaly } from "../../utils/sharedfunction";

import useTimer from "../../utils/hooks/useTimer";

const BankDetails = (props) => {
  const [name, setName] = useState(props.user.userBank.bank_name);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOTP] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpBeforeEditing, setOtpBeforeEditing] = useState(false);

  const [accountNumber, setAccountNumber] = useState(
    props.user.userBank.account_number,
  );

  const [IBAN, setIBAN] = useState(props.user.userBank.iban_code);
  const [swiftCode, setSwiftCode] = useState(props.user.userBank.swift_code);
  const [address, setAddress] = useState(props.user.userBank.bank_address);
  const [otp, setOTP] = useState("");
  const [otpId, setOTPID] = useState("");
  const [showTimer, setSHowTimer] = useState(false);
  const uploadMSG = useRef(null);

  let [minutes, seconds, stopTimer, startTimer] = useTimer();

  useEffect(() => {
    sendOtp(true);
  }, []);

  const sendOtp = async () => {
    //

    setLoadingOTP(true);
    setOtpSent(true);
    uploadMSG.current.innerHTML = "Sending Otp..Please Wait";
    uploadMSG.current.style.color = "green";
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/sendEmailOTP`;

    const data = {
      user_id: props.user._id,
      email: props.user.email,
    };

    try {
      let res = await editPersonalDetails(url, data, props.token);
      setOTPID(res.data.body.otpId);
      uploadMSG.current.innerHTML = "Otp has been sent to your mail..";
      setSHowTimer(true);
      startTimer(3, 0);
      setLoadingOTP(false);
    } catch (err) {
      console.log(err);
      setLoadingOTP(false);
      responseMessageDispaly(uploadMSG, "Something went wrong ! retry", "red");
    }
  };

  const validateOTP = async () => {
    uploadMSG.current.innerHTML = "";
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/validateEmailOTP`;
    const data = {
      user_id: props.user._id,
      email: props.user.email,
      otp: otp,
      otpId: otpId,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      responseMessageDispaly(uploadMSG, "Verified", "green");
      stopTimer();
      setOtpSent(false);
      setLoading(false);
      setSHowTimer(false);
      setOTP("");
      setOtpBeforeEditing(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setOtpBeforeEditing(false);
      responseMessageDispaly(uploadMSG, err.message, "red");
    }
  };

  const validateOTPAndBank = async () => {
    uploadMSG.current.innerHTML = "";
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/validateOTPAndBank`;
    const data = {
      user_id: props.user._id,
      email: props.user.email,
      otp: otp,
      otpId: otpId,
      userBank: {
        bank_name: name,
        account_number: accountNumber,
        iban_code: IBAN,
        swift_code: swiftCode,
        bank_address: address,
      },
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      responseMessageDispaly(uploadMSG, "Updated", "green");
      props.userDetails();
      stopTimer();
      setOtpSent(false);
      setLoading(false);
      setSHowTimer(false);
      setOTP("");
    } catch (err) {
      console.log(err);
      setLoading(false);
      responseMessageDispaly(uploadMSG, err.message, "red");
    }
  };

  // const updateBank = async () => {
  //   setLoading(true);
  //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserBankDetails`;
  //   const data = {
  //     user_id: props.user._id,
  //     userBank: {
  //       bank_name: name,
  //       account_number: accountNumber,
  //       iban_code: IBAN,
  //       swift_code: swiftCode,
  //       bank_address: address,
  //     },
  //   };

  //   try {
  //     let res = await editPersonalDetails(url, data, props.token);
  //     setLoadingOTP(false);
  //     setLoading(false);
  //     responseMessageDispaly(uploadMSG, "Updated", "green");
  //     props.userDetails();
  //     // setTimeout(function () {
  //     //   props.setBankShow(false);
  //     // }, 2000);
  //   } catch (err) {
  //     setLoading(false);
  //     responseMessageDispaly(uploadMSG, err.message, "red");
  //     console.log(err.message);
  //   }
  // };

  return (
    <div className="editprofile-section">
      {console.log(minutes, seconds)}
      {loading && (
        <span className="loader-spiner loader-spiner-addtionl"></span>
      )}
      <div ref={uploadMSG} className="ml-3"></div>

      {otpSent && (
        <>
          <div className="text-danger flex-center">
            {minutes === "00" && seconds === "00" ? (
              <>
                {showTimer && (
                  <div style={{ color: "red" }} onClick={sendOtp}>
                    Not Recived ?{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Send Again
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>{showTimer && `${minutes} : ${seconds}  Time left`}</>
            )}
          </div>
          <div className="sub-menu-drop-down-navbar mt-2">
            <span>Enter Otp</span>
            <input
              placeholder="OTP"
              className="form-control input-md registration-from-control-border"
              type="text"
              value={otp}
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     validateOTP(true);
              //   }
              // }}
            />
          </div>
          <div className="flex-center mt-1 mr-1">
            {!otpBeforeEditing && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ background: "#21AEDD" }}
                onClick={validateOTP}
              >
                Verify
              </Button>
            )}

            {otpBeforeEditing && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ background: "#21AEDD" }}
                onClick={validateOTPAndBank}
              >
                update
              </Button>
            )}
          </div>
        </>
      )}

      {!otpSent && !loadingOtp && (
        <>
          <div className="sub-menu-drop-down-navbar">
            <span>Bank Name</span>
            <input
              placeholder=""
              className="form-control input-md registration-from-control-border"
              type="text"
              value={name}
              autoFocus
              onChange={(e) => {
                setName(e.target.value);
              }}
              disabled={otpBeforeEditing ? false : true}
            />
          </div>
          <div className="sub-menu-drop-down-navbar">
            <span> Account No</span>
            <input
              placeholder=""
              className="form-control input-md registration-from-control-border"
              type="text"
              value={accountNumber}
              onChange={(e) => {
                setAccountNumber(e.target.value);
              }}
              disabled={otpBeforeEditing ? false : true}
            />
          </div>
          <div className="sub-menu-drop-down-navbar">
            <span>IBAN Code</span>
            <input
              placeholder=""
              className="form-control input-md registration-from-control-border"
              type="text"
              value={IBAN}
              onChange={(e) => {
                setIBAN(e.target.value);
              }}
              disabled={otpBeforeEditing ? false : true}
            />
          </div>
          <div className="sub-menu-drop-down-navbar">
            <span>SWIFT Code</span>
            <input
              placeholder=""
              className="form-control input-md registration-from-control-border"
              type="text"
              value={swiftCode}
              onChange={(e) => {
                setSwiftCode(e.target.value);
              }}
              disabled={otpBeforeEditing ? false : true}
            />
          </div>
          <div className="sub-menu-drop-down-navbar">
            <span>Bank Address</span>
            <input
              placeholder=""
              className="form-control input-md registration-from-control-border"
              type="text"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              disabled={otpBeforeEditing ? false : true}
            />
          </div>

          <div className="flex-center mt-1 mr-1">
            {otpBeforeEditing && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ background: "#21AEDD" }}
                onClick={sendOtp}
              >
                Send OTP to update
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BankDetails;
