import React from 'react'

import '../css/editprofile/editprofile.css'

import EditProfileSections from '../Components/editProfile/EditProfileSections'

const EditProfile = () => {
  return (
    <>
      <div style={{ background: '#dfdfdf' }}>
        <div className='container'>
          <div className='row'>
            <div className='col align-self-center background-edit-profile '>
              <EditProfileSections />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProfile
