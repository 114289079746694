import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
const SuccessSection = ({ dispatch }) => {
  return (
    <div className='registration-model-container '>
      <div className='registration-modal-headers '>
        <h5 className='registration-modal-title success-green '>Success</h5>
        <h5>Please wait redirecting to profile...</h5>
      </div>
    </div>
  )
}

export default connect()(SuccessSection)
