import React, { useState } from "react";
import { Card, Skeleton, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FaPen } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import Audio from "../../images/Audio.jpeg";
import profileDefualt from "../../images/default.jpeg";
import ReactPlayer from "react-player";
import EditUserStoryModel from "../profile/EditUserStoryModel";
import { useEffect } from "react";
import "./storyCard.css";

const { Meta } = Card;

const weburl = process.env.REACT_APP_BACKEND_URL;

const SubStoryCard = ({
  story,
  iam,
  user,
  index,
  setShowAddStoryModel,
  setStoryToEdit,
}) => {
  const { FileUploadPercentage, StoryImageCoverURL } = useSelector(
    (state) => state,
  );

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#fc6203",
        margin: 5,
      }}
      spin
    />
  );

  console.log(story.stories);

  return (
    <>
      {story.story_type == "text" && (
        <div className="card-box">
          {story.thumbnail_url && (
            <Link to={`/story/${story.stories[0]._id}`}>
              <div>
                <img
                  style={{
                    height: "100%",
                    objectFit: "cover",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            </Link>
          )}
          <div style={{ display: "flex" }}>
            <Link to={`/story/${story.stories[0]._id}`}>
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            </Link>

            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/story/${story.stories[0]._id}`}>
                <div className="titile-card">
                  {story.stories[0].story_title}
                </div>
              </Link>
            </div>

            <div className="cursor-pointer">
              <FaPen
                onClick={() => {
                  setShowAddStoryModel(true);
                  setStoryToEdit(story);
                }}
              />
            </div>
          </div>
          {!story.thumbnail_url && (
            <Link to={`/story/${story.stories[0]._id}`}>
              <div
                className={`${
                  story.stories[0].language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box-with-no-image ml-2 mt-2`}
              >
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length <= 350 &&
                  story.stories[0].story_text}
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length > 350 &&
                  story.stories[0].story_text.substring(0, 600) + " ...."}
              </div>
            </Link>
          )}
          {story.thumbnail_url && (
            <Link to={`/story/${story.stories[0]._id}`}>
              <div
                className={`${
                  story.stories[0].language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box ml-2 mt-2`}
              >
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length <= 50 &&
                  story.stories[0].story_text}
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length > 50 &&
                  story.stories[0].story_text.substring(0, 200) + " ...."}
              </div>
            </Link>
          )}
        </div>
      )}

      {story.story_type == "image" && (
        <div className="card-box">
          <Link to={`/story/${story.stories[0]._id}`}>
            {story.thumbnail_url && (
              <div>
                <img
                  style={{
                    height: "100%",
                    objectFit: "cover",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>

          <div style={{ display: "flex" }}>
            <Link to={`/story/${story.stories[0]._id}`}>
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            </Link>
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/story/${story.stories[0]._id}`}>
                <div className="titile-card">
                  {story.stories[0].story_title}
                </div>
              </Link>
              <div className="cursor-pointer">
                <FaPen
                  onClick={() => {
                    setShowAddStoryModel(true);
                    setStoryToEdit(story);
                  }}
                />
              </div>
            </div>
          </div>
          {!story.thumbnail_url && (
            <Link to={`/story/${story.stories[0]._id}`}>
              <div
                className={`${
                  story.stories[0].language === "ar"
                    ? "text-right"
                    : "text-left"
                } ml-2 mt-2`}
              >
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length <= 350 &&
                  story.stories[0].story_text}
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length > 350 &&
                  story.stories[0].story_text.substring(0, 350) + " ...."}
              </div>
            </Link>
          )}
          {story.thumbnail_url && (
            <Link to={`/story/${story.stories[0]._id}`}>
              <div
                className={`${
                  story.stories[0].language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box ml-2 mt-2`}
              >
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length <= 50 &&
                  story.stories[0].story_text}
                {story.stories[0].story_text &&
                  story.stories[0].story_text.length > 50 &&
                  story.stories[0].story_text.substring(0, 200) + " ...."}
              </div>
            </Link>
          )}
        </div>
      )}

      {story.story_type == "audio" && (
        <div className="card-box">
          <Link to={`/story/${story.stories[0]._id}`}>
            <div>
              <img
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                  borderRadius: "10px",
                }}
                src={story.thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = Audio;
                }}
              />
            </div>
          </Link>
          <div style={{ display: "flex" }}>
            <Link to={`/story/${story.stories[0]._id}`}>
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            </Link>
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/story/${story.stories[0]._id}`}>
                <div className="titile-card">
                  {story.stories[0].story_title}
                </div>
              </Link>
              <div className="cursor-pointer">
                <FaPen
                  onClick={() => {
                    setShowAddStoryModel(true);
                    setStoryToEdit(story);
                  }}
                />
              </div>
            </div>
          </div>
          <Link to={`/story/${story.stories[0]._id}`}>
            <div
              className={`${
                story.stories[0].language === "ar" ? "text-right" : "text-left"
              } description-box ml-2  mt-2`}
            >
              {story.stories[0].story_text &&
                story.stories[0].story_text.length <= 50 &&
                story.stories[0].story_text}
              {story.stories[0].story_text &&
                story.stories[0].story_text.length > 50 &&
                story.stories[0].story_text.substring(0, 200) + " ...."}
            </div>
          </Link>
        </div>
      )}

      {story.story_type == "video_url" && (
        <div className="card-box">
          <ReactPlayer
            url={story.stories[0].story_url}
            controls
            width="100%"
            height="100%"
          />

          <div style={{ display: "flex" }}>
            <Link to={`/story/${story.stories[0]._id}`}>
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            </Link>
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/story/${story.stories[0]._id}`}>
                <div className="titile-card">
                  {story.stories[0].story_title}
                </div>
              </Link>
              <div className="cursor-pointer">
                <FaPen
                  onClick={() => {
                    setShowAddStoryModel(true);
                    setStoryToEdit(story);
                  }}
                />
              </div>
            </div>
          </div>
          <Link to={`/story/${story.stories[0]._id}`}>
            <div
              className={`${
                story.stories[0].language === "ar" ? "text-right" : "text-left"
              } description-box ml-2  mt-2`}
            >
              {story.stories[0].story_text &&
                story.stories[0].story_text.length <= 50 &&
                story.stories[0].story_text}
              {story.stories[0].story_text &&
                story.stories[0].story_text.length > 50 &&
                story.stories[0].story_text.substring(0, 200) + " ...."}
            </div>
          </Link>
        </div>
      )}

      {story.story_type == "video" && (
        <>
          {index === 0 &&
            FileUploadPercentage.uploadVideoMeta.isVideoUploading === "YES" && (
              <div className="card-box">
                <div>
                  <img
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    src={StoryImageCoverURL.cover.URL}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <Spin indicator={antIcon} />
                  <div style={{ marginLeft: "8px" }}>
                    {FileUploadPercentage.uploadVideoMeta
                      .videoUploadPercentage > 0 && (
                      <div>
                        <span className="loading ml-2 mr-2">Uploading... </span>
                        <span>
                          {
                            FileUploadPercentage.uploadVideoMeta
                              .videoUploadPercentage
                          }
                        </span>
                        <span className="ml-2">%</span>
                      </div>
                    )}
                    {FileUploadPercentage.uploadVideoMeta
                      .videoprocessingUpload === true && (
                      <div className="ml-1" style={{ color: "black" }}>
                        <span className="loading ml-2">Processing...</span>
                      </div>
                    )}
                  </div>
                  <div className="cursor-pointer">
                    <FaPen
                      onClick={() => {
                        setShowAddStoryModel(true);
                        setStoryToEdit(story);
                      }}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: "35px" }}>
                  <div className="titile-card">
                    {story.stories[0].story_title}
                  </div>
                </div>
              </div>
            )}

          {index == 0 &&
            FileUploadPercentage.uploadVideoMeta.videoUploadPercentage === 0 &&
            FileUploadPercentage.uploadVideoMeta.videoprocessingUpload ===
              false && (
              <div className="card-box">
                <Link to={`/story/${story.stories[0]._id}`}>
                  <div>
                    <img
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                      src={story.thumbnail_url}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  </div>
                </Link>
                <div style={{ display: "flex" }}>
                  <Link to={`/story/${story.stories[0]._id}`}>
                    <img
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        float: "left",
                        marginTop: "12px",
                      }}
                      src={story.user_id.imageUrl}
                      alt="im-story-profile"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = profileDefualt;
                      }}
                    />
                  </Link>
                  <div
                    style={{
                      marginLeft: "8px",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Link to={`/story/${story.stories[0]._id}`}>
                      <div>
                        <div className="titile-card">
                          {story.stories[0].story_title}
                        </div>
                      </div>
                    </Link>
                    <div className="cursor-pointer">
                      <FaPen
                        onClick={() => {
                          setShowAddStoryModel(true);
                          setStoryToEdit(story);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Link to={`/story/${story.stories[0]._id}`}>
                  <div
                    className={`${
                      story.stories[0].language === "ar"
                        ? "text-right"
                        : "text-left"
                    } description-box ml-2  mt-2`}
                  >
                    {story.stories[0].story_text &&
                      story.stories[0].story_text.length <= 50 &&
                      story.stories[0].story_text}
                    {story.stories[0].story_text &&
                      story.stories[0].story_text.length > 50 &&
                      story.stories[0].story_text.substring(0, 200) + " ...."}
                  </div>
                </Link>
              </div>
            )}

          {index > 0 && (
            <div className="card-box">
              <Link to={`/story/${story.stories[0]._id}`}>
                <div>
                  <img
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
              </Link>
              <div style={{ display: "flex" }}>
                <Link to={`/story/${story.stories[0]._id}`}>
                  <img
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      float: "left",
                      marginTop: "12px",
                    }}
                    src={story.user_id.imageUrl}
                    alt="im-story-profile"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = profileDefualt;
                    }}
                  />
                </Link>
                <div
                  style={{
                    marginLeft: "8px",
                    position: "relative",
                    width: "100%",
                  }}
                  className="cursor-pointer"
                >
                  <Link to={`/story/${story.stories[0]._id}`}>
                    <div>
                      <div className="titile-card">
                        {story.stories[0].story_title}
                      </div>
                    </div>
                  </Link>
                  <div className="cursor-pointer">
                    <FaPen
                      onClick={() => {
                        setShowAddStoryModel(true);
                        setStoryToEdit(story);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Link to={`/story/${story.stories[0]._id}`}>
                <div
                  className={`${
                    story.stories[0].language === "ar"
                      ? "text-right"
                      : "text-left"
                  } description-box ml-2 mt-2`}
                >
                  {story.stories[0].story_text &&
                    story.stories[0].story_text.length <= 50 &&
                    story.stories[0].story_text}
                  {story.stories[0].story_text &&
                    story.stories[0].story_text.length > 50 &&
                    story.stories[0].story_text.substring(0, 200) + " ...."}
                </div>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SubStoryCard;
