import React from "react";

import Chat2 from "./Chat2";

const ChatPage = (props) => {
  return (
    <div className="container-fluid p-o" style={{ overflow: "hidden" }}>
      <div className="row">
        <div className="col-md-12">
          <Chat2 {...props} />
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
