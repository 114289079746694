import React, { useState } from "react";
import MaintenanceStory from "./MaintenanceStory";
import Dashbored from "./Dashbored";
import "../css/Global/admin.css";

const SuperAdmin = ({ user }) => {
  const [menu, setmenu] = useState("DASH");
  return (
    <>
      <div className="admin-navbar">
        <div className="admin-navbar-menu" onClick={() => setmenu("DASH")}>
          Dashbored
        </div>
        <div className="admin-navbar-menu" onClick={() => setmenu("MAINTAIN")}>
          Maintence
        </div>
      </div>
      {menu === "MAINTAIN" && (
        <div>
          <MaintenanceStory user={user} />
        </div>
      )}
      {menu === "DASH" && (
        <div>
          <Dashbored user={user} />
        </div>
      )}
    </>
  );
};

export default SuperAdmin;
