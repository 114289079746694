import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import EditProfilePicSection from './editSections/EditProfilePicSection'
import EditPersonalDetailsSection from './editSections/EditPersonalDetailsSection'
import EditLangaugeSection from './editSections/EditLangaugeSection'
import EditCategorySection from './editSections/EditCategorySection'
import EditAccountTypeSection from './editSections/EditAccountTypeSection'
import EditBankSection from './editSections/EditBankSection'
import EditChangePassword from './editSections/EditChangePassword'
import { toast } from 'react-toastify'
import { getUserDetails } from '../../functions/editProfile/editProfile'

import './editProfileSections.css'

const EditProfileSections = (props) => {
  const [userProps, setUserProps] = useState({})
  useEffect(() => {
    userDetails()
  }, [])

  const userDetails = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getUserDetails`
      const data = {
        user_id: props.user._id,
      }
      const res = await getUserDetails(url, data, props.token)
      setUserProps({ user: res.data.body.user, token: props.token })

      props.dispatch({
        type: 'userUpdate',
        payload: res.data.body.user,
      })
    } catch (err) {
      console.log(err.message)
      toast.error(err.message)
    }
  }

  return (
    <div className='padding-10'>
      {userProps.user && (
        <>
          <EditProfilePicSection {...userProps} />
          <EditPersonalDetailsSection
            {...userProps}
            userDetails={userDetails}
          />
          <EditChangePassword {...userProps} userDetails={userDetails} />
          <EditAccountTypeSection {...userProps} userDetails={userDetails} />
          <EditLangaugeSection {...userProps} setUserProps={setUserProps} />
          <EditCategorySection {...userProps} />
          <EditBankSection {...userProps} />
        </>
      )}
    </div>
  )
}
const MapStateToProp = ({ BasicUserReducers: { user } }) => {
  return { user: user.loginUser, token: user.token }
}
export default connect(MapStateToProp)(EditProfileSections)
