import React, { useState, useRef } from "react";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import Button from "@material-ui/core/Button";
import { editPersonalDetails } from "../../functions/editProfile/editProfile";
import { responseMessageDispaly } from "../../utils/sharedfunction";

const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const uploadMSG = useRef(null);

  const handleSubmit = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/changePassword`;
    const data = {
      new_password: newPassword,
      oldPassword: oldPassword,
      user_id: props.user._id,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      props.userDetails();
      setLoading(false);
      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
      //toast.success('Personal details has updated')
      responseMessageDispaly(uploadMSG, "success", "green");
      setTimeout(function () {
        props.setPasswordShow(false);
      }, 2000);
    } catch (err) {
      console.log(err.message);
      responseMessageDispaly(uploadMSG, err.message, "red");
      // if (err.response.data.message.includes('old password mismatch')) {
      //   responseMessageDispaly(uploadMSG, 'old password mismatch', 'red')
      //   toast.error('old password mismatch')
      // } else {
      //   responseMessageDispaly(uploadMSG, err.response.data.message, 'red')
      //   toast.error(err.message)
      // }
      setLoading(false);
    }
  };
  return (
    <div className="editprofile-section">
      {loading && (
        <span className="loader-spiner loader-spiner-addtionl"></span>
      )}
      <div ref={uploadMSG} className="ml-3"></div>
      <div className="sub-menu-drop-down-navbar">
        <span>Old Password</span>
        <input
          name="name"
          placeholder="Old password"
          className="form-control input-md registration-from-control-border"
          type="text"
          value={oldPassword}
          autoFocus
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
        />
      </div>
      <div className="sub-menu-drop-down-navbar">
        <span>New Password</span>
        <input
          name="email"
          placeholder="New password"
          className="form-control input-md registration-from-control-border"
          type="text"
          autoFocus
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
      </div>
      <div className="sub-menu-drop-down-navbar">
        <span>Confirm Password</span>
        <input
          name="email"
          placeholder="Confirm password"
          className="form-control input-md registration-from-control-border"
          type="text"
          autoFocus
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
      </div>
      <div className="flex-end mt-1 mr-1">
        <Button
          variant="contained"
          color="secondary"
          style={{ background: "#21AEDD" }}
          size="small"
          onClick={handleSubmit}
        >
          update
        </Button>
      </div>
    </div>
  );
};

export default ChangePassword;
