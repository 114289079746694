const defaultState = {
  isNavModalOpen: false,
  formLoginPage: false,
  redirect: "",
  userData: {},
};

const NavbarReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "OpenNavModel":
      return {
        ...state,
        isNavModalOpen: true,
        formLoginPage: false,
        redirect: action.payload,
      };
    case "CloseNavModel":
      return {
        ...state,
        isNavModalOpen: false,
        formLoginPage: false,
        redirect: "",
      };

    case "OpenNavModelFromLoginPage":
      return {
        ...state,
        isNavModalOpen: true,
        formLoginPage: true,
        redirect: action.payload,
      };
    case "userData":
      return { ...state, userData: action.payload };
    default:
      return state;
  }
};

export default NavbarReducer;
