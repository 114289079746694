let initialState = {
  user: {},
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case "user":
      return {
        ...state,
        user: action.payload,
      };
    case "breif":
      const newState = { ...state };
      newState.user.loginUser.briefCV[action.payload.index] =
        action.payload.briefcv;
      newState.user.loginUser.briefCV[action.payload.index].language_id =
        action.payload.language_id;
      localStorage.setItem("userDataSessionWay", JSON.stringify(newState.user));
      return newState;
    case "breifNew":
      const newStateBrief = { ...state };
      newStateBrief.user.loginUser.briefCV = action.payload;
      localStorage.setItem(
        "userDataSessionWay",
        JSON.stringify(newStateBrief.user),
      );
      return newStateBrief;
    case "updateLanguage":
      const newStateUpdateLanguage = { ...state };
      newStateUpdateLanguage.user.loginUser.userLangauges = action.payload;
      console.log("updateLanguage", newStateUpdateLanguage.user);
      localStorage.setItem(
        "userDataSessionWay",
        JSON.stringify(newStateUpdateLanguage.user),
      );
      return newStateUpdateLanguage;
    case "profileImage":
      const updateState = { ...state };
      updateState.user.loginUser.imageUrl = action.payload;
      localStorage.setItem(
        "userDataSessionWay",
        JSON.stringify(updateState.user),
      );
      return updateState;
    case "profileCover":
      const updatecoverState = { ...state };
      updatecoverState.user.loginUser.cover = action.payload;
      localStorage.setItem(
        "userDataSessionWay",
        JSON.stringify(updatecoverState.user),
      );
      return updatecoverState;

    case "userUpdate":
      const userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
      const updateuserState = {
        ...state,
      };
      updateuserState.user.loginUser = action.payload;
      updateuserState.user.token = userData.token;
      updateuserState.user.Following = userData.Following;
      updateuserState.user.Followers = userData.Followers;

      localStorage.setItem(
        "userDataSessionWay",
        JSON.stringify(updateuserState.user),
      );
      return updateuserState;
    default:
      return state;
  }
};

export default User;
