const initialState = {
  component: "HOME_STORIES",
};

const BasicHomePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "COMPOENET_CHANGE":
      return action.payload;
    default:
      return state;
  }
};

export default BasicHomePageReducers;
