import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import SectionTypeCourse from "./SectionTypeCourse";

import {
  getDataPostWithOutHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

const url = process.env.REACT_APP_BACKEND_URL;

const CourseIntro = ({ user }) => {
  const [textShowMore, setTextShowMore] = useState(false);
  const [briefShoMore, setBriefShowMore] = useState(false);
  const params = useParams();
  let subscription_id = params.id;

  const [course, setCourse] = useState([]);
  const [sections, setSections] = useState([]);
  const [stories, setStories] = useState([]);
  const [isHeAddedToTheCart, setIsheAddedToTheCart] = useState(false);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [learnTextShowMore, setLearnTextShowMore] = useState(false);
  const [
    storiesTotalCountAndSecAndSectionTotalCount,
    setStoriesTotalCountAndSecAndSectionTotalCount,
  ] = useState({});

  useEffect(() => {
    const getSubscriptionGroup = async () => {
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getSubscriptionGroup?subscription_id=${subscription_id}${
        user.userData?.loginUser?._id
          ? `&user_id=${user.userData.loginUser._id}`
          : ""
      }`;

      try {
        let res = await getRequestedData(url);

        console.log(res.data.body.GetSusbcription, "course");
        setCourse(res.data.body.GetSusbcription);
        setIsheAddedToTheCart(res.data.body.isHeAddedToTheCart);
        setIsWishlisted(res.data.body.isWishlisted);
        setStoriesTotalCountAndSecAndSectionTotalCount(
          res.data.body.storiesTotalCountAndSecAndSectionTotalCount,
        );

        if (res.data.body.GetSusbcription.section_type === 1) {
          setSections(res.data.body.section);
          setStories([]);
        } else {
          setStories(res.data.body.stories);
          setSections([]);
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    };

    getSubscriptionGroup();
  }, [user]);

  return (
    <>
      <SectionTypeCourse
        course={course}
        sections={sections}
        stories={stories}
        isHeAddedToTheCart={isHeAddedToTheCart}
        isWishlisted={isWishlisted}
        setIsWishlisted={setIsWishlisted}
        storiesCount={storiesTotalCountAndSecAndSectionTotalCount}
      />
    </>
  );
};

export default CourseIntro;
