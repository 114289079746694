import React, { useState, useEffect } from "react";

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk
const MAX_RETRIES = 3; // Maximum number of retries for failed parts
const MAX_CONCURRENT_UPLOADS = 2; // Maximum number of parallel uploads

const MutiPartFileUpload = ({
  file,
  user,
  progress,
  setProgress,
  setID,
  setUplaodFileUrl,
  keyString,
  updateAfterUpload,
}) => {
  const [error, setError] = useState(null);
  const [networkError, setNetworkError] = useState(false); // State for network errors
  const [uploadId, setUploadId] = useState(null);
  const [completedParts, setCompletedParts] = useState([]);
  const [isResumable, setIsResumable] = useState(false);
  const [keyName, setKeyName] = useState("");
  const [uploadFinshed, setUploadFinshed] = useState(false);

  useEffect(() => {
    //console.log(user, "user");
    setUploadFinshed(false);
    uploadFile(file);
  }, [file]);

  // Function to save upload progress in localStorage
  const saveProgress = (key, uploadId, completedParts) => {
    localStorage.setItem(key, JSON.stringify({ uploadId, completedParts }));
  };

  // Function to load upload progress from localStorage
  const loadProgress = (key) => {
    const savedProgress = localStorage.getItem(key);
    return savedProgress ? JSON.parse(savedProgress) : null;
  };

  // Function to check network status
  const isNetworkAvailable = () => navigator.onLine;

  // Function to upload a part with retry logic and network error handling
  const uploadPart = async (presignedUrl, chunk, partNumber, retries = 0) => {
    console.log(isNetworkAvailable(), "network");

    // Check for network availability before attempting the upload
    if (!isNetworkAvailable()) {
      setNetworkError(true);
      throw new Error("Network disconnected. Please check your connection.");
    }

    try {
      const uploadPartResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: chunk,
      });

      if (!uploadPartResponse.ok) {
        throw new Error(
          `Failed to upload part ${partNumber}. HTTP Status: ${uploadPartResponse.status}`
        );
      }

      // Collect ETag for the uploaded part, ensure it's not null
      const eTag = uploadPartResponse.headers.get("ETag");

      if (!eTag) {
        throw new Error(`ETag not returned for part ${partNumber}`);
      }

      // Return the processed eTag
      return { ETag: eTag.replace(/"/g, ""), PartNumber: partNumber };
    } catch (err) {
      console.error(`Error in part ${partNumber}:`, err.message);

      // Retry logic for failed parts
      if (retries < MAX_RETRIES) {
        console.log(
          `Retrying part ${partNumber} (${retries + 1}/${MAX_RETRIES})`
        );
        return uploadPart(presignedUrl, chunk, partNumber, retries + 1);
      } else {
        // If maximum retries are reached, set network error and throw
        setNetworkError(true);
        throw new Error(
          `Failed to upload part ${partNumber} after ${MAX_RETRIES} retries: ${err.message}`
        );
      }
    }
  };

  // Function to upload the file
  const uploadFile = async (file) => {
    setError(null);
    setNetworkError(false);
    setProgress(1);

    const savedProgress = loadProgress(`${keyString}/${file.name}`);
    let savedUploadId = savedProgress?.uploadId || null;
    let savedCompletedParts = savedProgress?.completedParts || [];
    let fileURL_Located = "";

    try {
      let currentUploadId = savedUploadId;
      if (!currentUploadId) {
        const uploadResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${
            keyString === "userFiles/storyFiles"
              ? "createMultipartUploadStory"
              : "createMultipartUpload"
          }`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`, // Add Bearer token
            },
            body: JSON.stringify({
              keyName: `${keyString}/${file.name}`,
              user_id: user.loginUser._id,
            }),
          }
        );

        if (uploadResponse.status === 401) {
          throw new Error("Unauthorized. Please log in again.");
        }

        const response = await uploadResponse.json();

        console.log(response);
        currentUploadId = response.body.uploadId;
        setKeyName(`${keyString}/${file.name}`);
        setUploadId(response.body.uploadId);
        saveProgress(`${keyString}/${file.name}`, response.body.uploadId, []);
        if (setID) setID(response.body.savedDoc._id);
        setUplaodFileUrl(response.body.fileUrl);
        fileURL_Located = response.body.fileUrl;
      } else {
        setUploadId(currentUploadId);
        setCompletedParts(savedCompletedParts);
        setIsResumable(true);
      }

      const parts = [...savedCompletedParts];
      const totalParts = Math.ceil(file.size / CHUNK_SIZE);

      const uploadPromises = [];

      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        if (parts.some((part) => part.PartNumber === partNumber)) {
          continue;
        }

        const start = (partNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        const presignedUrlResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getMultiPartpresignedUrl?keyName=${keyString}/${file.name}&uploadId=${currentUploadId}&partNumber=${partNumber}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`, // Add Bearer token
            },
          }
        );

        if (presignedUrlResponse.status === 401) {
          throw new Error("Unauthorized. Please log in again.");
        }

        const responseUrl = await presignedUrlResponse.json();

        console.log(responseUrl, "responseUrl");

        const uploadPromise = uploadPart(
          responseUrl.body.presignedUrl,
          chunk,
          partNumber
        ).then((part) => {
          parts.push(part);
          setCompletedParts(parts);
          saveProgress(`${keyString}/${file.name}`, currentUploadId, parts);
          setProgress((parts.length / totalParts) * 100);
        });

        uploadPromises.push(uploadPromise);

        if (uploadPromises.length === MAX_CONCURRENT_UPLOADS) {
          await Promise.all(uploadPromises);
          uploadPromises.length = 0;
        }
      }

      await Promise.all(uploadPromises);

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/completeMultipartUpload`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`, // Add Bearer token
          },
          body: JSON.stringify({
            keyName: `${keyString}/${file.name}`,
            uploadId: currentUploadId,
            parts,
          }),
        }
      );

      localStorage.removeItem(`${keyString}/${file.name}`);
      setProgress(0);
      setUploadFinshed(true);

      setTimeout(() => {
        setUploadFinshed(false);
      }, 3000); // 3000 milliseconds = 3 seconds

      if (updateAfterUpload) updateAfterUpload(fileURL_Located);
    } catch (err) {
      if (err.message === "Unauthorized. Please log in again.") {
        setError(err.message);
        // Optionally, redirect to login or refresh token
      } else {
        setError(err.message);
      }
    }
  };

  const handleResumeUpload = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput.files[0]) {
      uploadFile(fileInput.files[0]);
    }
  };

  return (
    <div>
      {networkError && (
        <p style={{ color: "red" }}>
          Network disconnected. Please check your connection.
        </p>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* {progress > 0 && <p>Upload Progress: {Math.round(progress)}%</p>} */}

      {progress > 0 && (
        <div className="row mt-3">
          <div className="col-md-12 pt-1">
            <div className="progress">
              <div
                className="progress-bar bg-success progress-bar-striped"
                role="progressbar"
                style={{
                  width: `${progress}%`,
                  height: "100%",
                }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          </div>
        </div>
      )}

      {progress === 0 && uploadFinshed && (
        <div style={{ color: "green" }}>Upload Finished</div>
      )}

      {/* {isResumable && (
        <button onClick={handleResumeUpload}>Resume Upload</button>
      )} */}
    </div>
  );
};

export default MutiPartFileUpload;
