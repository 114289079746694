import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Space, Button } from 'antd'
import { BsArrowLeft } from 'react-icons/bs'

import SessionCardMobile from '../../Components/cards/SessionCardMobile'
const { Search } = Input

const AllSubscription = ({ getUserStory, setAllusers }) => {
  const { Followers } = useSelector((state) => ({
    ...state,
  }))

  const callUserStory = (user_id) => {
    getUserStory(user_id, 1)
    setAllusers(false)
  }

  return (
    <>
      <div style={{ marginTop: '10px', display: 'flex', marginBottom: '10px' }}>
        <BsArrowLeft
          size={20}
          onClick={() => {
            setAllusers(false)
          }}
          className='cursor-pointer'
        />
        <Search placeholder='Search' size='large' className='ml-2' />
      </div>

      {Followers.user_followers.length > 0 &&
        Followers.user_followers.map((list, index) => {
          return (
            <div
              key={index}
              onClick={() => callUserStory(list.follower_user_id._id)}
              className='cursor-pointer'
            >
              <SessionCardMobile followers={list} />
            </div>
          )
        })}
    </>
  )
}

export default AllSubscription
