import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import profileDefualt from "../../images/default.jpeg";
import readShare from "../../images/Read&ShareThumbnail.png";

const weburl = process.env.REACT_APP_BACKEND_URL;
const ArticlesCard = (props) => {
  return (
    <>
      <div
        className="p-3 serach-result d-md-block d-none d-sm-block"
        style={{ cursor: "pointer" }}
      >
        <div>
          <div className="row">
            <div className="col-md-5">
              {props.story.thumbnail_url && (
                <Link
                  //to={`/consultant-profile?user_id=${props.searched_user._id}`}
                  to={
                    props.story.story_type == "articles"
                      ? `/article/${props.story._id}`
                      : `/science-research/${props.story._id}`
                  }
                  style={{ color: "black" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={props.story.thumbnail_url}
                      alt="im-story-profile"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = profileDefualt;
                      }}
                    />
                  </div>
                </Link>
              )}

              {!props.story.thumbnail_url && (
                <Link
                  //to={`/consultant-profile?user_id=${props.searched_user._id}`}
                  to={
                    props.story.story_type == "articles"
                      ? `/article/${props.story._id}`
                      : `/science-research/${props.story._id}`
                  }
                  style={{ color: "black", cursor: "pointer" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      style={{
                        borderRadius: "50%",
                      }}
                      src={props.story.user_id.imageUrl}
                      alt="im-story-profile"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = profileDefualt;
                      }}
                    />
                  </div>
                </Link>
              )}
            </div>
            <div className="col-md-7 text-center-mobile expert-text-container">
              <Link
                to={
                  props.story.story_type == "articles"
                    ? `/article/${props.story._id}`
                    : `/science-research/${props.story._id}`
                }
                //to={`/consultant-profile?user_id=${props.searched_user._id}`}
                style={{ color: "black" }}
              >
                <div style={{ fontWeight: "700", fontSize: "20px" }}>
                  {props.story.story_title}
                </div>
                <div
                  style={{
                    color: "#606060",
                    marginTop: "6px",
                    fontSize: "12px",
                  }}
                >
                  {props.story.categories &&
                    props.story.categories[0].category_name}
                </div>
                <div
                  style={{
                    color: "#606060",
                    marginTop: "6px",
                    fontSize: "12px",
                  }}
                  className="text-truncate-verticly"
                >
                  {parse(props.story.story_text)}
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div style={{ clear: "both" }}></div>
      </div>
      <Link
        to={
          props.story.story_type == "articles"
            ? `/article/${props.story._id}`
            : `/science-research/${props.story._id}`
        }
        style={{ color: "black" }}
        className="mobile-story-card-details"
      >
        <div className="story-card-wrapper">
          <div className="story-image-container">
            <img
              src={props.story.thumbnail_url}
              alt="im-story-profile"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = readShare;
              }}
            />
          </div>
          <div class="story-description-card">
            <h2 class="title"> {props.story.story_title}</h2>
            <p class="category">
              {props.story.categories &&
                props.story.categories[0].category_name}
            </p>
            <p class="text">{parse(props.story.story_text)}</p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ArticlesCard;
