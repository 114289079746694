import React, { useState } from "react";
import { toast } from "react-toastify";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import "./sidebarremarks.css";
const SidebarRemarksToDo = ({
  closeNav,
  setChatRemarks,
  user_id,
  token,
  client_id,
  user,
}) => {
  const [remark, setRemarks] = useState("");
  const [todo, setTodo] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const addChatRemark = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/addChatRemarksTodo`;
    const data = {
      client_id: client_id,
      user_id: user_id,
      remarks_text: remark,
      type: type,
      alertDate: date.$d,
      chat_session_id: user.chat_session_id,
    };
    try {
      let res = await getDataPostWithHeader(url, data, token);
      setChatRemarks(res.data.body);
      closeNav();
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };
  return (
    <>
      {type === "" && (
        <div
          className="closebtn cursor-pointer"
          onClick={() => {
            closeNav();
          }}
        >
          &times;
        </div>
      )}

      {type === "" && (
        <>
          <div className="mt-3" onClick={() => setType("remarks")}>
            Remarks
          </div>
          <div className="mt-3" onClick={() => setType("todo")}>
            To Do
          </div>
        </>
      )}

      {type === "remarks" && (
        <div className="mt-3">
          <div
            className="text-box"
            style={{ width: "100%", position: "relative" }}
          >
            <label for="Title" title="Title">
              Remarks
            </label>
            <div
              className="closebtnRemarks cursor-pointer"
              onClick={() => {
                setType("");
              }}
            >
              &times;
            </div>

            <br />
            <textarea
              id="Title"
              name="Title"
              placeholder="Enter Remarks"
              className="story-title"
              style={{ height: "calc(86vh - 250px)", resize: "none" }}
              value={remark}
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
          <div style={{ float: "right" }}>
            <button className="btn btn-primary mt-2" onClick={addChatRemark}>
              Add
            </button>
          </div>
        </div>
      )}
      {type === "todo" && (
        <div className="mt-3">
          <div style={{ width: "100%", position: "relative" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Alert Date Time"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
              />
            </LocalizationProvider>
            <div
              className="closebtnRemarks cursor-pointer"
              onClick={() => {
                setType("");
              }}
            >
              &times;
            </div>
          </div>
          <div
            className="text-box mt-2"
            style={{ width: "100%", position: "relative" }}
          >
            <label for="Title" title="Title">
              To Do
            </label>
            <br />
            <textarea
              id="Title"
              name="Title"
              placeholder="Enter Remarks"
              className="story-title"
              style={{ height: "calc(86vh - 300px)", resize: "none" }}
              value={remark}
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
          <div style={{ float: "right" }}>
            <button className="btn btn-primary mt-2" onClick={addChatRemark}>
              Add
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarRemarksToDo;
