const defaultState = {
  userLangauges: [],
  briefCV: [],
}

const RegFirstSectionReducers = (state = defaultState, action) => {
  switch (action.type) {
    case 'addlanguage':
      return {
        ...state,
        userLangauges: action.payload.langauge,
        briefCV: action.payload.briefCV,
      }
    default:
      return state
  }
}

export default RegFirstSectionReducers
