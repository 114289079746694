import React, { useState } from "react";
import CourseCardwithAddtoCart from "../cards/CourseCardwithAddtoCart";

const Courses = ({ coursesP, user }) => {
  return (
    <>
      {coursesP.length > 0 &&
        coursesP.map((Course) => {
          return (
            <CourseCardwithAddtoCart
              Course={Course.eachCourse}
              CourseCreatedByUser={Course.courseCreatedUser}
              key={Course._id}
              user={user}
              isPurchased={Course.isCoursePurchased}
            />
          );
        })}
    </>
  );
};

export default Courses;
