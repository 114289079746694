import React, { useState, useEffect } from "react";
import UserCard from "../../Components/cards/UserCard";
import StoriesSearchCard from "../../Components/cards/StoriesSearchCard";
import StoriesSearchCardMobile from "../../Components/cards/StoriesSearchCardMobile";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getDataPostWithOutHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

const SearchResult = ({
  loginUser,
  loadingSearchUser,
  tagtype,
  searchfromMobile,
  setSelectedStory,
  setSearchStories,
}) => {
  const [storyPage, setStoryPage] = useState(2);
  const [totalStoryPage, setTotalStoryPage] = useState();

  const { searchUsers } = useSelector((state) => state);

  const dispatch = useDispatch();

  console.log("tagtype", tagtype);

  useEffect(() => {
    if (
      searchUsers.search.Total_User_Page >=
      searchUsers.search.Total_Stories_Page
    ) {
      setTotalStoryPage(searchUsers.search.Total_User_Page);
    } else {
      setTotalStoryPage(searchUsers.search.Total_Stories_Page);
    }
  }, [searchUsers]);

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        searchUserHandler(storyPage);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  const searchUserHandler = async (storyPageNo) => {
    if (searchUsers.search.text) {
      setStoryPage(storyPageNo + 1);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/searchUser?search_text=${searchUsers.search.text}&user_id=${loginUser.userData.loginUser._id}&page=${storyPageNo}&tagtype=${tagtype}`;
      try {
        let res = await getRequestedData(url);
        console.log(res.data.body);
        dispatch({ type: "SEARCH_STORIES_APPEND", payload: res.data.body });
        //setTotalStoryPage(res.data.body)
        if (res.data.body.Total_User_Page >= res.data.body.Total_Stories_Page) {
          setTotalStoryPage(res.data.body.Total_User_Page);
        } else {
          setTotalStoryPage(res.data.body.Total_Stories_Page);
        }
        console.log(res.data.body);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };
  return (
    <>
      {loadingSearchUser && (
        <div className="flex-center">
          <span className="loader-spiner"></span>
        </div>
      )}

      {!loadingSearchUser &&
        searchUsers.search.usersAndStories &&
        searchUsers.search.usersAndStories.length > 0 && (
          <div>
            {searchUsers.search.usersAndStories.map((usersAndStories, i) => {
              if (
                usersAndStories.story ||
                usersAndStories.expert ||
                usersAndStories.article ||
                usersAndStories.scienceandresearch ||
                usersAndStories.profile
              ) {
                return (
                  <div className=" mt-3" key={usersAndStories._id}>
                    {!searchfromMobile && !usersAndStories.profile && (
                      <StoriesSearchCard
                        story={
                          usersAndStories.story ||
                          usersAndStories.expert ||
                          usersAndStories.article ||
                          usersAndStories.scienceandresearch
                        }
                        tag_type={usersAndStories.tag_type}
                      />
                    )}

                    {searchfromMobile && !usersAndStories.profile && (
                      <StoriesSearchCardMobile
                        story={
                          usersAndStories.story ||
                          usersAndStories.expert ||
                          usersAndStories.article ||
                          usersAndStories.scienceandresearch
                        }
                        setSelectedStory={setSelectedStory}
                        setSearchStories={setSearchStories}
                        tag_type={usersAndStories.tag_type}
                      />
                    )}

                    {usersAndStories.profile && (
                      <UserCard
                        user={loginUser}
                        profile={usersAndStories.profile}
                      />
                    )}
                    <hr />
                  </div>
                );
              }
            })}
          </div>
        )}
    </>
  );
};

export default SearchResult;
