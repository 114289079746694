import React from 'react'

import logo from '../../images/sessionway.svg'

const LogoLodingCard = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 210px)',
        }}
      >
        <img
          style={{
            width: '120px',
            height: '120px',
          }}
          src={logo}
          alt='im-story-profile'
        />
      </div>

      <div style={{ color: '#18A8D9' }}>From</div>
      <div style={{ color: '#18A8D9', fontWeight: 'bold' }}>ItegrityPro</div>
    </div>
  )
}

export default LogoLodingCard
