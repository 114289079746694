const initialState = {
  cover: {
    isThereCover: false,
    URL: "",
  },
};

const StoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COVEREXIST":
      return {
        ...state,
        cover: action.payload,
      };

    default:
      return state;
  }
};

export default StoriesReducer;
