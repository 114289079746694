const initialState = {
  cartTotal: 0,
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CARTTOTAL":
      return { cartTotal: action.payload };
    case "CARTTOTALAPPEND":
      let cartT = state.cartTotal + action.payload;
      return { cartTotal: cartT };
    default:
      return state;
  }
};

export default CartReducer;
