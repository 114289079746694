import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import Carousel from "react-elastic-carousel";

import { useDispatch, useSelector } from "react-redux";

import "../../css/home.css";
import "../../pages/Carousel.css";
import {
  getDataPostWithOutHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

import LoadingCard from "../../Components/cards/LoadingCard";
import LogoLodingCard from "../../Components/cards/LogoLodingCard";
import StoryCard from "../../Components/cards/StoryCard";
import DropDownMenu from "./DropDownMenu";

import SearchResult from "../home/SearchResult";

const UserStoriesDisplay = (props) => {
  const [category, setCategory] = useState("");
  const [showSubscriptionMobile, setShowSubscriptionMobile] = useState(false);
  const subAreaHomeMobileRef = useRef(null);

  const dispatch = useDispatch();
  const { AllStories, CatStories, searchUsers, UserRequiredDropDown } =
    useSelector((state) => ({
      ...state,
    }));

  const handleScrolListner1 = () => {
    if (searchUsers.search.usersAndStories.length <= 0) {
      const TotalPage = props.totalStoryPage || 1;
      const scrolled = window.scrollY;
      const scrollable =
        document.documentElement.scrollHeight - window.innerHeight;

      if (Math.ceil(scrolled) >= scrollable) {
        if (props.storyPage <= TotalPage) {
          console.log("totalpage", props.storyPage, TotalPage);
          if (props.user.isLogin) {
            if (category) {
              loadStoriesBasedOnCategoryHandler(
                category.target.attributes.value.value,
                props.storyPage,
                category,
              );
            } else if (props.isAllStories) {
              loadAllStoriesBasedOnUserLangauge(false, props.storyPage);
            } else if (props.isSubStories) {
              props.loadStoriesBasedOnSubscriptionGroup(
                props.subID,
                props.userID,
                props.storyPage,
              );
            } else if (props.isUserProfileStories) {
              props.loadStoriesBasedOnUserProfile(
                props.userID,
                props.storyPage,
              );
            }
          } else {
            if (category) {
              loadStoriesBasedOnCategoryHandler(
                category.target.attributes.value.value,
                props.storyPage,
                category,
              );
            } else {
              loadAllStoriesBasedOnMachineLangauge(false, props.storyPage);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (props.user.isLogin && props.mobile === false) {
      props.setAllStories(true);
      loadAllStoriesBasedOnUserLangauge(false, 1);
    } else {
      if (props.mobile === false)
        loadAllStoriesBasedOnMachineLangauge(false, 1);
    }
  }, [props.user.isLogin, props.mobile]);

  useEffect(() => {
    if (props.totalStoryPage) {
      document.addEventListener("scroll", handleScrolListner1);
    }
    return () => {
      document.removeEventListener("scroll", handleScrolListner1);
    };
  }, [props.storyPage, props.totalStoryPage, category]);

  const loadStoriesBasedOnCategoryHandler = async (
    categoryId,
    storyPageNo,
    e,
  ) => {
    setCategory(e);
    props.setAllStories(false);
    props.setUserProfileStories(false);
    props.setSubStories(false);
    document.querySelectorAll(".list-category-carousel").forEach((div) => {
      div.style.backgroundColor = "rgb(242, 242, 242)";
      div.style.color = "black";
    });
    e.target.style.backgroundColor = "#515154";
    e.target.style.color = "white";

    dispatch({
      type: "all_stories",
      payload: {
        getStoryModel: [],
      },
    });
    props.setLoadingUser(true);

    const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&page=${storyPageNo}`;
    try {
      let res = await getRequestedData(url);
      props.setStoryPage(storyPageNo + 1);

      if (storyPageNo > 1) {
        dispatch({
          type: "cat_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "cat_stories", payload: res.data.body });
      }
      props.setTotalStoryPage(res.data.body.Total_Page);
      props.setLoadingUser(false);
      if (res.data.body.length <= 0) {
        if (props.categoryUserMsg.current)
          props.categoryUserMsg.current.innerHTML =
            " Sorry there are no consultant for this category registered in the system..";
      } else {
        if (props.categoryUserMsg.current)
          props.categoryUserMsg.current.innerHTML = "";
      }
    } catch (err) {
      props.setLoadingUser(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const loadAllStoriesBasedOnMachineLangauge = async (e, storyPageNo) => {
    setCategory(false);
    var lang = navigator.languages[0].split("-")[0] || "en";
    if (e) {
      document.querySelectorAll(".list-category-carousel").forEach((div) => {
        div.style.backgroundColor = "rgb(242, 242, 242)";
        div.style.color = "black";
      });

      e.target.style.backgroundColor = "black";
      e.target.style.color = "white";
    } else {
      if (document.querySelectorAll(".list-category-carousel")[0]) {
        document.querySelectorAll(
          ".list-category-carousel",
        )[0].style.backgroundColor = "black";
        document.querySelectorAll(".list-category-carousel")[0].style.color =
          "white";
      }
    }
    props.setLoadingUser(true);
    dispatch({
      type: "cat_stories",
      payload: {
        getStoryModel: [],
      },
    });
    const url = `${
      process.env.REACT_APP_BACKEND_URL
    }/api/explore/getAllFreeStories?language=${lang}&page=${storyPageNo}${
      props.storyType ? `&storyType=${props.storyType}` : ""
    }`;
    try {
      let res = await getRequestedData(url);
      props.setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        dispatch({
          type: "all_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "all_stories", payload: res.data.body });
      }
      props.setTotalStoryPage(res.data.body.Total_Page);
      props.setLoadingUser(false);
      if (res.data.body.length <= 0) {
        if (props.categoryUserMsg.current)
          props.categoryUserMsg.current.innerHTML =
            " Sorry there are no consultants for this category and selected language registered in the system..";
      } else {
        if (props.categoryUserMsg.current)
          props.categoryUserMsg.current.innerHTML = "";
      }
    } catch (err) {
      props.setLoadingUser(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const loadAllStoriesBasedOnUserLangauge = async (e, storyPageNo) => {
    let langs = UserRequiredDropDown.languages.map((langauge) => {
      return langauge._id;
    });

    setCategory(false);
    props.setUserProfileStories(false);
    props.setAllStories(true);
    document.querySelectorAll(".list-category-carousel").forEach((div) => {
      div.style.backgroundColor = "rgb(242, 242, 242)";
      div.style.color = "black";
    });
    if (e) {
      e.target.style.backgroundColor = "#515154";
      e.target.style.color = "white";
    } else {
      if (document.querySelectorAll(".list-category-carousel")[0]) {
        document.querySelectorAll(
          ".list-category-carousel",
        )[0].style.backgroundColor = "#515154";
        document.querySelectorAll(".list-category-carousel")[0].style.color =
          "white";
      }
    }

    dispatch({
      type: "cat_stories",
      payload: {
        getStoryModel: [],
      },
    });

    props.setLoadingUser(true);

    const url = `${
      process.env.REACT_APP_BACKEND_URL
    }/api/explore/getAllFreeStories?user_id=${
      props.user.userData.loginUser._id
    }&page=${storyPageNo}${
      props.storyType ? `&storyType=${props.storyType}` : ""
    }`;
    try {
      let res = await getRequestedData(url);
      props.setStoryPage(storyPageNo + 1);

      if (storyPageNo > 1) {
        dispatch({
          type: "all_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "all_stories", payload: res.data.body });
      }
      props.setTotalStoryPage(res.data.body.Total_Page);
      props.setLoadingUser(false);
      if (res.data.body.length <= 0) {
        if (props.categoryUserMsg.current)
          props.categoryUserMsg.current.innerHTML =
            " Sorry there are no consultants for this category and user languages registered in the system..";
      } else {
        if (props.categoryUserMsg.current)
          props.categoryUserMsg.current.innerHTML = "";
      }
    } catch (err) {
      props.setLoadingUser(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 7 },
    { width: 1200, itemsToShow: 10 },
  ];

  useEffect(() => {
    window.addEventListener("resize", handleresizListner);
    return () => {
      window.removeEventListener("resize", handleresizListner);
    };
  });

  const handleresizListner = () => {
    if (window.outerWidth >= 768) {
      setShowSubscriptionMobile(false);
    } else {
      setShowSubscriptionMobile(true);
    }
  };

  return (
    <>
      <SearchResult
        loginUser={props.user}
        loadingSearchUser={props.loadingSearchUser}
        tagtype={"story_tag"}
      />
      <span id="width_check"></span>
      {searchUsers.search.usersAndStories &&
        searchUsers.search.usersAndStories.length <= 0 &&
        searchUsers.search.usersAndStories &&
        searchUsers.search.usersAndStories.length <= 0 && (
          <>
            <div className="display-flex carousel-box">
              <DropDownMenu />
              <div style={{ flexGrow: "1" }}>
                {/* {props.loadingCat && (
                <LoadingCard count={10} avatar page={"home"} />
              )} */}
                {props.categories && props.categories.length > 0 && (
                  <Carousel breakPoints={breakPoints}>
                    <>
                      {props.user.isLogin ? (
                        <span
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#515154",
                            color: "white",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                          className="list-category-carousel"
                          onClick={(e) => {
                            loadAllStoriesBasedOnUserLangauge(e, 1);
                          }}
                        >
                          ALL
                        </span>
                      ) : (
                        <span
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#515154",
                            color: "white",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                          className="list-category-carousel"
                          onClick={(e) => {
                            loadAllStoriesBasedOnMachineLangauge(e, 1);
                          }}
                        >
                          ALL
                        </span>
                      )}
                    </>
                    {props.categories.map((cat, i) => {
                      if (document.getElementById("width_check")) {
                        document.getElementById("width_check").innerHTML =
                          cat.category_id.category_name;
                        var width =
                          document.getElementById("width_check").offsetWidth +
                          50;

                        document.getElementById("width_check").innerHTML = "";
                      }

                      return (
                        <span
                          key={cat._id}
                          className="list-category-carousel"
                          value={cat._id}
                          style={{ cursor: "pointer", width: width + "px" }}
                          onClick={(e) => {
                            loadStoriesBasedOnCategoryHandler(
                              cat.category_id._id,
                              1,
                              e,
                            );
                          }}
                        >
                          {cat.category_id.category_name}
                        </span>
                      );
                    })}
                  </Carousel>
                )}
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              {props.loadingUser && (
                <>
                  <div className="d-md-block d-none d-sm-block">
                    <LoadingCard count={25} avatar />
                  </div>
                </>
              )}

              {props.loadingUser && (
                <div className="d-block d-sm-none">
                  <LogoLodingCard />
                </div>
              )}

              <div className="row">
                {AllStories.stories.getStoryModel.length > 0 &&
                  CatStories.stories.getStoryModel.length <= 0 &&
                  AllStories.stories.getStoryModel.map((story, i) => {
                    return (
                      <div
                        className={`${
                          story.story_type !== "mobile-card" && "mobile-card"
                        } col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5 padding-right-mobile-card padding-right-card`}
                        key={story._id}
                      >
                        <StoryCard
                          story={story}
                          userid={
                            props.user.userData
                              ? props.user.userData.loginUser._id
                              : ""
                          }
                          isLogin={props.user.isLogin}
                        />
                      </div>
                    );
                  })}
                {CatStories.stories.getStoryModel.length > 0 &&
                  CatStories.stories.getStoryModel.map((story, i) => {
                    return (
                      <div
                        className={`${
                          story.story_type !== "mobile-card" && "mobile-card"
                        } col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5 padding-right-mobile-card padding-right-card`}
                        key={story._id}
                      >
                        <StoryCard
                          story={story}
                          user={
                            props.user.userData
                              ? props.user.userData.loginUser
                              : ""
                          }
                          isLogin={props.user.isLogin}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default UserStoriesDisplay;
