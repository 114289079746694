import React, { useState, useRef } from 'react'
import { GoPencil } from 'react-icons/go'
import { AiOutlineClose } from 'react-icons/ai'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'
import { Spin } from 'antd'
import { editPersonalDetails } from '../../../functions/editProfile/editProfile'
import ModelPhone from '../../../utils/models/DefaultModel'
import { responseMessageDispaly } from '../../../utils/sharedfunction'
const EditPersonalDetailsSection = (props) => {
  const [name, setName] = useState(props.user.user_name)
  const [gender, setGender] = useState(props.user.gender)
  const [phone, setPhone] = useState(props.user.mobile_no)
  const [email, setMail] = useState(props.user.email)
  const [loginName, setLoginName] = useState(props.user.login_user_id)
  const [showEdit, setShowEdit] = useState(false)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const uploadMSG = useRef(null)

  const handleSubmit = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserProfile`
    const data = {
      user_name: name,
      user_email: email,
      user_mobile_no: phone,
      user_gender: gender,
      user_id: props.user._id,
      loginName: loginName,
    }
    try {
      let res = await editPersonalDetails(url, data, props.token)
      props.userDetails()
      setShowEdit(false)
      setLoading(false)
      //toast.success('Personal details has updated')
      responseMessageDispaly(
        uploadMSG,
        'Personal details has updated successfully',
        'green'
      )
    } catch (err) {
      console.log(err.response)
      if (err.response.data.message.includes('E11000')) {
        responseMessageDispaly(uploadMSG, 'Login Name already taken', 'red')
        toast.error('Login Name already taken please check another')
      } else {
        responseMessageDispaly(uploadMSG, 'Some thing went wrong', 'red')
        toast.error(err.message)
      }
      setLoading(false)
    }
  }
  return (
    <>
      <div className='edit-content-widget'>
        <div className='ui-tabs-title'>
          <h4 className='float-left'>Personal Details</h4>
          {loading && (
            <>
              <Spin className='ml-3' />
              <span className='text-danger'>....</span>
            </>
          )}

          {showEdit || (
            <GoPencil
              className='float-right mt-2'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEdit(true)
              }}
            />
          )}
          {showEdit && (
            <AiOutlineClose
              className='float-right mt-2'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEdit(false)
              }}
            />
          )}
          <div style={{ clear: 'both' }}></div>
        </div>

        {showEdit || (
          <div className=' tab-inner-content-padding'>
            <table id='edit-profile-section'>
              <tbody>
                <tr>
                  <td className='font-bold padding-5'>Name</td>
                  <td className='padding-5' id='group_name_view'>
                    {props.user.user_name}
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5'>Gender</td>
                  <td className='padding-5' id='group_name_view'>
                    {props.user.gender === 'Male' ? 'Male' : 'Female'}
                  </td>
                </tr>
                <tr>
                  <td className='padding-5 font-bold'>Phone No</td>
                  <td className='padding-5' id='langauge_view'>
                    {props.user.mobile_no}
                  </td>
                </tr>
                <tr>
                  <td className='padding-5 font-bold'>Mail</td>
                  <td className='padding-5' id='cat_view'>
                    {props.user.email}
                  </td>
                </tr>

                <tr>
                  <td className='padding-5 font-bold'>User login Name</td>
                  <td className='padding-5' id='langauge_view'>
                    {props.user.login_user_id}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {showEdit && (
          <>
            {loading && <Spin />}
            <ModelPhone show={show}>
              <div className='model-content-default'>
                <AiOutlineClose
                  className='video-close'
                  size='30px'
                  onClick={() => {
                    setShow(false)
                  }}
                />
                <h5 className='text-center'>
                  Please enter your new pone number
                </h5>

                <form className='mt-5'>
                  <div className='form-group row'>
                    <div className='col-sm-2'>
                      <input
                        name='phone'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        disabled
                        style={{ background: 'white', cursor: 'pointer' }}
                        value={`+${props.user.country_id.dial_cd}`}
                      />
                    </div>
                    <div className='col-sm-10'>
                      <input
                        name='phone'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className='float-right'
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    update
                  </Button>
                </form>
              </div>
            </ModelPhone>
            <div className=' tab-inner-content-padding'>
              <table id='edit-profile-section' style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td
                      className='font-bold padding-5'
                      style={{ width: '10%' }}
                    >
                      Name
                    </td>
                    <td>
                      <input
                        name='name'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        value={name}
                        autoFocus
                        onChange={(e) => {
                          setName(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='font-bold padding-5'
                      style={{ width: '10%' }}
                    >
                      Gender
                    </td>
                    <td>
                      <select
                        className='form-control registration-from-control-border'
                        defaultValue={gender.toUpperCase()}
                        onChange={(e) => {
                          setGender(e.target.value)
                        }}
                      >
                        <option value='0'>Select Gender</option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='padding-5 font-bold'
                      style={{ width: '10%' }}
                    >
                      Phone No
                    </td>
                    <td>
                      <div
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShow(true)
                        }}
                      >
                        {phone}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='padding-5 font-bold'
                      style={{ width: '10%' }}
                    >
                      Mail
                    </td>
                    <td>
                      <input
                        name='email'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        autoFocus
                        value={email}
                        onChange={(e) => {
                          setMail(e.target.value)
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td
                      className='padding-5 font-bold'
                      style={{ width: '10%' }}
                    >
                      User login Name
                    </td>
                    <td>
                      <input
                        name='login_name'
                        placeholder=''
                        className='form-control input-md registration-from-control-border'
                        type='text'
                        autoFocus
                        value={loginName}
                        onChange={(e) => {
                          setLoginName(e.target.value)
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='col-sm-12 p-2'>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className='float-right'
                  onClick={handleSubmit}
                >
                  update
                </Button>
              </div>
            </div>
          </>
        )}
        <div ref={uploadMSG}></div>
      </div>
    </>
  )
}

export default EditPersonalDetailsSection
