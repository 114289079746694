import React, { useState } from "react";
import CourseIntroImageVideo from "./CourseIntroImageVideo";
import CourseLessions from "./CourseLessions";
import {
  AiOutlineDown,
  AiOutlineUp,
  AiFillPlayCircle,
  AiOutlineFileText,
  AiOutlineClose,
} from "react-icons/ai";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { IoLockClosed } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import Model from "../../utils/models/DefaultModel";
import { hoursMinSec } from "../../utils/sharedfunction";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import videoCover from "../../images/uplaodVideo.png";
import { useHistory } from "react-router-dom";
const SectionTypeCourse = ({
  course,
  sections,
  stories,
  isHeAddedToTheCart,
  isWishlisted,
  setIsWishlisted,
  storiesCount,
}) => {
  const history = useHistory();
  const [showGotoCartModel, setShowGotoCartModel] = useState(false);
  const [selectedStory, setSelectedStory] = useState({});
  const [describeVideo, setDescribeVideo] = useState(true);

  const toggleDropDownSections = (id) => {
    let isDisplay = document.getElementById(id).style.display;
    if (isDisplay == "none") {
      document.getElementById(id).style.display = "block";
      document.getElementById(`${id}_up_arrow`).style.display = "block";
      document.getElementById(`${id}_down_arrow`).style.display = "none";
    } else {
      document.getElementById(id).style.display = "none";
      document.getElementById(`${id}_down_arrow`).style.display = "block";
      document.getElementById(`${id}_up_arrow`).style.display = "none";
    }
  };

  const addtocart = async () => {
    let userData = "";
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("userDataSessionWay")) {
        userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
      }
    } else {
      alert("Please update your browser");
    }

    if (!userData.loginUser?._id) {
      alert("login to purchase");
      return;
    }
    const data = {
      course_id: course._id,
      user_id: userData.loginUser._id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addtocart`;
      let res = await getDataPostWithHeader(url, data, userData.token);
      setShowGotoCartModel(true);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const addWishList = async () => {
    let userData = "";
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("userDataSessionWay")) {
        userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
      }
    } else {
      alert("Please update your browser");
    }
    const data = {
      course_id: course._id,
      user_id: userData.loginUser._id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addtowishlist`;
      let res = await getDataPostWithHeader(url, data, userData.token);
      setIsWishlisted(true);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const HandleStory = (e, story) => {
    if (story === "description") {
      setDescribeVideo(true);
      document
        .querySelectorAll(".backgroud-color-theme-gray")
        .forEach((div) => {
          div.classList.remove("backgroud-color-theme-gray");
        });
    } else {
      if (story.isStoryPreview) {
        document
          .querySelectorAll(".backgroud-color-theme-gray")
          .forEach((div) => {
            div.classList.remove("backgroud-color-theme-gray");
          });
        document
          .getElementById(story._id)
          .classList.add("backgroud-color-theme-gray");
        setSelectedStory(story);
        setDescribeVideo(false);
      }
    }
  };

  return (
    <>
      <Model show={showGotoCartModel}>
        <div className="modal-container-content" style={{ width: "43%" }}>
          <header
            className="modal__header "
            style={{
              padding: "10px",
              background: "white",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h4>Added to cart</h4>
            <h4>
              <AiOutlineClose
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowGotoCartModel(false);
                }}
              />
            </h4>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                <div style={{ display: "flex", paddingBottom: "10px" }}>
                  <img
                    id="thumnail_bio"
                    src={course.group_video_thumbnail_url}
                    className="cursor-pointer"
                    alt="thumbnail"
                    style={{ height: "60px" }}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = videoCover;
                    }}
                  />
                  <div
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    className="ml-2Shopping Cart"
                  >
                    {course.group_name}
                  </div>
                </div>
              </div>
              <div className="col-xs-3  col-sm-3 col-lg-3 col-md-3">
                <button
                  className="btn btn-info"
                  style={{ width: "100%" }}
                  onClick={() => {
                    history.push("/cart");
                    setShowGotoCartModel(false);
                  }}
                >
                  Go to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </Model>

      <div className="container-fluid">
        <div className="row" style={{ marginTop: "60px" }}>
          <div className="col-md-8">
            {course._id && describeVideo && (
              <CourseIntroImageVideo group_info={course} />
            )}
            {!describeVideo && selectedStory._id && (
              <CourseLessions story={selectedStory} group_info={course} />
            )}
          </div>

          <div
            className="col-md-4"
            style={{
              padding: "0px",
              margin: "0px",
              borderLeft: "1px solid #d1d7dc",
            }}
          >
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid #d1d7dc",
                padding: "12px",
                fontWeight: "600",
              }}
            >
              <div onClick={(e) => HandleStory(e, "description")}>
                <img
                  id="thumnail_bio"
                  src={course.group_video_thumbnail_url}
                  className="cursor-pointer mr-3"
                  alt="thumbnail"
                  style={{ height: "100px" }}
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = videoCover;
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <span onClick={(e) => HandleStory(e, "description")}>
                  Price : {course.subscription_price}{" "}
                  {course._id && course.currency_id.currency_cd}
                </span>
                <span onClick={addWishList} className="cursor-pointer">
                  {!isWishlisted && <BsHeart />}
                  {isWishlisted && <BsHeartFill />}
                </span>
                <div>
                  {!isHeAddedToTheCart && (
                    <button className="btn btn-info" onClick={addtocart}>
                      Buy This Course
                    </button>
                  )}
                  {isHeAddedToTheCart && (
                    <button
                      className="btn btn-info"
                      onClick={() => {
                        history.push("/cart");
                      }}
                    >
                      Go To the cart
                    </button>
                  )}
                </div>
              </div>
            </div>
            {sections.length > 0 &&
              sections.map((section, index) => {
                return (
                  <div
                    style={{
                      borderBottom: "1px solid #d1d7dc",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: " space-between",
                        cursor: "pointer",
                        padding: "12px",
                      }}
                      onClick={() => {
                        toggleDropDownSections(section._id);
                      }}
                    >
                      <div style={{ fontSize: "16px", fontWeight: "600" }}>
                        {section.section_title}
                      </div>
                      <div
                        id={section._id + "_down_arrow"}
                        style={{ display: "block" }}
                      >
                        <span>{section.sectionTotalStoryCount} lectures</span>

                        {hoursMinSec(section.sectionTotalSeconds).hours > 0 && (
                          <>
                            <span>
                              <GoDotFill />
                            </span>
                            {hoursMinSec(section.sectionTotalSeconds).hours}
                            <span>hours</span>
                          </>
                        )}
                        {hoursMinSec(section.sectionTotalSeconds).minutes >
                          0 && (
                          <>
                            {hoursMinSec(section.sectionTotalSeconds).minutes}
                            <span>min</span>
                          </>
                        )}
                        <AiOutlineDown />
                      </div>

                      <div
                        id={section._id + "_up_arrow"}
                        style={{ display: "none" }}
                      >
                        <AiOutlineUp />
                      </div>
                    </div>
                    <div id={section._id} style={{ display: "none" }}>
                      {section.section_stories.length >= 0 &&
                        section.section_stories.map((story, index) => {
                          let totalSeconds = story.story_duration;

                          let hours = Math.floor(totalSeconds / 3600);
                          totalSeconds %= 3600;
                          let minutes = Math.floor(totalSeconds / 60);
                          let seconds = totalSeconds % 60;

                          let format = "";
                          if (hours > 0) {
                            format = `${hours}hour ${minutes}min`;
                          } else {
                            format = `${minutes}min`;
                          }

                          if (format == "0min") {
                            format = `${Math.floor(seconds)}sec`;
                          }

                          return (
                            <div
                              style={{
                                marginTop: "10px",
                                fontSize: "14px",
                                cursor: "pointer",
                                padding: "12px",
                              }}
                              id={story._id}
                              className="selected-tab-lesson"
                              key={index}
                              onClick={(e) => HandleStory(e, story)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ width: "70%" }}>
                                  {!story.isStoryPreview && (
                                    <span>{story.story_title}</span>
                                  )}

                                  {story.isStoryPreview && (
                                    <span
                                      className="text-primary"
                                      style={{
                                        textDecoration: "underline",
                                        width: "70%",
                                      }}
                                    >
                                      {story.story_title}
                                    </span>
                                  )}
                                </div>
                                {story.isStoryPreview && (
                                  <div className="text-primary">Preview</div>
                                )}
                                {!story.isStoryPreview && (
                                  <span>
                                    <IoLockClosed />
                                  </span>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    width: "10%",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>
                                    {story.story_type === "video" && (
                                      <AiFillPlayCircle color="#6a6f73" />
                                    )}
                                    {story.story_type === "text" && (
                                      <AiOutlineFileText color="#6a6f73" />
                                    )}
                                  </div>

                                  <div
                                    style={{
                                      color: "#6a6f73",
                                      marginTop: "2px",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    {format}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}

            {stories && stories.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: " space-between",
                    cursor: "pointer",
                    padding: "12px",
                    borderBottom: "1px solid #d1d7dc",
                  }}
                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Lessons
                  </div>
                  <div>
                    <span>{storiesCount.storiesTotalCount} lectures </span>

                    {hoursMinSec(storiesCount.storiesTotalSeconds).hours >
                      0 && (
                      <>
                        <span>
                          <GoDotFill />
                        </span>
                        {hoursMinSec(storiesCount.storiesTotalSeconds).hours}
                        <span>hours</span>
                      </>
                    )}
                    {hoursMinSec(storiesCount.storiesTotalSeconds).minutes >
                      0 && (
                      <>
                        {hoursMinSec(storiesCount.storiesTotalSeconds).minutes}
                        <span>min</span>
                      </>
                    )}
                  </div>
                </div>
                {stories.map((story, index) => {
                  let totalSeconds = story.story_duration;
                  let hours = Math.floor(totalSeconds / 3600);
                  totalSeconds %= 3600;
                  let minutes = Math.floor(totalSeconds / 60);
                  let seconds = totalSeconds % 60;

                  let format = "";
                  if (hours > 0) {
                    format = `${hours}hours ${minutes}min`;
                  } else {
                    format = `${minutes}min`;
                  }

                  return (
                    <div
                      style={{
                        marginTop: "10px",
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "12px",
                        borderBottom: "1px solid #d1d7dc",
                      }}
                      id={story._id}
                      className="selected-tab-lesson"
                      key={index}
                      onClick={(e) => HandleStory(e, story)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {!story.isStoryPreview && (
                          <span style={{ width: "70%" }}>
                            {story.story_title}
                          </span>
                        )}
                        {story.isStoryPreview && (
                          <span
                            className="text-primary"
                            style={{
                              textDecoration: "underline",
                              width: "70%",
                            }}
                          >
                            {story.story_title}
                          </span>
                        )}
                        {story.isStoryPreview && (
                          <div className="text-primary">Preview</div>
                        )}
                        {!story.isStoryPreview && (
                          <span>
                            <IoLockClosed />
                          </span>
                        )}
                        <div
                          style={{
                            display: "flex",
                            width: "10%",
                            justifyContent: "end",
                          }}
                        >
                          <div>
                            {story.story_type === "video" && (
                              <AiFillPlayCircle color="#6a6f73" />
                            )}
                            {story.story_type === "text" && (
                              <AiOutlineFileText color="#6a6f73" />
                            )}
                          </div>
                          <div
                            style={{
                              color: "#6a6f73",
                              marginTop: "2px",
                              marginLeft: "3px",
                            }}
                          >
                            {format}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTypeCourse;
