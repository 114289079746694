import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { connect } from "react-redux";

import profileDefualt from "../../images/default.jpeg";
import defaultThumbnail from "../../images/default-thumbnail.png";
import addcover from "../../images/default-cover.png";
import edit from "../../images/edit-tick.png";

import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";

import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

import {
  dataURLtoBlob,
  videoPickerHandler,
  responseMessageDispaly,
  selectValueValue,
  selectIdValue,
  selectTextValue,
  setWithExpiry,
  getWithExpiry,
} from "../../utils/sharedfunction";

import ModelV from "../../utils/models/DefaultModel";
import VideoModel from "../VideoModel";

import { categgoryBasedLangauge } from "../../utils/apis/commonApis";

import VideoUpload from "../../utils/sharedComponent/VideoUpload";

const url = process.env.REACT_APP_BACKEND_URL;

const SubscriptionGroupInfo = ({
  group_info,
  UserRequiredDropDown,
  dispatch,
}) => {
  const menuBoxBioRef = useRef(null);
  const videoPickerRef = useRef(null);
  const videobioUpload = useRef(null);
  const sessionGroupViewRef = useRef(null);
  const errorMessage = useRef(null);
  const successMessage = useRef(null);
  const groupNameEdit = useRef(null);
  const subPriceEditRef = useRef(null);
  const languageEditRef = useRef(null);
  const currancyEditRef = useRef(null);
  const categoryEditRef = useRef(null);
  const sessionGroupEditRef = useRef(null);
  const bioImageRef = useRef(null);
  const groupProfileRef = useRef(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);
  const uploadMSG = useRef(null);
  const [showCrop, setShowCrop] = useState(false);
  const [showCoverCrop, setShowCoverCrop] = useState(false);

  const closeSubRef = useRef(null);
  const editSubRef = useRef(null);
  const subDecription = useRef(null);
  const subDecriptionview = useRef(null);
  const [showVideo, setshowVideo] = useState({ isShowVideo: false, url: "" });

  useEffect(() => {
    categgoryBasedOnLangaugeHandler();
  }, []);

  const categgoryBasedOnLangaugeHandler = async () => {
    try {
      let categories = await categgoryBasedLangauge();

      if (categories) {
        let category = "";
        category += `<option id="00" value="0">Select Category</option>`;
        categories.forEach((list) => {
          category += `<option id=${list.language_cd} value="${list._id}">${list.category_name}</option>`;
        });
        categoryEditRef.current.innerHTML = category;

        categoryEditRef.current.value = group_info.category_id._id;
      }
    } catch (err) {
      console.log(err);
      responseMessageDispaly(
        errorMessage,
        "something went wrong loading category",
        "red",
      );
    }
  };
  const saveSubscriptionGroupHandler = async () => {
    const language = selectValueValue(languageEditRef.current);
    const currency = selectValueValue(currancyEditRef.current);
    const groupName = groupNameEdit.current.value;
    const subgroupPrice = subPriceEditRef.current.value;
    const subgroupDecription = subDecription.current.value;
    const language_id = selectIdValue(languageEditRef.current);

    if (!groupName) {
      responseMessageDispaly(errorMessage, "Please enter group name", "red");
      return;
    }

    if (language == "0") {
      responseMessageDispaly(errorMessage, "Please select language", "red");
      return;
    }

    const category = selectValueValue(categoryEditRef.current);

    if (category == "0") {
      responseMessageDispaly(errorMessage, "Please select category", "red");
      return;
    }

    if (!subgroupPrice) {
      responseMessageDispaly(
        errorMessage,
        "Please enter subscription price",
        "red",
      );
      return;
    }

    if (currency == "0") {
      responseMessageDispaly(errorMessage, "Please select currency", "red");
      return;
    }

    if (!subgroupDecription) {
      console.log("hello");
      responseMessageDispaly(errorMessage, "Please enter description", "red");
      return;
    }

    try {
      const response = await fetch(
        `${url}/api/userProfile/updateSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${group_info.token}`,
          },
          body: JSON.stringify({
            group_name: groupName,
            language_id: language_id,
            category_id: category,
            subscription_price: subgroupPrice,
            currency_id: currency,
            group_description: subgroupDecription,
            subscription_id: group_info._id,
          }),
        },
      );
      const responseData = await response.json();

      if (!response.ok) {
        console.log(response.status);
        if (response.status === 401) {
          localStorage.removeItem("userDataSessionWay");
          window.location.replace("/");
        }
        throw new Error(responseData.message || "something went wrong");
      } else {
        responseMessageDispaly(successMessage, "Success", "green");

        document.getElementById("group_name_view").innerHTML = groupName;
        document.getElementById("langauge_view").innerHTML = selectTextValue(
          languageEditRef.current,
        );
        document.getElementById("cat_view").innerHTML = selectTextValue(
          categoryEditRef.current,
        );
        document.getElementById("currency_view").innerHTML = selectTextValue(
          currancyEditRef.current,
        );
        document.getElementById("price_view").innerHTML = subgroupPrice;

        document.getElementById("subDecriptionview").innerHTML =
          subgroupDecription;

        sessionGroupViewRef.current.style.display = "block";
        sessionGroupEditRef.current.style.display = "none";
        closeSubRef.current.style.display = "none";
        editSubRef.current.style.display = "inline-block";
      }
    } catch (err) {
      console.log(err);
      responseMessageDispaly(errorMessage, "Something went wrong", "red");
    }
  };

  const toggleEditSubHandler = () => {
    let isViewed = sessionGroupEditRef.current.style.display;
    if (isViewed === "block") {
      sessionGroupViewRef.current.style.display = "block";
      sessionGroupEditRef.current.style.display = "none";
      closeSubRef.current.style.display = "none";
      editSubRef.current.style.display = "inline-block";
    } else {
      sessionGroupViewRef.current.style.display = "none";
      sessionGroupEditRef.current.style.display = "block";
      closeSubRef.current.style.display = "inline-block";
      editSubRef.current.style.display = "none";
    }
  };

  const videofileInputHandler = (file, imgeval) => {
    var imgeblob = dataURLtoBlob(imgeval);
    var path = `${url}/api/userProfile/SubscriptionGroupVideo`;
    var type = "video";
    uploadVideoHandler(type, path, imgeblob, imgeval, file);
  };

  const openProfileHandler = () => {
    closeSubMenuHandler();
    setShowCrop(true);
  };

  const closeProfileHandler = () => setShowCrop(false);

  const imagefileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    groupProfileRef.current.src = file;
    document.getElementById(group_info._id).childNodes[0].childNodes[0].src =
      file;
    document.getElementById(
      "session_short_intro",
    ).childNodes[0].childNodes[0].src = file;
    var path = `${url}/api/userProfile/updateSubscriptionImage`;
    var type = "image";
    uploadVideoHandler(type, path, imgeFile, file);
  };

  const coverfileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    bioImageRef.current.src = file;
    var path = `${url}/api/userProfile/updateSubscriptionThumbnail`;
    var type = "cover";
    uploadVideoHandler(type, path, imgeFile, file);
  };

  const openCoverHandler = () => {
    closeSubMenuHandler();
    setShowCoverCrop(true);
  };

  const closeCoverHandler = () => setShowCoverCrop(false);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const uploadVideoHandler = (type, path, Cover, base64img, videoFile) => {
    let formData = new FormData();

    if (type === "video") {
      formData.append("video", videoFile);
      let isCoverPicBio = getWithExpiry("subGroupInfoCover");
      if (isCoverPicBio === null) {
        formData.append("video_cover", Cover);
      } else {
        var imgeFile = dataURLtoBlob(isCoverPicBio);
        formData.append("video_cover", imgeFile);
      }
      formData.append(
        "video_cover_key_name",
        group_info.group_video_thumbnail_url,
      );
      formData.append("video_key_name", group_info.group_video_thumbnail_url);
    } else {
      formData.append("image", Cover);
      formData.append("Name", group_info.group_video_thumbnail_url);
      setWithExpiry("subGroupInfoCover", base64img, 100000);
    }
    formData.append("subscription_id", group_info._id);
    const options = {
      headers: {
        Authorization: `Bearer ${group_info.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        setUploadPercentage(0);
        if (type === "video") {
          bioImageRef.current.src = base64img;
          videobioUpload.current.src = "";
        }
        closeSubMenuHandler();

        dispatch({
          type: "group_details",
          payload: {
            ...group_info,
            group_introduction_video_url:
              res.data.body.group_introduction_video_url,
          },
        });

        responseMessageDispaly(uploadMSG, "success", "green");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }
        if (type === "video") {
          videobioUpload.current.src = "";
        } else {
          bioImageRef.current.src = "";
          groupProfileRef.current.src = "";
        }
        setUploadPercentage(0);
        if (isCancel(err)) {
          alert(err.message);
          responseMessageDispaly(uploadMSG, "Aborted", "red");
        } else {
          responseMessageDispaly(uploadMSG, "System Failed", "red");
        }
      });
  };

  const closeSubMenuHandler = () => {
    menuBoxBioRef.current.style.display = "none";
    document.getElementById("story_box").style.height = 300 + "px";
  };

  const onMenuSubHandler = () => {
    menuBoxBioRef.current.style.display = "block";
    document.getElementById("story_box").style.height = 210 + "px";
  };

  const playVideo = (url) => {
    setshowVideo({ isShowVideo: true, url: url });
  };

  const closeVideoPlayer = () => {
    setshowVideo({ isShowVideo: false, url: "" });
  };

  return (
    <>
      <ModelV show={showVideo.isShowVideo}>
        <VideoModel src={showVideo.url} onClose={closeVideoPlayer} />
      </ModelV>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 400, height: 400 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>
      <Modal
        show={showCoverCrop}
        onCancel={closeCoverHandler}
        headerClass="text-center"
        containerStyle={{ width: "50%" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeCoverHandler}
            onSave={coverfileInputHandler}
            aspectRatio={16 / 9}
            objectFit="horizontal-cover"
          />
        </div>
      </Modal>
      <div
        id="Session_display_form"
        style={{
          paddingTop: 5 + "px",
          paddingBottom: 5 + "px",
          display: "block",
        }}
      >
        <div className="sub-group-info-border">
          <div
            className="push-left"
            style={{
              width: 320 + "px",
              marginBottom: 5 + "px",
              marginRight: "15px",
            }}
          >
            <video
              width="100%"
              controls
              ref={videobioUpload}
              style={{ display: "none" }}
            >
              Your browser does not support the video tag.
            </video>
            {group_info.group_introduction_video_url ? (
              <img
                src={group_info.group_video_thumbnail_url}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = addcover;
                }}
                id="group_thumbnail"
                alt="group-thumnbnail"
                className="sub-group-thumbnail cursor-pointer"
                ref={bioImageRef}
                onClick={() => {
                  playVideo(group_info.group_introduction_video_url);
                }}
              />
            ) : (
              <img
                src={group_info.group_video_thumbnail_url}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defaultThumbnail;
                }}
                id="group_thumbnail"
                alt="group-thumnbnail"
                className="sub-group-thumbnail cursor-pointer"
                ref={bioImageRef}
              />
            )}

            <div style={{ margin: 3 + "px" }}>
              <div ref={uploadMSG}></div>
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <div className="progress">
                      <div
                        className="progress-bar bg-success progress-bar-striped"
                        role="progressbar"
                        style={{
                          width: `${uploadPercentage}%`,
                          height: "100%",
                        }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      <AiOutlineClose />
                    </span>
                  </div>
                </div>
              )}
              <div className="push-left">
                <img
                  src={group_info.group_image_url}
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                  id="session_group_profile"
                  className="sub-group-small-profile"
                  alt="profile"
                  ref={groupProfileRef}
                />
              </div>

              <div className="push-left sub-group-small-name">
                {group_info.group_name}
              </div>
              <div
                style={{
                  float: "right",
                  lineHeight: "25px",
                  marginRight: "-5px",
                }}
              >
                <BsThreeDotsVertical onClick={onMenuSubHandler} />
              </div>
              <div className="menu-box-sub display-none" ref={menuBoxBioRef}>
                <div className="menu-header">
                  <AiOutlineClose
                    className="cursor-pointer"
                    onClick={closeSubMenuHandler}
                  />
                </div>
                <div
                  className="menu-line cursor-pointer"
                  onClick={openProfileHandler}
                >
                  Replace Profile
                </div>
                <div
                  className="menu-line cursor-pointer"
                  onClick={openCoverHandler}
                >
                  Replace Cover
                </div>
                <div
                  className="menu-line cursor-pointer"
                  onClick={() => videoPickerHandler(videoPickerRef.current)}
                >
                  Replace Video
                </div>
                <VideoUpload
                  videoFilePickerRef={videoPickerRef}
                  videourlref={videobioUpload}
                  onvideofileInput={videofileInputHandler}
                />
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
          <div style={{ float: "right", height: "18px" }}>
            <img
              id="view_group_edit"
              src={edit}
              style={{ height: "18px", display: "inlineBlock" }}
              ref={editSubRef}
              onClick={toggleEditSubHandler}
              className="cursor-pointer"
            />
            <div
              ref={closeSubRef}
              style={{ display: "none" }}
              onClick={toggleEditSubHandler}
              className="cursor-pointer"
            >
              <AiOutlineClose />
            </div>
          </div>

          <div ref={sessionGroupViewRef}>
            <div
              className="sub-group-details"
              style={{ width: "auto", marginRight: "15px" }}
            >
              <div ref={successMessage}></div>
              <table id="session_group_view">
                <tbody>
                  <tr>
                    <td className="font-bold padding-5">Group name</td>
                    <td className="padding-5" id="group_name_view">
                      {group_info.group_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Language</td>
                    <td className="padding-5" id="langauge_view">
                      {group_info.language_id.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Category</td>
                    <td className="padding-5" id="cat_view">
                      {group_info.category_id.category_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Price</td>
                    <td className="padding-5" id="price_view">
                      {group_info.subscription_price}
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Currency</td>
                    <td id="currency_view" className="padding-5">
                      {group_info.currency_id.currency_name}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="sub-group-details" style={{ width: "30%" }}>
              <div>
                <div className="font-bold padding-5">Description</div>
                <div style={{ paddingLeft: "5px" }}>
                  <div
                    className="sub-group-view-description padding-5"
                    ref={subDecriptionview}
                    id="subDecriptionview"
                  >
                    {group_info.group_description}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={sessionGroupEditRef} style={{ display: "none" }}>
            <div className="sub-group-details">
              <div ref={errorMessage}></div>
              <table id="session_group_edit">
                <tbody>
                  <tr>
                    <td className="font-bold padding-5">Group name</td>
                    <td className="padding-5" id="group_name_view">
                      <input
                        type="text"
                        ref={groupNameEdit}
                        style={{ width: "200px", lineHeight: "10px" }}
                        defaultValue={group_info.group_name}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Language</td>
                    <td className="padding-5" id="langauge_view">
                      <select
                        id="langauge_edit"
                        style={{ width: "200px" }}
                        ref={languageEditRef}
                        defaultValue={group_info.language_id.code}
                        onChange={(e) => {
                          categgoryBasedOnLangaugeHandler(e.target.value);
                        }}
                      >
                        <option value="0">Select Language</option>
                        {UserRequiredDropDown.languages.map((list, index) => {
                          return (
                            <option key={index} id={list._id} value={list.code}>
                              {list.nativeName}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Category</td>
                    <td className="padding-5" id="cat_view">
                      <select
                        id="currancy_edit"
                        style={{ width: "200px" }}
                        ref={categoryEditRef}
                        defaultValue={group_info.category_id._id}
                      ></select>
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Price</td>
                    <td className="padding-5" id="price_view">
                      <input
                        type="number"
                        ref={subPriceEditRef}
                        style={{ width: "200px", lineHeight: "10px" }}
                        defaultValue={group_info.subscription_price}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td className="padding-5 font-bold">Currency</td>
                    <td className="padding-5" id="currency_view">
                      <select
                        id="currancy_edit"
                        style={{ width: "200px" }}
                        ref={currancyEditRef}
                        defaultValue={group_info.currency_id._id}
                      >
                        <option value="0">Select Language</option>
                        {UserRequiredDropDown.currencies.map((list, index) => {
                          return (
                            <option
                              key={index}
                              id={list.currency_cd}
                              value={list._id}
                            >
                              {list.currency_name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="padding-5">
                      <button
                        className="btn-info"
                        onClick={saveSubscriptionGroupHandler}
                      >
                        save
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="sub-group-details" style={{ width: "30%" }}>
              <div>
                <div className="font-bold padding-5">Description</div>
                <div style={{ paddingLeft: "5px" }}>
                  <textarea
                    name="group_desc"
                    rows="2"
                    cols="20"
                    id="group_desc"
                    style={{ height: "120px", width: "350px" }}
                    ref={subDecription}
                    defaultValue={group_info.group_description}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};

const MapStateToProps = ({ UserRequiredDropDown }) => {
  return { UserRequiredDropDown };
};

export default connect(MapStateToProps)(SubscriptionGroupInfo);
