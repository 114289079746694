import React from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import profileDefualt from "../../images/default.jpeg";
import parse from "html-react-parser";

const CourseCardMyProfile = ({ course, token, showSubscription }) => {
  const menuHandler = (id) => {
    // navbarMenuRef.current.style.display = 'block'
    let isDisplay = document.getElementById(id).style.display;
    if (isDisplay === "block") {
      document.getElementById(id).style.display = "none";
    } else {
      document.getElementById(id).style.display = "block";
    }
  };

  return (
    <>
      <div className="card-box story-card-box course-card-box">
        {course.group_video_thumbnail_url && (
          <div
            onClick={() => {
              showSubscription(course, token);
            }}
            className="course-cover-box"
          >
            <img
              style={{
                height: "100%",
                objectFit: "cover",
                width: "100%",
              }}
              className="bio-consultant"
              src={course.group_video_thumbnail_url}
              alt="im-story"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = defualtImg;
              }}
            />
          </div>
        )}

        {!course.group_video_thumbnail_url && (
          <div
            onClick={() => {
              showSubscription(course, token);
            }}
            className="image-warapper-ratio-adujestment-user-story"
          >
            <img
              src={course.user_id.imageUrl}
              className="image-whereplayer-shows-user-story"
              style={{
                borderRadius: "50%",
              }}
              alt="im-story"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = profileDefualt;
              }}
            />
          </div>
        )}

        <div className="story-card-box-profile card-profile-course">
          <div
            style={{ marginLeft: "8px", flex: "1" }}
            className="flex-space-btw display-flex-align-center"
          >
            <div
              className="titile-card"
              onClick={() => {
                showSubscription(course, token);
              }}
            >
              {course.group_name}
            </div>
            <div className="cursor-pointer mt-2 storie-edit-section">
              <BsThreeDotsVertical
                onClick={() => menuHandler(`${course._id}_edit`)}
                className="cursor-pointer"
                size={20}
              />
              <div
                className="dropdown-content storie-edit-dropdown-content"
                id={`${course._id}_edit`}
                style={{ display: "none" }}
              >
                <div className="cursor-pointer drop-down-navbar-menu">
                  <div className="flex-space-btw display-flex-align-center">
                    <div
                      style={{ width: "85%" }}
                      onClick={() => {
                        showSubscription(course, token);
                        document.getElementById(
                          `${course._id}_edit`,
                        ).style.display = "none";
                      }}
                    >
                      Edit
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        document.getElementById(
                          `${course._id}_edit`,
                        ).style.display = "none";
                      }}
                    />
                  </div>
                </div>
                <div className="cursor-pointer drop-down-navbar-menu ">
                  analytics
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {!course.group_video_thumbnail_url && (
          <div
            className={`${
              course.language_id.code === "ar" ? "text-right" : "text-left"
            } ml-2`}
            style={{ color: "black" }}
            onClick={() => {
              showSubscription(course, token);
            }}
          >
            {course.group_description &&
              course.group_description.length <= 350 &&
              parse(course.group_description)}
            {course.group_description &&
              course.group_description.length > 350 &&
              parse(course.group_description.substring(0, 350))}
          </div>
        )} */}

        <div
          className={`${
            course.language_id.code === "ar" ? "text-right" : "text-left"
          } description-box ml-2 mt-2`}
          style={{ color: "black" }}
          onClick={() => {
            showSubscription(course, token);
          }}
        >
          {course.group_description &&
            course.group_description.length <= 50 &&
            parse(course.group_description)}
          {course.group_description &&
            course.group_description.length > 50 &&
            parse(course.group_description.substring(0, 200))}
        </div>
      </div>
    </>
  );
};

export default CourseCardMyProfile;
