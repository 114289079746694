import React, { useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { HiLightBulb } from "react-icons/hi";
import { BsHeart } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import profileDefualt from "../../images/default.jpeg";
import courses from "../../images/courses.svg";
import coursesSelected from "../../images/courses selected.svg";

import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";

const CourseCardwithAddtoCart = ({
  Course,
  CourseCreatedByUser,
  user,
  isPurchased,
}) => {
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingWish, setLoadingWish] = useState(false);
  const dispatch = useDispatch({});

  const addtocart = async (course_id) => {
    if (user.isLogin) {
      setLoadingCart(true);
      try {
        const data = {
          course_id: course_id,
          user_id: user.userData.loginUser._id,
        };

        let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addtocart`;
        let res = await getDataPostWithHeader(url, data, user.userData.token);
        dispatch({ type: "CARTTOTALAPPEND", payload: 1 });
        getCarts();
        getWishLists();
      } catch (err) {
        console.log(err.message);
        alert(err.message);
      }
      setLoadingCart(false);
    } else {
      alert("Login to add to cart");
    }
  };

  const addWishList = async (course_id) => {
    if (user.isLogin) {
      setLoadingWish(true);
      const data = {
        course_id: course_id,
        user_id: user.userData.loginUser._id,
      };

      try {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addtowishlist`;
        let res = await getDataPostWithHeader(url, data, user.userData.token);
        getCarts();
        getWishLists();
      } catch (err) {
        console.log(err.message);
        alert(err.message);
      }
      setLoadingWish(false);
    } else {
      alert("Login to WishList");
    }
  };

  const getCarts = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getCart?user_id=${user.userData.loginUser._id}`;
    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      dispatch({ type: "CARTTOTAL", payload: res.data.body.courses.length });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const getWishLists = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getWishlists?user_id=${user.userData.loginUser._id}`;
    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      dispatch({ type: "WISHLIST", payload: res.data.body.length });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  return (
    <div className="box">
      <Link
        to={`/course/${Course.group_name}/${Course._id}`}
        style={{ color: "black" }}
      >
        {" "}
        <div className="course-img-area">
          <img src={Course.group_video_thumbnail_url} />
        </div>
      </Link>
      <div className="course-desc">
        <Link
          to={`/course/${Course.group_name}/${Course._id}`}
          style={{ color: "black" }}
        >
          <div className="display-flex">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "10px",
                marginTop: "5px",
              }}
              src={Course.group_image_url}
              alt="im-story-profile"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = profileDefualt;
              }}
            />
            <div>
              <div className="carouse-detail-title">{Course.group_name}</div>
              <div className="course-title">
                <div>{CourseCreatedByUser.user_name}</div>
              </div>
              <div className="course-price">{Course.subscription_price} KD</div>
            </div>
          </div>
        </Link>

        <div className="cart-icons-wrapper">
          {!isPurchased && (
            <div>
              <div
                onClick={() => {
                  addtocart(Course._id);
                }}
                className="display-flex display-flex-align-center"
              >
                <AiOutlineShoppingCart size={21} className="mr-1" />
                {!loadingCart && <div className="cart-texts">Add to cart</div>}
                {loadingCart && <Spin />}
              </div>
            </div>
          )}

          {!isPurchased && (
            <div
              onClick={() => {
                addWishList(Course._id);
              }}
              className="display-flex display-flex-align-center"
            >
              {!loadingWish && (
                <div className="cart-texts">Add to wishlist</div>
              )}
              {loadingWish && <Spin />}
              <div>
                <BsHeart size={19} />
              </div>
            </div>
          )}
        </div>
        <div className="flex-center">
          {isPurchased && (
            <div className="display-flex display-flex-align-center">
              <HiLightBulb size={22} />
              <Link to={`/session/${Course._id}`} style={{ color: "black" }}>
                <div className="cart-texts">Go to Course</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCardwithAddtoCart;
