import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

const AddLessionInput = ({
  setLessionTitle,
  lessionTittle,
  addLession,
  section_id,
}) => {
  const [lessionBoxShow, setLessionBoxShow] = useState(false);
  return (
    <div
      style={{
        padding: "0px 12px 12px 12px",
        fontSize: "16px",
        cursor: "pointer",
        fontWeight: "500",
      }}
    >
      <div onClick={() => setLessionBoxShow(!lessionBoxShow)}>
        <AiOutlinePlusCircle
          className="mr-2"
          style={{ alignSelf: "center", marginTop: "1px" }}
          size={30}
        />
        Add lesson
      </div>
      {lessionBoxShow && (
        <input
          style={{ width: "100%" }}
          placeholder="Add lesson Title"
          className="mt-2 p-1"
          type="text"
          value={lessionTittle}
          onChange={(e) => {
            setLessionTitle(e.target.value);
          }}
          onBlur={() => {
            setLessionBoxShow(true);
            addLession(section_id);
            setLessionBoxShow(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLessionBoxShow(true);
              addLession(section_id);
              setLessionBoxShow(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default AddLessionInput;
