const initialState = {
  refresh: false,
}

const RefreshReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'referesh':
      let isRef = state.refresh
      return { ...state, refresh: !isRef }
    default:
      return state
  }
}

export default RefreshReducers
