import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { FaShare, FaComment } from 'react-icons/fa'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { AiOutlineMore } from 'react-icons/ai'
import { BsSend } from 'react-icons/bs'

import { toast } from 'react-toastify'

import { fetchStory, buySubscriptionGroup } from '../../functions/story/story'

import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedData,
} from '../../functions/editProfile/editProfile'

import defualtImg from '../../images/Read&ShareThumbnail.png'
import chat from '../../images/chatwhite.svg'
import HlsMobileReelsPlayer from '../players/HlsMobileReelsPlayer'

import './viewstroy.css'

function useHookWithRefCallback() {
  const ref = useRef(null)
  const [isScrolLoaded, setIsScrolLoaded] = useState(false)
  const setRef = useCallback((node) => {
    if (ref.current) {
      setIsScrolLoaded(false)
    }

    if (node) {
      setIsScrolLoaded(true)
    }
    ref.current = node
  }, [])

  return [setRef, isScrolLoaded]
}

const ViewStoryMobile = ({ user, params_id }) => {
  const [story, setStory] = useState({})
  const [youtubeVideoId, setYoutubeVideoID] = useState({})
  const [storyPage, setStoryPage] = useState(1)
  const [totalStoryPage, setTotalStoryPage] = useState()
  const [loading, setLoading] = useState()
  const [showSignin, setShowSignin] = useState(false)
  const [showDummyCard, setShowDummyCard] = useState(false)
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true)

  const [isFollow, setIsFollow] = useState(false)
  const [isNotifyMeAll, setIsNotifyMeAll] = useState(false)
  const [catStories, setCatStories] = useState({})
  const dispatch = useDispatch()
  // const { CatStories } = useSelector((state) => ({ ...state }));
  const playerRef = useRef(null)
  const [ref, isScrolLoaded] = useHookWithRefCallback()
  useEffect(() => {
    let startFlag = true
    let initialScroll = window.scrollY
    let sectionsQty = 0
    let qty = 1,
      main = null,
      next = null

    const TIME_OUT = 600 // It should be the same transition time of the sections
    const body = document.querySelector('body')

    // Keep scrollbar in the middle of the viewport
    window.scroll(0, window.screen.height)

    const handalelistner = () => {
      console.log(qty, catStories.length)
      if (startFlag) {
        const scrollDown = window.scrollY >= initialScroll

        const scrollLimit = qty >= 1 && qty <= catStories.length + 1

        // Verify that the scroll does not exceed the number of sections
        if (scrollLimit) {
          if (scrollDown && qty < catStories.length + 1) {
            main = document.querySelector(`section.s${qty}`)
            next = document.querySelector(`section.s${qty + 1}`)

            if (main.getElementsByTagName('video').length > 0) {
              main.getElementsByTagName('video')[0].currentTime = 0
              main.getElementsByTagName('video')[0].pause()
            }

            if (next.getElementsByTagName('video').length > 0) {
              next.getElementsByTagName('video')[0].play()
            }
            main.style.transform = 'translateY(-100vh)'
            next.style.transform = 'translateY(0)'

            if (qty === catStories.length - 4) {
              loadStoriesBasedOnCategoryHandler(
                catStories[0].category_id,
                storyPage
              )
            }

            qty++
          } else if (!scrollDown && qty > 1) {
            main = document.querySelector(`section.s${qty - 1}`)
            next = document.querySelector(`section.s${qty}`)

            if (next.getElementsByTagName('video').length > 0) {
              next.getElementsByTagName('video')[0].currentTime = 0
              next.getElementsByTagName('video')[0].pause()
            }

            if (main.getElementsByTagName('video').length > 0) {
              main.getElementsByTagName('video')[0].play()
            }
            main.style.transform = 'translateY(0)'
            next.style.transform = 'translateY(100vh)'

            qty--
          }
          // Scroll progressbar
          const active = document.querySelector('.section-stick .stick.active')
          active.style.top = (62 + 30) * (qty - 1) + 'px'
        }

        // Wait for the scrolling to finish to reset the values
        setTimeout(() => {
          initialScroll = window.scrollY
          startFlag = true
          body.style.overflowY = 'scroll' // Unlock scroll
        }, TIME_OUT)

        startFlag = false
      }

      // Keep scrollbar in the middle of the viewport
      window.scroll(0, window.screen.height)
    }

    if (isScrolLoaded) {
      console.log('hello scroll')
      sectionsQty = catStories.length + 1

      const sectionStick = document.querySelector('.section-stick')
      Array(sectionsQty)
        .fill()
        .forEach(() => {
          sectionStick.innerHTML =
            sectionStick.innerHTML + '<div className="stick"></div>'
        })

      window.addEventListener('scroll', handalelistner)
    }

    return () => {
      body.style.overflowY = 'visible'
      window.removeEventListener('scroll', handalelistner)
    }
  }, [isScrolLoaded])

  useEffect(() => {
    document.body.style.backgroundColor = '#f6f6f6'
    storyDetails(params_id)
  }, [params_id, user.userData])

  const toggleDescription = (id) => {
    var element = document.getElementById(id)
    element.classList.toggle('active')
  }

  const storyDetails = async (story_id) => {
    try {
      let url = ''
      if (user.userData) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}&user_id=${user.userData.loginUser._id}`
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}`
      }

      const res = await getRequestedData(url)

      if (res.data.body.story.story_type === 'video_url') {
        const videoId = getId(res.data.body.story.story_url)
        setYoutubeVideoID(videoId)
      }
      setStory(res.data.body.story)

      if (res.data.body.story.story_converted_url !== '') {
        setHLSVIDEOPLAYER(true)
      }
      setIsFollow(res.data.body.isFollow)
      setIsNotifyMeAll(res.data.body.isNotifyMeAll)
      loadStoriesBasedOnCategoryHandler(res.data.body.story.category_id, 1)
    } catch (err) {
      console.log(err.message)
      toast.error(err.message)
    }
  }

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  const loadStoriesBasedOnCategoryHandler = async (categoryId, storyPageNo) => {
    setLoading(true)
    let url = ``
    if (user.userData) {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&user_id=${user.userData.loginUser._id}&storyIdToSkip=${params_id}&page=${storyPageNo}&storyType=video`
    } else {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&storyIdToSkip=${params_id}&page=${storyPageNo}&storyType=video`
    }
    try {
      let res = await getRequestedData(url)

      setStoryPage(storyPageNo + 1)
      if (storyPageNo > 1) {
        // dispatch({
        //   type: "cat_stories_append",
        //   payload: res.data.body.getStoryModel,
        // });

        setCatStories((oldArray) => [
          ...oldArray,
          ...res.data.body.getStoryModel,
        ])
        const sectionStick = document.querySelector('.section-stick')
        Array(res.data.body.getStoryModel.length)
          .fill()
          .forEach(() => {
            sectionStick.innerHTML =
              sectionStick.innerHTML + '<div className="stick"></div>'
          })
      } else {
        // dispatch({ type: "cat_stories", payload: res.data.body });

        setCatStories(res.data.body.getStoryModel)
      }
      setTotalStoryPage(res.data.body.Total_Page)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err.message)
      toast.error(err.message)
    }
  }

  const subscribeGroup = () => {
    setShowSignin(true)
  }

  const closeDummyCard = () => setShowDummyCard(false)

  const usubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === 'Subscribed') {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        }
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token)
          e.target.innerHTML = 'Subscribe'
          setIsFollow(res.data.body.isFollow)
          setIsNotifyMeAll(res.data.body.isNotifyMeAll)
        } catch (err) {
          console.log(err)
          toast.error(err.message)
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        }
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token)
          e.target.innerHTML = 'Subscribed'

          setIsFollow(res.data.body.isFollow)
          setIsNotifyMeAll(res.data.body.isNotifyMeAll)
        } catch (err) {
          console.log(err)
          toast.error(err.message)
        }
      }
    }
  }

  const buysubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === 'Subscribe') {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        }
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token)

          e.target.innerHTML = 'Subscribed'
          setIsFollow(res.data.body.isFollow)
          setIsNotifyMeAll(res.data.body.isNotifyMeAll)
        } catch (err) {
          console.log(err)
          toast.error(err.message)
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        }
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token)
          e.target.innerHTML = 'Subscribe'
          setIsFollow(res.data.body.isFollow)
          setIsNotifyMeAll(res.data.body.isNotifyMeAll)
        } catch (err) {
          console.log(err)
          toast.error(err.message)
        }
      }
    } else {
      e.target.innerHTML = 'Login To Subscribe'
      setTimeout(function () {
        e.target.innerHTML = 'Subscribe'
      }, 3000)
    }
  }

  const notifyHandler = async (notifyMeAll) => {
    if (user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/notifyMeWhenUserEachUpdates`
      const data = {
        user_id: user.userData.loginUser._id,
        subscriber_user_id: story.user_id._id,
        notifyMeAll: notifyMeAll,
      }
      try {
        let res = await getDataPostWithHeader(url, data, user.userData.token)
        setIsNotifyMeAll(res.data.body.isNotifyMeAll)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }

  return (
    <>
      <div className='layout'>
        <div className='section-stick'>
          <div className='stick active'></div>
        </div>

        {story._id && (
          <section className='s1'>
            <div className='home'>
              <div className='padding-right-mobile-card padding-right-card'>
                <div className='card-box-section'>
                  <a href='#'>
                    <div className='image-warap-ratio-adujestment'>
                      {story._id && story.story_type == 'video' && (
                        <>
                          {story.story_url_quality_versions.url_360 && (
                            <video
                              autoPlay
                              loop
                              playsInline
                              preload='metadata'
                              width='100%'
                              height='100%'
                            >
                              <source
                                src={story.story_url_quality_versions.url_720}
                                type='video/mp4'
                              />
                            </video>
                          )}

                          {!story.story_url_quality_versions.url_360 && (
                            <video
                              autoPlay
                              loop
                              playsInline
                              preload='metadata'
                              width='100%'
                              height='100%'
                            >
                              <source src={story.story_url} type='video/mp4' />
                            </video>
                          )}
                        </>
                      )}
                    </div>
                  </a>
                  <div className='thumb-section'>
                    <div className='thumb-section-left'>
                      <a href='#' className='image-thumb-section'>
                        <img
                          src={story.user_id.imageUrl}
                          alt='imagename'
                          className='image-thumb'
                          onError={(e) => {
                            e.target.onError = null
                            e.target.src = defualtImg
                          }}
                        />
                      </a>
                      <a href='#' className='description-thumb-section'>
                        <div
                          className='titile-card'
                          style={{ color: '#fff', marginTop: '0px' }}
                        >
                          {story.story_title}
                        </div>
                      </a>
                      {story.following && (
                        <button
                          className='btn btn-subscribe'
                          onClick={(e) => {
                            usubscribeGroup(story.user_id._id, e)
                          }}
                        >
                          Subscribed
                        </button>
                      )}
                      {!story.following && (
                        <button
                          className='btn btn-subscribe'
                          onClick={(e) => {
                            buysubscribeGroup(story.user_id._id, e)
                          }}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                    <div className='thumb-section-right'>
                      <div className='comments mt-2'>
                        <img
                          src={chat}
                          alt='imagename'
                          style={{ height: '37px' }}
                        />
                        {/* <FaComment size={30} /> */}
                      </div>

                      <div>
                        <HiOutlineLightBulb size={30} />
                      </div>

                      <div className='shares mt-2'>
                        <BsSend size={25} />
                      </div>

                      <div className='comments mt-2'>
                        <AiOutlineMore
                          size={30}
                          style={{ fontWeight: '600' }}
                          onClick={() => {
                            toggleDescription(story._id)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='thumb-longdesc'
                    onClick={() => {
                      toggleDescription(story._id)
                    }}
                  >
                    <div
                      className={`text-left description-view-story ml-2 mr-4 mt-2 ${
                        story.story_language_id.code === 'ar'
                          ? 'text-right'
                          : 'text-left'
                      }`}
                      style={{ color: '#fff' }}
                      id={story._id}
                    >
                      {parse(story.story_text)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {catStories.length > 0 &&
          catStories.map((story, i) => {
            if (story._id !== params_id)
              if (story.story_type === 'video')
                return (
                  <>
                    <section className={`s${i + 2}`} key={i + story._id}>
                      <div className='home'>
                        <div className='padding-right-mobile-card padding-right-card'>
                          <div className='card-box-section'>
                            <a href='#'>
                              <div className='image-warap-ratio-adujestment'>
                                {story._id && story.story_type === 'video' && (
                                  <>
                                    {story.story_url_quality_versions
                                      .url_720 && (
                                      <video
                                        loop
                                        playsInline
                                        preload='metadata'
                                        width='100%'
                                        height='100%'
                                      >
                                        <source
                                          src={
                                            story.story_url_quality_versions
                                              .url_720
                                          }
                                          type='video/mp4'
                                        />
                                      </video>
                                    )}

                                    {!story.story_url_quality_versions
                                      .url_720 && (
                                      <video
                                        loop
                                        playsInline
                                        preload='metadata'
                                        width='100%'
                                        height='100%'
                                      >
                                        <source
                                          src={story.story_url}
                                          type='video/mp4'
                                        />
                                      </video>
                                    )}
                                  </>
                                )}
                                {/* {story._id && story.story_type == 'text' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                  }}
                                >
                                  {story.thumbnail_url !== '' && (
                                    <img
                                      style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                      }}
                                      src={story.thumbnail_url}
                                      alt='im-story'
                                      onError={(e) => {
                                        e.target.onError = null
                                        e.target.src = defualtImg
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              {story._id && story.story_type == 'image' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <img
                                    style={{
                                      objectFit: 'cover',
                                      width: '100%',
                                    }}
                                    src={story.thumbnail_url}
                                    alt='im-story'
                                    onError={(e) => {
                                      e.target.onError = null
                                      e.target.src = defualtImg
                                    }}
                                  />
                                </div>
                              )}

                              {story._id && story.story_type == 'audio' && (
                                <>
                                  <audio
                                    controls
                                    src={story.story_url}
                                    className='story-margin '
                                    style={{ width: '100%' }}
                                  ></audio>
                                </>
                              )}
                              {story._id && story.story_type == 'video_url' && (
                                <>
                                  <div
                                    style={{
                                      position: 'relative',
                                      paddingTop: '56.25%',
                                    }}
                                  >
                                    <iframe
                                      src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
                                      style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        height: '100%',
                                        width: '100%',
                                      }}
                                      title='YouTube video player'
                                      frameborder='0'
                                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                      allowfullscreen='allowfullscreen'
                                      mozallowfullscreen='mozallowfullscreen'
                                      msallowfullscreen='msallowfullscreen'
                                      oallowfullscreen='oallowfullscreen'
                                      webkitallowfullscreen='webkitallowfullscreen'
                                    ></iframe>
                                  </div>
                                </>
                              )} */}
                              </div>
                            </a>
                            <div className='thumb-section'>
                              <div className='thumb-section-left'>
                                <a href='#' className='image-thumb-section'>
                                  <img
                                    src={story.user_id.imageUrl}
                                    alt='imagename'
                                    className='image-thumb'
                                    onError={(e) => {
                                      e.target.onError = null
                                      e.target.src = defualtImg
                                    }}
                                  />
                                </a>
                                <a
                                  href='#'
                                  className='description-thumb-section'
                                >
                                  <div
                                    className='titile-card'
                                    style={{ color: '#fff', marginTop: '0px' }}
                                  >
                                    {story.story_title}
                                  </div>
                                </a>
                                {story.following && (
                                  <button
                                    className='btn btn-subscribe'
                                    onClick={(e) => {
                                      usubscribeGroup(story.user_id._id, e)
                                    }}
                                  >
                                    Subscribed
                                  </button>
                                )}
                                {!story.following && (
                                  <button
                                    className='btn btn-subscribe'
                                    onClick={(e) => {
                                      buysubscribeGroup(story.user_id._id, e)
                                    }}
                                  >
                                    Subscribe
                                  </button>
                                )}
                              </div>
                              <div className='thumb-section-right'>
                                <div className='comments mt-2'>
                                  <img
                                    src={chat}
                                    alt='chat'
                                    style={{ height: '37px' }}
                                  />
                                  {/* 
                                <FaComment size={30} /> */}
                                </div>

                                <div>
                                  <HiOutlineLightBulb size={30} />
                                </div>

                                <div className='shares mt-2'>
                                  <BsSend size={26} />
                                </div>

                                <div className='comments mt-2'>
                                  <AiOutlineMore
                                    size={30}
                                    style={{ fontWeight: '600' }}
                                    onClick={() => {
                                      toggleDescription(story._id)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className='thumb-longdesc'
                              onClick={() => {
                                toggleDescription(story._id)
                              }}
                            >
                              <div
                                className={`text-left description-view-story ml-2 mr-4 mt-2 ${
                                  story.story_language_id.code === 'ar'
                                    ? 'text-right'
                                    : 'text-left'
                                }`}
                                style={{ color: '#fff' }}
                                id={story._id}
                              >
                                {parse(story.story_text)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {catStories.length - 1 === i && <div ref={ref}>fsdfds</div>}
                  </>
                )
          })}
      </div>
    </>
  )
}

export default ViewStoryMobile
