const defaultState = {
  userData: { user_name: "", gender: "", is_consultant: 0, lang: "" },
};

const RegSecondSectionReducers = (state = defaultState, action) => {
  switch (action.type) {
    case "userData":
      return { ...state, userData: action.payload };
    default:
      return state;
  }
};

export default RegSecondSectionReducers;
