import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "../css/home.css";
import "../pages/Carousel.css";
import {
  getRequestedData,
  getRequestedDataWithHeader,
  getDataPostWithOutHeader,
  getDataPostWithHeader,
} from "../functions/editProfile/editProfile";

import FooterMenu from "../navbar/FooterMenu";
import ArticlesDisplay from "../Components/home/ArticlesDisplay";
import UserSubscriptionStories from "../Components/home/UserSubscriptionStories";

import ViewHomeStoryReels from "../Components/ViewStory/ViewHomeStoryReels";
const Articles = (props) => {
  const [categories, setCategories] = useState([]);
  const [loadingCat, setLoadingCat] = useState(false);
  const [userID, setUserID] = useState("");
  const [subID, setsubID] = useState("");
  const [selectedLangisAllStories, setSelectedLang] = useState(false);
  const [languages, setLanguages] = useState(null);
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [coursePage, setCoursePage] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState();
  const [loadingUser, setLoadingUser] = useState(false);
  const [isSubStories, setSubStories] = useState(false);
  const [isUserProfileStories, setUserProfileStories] = useState(false);
  const [isAllStories, setAllStories] = useState(false);
  const [subMobilePage, setSubMobilePage] = useState(false);
  const [sessionMobilePage, setSessionMobilePage] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [isUserStoryPage, setIsUserStoryPage] = useState(true);
  const [coursesDetails, setCoursesDetails] = useState({});
  const [courses, setCourses] = useState([]);
  const categoryUserMsg = useRef(null);
  const categoryMsg = useRef(null);

  const dispatch = useDispatch();
  const {
    BasicHomePageReducer: { component },
    UserRequiredDropDown,
  } = useSelector((state) => ({
    ...state,
  }));

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    setSubMobilePage(false);
    setSessionMobilePage(false);
    setUserProfileStories(false);
    dispatch({ type: "EMPTY_SEARCH_USER" });
  }, []);

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    setSelectedLang(userLang.split("-")[0]);
    if (props.user.isLogin) {
      categgoryBasedOnUserLangauge();
    } else {
      getUserCategoryBasedOnMachineLanguage(userLang.split("-")[0]);
    }
  }, [props.user.isLogin]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getLanguages`;
    getRequestedData(url)
      .then((res) => {
        setLanguages(res.data.body);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, []);

  const getUserCategoryBasedOnMachineLanguage = async (lang) => {
    setLoadingCat(true);
    setSelectedLang(lang);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/categories?tagType=${props.type}`;
    try {
      let res = await getRequestedData(url);
      if (categoryUserMsg.current) categoryUserMsg.current.innerHTML = "";
      setCategories(res.data.body);
      setLoadingCat(false);
      if (res.data.body.length <= 0) {
        if (categoryUserMsg.current)
          categoryMsg.current.innerHTML =
            "Sorry there are no categories found for your languages";
      } else {
        if (categoryUserMsg.current) categoryMsg.current.innerHTML = "";
      }
    } catch (err) {
      setLoadingCat(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const categgoryBasedOnUserLangauge = async () => {
    //setLoadingCat(true)
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/categories?tagType=${props.type}`;

      let res = await getRequestedData(url);

      if (categoryUserMsg.current) categoryUserMsg.current.innerHTML = "";
      setCategories(res.data.body);
      setLoadingCat(false);
      if (res.length <= 0) {
        if (categoryUserMsg.current)
          categoryMsg.current.innerHTML =
            "Sorry there are no category for your language";
      } else {
        if (categoryUserMsg.current) categoryMsg.current.innerHTML = "";
      }
    } catch (err) {
      setLoadingCat(false);
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-12`}
            style={{
              marginTop: "60px",
            }}
          >
            <ArticlesDisplay
              {...props}
              userID={userID}
              subID={subID}
              storyPage={storyPage}
              setStoryPage={setStoryPage}
              totalStoryPage={totalStoryPage}
              setTotalStoryPage={setTotalStoryPage}
              loadingUser={loadingUser}
              setLoadingUser={setLoadingUser}
              setSubStories={setSubStories}
              isSubStories={isSubStories}
              setUserProfileStories={setUserProfileStories}
              isUserProfileStories={isUserProfileStories}
              setAllStories={setAllStories}
              isAllStories={isAllStories}
              categoryUserMsg={categoryUserMsg}
              categoryMsg={categoryMsg}
              loadingCat={loadingCat}
              categories={categories}
              mobile={mobile}
              type={props.type}
            />
          </div>

          {!loadingUser && (
            <div className="fixed-bottom d-block d-sm-none">
              <FooterMenu user={props.user} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Articles;
