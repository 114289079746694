import React from "react";
import { Link } from "react-router-dom";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import profileDefualt from "../../images/default.jpeg";
import parse from "html-react-parser";

const CourseCardNew = ({ course }) => {
  return (
    <>
      <div className="card-box story-card-box course-card-box">
        <Link to={`/course/${course.group_name}/${course._id}`}>
          {course.group_video_thumbnail_url && (
            <div className="course-cover-box">
              <img
                className="bio-consultant course-cover"
                src={course.group_video_thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defualtImg;
                }}
              />
            </div>
          )}
        </Link>
        <div className="story-card-box-profile card-profile-course">
          <Link to={`/course/${course.group_name}/${course._id}`}>
            <img
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "50%",
                float: "left",
                marginTop: "12px",
              }}
              src={course.group_image_url}
              alt="im-story-profile"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = profileDefualt;
              }}
            />
          </Link>
          <Link to={`/course/${course.group_name}/${course._id}`}>
            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{course.group_name}</div>
            </div>
          </Link>
        </div>
        <Link to={`/course/${course.group_name}/${course._id}`}>
          {!course.group_video_thumbnail_url && (
            <div
              className={`${
                course.language_id.code === "ar" ? "text-right" : "text-left"
              } ml-2`}
              style={{ color: "black" }}
            >
              {course.group_description &&
                course.group_description.length <= 350 &&
                parse(course.group_description)}
              {course.group_description &&
                course.group_description.length > 350 &&
                parse(course.group_description.substring(0, 350))}
            </div>
          )}
          {course.group_video_thumbnail_url && (
            <div
              className={`${
                course.language_id.code === "ar" ? "text-right" : "text-left"
              } description-box-course ml-2 mt-2`}
              style={{ color: "black" }}
            >
              {course.group_description &&
                course.group_description.length <= 50 &&
                parse(course.group_description)}
              {course.group_description &&
                course.group_description.length > 50 &&
                parse(course.group_description.substring(0, 200))}
            </div>
          )}
        </Link>
      </div>
    </>
  );
};

export default CourseCardNew;
