import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getRequestedDataWithHeader } from "../functions/editProfile/editProfile";
import ViewChannelsList from "../Components/Admin/Metrics/ViewChannelsList";

const Dashbored = ({ user }) => {
  const history = useHistory();
  const [countriesWithGenderLists, setcountriesWithGenderLists] = useState([]);
  const [channelLists, setChannelLists] = useState({
    usersCounts: [],
    channelCounts: [],
  });
  const [viewChanneList, setViewChannelList] = useState("");
  useEffect(() => {
    getUserChannelMetrics();
  }, []);

  const getUserMetrics = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/usermetrics`;

    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      console.log(res.data.body);
      setcountriesWithGenderLists(res.data.body);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getUserChannelMetrics = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/userChannelMetrics`;

    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      setChannelLists(res.data.body);
    } catch (err) {
      console.log(err.message);
    }
  };

  // const getUserNoChannelMetrics = async () => {
  //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/userwithnochannelmetrics`

  //   try {
  //     let res = await getRequestedDataWithHeader(url, user.userData.token)
  //     setUserNoChannelLists(res.data.body)
  //   } catch (err) {
  //     console.log(err.message)
  //   }
  // }
  return (
    <div className="dashbored">
      <div className="container-fluid">
        <div className="row">
          {!viewChanneList && (
            <>
              <div className="col-md-3">
                <div className="satatistics-card">
                  <div className="pl-2 pb-2">Channels</div>
                  <table className="table cursor-pointer">
                    <tr>
                      <th>Countries</th>
                      <th>Channels</th>
                      <th>Users</th>
                    </tr>

                    {channelLists.usersCounts.length > 0 &&
                      channelLists.usersCounts.map(
                        (countriesWithGenderList, index) => {
                          return (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  history.push(
                                    `/min/channel-list?country_ID=${countriesWithGenderList.countryInfo._id}`,
                                  );
                                }}
                              >
                                {
                                  countriesWithGenderList.countryInfo
                                    .country_english
                                }
                              </td>
                              <td>{countriesWithGenderList.userwithchannel}</td>
                              <td>{countriesWithGenderList.totalusers}</td>
                            </tr>
                          );
                        },
                      )}
                  </table>
                </div>
              </div>
            </>
          )}

          {viewChanneList && (
            <ViewChannelsList
              country_ID={viewChanneList}
              user={user}
              setViewChannelList={setViewChannelList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashbored;
