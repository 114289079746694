import React from 'react'
import ReactDOM from 'react-dom'
import './Modal.css'

const ModalOverlay = (props) => {
  const content = <div className='model-bg'>{props.children}</div>
  return ReactDOM.createPortal(
    content,
    document.getElementById('crop-modal-hook')
  )
}

const ModelStory = (props) => {
  return (
    <React.Fragment>{props.show && <ModalOverlay {...props} />}</React.Fragment>
  )
}

export default ModelStory
