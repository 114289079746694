import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeOff,
  FaCog,
  FaExpand,
  FaCompress,
} from "react-icons/fa";

import { BsCheck2 } from "react-icons/bs";
import "./player.css";
import Hls from "hls.js";

function useHookWithRefCallback(url, setHLSVIDEOPLAYER) {
  const videoref = useRef(null);
  const [levels, setLevels] = useState([]);
  const setRef = useCallback((node) => {
    if (videoref.current) {
    }

    if (node) {
      if (Hls.isSupported()) {
        console.log(true, "supported");
        //var video = document.getElementById("video");
        var video = document.getElementById("video");
        var hls = new Hls();

        hls.loadSource(url);
        // bind them together
        hls.attachMedia(video);

        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          console.log("video and hls.js are now bound together !");
          video.play();
        });

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level",
          );
          setLevels(hls.levels.reverse());
        });

        hls.on(Hls.Events.ERROR, function (event, data) {
          setHLSVIDEOPLAYER(false);
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.log("fatal media error encountered, try to recover");
                hls.recoverMediaError();
                break;
              case Hls.ErrorTypes.NETWORK_ERROR:
                alert("fatal network error encountered", data);
                console.error("fatal network error encountered", data);
                // All retries and media options have been exhausted.
                // Immediately trying to restart loading could cause loop loading.
                // Consider modifying loading policies to best fit your asset and network
                // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
                break;
              default:
                // cannot recover
                alert("player error");
                hls.destroy();
                break;
            }
          }
        });
      } else {
        alert("Update browers for best videoplayer");
        setHLSVIDEOPLAYER(false);
      }

      window.hls = hls;
    }
    videoref.current = node;
  }, []);

  return [setRef, videoref, levels];
}

const HlsPlayer = ({ url, setHLSVIDEOPLAYER, mobile }) => {
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);
  const [volumeSliderValue, setVolumeSliderValue] = useState(1);
  const [expand, setExapand] = useState(false);
  const [qualityShow, setQualityShow] = useState(false);
  const [selctedQuality, setSelctedQuality] = useState(-1);
  //const videoref = useRef(null);
  const videaWrapperRef = useRef(null);
  const progressBarRef = useRef(null);
  const [ref, videoref, levels] = useHookWithRefCallback(
    url,
    setHLSVIDEOPLAYER,
  );
  useEffect(() => {
    setPlay(!play);
    const chekFullScreen = () => {
      if (!document.fullscreenElement) {
        setExapand(false);
      }
    };

    document.addEventListener("webkitfullscreenchange", chekFullScreen);
    document.addEventListener("mozfullscreenchange", chekFullScreen);
    document.addEventListener("fullscreenchange", chekFullScreen);

    return () => {
      document.removeEventListener("webkitfullscreenchange", chekFullScreen);
      document.removeEventListener("mozfullscreenchange", chekFullScreen);
      document.removeEventListener("fullscreenchange", chekFullScreen);
    };
  }, []);

  const playSelectedQuality = (levelIndex) => {
    setSelctedQuality(levelIndex);
    setQualityShow(false);

    if (levelIndex >= 0) {
      window.hls.currentLevel = levelIndex;
    } else {
      window.hls.currentLevel = -1;
    }

    if (!play) {
      videoref.current.pause();
    } else {
      videoref.current.play();
    }
  };

  const qualityCHeck = () => {
    var hls = new Hls();
    console.log(window.hls.currentLevel);
    console.log(window.hls.autoLevelEnabled);
  };

  const togglePlayvideo = () => {
    setPlay(!play);

    if (play) {
      videoref.current.pause();
    } else {
      videoref.current.play();
    }
  };

  const toggleVlume = (volume) => {
    setVolume(volume);
    videoref.current.volume = volume;
    setVolumeSliderValue(volume);
  };

  const volumeContorl = (e) => {
    setVolume(e.target.value);
    if (e.target.value == 0) setVolume(0);
    videoref.current.volume = e.target.value;
  };

  let turnFullscreen = (fullscreen) => {
    setExapand(fullscreen);
    if (fullscreen) {
      if (videaWrapperRef.current.requestFullScreen) {
        videaWrapperRef.current.requestFullScreen();
      } else if (videaWrapperRef.current.webkitRequestFullScreen) {
        videaWrapperRef.current.webkitRequestFullScreen();
      } else if (videaWrapperRef.current.mozRequestFullScreen) {
        videaWrapperRef.current.mozRequestFullScreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msCancelFullScreen) {
        document.msCancelFullScreen();
      }
    }
  };

  const videoLoading = (e) => {
    console.log("loading..");
  };

  const progressBar = (e) => {
    let progress = (100 / e.target.duration) * e.target.currentTime;
    document
      .querySelector(".progress__current")
      .setAttribute("style", `width:${progress}%`);
  };

  const progressMoveOnClick = (e) => {
    let goToTime =
      (e.nativeEvent.offsetX / progressBarRef.current.offsetWidth) *
      videoref.current.duration;
    videoref.current.currentTime = goToTime;
  };
  return (
    <div className={`video-container ${expand && ""}`} ref={videaWrapperRef}>
      <video
        className="video-container__video"
        ref={ref}
        id="video"
        autoPlay
        loop
        playsInline
        preload="metadata"
        width="100%"
        height="100%"
      ></video>
    </div>
  );
};

export default HlsPlayer;
