import React, { useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { BsThreeDotsVertical } from "react-icons/bs";
import profileDefualt from "../../images/default.jpeg";
import CourseCardNew from "../cards/CourseCardNew";
import CourseCard from "../cards/CourseCard";

import {
  responseMessage,
  responseMessageClear,
} from "../../utils/sharedfunction";

import {
  getRequestedData,
  getDataPostWithOutHeader,
  getDataPostWithHeader,
} from "../../functions/editProfile/editProfile";

const UserCourses = ({ user, language_id, menuType }) => {
  const [isloading, setIsLoading] = useState(false);
  const [coursePage, setCoursePage] = useState(1);
  const [courses, setCourses] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState();
  const intialLoadErrorMessageRef = useRef(null);
  const recursionLoadErrorMessageRef = useRef(null);
  const stroyBoxRef = useRef(null);

  useEffect(() => {
    geUserCourses(1);
  }, [language_id, menuType]);

  const geUserCourses = async (page_no) => {
    try {
      let coursetype = menuType === "COURSES" ? 1 : 0;
      setIsLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userCoursesByLanguage?user_id=${user._id}&language_id=${language_id}&coursetype=${coursetype}&page=${page_no}`;

      setCoursePage(page_no + 1);
      let res = await getRequestedData(url);
      setCourses(res.data.body.courses);
      setTotalCoursePage(res.data.body.Total_Page);
      setIsLoading(false);
    } catch (errr) {
      setIsLoading(false);
      console.log(errr.message);
      responseMessage(intialLoadErrorMessageRef, "Something Went Wrong", "red");
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-center margin-top-5"
        ref={intialLoadErrorMessageRef}
      ></div>
      {isloading && (
        <div className="d-flex justify-content-center margin-top-5">
          <div className="spinner-border text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="container-fluid story-card-box-section course-card-box-section">
        <div className="row">
          {courses.length > 0 &&
            courses.map((course, index) => {
              return (
                <div
                  className={`col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-3`}
                  key={course._id}
                >
                  <CourseCardNew course={course} />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default UserCourses;
