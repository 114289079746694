import React, { useRef, useState } from "react";
import axios, { CancelToken, isCancel } from "axios";
import {
  AiFillCamera,
  AiOutlinePlayCircle,
  AiOutlineClose,
} from "react-icons/ai";
import profileDefualt from "../../images/default.jpeg";
import addcover from "../../images/default-thumbnail.png";
import Modal from "../../utils/models/Modal";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";
import ImageCrop from "../ImageCrop";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  dataURLtoBlob,
  videoPickerHandler,
  setWithExpiry,
  getWithExpiry,
} from "../../utils/sharedfunction";
const url = process.env.REACT_APP_BACKEND_URL;

const IntroImageVideo = ({
  groupID,
  user,
  setVideoFile,
  videoFile,
  getPresignedUrl,
  videoUploadPercentage,
  videoProcessingUpload,
}) => {
  const profileImageRef = useRef(null);
  const videoImageRef = useRef(null);
  const [showCrop, setShowCrop] = useState(false);
  const bioImageRef = useRef(null);
  const menuBoxBioRef = useRef(null);
  const videoPickerRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const videobioUpload = useRef(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const dispatch = useDispatch({});

  const imagefileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    profileImageRef.current.src = file;
    var path = `${url}/api/userProfile/updateSubscriptionImage`;
    var type = "image";
    uploadVideoHandler(type, path, imgeFile, file);
  };

  const videofileInputHandler = (file) => {
    let blobURL = URL.createObjectURL(file);

    setVideoFile({
      video: file,
      imageBlob: "",
      videoImg: "",
      videoUrl: blobURL,
    });
    // var path = `${url}/api/userProfile/SubscriptionGroupVideo`;
    // var type = "video";
    // uploadVideoHandler(type, path, imgeblob, imgeval, file);

    const dataVideo = {
      user_id: user.userData.loginUser._id,
      keyname: "userFiles/Courses",
      filename: file.name,
      fileType: file.type,
    };
    getPresignedUrl("getPresignedURLForVideUpload", dataVideo, file);
  };

  const uploadVideoHandler = (type, path, Cover, base64img, videoFile) => {
    let formData = new FormData();

    if (type === "video") {
      formData.append("video", videoFile);
      let isCoverPicBio = getWithExpiry("introImageVideo");
      if (isCoverPicBio === null) {
        formData.append("video_cover", Cover);
      } else {
        var imgeFile = dataURLtoBlob(isCoverPicBio);
        formData.append("video_cover", imgeFile);
      }
    } else {
      formData.append("image", Cover);
      setWithExpiry("introImageVideo", base64img, 100000);
    }
    formData.append("subscription_id", groupID);
    const options = {
      headers: {
        Authorization: `Bearer ${user.userData.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        setUploadPercentage(0);
        if (type === "video") {
          videoImageRef.current.src = base64img;
          videobioUpload.current.src = "";
        }

        toast.success("Successfully updated");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (type === "video") {
          videobioUpload.current.src = "";
        } else {
          //bioImageRef.current.src = ''
        }
        setUploadPercentage(0);
        if (isCancel(err)) {
          alert(err.message);
          toast.error("Aborted");
        } else {
          toast.error("System Failed");
        }
      });
  };
  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };
  const closeSubMenuHandler = () => {
    menuBoxBioRef.current.style.display = "none";
  };
  const openProfileHandler = () => {
    setShowCrop(true);
  };

  const closeProfileHandler = () => setShowCrop(false);
  return (
    <>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 400, height: 400 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>

      {/* <div
        style={{
          display: "flex",
          height: "400px",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid",
            }}
          >
            <img
              src={profileDefualt}
              alt="add-img"
              ref={profileImageRef}
              style={{ height: "200px", width: "200px" }}
            />
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="btn btn-success" onClick={openProfileHandler}>
              Uplaod Image
            </span>
          </div>
        </div>
      </div> */}

      <div
        style={{
          width: "70%",
          margin: "auto",
          marginTop: "24px",
        }}
      >
        <div>
          {!videoFile.video && (
            <img
              src={addcover}
              alt="add-img"
              ref={videoImageRef}
              style={{ width: "100%" }}
              onClick={() => {
                videoPickerHandler(videoPickerRef.current);
              }}
            />
          )}

          {videoFile.video && (
            <video controls src={videoFile.videoUrl} style={{ width: "100%" }}>
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <div
          style={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <VideoUpload
            videoFilePickerRef={videoPickerRef}
            videourlref={videobioUpload}
            onvideofileInput={videofileInputHandler}
          />

          <video
            width="100%"
            controls
            ref={videobioUpload}
            style={{ display: "none" }}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {videoUploadPercentage > 0 && videoUploadPercentage < 100 && (
        <div className="row mt-3 m-3">
          <div className="col pt-1">
            <div className="progress" style={{ width: "70%", margin: "auto" }}>
              <div
                className="progress-bar bg-success progress-bar-striped"
                role="progressbar"
                style={{ width: `${videoUploadPercentage}%`, height: "100%" }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {videoUploadPercentage}%
              </div>
            </div>
          </div>
          <div className="col-auto">
            <span
              className="text-primary cursor-pointer"
              onClick={() => cancelUpload()}
            >
              <AiOutlineClose />
            </span>
          </div>
        </div>
      )}
      {videoProcessingUpload && (
        <div style={{ width: "70%", margin: "auto" }}>
          <div className="loading mt-3 m-3" style={{ textAlign: "center" }}>
            Processing...
          </div>
        </div>
      )}
      {uploadPercentage > 0 && (
        <div className="row mt-3">
          <div className="col pt-1">
            <div className="progress">
              <div
                className="progress-bar bg-success progress-bar-striped"
                role="progressbar"
                style={{ width: `${uploadPercentage}%`, height: "100%" }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadPercentage}%
              </div>
            </div>
          </div>
          <div className="col-auto">
            <span
              className="text-primary cursor-pointer"
              onClick={() => cancelUpload()}
            >
              <AiOutlineClose />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default IntroImageVideo;
