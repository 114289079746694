import React, { useState } from "react";

import WhatToLearn2 from "../subscriptionGroup/WhatToLearn2";
import PreRequired from "../subscriptionGroup/PreRequired";
import CourseTitile from "./CourseTitile";
import Langauge from "./Langauge";
import CategoryCourse from "./CategoryCourse";
import Price from "./Price";
import { width } from "@mui/system";

const SectionType = ({
  inputFields,
  setInputFields,
  inputFieldsPreRequired,
  setInputFieldsPreRequired,
  setNext,
  menu,
  setMenu,
  title,
  setTitle,
  user,
  language,
  setProfileId,
  langaugeId,
  setLanguage,
  setLangaugeId,
  setCategory,
  category,
  description,
  setDescription,
  setCurrency,
  currency,
  price,
  setPrice,
  setNewCategory,
  newCategory,
}) => {
  const placeholder = [
    "Example: you will be good react developer",
    "Example: you can build your own apps",
  ];

  const placeholderPreRequired = [
    "Example: Network connection and a laptop",
    "Example: No Coding experiance is needed",
  ];

  return (
    <>
      <div
        style={{
          padding: "2rem 2.4rem",
          fontSize: "16px",
        }}
      >
        {/* <div
          style={{
            marginBottom: "2.4rem",
            fontSize: "16px",
          }}
        >
          Sections type course will have Sections for each group of lessions, it
          will help people for better understanding, it's like going chapter
          wise.
        </div>
        <div
          style={{
            marginBottom: "2.4rem",
            fontSize: "16px",
          }}
        >
          The following descriptions will be publicly visible on your Course
          Landing Page and will have a direct impact on your course performance.
          These descriptions will help learners decide if your course is right
          for them.
        </div> */}

        <div>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              padding: "5px",
            }}
          >
            <span style={{ padding: "5px", width: "140px" }}>
              Course language :
            </span>

            <Langauge
              user={user}
              language={language}
              langaugeId={langaugeId}
              setProfileId={setProfileId}
              setLanguage={setLanguage}
              setLangaugeId={setLangaugeId}
              selectStyle={{
                background: "white",
                padding: "5px",
                flexGrow: "1",
                border: "none",
                borderBottom: "1px solid #909090",
              }}
            />
          </div>

          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              padding: "5px",
            }}
          >
            <span style={{ padding: "5px", width: "140px" }}>
              Course category :
            </span>

            <CategoryCourse
              user={user}
              language={language}
              langaugeId={langaugeId}
              category={category}
              setCategory={setCategory}
              setNewCategory={setNewCategory}
              newCategory={newCategory}
              selectStyle={{
                background: "white",
                padding: "5px",
                flexGrow: "1",
                border: "none",
                borderBottom: "1px solid #909090",
                width: "100%",
              }}
            />
          </div>
          <CourseTitile setNext={setNext} title={title} setTitle={setTitle} />

          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              padding: "5px",
            }}
          >
            <span style={{ padding: "5px", width: "140px" }}>Currency : </span>

            <Price
              user={user}
              currency={currency}
              setCurrency={setCurrency}
              price={price}
              setPrice={setPrice}
              selectStyle={{
                background: "white",
                padding: "5px",
                flexGrow: "1",
                border: "none",
                borderBottom: "1px solid #909090",
              }}
            />
          </div>

          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              padding: "5px",
            }}
          >
            <span style={{ padding: "5px", width: "140px" }}>Add price :</span>
            <input
              type="number"
              defaultValue=""
              placeholder="Enter your price"
              style={{
                background: "white",
                padding: "5px",
                flexGrow: "1",
                border: "none",
                borderBottom: "1px solid #909090",
              }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div
            style={{
              maxWidth: "1000px",
              width: "100%",
              margin: "auto",
              marginBottom: "15px",
            }}
          >
            <span>Course Description</span>
            <div style={{ textAlign: "center", margin: "auto" }}>
              <textarea
                style={{ width: "100%", height: "150px" }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "2.4rem",
            marginBottom: "2.4rem",
            fontSize: "16px",
          }}
        >
          <h4>What will students learn in your course?</h4>
          <p style={{ marginBottom: "20px" }}>
            You must enter at least 2 learning objectives or outcomes that
            learners can expect to achieve after completion of course.
          </p>
          <WhatToLearn2
            inputFields={inputFields}
            setInputFields={setInputFields}
            placeholder={placeholder}
          />
          <h4 style={{ marginTop: "20px" }}>
            What are the requirements or prerequisites for taking your course?
          </h4>
          <p style={{ marginBottom: "20px" }}>
            List the required skills, experience, tools or equipment learners
            should have prior to taking your course.
          </p>
          <WhatToLearn2
            inputFields={inputFieldsPreRequired}
            setInputFields={setInputFieldsPreRequired}
            placeholder={placeholderPreRequired}
          />
        </div>
      </div>
    </>
  );
};

export default SectionType;
