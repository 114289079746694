import React, { useEffect, useState } from "react";
import CourseCard2 from "../Components/cards/CourseCard2";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";

const WishLists = ({ user }) => {
  const [wishList, setWishList] = useState([]);
  useEffect(() => {
    getWishLists();
  }, []);
  const getWishLists = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getWishlists?user_id=${user.userData.loginUser._id}`;
    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      console.log(res.data.body);
      setWishList(res.data.body);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h2>Wish Lists</h2>

          <div className="row">
            {wishList.length > 0 &&
              wishList.map((cart) => {
                return (
                  <div
                    key={cart._id}
                    className="col-sm-6 col-md-3 col-xl-3 col-xxl-2 "
                  >
                    <CourseCard2
                      course={cart.course_id}
                      userid={user.userData.loginUser._id}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishLists;
