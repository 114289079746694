import React, { useEffect, useState, useRef } from "react";

import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";
import { BsTrash, BsHeart } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LoadingCard from "../Components/cards/LoadingCard";
import "../css/cart.css";
import videoCover from "../images/uplaodVideo.png";
import { useReactToPrint } from "react-to-print";
const Cart = ({ user }) => {
  const printRef = useRef();
  const dispatch = useDispatch({});
  const [carts, setCarts] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingWish, setLoadingWish] = useState(false);
  useEffect(() => {
    getCarts();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const getCarts = async () => {
    setLoadingCart(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getCart?user_id=${user.userData.loginUser._id}`;

    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      setCarts(res.data.body.courses);
      setCartTotal(res.data.body.priceTotol);
      dispatch({ type: "CARTTOTAL", payload: res.data.body.courses.length });
      dispatch({
        type: "CARTCHECKOUT",
        payload: {
          courses: res.data.body.courses,
          cartTotalPrice: res.data.body.priceTotol,
        },
      });
      getWishLists();
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
    setLoadingCart(false);
  };

  const getWishLists = async () => {
    setLoadingWish(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getWishlists?user_id=${user.userData.loginUser._id}`;
    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      setWishList(res.data.body);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
    setLoadingWish(false);
  };

  const removefromCart = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/removeFromCart`;
    const data = {
      user_id: user.userData.loginUser._id,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.userData.token);
      setCarts(res.data.body.courses);
      setCartTotal(res.data.body.priceTotol);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const removeOneCart = async (cartid) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/removeOneFromCart`;
    const data = {
      user_id: user.userData.loginUser._id,
      cart_id: cartid,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.userData.token);
      setCarts(res.data.body.courses);
      setCartTotal(res.data.body.priceTotol);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const removeFromWishList = async (cartid) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/removeOneFromWishList`;
    const data = {
      user_id: user.userData.loginUser._id,
      wishlist_id: cartid,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.userData.token);
      setWishList(res.data.body);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const moveToCart = async (cartid, course_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/removeOneFromWishList`;
    const data = {
      user_id: user.userData.loginUser._id,
      wishlist_id: cartid,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.userData.token);
      setWishList(res.data.body);
      addtocart(course_id);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const addtocart = async (course_id) => {
    let userData = "";
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("userDataSessionWay")) {
        userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
      }
    } else {
      alert("Please update your browser");
    }
    const data = {
      course_id: course_id,
      user_id: userData.loginUser._id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addtocart`;
      let res = await getDataPostWithHeader(url, data, userData.token);
      getCarts();
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const moveToWishList = async (cartid, course_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/removeOneFromCart`;
    const data = {
      user_id: user.userData.loginUser._id,
      cart_id: cartid,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.userData.token);
      setCarts(res.data.body.courses);
      setCartTotal(res.data.body.priceTotol);
      addWishList(course_id);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const addWishList = async (course_id) => {
    let userData = "";
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem("userDataSessionWay")) {
        userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
      }
    } else {
      alert("Please update your browser");
    }
    const data = {
      course_id: course_id,
      user_id: userData.loginUser._id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addtowishlist`;
      let res = await getDataPostWithHeader(url, data, userData.token);
      getWishLists();
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  // const handleCart = async () => {
  //   let userData = "";
  //   if (typeof Storage !== "undefined") {
  //     if (localStorage.getItem("userDataSessionWay")) {
  //       userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
  //     }
  //   } else {
  //     alert("Please update your browser");
  //   }
  //   setLoading(true);

  //   const subs = carts.map((cart) => {
  //     return cart.course_id._id;
  //   });

  //   const data = {
  //     user_id: userData.loginUser._id,
  //     subscription_id: subs,
  //   };

  //   try {
  //     let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/purchaseSubscriptionGroups`;
  //     let res = await getDataPostWithHeader(url, data, userData.token);
  //     removefromCart();
  //   } catch (err) {
  //     console.log(err.message);
  //     alert(err.message);
  //   }
  //   setLoading(false);
  // };

  return (
    <div className="container-fluid" ref={printRef}>
      <div className="row">
        <div className="col-md-12">
          <h2>Shopping Cart</h2>
        </div>
        <div className="col-md-8">
          <div>
            <div>{carts.length > 0 && carts.length} Course in cart</div>
            <hr />
            {loadingCart && <LoadingCard count={3} defualt />}
            {!loadingCart &&
              carts.length > 0 &&
              carts.map((cart) => {
                return (
                  <div
                    key={cart._id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="mb-2"
                  >
                    <div style={{ display: "flex" }}>
                      <img
                        id="thumnail_bio"
                        src={cart.course_id.group_video_thumbnail_url}
                        className="cursor-pointer mr-2"
                        alt="thumbnail"
                        style={{ height: "100px" }}
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = videoCover;
                        }}
                      />
                      <div style={{ fontSize: "16px", fontWeight: "600" }}>
                        {cart.course_id.group_name}
                      </div>
                    </div>
                    <div
                      className="text-primary"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      <div className="mb-1" style={{ textAlign: "end" }}>
                        {cart.course_id.subscription_price}{" "}
                        {cart.course_id.currency_id.currency_cd}
                      </div>
                      <div
                        className="cursor-pointer mb-1"
                        style={{ textAlign: "end" }}
                        onClick={() => removeOneCart(cart._id)}
                      >
                        <BsTrash />
                        <span className="ml-1">Remove</span>
                      </div>
                      <div
                        className="cursor-pointer mb-1"
                        onClick={() =>
                          moveToWishList(cart._id, cart.course_id._id)
                        }
                      >
                        <BsHeart />
                        <span className="ml-1">Move to WishList</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div style={{ marginTop: "2.5rem" }}>
            {wishList.length > 0 && (
              <h3 style={{ borderBottom: "1px solid #d1d7dc" }}>
                Recently wishlisted
              </h3>
            )}
            {loadingWish && <LoadingCard count={3} defualt />}
            {!loadingWish &&
              wishList.length > 0 &&
              wishList.map((cart) => {
                return (
                  <div
                    key={cart._id}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="mb-2"
                  >
                    <div style={{ display: "flex" }}>
                      <img
                        id="thumnail_bio"
                        src={cart.course_id.group_video_thumbnail_url}
                        className="cursor-pointer mr-2"
                        alt="thumbnail"
                        style={{ height: "100px" }}
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = videoCover;
                        }}
                      />
                      <div style={{ fontSize: "16px", fontWeight: "600" }}>
                        {cart.course_id.group_name}
                      </div>
                    </div>
                    <div
                      className="text-primary"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      <div
                        className="cursor-pointer mb-1"
                        style={{ textAlign: "end" }}
                        onClick={() => removeFromWishList(cart._id)}
                      >
                        <BsTrash />
                        <span className="ml-1">Remove</span>
                      </div>
                      <div
                        className="cursor-pointer mb-1"
                        onClick={() => moveToCart(cart._id, cart.course_id._id)}
                      >
                        <span className="ml-1">Move to cart</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="col-md-4">
          Totol:
          <hr />
          <div style={{ fontSize: "20px", fontWeight: "600" }} className="mb-4">
            {cartTotal > 0 && cartTotal}
          </div>
          <div>
            <Link
              to="/payment/checkout"
              className="btn btn-info btn-text-cenetr "
              style={{ width: "100%" }}
            >
              CheckOut
              {loading && (
                <span className="loader-spiner loader-spiner-addtionl"></span>
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
