import React from 'react'

const ErrorSection = () => {
  return (
    <div className='registration-model-container'>
      <div className='registration-modal-headers '>
        <h5 className='registration-modal-title'>SomeThing Went Wrong</h5>
        <h5>Please Try again later</h5>
      </div>
    </div>
  )
}

export default ErrorSection
