import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import axios, { CancelToken, isCancel } from "axios";

import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";

import { useSelector, useDispatch } from "react-redux";

import { BsThreeDotsVertical, BsPencil } from "react-icons/bs";

import { BsToggleOn, BsToggleOff } from "react-icons/bs";

import CourseSectionsLessons from "./CourseSectionsLessons";
import CourseIntroImageVideoEdit from "./CourseIntroImageVideoEdit";
import addcover from "../../images/ADD_COVER.png";

import {
  AiOutlineDown,
  AiOutlinePlusCircle,
  AiFillPlayCircle,
  AiOutlineFileText,
  AiOutlineClose,
} from "react-icons/ai";
const url = process.env.REACT_APP_BACKEND_URL;

const StoryTypeModelCourse = ({
  group_info,
  setProfile,
  setIsCourseListClose,
  isHecanUnPublish,
  isHecanUnpublishOrStopCourse,
}) => {
  const { BasicUserReducers } = useSelector((state) => state);
  const { user } = BasicUserReducers;

  const dispatch = useDispatch({});

  const [showChatModel, setShowChatModel] = useState({
    isModelOpen: false,
  });
  const [videoUrl, setVideoUrl] = useState(
    group_info.group_introduction_video_url,
  );
  const [describeVideo, setDescribeVideo] = useState(true);
  const [story, setStory] = useState({});
  const [sections, setSections] = useState([]);
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [lessionBoxShow, setLessionBoxShow] = useState(false);
  const [lessionTittle, setLessionTitle] = useState("");
  const [lessionTittleEdit, setLessionTitleEdit] = useState({
    story_id: "",
    editing: false,
  });
  const menuBoxBioRef = useRef(null);
  useEffect(() => {
    getSections(1);
  }, [group_info]);

  // useEffect(() => {
  //   document.addEventListener("scroll", handleScrolListner);
  //   return () => {
  //     document.removeEventListener("scroll", handleScrolListner);
  //   };
  // }, [storyPage, totalStoryPage]);

  const handleScrolListner = (e) => {
    const scrollableDiv = e.target;

    if (
      scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      scrollableDiv.scrollHeight
    ) {
      console.log("end reached");
      const TotalPage = totalStoryPage || 1;
      if (storyPage <= TotalPage) {
        getSections(storyPage);
      }
    }
    // const TotalPage = totalStoryPage || 1;
    // const scrolled = window.scrollY;
    // const scrollable =
    //   document.documentElement.scrollHeight - window.innerHeight;
    // if (Math.ceil(scrolled) >= scrollable) {
    //   if (storyPage <= TotalPage) {
    //     console.log(storyPage);
    //     getSections(storyPage);
    //   }
    // }
  };

  const handleStory = async (id, video_url, e) => {
    if (id === "describe_video_story") {
      setVideoUrl(video_url);
      document.querySelectorAll(".selected-tab-lesson").forEach((div) => {
        div.style.backgroundColor = "white";
      });
      //document.getElementById(id).style.backgroundColor = "#d1d7dc";
    } else {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getCourseStory?story_id=${id}`;
      try {
        let res = await getRequestedDataWithHeader(url, group_info.token);
        //setEditDescription(false)

        setVideoUrl(res.data.body.story_url);

        console.log("story_get", res.data.body);

        setStory(res.data.body);

        document.querySelectorAll(".selected-tab-lesson").forEach((div) => {
          div.style.backgroundColor = "white";
        });
        document.getElementById(id).style.backgroundColor = "#d1d7dc";
      } catch (err) {
        //toast.error(err.message)
        console.log(err.message);
      }
    }
    setDescribeVideo(false);
    // if (previousStroyID)
    //   document.getElementById(previousStroyID).style.background = 'white'
    // if (id) document.getElementById(id).style.background = '#d1d7dc'
    // previousStroyID = id
  };
  const closeModelHandler = () =>
    setShowChatModel({
      isModelOpen: false,
    });

  const {
    BasicUserReducers: {
      user: { loginUser },
    },
  } = useSelector((state) => state);

  const getSections = async (storyPageNo) => {
    console.log("page", storyPageNo);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/subscriptionStories?user_id=${loginUser._id}&subscription_id=${group_info._id}&page=${storyPageNo}`;
    try {
      let res = await getRequestedDataWithHeader(url, group_info.token);
      setStoryPage(storyPageNo + 1);
      //setSections(res.data.body)
      setTotalStoryPage(res.data.body.Total_Page);
      if (storyPageNo > 1) {
        setSections((oldArray) => [
          ...oldArray,
          ...res.data.body.getStoryModel,
        ]);
      } else {
        if (res.data.body.getStoryModel[0]) {
          setVideoUrl(res.data.body.getStoryModel[0].story_url);
          setStory(res.data.body.getStoryModel[0]);
        }
        setSections(res.data.body.getStoryModel);
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const addLession = () => {
    let totalSeconds = 0;
    // let hours = Math.floor(totalSeconds / 3600)
    // totalSeconds %= 3600
    // let minutes = Math.floor(totalSeconds / 60)
    // let seconds = Math.floor(totalSeconds % 60)

    // console.log(hours, minutes, seconds)

    const storyTittle = lessionTittle;
    const language = group_info.language_id._id;
    const story = "";
    const storyTypeProvider = "1";
    const user_id = group_info.user_id._id;
    const category_id = group_info.category_id._id;
    const subscription_id = group_info._id;
    const token = group_info.token;
    if (!storyTittle) {
      return;
    }

    let formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("subscription_id", subscription_id);
    formData.append("category_id", category_id);
    formData.append("story_provider", storyTypeProvider);
    formData.append("story_language_id", language);
    formData.append("story_title", storyTittle);
    formData.append("story_duration", totalSeconds);
    formData.append("story_text", story);
    formData.append("story_type", "");
    formData.append("section_type", group_info.section_type);

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
        }
      },
    };

    axios
      .post(`${url}/api/userProfile/sendStory`, formData, options)
      .then((res) => {
        getSections(1);
        handleStory(res.data.body._id, "");
        setLessionTitle("");
        setLessionBoxShow(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("System Failed");
      });
  };

  const publishCourse = async () => {
    const data = {
      subscription_id: group_info._id,
      user_id: user.loginUser._id,
      language_id: group_info.language_id._id,
      category_id: group_info.category_id._id,
      profile_id: group_info.profile_id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/publishCourse`;
      let res = await getDataPostWithHeader(url, data, group_info.token);

      dispatch({
        type: "group_details",
        payload: {
          ...res.data.body,
          user_id: group_info.user_id,
          token: group_info.token,
        },
      });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const unpublishCourse = async () => {
    const data = {
      subscription_id: group_info._id,
      user_id: user.loginUser._id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/unPublishCourse`;
      let res = await getDataPostWithHeader(url, data, group_info.token);

      dispatch({
        type: "group_details",
        payload: {
          ...res.data.body,
          user_id: group_info.user_id,
          token: group_info.token,
        },
      });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };

  const updatePreview = async (id, isPreview) => {
    console.log("isPreview", isPreview, id);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryIsPreview`;
    const data = {
      story_id: id,
      isStoryPreview: isPreview,
    };
    try {
      let res = await getDataPostWithHeader(url, data, group_info.token);
      console.log(res);
      getSections(1);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const updateLessionTitle = async (storyid, newTitle) => {
    console.log("storyid,newTitle", newTitle, storyid);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryTitle`;
    const data = {
      story_id: storyid,
      newtitle: newTitle,
    };
    try {
      let res = await getDataPostWithHeader(url, data, group_info.token);
      console.log(res);
      getSections(1);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          {!describeVideo && (
            <CourseSectionsLessons
              group_info={group_info}
              videoUrl={videoUrl}
              story={story}
              handleStory={handleStory}
              getSections={getSections}
              setProfile={setProfile}
            />
          )}

          {describeVideo && (
            <CourseIntroImageVideoEdit
              group_info={group_info}
              setIsCourseListClose={setIsCourseListClose}
              setProfile={setProfile}
            />
          )}
        </div>

        <div
          className="col-md-4"
          style={{
            padding: "0px",
            margin: "0px",
            borderLeft: "1px solid #d1d7dc",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                borderBottom: "1px solid #d1d7dc",
                padding: "12px",
              }}
            >
              <div style={{ position: "relative" }}>
                {group_info.status === 0 && (
                  <button className="btn btn-info" onClick={publishCourse}>
                    Publish
                  </button>
                )}
                {group_info.status === 1 && (
                  <>
                    <span>Active</span>

                    {/* <button className="btn btn-info" onClick={unpublishCourse}>
                      Un Publish 
                    </button> */}
                    <BsThreeDotsVertical
                      onClick={() => {
                        menuBoxBioRef.current.style.display = "block";
                        isHecanUnpublishOrStopCourse();
                      }}
                    />

                    <div
                      className="menu-box-bio display-none"
                      style={{
                        position: "absolute",
                        right: "-4px",
                        top: "5px",
                      }}
                      ref={menuBoxBioRef}
                    >
                      <div className="menu-header">
                        <AiOutlineClose
                          className="cursor-pointer"
                          onClick={() => {
                            menuBoxBioRef.current.style.display = "none";
                          }}
                        />
                      </div>
                      {isHecanUnPublish.count === 0 && (
                        <div
                          onClick={unpublishCourse}
                          className="cursor-pointer"
                        >
                          Un publish
                        </div>
                      )}
                      {isHecanUnPublish.count > 0 && (
                        <div
                          onClick={unpublishCourse}
                          className="cursor-pointer"
                        >
                          Stop temporarily
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              id="describing_video_section"
              style={{ display: "block", borderBottom: "1px solid #d1d7dc" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  padding: "12px",
                  display: "flex",
                }}
                onClick={(e) => {
                  handleStory(
                    "describe_video_story",
                    group_info.group_introduction_video_url,
                    e,
                  );
                  setDescribeVideo(true);
                }}
              >
                {group_info.group_video_thumbnail_url && (
                  <img
                    id="thumnail_bio"
                    src={group_info.group_video_thumbnail_url}
                    className="cursor-pointer"
                    alt="thumbnail"
                    style={{ height: "100px" }}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = addcover;
                    }}
                  />
                )}

                {!group_info.group_video_thumbnail_url && (
                  <img
                    id="thumnail_bio"
                    src={addcover}
                    className="cursor-pointer"
                    alt="thumbnail"
                    style={{ height: "100px" }}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = addcover;
                    }}
                  />
                )}
                <div style={{ marginLeft: "5px" }}>
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    {group_info.group_name}
                  </div>
                  <div>{group_info.category_id.category_name}</div>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "12px",
                borderBottom: "1px solid #d1d7dc",
                fontSize: "18px",
                fontWeight: "700",
                height: "54px",
              }}
            >
              Lessons
            </div>

            <div>
              <div
                onClick={() => setLessionBoxShow(!lessionBoxShow)}
                style={{
                  paddingLeft: "5px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: "600",
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlinePlusCircle
                  className="mr-2"
                  style={{ alignSelf: "center", marginTop: "1px" }}
                />
                Add lesson
              </div>
              {lessionBoxShow && (
                <input
                  style={{ width: "100%" }}
                  placeholder="Add Lesson Title"
                  className="m-2 p-1"
                  type="text"
                  value={lessionTittle}
                  onChange={(e) => {
                    setLessionTitle(e.target.value);
                  }}
                  onBlur={() => addLession()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setLessionBoxShow(!lessionBoxShow);
                      addLession();
                    }
                  }}
                />
              )}
            </div>
            <div
              style={{ height: "calc(100vh - 329px)", overflow: "scroll" }}
              onScroll={handleScrolListner}
            >
              {sections.length >= 0 &&
                sections.map((story, index) => {
                  let totalSeconds = story.story_duration;
                  let hours = Math.floor(totalSeconds / 3600);
                  totalSeconds %= 3600;
                  let minutes = Math.floor(totalSeconds / 60);
                  let seconds = totalSeconds % 60;
                  let format = "";
                  if (hours > 0) {
                    format = `${hours}hours ${minutes}min`;
                  } else {
                    format = `${minutes}min`;
                  }

                  return (
                    <div
                      style={{
                        marginTop: "10px",
                        fontSize: "14px",
                        cursor: "pointer",
                        padding: "12px",
                      }}
                      id={story._id}
                      className="selected-tab-lesson display-flex"
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                        onClick={(e) => {
                          if (lessionTittleEdit.story_id === "") {
                            handleStory(story._id, story, e);
                          }
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            className="display-flex"
                            style={{ gap: "10px", alignItems: "center" }}
                          >
                            {console.log(lessionTittleEdit, story._id, index)}
                            {lessionTittleEdit.story_id === story._id &&
                            lessionTittleEdit.story_id ? (
                              <></>
                            ) : (
                              <span>{story.story_title}</span>
                            )}

                            {lessionTittleEdit.story_id === story._id && (
                              <input
                                style={{ width: "100%" }}
                                placeholder="Edit Title"
                                className="p-1"
                                type="text"
                                defaultValue={story.story_title}
                                onBlur={(e) => {
                                  setLessionTitleEdit({
                                    story_id: "",
                                    editing: false,
                                  });
                                  updateLessionTitle(story._id, e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setLessionTitleEdit({
                                      story_id: "",
                                      editing: false,
                                    });
                                    updateLessionTitle(
                                      story._id,
                                      e.target.value,
                                    );
                                  }
                                }}
                              />
                            )}
                            {lessionTittleEdit.story_id === "" && (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents click event from bubbling to the parent
                                  setLessionTitleEdit({
                                    story_id: story._id,
                                    editing: true,
                                  });
                                }}
                              >
                                <BsPencil />
                              </span>
                            )}

                            {lessionTittleEdit.story_id === story._id && (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents click event from bubbling to the parent
                                  setLessionTitleEdit({
                                    story_id: "",
                                    editing: false,
                                  });
                                }}
                              >
                                <AiOutlineClose />
                              </span>
                            )}
                          </div>

                          <div style={{ display: "flex" }}>
                            <div>
                              {story.story_type === "video" && (
                                <AiFillPlayCircle color="#6a6f73" />
                              )}
                              {story.story_type === "text" && (
                                <AiOutlineFileText color="#6a6f73" />
                              )}
                            </div>
                            <div
                              style={{
                                color: "#6a6f73",
                                marginTop: "2px",
                                marginLeft: "3px",
                              }}
                            >
                              {format}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ alignSelf: "center" }}
                          className="loading"
                          id={`${story._id}_process`}
                        ></div>
                      </div>
                      <div
                        style={{ alignSelf: "center" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          updatePreview(story._id, !story.isStoryPreview);
                        }}
                      >
                        Make view to public
                        {console.log(story.isStoryPreview, index)}
                        {story.isStoryPreview ? (
                          <BsToggleOn size={20} className="ml-1" />
                        ) : (
                          <BsToggleOff size={20} className="ml-1" />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryTypeModelCourse;
