import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { responseMessageDispaly } from "../../utils/sharedfunction";
import { editPersonalDetails } from "../../functions/editProfile/editProfile";

const EditPersonalDetails = (props) => {
  const [name, setName] = useState(props.user.user_name);
  const [gender, setGender] = useState(props.user.gender);
  const [phone, setPhone] = useState(props.user.mobile_no);
  const [email, setMail] = useState(props.user.email);
  const [loginName, setLoginName] = useState(props.user.login_user_id);
  const [showEdit, setShowEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadMSG = useRef(null);

  const handleSubmit = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserProfile`;
    const data = {
      user_name: name,
      user_email: email,
      user_mobile_no: phone,
      user_gender: gender,
      user_id: props.user._id,
      loginName: loginName,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      props.userDetails();
      setShowEdit(false);
      setLoading(false);
      //toast.success('Personal details has updated')
      responseMessageDispaly(uploadMSG, "success", "green");
      setTimeout(function () {
        props.setEditProfile(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      responseMessageDispaly(uploadMSG, "something went worng", "red");
      // if (err.response.data.message.includes('E11000')) {
      //   responseMessageDispaly(uploadMSG, 'Login Name already taken', 'red')
      //   //toast.error('Login Name already taken please check another')
      // } else {
      //   responseMessageDispaly(uploadMSG, 'Some thing went wrong', 'red')
      // }
      setLoading(false);
      setTimeout(function () {
        props.setEditProfile(false);
      }, 2000);
    }
  };
  return (
    <div className="editprofile-section">
      {loading && (
        <span className="loader-spiner loader-spiner-addtionl"></span>
      )}
      <div ref={uploadMSG} className="ml-3"></div>
      <div className="sub-menu-drop-down-navbar">
        <span>Name</span>
        <input
          placeholder="name"
          className="form-control input-md registration-from-control-border"
          type="text"
          value={name}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="sub-menu-drop-down-navbar">
        <span>Gender</span>
        <select
          className="form-control registration-from-control-border"
          defaultValue={gender}
          onChange={(e) => {
            setGender(e.target.value);
          }}
        >
          <option value="0">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
      <div className="sub-menu-drop-down-navbar">
        <span>Phone</span>
        <input
          placeholder="Phone"
          className="form-control input-md registration-from-control-border"
          type="text"
          value={phone}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="sub-menu-drop-down-navbar">
        <span>Mail</span>
        <input
          placeholder="mail"
          className="form-control input-md registration-from-control-border"
          type="text"
          value={email}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="sub-menu-drop-down-navbar">
        <span>Username</span>
        <input
          placeholder="username"
          className="form-control input-md registration-from-control-border"
          type="text"
          value={loginName}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="flex-end mt-1 mr-1">
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ background: "#21AEDD" }}
          onClick={handleSubmit}
        >
          update
        </Button>
      </div>
    </div>
  );
};

export default EditPersonalDetails;
