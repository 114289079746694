import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";

import profileDefualt from "../../images/default.jpeg";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  responseMessage,
  responseMessageClear,
} from "../../utils/sharedfunction";
import SubStoryCard from "../cards/SubStoryCard";
const url = process.env.REACT_APP_BACKEND_URL;

const ShowSubscriptionStories = ({ group_info, stories, dispatch }) => {
  let storyPagenum = 1;
  const [isloading, setIsLoading] = useState(false);
  const [storyPage, setStoryPage] = useState(storyPagenum + 1);
  const intialLoadErrorMessageRef = useRef(null);
  const recursionLoadErrorMessageRef = useRef(null);

  const getSubscirptionGroupStories = async (storyPage) => {
    try {
      if (group_info.user_id._id) {
        const response = await fetch(
          `${url}/api/userProfile/subscriptionStories?user_id=${group_info.user_id._id}&subscription_id=${group_info._id}&page=${storyPage}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${group_info.token}`,
            },
          },
        );
        const responseData = await response.json();

        console.log(responseData.body.getStoryModel);

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          } else {
            if (storyPage > 1) {
              responseMessage(
                recursionLoadErrorMessageRef,
                "Something Went Wrong",
                "red",
              );
              responseMessageClear(recursionLoadErrorMessageRef);
            } else {
              responseMessage(
                intialLoadErrorMessageRef,
                "Something Went Wrong",
                "red",
              );
              responseMessageClear(intialLoadErrorMessageRef);
            }
            //throw new Error(responseData.message || 'something went wrong')
          }
        } else {
          if (storyPage > 1) {
            setIsLoading(false);
            dispatch({
              type: "sub_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({ type: "sub_group_stories", payload: responseData.body });
          }
          setIsLoading(true);
        }
      }
    } catch (err) {
      if (storyPage > 1) {
        responseMessage(
          recursionLoadErrorMessageRef,
          "Something Went Wrong",
          "red",
        );
        console.log(err);
        responseMessageClear(recursionLoadErrorMessageRef);
      } else {
        responseMessage(
          intialLoadErrorMessageRef,
          "Something Went Wrong",
          "red",
        );
        console.log(err);
        responseMessageClear(intialLoadErrorMessageRef);
      }
    }
  };

  useEffect(() => {
    getSubscirptionGroupStories(storyPagenum);
    setStoryPage(storyPagenum + 1);
  }, [group_info]);

  const scrollHandler = (e, total_page) => {
    if (
      Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
      e.target.scrollHeight
    ) {
      setStoryPage(storyPage + 1);
      if (storyPage <= total_page) {
        getSubscirptionGroupStories(storyPage);
      }
    }
  };

  const ImageFile = ({ imgsrc }) => {
    return (
      <>
        <div style={{ padding: 3 + "px", textAlign: "center" }}>
          <img src={imgsrc} className="width-100" alt="Image not found" />
        </div>
      </>
    );
  };

  const VideoUrl = ({ videolink, width, height }) => {
    return (
      <>
        <div className="text-center story-margin ">
          <ReactPlayer url={videolink} controls width={width} height={height} />
        </div>
      </>
    );
  };

  const AudioUrl = ({ audiosrc }) => {
    return (
      <>
        <div className="text-center">
          <audio controls src={audiosrc} className="story-margin "></audio>
        </div>
      </>
    );
  };

  if (stories.getStoryModel) {
    return (
      <>
        <div
          id="story_box"
          className="story-box"
          onScroll={(e) => {
            scrollHandler(e, stories.Total_Page);
          }}
        >
          <div
            className="d-flex justify-content-center margin-top-5"
            ref={intialLoadErrorMessageRef}
          ></div>
          {!isloading && (
            <div className="d-flex justify-content-center margin-top-5">
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="row">
              {stories.getStoryModel.map((story, index) => {
                return (
                  <div className="col-md-4 mt-3" key={story._id}>
                    <div
                      style={{
                        boxSizing: "border-box",
                        borderTop: "1px solid #f0f0f0",
                        borderLeft: "1px solid #f0f0f0",
                        borderRight: "1px solid #f0f0f0",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          float: "left",
                        }}
                        src={story.user_id.imageUrl}
                        alt="im-story-profile"
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = profileDefualt;
                        }}
                      />

                      <span
                        className="ml-1"
                        style={{ float: "left", marginTop: "-3px" }}
                      >
                        {story.user_id.user_name}
                      </span>
                      <BsThreeDotsVertical
                        style={{ float: "right", margin: "3px" }}
                      />
                      <div style={{ clear: "both" }}> </div>
                    </div>
                    <SubStoryCard story={story} />
                  </div>
                  // <div key={index} className='message-box'>
                  //   <div>
                  //     <div className='push-left'>
                  //       <img
                  //         src={url + '/' + list.user_id.imageUrl}
                  //         onError={(e) => {
                  //           e.target.onError = null
                  //           e.target.src = profileDefualt
                  //         }}
                  //         className='message-box-profile'
                  //         alt='profile'
                  //       />
                  //     </div>
                  //     <div
                  //       className='push-left'
                  //       style={{ lineHeight: 25 + 'px', marginLeft: 10 + 'px' }}
                  //     >
                  //       {list.user_id.user_name}
                  //     </div>
                  //     <div className='clearfix'></div>
                  //   </div>

                  //   {list.story_type === 'image' && (
                  //     <ImageFile imgsrc={list.story_url} />
                  //   )}

                  //   {list.story_type === 'video_url' && (
                  //     <VideoUrl
                  //       videolink={list.story_url}
                  //       width=''
                  //       height='200px'
                  //     />
                  //   )}

                  //   {list.story_type === 'video' && (
                  //     <VideoUrl
                  //       videolink={url + '/' + list.story_url}
                  //       width=''
                  //       height=''
                  //     />
                  //   )}

                  //   {list.story_type === 'audio' && (
                  //     <AudioUrl audiosrc={list.story_url} />
                  //   )}

                  //   <div style={{ fontWeight: 'bold', padding: 3 + 'px' }}>
                  //     {list.story_title}
                  //   </div>
                  //   <div style={{ padding: 3 + 'px' }}>{list.story_text} </div>
                  // </div>
                );
              })}
            </div>
          </div>
          {!isloading && (
            <div className="d-flex justify-content-center margin-top-5">
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div
            className="d-flex justify-content-center margin-top-5"
            ref={recursionLoadErrorMessageRef}
          ></div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="story_box" className="story-box">
          <div
            className="d-flex justify-content-center margin-top-5"
            style={{ color: "red" }}
            ref={intialLoadErrorMessageRef}
          ></div>
        </div>
      </>
    );
  }
};

const MapStateToPorps = ({ StoriesReducer: { stories } }) => {
  return { stories };
};

export default connect(MapStateToPorps)(ShowSubscriptionStories);
