import React, { useState, useRef } from "react";
import { Card, Skeleton, Tooltip } from "antd";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { AiOutlinePlayCircle, AiOutlineClose } from "react-icons/ai";
import { FaPen } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import defualtImg from "../../images/default.jpeg";
import { BsPlayFill } from "react-icons/bs";
import Audio from "../../images/Audio.jpeg";
import profileDefualt from "../../images/default.jpeg";
import ReactPlayer from "react-player";
import UserStoryModel from "../profile/EditUserStoryModel";
import { useEffect } from "react";
import "./storyCard.css";
import { getRequestedData } from "../../functions/editProfile/editProfile";
const { Meta } = Card;

const weburl = process.env.REACT_APP_BACKEND_URL;

const UserStoryCard = ({
  story,
  iam,
  user,
  index,
  setShowEditStoryModel,
  setStoryToEdit,
}) => {
  const { FileUploadPercentage, StoryImageCoverURL } = useSelector(
    (state) => state,
  );

  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [youtubeThumbnail, setYoutubeThumbnail] = useState("");
  const MenuRef = useRef(null);

  useEffect(() => {
    if (story.story_type === "video_url") {
      const videoId = getId(story.story_url);

      console.log(videoId);
      setYoutubeVideoID(videoId);
      getYoutbe(videoId);
    }
  }, [story.story_type, story.story_url]);

  const getYoutbe = async (videoId) => {
    let res = await getRequestedData(
      `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`,
    );
    setYoutubeThumbnail(res.data.thumbnail_url);
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#fc6203",
        margin: 5,
      }}
      spin
    />
  );

  const menuHandler = (id) => {
    // navbarMenuRef.current.style.display = 'block'
    let isDisplay = document.getElementById(id).style.display;
    if (isDisplay === "block") {
      document.getElementById(id).style.display = "none";
    } else {
      document.getElementById(id).style.display = "block";
    }
  };

  return (
    <>
      {/* {story.story_type == "text" && (
        <div className="card-box story-backgroud-color">
          {story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            </Link>
          )}
          <div style={{ display: "flex" }} className="story-card-box-profile">
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/profile/story/${story._id}`}>
                <div className="titile-card">
                  {story.story_title}
                </div>
              </Link>
            </div>

            <div className="cursor-pointer mt-2 storie-edit-section">
              <BsThreeDotsVertical />
              <div className="dropdown-content storie-edit-dropdown-content">
                <div className="cursor-pointer drop-down-navbar-menu">
                  <div className="flex-space-btw display-flex-align-center">
                    <div
                      style={{ width: "85%" }}
                      onClick={() => {
                        setShowEditStoryModel(true);
                        setStoryToEdit(story);
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    >
                      Edit
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    />
                  </div>
                </div>
                <div className="cursor-pointer drop-down-navbar-menu">
                  story analytics
                </div>
              </div>
            </div>
          </div>
          {!story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box-with-no-image ml-2 mt-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 350 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 350 &&
                  parse(story.story_text.substring(0, 600))}
              </div>
            </Link>
          )}
          {story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box ml-2 mt-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 50 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 50 &&
                  parse(story.story_text.substring(0, 200))}
              </div>
            </Link>
          )}
        </div>
      )} */}

      {story.story_type == "image" && (
        <div className="card-box story-backgroud-color">
          <Link to={`/profile/story/${story._id}`}>
            {story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>

          <div style={{ display: "flex" }} className="story-card-box-profile">
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/profile/story/${story._id}`}>
                <div className="titile-card">{story.story_title}</div>
              </Link>
            </div>
            <div className="cursor-pointer mt-2 storie-edit-section">
              <BsThreeDotsVertical
                onClick={() => menuHandler(`${story._id}_edit`)}
                className="cursor-pointer"
                size={20}
              />
              <div
                className="dropdown-content storie-edit-dropdown-content"
                id={`${story._id}_edit`}
                style={{ display: "none" }}
              >
                <div className="cursor-pointer drop-down-navbar-menu">
                  <div className="flex-space-btw display-flex-align-center">
                    <div
                      style={{ width: "85%" }}
                      onClick={() => {
                        setShowEditStoryModel(true);
                        setStoryToEdit(story);
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    >
                      Edit
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    />
                  </div>
                </div>
                <div className="cursor-pointer drop-down-navbar-menu ">
                  analytics
                </div>
              </div>
            </div>
          </div>
          {/* {!story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } ml-2 mt-2`}
              >
                <p>
                  {story.story_text &&
                    story.story_text.length <= 350 &&
                    parse(story.story_text)}
                  {story.story_text &&
                    story.story_text.length > 350 &&
                    parse(story.story_text.substring(0, 350))}
                </p>
              </div>
            </Link>
          )}
          {story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box ml-2 mt-2`}
              >
                <p>
                  {story.story_text &&
                    story.story_text.length <= 50 &&
                    parse(story.story_text)}
                  {story.story_text &&
                    story.story_text.length > 50 &&
                    parse(story.story_text.substring(0, 200))}
                </p>
              </div>
            </Link>
          )} */}
        </div>
      )}

      {story.story_type == "video" && (
        <>
          {index === 0 &&
            FileUploadPercentage.uploadVideoMeta.isVideoUploading === "YES" && (
              <div className="card-box story-backgroud-color">
                <div
                  className="image-warapper-ratio-adujestment-user-story"
                  style={{ borderRadius: "5px" }}
                >
                  {StoryImageCoverURL.cover.URL && (
                    <img
                      className="image-whereplayer-shows-user-story"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={StoryImageCoverURL.cover.URL}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  )}
                  {!StoryImageCoverURL.cover.URL && (
                    <img
                      className="image-whereplayer-shows-user-story"
                      src={story.user_id.imageUrl}
                      style={{
                        borderRadius: "50%",
                      }}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <Spin indicator={antIcon} />
                  <div style={{ marginLeft: "8px" }}>
                    {FileUploadPercentage.uploadVideoMeta
                      .videoUploadPercentage > 0 && (
                      <div>
                        <span className="loading ml-2 mr-2">Uploading... </span>
                        <span>
                          {
                            FileUploadPercentage.uploadVideoMeta
                              .videoUploadPercentage
                          }
                        </span>
                        <span className="ml-2">%</span>
                      </div>
                    )}
                    {FileUploadPercentage.uploadVideoMeta
                      .videoprocessingUpload === true && (
                      <div className="ml-1" style={{ color: "black" }}>
                        <span className="loading ml-2">Processing...</span>
                      </div>
                    )}
                  </div>
                  <div className="cursor-pointer mt-2 storie-edit-section">
                    <BsThreeDotsVertical
                      onClick={() => menuHandler(`${story._id}_edit`)}
                      className="cursor-pointer"
                      size={20}
                    />
                    <div
                      className="dropdown-content storie-edit-dropdown-content"
                      id={`${story._id}_edit`}
                      style={{ display: "none" }}
                    >
                      <div className="cursor-pointer drop-down-navbar-menu ">
                        <div className="flex-space-btw display-flex-align-center">
                          <div
                            style={{ width: "85%" }}
                            onClick={() => {
                              setShowEditStoryModel(true);
                              setStoryToEdit(story);
                              document.getElementById(
                                `${story._id}_edit`,
                              ).style.display = "none";
                            }}
                          >
                            Edit
                          </div>
                          <AiOutlineClose
                            onClick={() => {
                              document.getElementById(
                                `${story._id}_edit`,
                              ).style.display = "none";
                            }}
                          />
                        </div>
                      </div>
                      <div className="cursor-pointer drop-down-navbar-menu ">
                        analytics
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "35px" }}>
                  <div className="titile-card">
                    <span className="mr-1" key={story._id}>
                      {story.languages.nativeName},
                    </span>
                  </div>
                </div>
              </div>
            )}
          {index == 0 &&
            FileUploadPercentage.uploadVideoMeta.videoUploadPercentage === 0 &&
            FileUploadPercentage.uploadVideoMeta.videoprocessingUpload ===
              false && (
              <div className="card-box story-backgroud-color">
                <Link to={`/profile/story/${story._id}`}>
                  <div
                    className="image-warapper-ratio-adujestment-user-story"
                    style={{ borderRadius: "5px" }}
                  >
                    {story.thumbnail_url && (
                      <img
                        className="image-whereplayer-shows-user-story"
                        style={{
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={story.thumbnail_url}
                        alt="im-story"
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = defualtImg;
                        }}
                      />
                    )}

                    {!story.thumbnail_url && (
                      <img
                        className="image-whereplayer-shows-user-story"
                        src={story.user_id.imageUrl}
                        style={{
                          borderRadius: "50%",
                        }}
                        alt="im-story"
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = defualtImg;
                        }}
                      />
                    )}
                    <div className="play-video-home-page">
                      <div
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                          background: "black",
                          opacity: "0.8",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BsPlayFill
                          style={{ color: "white", marginLeft: "3px" }}
                          size={30}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
                <div
                  style={{ display: "flex" }}
                  className="story-card-box-profile"
                >
                  <div
                    style={{
                      marginLeft: "8px",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Link to={`/profile/story/${story._id}`}>
                      <div>
                        <div className="titile-card">{story.story_title}</div>
                      </div>
                    </Link>
                  </div>
                  <div className="cursor-pointer mt-2">
                    <BsThreeDotsVertical
                      onClick={() => menuHandler(`${story._id}_edit`)}
                      className="cursor-pointer"
                      size={20}
                    />
                    <div
                      className="dropdown-content storie-edit-dropdown-content"
                      id={`${story._id}_edit`}
                      style={{ display: "none" }}
                    >
                      <div className="cursor-pointer drop-down-navbar-menu">
                        <div className="flex-space-btw display-flex-align-center">
                          <div
                            style={{ width: "85%" }}
                            onClick={() => {
                              setShowEditStoryModel(true);
                              setStoryToEdit(story);
                              document.getElementById(
                                `${story._id}_edit`,
                              ).style.display = "none";
                            }}
                          >
                            Edit
                          </div>
                          <AiOutlineClose
                            onClick={() => {
                              document.getElementById(
                                `${story._id}_edit`,
                              ).style.display = "none";
                            }}
                          />
                        </div>
                      </div>
                      <div className="cursor-pointer drop-down-navbar-menu ">
                        analytics
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Link to={`/profile/story/${story._id}`}>
                  <div
                    className={`${
                      story.language === "ar"
                        ? "text-right"
                        : "text-left"
                    } description-box ml-2  mt-2`}
                  >
                    <p>
                      {story.story_text &&
                        story.story_text.length <= 50 &&
                        parse(story.story_text)}
                      {story.story_text &&
                        story.story_text.length > 50 &&
                        parse(story.story_text.substring(0, 200))}
                    </p>
                  </div>
                </Link> */}
              </div>
            )}

          {index > 0 && story.mediaUploaded && (
            <div className="card-box story-backgroud-colors">
              <Link to={`/profile/story/${story._id}`}>
                <div
                  className="image-warapper-ratio-adujestment-user-story"
                  style={{ borderRadius: "5px" }}
                >
                  {story.thumbnail_url && (
                    <img
                      className="image-whereplayer-shows-user-story"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={story.thumbnail_url}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  )}

                  {!story.thumbnail_url && (
                    <img
                      className="image-whereplayer-shows-user-story"
                      src={story.user_id.imageUrl}
                      style={{
                        borderRadius: "50%",
                      }}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                    className="play-video-home-page"
                  >
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        background: "black",
                        opacity: "0.8",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BsPlayFill
                        style={{ color: "white", marginLeft: "3px" }}
                        size={30}
                      />
                    </div>
                  </div>
                </div>
              </Link>
              <div
                style={{ display: "flex" }}
                className="story-card-box-profile"
              >
                <div
                  style={{
                    marginLeft: "8px",
                    position: "relative",
                    width: "100%",
                  }}
                  className="cursor-pointer"
                >
                  <Link to={`/profile/story/${story._id}`}>
                    <div>
                      <div className="titile-card">{story.story_title}</div>
                    </div>
                  </Link>
                </div>
                <div className="cursor-pointer mt-2">
                  <BsThreeDotsVertical
                    onClick={() => menuHandler(`${story._id}_edit`)}
                    className="cursor-pointer"
                    size={20}
                  />
                  <div
                    className="dropdown-content storie-edit-dropdown-content"
                    id={`${story._id}_edit`}
                    style={{ display: "none" }}
                  >
                    <div className="cursor-pointer drop-down-navbar-menu ">
                      <div className="flex-space-btw display-flex-align-center">
                        <div
                          style={{ width: "85%" }}
                          onClick={() => {
                            setShowEditStoryModel(true);
                            setStoryToEdit(story);
                            document.getElementById(
                              `${story._id}_edit`,
                            ).style.display = "none";
                          }}
                        >
                          Edit
                        </div>
                        <AiOutlineClose
                          onClick={() => {
                            document.getElementById(
                              `${story._id}_edit`,
                            ).style.display = "none";
                          }}
                        />
                      </div>
                    </div>
                    <div className="cursor-pointer drop-down-navbar-menu ">
                      analytics
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to={`/profile/story/${story._id}`}>
                <div
                  className={`${
                    story.language === "ar"
                      ? "text-right"
                      : "text-left"
                  } description-box ml-2 mt-2`}
                >
                  <p>
                    {story.story_text &&
                      story.story_text.length <= 50 &&
                      parse(story.story_text)}
                    {story.story_text &&
                      story.story_text.length > 50 &&
                      parse(story.story_text.substring(0, 200))}
                  </p>
                </div>
              </Link> */}
            </div>
          )}
        </>
      )}

      {story.story_type == "stafs" && (
        <div className="card-box story-backgroud-color">
          <Link to={`/expert/cv/${story._id}`}>
            {story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>

          <div style={{ display: "flex" }} className="story-card-box-profile">
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/profile/story/${story._id}`}>
                <div className="titile-card">{story.story_title}</div>
              </Link>
            </div>
            <div className="cursor-pointer mt-2 storie-edit-section">
              <BsThreeDotsVertical
                onClick={() => menuHandler(`${story._id}_edit`)}
                className="cursor-pointer"
                size={20}
              />
              <div
                className="dropdown-content storie-edit-dropdown-content"
                id={`${story._id}_edit`}
                style={{ display: "none" }}
              >
                <div
                  className="cursor-pointer drop-down-navbar-menu"
                  style={{ top: "4px" }}
                >
                  <div className="flex-space-btw display-flex-align-center">
                    <div
                      style={{ width: "85%" }}
                      onClick={() => {
                        setShowEditStoryModel(true);
                        setStoryToEdit(story);
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    >
                      Edit
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    />
                  </div>
                </div>
                <div className="cursor-pointer drop-down-navbar-menu ">
                  analytics
                </div>
              </div>
            </div>
          </div>
          {/* {!story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } ml-2 mt-2`}
              >
                <p>
                  {story.story_text &&
                    story.story_text.length <= 350 &&
                    parse(story.story_text)}
                  {story.story_text &&
                    story.story_text.length > 350 &&
                    parse(story.story_text.substring(0, 350))}
                </p>
              </div>
            </Link>
          )} */}
          {/* {story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box ml-2 mt-2`}
              >
                <p>
                  {story.story_text &&
                    story.story_text.length <= 50 &&
                    parse(story.story_text)}
                  {story.story_text &&
                    story.story_text.length > 50 &&
                    parse(story.story_text.substring(0, 200))}
                </p>
              </div>
            </Link>
          )} */}
        </div>
      )}

      {(story.story_type == "articles" ||
        story.story_type == "scienceresearchs") && (
        <div className="card-box story-backgroud-color">
          <Link
            to={`${
              story.story_type == "articles"
                ? `profile/article/${story._id}`
                : `profile/science-reasearch/${story._id}`
            }`}
          >
            {story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}

            {!story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "white", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  src={story.user_id.imageUrl}
                  style={{
                    borderRadius: "50%",
                  }}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>

          <div style={{ display: "flex" }} className="story-card-box-profile">
            <div
              style={{
                marginLeft: "8px",
                position: "relative",
                width: "100%",
              }}
            >
              <Link to={`/profile/story/${story._id}`}>
                <div className="titile-card">{story.story_title}</div>
              </Link>
            </div>
            <div className="cursor-pointer mt-2 storie-edit-section">
              <BsThreeDotsVertical
                onClick={() => menuHandler(`${story._id}_edit`)}
                className="cursor-pointer"
                size={20}
              />
              <div
                className="dropdown-content storie-edit-dropdown-content"
                id={`${story._id}_edit`}
                style={{ display: "none" }}
              >
                <div
                  className="cursor-pointer drop-down-navbar-menu"
                  style={{ top: "4px" }}
                >
                  <div className="flex-space-btw display-flex-align-center">
                    <div
                      style={{ width: "85%" }}
                      onClick={() => {
                        setShowEditStoryModel(true);
                        setStoryToEdit(story);
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    >
                      Edit
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        document.getElementById(
                          `${story._id}_edit`,
                        ).style.display = "none";
                      }}
                    />
                  </div>
                </div>
                <div className="cursor-pointer drop-down-navbar-menu ">
                  analytics
                </div>
              </div>
            </div>
          </div>
          {/* {!story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } ml-2 mt-2`}
              >
                <p>
                  {story.story_text &&
                    story.story_text.length <= 350 &&
                    parse(story.story_text)}
                  {story.story_text &&
                    story.story_text.length > 350 &&
                    parse(story.story_text.substring(0, 350))}
                </p>
              </div>
            </Link>
          )} */}
          {/* {story.thumbnail_url && (
            <Link to={`/profile/story/${story._id}`}>
              <div
                className={`${
                  story.language === "ar"
                    ? "text-right"
                    : "text-left"
                } description-box ml-2 mt-2`}
              >
                <p>
                  {story.story_text &&
                    story.story_text.length <= 50 &&
                    parse(story.story_text)}
                  {story.story_text &&
                    story.story_text.length > 50 &&
                    parse(story.story_text.substring(0, 200))}
                </p>
              </div>
            </Link>
          )} */}
        </div>
      )}
    </>
  );
};

export default UserStoryCard;
