import React, { useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const RichText = ({ data, setData, update, updateText }) => {
  return (
    <div style={{ width: "100%" }}>
      {!update && (
        <CKEditor
          editor={ClassicEditor}
          data={data}
          onChange={(e, editor) => {
            setData(editor.getData());
          }}
          onError={(error, { willEditorRestart }) => {
            // If the editor is restarted, the toolbar element will be created once again.
            // The `onReady` callback will be called again and the new toolbar will be added.
            // This is why you need to remove the older toolbar.
            if (willEditorRestart) {
              this.editor.ui.view.toolbar.element.remove();
            }
          }}
        />
      )}
      {update && (
        <CKEditor
          editor={ClassicEditor}
          data={data}
          onChange={(e, editor) => {
            setData(editor.getData());
          }}
          onBlur={(e, editor) => {
            updateText(editor.getData());
          }}
          onError={(error, { willEditorRestart }) => {
            // If the editor is restarted, the toolbar element will be created once again.
            // The `onReady` callback will be called again and the new toolbar will be added.
            // This is why you need to remove the older toolbar.
            if (willEditorRestart) {
              this.editor.ui.view.toolbar.element.remove();
            }
          }}
        />
      )}
    </div>
  );
};

export default RichText;
