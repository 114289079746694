import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import profileDefualt from "../../images/default.jpeg";
import readShare from "../../images/Read&ShareThumbnail.png";

const weburl = process.env.REACT_APP_BACKEND_URL;
const StoriesSearchCardMobile = ({
  story,
  setSelectedStory,
  setSearchStories,
  tag_type,
}) => {
  const history = useHistory({});
  return (
    <div className="p-3 serach-result" style={{ cursor: "pointer" }}>
      <div>
        <div className="row">
          <div
            className="col-md-5"
            onClick={() => {
              if (story.story_type === "video") {
                setSelectedStory(story._id);
                setSearchStories(false);
              } else {
                let path = "";
                if (story.story_type === "stafs") {
                  path = `/expert/${story._id}?type=stafs`;
                } else if (story.story_type === "articles") {
                  path = `/article/${story._id}`;
                } else {
                  path = `/science-research/${story._id}`;
                }
                history.push(path);
              }
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <img
                style={{
                  width: "100%",
                }}
                src={story.thumbnail_url}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = readShare;
                }}
              />
            </div>
          </div>
          <div
            className="col-md-7 text-center-mobile"
            onClick={() => {
              if (story.story_type === "video") {
                setSelectedStory(story._id);
                setSearchStories(false);
              } else {
                let path = "";
                if (story.story_type === "stafs") {
                  path = `/expert/${story._id}?type=stafs`;
                } else if (story.story_type === "articles") {
                  path = `/article/${story._id}`;
                } else {
                  path = `/science-research/${story._id}`;
                }
                history.push(path);
              }
            }}
          >
            <div style={{ fontWeight: "700", fontSize: "20px" }}>
              {story.story_title}
            </div>
            <div
              style={{ color: "#606060", marginTop: "6px", fontSize: "12px" }}
            >
              {story.categories && story.categories.category_name}
            </div>
            <div
              style={{ color: "#606060", marginTop: "6px", fontSize: "12px" }}
            >
              {parse(story.story_text.substring(0, 100))}
            </div>
          </div>
        </div>
      </div>

      <div style={{ clear: "both" }}></div>
    </div>
  );
};

export default StoriesSearchCardMobile;
