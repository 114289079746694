import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios, { CancelToken, isCancel } from "axios";
import SideNavBar from "../Components/newCourse/SideNavBar";
import Langauge from "../Components/newCourse/Langauge";
import CategoryCourse from "../Components/newCourse/CategoryCourse";
import CourseTitile from "../Components/newCourse/CourseTitile";
import Section from "../Components/newCourse/Section";
import { useParams } from "react-router-dom";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";

const url = process.env.REACT_APP_BACKEND_URL;
const EditSubCourse = ({ user }) => {
  const [language, setLanguage] = useState("0");
  const [category, setCategory] = useState("0");
  const [currency, setCurrency] = useState("0");
  const [langaugeId, setLangaugeId] = useState("0");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [next, setNext] = useState(1);
  const [groupDeatils, setGroupDetails] = useState("");
  const [menu, setMenu] = useState("Sections");
  const [price, setPrice] = useState(0);
  const [sectionType, setSectionType] = useState(0);
  const params = useParams();

  useEffect(() => {
    const subDetails = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscriptionGroup?subscription_id=${params.id}`;

        const res = await getRequestedDataWithHeader(url, user.userData.token);
        console.log(res.data.body[0]);
        setGroupDetails(res.data.body[0]);
        setTitle(res.data.body[0].group_name);
        setDescription(res.data.body[0].group_description);
        setLanguage(res.data.body[0].language_id.code);
        setCategory(res.data.body[0].category_id._id);
        setLangaugeId(res.data.body[0].language_id._id);
        setPrice(res.data.body[0].subscription_price);
        if (res.data.body[0].currency_id) {
          setCurrency(res.data.body[0].currency_id._id);
        }
        console.log(res.data.body[0].section_type);
        setSectionType(res.data.body[0].section_type);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    };
    subDetails();
  }, []);

  const saveSubscriptionGroupHandler = async (saveType) => {
    if (!title) {
      toast.error("Please enter title");
      return;
    }

    if (langaugeId == "00") {
      toast.error("Please select the language");
      return;
    }

    if (category == "0") {
      toast.error("Please select category");
      return;
    }

    if (saveType === "create") {
      let formData = new FormData();
      formData.append("user_id", user.userData.loginUser._id);
      formData.append("group_name", title);
      formData.append("language_id", langaugeId);
      formData.append("category_id", category);
      formData.append("section_type", sectionType);
      const options = {
        headers: {
          Authorization: `Bearer ${user.userData.token}`,
        },
      };
      axios
        .post(`${url}/api/userProfile/addSubscription`, formData, options)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            setGroupDetails(res.data.body);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.status === 401) {
              localStorage.removeItem("userDataSessionWay");
              window.location.replace("/");
            }
          }

          toast.error("Some Thing Went Wrong");
          // videourlref.current.style.display = 'none'
          // uploadImgref.current.style.display = 'block'
        });
    } else {
      if (!description) {
        toast.error("Please enter description");
        return;
      }
      console.log(currency);
      if (currency == "0") {
        toast.error("Please select currency");
        return;
      }

      if (!price) {
        toast.error("Please enter price");
        return;
      }
      console.log(sectionType);
      try {
        const response = await fetch(
          `${url}/api/userProfile/updateSubscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.userData.token}`,
            },
            body: JSON.stringify({
              group_name: title,
              language_id: langaugeId,
              category_id: category,
              subscription_price: price,
              currency_id: currency,
              group_description: description,
              subscription_id: groupDeatils._id,
              section_type: sectionType,
            }),
          },
        );
        const responseData = await response.json();

        if (!response.ok) {
          if (response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
          throw new Error(responseData.message || "something went wrong");
        } else {
          setMenu("Into Video And Image");
          toast.success("Successfully updated");
        }
      } catch (err) {
        console.log(err);
        toast.error("Some Thing Went Wrong");
      }
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            maxWidth: "1400px",
            margin: "auto",
            padding: "3.2rem 2.4rem",
          }}
        >
          <div
            style={{
              maxWidth: "240px",
              width: "100%",
              float: "left",
              padding: "32px 24px 32px 0px",
            }}
          >
            <SideNavBar
              setMenu={setMenu}
              sectionType={sectionType}
              setSectionType={setSectionType}
            />
          </div>
          <Section
            menu={menu}
            setMenu={setMenu}
            title={title}
            setTitle={setTitle}
            user={user}
            language={language}
            setLanguage={setLanguage}
            setLangaugeId={setLangaugeId}
            category={category}
            setCategory={setCategory}
            currency={currency}
            setCurrency={setCurrency}
            price={price}
            setPrice={setPrice}
            description={description}
            setDescription={setDescription}
            saveSubscriptionGroupHandler={saveSubscriptionGroupHandler}
            groupDeatils={groupDeatils}
            sectionType={sectionType}
            setSectionType={setSectionType}
          />
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </>
  );
};

export default EditSubCourse;
