import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { getUserDetails } from "../../functions/editProfile/editProfile";
import EditPersonalDetails from "./EditPersonalDetails";
import ChangePassword from "./ChangePassword";
import BankDetails from "./BankDetails";
import LanguageDetails from "./LanguageDetails";
import ProfileSetting from "./ProfileSetting";

const EditProfile = ({ navbarMenuRef, logoutHandler, user_id, token }) => {
  const dispatch = useDispatch({});
  const [userProps, setUserProps] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [profileSetting, setProfileSetting] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [bankShow, setBankShow] = useState(false);
  const [languageShow, setLanguageShow] = useState(false);

  const userDetails = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getUserDetails`;
      const data = {
        user_id: user_id,
      };
      const res = await getUserDetails(url, data, token);
      setUserProps({ user: res.data.body.user, token: token });

      dispatch({
        type: "userUpdate",
        payload: res.data.body.user,
      });
    } catch (err) {
      console.log(err.message);
      //toast.error(err.message)
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  // const onClickOutside = (e) => {
  //   console.log(e.target);
  // };

  // useEffect(() => {
  //   window.addEventListener("click", onClickOutside);

  //   return () => {
  //     window.removeEventListener("click", onClickOutside);
  //   };
  // }, []);

  return (
    <div>
      <div
        className="flex-end mr-2 p-1"
        onClick={() => {
          navbarMenuRef.current.style.display = "none";
          setEditProfile(false);
          setProfileSetting(false);
          setPasswordShow(false);
          setBankShow(false);
          setLanguageShow(false);
        }}
      >
        <AiOutlineClose size={20} />
      </div>

      <div>
        <div
          className={`cursor-pointer drop-down-navbar-menu ${
            editProfile ? "edit-profile-open" : ""
          }`}
          onClick={() => {
            setProfileSetting(false);
            setPasswordShow(false);
            setBankShow(false);
            setLanguageShow(false);
            setEditProfile(!editProfile);
          }}
        >
          Edit Profile
        </div>
        {editProfile && userProps.user && (
          <EditPersonalDetails
            {...userProps}
            userDetails={userDetails}
            navbarMenuRef={navbarMenuRef}
            setEditProfile={setEditProfile}
          />
        )}
      </div>
      <div>
        <div
          className={`cursor-pointer drop-down-navbar-menu ${
            passwordShow ? "edit-profile-open" : ""
          }`}
          onClick={() => {
            setProfileSetting(false);
            setBankShow(false);
            setLanguageShow(false);
            setEditProfile(false);
            setPasswordShow(!passwordShow);
          }}
        >
          Change password
        </div>
        {passwordShow && userProps.user && (
          <ChangePassword
            {...userProps}
            userDetails={userDetails}
            navbarMenuRef={navbarMenuRef}
            setPasswordShow={setPasswordShow}
          />
        )}
      </div>

      <div>
        <div
          className={`cursor-pointer drop-down-navbar-menu ${
            profileSetting ? "edit-profile-open" : ""
          }`}
          onClick={() => {
            setPasswordShow(false);
            setBankShow(false);
            setLanguageShow(false);
            setEditProfile(false);
            setProfileSetting(!profileSetting);
          }}
        >
          Channel Settings
        </div>
        {profileSetting && userProps.user && (
          <ProfileSetting
            {...userProps}
            userDetails={userDetails}
            navbarMenuRef={navbarMenuRef}
            setProfileSetting={setProfileSetting}
          />
        )}
      </div>

      <div>
        <div
          className={`cursor-pointer drop-down-navbar-menu ${
            bankShow ? "edit-profile-open" : ""
          }`}
          onClick={() => {
            setProfileSetting(false);
            setPasswordShow(false);
            setLanguageShow(false);
            setEditProfile(false);
            setBankShow(!bankShow);
          }}
        >
          Bank Details
        </div>
        {bankShow && userProps.user && (
          <BankDetails
            {...userProps}
            userDetails={userDetails}
            navbarMenuRef={navbarMenuRef}
            setBankShow={setBankShow}
          />
        )}
      </div>

      <div>
        <div
          className={`cursor-pointer drop-down-navbar-menu ${
            bankShow ? "edit-profile-open" : ""
          }`}
          onClick={() => {
            setProfileSetting(false);
            setPasswordShow(false);
            setBankShow(false);
            setEditProfile(false);
            setLanguageShow(!languageShow);
          }}
        >
          Language
        </div>
        {languageShow && userProps.user && (
          <LanguageDetails
            {...userProps}
            userDetails={userDetails}
            navbarMenuRef={navbarMenuRef}
            setLanguageShow={setLanguageShow}
          />
        )}
      </div>

      <div
        className="cursor-pointer drop-down-navbar-menu "
        onClick={logoutHandler}
      >
        Logout
      </div>
    </div>
  );
};

export default EditProfile;
