const defaultState = {}

const currencies = (state = defaultState, action) => {
  switch (action.type) {
    case 'currency':
      return { ...state, currencies: action.payload }
    case 'userlanguages':
      return { ...state, languages: action.payload }
    case 'userCategories':
      return { ...state, categories: action.payload }
    default:
      return state
  }
}

export default currencies
