import React from "react";
import { toast } from "react-toastify";
import profileDefualt from "../../images/default.jpeg";
const weburl = process.env.REACT_APP_BACKEND_URL;

const SessionCardMobile = ({ followers }) => {
  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid rgba(0,0,0,.1)",
            paddingBottom: "5px",
            paddingTop: "5px",
          }}
        >
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
            src={followers.follower_user_id.imageUrl}
            alt="im-story-profile"
            onError={(e) => {
              e.target.onError = null;
              e.target.src = profileDefualt;
            }}
          />
          <div
            style={{
              width: "80%",
              padding: "5px",
            }}
          >
            <b>{followers.follower_user_id.user_name}</b>
            <br />
            <span>{followers.follower_user_id.user_name}</span>
          </div>

          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </div>
  );
};

export default SessionCardMobile;
