import React, { useState, useEffect } from "react";

import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UserStories from "../profile/UserStories";
import UserArticlesReSearch from "../profile/UserArticlesReSearch";
import UserCourses from "../profile/UserCourses";
import Locations from "./Locations";
import Profiles from "./Profiles";
import addStory from "../../images/add_blue.png";
import UserFollowers from "./UserFollowers";
import Finance from "./Finance";

import UserStoryModelControl from "./UserStoryModelControl";
import AddStoryModel from "../Models/AddStoryModel";
import AddLocationModel from "../Models/AddLocationModel";
import AddDocAndImageModel from "../Models/AddDocAndImageModel";
import AddLocations from "./AddLocations";
import { TryRounded } from "@mui/icons-material";

const url = process.env.REACT_APP_BACKEND_URL;

const ProfileStories = ({
  user,
  menuType,
  setProfile,
  setMenuType,
  stafType,
}) => {
  const [showAddStoryModel, setShowAddStoryModel] = useState(false);
  const [showAddDocAndImageModel, setShowAddDocAndImageModel] = useState(false);
  const [showAddLocationModel, setShowAddLocationModel] = useState(false);
  const [newLocationsAdded, setNewLocationsAdded] = useState(false);
  const [CVAddedAlready, setCVAddedAlready] = useState(0);
  const [isFromMobile, setIsFromMobile] = useState(true);
  const { FileUploadPercentage } = useSelector((state) => state);

  const closeModelHandler = () => setShowAddStoryModel(false);

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  return (
    <>
      <AddStoryModel
        user={user}
        onModelClose={closeModelHandler}
        showAddStoryModel={showAddStoryModel}
        menuType={menuType}
        stafType={stafType}
        setCVAddedAlready={setCVAddedAlready}
      />
      <AddLocationModel
        user={user}
        onModelClose={() => {
          setShowAddLocationModel(false);
        }}
        showAddLocationModel={showAddLocationModel}
        menuType={menuType}
        setNewLocationsAdded={setNewLocationsAdded}
        newLocationsAdded={newLocationsAdded}
      />

      {showAddDocAndImageModel && (
        <AddDocAndImageModel
          user={user}
          onModelClose={() => {
            setShowAddDocAndImageModel(false);
          }}
          showAddStoryModel={showAddDocAndImageModel}
          menuType={menuType}
          stafType={stafType}
        />
      )}

      {menuType === "HOME" && <Profiles />}

      {(menuType === "ARTICLES" || menuType === "SCIRES") && (
        <UserArticlesReSearch
          user={user}
          menuType={menuType}
          setShowAddDocAndImageModel={setShowAddDocAndImageModel}
        />
      )}

      {(menuType === "STORIES" || menuType === "STAFS") && (
        <UserStories
          user={user}
          menuType={menuType}
          stafType={stafType}
          setCVAddedAlready={setCVAddedAlready}
          CVAddedAlready={CVAddedAlready}
          setShowAddStoryModel={setShowAddStoryModel}
        />
      )}
      {menuType === "COURSES" && (
        <UserCourses
          user={user}
          authorize={{ token: user.token, userId: user.loginUser._id }}
          setProfile={setProfile}
          menuType={menuType}
        />
      )}

      {menuType === "PGROUPS" && (
        <UserCourses
          user={user}
          authorize={{ token: user.token, userId: user.loginUser._id }}
          setProfile={setProfile}
          menuType={menuType}
        />
      )}

      {menuType === "LOCATIONS" && (
        <>
          <div className="row">
            <AddLocations user={user} menuType={menuType} />
          </div>
          {/* <Locations
            user={user}
            menuType={menuType}
            newLocationsAdded={newLocationsAdded}
          /> */}
        </>
      )}

      {menuType === "SUBSCRIBERS" && (
        <UserFollowers
          user={user}
          authorize={{ token: user.token, userId: user.loginUser._id }}
          setProfile={setProfile}
          menuType={menuType}
        />
      )}
      {menuType === "FINANCE" && (
        <Finance
          user={user}
          authorize={{ token: user.token, userId: user.loginUser._id }}
          setProfile={setProfile}
          menuType={menuType}
        />
      )}
      {/* {(menuType === "STORIES" || menuType === "STAFS") && (
        <div
          style={{
            position: "absolute",
            top: "-10px",
            right: "50px",
            cursor: "pointer",
          }}
          className="d-md-block d-none d-sm-block"
          onClick={() => {
            if (
              FileUploadPercentage.uploadVideoMeta.isVideoUploading === "YES"
            ) {
              alert("Until Upload You can't add new one");
              return;
            }

            setShowAddStoryModel(true);
          }}
        >
          <img src={addStory} alt="add" style={{ height: 50 + "px" }} />
        </div>
      )} */}
      {/* {(menuType === "COURSES" || menuType === "PGROUPS") && (
        <div
          style={{
            position: "absolute",
            top: "-10px",
            right: "50px",
            cursor: "pointer",
          }}
          className="d-md-block d-none d-sm-block"
        >
          <Link to={`/new/course/mange?type=${menuType}`}>
            <img src={addStory} alt="add" style={{ height: 50 + "px" }} />
          </Link>
        </div>
      )} */}
      {/* 
      {(menuType === "ARTICLES" || menuType === "SCIRES") && (
        <div
          style={{
            position: "absolute",
            top: "-10px",
            right: "50px",
            cursor: "pointer",
          }}
          className="d-md-block d-none d-sm-block"
          onClick={() => {
            if (
              FileUploadPercentage.uploadVideoMeta.isVideoUploading === "YES"
            ) {
              alert("Until Upload You can't add new one");
              return;
            }

            setShowAddDocAndImageModel(true);
          }}
        >
          <img src={addStory} alt="add" style={{ height: 50 + "px" }} />
        </div>
      )} */}

      {/* {menuType === 'LOCATIONS' && (
        <div
          style={{
            position: 'fixed',
            bottom: '30px',
            right: '50px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setShowAddLocationModel(true)
          }}
        >
          <img src={addStory} alt='add' style={{ height: 50 + 'px' }} />
        </div>
      )} */}
    </>
  );
};

const MapStateToProps = ({ BasicUserReducers: { user } }) => {
  return { user };
};

export default connect(MapStateToProps)(ProfileStories);
