import React, { useEffect, useState, useRef } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

import {
  AiOutlineDown,
  AiOutlineUp,
  AiFillPlayCircle,
  AiOutlineFileText,
  AiOutlinePlusCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useSelector, useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import StoryModelControl from "../subscriptionGroup/StoryModelControl";
import CourseSectionsLessons from "./CourseSectionsLessons";
import CourseIntroImageVideoEdit from "./CourseIntroImageVideoEdit";
import profileDefualt from "../../images/default.jpeg";
import RichText from "../editProfile/RichText";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";
import addcover from "../../images/ADD_COVER.png";
import { selectIdValue } from "../../utils/sharedfunction";

import AddLessionInput from "./AddLessionInput";
const url = process.env.REACT_APP_BACKEND_URL;
let previousStroyID = "describe_video_story";
const CourseMaterials = ({
  group_info,
  getSections,
  sections,
  setSections,
  setIsCourseListClose,
  setProfile,
  isHecanUnPublish,
  isHecanUnpublishOrStopCourse,
}) => {
  console.log(group_info, "group_info");
  const { BasicUserReducers } = useSelector((state) => state);
  const { user } = BasicUserReducers;

  const dispatch = useDispatch({});
  const [videoUrl, setVideoUrl] = useState(
    group_info.group_introduction_video_url,
  );
  const [story, setStory] = useState({});
  const [describeVideo, setDescribeVideo] = useState(true);
  const [showChatModel, setShowChatModel] = useState({
    isModelOpen: false,
    section_id: "",
  });

  const [sectionTittle, setSectionTitle] = useState("");
  const [lessionTittle, setLessionTitle] = useState("");
  const [sectionBoxShow, setSectionBoxShow] = useState(false);
  const [lessionBoxShow, setLessionBoxShow] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState("");

  const menuBoxBioRef = useRef(null);

  useEffect(() => {
    setVideoUrl(group_info.group_introduction_video_url);
    getSections();
    setDescribeVideo(true);
  }, [group_info]);

  const toggleDropDownSections = (id) => {
    let isDisplay = document.getElementById(id).style.display;
    if (isDisplay == "none") {
      document.getElementById(id).style.display = "block";
      document.getElementById(`${id}_up_arrow`).style.display = "block";
      document.getElementById(`${id}_down_arrow`).style.display = "none";
    } else {
      document.getElementById(id).style.display = "none";
      document.getElementById(`${id}_down_arrow`).style.display = "block";
      document.getElementById(`${id}_up_arrow`).style.display = "none";
    }
  };

  const handleStory = async (id, video_url, e) => {
    if (id === "describe_video_story") {
      setVideoUrl(video_url);
      document.querySelectorAll(".selected-tab-lesson").forEach((div) => {
        div.style.backgroundColor = "white";
      });
      //document.getElementById(id).style.backgroundColor = "#d1d7dc";
    } else {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getCourseStory?story_id=${id}`;
      try {
        let res = await getRequestedDataWithHeader(url, group_info.token);
        //setEditDescription(false)

        setVideoUrl(res.data.body.story_url);

        setStory(res.data.body);

        document.querySelectorAll(".selected-tab-lesson").forEach((div) => {
          div.style.backgroundColor = "white";
        });

        if (document.getElementById(id)) {
          document.getElementById(id).style.backgroundColor = "#d1d7dc";
        } else {
          setSelectedLesson(id);
        }
      } catch (err) {
        //toast.error(err.message)
        console.log(err.message);
      }
    }
    setDescribeVideo(false);
    // if (previousStroyID)
    //   document.getElementById(previousStroyID).style.background = 'white'
    // if (id) document.getElementById(id).style.background = '#d1d7dc'
    // previousStroyID = id
  };

  const updatePreview = async (id, isPreview) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryIsPreview`;
    const data = {
      story_id: id,
      isStoryPreview: isPreview,
    };
    try {
      let res = await getDataPostWithHeader(url, data, group_info.token);
      getSections();
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const closeModelHandler = () =>
    setShowChatModel({
      isModelOpen: false,
      section_id: "",
    });

  const addSection = async () => {
    const language = group_info.language_id._id;
    const category_id = group_info.category_id._id;
    const subscription_id = group_info._id;
    const token = group_info.token;

    if (!sectionTittle) {
      return;
    }

    const data = {
      section_title: sectionTittle,
      language_id: language,
      category_id: category_id,
      subscription_id: subscription_id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addSubSections`;
      let res = await getDataPostWithHeader(url, data, token);
      getSections();
      setSectionTitle("");
    } catch (err) {
      console.log(err);
      toast.error("System Failed");
    }
  };

  const addLession = (section_id) => {
    let totalSeconds = 0;
    // let hours = Math.floor(totalSeconds / 3600)
    // totalSeconds %= 3600
    // let minutes = Math.floor(totalSeconds / 60)
    // let seconds = Math.floor(totalSeconds % 60)

    // console.log(hours, minutes, seconds)

    const storyTittle = lessionTittle;
    const language = group_info.language_id._id;
    const story = "";
    const storyTypeProvider = "2";
    const user_id = group_info.user_id._id
      ? group_info.user_id._id
      : group_info.user_id;
    const category_id = group_info.category_id._id;
    const subscription_id = group_info._id;
    const token = group_info.token;
    if (!storyTittle) {
      return;
    }

    let formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("subscription_id", subscription_id);
    formData.append("category_id", category_id);
    formData.append("story_provider", storyTypeProvider);
    formData.append("story_language_id", language);
    formData.append("story_title", storyTittle);
    formData.append("story_duration", totalSeconds);
    formData.append("story_text", story);
    formData.append("story_type", "");
    formData.append("section_type", group_info.section_type);

    if (storyTypeProvider === "2") {
      formData.append("section_id", section_id);
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
        }
      },
    };

    axios
      .post(`${url}/api/userProfile/sendStory`, formData, options)
      .then((res) => {
        getSections();

        if (document.getElementById(section_id).style.display !== "block") {
          toggleDropDownSections(section_id);
        }
        setLessionTitle("");
        setSectionBoxShow(false);
        handleStory(res.data.body._id, "");
      })
      .catch((err) => {
        console.log(err);
        toast.error("System Failed");
      });
  };

  const publishCourse = async (sections) => {
    if (sections.length > 0) {
      if (sections[0].stories_of_this_section.length > 0) {
        if (sections[0].stories_of_this_section[0].story_type === "video") {
          if (sections[0].stories_of_this_section[0].story_url === "") {
            alert("please add video or text to publish to the first lesson");
            return;
          }
        } else {
          if (sections[0].stories_of_this_section[0].story_text === "") {
            alert("please add video or text to publish to the first lesson");
            return;
          }
        }
        const data = {
          subscription_id: group_info._id,
          user_id: user.loginUser._id,
          language_id: group_info.language_id._id,
          category_id: group_info.category_id._id,
          profile_id: group_info.profile_id,
        };

        try {
          let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/publishCourse`;
          let res = await getDataPostWithHeader(url, data, group_info.token);

          dispatch({
            type: "group_details",
            payload: {
              ...res.data.body,
              user_id: group_info.user_id,
              token: group_info.token,
            },
          });
        } catch (err) {
          console.log(err.message);
          alert(err.message);
        }
      } else {
        alert("At least you should have one lesson");
      }
    } else {
      alert("At least you should have one lesson");
    }
  };

  const unpublishCourse = async () => {
    const data = {
      subscription_id: group_info._id,
      user_id: user.loginUser._id,
    };

    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/unPublishCourse`;
      let res = await getDataPostWithHeader(url, data, group_info.token);

      dispatch({
        type: "group_details",
        payload: {
          ...res.data.body,
          user_id: group_info.user_id,
          token: group_info.token,
        },
      });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
  };
  return (
    <>
      <StoryModelControl
        group_info={group_info}
        onModelClose={closeModelHandler}
        showChatModel={showChatModel.isModelOpen}
        modelType="defaultstory"
        getSections={getSections}
        storyProvider="2"
        section_id={showChatModel.section_id}
      />
      <div className="row">
        <div className="col-md-8">
          {!describeVideo && (
            <CourseSectionsLessons
              group_info={group_info}
              videoUrl={videoUrl}
              story={story}
              handleStory={handleStory}
              getSections={getSections}
              setProfile={setProfile}
            />
          )}

          {describeVideo && (
            <CourseIntroImageVideoEdit
              group_info={group_info}
              setIsCourseListClose={setIsCourseListClose}
              setProfile={setProfile}
            />
          )}
        </div>
        <div
          className="col-md-4"
          style={{
            padding: "0px",
            margin: "0px",
            borderLeft: "1px solid #d1d7dc",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "Start",
                borderBottom: "1px solid #d1d7dc",
                padding: "12px",
              }}
            >
              <div style={{ position: "relative" }}>
                {group_info.status === 0 && (
                  <button
                    className="btn btn-info"
                    onClick={() => publishCourse(sections)}
                  >
                    Publish
                  </button>
                )}
                {group_info.status === 1 && (
                  <>
                    <span>Active</span>

                    {/* <button className="btn btn-info" onClick={unpublishCourse}>
                      Un Publish 
                    </button> */}
                    <BsThreeDotsVertical
                      onClick={() => {
                        menuBoxBioRef.current.style.display = "block";
                        isHecanUnpublishOrStopCourse();
                      }}
                    />

                    <div
                      className="menu-box-bio display-none"
                      style={{
                        position: "absolute",
                        right: "-4px",
                        top: "5px",
                      }}
                      ref={menuBoxBioRef}
                    >
                      <div className="menu-header">
                        <AiOutlineClose
                          className="cursor-pointer"
                          onClick={() => {
                            menuBoxBioRef.current.style.display = "none";
                          }}
                        />
                      </div>
                      {isHecanUnPublish.count === 0 && (
                        <div
                          onClick={unpublishCourse}
                          className="cursor-pointer"
                        >
                          Un publish
                        </div>
                      )}
                      {isHecanUnPublish.count > 0 && (
                        <div
                          onClick={unpublishCourse}
                          className="cursor-pointer"
                        >
                          Stop temporarily
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #d1d7dc",
              }}
            >
              <div id="describing_video_section" style={{ display: "block" }}>
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    padding: "12px",
                    display: "flex",
                  }}
                  onClick={(e) => {
                    handleStory(
                      "describe_video_story",
                      group_info.group_introduction_video_url,
                      e,
                    );
                    setDescribeVideo(true);
                  }}
                >
                  {group_info.group_video_thumbnail_url && (
                    <img
                      id="thumnail_bio"
                      src={group_info.group_video_thumbnail_url}
                      className="cursor-pointer"
                      alt="thumbnail"
                      style={{ height: "100px" }}
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = addcover;
                      }}
                    />
                  )}

                  {!group_info.group_video_thumbnail_url && (
                    <img
                      id="thumnail_bio"
                      src={addcover}
                      className="cursor-pointer"
                      alt="thumbnail"
                      style={{ height: "100px" }}
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = addcover;
                      }}
                    />
                  )}
                  <div style={{ marginLeft: "5px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "600" }}>
                      {group_info.group_name}
                    </div>
                    <div>{group_info.category_id.category_name}</div>
                  </div>
                </div>
                {/* <div
                  id="describe_video_story"
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    cursor: "pointer",
                    background: "#d1d7dc",
                    padding: "12px",
                  }}
                  className="selected-tab-lesson"
                  onClick={(e) => {
                    handleStory(
                      "describe_video_story",
                      group_info.group_introduction_video_url,
                      e,
                    );
                    setDescribeVideo(true);
                  }}
                >
                  Describe your course
                </div> */}
              </div>
            </div>

            <div
              style={{
                cursor: "pointer",
                padding: "12px",
                borderBottom: "1px solid #d1d7dc",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  fontWeight: "500",
                  display: "flex",
                }}
                onClick={() => setSectionBoxShow(!sectionBoxShow)}
              >
                <AiOutlinePlusCircle
                  className="mr-2"
                  style={{ alignSelf: "center", marginTop: "1px" }}
                  size={30}
                />
                Add Section
              </div>
              {sectionBoxShow && (
                <input
                  style={{ width: "100%" }}
                  placeholder="Add Section Title"
                  className="mt-2 p-1"
                  type="text"
                  onChange={(e) => {
                    setSectionTitle(e.target.value);
                  }}
                  value={sectionTittle}
                  onBlur={addSection}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSectionBoxShow(!sectionBoxShow);
                      addSection();
                    }
                  }}
                />
              )}
            </div>

            {sections.length >= 0 &&
              sections.map((section, index) => {
                if (index === 0) {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid #d1d7dc",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: " space-between",
                          cursor: "pointer",
                          padding: "12px",
                        }}
                        onClick={() => {
                          toggleDropDownSections(section._id);
                        }}
                      >
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                          Section {index + 1}: {section.section_title}
                        </div>
                        <div
                          id={section._id + "_down_arrow"}
                          style={{ display: "none" }}
                        >
                          <AiOutlineDown />
                        </div>

                        <div
                          id={section._id + "_up_arrow"}
                          style={{ display: "block" }}
                        >
                          <AiOutlineUp />
                        </div>
                      </div>

                      <AddLessionInput
                        setLessionTitle={setLessionTitle}
                        addLession={addLession}
                        section_id={section._id}
                        lessionTittle={lessionTittle}
                      />

                      <div id={section._id} style={{ display: "block" }}>
                        {section.stories_of_this_section.length >= 0 &&
                          section.stories_of_this_section.map(
                            (story, index) => {
                              let totalSeconds = story.story_duration;

                              let hours = Math.floor(totalSeconds / 3600);
                              totalSeconds %= 3600;
                              let minutes = Math.floor(totalSeconds / 60);
                              let seconds = totalSeconds % 60;

                              let format = "";
                              if (hours > 0) {
                                format = `${hours}hours ${minutes}min`;
                              } else {
                                format = `${minutes}min`;
                              }

                              if (format == "0min") {
                                format = `${Math.floor(seconds)}sec`;
                              }
                              return (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    padding: "12px",
                                  }}
                                  id={story._id}
                                  className={`${
                                    selectedLesson &&
                                    "selected-tab-background-course"
                                  } selected-tab-lesson `}
                                  key={index}
                                  onClick={(e) => {
                                    handleStory(story._id, story, e);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <span>{story.story_title}</span>

                                      <div style={{ display: "flex" }}>
                                        <div>
                                          {story.story_type === "video" && (
                                            <AiFillPlayCircle color="#6a6f73" />
                                          )}
                                          {story.story_type === "text" && (
                                            <AiOutlineFileText color="#6a6f73" />
                                          )}
                                        </div>
                                        <div
                                          style={{
                                            color: "#6a6f73",
                                            marginTop: "2px",
                                            marginLeft: "3px",
                                          }}
                                        >
                                          {format}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{ alignSelf: "center" }}
                                      className="loading"
                                      id={`${story._id}_process`}
                                    ></div>

                                    <div style={{ alignSelf: "center" }}>
                                      Make view to public
                                      {story.isStoryPreview ? (
                                        <BsToggleOn
                                          size={20}
                                          onClick={() =>
                                            updatePreview(story._id, false)
                                          }
                                          className="ml-1"
                                        />
                                      ) : (
                                        <BsToggleOff
                                          size={20}
                                          onClick={() =>
                                            updatePreview(story._id, true)
                                          }
                                          className="ml-1"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid #d1d7dc",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: " space-between",
                          cursor: "pointer",
                          padding: "12px",
                        }}
                        onClick={() => {
                          toggleDropDownSections(section._id);
                        }}
                      >
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                          Section {index + 1}: {section.section_title}
                        </div>
                        <div
                          id={section._id + "_down_arrow"}
                          style={{ display: "block" }}
                        >
                          <AiOutlineDown />
                        </div>

                        <div
                          id={section._id + "_up_arrow"}
                          style={{ display: "none" }}
                        >
                          <AiOutlineUp />
                        </div>
                      </div>
                      <AddLessionInput
                        setLessionTitle={setLessionTitle}
                        addLession={addLession}
                        section_id={section._id}
                        lessionTittle={lessionTittle}
                      />
                      <div id={section._id} style={{ display: "none" }}>
                        {section.stories_of_this_section.length >= 0 &&
                          section.stories_of_this_section.map(
                            (story, index) => {
                              let totalSeconds = story.story_duration;

                              let hours = Math.floor(totalSeconds / 3600);
                              totalSeconds %= 3600;
                              let minutes = Math.floor(totalSeconds / 60);
                              let seconds = totalSeconds % 60;

                              let format = "";
                              if (hours > 0) {
                                format = `${hours}hour ${minutes}min`;
                              } else {
                                format = `${minutes}min`;
                              }

                              if (format == "0min") {
                                format = `${Math.floor(seconds)}sec`;
                              }
                              return (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    padding: "12px",
                                  }}
                                  id={story._id}
                                  className="selected-tab-lesson"
                                  key={index}
                                  onClick={(e) => {
                                    handleStory(story._id, story, e);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <span>{story.story_title}</span>

                                      <div style={{ display: "flex" }}>
                                        <div>
                                          {story.story_type === "video" && (
                                            <AiFillPlayCircle color="#6a6f73" />
                                          )}
                                          {story.story_type === "text" && (
                                            <AiOutlineFileText color="#6a6f73" />
                                          )}
                                        </div>
                                        <div
                                          style={{
                                            color: "#6a6f73",
                                            marginTop: "2px",
                                            marginLeft: "3px",
                                          }}
                                        >
                                          {format}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{ alignSelf: "center" }}
                                      className="loading"
                                      id={`${story._id}_process`}
                                    ></div>

                                    <div style={{ alignSelf: "center" }}>
                                      Make view to public
                                      {story.isStoryPreview ? (
                                        <BsToggleOn
                                          size={20}
                                          onClick={() =>
                                            updatePreview(story._id, false)
                                          }
                                          className="ml-1"
                                        />
                                      ) : (
                                        <BsToggleOff
                                          size={20}
                                          onClick={() =>
                                            updatePreview(story._id, true)
                                          }
                                          className="ml-1"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseMaterials;
