const initialState = {
  cartTotalPrice: 0,
  courses: [],
};

const CheckoutLists = (state = initialState, action) => {
  switch (action.type) {
    case "CARTCHECKOUT":
      return {
        cartTotalPrice: action.payload.cartTotalPrice,
        courses: action.payload.courses,
      };
    default:
      return state;
  }
};

export default CheckoutLists;
