const defaultState = {
  IsCourseListCloseOFOtherProfile: false,
  IsShowProfileOfOtherProfile: true,
  isShowProfile: false,
  isCourseListClose: true,
};

const ChannelHandleReducers = (state = defaultState, action) => {
  switch (action.type) {
    case "IsCourseListCloseOtherProfile":
      return { ...state, IsCourseListCloseOFOtherProfile: action.payload };
    case "IsShowProfileOfOtherProfile":
      return { ...state, IsShowProfileOfOtherProfile: action.payload };
    case "isShowProfile":
      return { ...state, isShowProfile: action.payload };
    case "isCourseListClose":
      return { ...state, isCourseListClose: action.payload };

    default:
      return state;
  }
};

export default ChannelHandleReducers;
