import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";
import CatgeorySearchBox from "../Models/CatgeorySearchBox";

const CategoryCourse = ({
  language,
  langaugeId,
  setCategory,
  category,
  selectStyle,
  setNewCategory,
  newCategory,
}) => {
  const userCategory = useRef(null);
  const [categories, setcategories] = useState([]);

  const categoryHandler = async (value) => {
    console.log(value);
    setNewCategory(value);
    if (value !== "") {
      try {
        if (langaugeId !== "0") {
          let categories = await categgoryBasedLangaugeBYID(value);
          console.log(categories);
          setcategories(categories);
        } else {
          alert("Please select langauge");
        }
      } catch (err) {
        console.log(err);
        toast.error("something went wrong");
      }
    }
  };

  const selecetCategory = (cat_id, category_name) => {
    setCategory(cat_id);
    setNewCategory(category_name);
    setcategories([]);
  };

  // const categgoryBasedOnLangaugeHandler = (e) => {
  //   setCategory(e.target.value);
  // };

  return (
    <>
      <div style={{ position: "relative", flexGrow: "1" }}>
        <input
          type="text"
          style={selectStyle}
          placeholder="Enter category"
          value={newCategory}
          onChange={(e) => categoryHandler(e.target.value)}
        />
        {/* <select
        name="language"
        ref={userCategory}
        defaultValue={category}
        style={selectStyle}
        onChange={categgoryBasedOnLangaugeHandler}
      ></select> */}
        {categories.length > 0 && (
          <CatgeorySearchBox
            categories={categories}
            selecetCategory={selecetCategory}
          />
        )}
      </div>
    </>
  );
};

export default CategoryCourse;
