const defaultState = {
  roomOne: "",
};

const ROOMCHAT = (state = defaultState, action) => {
  switch (action.type) {
    case "ROOMCONNECT":
      return { ...state, roomOne: action.payload };
    case "ROOMDISCONNECT":
      return { ...state, roomOne: "" };

    default:
      return state;
  }
};

export default ROOMCHAT;
