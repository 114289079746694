import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import RichText from "../editProfile/RichText";
import ReactPlayer from "react-player";
import { FaPlus } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import {
  BsArrowLeft,
  BsThreeDotsVertical,
  BsPencil,
  BsToggleOn,
  BsToggleOff,
} from "react-icons/bs";
import axios, { CancelToken, isCancel } from "axios";
import {
  AiFillCamera,
  AiOutlinePlayCircle,
  AiOutlineClose,
} from "react-icons/ai";
import profileDefualt from "../../images/default.jpeg";
import { useDispatch, useSelector } from "react-redux";
import StoryAttacment from "../newCourse/StoryAttacment";
import addcover from "../../images/default-thumbnail.png";
import ModelV from "../../utils/models/DefaultModel";
import VideoModel from "../VideoModel";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";

import HlsPlayer from "../../Components/players/HlsPlayer";
import {
  dataURLtoBlob,
  videoPickerHandler,
  responseMessageDispaly,
  selectValueValue,
  selectIdValue,
  selectTextValue,
  setWithExpiry,
  getWithExpiry,
  calculateVideoRatio,
} from "../../utils/sharedfunction";

import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
const url = process.env.REACT_APP_BACKEND_URL;
const CourseStoryView = ({
  group_info,
  videoUrl,
  story,
  handleStory,
  getSections,
}) => {
  const dispatch = useDispatch({});
  const [editDescription, setEditDescription] = useState(false);
  const [testStory, setTextStory] = useState(false);

  const [editDescriptionValue, setEditDescriptionValue] = useState(
    story.story_text,
  );
  const [attachemnt, setAttachment] = useState(false);
  const [isPreview, setIsPreview] = useState(story.isStoryPreview);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [description, setDescription] = useState(true);
  const [showCoverCrop, setShowCoverCrop] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [videoProcessingUpload, setVideoProcessingUpload] = useState(false);

  const [imageProcessingUpload, setImageProcessingUpload] = useState(0);
  const videoRef = useRef(null);
  const bioImageRef = useRef(null);
  const menuBoxBioRef = useRef(null);
  const videoPickerRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const videobioUpload = useRef(null);

  const { videoProcessingReducer, videoUploadingPercentageReducers } =
    useSelector((state) => state);

  useEffect(() => {
    setEditDescriptionValue(story.story_text);

    if (story.story_type === "video") {
      setDescription(true);
      setAttachment(false);
    } else {
      setDescription(true);
      setAttachment(true);
    }

    if (!story.story_type) {
      setTextStory(false);
      setAttachment(false);
    }
    // Cleanup function
    return () => {
      // Perform cleanup actions
      // For example, resetting state or canceling network requests
      setEditDescription(false);
    };
  }, [story]);

  const videofileInputHandler = async (file) => {
    let blobURL = URL.createObjectURL(file);
    const dataVideo = {
      user_id: group_info.user_id._id,
      keyname: "userFiles/Courses",
      filename: file.name,
      fileType: file.type,
    };

    const { ratio } = await calculateVideoRatio(
      videobioUpload.current,
      blobURL,
    );
    console.log("ratio", ratio);

    setUploadPercentage(1);
    dispatch({
      type: "UPLOADINGVIDEOS",
      payload: { [story._id]: 1 },
    });

    getPresignedUrl("getPresignedURLForVideUpload", dataVideo, file, ratio);
  };

  const getPresignedUrl = async (apiName, data, pickedFile, ratio) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, group_info.token);
      if (res.data.status === 200) {
        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
          ratio,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl, ratio) => {
    if (apiName !== "getPresignedURLForCoverUpload") {
      setUploadPercentage(1);
      dispatch({
        type: "UPLOADINGVIDEOS",
        payload: { [story._id]: 1 },
      });
    }
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
              setImageUploadPercentage(percent);
            } else {
              dispatch({ type: "processing", payload: { [story._id]: true } });
              setVideoProcessingUpload(true);
              setUploadPercentage(percent);
              dispatch({
                type: "UPLOADINGVIDEOS",
                payload: { [story._id]: 0 },
              });
            }
          } else {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else {
              console.log(percent);
              setUploadPercentage(percent + 1);
              dispatch({
                type: "UPLOADINGVIDEOS",
                payload: { [story._id]: percent + 1 },
              });
            }
            document.getElementById(`${story._id}_process`).innerHTML =
              "uploading";
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            const dataUpdateImage = {
              story_id: story._id,
              path: fileUrl,
            };
            const res2 = await getDataPostWithHeader(
              `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryThumbnail`,
              dataUpdateImage,
              group_info.token,
            );

            //if you want thumbnail after update  use this if condition
            // if (videoUploadingPercentageReducers[story._id] <= 0) {
            //   handleStory(story._id);
            // }

            setImageUploadPercentage(0);
            setImageProcessingUpload(false);
          } else {
            document.getElementById(`${story._id}_process`).innerHTML =
              "Processing....";

            try {
              console.log("ratio22", ratio);
              const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
              const data = {
                url: fileUrl,
                user_id: group_info.user_id._id,
                whereToUpdate: "courseVideoUpload",
                id: story._id,
                duration: videobioUpload.current.duration,
                videoRatio: ratio,
              };

              const res1 = getDataPostWithHeader(
                urlConvert,
                data,
                group_info.token,
              );
            } catch (err) {
              throw new Error("update error or suspended");
            }
            document.getElementById(`${story._id}_process`).innerHTML = "";
            const dataUpdateVideo = {
              story_id: story._id,
              video_path: fileUrl,
              duration: videobioUpload.current.duration,
            };
            //setHlsUrl(res1.data.body.url);

            const res2 = await getDataPostWithHeader(
              `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryVideo`,
              dataUpdateVideo,
              group_info.token,
            );
            handleStory(story._id);
            //getSections(1);
          }

          setUploadPercentage(0);
          dispatch({
            type: "UPLOADINGVIDEOS",
            payload: { [story._id]: 0 },
          });
          document.getElementById(`${story._id}_process`).innerHTML = "";
          setVideoProcessingUpload(false);
          dispatch({ type: "processing", payload: { [story._id]: false } });
        }
      })
      .catch((err) => {
        console.log(err);
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else {
          console.log(err.message);
          setUploadPercentage(0);

          if (err.message === "update error or suspended") {
            alert(err.message);
          } else {
            if (err.message !== "User has canceled the file upload") {
              alert(
                "While uploading something went wrong ! try again uploading",
              );
            }
          }
          setUploadPercentage(0);
          dispatch({
            type: "UPLOADINGVIDEOS",
            payload: { [story._id]: 0 },
          });
          setVideoProcessingUpload(false);
          dispatch({ type: "processing", payload: { [story._id]: false } });
        }
      });
  };

  const uploadVideoHandler = (type, path, Cover, base64img, videoFile) => {
    let formData = new FormData();

    if (type === "video") {
      formData.append("video", videoFile);
      let isCoverPicBio = getWithExpiry("courseStory");
      if (isCoverPicBio === null) {
        formData.append("video_cover", Cover);
      } else {
        var imgeFile = dataURLtoBlob(isCoverPicBio);
        formData.append("video_cover", imgeFile);
      }
      formData.append("video_cover_key_name", story.thumbnail_url);
      formData.append("video_key_name", story.story_url);
    } else {
      formData.append("image", Cover);
      formData.append("Name", story.thumbnail_url);
      setWithExpiry("courseStory", base64img, 100000);
    }
    formData.append("story_id", story._id);
    const options = {
      headers: {
        Authorization: `Bearer ${group_info.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
          dispatch({
            type: "UPLOADINGVIDEOS",
            payload: { [story._id]: percent },
          });
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        setUploadPercentage(0);
        dispatch({
          type: "UPLOADINGVIDEOS",
          payload: { [story._id]: 0 },
        });
        if (type === "video") {
          //bioImageRef.current.src = base64img
          videoRef.current.poster = base64img;
          videobioUpload.current.src = `${res.data.body.story_url}`;
          videoRef.current.src = `${res.data.body.story_url}`;
        } else if (type === "cover") {
          videoRef.current.poster = `${res.data.body.thumbnail_url}`;
        }
        closeSubMenuHandler();
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (type === "video") {
          videobioUpload.current.src = "";
        } else {
          bioImageRef.current.src = "";
        }
        setUploadPercentage(0);
        dispatch({
          type: "UPLOADINGVIDEOS",
          payload: { [story._id]: 0 },
        });
        if (isCancel(err)) {
          alert(err.message);
          toast.error("Aborted");
        } else {
          toast.error("System Failed");
        }
      });
  };

  const closeBioMenuHander = () => {
    menuBoxBioRef.current.style.display = "none";
  };
  const openCoverHandler = () => {
    closeSubMenuHandler();
    setShowCoverCrop(true);
  };

  const closeSubMenuHandler = () => {
    menuBoxBioRef.current.style.display = "none";
  };

  const coverfileInputHandler = (file) => {
    var imgeblob = dataURLtoBlob(file);

    const dataImage = {
      user_id: group_info.user_id._id,
      keyname: "userFiles/Courses",
      filename: `screnhot_${Math.round(Math.random() * 1e5)}.png`,
      fileType: imgeblob.type,
    };

    getPresignedUrl("getPresignedURLForCoverUpload", dataImage, imgeblob);
    // var path = `${url}/api/userProfile/updateStoryThumbnail`
    // var type = 'cover'
    // uploadVideoHandler(type, path, imgeFile, file)
  };

  const closeCoverHandler = () => setShowCoverCrop(false);

  const updateDescription = async (value) => {
    if (value !== "") {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryDescription`;
      const data = {
        story_id: story._id,
        story_provider: story.story_provider,
        story_description: editDescriptionValue,
        story_type: story.story_type || "text",
        duration: "120",
      };
      try {
        let res = await getDataPostWithHeader(url, data, group_info.token);
        //setEditDescription(false)
        // getSections(1);
        handleStory(story._id);
        setEditDescription(false);
        setEditDescriptionValue("");
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      setEditDescription(false);
    }
  };

  const selectTabs = (e, tab) => {
    // document.querySelectorAll(".chapter-tabs").forEach((div) => {
    //   div.style.color = "#6a6f73";
    //   div.style.borderBottom = "none";
    // });

    // e.target.style.color = "black";
    // e.target.style.borderBottom = "1px solid black";

    if (tab === "description") {
      setDescription(true);
      setAttachment(false);
    } else {
      if (story.story_type === "video") {
        setDescription(false);
        setAttachment(true);
      } else {
        setAttachment(true);
      }
    }
  };

  const updatePreview = async (isPreview) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateStoryIsPreview`;
    const data = {
      story_id: story._id,
      isStoryPreview: isPreview,
    };
    try {
      let res = await getDataPostWithHeader(url, data, group_info.token);
      setIsPreview(isPreview);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Preview option to the users
    </Tooltip>
  );

  return (
    <>
      <Modal
        show={showCoverCrop}
        onCancel={closeCoverHandler}
        headerClass="text-center"
        containerStyle={{ width: "50%" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeCoverHandler}
            onSave={coverfileInputHandler}
            aspectRatio={16 / 9}
            objectFit="horizontal-cover"
            ratio="16:9"
          />
        </div>
      </Modal>

      {story.story_type === "" && (
        <div style={{ display: "flex" }}>
          <img
            src={addcover}
            alt="add-img"
            style={{ width: "50%", paddingRight: "20px" }}
            onClick={() => {
              videoPickerHandler(videoPickerRef.current);
            }}
          />
          <div style={{ flexGrow: "1" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!testStory ? (
                <>
                  <div
                    id="text_brief"
                    className="language-bio cursor-pointer"
                    style={{
                      overflow: "auto",
                    }}
                    onClick={() => {
                      setTextStory(true);
                    }}
                  >
                    {story.story_text ? (
                      parse(story.story_text)
                    ) : (
                      <span style={{ opacity: "0.5", height: "50px" }}>
                        Add Text Lesson
                      </span>
                    )}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTextStory(true);
                    }}
                  >
                    <BsPencil />
                  </div>
                </>
              ) : (
                <>
                  <RichText
                    data={editDescriptionValue}
                    setData={setEditDescriptionValue}
                    update={true}
                    updateText={updateDescription}
                  />
                  <AiOutlineClose
                    className="ml-1 cursor-pointer"
                    size={20}
                    onClick={() => {
                      setTextStory(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {story && story.story_type === "video" && (
        <>
          {!videoUrl && (
            <img
              src={addcover}
              alt="add-img"
              style={{ width: "100%" }}
              onClick={() => {
                videoPickerHandler(videoPickerRef.current);
              }}
            />
          )}

          {story.converted_url &&
            HLSVIDEOPLAYER &&
            story.video_encode_processed &&
            story.video_encode_process_status === "completed" && (
              <HlsPlayer
                url={story.converted_url}
                setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
              />
            )}

          {!story.video_encode_processed && videoUrl && (
            <div className="video-warapper-16-9-center">
              <video
                id="play_video"
                src={`${story.story_url}`}
                poster={story.thumbnail_url}
                className="video-16-9-center"
                controls
                ref={videoRef}
              ></video>
            </div>
          )}

          {!story.converted_url && story.video_encode_processed && videoUrl && (
            <div className="video-warapper-16-9-center">
              <video
                id="play_video"
                src={`${story.story_url}`}
                poster={story.thumbnail_url}
                className="video-16-9-center"
                controls
                ref={videoRef}
              ></video>
            </div>
          )}

          <div
            style={{
              textAlign: "right",
            }}
          >
            <BsThreeDotsVertical
              onClick={() => {
                menuBoxBioRef.current.style.display = "block";
              }}
              style={{ marginRight: -6 + "px" }}
            />
          </div>
          <div className="menu-box-bio display-none" ref={menuBoxBioRef}>
            <div className="menu-header">
              <AiOutlineClose
                className="cursor-pointer"
                onClick={closeBioMenuHander}
              />
            </div>
            <div
              className="menu-line cursor-pointer"
              onClick={openCoverHandler}
            >
              Select cover
            </div>
            <div
              className="menu-line cursor-pointer"
              onClick={() => {
                if (uploadPercentage === 100 || uploadPercentage === 0) {
                  if (!videoProcessingReducer[story._id]) {
                    videoPickerHandler(videoPickerRef.current);
                    menuBoxBioRef.current.style.display = "none";
                  } else {
                    alert("wait untile vbideo is processing");
                  }
                } else {
                  alert("until uplaod please wait");
                }
              }}
            >
              Replace the video
            </div>
          </div>
        </>
      )}

      <VideoUpload
        videoFilePickerRef={videoPickerRef}
        videourlref={videobioUpload}
        onvideofileInput={videofileInputHandler}
      />
      <video
        width="100%"
        controls
        ref={videobioUpload}
        style={{ display: "none" }}
      >
        Your browser does not support the video tag.
      </video>

      {story.story_type === "text" && (
        <>
          {description && (
            <div
              style={{
                marginTop: "24px",
                width: "100%",
              }}
            >
              {!editDescription ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    id="text_brief"
                    className="language-bio"
                    style={{ height: "520px", overflow: "auto", width: "95%" }}
                  >
                    {story.story_text ? (
                      parse(story.story_text)
                    ) : (
                      <span style={{ opacity: "0.5", height: "50px" }}>
                        Add Description
                      </span>
                    )}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditDescription(true);
                    }}
                  >
                    <BsPencil />
                  </div>
                </div>
              ) : (
                <RichText
                  data={editDescriptionValue}
                  setData={setEditDescriptionValue}
                  update={true}
                  updateText={updateDescription}
                />
              )}
            </div>
          )}
        </>
      )}

      {videoUploadingPercentageReducers[story._id] > 0 &&
        uploadPercentage > 0 &&
        uploadPercentage < 100 && (
          <div className="row mt-3">
            <div className="col pt-1">
              <div className="progress">
                <div
                  className="progress-bar bg-success progress-bar-striped"
                  role="progressbar"
                  style={{
                    width: `${videoUploadingPercentageReducers[story._id]}%`,
                    height: "100%",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {videoUploadingPercentageReducers[story._id]}%
                </div>
              </div>
            </div>
            <div className="col-auto">
              <span
                className="text-primary cursor-pointer"
                onClick={() => cancelUpload()}
              >
                <AiOutlineClose />
              </span>
            </div>
          </div>
        )}
      {videoProcessingReducer[story._id] && (
        <div className="loading mt-1 m-3">processing</div>
      )}
      <div
        style={{
          display: "flex",
          gap: "15px",
          marginTop: "5px",
          fontSize: "20px",
          color: "#6a6f73",
          fontWeight: "400",
          borderBottom: "1px solid #d1d7dc",
        }}
      >
        {story.story_type === "video" && (
          <div
            className={`${
              description ? "chapter-tabs-selected" : "chapter-tabs"
            }`}
            onClick={(e) => {
              selectTabs(e, "description");
            }}
            style={{
              paddingBottom: "15px",
            }}
          >
            Description{" "}
          </div>
        )}

        {story.story_type !== "" && (
          <>
            <div
              className={`${
                attachemnt ? "chapter-tabs-selected" : "chapter-tabs"
              }`}
              onClick={(e) => {
                selectTabs(e, "attachment");
              }}
              style={{ paddingBottom: "15px" }}
            >
              Attachments
            </div>
            {/* <div
              className="chapter-tabs"
              onClick={(e) => {
                selectTabs(e, "attachment");
              }}
              style={{ paddingBottom: "15px" }}
            >
              Notes
            </div> */}
          </>
        )}
      </div>

      {story.story_type === "video" && (
        <>
          {description && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "24px",
              }}
            >
              {!editDescription ? (
                <>
                  <div
                    id="text_brief"
                    className="language-bio"
                    style={{ height: "50px" }}
                  >
                    {story.story_text ? (
                      parse(story.story_text)
                    ) : (
                      <span style={{ opacity: "0.5", height: "50px" }}>
                        Add Description
                      </span>
                    )}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditDescription(true);
                    }}
                  >
                    <BsPencil />
                  </div>
                </>
              ) : (
                <RichText
                  data={editDescriptionValue}
                  setData={setEditDescriptionValue}
                  update={true}
                  updateText={updateDescription}
                />
              )}
            </div>
          )}
        </>
      )}
      {attachemnt && story && (
        <StoryAttacment story={story} group_info={group_info} />
      )}
    </>
  );
};

export default CourseStoryView;
