import React, { useState, useEffect } from "react";
import styles from "./LoginForm.module.css";
import { Link, useHistory } from "react-router-dom";

const LoginUser = ({ redirect, speacialMessage }) => {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const loginSubmit = async (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_BACKEND_URL;

    if (userName && password) {
      try {
        setError("");
        setLoading(true);

        const response = await fetch(`${url}/api/users/userLogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            login_user_id: userName,
            password: password,
            isKeepLogin: true, // Set isKeepLogin based on your needs
          }),
        });

        const responseData = await response.json();

        if (!response.ok) {
          const error = responseData.message.split(":")[1].trim();
          if (error === "password mismatch") {
            setError("Incorrect Password");
          } else if (error === "User not Exsited Try with other user_id") {
            setError("User not found");
          } else {
            throw new Error(responseData.message || "Something went wrong");
          }
        } else {
          if (responseData.body.alreadyLogin) {
            alert("Alredy logged in somewere still you can use here too");
          }

          if (typeof Storage !== "undefined") {
            localStorage.setItem(
              "userDataSessionWay",
              JSON.stringify(responseData.body),
            );

            if (!redirect) {
              history.push("/profile");
            } else {
              history.push(`/${redirect}`);
            }
          } else {
            alert("Please update your browser");
          }
        }
      } catch (err) {
        console.error(err);
        setError("Something went wrong. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter both username and password.");
    }
  };

  // Clear the error message after 3 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      {speacialMessage !== "" && (
        <div className={styles.spacialMessage}>{speacialMessage}</div>
      )}

      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.brand}>XpertRow</span>
          <Link to="/" className={styles.backToMain}>
            Back to Home
          </Link>
        </div>
        <form className={styles.form} onSubmit={loginSubmit}>
          <h2>Welcome Back</h2>
          {error && <p className={styles.errorMessage}>{error}</p>}
          <div className={styles.inputGroup}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.forgotPassword}>
            <Link to="user/forgot-password">Forgot Password?</Link>
          </div>
          <button
            type="submit"
            className={styles.signInButton}
            disabled={loading}
          >
            {loading ? "Loading..." : "Sign In"}
          </button>
          <div className={styles.createAccount}>
            <span>New to XpertRow?</span>{" "}
            <Link to="register">Create an account</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginUser;
