import React from "react";

import SideBarMenus from "./SideBarMenus";

const SideNavBar = ({ setMenu, setSectionType, sectionType }) => {
  const courseType = ["Sections", "Stories"];
  const courseDetails = ["Course Landing Page"];

  return (
    <>
      <SideBarMenus
        header={"Course Type"}
        sections={courseType}
        menu={1}
        setMenu={setMenu}
        setSectionType={setSectionType}
        sectionType={sectionType}
      />
      <SideBarMenus
        header={"Course Details"}
        sections={courseDetails}
        menu={2}
        setMenu={setMenu}
      />
    </>
  );
};

export default SideNavBar;
