import React from "react";
import AddMoreResponse from "./AddMoreResponse";
import { BsPencil, BsTrash, BsXLg } from "react-icons/bs";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import { useDispatch } from "react-redux";

const ResponseViewAndEdit = ({
  addReponse,
  setAddResponse,
  inputBoxvisible,
  setBoxVisible,
  responsehandler,
  responses,
  responseUpdateHandler,
  responseTitle,
  palceholder,
  group_info,
}) => {
  const dispatch = useDispatch();
  const editResponse = (response_id) => {
    let disp = document.getElementById(`${response_id}_edit`).style.display;
    if (disp === "block") {
      document.getElementById(`${response_id}_view`).style.display = "block";
      document.getElementById(`${response_id}_edit_button`).style.display =
        "block";
      document.getElementById(`${response_id}_edit`).style.display = "none";
    } else {
      document.getElementById(`${response_id}_view`).style.display = "none";
      document.getElementById(`${response_id}_edit_button`).style.display =
        "none";
      document.getElementById(`${response_id}_edit`).style.display = "block";
    }
  };

  const deleteRespons = async (response_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/deleteCourseLearnText`;
      const data = {
        course_id: group_info._id,
        user_id: group_info.user_id._id,
        response_id: response_id,
        responseTitle: responseTitle,
      };

      let res = await getDataPostWithHeader(url, data, group_info.token);

      dispatch({
        type: "group_details",
        payload: {
          ...res.data.body,
          user_id: group_info.user_id,
          token: group_info.token,
          category_id: group_info.category_id,
          currency_id: group_info.currency_id,
          language_id: group_info.language_id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <h4>{responseTitle}</h4>
      <div style={{ padding: "20px", border: "1px solid #d1d7dc" }}>
        {responses.length >= 0 &&
          responses.map((response, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "24px",
                }}
                key={index}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ display: "block" }} id={`${response._id}_view`}>
                    {response.your_response}
                  </div>
                  <div style={{ display: "none" }} id={`${response._id}_edit`}>
                    <input
                      type="text"
                      className="input_learn_text_field mr-3"
                      defaultValue={response.your_response}
                      id={`${response._id}_edit_text`}
                      style={{ width: "85%" }}
                      onBlur={(e) => {
                        responseUpdateHandler(
                          response._id,
                          response.your_response,
                          e,
                        );
                      }}
                    />
                    <BsTrash
                      size={24}
                      className="mr-3"
                      onClick={() => {
                        deleteRespons(response._id);
                      }}
                    />
                    <BsXLg
                      size={20}
                      onClick={() => editResponse(response._id)}
                    />
                  </div>
                </div>

                <div
                  id={`${response._id}_edit_button`}
                  onClick={() => editResponse(response._id)}
                >
                  <BsPencil />
                </div>
              </div>
            );
          })}
        <AddMoreResponse
          addReponse={addReponse}
          setAddResponse={setAddResponse}
          inputBoxvisible={inputBoxvisible}
          setBoxVisible={setBoxVisible}
          responsehandler={responsehandler}
          palceholder={palceholder}
        />
      </div>
    </div>
  );
};

export default ResponseViewAndEdit;
