import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import profileDefualt from "../../images/default.jpeg";
import readShare from "../../images/Read&ShareThumbnail.png";

const weburl = process.env.REACT_APP_BACKEND_URL;
const StoriesSearchCard = (props) => {
  useEffect(() => {}, []);
  return (
    <div className="p-3 serach-result" style={{ cursor: "pointer" }}>
      <div>
        <div className="row">
          <div className="col-md-5">
            <Link
              //to={`/consultant-profile?user_id=${props.searched_user._id}`}
              to={
                props.story.story_type === "video"
                  ? `/story/${props.story._id}`
                  : props.story.story_type === "stafs"
                  ? `/expert/${props.story._id}?type=stafs`
                  : props.story.story_type === "articles"
                  ? `/article/${props.story._id}`
                  : `/science-research/${props.story._id}`
              }
              style={{ color: "black" }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={props.story.thumbnail_url}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = readShare;
                  }}
                />
              </div>
            </Link>
          </div>
          <div className="col-md-7 text-center-mobile">
            <Link
              to={
                props.story.story_type == "video"
                  ? `/story/${props.story._id}`
                  : `/expert/${props.story._id}?type=stafs`
              }
              //to={`/consultant-profile?user_id=${props.searched_user._id}`}
              style={{ color: "black" }}
            >
              <div style={{ fontWeight: "700", fontSize: "20px" }}>
                {props.story.story_title}
              </div>
              <div
                style={{ color: "#606060", marginTop: "6px", fontSize: "12px" }}
              >
                {props.story.categories && props.story.categories.category_name}
              </div>
              <div
                style={{ color: "#606060", marginTop: "6px", fontSize: "12px" }}
              >
                {parse(props.story.story_text.substring(0, 100))}
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div style={{ clear: "both" }}></div>
    </div>
  );
};

export default StoriesSearchCard;
