import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";

import ErrorSection from "./ErrorSection";

import {
  selectIdValue,
  selectTextValue,
  selectValueValue,
} from "../../utils/sharedfunction";

const FirstSection = ({
  sectionHolder: {
    sheftSections,
    CurrentSection,
    NextSection,
    errorSection,
    progressBar,
  },
  userLangauges,
  briefCV,
  dispatch,
}) => {
  const languageRef = useRef(null);
  const refSelectedLang = useRef(null);
  const selectLangRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    var usrlang = navigator.languages;
    console.log(usrlang);
    const getLanguage = async () => {
      try {
        const url = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${url}/api/users/getLanguages`);
        const responseData = await response.json();
        let languageList = `<option selected="selected" value="0">Select Language You Speak</option>`;
        responseData.body.forEach((list) => {
          languageList += `<option id=${list._id} value="${list.code}">${list.nativeName}</option>`;
        });

        languageRef.current.innerHTML = languageList;

        if (!response.ok) {
          throw new Error(responseData.message || "something went wrong");
        } else {
          CurrentSection.current.style.pointerEvents = "auto";
          CurrentSection.current.style.opacity = "1";
          progressBar.current.style.display = "none";
          setLoading(true);
        }
      } catch (err) {
        CurrentSection.current.style.display = "none";
        errorSection.current.style.display = "block";
        progressBar.current.style.display = "none";
        console.log(err);
      }
    };
    if (!isLoading) {
      CurrentSection.current.style.pointerEvents = "None";
      CurrentSection.current.style.opacity = "0.7";
      progressBar.current.style.display = "block";
    }
    getLanguage();
  }, []);

  var selectedLanguage = userLangauges;
  var briefYou = briefCV;

  const langSelectHandler = (e) => {
    let id = selectIdValue(e.target) || 0;
    let text = selectTextValue(e.target);
    let value = selectValueValue(e.target);

    var obj = {};
    obj.id = id;
    obj.text = text;
    obj.value = value;

    if (obj.id !== 0) {
      const isLanguageCheck = selectedLanguage.findIndex((language) => {
        return language.id === obj.id;
      });

      if (isLanguageCheck === -1) {
        selectedLanguage.push(obj);
        briefYou.push({ language_id: obj.id, brief_cv: "Brief YourSelf" });
        refSelectedLang.current.innerHTML =
          refSelectedLang.current.innerHTML +
          `<li class = "ul-common" id="${obj.id}">${obj.text}</li>`;
        selectLangRef.current.style.display = "none";
      }
    }
  };

  const updateSection = () => {
    if (selectedLanguage.length > 0) {
      console.log(briefYou);
      dispatch({
        type: "addlanguage",
        payload: { langauge: selectedLanguage, briefCV: briefYou },
      });
      sheftSections(NextSection, CurrentSection);
    } else {
      console.log("please select at least One Language");
      selectLangRef.current.style.display = "block";
    }
  };

  return (
    <>
      {/* <marquee className='progress-bar' direction='right' scrollamount='30'>
        <div className='load-bar'>dsaddsadsadasdsadsa</div>
      </marquee> */}
      <div className="registration-model-container" ref={CurrentSection}>
        <div className="registration-modal-headers ">
          <h5 className="registration-modal-title">
            Select Language you speak
          </h5>
        </div>

        {<ul className="list" ref={refSelectedLang}></ul>}

        <span
          className="push-left display-none danger-red "
          ref={selectLangRef}
        >
          Select Atleast one Language
        </span>
        <div>
          <div className="margin-battom-15">
            <select
              className="form-control registration-from-control-border"
              ref={languageRef}
              onChange={langSelectHandler}
            ></select>
          </div>

          <div className="margin-battom-15">
            <button className="btn-proceed push-right " onClick={updateSection}>
              Next
            </button>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      <div ref={errorSection} style={{ display: "none" }}>
        <ErrorSection />
      </div>
    </>
  );
};

const MapStateToProps = ({ RegFirstSection: { userLangauges, briefCV } }) => {
  return { userLangauges, briefCV };
};

export default connect(MapStateToProps)(FirstSection);
