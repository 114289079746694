const initialState = {
  stories: {},
}

const StoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'sub_group_stories':
      return { ...state, stories: action.payload }
    case 'sub_group_stories_append':
      let newgetStoryModel = [...state.stories.getStoryModel, ...action.payload]
      let newState = { ...state }
      newState.stories.getStoryModel = newgetStoryModel
      return newState
    default:
      return state
  }
}

export default StoriesReducer
