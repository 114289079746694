import React, { useEffect, useRef, useState } from "react";
import axios, { CancelToken, isCancel } from "axios";
import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { ImCancelCircle } from "react-icons/im";
import { toast } from "react-toastify";
import { BsThreeDotsVertical, BsArrowLeft } from "react-icons/bs";
import { AiOutlinePlayCircle, AiOutlineClose } from "react-icons/ai";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";
import Button from "@material-ui/core/Button";
import addcoverorVideo from "../../images/icons/icon set-02.png";
import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";
import RichText from "../editProfile/RichText";
import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
import CatgeorySearchBox from "../Models/CatgeorySearchBox";
import VideoModel from "../VideoModel";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";
import MutiPartFileUpload from "../MutiPartFileUpload";
import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
} from "../../functions/editProfile/editProfile";
import {
  dataURLtoBlob,
  videoPickerHandler,
  responseMessageDispaly,
  setWithExpiry,
  getWithExpiry,
  calculateVideoRatio,
} from "../../utils/sharedfunction";
import youtube from "../../images/icons/youtube.png";
import defualtImg from "../../images/Read&ShareThumbnail.png";

const url = process.env.REACT_APP_BACKEND_URL;

const Profiles = () => {
  const { ProfileReducers, UserRequiredDropDown, BasicUserReducers } =
    useSelector((state) => state);
  const dispatch = useDispatch({});

  const { profiles } = ProfileReducers;
  const { languages } = UserRequiredDropDown;
  const { user } = BasicUserReducers;
  const [showCrop, setShowCrop] = useState(false);
  const [tags, setTags] = useState([]);
  const [ChannelName, setChannelName] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [videoprocessingUpload, setVideoProcessingUpload] = useState(false);
  const [ChannelDesc, setChannelDesc] = useState("");
  const [language, setLangauage] = useState("");
  const [profileId, setProfileId] = useState("");
  const [ChannelCategories, setChannelCategories] = useState([]);
  const [channelCategoriesName, setChannelCategoriesName] = useState([]);
  const [categories, setcategories] = useState([]);
  const [selectNewLangauge, setSelectNewLangauge] = useState(false);
  const [index, setIndex] = useState(0);
  const [thumbnail, setThumbnail] = useState("");
  const [profileVideo, setProfileVideo] = useState("");
  const [profileVideoHlsURL, setProfileVideoHLSURL] = useState("");
  const [uploadVideoUrl, setUplaodVideoUrl] = useState("");
  const [video_encode_processed, setvideoEncodeProcessed] = useState(false);
  const [video_encode_process_status, setVideoEncodeProcessStatus] =
    useState("");
  const [profileVideoMP4Version, setProfileVideoMp4Version] = useState({});
  const [showVideo, setshowVideo] = useState({
    isShowVideo: false,
    url: "",
    type: "",
  });
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [languagecd, setLanguagecd] = useState();
  const [newCategory, setNewCategory] = useState("");
  const bioImageRef = useRef(null);
  const tagErrorMessegesRef = useRef(null);
  const menuBoxBioRef = useRef(null);
  const videobioUpload = useRef(null);
  const cancelFileUpload = useRef(null);
  const videoPickerRef = useRef(null);
  const messageRef = useRef(null);
  const fileRef = useRef(null);
  const channelNameRef = useRef(null);
  const [selctedCourseHighlight, setSeletedCourseHighlight] = useState(null);
  const [viewMode, setViewMode] = useState(true);
  const [selectFile, setSelectFile] = useState(null);
  const [profileType, setProfileType] = useState("");
  const [profileTypeToUpdate, setProfileTypeToUpdate] = useState("");
  const [videoRatio, setVideoRatio] = useState("");

  const videoTempRef = useRef(null);
  useEffect(() => {
    if (profiles.length > 0) {
      if (profiles[0].profile_name === "") {
        setViewMode(false);
      }

      if (!profiles[0].categories[0]) {
        setViewMode(false);
      }

      setChannelName(profiles[0].profile_name);
      setChannelDesc(profiles[0].brief_cv);
      setLangauage(profiles[0].language_id._id);
      setProfileId(profiles[0]._id);
      setThumbnail(profiles[0].video_thumbnail);
      setProfileVideo(profiles[0].video_url);
      setProfileType(profiles[0].profile_type);
      setProfileVideoHLSURL(profiles[0].video_converted_url);
      setProfileVideoMp4Version(profiles[0].video_url_quality_versions);
      setvideoEncodeProcessed(profiles[0].video_encode_processed);
      setVideoEncodeProcessStatus(profiles[0].video_encode_process_status);
      setChannelCategories(profiles[0].categories.map((c) => c._id));
      setChannelCategoriesName(
        profiles[0].categories.map((c) => c.category_name),
      );

      if (profiles[0].categories.length > 0) {
        setNewCategory(profiles[0].categories[0].category_name);
      }
      setUplaodVideoUrl("");
      setTags(profiles[0].tags);
      setLanguagecd(profiles[0].language_id.code);
    }
  }, [profiles]);

  const categgoryBasedOnLangaugeHandler = async (value) => {
    try {
      setNewCategory(value);
      if (value !== "") {
        let categories = await categgoryBasedLangaugeBYID(value);
        setcategories(categories);
        if (categories.length === 0) {
          setShowAddCategory(true);
        }
      } else {
        setChannelCategories([]);
        setChannelCategoriesName([]);
      }
    } catch (err) {
      console.log(err);
      toast.error("failed to load category");
    }
  };

  const playVideo = (url, type) => {
    setshowVideo({ isShowVideo: true, url: url, type: type });
  };

  const closeProfileHandler = () => setShowCrop(false);
  const closeVideoPlayer = () => {
    setshowVideo({ isShowVideo: false, url: "", type: "" });
  };

  const openProfileHandler = () => {
    setSelectFile(null);
    setShowCrop(true);
    setProfileTypeToUpdate("VideoOrImage");
    menuBoxBioRef.current.style.display = "none";
  };

  const imagefileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    //bioImageRef.current.src = file;
    setThumbnail(file);
    var path = `${url}/api/users/updateBriefThumbnail`;
    var type = "image";
    uploadVideoHandler(type, path, imgeFile, file);
  };

  const videofileInputHandler = (file, imgeval) => {
    var imgeblob = "";
    var path = `${url}/api/users/updateBriefVideo`;
    var type = "video";

    console.log(file);
    uploadVideoHandler(type, path, imgeblob, imgeval, file);
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const uploadVideoHandler = async (
    type,
    path,
    Cover,
    base64img,
    videoFile,
  ) => {
    if (type === "video") {
      console.log("videoFile green", videoFile);
      let CoverPic = "";
      let coverName = "";
      let blobURL = URL.createObjectURL(videoFile);
      const { ratio } = await calculateVideoRatio(
        videoTempRef.current,
        blobURL,
      );
      console.log("ratio", ratio);
      setVideoRatio(ratio);

      setSelectFile(videoFile);

      // const dataVideo = {
      //   user_id: user.loginUser._id,
      //   keyname: `${user.loginUser._id}/userFiles/briefVideo`,
      //   filename: videoFile.name,
      //   fileType: videoFile.type,
      // };
      // setUploadPercentage(1);

      // getPresignedUrl(
      //   "getPresignedURLForVideUpload",
      //   dataVideo,
      //   videoFile,
      //   "video",
      //   `${url}/api/users/  `,
      //   ratio,
      // );

      // const dataImage = {
      //   user_id: user.loginUser._id,
      //   keyname: `${user.loginUser._id}/userFiles/briefThumbnail`,
      //   filename: coverName,
      //   fileType: CoverPic.type,
      // };
      // getPresignedUrl(
      //   "getPresignedURLForCoverUpload",
      //   dataImage,
      //   CoverPic,
      //   "image",
      //   `${url}/api/users/updateBriefThumbnail`,
      // );
    } else {
      setWithExpiry("coverPicBioArea", base64img, 100000);

      const dataImage = {
        user_id: user.loginUser._id,
        keyname: `${user.loginUser._id}/userFiles/briefThumbnail`,
        filename: "coverpic.png",
        fileType: Cover.type,
      };

      getPresignedUrl(
        "getPresignedURLForCoverUpload",
        dataImage,
        Cover,
        "image",
        path,
      );
    }
  };

  const getPresignedUrl = async (
    apiName,
    data,
    pickedFile,
    fileExttype,
    path,
    ratio,
  ) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, user.token);
      if (res.data.status === 200) {
        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
          path,
          ratio,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const uploadToS3 = (
    pickedFile,
    preSignedurl,
    apiName,
    fileUrl,
    path,
    ratio,
  ) => {
    if (apiName === "getPresignedURLForVideUpload") {
      setUploadPercentage(1);
    }
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        if (percent <= 100) {
          setUploadPercentage(percent + 1);
          console.log(percent);
          if (percent === 100) {
            setUploadPercentage(percent);

            if (apiName === "getPresignedURLForVideUpload")
              setVideoProcessingUpload(true);

            setSelectFile(null);
          }
        } else {
          setUploadPercentage(0);
          if (apiName === "getPresignedURLForVideUpload")
            setVideoProcessingUpload(true);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(profileId);
          if (apiName === "getPresignedURLForCoverUpload") {
            let id = getId(profileVideo);

            let data = {};
            if (id) {
              data = {
                path: fileUrl,
                user_id: user.loginUser._id,
                language_id: language,
                profile_type: "video_url",
              };
            } else {
              data = {
                path: fileUrl,
                user_id: user.loginUser._id,
                language_id: language,
                profile_type: "VideoOrImage",
              };
            }
            const resPorifle = await getDataPostWithHeader(
              path,
              data,
              user.token,
            );
            dispatch({
              type: "PROFILE",
              payload: resPorifle.data.body,
            });

            if (bioImageRef.current)
              bioImageRef.current.src =
                resPorifle.data.body[index].video_thumbnail;

            console.log(index);
            handleLanguage(resPorifle.data.body[index], index);
          } else {
            try {
              console.log(ratio, "videoRatio22");
              const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
              const data = {
                url: fileUrl,
                id: language,
                user_id: user.loginUser._id,
                whereToUpdate: "profiles",
                videoRatio: ratio,
              };
              const res = await getDataPostWithHeader(
                urlConvert,
                data,
                user.token,
              );
              console.log(res.data, "res datra");

              dispatch({
                type: "PROFILE",
                payload: res.data.body.updated,
              });

              if (bioImageRef.current)
                bioImageRef.current.src =
                  res.data.body.updated[index].video_thumbnail;
              videobioUpload.current.src =
                res.data.body.updated[index].video_url;
              handleLanguage(res.data.body.updated[index], index);
            } catch (err) {
              throw new Error("update error or suspended");
            }
            // const urlMp4Convert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaFourmGroupCoveret`;

            // const res1 = await getDataPostWithHeader(
            //   urlMp4Convert,
            //   data,
            //   user.token,
            // );
            // const data = {
            //   video_path: fileUrl,
            //   user_id: user.loginUser._id,
            //   language_id: language,
            // };

            // const resPorifle = await getDataPostWithHeader(
            //   path,
            //   data,
            //   user.token,
            // );
          }

          setUploadPercentage(0);
          setVideoProcessingUpload(false);
          setSelectFile(null);

          closeBioMenuHander();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (apiName === "getPresignedURLForVideUpload") {
          videobioUpload.current.src = "";

          console.log(err.message);

          if (err.message === "update error or suspended") {
            alert(err.message);
          } else {
            if (err.message !== "User has canceled the file upload") {
              alert(
                "While uploading something went wrong ! try again uploading",
              );
            }
          }
        }
        setUploadPercentage(0);
        setVideoProcessingUpload(false);
        // if (isCancel(err)) {
        //   alert(err.message);
        //   toast.error("aborted");
        // } else {
        //   toast.error("System Failed");
        // }
      });
  };

  const updateAfterUpload = async (fileURl) => {
    try {
      setVideoProcessingUpload(true);
      console.log(videoRatio, "videoRatio22");
      const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
      const data = {
        url: fileURl,
        id: language,
        user_id: user.loginUser._id,
        whereToUpdate: "profiles",
        videoRatio: videoRatio,
      };

      const res = await getDataPostWithHeader(urlConvert, data, user.token);
      console.log(res.data, "res datra");

      dispatch({
        type: "PROFILE",
        payload: res.data.body.updated,
      });

      if (bioImageRef.current)
        bioImageRef.current.src = res.data.body.updated[index].video_thumbnail;
      videobioUpload.current.src = res.data.body.updated[index].video_url;
      handleLanguage(res.data.body.updated[index], index);
    } catch (err) {
      throw new Error("update error or suspended");
    } finally {
      setSelectFile(null);
      setVideoProcessingUpload(false);
    }
  };
  const handleLanguage = (profile, i) => {
    setSelectNewLangauge(false);
    if (profile.profile_name === "") {
      setViewMode(false);
    }

    console.log("check", profile.profile_name, i);
    setIndex(i);
    setChannelName(profile.profile_name);
    setChannelDesc(profile.brief_cv);
    setLangauage(profile.language_id._id);
    setProfileId(profile._id);
    setThumbnail(profile.video_thumbnail);
    setProfileVideo(profile.video_url);
    setProfileType(profile.profile_type);
    setProfileVideoHLSURL(profile.video_converted_url);
    setvideoEncodeProcessed(profile.video_encode_processed);
    setVideoEncodeProcessStatus(profile.video_encode_process_status);
    setProfileVideoMp4Version(profile.video_url_quality_versions);
    if (profile.categories) {
      setChannelCategories(
        profile.categories.map((c) => {
          return c._id;
        }),
      );
      setChannelCategoriesName(
        profile.categories.map((c) => {
          return c.category_name;
        }),
      );
      if (profile.categories.length > 0) {
        setNewCategory(profile.categories[0].category_name);
      } else {
        setNewCategory("");
      }
    } else {
      setChannelCategories([]);
      setChannelCategoriesName([]);
    }
    setTags(profile.tags);

    document
      .querySelectorAll(".profile-bio-tab")
      .forEach((div) => (div.style.borderBottom = "none"));

    document.getElementById(`lang_list_profile_${i}`).style.borderBottom =
      "solid 1px #118acf";

    setLanguagecd(profile.language_id.code);
    setShowAddCategory(false);
  };

  const fileSelectHandler = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      let filExt = event.target.files[0].type.split("/")[0];
      setProfileTypeToUpdate("VideoOrImage");
      if (filExt === "image") {
        setSelectFile(event);
        setShowCrop(true);
      } else if (filExt === "video") {
        console.log(selectFile, "selectFile");

        let pickedFile = event.target.files[0];

        let imgeblob = "";
        let imgeval = "";
        var path = `${url}/api/users/updateBriefVideo`;
        var type = "video";
        uploadVideoHandler(type, path, imgeblob, imgeval, pickedFile);
        //console.log("its here");
      }
    }
  };
  const closeBioMenuHander = () => {
    menuBoxBioRef.current.style.display = "none";
  };

  const updateChanelLanguagehandler = async () => {
    let ChannelCategoriesNew = ChannelCategories;

    if (language === "") {
      responseMessageDispaly(messageRef, "Select language", "red");
      return;
    }

    if (newCategory !== "") {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

        const data = {
          category_name: newCategory,
        };

        const resCategory = await getDataPostWithOutHeader(url, data);
        if (resCategory.data.status === 200) {
          setShowAddCategory(false);

          setChannelCategories([resCategory.data.body._id]);

          ChannelCategoriesNew = [resCategory.data.body._id];
          setChannelCategoriesName([resCategory.data.body.category_name]);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    } else {
      alert("please enter category");
      return;
    }

    if (ChannelName !== "" || ChannelName !== null) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/updateBriefCv`;
      let data = {};
      if (ChannelDesc) {
        data = {
          user_id: user.loginUser._id,
          language_id: language,
          profile_name: ChannelName,
          brief_cv: ChannelDesc,
          tags: tags,
          categories: ChannelCategoriesNew,
          category_name: newCategory,
        };
      } else {
        data = {
          user_id: user.loginUser._id,
          language_id: language,
          profile_name: ChannelName,
          tags: tags,
          categories: ChannelCategoriesNew,
          category_name: newCategory,
        };
      }

      try {
        let res = await getDataPostWithHeader(url, data, user.token);
        dispatch({
          type: "PROFILE",
          payload: res.data.body,
        });
        setViewMode(true);
        responseMessageDispaly(messageRef, "Success", "green");

        const result = getDataPostWithHeader(
          `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/tagsForSearchKey`,
          { tags: [...tags, ...channelCategoriesName, ChannelName] },
          user.token,
        );

        handleLanguage(res.data.body[index], index);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };
  const selectLanguage = () => {
    setSelectNewLangauge(true);
    document
      .querySelectorAll(".profile-bio-tab")
      .forEach((div) => (div.style.borderBottom = "none"));
    setViewMode(false);
    setLangauage("");
    setChannelName("");
    setChannelDesc("");
    setThumbnail("");
    setProfileVideo("");
    setProfileType("");
    setProfileVideoMp4Version("");
    setProfileVideoHLSURL("");
  };

  const crteateProfile = async () => {
    if (language === "") {
      responseMessageDispaly(messageRef, "Select language", "red");
      return;
    }

    if (ChannelName === "") {
      responseMessageDispaly(messageRef, "Select language", "red");
      return;
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addBriefCv`;
    const data = {
      language_id: language,
      user_id: user.loginUser._id,
      profile_name: ChannelName,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.token);
      dispatch({
        type: "PROFILE",
        payload: res.data.body,
      });
      // const result = await getDataPostWithHeader(
      //   `${url}/api/userProfile/tagsForSearchKey`,
      //   { tags: [ChannelName] },
      //   user.token,
      // );
      setSelectNewLangauge(false);

      res.data.body.forEach((profile, i) => {
        if (profile.language_id._id === language) {
          handleLanguage(res.data.body[i], i);
        }
      });
    } catch (err) {
      console.log(err.message);
      responseMessageDispaly(messageRef, err.message, "red");
      return;
    }
  };

  const AddCategory = async () => {
    if (language === "0") {
      toast.error("Please Select Langauge");
      return;
    }

    if (!languagecd) {
      toast.error("Please select Langauge code");
      return;
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

      const data = {
        language_cd: languagecd,
        category_name: newCategory,
        language_id: language,
      };

      const res = await getDataPostWithOutHeader(url, data);
      if (res.data.status === 200) {
        setNewCategory("");
        setShowAddCategory(false);
        categgoryBasedOnLangaugeHandler(language);

        setChannelCategories([res.data.body._id]);
        setChannelCategoriesName([res.data.body.category_name]);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const handleCategory = (event) => {
    if (event.target.value !== "") {
      if (ChannelCategories.length < 25) {
        if (event.target.value === "00") {
          setShowAddCategory(true);
        } else {
          if (ChannelCategories !== "")
            if (!ChannelCategories.includes(event.target.value)) {
              setChannelCategories([event.target.value]);
              setChannelCategoriesName([
                event.target.options[event.target.selectedIndex].text,
              ]);
            }
        }
      }
    }
  };

  function matchYoutubeUrl(link) {
    let url = link.trim();
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      //setVideoUrl(url);
      youtubeUpdate(url);
      return;
    }
    //setVideoUrl("");
    alert("please enter valid youtube url");
    return false;
  }

  const youtubeUpdate = async (video_url) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/updateBriefVideo`;

    const data = {
      video_path: video_url,
      user_id: user.loginUser._id,
      language_id: language,
      profile_type: "video_url",
    };

    const res = await getDataPostWithHeader(url, data, user.token);

    setProfileTypeToUpdate("");

    dispatch({
      type: "PROFILE",
      payload: res.data.body,
    });

    handleLanguage(res.data.body[index], index);
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const selecetCategory = (cat_id, category_name) => {
    setNewCategory(category_name);
    setcategories([]);

    if (cat_id !== "") {
      if (ChannelCategories.length < 25) {
        if (cat_id === "00") {
          setShowAddCategory(true);
        } else {
          if (ChannelCategories !== "")
            if (!ChannelCategories.includes(cat_id)) {
              setChannelCategories([cat_id]);
              setChannelCategoriesName([category_name]);
            }
        }
      }
    }
  };

  return (
    <div className="row">
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ height: "550px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={16 / 9}
            objectFit="horizontal-cover"
            selectedFile={selectFile}
            ratio="16:9"
          />
        </div>
      </Modal>

      <video
        controls
        src=""
        ref={videoTempRef}
        style={{
          display: "none",
        }}
        className={`${videoRatio && "video-whereplayer-shows"}`}
      >
        Your browser does not support the video tag.
      </video>
      {profiles.length > 0 && (
        <>
          <div className="col-md-6 com-sm-12">
            <div className="border-bottom-white margin-top-5 d-md-block d-none d-sm-block">
              {profiles.map((list, i) => {
                if (i == 0) {
                  return (
                    <div
                      key={i}
                      id={`lang_list_profile_${i}`}
                      className="profile-bio-tab ml-2 language-select-border"
                      style={{
                        borderBottom: "solid 1px #118acf",
                      }}
                      onClick={() => handleLanguage(profiles[i], i)}
                    >
                      {list.language_id.nativeName}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={i}
                      id={`lang_list_profile_${i}`}
                      className="profile-bio-tab ml-2 language-select-border"
                      style={{
                        borderBottom: "none",
                      }}
                      onClick={() => handleLanguage(profiles[i], i)}
                    >
                      {list.language_id.nativeName}
                    </div>
                  );
                }
              })}

              <div
                style={{
                  marginLeft: "5px",
                  cursor: "pointer",
                  zIndex: "unset",
                }}
                className="tooltip"
                onClick={selectLanguage}
              >
                <span style={{ fontSize: "20px" }}>+</span>
                <span
                  style={{ fontSize: "10px" }}
                  //ref={createProfileRefMenu}
                  className="tooltiptext"
                >
                  Create New Language for Profile
                </span>
              </div>

              {selectNewLangauge && (
                <div style={{ float: "left", width: "200px" }}>
                  <select
                    onChange={(e) => {
                      setLangauage(e.target.value);
                      channelNameRef.current.focus();
                    }}
                    style={{
                      borderBottom: "solid 1px #118acf",
                      height: "30px",
                    }}
                    className="form-control registration-from-control-border"
                  >
                    <option value="0">Add Langauge</option>
                    {languages.length > 0 &&
                      languages.map((lang) => {
                        return (
                          <option key={lang._id} value={lang._id}>
                            {lang.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}

              <div style={{ float: "right" }}>
                {!viewMode && (
                  <>
                    {/* <button
                      onClick={updateChanelLanguagehandler}
                      className=" btn btn-success mr-3"
                      style={{ background: "#0C9FD4" }}
                    >
                      Update
                    </button> */}
                    <GrDocumentUpdate
                      size={20}
                      onClick={updateChanelLanguagehandler}
                      className="mr-3 cursor-pointer"
                    />
                    <AiOutlineClose
                      size={20}
                      onClick={() => {
                        if (
                          videoUploadPercentage > 0 ||
                          videoprocessingUpload
                        ) {
                          alert("wait until upload");
                          return;
                        }
                        setViewMode(true);
                        setSelectNewLangauge(false);
                        handleLanguage(profiles[index], index);
                      }}
                      className="cursor-pointer"
                    />
                  </>
                )}
                {viewMode && (
                  <MdOutlineEdit
                    className="mr-3"
                    size={20}
                    onClick={() => {
                      setViewMode(false);
                    }}
                  />
                )}
              </div>

              <div className="clearfix"></div>
            </div>
            {viewMode && (
              <div id="traing_list_area">
                <div className="border-bottom-white margin-top-5 d-block d-sm-none">
                  {profiles.map((list, i) => {
                    var id = i + "mobile";
                    if (i == 0) {
                      return (
                        <div
                          key={i}
                          id={`lang_list_profile_${i}mobile`}
                          className="profile-bio-tab ml-2 language-select-border"
                          style={{
                            borderBottom: "solid 1px #118acf",
                          }}
                          onClick={() => handleLanguage(profiles[i], id)}
                        >
                          {list.language_id.nativeName}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={i}
                          id={`lang_list_profile_${i}mobile`}
                          className="profile-bio-tab ml-2 language-select-border"
                          style={{
                            borderBottom: "none",
                          }}
                          onClick={() => handleLanguage(profiles[i], id)}
                        >
                          {list.language_id.nativeName}
                        </div>
                      );
                    }
                  })}

                  <div
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      zIndex: "unset",
                    }}
                    className="tooltip"
                    onClick={selectLanguage}
                  >
                    <span style={{ fontSize: "20px" }}>+</span>
                    <span
                      style={{ fontSize: "10px" }}
                      //ref={createProfileRefMenu}
                      className="tooltiptext"
                    >
                      Create New Language for Profile
                    </span>
                  </div>

                  {selectNewLangauge && (
                    <div>
                      <select
                        onChange={(e) => {
                          setLangauage(e.target.value);
                        }}
                        style={{
                          borderBottom: "solid 1px #118acf",
                          height: "30px",
                        }}
                        className="form-control registration-from-control-border"
                      >
                        <option value="0">Add Langauge</option>
                        {languages.length > 0 &&
                          languages.map((lang) => {
                            return (
                              <option key={lang._id} value={lang._id}>
                                {lang.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}

                  <div className="clearfix"></div>
                </div>

                {showVideo.isShowVideo && (
                  <div className="col-md-12">
                    <VideoModel
                      src={profileVideo}
                      onClose={closeVideoPlayer}
                      video_encode_process_status={video_encode_process_status}
                      video_encode_processed={video_encode_processed}
                      hlsUrl={profileVideoHlsURL}
                      videoQaulityVersion={profileVideoMP4Version}
                      type={profileType}
                    />
                  </div>
                )}

                {!showVideo.isShowVideo && (
                  <>
                    <div
                      id="thumbnail_div"
                      className="text-center mt-3"
                      style={{ position: "relative" }}
                    >
                      {profiles.length > 0 &&
                        profileType === "VideoOrImage" && (
                          <div className="flex-center">
                            {profiles.length > 0 &&
                              (profileVideo || thumbnail) && (
                                <div className="bio-video-width">
                                  {!profileVideo && (
                                    <img
                                      id="thumnail_bio"
                                      src={thumbnail}
                                      className="width-100 cursor-pointer"
                                      alt="thumbnail"
                                      ref={bioImageRef}
                                      onError={(e) => {
                                        e.target.onError = "ERROR";
                                        e.target.src = addcoverorVideo;
                                      }}
                                      onClick={(e) => {
                                        console.log(e.target.onError);
                                        if (profileVideo) {
                                          playVideo(profileVideo, profileType);
                                        }
                                        if ((e.target.onError = "ERROR")) {
                                          if (selectNewLangauge) {
                                            alert(
                                              "First please enter channel name",
                                            );
                                          }
                                          //fileRef.current.click();
                                        }
                                      }}
                                    />
                                  )}
                                  {profileVideo && (
                                    <VideoModel
                                      src={profileVideo}
                                      onClose={closeVideoPlayer}
                                      video_encode_process_status={
                                        video_encode_process_status
                                      }
                                      video_encode_processed={
                                        video_encode_processed
                                      }
                                      hlsUrl={profileVideoHlsURL}
                                      videoQaulityVersion={
                                        profileVideoMP4Version
                                      }
                                      type={profileType}
                                    />
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      {profiles.length > 0 && profileType === "video_url" && (
                        <>
                          <div
                            id="thumbnail_div"
                            className="text-center mt-3"
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="bio-video-width">
                              {profileVideo && (
                                <VideoModel
                                  src={profileVideo}
                                  onClose={closeVideoPlayer}
                                  hlsUrl={profileVideoHlsURL}
                                  video_encode_process_status={
                                    video_encode_process_status
                                  }
                                  video_encode_processed={
                                    video_encode_processed
                                  }
                                  videoQaulityVersion={profileVideoMP4Version}
                                  type={profileType}
                                />
                              )}
                            </div>

                            {!profileVideo && thumbnail && (
                              <img
                                id="thumnail_bio"
                                src={thumbnail}
                                className="width-100 cursor-pointer"
                                alt="thumbnail"
                                ref={bioImageRef}
                                onError={(e) => {
                                  e.target.onError = "ERROR";
                                  e.target.src = addcoverorVideo;
                                }}
                                onClick={(e) => {
                                  if (profileVideo) {
                                    playVideo(profileVideo, profileType);
                                  }
                                  if ((e.target.onError = "ERROR")) {
                                    if (selectNewLangauge) {
                                      alert("First please enter channel name");
                                    }
                                    //fileRef.current.click()
                                  }
                                }}
                              />
                            )}

                            {!profileVideo && !thumbnail && (
                              <img
                                className="width-100 cursor-pointer"
                                src={`https://img.youtube.com/vi/${getId(
                                  profileVideo,
                                )}/maxresdefault.jpg`}
                                alt="im-story"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = defualtImg;
                                }}
                                onClick={() => {
                                  playVideo(profileVideo, profileType);
                                }}
                              />
                            )}
                          </div>
                        </>
                      )}
                      {profiles.length > 0 &&
                        profileVideo == "" &&
                        thumbnail == "" && (
                          <img
                            id="thumnail_bio"
                            src={addcoverorVideo}
                            className="width-100 cursor-pointer"
                            ref={bioImageRef}
                            alt="thumbnail"
                            onClick={() => {
                              if (selectNewLangauge) {
                                alert("First please enter channel name");
                              } else {
                                fileRef.current.click();
                              }
                            }}
                          />
                        )}
                    </div>
                    {profileTypeToUpdate === "video_url" && (
                      <div className="mt-2 text-box">
                        <input
                          type="text"
                          id="Title"
                          name="Title"
                          placeholder="Enter Your Youtube Link"
                          className="story-title"
                          onChange={(e) => {
                            matchYoutubeUrl(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {/* <div 
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "5%",
                      }}>
                      {profiles.length > 0 &&
                      profileVideo == "" &&
                      thumbnail == "" ? (
                        <img
                          id="thumnail_bio"
                          src={youtube}
                          className="cursor-pointer"
                          alt="thumbnail"
                          style={{ height: "20px" }}
                          onClick={() => {
                            setProfileTypeToUpdate("video_url");
                          }}
                        />
                      ) : (
                        <BsThreeDotsVertical
                          onClick={() => {
                            menuBoxBioRef.current.style.display = "block";
                          }}
                          size={20}
                          style={{
                            marginRight: -4 + "px",
                            border: "1px solid",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                          className="menu-bio-mobile"
                        />
                      )}
                    </div> */}
                    <input
                      type="file"
                      accept="image/*,video/mp4,video/x-m4v,video/*"
                      ref={fileRef}
                      onChange={fileSelectHandler}
                      style={{ display: "none" }}
                    />
                    <div
                      className="menu-box-bio display-none"
                      ref={menuBoxBioRef}
                    >
                      <div className="menu-header">
                        <AiOutlineClose
                          className="cursor-pointer"
                          onClick={closeBioMenuHander}
                        />
                      </div>
                      <div
                        className="menu-line cursor-pointer"
                        onClick={openProfileHandler}
                      >
                        Select cover
                      </div>
                      <div
                        className="menu-line cursor-pointer"
                        onClick={() => {
                          setSelectFile(null);
                          setProfileTypeToUpdate("VideoOrImage");
                          videoPickerHandler(videoPickerRef.current);
                          menuBoxBioRef.current.style.display = "none";
                        }}
                      >
                        Replace the video
                      </div>
                      <div
                        className="menu-line cursor-pointer"
                        onClick={() => {
                          setSelectFile(null);
                          setProfileTypeToUpdate("video_url");
                          menuBoxBioRef.current.style.display = "none";
                        }}
                      >
                        Youtube Link
                      </div>
                      <VideoUpload
                        videoFilePickerRef={videoPickerRef}
                        videourlref={videobioUpload}
                        onvideofileInput={videofileInputHandler}
                      />
                    </div>
                  </>
                )}
                {selectFile && (
                  <MutiPartFileUpload
                    file={selectFile}
                    user={user}
                    setProgress={setVideoUploadPercentage}
                    progress={videoUploadPercentage}
                    setUplaodFileUrl={setUplaodVideoUrl}
                    keyString={`${user.loginUser._id}/userFiles/briefVideo`}
                    updateAfterUpload={updateAfterUpload}
                  />
                )}

                {!videoprocessingUpload && uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <div className="progress">
                        <div
                          className="progress-bar bg-success progress-bar-striped"
                          role="progressbar"
                          style={{
                            width: `${uploadPercentage}%`,
                            height: "100%",
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {uploadPercentage}%
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        <AiOutlineClose />
                      </span>
                    </div>
                  </div>
                )}
                {videoprocessingUpload && (
                  <div className="loading">Processing...</div>
                )}
                <video
                  width="100%"
                  controls
                  ref={videobioUpload}
                  style={{ display: "none" }}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            <div className="mb-4">
              <div>
                <span ref={messageRef}></span>

                {!viewMode && (
                  <>
                    <div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-control-focus"
                          placeholder="Channel Name"
                          style={{ background: "white" }}
                          value={ChannelName}
                          ref={channelNameRef}
                          onChange={(e) => setChannelName(e.target.value)}
                          onBlur={(e) => {
                            if (selectNewLangauge) crteateProfile();
                          }}
                        />
                      </div>
                      <div className="mt-2">
                        {ChannelCategories.length < 25 && (
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control mt-2 form-control-focus"
                              placeholder="Enter category"
                              value={newCategory}
                              onChange={(e) =>
                                categgoryBasedOnLangaugeHandler(e.target.value)
                              }

                              // onKeyPress={(event) => {
                              //   if (event.key === "Enter") {
                              //     if (event.target.value !== "") {
                              //       AddCategory();
                              //     } else {
                              //       alert("enter the new catgeory");
                              //     }
                              //   }
                              // }}
                              // onBlur={(e) => {
                              //   if (newCategory !== "") {
                              //     AddCategory();
                              //   }
                              // }}
                            />
                            {categories.length > 0 && (
                              <CatgeorySearchBox
                                categories={categories}
                                selecetCategory={selecetCategory}
                              />
                            )}
                          </div>
                        )}
                      </div>

                      <div className="mt-2">
                        <RichText data={ChannelDesc} setData={setChannelDesc} />
                      </div>

                      {/* {user.loginUser.accounttype !== "2" && (
                        <div className="mt-2">
                          <textarea
                            className={`${
                              languagecd === "ar" ? "text-right" : "text-left"
                            } story-desc-title text-area-desc`}
                            style={{ height: "140px" }}
                            placeholder="Description"
                            maxlength="5000"
                            value={
                              ChannelDesc ? parse(ChannelDesc) : ChannelDesc
                            }
                            onChange={(e) => {
                              setChannelDesc(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      )} */}
                    </div>
                    <div>
                      <div className="mt-2 text-box" style={{ height: "90%" }}>
                        <label for="Title" title="Title">
                          Tags:
                        </label>
                        <br />
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {tags.length > 0 &&
                            tags.map((tagName, index) => {
                              return (
                                <div key={index} className="m-1 tags">
                                  <div className="mr-1">{tagName}</div>
                                  <div
                                    className="tags-cancel-box cursor-pointer"
                                    onClick={() => {
                                      setTags(
                                        tags.filter((tag, i) => i !== index),
                                      );
                                    }}
                                  >
                                    <ImCancelCircle size={18} />
                                  </div>
                                </div>
                              );
                            })}

                          {tags.length < 25 && (
                            <input
                              type="text"
                              className="story-title text-area-desc"
                              placeholder="Enter coma after each tag"
                              maxlength="100"
                              style={{ width: "40%" }}
                              onKeyUp={(event) => {
                                let newTag = event.target.value.split(",")[0];
                                if (newTag.length < 100) {
                                  if (
                                    event.key === "Enter" ||
                                    event.key === ","
                                  ) {
                                    if (event.target.value !== "") {
                                      if (tags.length < 25) {
                                        if (newTag !== "")
                                          if (!tags.includes(newTag)) {
                                            setTags([...tags, newTag]);
                                          }

                                        event.target.value = "";
                                      }
                                    }
                                  }
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {viewMode && (
                  <>
                    <div className="bio-description-mobile">
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "1.3rem",
                          textAlign: "center",
                        }}
                      >
                        <span> {ChannelName}</span>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        {ChannelCategories.length > 0 &&
                          channelCategoriesName.map((category, index) => {
                            return (
                              <div key={index} className="m-1">
                                <div>
                                  {category}
                                  {index < ChannelCategories.length - 1 && (
                                    <span>,</span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div
                        className={`${
                          languagecd === "ar" ? "text-right" : "text-left"
                        } mt-2`}
                        style={{ color: "#606060" }}
                      >
                        {ChannelDesc ? parse(ChannelDesc) : ChannelDesc}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {!viewMode && (
            <div className="col-md-6 com-sm-12">
              <div id="traing_list_area">
                <div className="border-bottom-white margin-top-5 d-block d-sm-none">
                  {profiles.map((list, i) => {
                    var id = i + "mobile";
                    if (i == 0) {
                      return (
                        <div
                          key={i}
                          id={`lang_list_profile_${i}mobile`}
                          className="profile-bio-tab ml-2 language-select-border"
                          style={{
                            borderBottom: "solid 1px #118acf",
                          }}
                          onClick={() => handleLanguage(profiles[i], id)}
                        >
                          {list.language_id.nativeName}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={i}
                          id={`lang_list_profile_${i}mobile`}
                          className="profile-bio-tab ml-2 language-select-border"
                          style={{
                            borderBottom: "none",
                          }}
                          onClick={() => handleLanguage(profiles[i], id)}
                        >
                          {list.language_id.nativeName}
                        </div>
                      );
                    }
                  })}

                  <div
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      zIndex: "unset",
                    }}
                    className="tooltip"
                    onClick={selectLanguage}
                  >
                    <span style={{ fontSize: "20px" }}>+</span>
                    <span
                      style={{ fontSize: "10px" }}
                      //ref={createProfileRefMenu}
                      className="tooltiptext"
                    >
                      Create New Language for Profile
                    </span>
                  </div>

                  {selectNewLangauge && (
                    <div>
                      <select
                        onChange={(e) => {
                          setLangauage(e.target.value);
                        }}
                        style={{
                          borderBottom: "solid 1px #118acf",
                          height: "30px",
                        }}
                        className="form-control registration-from-control-border"
                      >
                        <option value="0">Add Langauge</option>
                        {languages.length > 0 &&
                          languages.map((lang) => {
                            return (
                              <option key={lang._id} value={lang._id}>
                                {lang.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}

                  <div className="clearfix"></div>
                </div>
                {!showVideo.isShowVideo && (
                  <>
                    <div
                      id="thumbnail_div"
                      className="text-center mt-3"
                      style={{ position: "relative" }}
                    >
                      {profiles.length > 0 &&
                        profileType === "VideoOrImage" && (
                          <div className="flex-center">
                            {profiles.length > 0 &&
                              (profileVideo || thumbnail) && (
                                <div className="bio-video-width">
                                  {!profileVideo && (
                                    <img
                                      id="thumnail_bio"
                                      src={thumbnail}
                                      className="width-100 cursor-pointer"
                                      alt="thumbnail"
                                      ref={bioImageRef}
                                      onError={(e) => {
                                        e.target.onError = "ERROR";
                                        e.target.src = addcoverorVideo;
                                      }}
                                      onClick={(e) => {
                                        console.log(e.target.onError);
                                        if (profileVideo) {
                                          playVideo(profileVideo, profileType);
                                        }
                                        if ((e.target.onError = "ERROR")) {
                                          if (selectNewLangauge) {
                                            alert(
                                              "First please enter channel name",
                                            );
                                          }
                                          //fileRef.current.click()
                                        }
                                      }}
                                    />
                                  )}
                                  {profileVideo && (
                                    <VideoModel
                                      src={profileVideo}
                                      onClose={closeVideoPlayer}
                                      hlsUrl={profileVideoHlsURL}
                                      video_encode_process_status={
                                        video_encode_process_status
                                      }
                                      video_encode_processed={
                                        video_encode_processed
                                      }
                                      videoQaulityVersion={
                                        profileVideoMP4Version
                                      }
                                      type={profileType}
                                    />
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      {profiles.length > 0 && profileType === "video_url" && (
                        <>
                          <div
                            id="thumbnail_div"
                            className="text-center mt-3"
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="bio-video-width">
                              {profileVideo && (
                                <VideoModel
                                  src={profileVideo}
                                  onClose={closeVideoPlayer}
                                  hlsUrl={profileVideoHlsURL}
                                  video_encode_process_status={
                                    video_encode_process_status
                                  }
                                  video_encode_processed={
                                    video_encode_processed
                                  }
                                  videoQaulityVersion={profileVideoMP4Version}
                                  type={profileType}
                                />
                              )}
                            </div>

                            {!profileVideo && thumbnail && (
                              <img
                                id="thumnail_bio"
                                src={thumbnail}
                                className="width-100 cursor-pointer"
                                alt="thumbnail"
                                ref={bioImageRef}
                                onError={(e) => {
                                  e.target.onError = "ERROR";
                                  e.target.src = addcoverorVideo;
                                }}
                                onClick={(e) => {
                                  if (profileVideo) {
                                    playVideo(profileVideo, profileType);
                                  }
                                  if ((e.target.onError = "ERROR")) {
                                    if (selectNewLangauge) {
                                      alert("First please enter channel name");
                                    }
                                    //fileRef.current.click()
                                  }
                                }}
                              />
                            )}

                            {!profileVideo && !thumbnail && (
                              <img
                                className="width-100 cursor-pointer"
                                src={`https://img.youtube.com/vi/${getId(
                                  profileVideo,
                                )}/maxresdefault.jpg`}
                                alt="im-story"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = defualtImg;
                                }}
                                onClick={() => {
                                  playVideo(profileVideo, profileType);
                                }}
                              />
                            )}
                          </div>
                        </>
                      )}
                      {profiles.length > 0 &&
                        profileVideo == "" &&
                        thumbnail == "" && (
                          <img
                            id="thumnail_bio"
                            src={addcoverorVideo}
                            className="width-100 cursor-pointer"
                            ref={bioImageRef}
                            alt="thumbnail"
                            onClick={() => {
                              if (selectNewLangauge) {
                                alert("First please enter channel name");
                              } else {
                                fileRef.current.click();
                              }
                            }}
                          />
                        )}
                    </div>
                    {profileTypeToUpdate === "video_url" && (
                      <div className="mt-2 text-box">
                        <input
                          type="text"
                          id="Title"
                          name="Title"
                          placeholder="Enter Your Youtube Link"
                          className="story-title"
                          onChange={(e) => {
                            matchYoutubeUrl(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "5%",
                      }}
                    >
                      {profiles.length > 0 &&
                      profileVideo == "" &&
                      thumbnail == "" ? (
                        <img
                          id="thumnail_bio"
                          src={youtube}
                          className="cursor-pointer"
                          alt="thumbnail"
                          style={{ height: "20px" }}
                          onClick={() => {
                            setProfileTypeToUpdate("video_url");
                          }}
                        />
                      ) : (
                        <BsThreeDotsVertical
                          onClick={() => {
                            menuBoxBioRef.current.style.display = "block";
                          }}
                          size={20}
                          style={{
                            marginRight: -4 + "px",
                            border: "1px solid",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                          className="menu-bio-mobile"
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*,video/mp4,video/x-m4v,video/*"
                      ref={fileRef}
                      onChange={fileSelectHandler}
                      style={{ display: "none" }}
                    />
                    <div
                      className="menu-box-bio display-none"
                      ref={menuBoxBioRef}
                    >
                      <div className="menu-header">
                        <AiOutlineClose
                          className="cursor-pointer"
                          onClick={closeBioMenuHander}
                        />
                      </div>
                      <div
                        className="menu-line cursor-pointer"
                        onClick={openProfileHandler}
                      >
                        Select cover
                      </div>
                      <div
                        className="menu-line cursor-pointer"
                        onClick={() => {
                          setSelectFile(null);
                          setProfileTypeToUpdate("VideoOrImage");
                          videoPickerHandler(videoPickerRef.current);
                          menuBoxBioRef.current.style.display = "none";
                        }}
                      >
                        Replace the video
                      </div>
                      <VideoUpload
                        videoFilePickerRef={videoPickerRef}
                        videourlref={videobioUpload}
                        onvideofileInput={videofileInputHandler}
                      />
                    </div>
                  </>
                )}

                {selectFile && (
                  <MutiPartFileUpload
                    file={selectFile}
                    user={user}
                    setProgress={setVideoUploadPercentage}
                    progress={videoUploadPercentage}
                    setUplaodFileUrl={setUplaodVideoUrl}
                    keyString={`${user.loginUser._id}/userFiles/briefVideo`}
                    updateAfterUpload={updateAfterUpload}
                  />
                )}
                {!videoprocessingUpload && uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <div className="progress">
                        <div
                          className="progress-bar bg-success progress-bar-striped"
                          role="progressbar"
                          style={{
                            width: `${uploadPercentage}%`,
                            height: "100%",
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {uploadPercentage}%
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        <AiOutlineClose />
                      </span>
                    </div>
                  </div>
                )}
                {videoprocessingUpload && (
                  <div className="loading">Processing...</div>
                )}
                <video
                  width="100%"
                  controls
                  ref={videobioUpload}
                  style={{ display: "none" }}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profiles;
