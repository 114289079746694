import React, { useState, useRef } from 'react'
import { GoPencil } from 'react-icons/go'
import { AiOutlineClose } from 'react-icons/ai'
import { Spin } from 'antd'
import Button from '@material-ui/core/Button'
import { editPersonalDetails } from '../../../functions/editProfile/editProfile'
import { responseMessageDispaly } from '../../../utils/sharedfunction'

const EditBankSection = (props) => {
  const [name, setName] = useState(props.user.userBank.bank_name)
  const [loading, setLoading] = useState(false)
  const [accountNumber, setAccountNumber] = useState(
    props.user.userBank.account_number
  )
  const [IBAN, setIBAN] = useState(props.user.userBank.iban_code)
  const [swiftCode, setSwiftCode] = useState(props.user.userBank.swift_code)
  const [address, setAddress] = useState(props.user.userBank.bank_address)

  const [showEdit, setShowEdit] = useState(false)
  const uploadMSG = useRef(null)

  const handleSubmit = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserBankDetails`
    const data = {
      user_id: props.user._id,
      userBank: {
        bank_name: name,
        account_number: accountNumber,
        iban_code: IBAN,
        swift_code: swiftCode,
        bank_address: address,
      },
    }

    try {
      let res = await editPersonalDetails(url, data, props.token)
      setLoading(false)
      responseMessageDispaly(
        uploadMSG,
        'Bank details has updated successfully',
        'green'
      )
      console.log(res)
      setShowEdit(false)
    } catch (err) {
      setLoading(false)
      responseMessageDispaly(uploadMSG, 'Something went wrong', 'red')
      console.log(err.message)
    }
  }

  return (
    <>
      <div className='edit-content-widget'>
        <div className='ui-tabs-title'>
          <h4 className='float-left'>Bank Detail</h4>
          {loading && (
            <>
              <Spin className='ml-3' />
              <span className='text-danger'>....</span>
            </>
          )}
          {showEdit || (
            <GoPencil
              className='float-right mt-2'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEdit(true)
              }}
            />
          )}
          {showEdit && (
            <AiOutlineClose
              className='float-right mt-2'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEdit(false)
              }}
            />
          )}
          <div style={{ clear: 'both' }}></div>
        </div>

        {showEdit || (
          <div className='tab-inner-content-padding'>
            <table id='edit-bank-section'>
              <tbody>
                <tr>
                  <td className='font-bold padding-5'>Bank Name</td>
                  <td className='padding-5' id='group_name_view'>
                    {name}
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5'>Account No</td>
                  <td className='padding-5' id='group_name_view'>
                    {accountNumber}
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5'>IBAN Code</td>
                  <td className='padding-5' id='group_name_view'>
                    {IBAN}
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5'>Swift Code</td>
                  <td className='padding-5' id='group_name_view'>
                    {swiftCode}
                  </td>
                </tr>
                <tr>
                  <td className='padding-5 font-bold'>Bank Address</td>
                  <td className='padding-5' id='langauge_view'>
                    {address}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {showEdit && (
          <div className='tab-inner-content-padding'>
            <table id='edit-bank-section' style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className='font-bold padding-5' style={{ width: '10%' }}>
                    Bank Name
                  </td>
                  <td>
                    <input
                      placeholder=''
                      className='form-control input-md registration-from-control-border'
                      type='text'
                      value={name}
                      autoFocus
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5' style={{ width: '10%' }}>
                    Account No
                  </td>
                  <td>
                    <input
                      placeholder=''
                      className='form-control input-md registration-from-control-border'
                      type='text'
                      value={accountNumber}
                      onChange={(e) => {
                        setAccountNumber(e.target.value)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5' style={{ width: '10%' }}>
                    IBAN Code
                  </td>

                  <td>
                    <input
                      placeholder=''
                      className='form-control input-md registration-from-control-border'
                      type='text'
                      value={IBAN}
                      onChange={(e) => {
                        setIBAN(e.target.value)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='font-bold padding-5' style={{ width: '10%' }}>
                    SWIFT Code
                  </td>

                  <td>
                    <input
                      placeholder=''
                      className='form-control input-md registration-from-control-border'
                      type='text'
                      value={swiftCode}
                      onChange={(e) => {
                        setSwiftCode(e.target.value)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='padding-5 font-bold' style={{ width: '10%' }}>
                    Bank Address
                  </td>
                  <td>
                    <input
                      placeholder=''
                      className='form-control input-md registration-from-control-border'
                      type='text'
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value)
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='col-sm-12 p-2'>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                className='float-right'
                onClick={() => {
                  handleSubmit()
                }}
              >
                update
              </Button>
            </div>
          </div>
        )}
        <div ref={uploadMSG}></div>
      </div>
    </>
  )
}

export default EditBankSection
