import React, { useState, useRef } from "react";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { editPersonalDetails } from "../../../functions/editProfile/editProfile";
import ModelPhone from "../../../utils/models/DefaultModel";
import { BsCheckLg } from "react-icons/bs";
import { responseMessageDispaly } from "../../../utils/sharedfunction";
const EditAccountTypeSection = (props) => {
  const [consultant, setConsultant] = useState(props.user.is_consultant);
  const [accounttype, setAccounttype] = useState(props.user.accounttype);
  const [oldAccountType, setOldAccountType] = useState(props.user.accounttype);
  const [provideTraining, setProvideTraining] = useState(
    props.user.provide_training,
  );
  const [acceptEnquiers, setAcceptEnquiers] = useState(
    props.user.accept_enquiers,
  );
  const [showEdit, setShowEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadMSG = useRef(null);

  const handleSubmit = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserChannelSettings`;
    const data = {
      consultant: consultant,
      accounttype: accounttype,
      user_id: props.user._id,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      props.userDetails();
      setShowEdit(false);
      setLoading(false);
      responseMessageDispaly(
        uploadMSG,
        "account type details has updated successfully",
        "green",
      );
    } catch (err) {
      console.log(err.message);
      responseMessageDispaly(uploadMSG, "Some thing went wrong", "red");
      setLoading(false);
      //toast.error(err.message);
    }
  };
  return (
    <>
      <div className="edit-content-widget">
        <div className="ui-tabs-title">
          <h4 className="float-left">Channel Settings</h4>
          {loading && (
            <>
              <Spin className="ml-3" />
              <span className="text-danger">....</span>
            </>
          )}

          {showEdit || (
            <GoPencil
              className="float-right mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEdit(true);
              }}
            />
          )}
          {showEdit && (
            <AiOutlineClose
              className="float-right mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEdit(false);
              }}
            />
          )}
          <div style={{ clear: "both" }}></div>
        </div>

        {showEdit || (
          <div className=" tab-inner-content-padding">
            <table id="edit-profile-section">
              <tbody>
                <tr>
                  <td className="font-bold padding-5" style={{ width: "15%" }}>
                    {consultant == "1" && (
                      <BsCheckLg
                        style={{ color: "#10c72b", fontWeight: "bold" }}
                      />
                    )}
                  </td>
                  <td>I Provide Consultantations</td>
                </tr>
                {/* <tr>
                  <td className="font-bold padding-5" style={{ width: "15%" }}>
                    {(accounttype == "1" || accounttype == "2") && (
                      <BsCheckLg
                        style={{ color: "#10c72b", fontWeight: "bold" }}
                      />
                    )}
                  </td>
                  <td>
                    {accounttype == "1" && <>Indivdual</>}
                    {accounttype == "2" && <>Organization</>}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        )}
        {showEdit && (
          <>
            {loading && <Spin />}
            <div className=" tab-inner-content-padding">
              <table id="edit-profile-section" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td
                      className="font-bold padding-5"
                      style={{ width: "10%" }}
                    >
                      <input
                        className="form-control input-md registration-from-control-border"
                        type="checkbox"
                        autoFocus
                        checked={consultant == "1" ? true : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setConsultant("1");
                          } else {
                            setConsultant("0");
                          }
                        }}
                      />
                    </td>
                    <td>I Provide Consultantations</td>
                  </tr>
                  {/* 
                  <tr>
                    <td
                      className="font-bold padding-5"
                      style={{ width: "10%" }}
                    >
                      <input
                        className="form-control input-md registration-from-control-border"
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (accounttype === "1") {
                              setAccounttype("2");
                            } else {
                              setAccounttype("1");
                            }
                          } else {
                            setAccounttype(oldAccountType);
                          }
                        }}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      {oldAccountType === "1" ? (
                        <span>Convert it to Organization</span>
                      ) : (
                        <span>Convert it to an Individual</span>
                      )}
                    </td>
                  </tr> */}
                </tbody>
              </table>
              <div className="col-sm-12 p-2">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="float-right"
                  onClick={handleSubmit}
                >
                  update
                </Button>
              </div>
            </div>
          </>
        )}
        <div ref={uploadMSG}></div>
      </div>
    </>
  );
};

export default EditAccountTypeSection;
