import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios, { CancelToken, isCancel } from "axios";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import {
  AiOutlineUpload,
  AiOutlineClose,
  AiOutlineAudio,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import Model from "../../utils/models/ModelStory";
import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
} from "../../functions/editProfile/editProfile";

import defualtImg from "../../images/Read&ShareThumbnail.png";
import ReactPlayer from "react-player";
import defaultThumbmail from "../../images/Read&ShareThumbnail.png";
import addcoverorVideo from "../../images/icons/icon set-01.png";
import mic from "../../images/icons/mic.png";
import camera from "../../images/icons/camera.png";
import youtube from "../../images/icons/youtube.png";
import Modal from "../../utils/models/Modal";
import useRecorder from "../../utils/hooks/useRecorder";
import RichText from "../editProfile/RichText";
import ImageCrop from "../ImageCrop";
import "./addStoryModel.css";

import {
  responseMessageDispaly,
  dataURLtoBlob,
  calculateVideoRatio,
} from "../../utils/sharedfunction";
import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";

import CatgeorySearchBox from "./CatgeorySearchBox";
import MutiPartFileUpload from "../MutiPartFileUpload";

const AddStoryModel = ({
  user,
  onModelClose,
  showAddStoryModel,
  menuType,
  stafType,
  setCVAddedAlready,
}) => {
  let [audioURL, isRecording, startRecording, stopRecording, recorder] =
    useRecorder();

  const dispatch = useDispatch();
  const [storyType, setStoryType] = useState("");
  const [storyTitle, setStoryTitle] = useState("");
  const [storyDesc, setStoryDesc] = useState("");
  const [language, setLanguage] = useState("0");
  const [languagecd, setLanguagecd] = useState();
  const [profileId, setProfileId] = useState("");
  const [category, setCategory] = useState("0");
  const [newCategory, setNewCategory] = useState("");
  const [storyID, setStoryID] = useState("");
  const [categories, setcategories] = useState([]);
  const [showCrop, setShowCrop] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [videoprocessingUpload, setVideoProcessingUpload] = useState(false);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [imageprocessingUpload, setImageProcessingUpload] = useState(false);
  const [videoHeight, setVideoHeight] = useState("");
  const [videoWidth, setVideoWidth] = useState("");
  const [videoRatio, setVideoRatio] = useState("");
  const [videUrl, setVideoUrl] = useState("");
  const [showvideoUrlLink, setShowvideoUrlLink] = useState(true);
  const [coverUrl, setCoverUrl] = useState("");
  const [coverUpload, setCoverUpload] = useState("");
  const [cover1, setCover1] = useState("");
  const [cover2, setCover2] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [uplaodVideoUrl, setUplaodVideoUrl] = useState("");
  const [uploadedCoverImage, setUploadedCoverImage] = useState({
    url: "",
    blob: "",
    fileName: "",
  });
  const [screenShot1, setScreenShot1] = useState({
    url: defaultThumbmail,
    blob: "",
    fileName: "",
  });
  const [screenShot2, setScreenShot2] = useState({
    url: defaultThumbmail,
    blob: "",
    fileName: "",
  });
  const [selectFile, setSelectFile] = useState(null);
  const [selctedVideoFile, setSelctedVideoFile] = useState(null);
  const [imageSelectType, setImageSelectType] = useState("");
  const [imagePriceType, setImagePriceType] = useState("");
  const [imagePrice, setImagePrice] = useState(0);
  const [currency, setCurrency] = useState("0");

  const [isFromMobile, setIsFromMobile] = useState(true);
  const errormesseageRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const fileResiderRef = useRef(null);
  const fileResiderRef1 = useRef(null);
  const audioResiderRef = useRef(null);
  const videoPickerRef = useRef(null);
  const videoTempRef = useRef(null);
  const videoTempRef2 = useRef(null);
  const videoTempRef3 = useRef(null);
  const audioPickerRef = useRef(null);
  const langaugeRef = useRef(null);
  const categoryRef = useRef(null);
  const tagErrorMessegesRef = useRef(null);
  const fileRef = useRef(null);
  const menuBoxBioRef = useRef(null);

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  const { UserRequiredDropDown, ProfileReducers, FileUploadPercentage } =
    useSelector((state) => state);

  const { profiles } = ProfileReducers;

  useEffect(() => {
    setCoverUrl("");
    setVideoUrl("");
    setStoryType("");
    setCoverUpload("");
    setCover1("");
    setCover2("");
    setcategories([]);
    setTags([]);
    setStoryID("");
    setShowAddCategory(false);
    setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
    setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
    setUploadedCoverImage({ url: "", blob: "", fileName: "" });
    setSelctedVideoFile(null);
    if (profiles.length > 0) {
      setLanguagecd(profiles[0].language_id.code);
      setProfileId(profiles[0]._id);
      setLanguage(profiles[0].language_id._id);
    }
  }, [profiles, showAddStoryModel]);

  useEffect(() => {
    if (showAddStoryModel) fileRef.current.click();
  }, [showAddStoryModel]);

  const url = process.env.REACT_APP_BACKEND_URL;

  function matchYoutubeUrl(link) {
    let url = link.trim();
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      setVideoUrl(url);
      setShowvideoUrlLink(false);
      return;
    }
    setVideoUrl("");
    setShowvideoUrlLink(true);
    alert("please enter valid youtube url");
    return false;
  }

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const categgoryBasedOnLangaugeHandler = async (value) => {
    try {
      setNewCategory(value);
      if (value !== "") {
        let categories = await categgoryBasedLangaugeBYID(value);
        setcategories(categories);
        if (categories.length === 0) {
          setShowAddCategory(true);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("failed to load category");
    }
  };

  const selecetCategory = (cat_id, category_name) => {
    setCategory(cat_id);
    setNewCategory(category_name);
    setcategories([]);
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
    setVideoUploadPercentage(0);
    dispatch({
      type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
      payload: {
        videoUploadPercentage: 0,
        videoprocessingUpload: false,
        isVideoUploading: "NO",
      },
    });
    setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
    setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
    setCoverUpload("");
    setCover1("");
    setCover2("");
    videoTempRef.current.src = "";
    videoTempRef2.current.src = "";
    videoTempRef3.current.src = "";
    setUplaodVideoUrl("");
  };

  const openProfileHandler = () => {
    setShowCrop(true);
  };
  const closeProfileHandler = () => setShowCrop(false);

  const fileSelectHandler = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      let filExt = event.target.files[0].type.split("/")[0];

      if (menuType === "STAFS") {
        if (filExt === "image") {
          setSelectFile(event);
          if (menuType === "STAFS") {
            setStoryType("stafs");
          } else {
            setStoryType("text");
          }
          videoTempRef.current.style.display = "none";
          setShowCrop(true);
        } else {
          alert("Select only image");
        }
      } else {
        if (filExt === "image") {
          alert("Select video only");
          return;
        } else if (filExt === "video") {
          filePickedHandler(event);
          setSelectFile("");
          setStoryType("video");
          videoTempRef.current.style.display = "block";
        } else {
          alert("Select video or image");
        }
      }
    }
  };

  const getPresignedUrl = async (
    apiName,
    data,
    pickedFile,
    fileExttype,
    ratio
  ) => {
    try {
      console.log(apiName, "apiName");
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, user.token);
      if (res.data.status === 200) {
        console.log(res.data.body, "expert");
        if (fileExttype === "cover") {
          setCoverUrl(res.data.body.fileUrl);
          setCoverUpload(res.data.body.fileUrl);
        } else if (fileExttype === "video") {
          setUplaodVideoUrl(res.data.body.fileUrl);
          setStoryID(res.data.body.storyModel._id);
        } else if (fileExttype === "cover1") {
          setCoverUrl(res.data.body.fileUrl);
          setCover1(res.data.body.fileUrl);
        } else if (fileExttype === "cover2") {
          setCover2(res.data.body.fileUrl);
        } else {
          setAudioUrl(res.data.body.fileUrl);
        }

        if (fileExttype === "video") {
          uploadToS3(
            pickedFile,
            res.data.body.preSignedurl,
            apiName,
            res.data.body.fileUrl,
            res.data.body.storyModel._id,
            ratio
          );
        } else {
          uploadToS3(
            pickedFile,
            res.data.body.preSignedurl,
            apiName,
            res.data.body.fileUrl
          );
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const filePickedHandler = async (event) => {
    let pickedFile = event.target.files[0];
    let fileExttype = event.target.files[0].type.split("/")[0];

    if (fileExttype === "video") {
      let blobURL = URL.createObjectURL(pickedFile);
      try {
        const { ratio, width, height } = await calculateVideoRatio(
          videoTempRef.current,
          blobURL
        );
        setVideoRatio(ratio);
        setVideoWidth(width);
        setVideoHeight(height);

        videoTempRef2.current.src = blobURL;
        videoTempRef3.current.src = blobURL;

        setSelctedVideoFile(pickedFile);

        // setVideoUploadPercentage(1);
        // dispatch({
        //   type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
        //   payload: {
        //     videoUploadPercentage: 1,
        //     videoprocessingUpload: false,
        //     isVideoUploading: "YES",
        //   },
        // });
        // screenShotCreater();
        // const data = {
        //   user_id: user.loginUser._id,
        //   keyname: "userFiles/storyFiles",
        //   filename: pickedFile.name,
        //   fileType: pickedFile.type,
        // };

        // getPresignedUrl(
        //   "getPresignedURLForFileUpload",
        //   data,
        //   pickedFile,
        //   "video",
        //   ratio,
        // );
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("select video file");
    }
  };

  const imagefileInputHandler = async (file, fileName) => {
    var imgeFile = dataURLtoBlob(file);

    console.log(imgeFile, storyType, "thumb");

    if (storyType === "video") {
      fileResiderRef1.current.innerHTML = "";
      //let image = await createImage(reader.result)
      // let image = await createImage(file)
      // fileResiderRef1.current.appendChild(image)
      fileResiderRef1.current.style.width = "100%";
      // image.style.width = '100%'
      fileResiderRef1.current.innerHTML = `<img src=${file} alt="thumbnail" style="width:100%"/>`;
    } else {
      fileResiderRef.current.innerHTML = "";
      //let image = await createImage(reader.result)
      // let image = await createImage(file)
      // fileResiderRef.current.appendChild(image)
      fileResiderRef.current.style.width = "100%";
      // image.style.width = '100%'
      fileResiderRef.current.innerHTML = `<img src=${file} alt="thumbnail" style="width:100%"/>`;
    }

    const data = {
      user_id: user.loginUser._id,
      keyname: "userFiles/storyFiles",
      filename: fileName,
      fileType: imgeFile.type,
    };

    dispatch({
      type: "COVEREXIST",
      payload: {
        isThereCover: true,
        URL: file,
      },
    });
    getPresignedUrl("getPresignedURLForCoverUpload", data, imgeFile, "cover");
    setUploadedCoverImage({ url: file, blob: imgeFile, fileName: fileName });
  };
  const selectCoverImageForVideo = (imageType, event) => {
    document
      .querySelectorAll(".thumbnail-img-selected-border")
      .forEach((div) => {
        div.classList.remove("thumbnail-img-selected-border");
      });

    event.currentTarget.childNodes[0].classList.add(
      "thumbnail-img-selected-border"
    );

    if (imageType === "uploadImage") {
      setCoverUrl(coverUpload);

      dispatch({
        type: "COVEREXIST",
        payload: {
          isThereCover: true,
          URL: uploadedCoverImage.url,
        },
      });
    }

    if (imageType === "screenshot1") {
      setCoverUrl(cover1);

      dispatch({
        type: "COVEREXIST",
        payload: {
          isThereCover: true,
          URL: screenShot1.url,
        },
      });
    }

    if (imageType === "screenshot2") {
      setCoverUrl(cover2);

      dispatch({
        type: "COVEREXIST",
        payload: {
          isThereCover: true,
          URL: screenShot2.url,
        },
      });
    }
  };

  const sendStory = async () => {
    let storyTyp = storyType;

    if (!storyType) {
      storyTyp = "text";
    } else {
      storyTyp = storyType;
    }

    if (storyTyp === "text") {
      if (stafType === "STORIES") {
        alert("Add atleast one video is needed");
      } else {
        alert("Add atleast one STAF IMAGE is needed");
      }
      return;
    }

    if (language === "0") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet language",
        "Red"
      );
      return;
    }

    if (!storyTitle) {
      responseMessageDispaly(errormesseageRef, "Please enter title^", "Red");
      return;
    }

    if (!storyDesc) {
      responseMessageDispaly(errormesseageRef, "Please enter story*", "Red");
      return;
    }

    const user_id = user.loginUser._id;

    const token = user.token;

    let categoryAdded = category;

    if (category === "0") {
      if (newCategory !== "") {
        try {
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

          const data = {
            category_name: newCategory,
          };

          const res = await getDataPostWithOutHeader(url, data);
          if (res.data.status === 200) {
            setShowAddCategory(false);
            setCategory(res.data.body._id);
            categoryAdded = res.data.body._id;
          }
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        alert("please enter category");
        return;
      }
    }

    console.log(storyID, videoUploadPercentage, "storyID");
    if (!storyID && storyTyp === "video") {
      responseMessageDispaly(errormesseageRef, "Story ID is required", "Red");
      return;
    }

    if (videoUploadPercentage > 0) {
      responseMessageDispaly(
        errormesseageRef,
        "Video is uploading, please wait until upload",
        "Red"
      );
      return;
    }

    const data = {
      user_id: user_id,
      category_id: categoryAdded,
      story_language_id: language,
      story_title: storyTitle,
      story_text: storyDesc,
      story_type: storyTyp,
      cover1: cover1,
      cover2: cover2,
      tags: tags,
      profileId: profileId,
      languagecd: languagecd,
      storyID: storyID,
    };

    if (storyTyp === "video_url") {
      if (!videUrl) {
        responseMessageDispaly(
          errormesseageRef,
          "Please enter video link*",
          "Red"
        );
        return;
      }
      data.video_url = videUrl;
      if (coverUrl) data.image_path = coverUrl;
    }

    if (storyTyp !== "video_url") {
      data.image_path = coverUrl;
    }
    if (storyTyp === "video") {
      if (!uplaodVideoUrl) {
        responseMessageDispaly(errormesseageRef, "select the video", "Red");
        return;
      }
      data.video_path = uplaodVideoUrl;
      data.videoHeight = videoHeight;
      data.videoWidth = videoWidth;
    }
    if (storyTyp === "audio") {
      data.audio_url = audioUrl;
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    onClose();

    let urlSend =
      menuType == "STAFS"
        ? `${url}/api/userProfile/sendExpert`
        : `${url}/api/userProfile/sendUserStory`;

    console.log(urlSend, "urlSend");
    axios
      .post(urlSend, data, options)
      .then(async (res) => {
        if (storyTyp === "video") {
          try {
            console.log(videoRatio, "videoRatio upload");
            const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
            const data = {
              url: uplaodVideoUrl,
              id: storyID,
              user_id: user.loginUser._id,
              whereToUpdate: "user_stories",
              videoRatio: videoRatio,
            };
            getStories(1);
            const res = await getDataPostWithHeader(
              urlConvert,
              data,
              user.token
            );
          } catch (err) {
            throw new Error("update error or suspended");
          }
        }
        setStoryType("");
        setStoryTitle("");
        setStoryDesc("");
        setLanguage("0");
        setCategory("0");
        setNewCategory("");
        setVideoUrl("");
        setCoverUrl("");
        setCoverUpload("");
        setCover1("");
        setCover2("");
        setAudioUrl("");
        setTags([]);
        setShowvideoUrlLink(true);
        setStoryID("");
        setcategories([]);
        setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
        setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
        setUploadedCoverImage({ url: "", blob: "", fileName: "" });
        getStories(1);
        setVideoRatio("");

        if (stafType === "CV") {
          setCVAddedAlready(true);
        }
        //document.getElementById(res.data.body.subscription_id).click();
        //onModelClose();
        onClose();

        const result = await getDataPostWithHeader(
          `${url}/api/userProfile/tagsForSearchKey`,
          { tags: tags },
          user.token
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        alert(err.message);
        //toast.error("System Failed");
      });
  };

  const getStories = async (storyPage) => {
    try {
      if (user.loginUser._id) {
        let storyKind = "userstories";
        if (menuType === "STORIES") {
          storyKind = "userstories";
        } else {
          storyKind = "userstafs";
        }

        let getStoryUrl =
          menuType === "STORIES"
            ? `${url}/api/userProfile/getUserStories?page=${storyPage}&user_id=${user.loginUser._id}`
            : `${url}/api/userProfile/getUserExperts?page=${storyPage}&user_id=${user.loginUser._id}`;

        const response = await fetch(getStoryUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const responseData = await response.json();

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          } else {
            if (storyPage > 1) {
              toast.error("Something Went Wrong");
            } else {
              toast.error("Something Went Wrong");
            }
            //throw new Error(responseData.message || 'something went wrong')
          }
        } else {
          if (storyPage > 1) {
            dispatch({
              type: "sub_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({ type: "sub_group_stories", payload: responseData.body });
          }
          document.getElementById("story_box").scrollTop = 0;
        }
      }
    } catch (err) {
      if (storyPage > 1) {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
        console.log(err);
      }
    }
  };

  const selectStoryType = (event, type) => {
    document.querySelectorAll(".header-wrapper-each").forEach((div) => {
      div.style.backgroundColor = "#fff";
    });

    event.currentTarget.style.background = "#dbdbdb";
    setStoryType(type);

    if (type === "video") {
      videoPickerRef.current.click();
    }
  };

  const onClose = () => {
    setCoverUrl("");
    setStoryType("");
    setStoryTitle("");
    setAudioUrl("");
    setStoryDesc("");
    setLanguage("0");
    setCategory("0");
    setNewCategory("");
    setVideoUrl("");
    setCoverUrl("");
    setCoverUpload("");
    setCover1("");
    setCover2("");
    setStoryID("");
    //setVideoRatio("");
    setTags([]);
    setcategories([]);
    setShowAddCategory(false);
    setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
    setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
    setUploadedCoverImage({ url: "", blob: "", fileName: "" });
    setLanguagecd(profiles[0].language_id.code);
    setProfileId(profiles[0]._id);
    setShowvideoUrlLink(true);
    setImagePrice("");
    setImageSelectType("");
    setImagePriceType("");
    setCurrency("");
    onModelClose();
    setVideoRatio("");
  };

  const startRecord = () => {
    startRecording();
  };

  const stopRecord = () => {
    stopRecording();
  };

  const langaugeSelector = (langcode, langid, i, profiles) => {
    setLanguage(langid);
    setProfileId(profiles[i]._id);
    setLanguagecd(langcode);
    setShowAddCategory(false);
    setNewCategory("");
  };

  const uploadToS3 = (
    pickedFile,
    preSignedurl,
    apiName,
    fileUrl,
    story_id,
    ratio
  ) => {
    if (apiName === "getPresignedURLForCoverUpload") {
      setImageUploadPercentage(1);
    } else {
      setVideoUploadPercentage(1);
      dispatch({
        type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
        payload: {
          videoUploadPercentage: 1,
          videoprocessingUpload: false,
          isVideoUploading: "YES",
        },
      });
    }
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent <= 100) {
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
            } else {
              console.log(percent, "percentage");
              setVideoProcessingUpload(true);
              dispatch({
                type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
                payload: {
                  videoprocessingUpload: true,
                  videoUploadPercentage: 100,
                  isVideoUploading: "YES",
                },
              });
            }
          } else {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else {
              console.log(percent, "percentage");
              setVideoUploadPercentage(percent + 1);
              dispatch({
                type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
                payload: {
                  videoUploadPercentage: percent + 1,
                  videoprocessingUpload: false,
                  isVideoUploading: "YES",
                },
              });
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setImageUploadPercentage(0);
            setImageProcessingUpload(false);
          } else {
            if (pickedFile.type.split("/")[0] === "video") {
              const dataMedaiUpdate = {
                story_id: story_id,
                user_id: user.loginUser._id,
              };

              try {
                const urlMediaUpdate = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/storyMediaUpdate`;
                const res2 = await getDataPostWithHeader(
                  urlMediaUpdate,
                  dataMedaiUpdate,
                  user.token
                );

                console.log(ratio, "videoRatio upload");
                const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
                const data = {
                  url: fileUrl,
                  id: story_id,
                  user_id: user.loginUser._id,
                  whereToUpdate: "user_stories",
                  videoRatio: ratio,
                };
                const res = await getDataPostWithHeader(
                  urlConvert,
                  data,
                  user.token
                );
              } catch (err) {
                throw new Error("update error or suspended");
              }
              // const urlMp4Convert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaFourmGroupCoveret`;

              // const res1 = await getDataPostWithHeader(
              //   urlMp4Convert,
              //   data,
              //   user.token,
              // );
            }

            setVideoUploadPercentage(0);
            setVideoProcessingUpload(false);
            getStories(1);
            dispatch({
              type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
              payload: {
                videoUploadPercentage: 0,
                videoprocessingUpload: false,
                isVideoUploading: "NO",
              },
            });
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else {
          console.log(err.message);

          if (err.message === "update error or suspended") {
            alert(err.message);
          } else {
            if (err.message !== "User has canceled the file upload") {
              alert(
                "While uploading something went wrong ! try again uploading"
              );
            }
          }
          setVideoUploadPercentage(0);
          setVideoProcessingUpload(false);
          dispatch({
            type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
            payload: {
              videoUploadPercentage: 0,
              videoprocessingUpload: false,
              isVideoUploading: "NO",
            },
          });
          dispatch({
            type: "COVEREXIST",
            payload: {
              isThereCover: false,
              URL: "",
            },
          });
        }
      });
  };

  return (
    <>
      <Model show={showAddStoryModel} onCancel={onModelClose}>
        <Modal
          show={showCrop}
          onCancel={closeProfileHandler}
          headerClass="text-center"
          containerStyle={{ height: "550px" }}
        >
          <div className="map-container">
            <ImageCrop
              close={closeProfileHandler}
              onSave={imagefileInputHandler}
              aspectRatio={16 / 9}
              objectFit="horizontal-cover"
              selectedFile={selectFile}
              ratio={"16:9"}
            />
          </div>
        </Modal>
        <div
          className="container-box-model"
          style={{
            background: "#fff",
            paddingBottom: "10px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              padding: "15px",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: "0px",
              background: "white",
              zIndex: "1",
              borderBottom: "1px solid #dbdbdb",
            }}
          >
            <AiOutlineClose
              onClick={() => {
                cancelUpload();
                onClose();
              }}
              className="cursor-pointer"
              size={20}
            />
          </div>

          {
            <>
              <div className="row-model pt-2">
                <div className="col-md-12">
                  <div ref={errormesseageRef}></div>
                </div>

                {isFromMobile && (
                  <div
                    className="col-md-5 mt-2"
                    style={{ marginBottom: "10px", overflow: "auto" }}
                  >
                    <div>
                      <>
                        <div
                          className={`${"video-warapper-ratio-adujestment"}`}
                        >
                          <video
                            controls
                            src=""
                            ref={videoTempRef}
                            style={{
                              display: "none",
                            }}
                            className={`${"video-whereplayer-shows"}`}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef2}
                        >
                          Your browser does not support the video tag.
                        </video>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef3}
                        >
                          Your browser does not support the video tag.
                        </video>
                      </>

                      <div>
                        {storyType !== "video_url" && (
                          <div
                            ref={fileResiderRef}
                            className={`${
                              (storyType === "image" || storyType === "text") &&
                              "image-warapper-ratio-adujestment"
                            }`}
                          ></div>
                        )}
                        <div className="mt-2 cursor-pointer">
                          {videoRatio === "" &&
                            audioUrl === "" &&
                            videUrl == "" &&
                            coverUrl == "" && (
                              <div className="uploadCover">
                                <img
                                  id="thumnail_bio"
                                  src={addcoverorVideo}
                                  className="width-100 cursor-pointer"
                                  alt="thumbnail"
                                  onClick={() => {
                                    fileRef.current.click();
                                  }}
                                />
                                <input
                                  type="file"
                                  accept="image/*,video/mp4,video/x-m4v,video/*,audio/*"
                                  ref={fileRef}
                                  onChange={(e) => {
                                    if (menuType !== "STAFS") {
                                      //setStoryType("image");
                                      fileSelectHandler(e);
                                      //openProfileHandler(e);
                                      setSelectFile(e);
                                    } else {
                                      setStoryType("stafs");
                                      fileSelectHandler(e);
                                      setSelectFile(e);
                                    }
                                  }}
                                  style={{ display: "none" }}
                                />
                              </div>
                            )}

                          {storyType === "audio" && coverUrl === "" && (
                            <div
                              className="uploadCover thumbnail-img-border"
                              style={{ height: "247px" }}
                              onClick={openProfileHandler}
                            >
                              <AiOutlineUpload />
                              <div>Upload thumbnail</div>
                            </div>
                          )}
                        </div>
                        <div
                          ref={audioResiderRef}
                          className="mt-1"
                          style={{ textAlign: "center" }}
                        ></div>
                      </div>

                      {videUrl !== "" && (
                        <>
                          <ReactPlayer
                            url={videUrl}
                            controls
                            width="100%"
                            height="206px"
                            style={{
                              marginBottom: "5px",
                            }}
                          />
                          <div className="row-model">
                            <div className="col-md-5  mt-2 cursor-pointer">
                              <div
                                className="uploadCover thumbnail-img-border"
                                onClick={openProfileHandler}
                              >
                                <AiOutlineUpload />
                                <div>Upload thumbnail</div>
                              </div>
                            </div>
                            <div className="col-md-5  mt-2 cursor-pointer">
                              <div
                                ref={fileResiderRef}
                                className={`${
                                  storyType === "image" &&
                                  "image-warapper-ratio-adujestment"
                                }`}
                              >
                                <img
                                  className="width-100 cursor-pointer"
                                  src={`https://img.youtube.com/vi/${getId(
                                    videUrl
                                  )}/maxresdefault.jpg`}
                                  alt="im-story"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defualtImg;
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {videUrl !== "" && (
                        <div style={{ position: "relative" }}>
                          {!showvideoUrlLink && (
                            <BsThreeDotsVertical
                              onClick={() => {
                                menuBoxBioRef.current.style.display = "block";
                              }}
                              size={20}
                              style={{
                                marginRight: -4 + "px",
                                border: "1px solid",
                                borderRadius: "50%",
                                padding: "2px",
                                float: "right",
                                marginTop: "10px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                          <div
                            className="menu-box-bio display-none"
                            style={{
                              position: "absolute",
                              right: "-4px",
                              top: "5px",
                            }}
                            ref={menuBoxBioRef}
                          >
                            <div className="menu-header">
                              <AiOutlineClose
                                className="cursor-pointer"
                                onClick={() => {
                                  menuBoxBioRef.current.style.display = "none";
                                }}
                              />
                            </div>
                            <div
                              className="menu-line cursor-pointer"
                              onClick={() => {
                                openProfileHandler();
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            >
                              Select cover
                            </div>
                            <div
                              className="menu-line cursor-pointer"
                              onClick={() => {
                                setShowvideoUrlLink(true);
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            >
                              Replace the Youtube link
                            </div>
                          </div>
                        </div>
                      )}
                      {storyType === "video" && (
                        <div className="row-model">
                          <div className="col-md-5   mt-2 cursor-pointer">
                            {uploadedCoverImage.url === "" && (
                              <div
                                className="uploadCover thumbnail-img-border"
                                onClick={openProfileHandler}
                              >
                                <AiOutlineUpload />
                                <div>Upload thumbnail</div>
                              </div>
                            )}

                            <div
                              ref={fileResiderRef1}
                              onClick={(e) => {
                                selectCoverImageForVideo("uploadImage", e);
                              }}
                            ></div>
                          </div>
                          {/* <div
                            className="col-md-4  mt-2 cursor-pointer"
                            onClick={(e) => {
                              selectCoverImageForVideo("screenshot1", e);
                            }}
                          >
                            <img
                              src={screenShot1.url}
                              style={{ width: "100%" }}
                              alt="youtubelink"
                              className="thumbnail-img-selected-border"
                            />
                          </div>
                          <div
                            className="col-md-4  mt-2 cursor-pointer"
                            onClick={(e) => {
                              selectCoverImageForVideo("screenshot2", e);
                            }}
                          >
                            <img
                              src={screenShot2.url}
                              style={{ width: "100%" }}
                              alt="youtubelink"
                            />
                          </div> */}
                          <div className="col-md-12">
                            {imageUploadPercentage > 0 && (
                              <div className="row mt-3">
                                <div>Image upload</div>
                                <div className="col-md-12 pt-1">
                                  <div className="progress">
                                    <div
                                      className="progress-bar bg-success progress-bar-striped"
                                      role="progressbar"
                                      style={{
                                        width: `${imageUploadPercentage}%`,
                                        height: "100%",
                                      }}
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {imageUploadPercentage}%
                                    </div>
                                  </div>
                                </div>
                                {!imageprocessingUpload && (
                                  <div className="col-md-12 pt-1">
                                    <span
                                      className="text-primary cursor-pointer"
                                      onClick={() => cancelUpload()}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      Abort Uploading
                                      <AiOutlineClose
                                        className="cursor-pointer"
                                        size={20}
                                      />
                                    </span>
                                  </div>
                                )}
                                {imageprocessingUpload && (
                                  <div className="col-md-12 pt-1 loading">
                                    Processing...
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {selctedVideoFile && (
                        <MutiPartFileUpload
                          file={selctedVideoFile}
                          user={user}
                          setProgress={setVideoUploadPercentage}
                          progress={videoUploadPercentage}
                          setID={setStoryID}
                          setUplaodFileUrl={setUplaodVideoUrl}
                          keyString={"userFiles/storyFiles"}
                        />
                      )}

                      {/* {videoUploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${videoUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {videoUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!videoprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {videoprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )} */}

                      {imageUploadPercentage > 0 && storyType === "image" && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${imageUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {imageUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!imageprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {imageprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-7">
                  <>
                    {profiles.length > 1 ? (
                      <select
                        onChange={(e) => {
                          langaugeSelector(
                            e.target.id,
                            e.target.value,
                            e.target.selectedIndex - 1,
                            profiles
                          );
                        }}
                        value={language}
                        ref={langaugeRef}
                        className="form-control mt-3"
                      >
                        {profiles.length > 1 && (
                          <option value="0">Select Langauge</option>
                        )}
                        {profiles.map((list, index) => {
                          return (
                            <option
                              key={list._id}
                              id={list.language_id.code}
                              value={list.language_id._id}
                            >
                              {list.language_id.nativeName}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <div className="text-box" style={{ padding: "7px" }}>
                        {profiles.length > 0 &&
                          profiles[0].language_id.nativeName}
                      </div>
                    )}
                  </>

                  <div style={{ position: "relative" }}>
                    <div className="mt-4 popup-form-area">
                      <div className="popup-lbl">Category</div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Enter category"
                        value={newCategory}
                        onChange={(e) =>
                          categgoryBasedOnLangaugeHandler(e.target.value)
                        }
                      />
                    </div>
                    {categories.length > 0 && (
                      <CatgeorySearchBox
                        categories={categories}
                        selecetCategory={selecetCategory}
                      />
                    )}
                  </div>

                  <div className="mt-4 popup-form-area">
                    <div className="popup-lbl">Title(required):</div>

                    <input
                      type="text"
                      id="Title"
                      name="Title"
                      placeholder="Title"
                      className="form-control mt-2"
                      value={storyTitle}
                      onChange={(e) => {
                        setStoryTitle(e.target.value);
                      }}
                      onBlur={(e) => {
                        setTags([e.target.value]);
                      }}
                    />
                  </div>

                  <div className="mt-2">
                    <RichText data={storyDesc} setData={setStoryDesc} />
                  </div>

                  <div className="mt-3 text-box popup-form-area tag-box">
                    <div className="popup-lbl">Tags:</div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {tags.length > 0 &&
                        tags.map((tagName, index) => {
                          return (
                            <div key={index} className="m-1 tags">
                              <div className="mr-1">{tagName}</div>
                              <div
                                className="tags-cancel-box cursor-pointer"
                                onClick={() => {
                                  setTags(tags.filter((tag, i) => i !== index));
                                }}
                              >
                                <ImCancelCircle size={18} />
                              </div>
                            </div>
                          );
                        })}

                      {tags.length < 25 && (
                        <input
                          type="text"
                          className="story-title text-area-desc"
                          placeholder="Enter coma after each tag"
                          maxLength="100"
                          style={{ width: "30%" }}
                          onKeyUp={(event) => {
                            let newTag = event.target.value.split(",")[0];
                            if (newTag.length < 100) {
                              tagErrorMessegesRef.current.innerHTML = "";
                              if (event.key === "Enter" || event.key === ",") {
                                if (event.target.value !== "") {
                                  if (tags.length < 25) {
                                    if (newTag !== "")
                                      if (!tags.includes(newTag)) {
                                        setTags([...tags, newTag]);
                                      }

                                    event.target.value = "";
                                  }
                                }
                              }
                            } else {
                              tagErrorMessegesRef.current.innerHTML =
                                "Tag length should be below 100";
                              tagErrorMessegesRef.current.style.color = "red";
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="tag-length-count-box">
                    <div ref={tagErrorMessegesRef}></div>
                    <span>
                      <span className="mr-1">{tags.length}</span>/
                      <span className="ml-1">25 tags</span>
                    </span>
                  </div>
                </div>

                {!isFromMobile && (
                  <div
                    className="col-md-5 mt-2"
                    style={{ height: "600px", overflow: "auto" }}
                  >
                    <div>
                      <>
                        <div
                          className={`${
                            videoRatio && "video-warapper-ratio-adujestment"
                          }`}
                        >
                          <video
                            controls
                            src=""
                            ref={videoTempRef}
                            style={{
                              display: "none",
                            }}
                            className={`${
                              videoRatio && "video-whereplayer-shows"
                            }`}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef2}
                        >
                          Your browser does not support the video tag.
                        </video>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef3}
                        >
                          Your browser does not support the video tag.
                        </video>
                      </>

                      {selctedVideoFile && (
                        <MutiPartFileUpload
                          file={selctedVideoFile}
                          user={user}
                          setProgress={setVideoUploadPercentage}
                          progress={videoUploadPercentage}
                          setID={setStoryID}
                          setUplaodFileUrl={setUplaodVideoUrl}
                          keyString={"userFiles/storyFiles"}
                        />
                      )}

                      <div>
                        {storyType !== "video_url" && (
                          <div
                            ref={fileResiderRef}
                            className={`${
                              (storyType === "image" || storyType === "text") &&
                              "image-warapper-ratio-adujestment"
                            }`}
                          ></div>
                        )}
                        <div className="mt-2 cursor-pointer">
                          {videoRatio === "" &&
                            audioUrl === "" &&
                            videUrl == "" &&
                            coverUrl == "" && (
                              <div className="uploadCover">
                                <img
                                  id="thumnail_bio"
                                  src={addcoverorVideo}
                                  className="width-100 cursor-pointer"
                                  alt="thumbnail"
                                  onClick={() => {
                                    fileRef.current.click();
                                  }}
                                />
                                <input
                                  type="file"
                                  accept="image/*,video/mp4,video/x-m4v,video/*,audio/*"
                                  ref={fileRef}
                                  onChange={(e) => {
                                    if (menuType !== "STAFS") {
                                      //setStoryType("image");
                                      fileSelectHandler(e);
                                      //openProfileHandler(e);
                                      setSelectFile(e);
                                    } else {
                                      setStoryType("stafs");
                                      fileSelectHandler(e);
                                      setSelectFile(e);
                                    }
                                  }}
                                  style={{ display: "none" }}
                                />
                              </div>
                            )}

                          {storyType === "audio" && coverUrl === "" && (
                            <div
                              className="uploadCover thumbnail-img-border"
                              style={{ height: "247px" }}
                              onClick={openProfileHandler}
                            >
                              <AiOutlineUpload />
                              <div>Upload thumbnail</div>
                            </div>
                          )}
                        </div>

                        <div
                          ref={audioResiderRef}
                          className="mt-1"
                          style={{ textAlign: "center" }}
                        ></div>
                      </div>

                      {videUrl !== "" && (
                        <>
                          <ReactPlayer
                            url={videUrl}
                            controls
                            width="100%"
                            height="206px"
                            style={{
                              marginBottom: "5px",
                            }}
                          />
                          <div className="row-model">
                            <div className="col-md-5  mt-2 cursor-pointer">
                              <div
                                className="uploadCover thumbnail-img-border"
                                onClick={openProfileHandler}
                              >
                                <AiOutlineUpload />
                                <div>Upload thumbnail</div>
                              </div>
                            </div>
                            <div className="col-md-5  mt-2 cursor-pointer">
                              <div
                                ref={fileResiderRef}
                                className={`${
                                  storyType === "image" &&
                                  "image-warapper-ratio-adujestment"
                                }`}
                              >
                                <img
                                  className="width-100 cursor-pointer"
                                  src={`https://img.youtube.com/vi/${getId(
                                    videUrl
                                  )}/maxresdefault.jpg`}
                                  alt="im-story"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defualtImg;
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {videUrl !== "" && (
                        <div style={{ position: "relative" }}>
                          {!showvideoUrlLink && (
                            <BsThreeDotsVertical
                              onClick={() => {
                                menuBoxBioRef.current.style.display = "block";
                              }}
                              size={20}
                              style={{
                                marginRight: -4 + "px",
                                border: "1px solid",
                                borderRadius: "50%",
                                padding: "2px",
                                float: "right",
                                marginTop: "10px",
                                marginBottom: "5px",
                              }}
                            />
                          )}
                          <div
                            className="menu-box-bio display-none"
                            style={{
                              position: "absolute",
                              right: "-4px",
                              top: "5px",
                            }}
                            ref={menuBoxBioRef}
                          >
                            <div className="menu-header">
                              <AiOutlineClose
                                className="cursor-pointer"
                                onClick={() => {
                                  menuBoxBioRef.current.style.display = "none";
                                }}
                              />
                            </div>
                            <div
                              className="menu-line cursor-pointer"
                              onClick={() => {
                                openProfileHandler();
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            >
                              Select cover
                            </div>
                            <div
                              className="menu-line cursor-pointer"
                              onClick={() => {
                                setShowvideoUrlLink(true);
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            >
                              Replace the Youtube link
                            </div>
                          </div>
                        </div>
                      )}
                      {storyType === "video" && (
                        <div className="row-model">
                          <div className="col-md-5  mt-2 cursor-pointer">
                            {uploadedCoverImage.url === "" && (
                              <div
                                className="uploadCover thumbnail-img-border"
                                onClick={openProfileHandler}
                              >
                                <AiOutlineUpload />
                                <div>Upload thumbnail</div>
                              </div>
                            )}

                            <div
                              ref={fileResiderRef1}
                              onClick={(e) => {
                                selectCoverImageForVideo("uploadImage", e);
                              }}
                            ></div>
                          </div>

                          <div className="col-md-12">
                            {imageUploadPercentage > 0 && (
                              <div className="row mt-3">
                                <div className="col-md-12 pt-1">
                                  <div className="progress">
                                    <div
                                      className="progress-bar bg-success progress-bar-striped"
                                      role="progressbar"
                                      style={{
                                        width: `${imageUploadPercentage}%`,
                                        height: "100%",
                                      }}
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {imageUploadPercentage}%
                                    </div>
                                  </div>
                                </div>
                                {!imageprocessingUpload && (
                                  <div className="col-md-12 pt-1">
                                    <span
                                      className="text-primary cursor-pointer"
                                      onClick={() => cancelUpload()}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      Abort Uploading
                                      <AiOutlineClose
                                        className="cursor-pointer"
                                        size={20}
                                      />
                                    </span>
                                  </div>
                                )}
                                {imageprocessingUpload && (
                                  <div className="col-md-12 pt-1 loading">
                                    Processing...
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* 
                      {videoUploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${videoUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {videoUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!videoprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {videoprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )} */}

                      {imageUploadPercentage > 0 && storyType === "image" && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${imageUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {imageUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!imageprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {imageprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="footer-upload">
                  <div>
                    {!videoprocessingUpload && videoUploadPercentage > 0 && (
                      <>
                        <span className="loading">Uplaoding....</span>
                        <span> {videoUploadPercentage}%</span>
                      </>
                    )}
                    {videoprocessingUpload && (
                      <div className="loading">Processing...</div>
                    )}
                  </div>
                  <div className="mb-2">
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="push-right"
                        onClick={sendStory}
                      >
                        Publish
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </Model>
    </>
  );
};

export default AddStoryModel;
