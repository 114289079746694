const initialState = {
  userCategories: [],
}

const RegFifthSectionReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'userCategories':
      return { ...state, userCategories: action.payload }

    default:
      return state
  }
}

export default RegFifthSectionReducers
