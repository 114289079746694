import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios, { CancelToken, isCancel } from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { AiOutlineUpload, AiOutlineClose } from "react-icons/ai";

import { RiDeleteBin6Line } from "react-icons/ri";

import Model from "../../utils/models/ModelStory";
import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
} from "../../functions/editProfile/editProfile";

import defualtImg from "../../images/Read&ShareThumbnail.png";

import ReactPlayer from "react-player";
import defaultThumbmail from "../../images/Read&ShareThumbnail.png";
import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
import CatgeorySearchBox from "./CatgeorySearchBox";
import RichText from "../editProfile/RichText";
import "./addStoryModel.css";

import {
  responseMessageDispaly,
  dataURLtoBlob,
  blobToDataUrl,
} from "../../utils/sharedfunction";
import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";

const EditStoryModel = ({
  user,
  onModelClose,
  showEditStoryModel,
  story,
  menuType,
  stafType,
  setCVAddedAlready,
}) => {
  const dispatch = useDispatch();
  console.log("story edit inside", story.story_type, "type");
  const [storyType, setStoryType] = useState(story.story_type);
  const [profileID, setProfileID] = useState("");
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [coverUrl, setCoverUrl] = useState(story.thumbnail_url);
  const [coverUpload, setCoverUpload] = useState(story.thumbnail_url);
  const [storyTitle, setStoryTitle] = useState("");
  const [storyDesc, setStoryDesc] = useState("");
  const [language, setLanguage] = useState("0");
  const [category, setCategory] = useState("0");
  const [cover1, setCover1] = useState(story.cover1);
  const [cover2, setCover2] = useState(story.cover2);
  const [categories, setcategories] = useState([]);
  const [showCrop, setShowCrop] = useState(false);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [videoprocessingUpload, setVideoProcessingUpload] = useState(false);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [imageprocessingUpload, setImageProcessingUpload] = useState(false);
  const [languagecd, setLanguagecd] = useState();
  const [showvideoUrlLink, setShowvideoUrlLink] = useState(false);
  const [videUrl, setVideoUrl] = useState(story.story_url);
  const langaugeRef = useRef(null);
  const categoryRef = useRef(null);
  const menuBoxRef = useRef(null);

  const [tags, setTags] = useState([]);

  const [audioUrl, setAudioUrl] = useState("");
  const [uplaodVideoUrl, setUplaodVideoUrl] = useState("");
  const [uploadedCoverImage, setUploadedCoverImage] = useState({
    url: story.thumbnail_url,
    blob: "",
    fileName: "",
  });
  const [screenShot1, setScreenShot1] = useState({
    url: defaultThumbmail,
    blob: "",
    fileName: "",
  });
  const [screenShot2, setScreenShot2] = useState({
    url: defaultThumbmail,
    blob: "",
    fileName: "",
  });
  const [isFromMobile, setIsFromMobile] = useState(true);
  const errormesseageRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const fileResiderRef = useRef(null);
  const audioResiderRef = useRef(null);
  const menuBoxBioRef = useRef(null);
  const videoPickerRef = useRef(null);
  const videoTempRef = useRef(null);
  const videoTempRef2 = useRef(null);
  const videoTempRef3 = useRef(null);
  const audioPickerRef = useRef(null);
  const selectCategory = useRef(null);
  const tagErrorMessegesRef = useRef(null);
  const { UserRequiredDropDown, ProfileReducers } = useSelector(
    (state) => state,
  );

  const { profiles } = ProfileReducers;

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  useEffect(() => {
    setCoverUrl(story.thumbnail_url);
    setCoverUpload(story.thumbnail_url);
    setCover1(story.cover1);
    setVideoUrl(story.story_url);
    setCover2(story.cover2);
    setStoryType(story.story_type);
    setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
    setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
    setUploadedCoverImage({ url: story.thumbnail_url, blob: "", fileName: "" });
  }, [story]);

  useEffect(() => {
    console.log("story", story);
    if (story) {
      setNewCategory(story.category.category_name);
      setStoryTitle(story.story_title);
      setStoryDesc(story.story_text);
      setLanguage(story.story_language_id);
      setTags(story.tags);
      setCategory(story.category_id);
    }
  }, [story]);

  const url = process.env.REACT_APP_BACKEND_URL;

  function matchYoutubeUrl(link) {
    let url = link.trim();
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      setVideoUrl(url);
      setShowvideoUrlLink(false);
      return;
    }

    setVideoUrl(story.story_url);
    setShowvideoUrlLink(false);

    alert("please enter valid youtube url");
    return false;
  }

  const categgoryBasedOnLangaugeHandler = async (value) => {
    setNewCategory(value);
    if (value !== "") {
      if (value !== newCategory) {
        setCategory("0");
      }
      try {
        let categories = await categgoryBasedLangaugeBYID(value);
        setcategories(categories);
      } catch (err) {
        console.log(err);
        toast.error("failed to load category");
      }
    }
  };

  const selecetCategory = (cat_id, category_name) => {
    setCategory(cat_id);
    setNewCategory(category_name);
    setcategories([]);
  };

  const langaugeSelector = (langcode, langid, i, profiles) => {
    setProfileID(profiles[i]._id);
    setLanguagecd(langcode);
    setLanguage(langid);
    setShowAddCategory(false);
  };

  //const langaugeHandler = (list, i) => {
  //   var index = document
  //     .getElementsByClassName("language-select-border")[0]
  //     .id.split("_")[2];

  //   setProfileID(profiles[i]._id);

  //   const lang = profiles[index].language_id;

  //   let TheStoryPrevious = {};
  //   story.stories.forEach((stroy, i) => {
  //     if (stroy.story_language_id === lang._id) {
  //       TheStoryPrevious = {
  //         story_language_id: stroy.story_language_id,
  //         index: i,
  //       };
  //     }
  //   });

  //   if (story.stories[TheStoryPrevious.index]) {
  //     sendStory();
  //   }

  //   document.querySelectorAll(".profile-bio-tab").forEach((div) => {
  //     div.classList.remove("language-select-border");
  //   });
  //   document
  //     .getElementById(`lang_list_${i}`)
  //     .classList.add("language-select-border");
  //   setLanguagecd(list.code);
  //   setShowAddCategory(false);

  //   let TheStoryToEdit = {};
  //   story.stories.forEach((stroy, i) => {
  //     if (stroy.story_language_id === list._id) {
  //       TheStoryToEdit = {
  //         story_language_id: stroy.story_language_id,
  //         index: i,
  //       };
  //     }
  //   });
  //   if (TheStoryToEdit.story_language_id) {
  //     setStoryTitle(story.stories[TheStoryToEdit.index].story_title);
  //     setStoryDesc(story.stories[TheStoryToEdit.index].story_text);
  //     setLanguage(story.stories[TheStoryToEdit.index].story_language_id);
  //     setTags(story.stories[TheStoryToEdit.index].tags);
  //     setNewCategory(
  //       story.stories[TheStoryToEdit.index].category.category_name,
  //     );
  //   } else {
  //     setStoryTitle("");
  //     setStoryDesc("");
  //     setLanguage(list._id);
  //     setTags([]);
  //     setStorylanguage_id("");
  //     setCategory("0");
  //   }
  // };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
    setVideoUploadPercentage(0);
    dispatch({
      type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
      payload: {
        videoUploadPercentage: 0,
        videoprocessingUpload: false,
        isVideoUploading: "NO",
      },
    });
    setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
    setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
    videoTempRef.current.src = "";
    videoTempRef2.current.src = "";
    videoTempRef3.current.src = "";
    setUplaodVideoUrl("");
  };

  const openProfileHandler = () => {
    setShowCrop(true);
  };
  const closeProfileHandler = () => setShowCrop(false);

  const createImage = (url) => {
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
            } else {
              setVideoProcessingUpload(true);
              dispatch({
                type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
                payload: {
                  videoprocessingUpload: true,
                  videoUploadPercentage: 0,
                  isVideoUploading: "YES",
                },
              });
            }
            if (apiName === "getPresignedURLForCoverUpload") {
              if (storyType !== "video") {
                setImageUploadPercentage(percent);
              } else {
                setImageUploadPercentage(percent);
              }
            } else {
              setVideoUploadPercentage(percent);

              dispatch({
                type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
                payload: {
                  videoUploadPercentage: percent,
                  videoprocessingUpload: false,
                  isVideoUploading: "YES",
                },
              });
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setImageUploadPercentage(0);
            setImageProcessingUpload(false);

            document.getElementById("video-poster").poster =
              await blobToDataUrl(pickedFile);
          } else {
            setVideoUploadPercentage(0);
            setVideoProcessingUpload(false);
            dispatch({
              type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
              payload: {
                videoUploadPercentage: 0,
                videoprocessingUpload: false,
                isVideoUploading: "NO",
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (preSignedurl === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else {
          setVideoUploadPercentage(0);
          setVideoProcessingUpload(false);
          dispatch({
            type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
            payload: {
              videoUploadPercentage: 0,
              videoprocessingUpload: false,
              isVideoUploading: "NO",
            },
          });
          dispatch({
            type: "COVEREXIST",
            payload: {
              isThereCover: false,
              URL: "",
            },
          });
        }
      });
  };

  const getPresignedUrl = async (apiName, data, pickedFile, fileExttype) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, user.token);
      if (res.data.status === 200) {
        if (fileExttype === "cover") {
          setCoverUrl(res.data.body.fileUrl);
          setCoverUpload(res.data.body.fileUrl);
        } else if (fileExttype === "video") {
          setUplaodVideoUrl(res.data.body.fileUrl);
        } else if (fileExttype === "cover1") {
          setCoverUrl(res.data.body.fileUrl);
          setCover1(res.data.body.fileUrl);
        } else if (fileExttype === "cover2") {
          setCover2(res.data.body.fileUrl);
        } else {
          setAudioUrl(res.data.body.fileUrl);
        }

        uploadToS3(pickedFile, res.data.body.preSignedurl, apiName);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const imagefileInputHandler = async (file, fileName) => {
    var imgeFile = dataURLtoBlob(file);

    if (story.story_type !== "video") {
      fileResiderRef.current.innerHTML = "";
      //let image = await createImage(reader.result)
      // let image = await createImage(file)
      // fileResiderRef.current.appendChild(image)
      fileResiderRef.current.style.width = "100%";
      // image.style.width = '100%'
      fileResiderRef.current.innerHTML = `<img src=${file} alt="thumbnail" style="width:100%"/>`;
    }

    if (story.story_type === "video") {
      document.getElementById("video-poster").poster = file;
    }
    const data = {
      user_id: user.loginUser._id,
      keyname: "coverimages/userFiles/storyFiles",
      filename: fileName,
      fileType: imgeFile.type,
    };

    dispatch({
      type: "COVEREXIST",
      payload: {
        isThereCover: true,
        URL: file,
      },
    });
    getPresignedUrl("getPresignedURLForCoverUpload", data, imgeFile, "cover");
    setUploadedCoverImage({ url: file, blob: imgeFile, fileName: fileName });
  };
  const selectCoverImageForVideo = (imageType, event) => {
    let data = {};

    document
      .querySelectorAll(".thumbnail-img-selected-border")
      .forEach((div) => {
        div.classList.remove("thumbnail-img-selected-border");
      });

    event.currentTarget.childNodes[0].classList.add(
      "thumbnail-img-selected-border",
    );

    if (imageType === "uploadImage") {
      setCoverUrl(coverUpload);
    }

    if (imageType === "screenshot1") {
      setCoverUrl(cover1);
    }

    if (imageType === "screenshot2") {
      setCoverUrl(cover2);
    }
  };

  const sendStory = async () => {
    if (language === "0") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet language",
        "Red",
      );
      return;
    }

    let categoryAdded = category;
    const user_id = user.loginUser._id;
    if (category === "0") {
      if (newCategory !== "") {
        try {
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

          const data = {
            category_name: newCategory,
          };

          const res = await getDataPostWithOutHeader(url, data);
          if (res.data.status === 200) {
            setShowAddCategory(false);
            setCategory(res.data.body._id);
            categoryAdded = res.data.body._id;
          }
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        alert("please enter category");
        return;
      }
    }
    const token = user.token;
    if (!storyTitle) {
      responseMessageDispaly(errormesseageRef, "Please enter title^", "Red");
      return;
    }
    if (!storyDesc) {
      responseMessageDispaly(errormesseageRef, "Please enter story*", "Red");
      return;
    }
    const data = {
      story_id: story._id,
      user_id: user_id,
      category_id: categoryAdded,
      story_language_id: language,
      story_title: storyTitle,
      story_text: storyDesc,
      story_type: storyType,
      cover1: cover1,
      cover2: cover2,
      tags: tags,
      profile_id: profileID,
    };
    if (storyType === "video_url") {
      if (!videUrl) {
        responseMessageDispaly(
          errormesseageRef,
          "Please enter video link*",
          "Red",
        );
        return;
      }
      data.story_url = videUrl;
      data.cover = coverUrl;
    }
    if (storyType !== "video_url") {
      data.cover = coverUrl;
    }
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let urlEdit =
      story.story_type === "stafs"
        ? `${url}/api/userProfile/editExpert`
        : `${url}/api/userProfile/editUserStory`;
    axios
      .post(urlEdit, data, options)
      .then((res) => {
        // errormesseageRef.current.innerHTML = "Success ";
        // errormesseageRef.current.style.color = "green ";
        // setStoryType("");
        // setStoryTitle("");
        // setStoryDesc("");
        // //setLanguage("0");
        // setVideoUrl("");
        // setCoverUrl("");
        // setCoverUpload("");
        // setCover1("");
        // setCover2("");
        // setScreenShot1({ url: defaultThumbmail, blob: "", fileName: "" });
        // setScreenShot2({ url: defaultThumbmail, blob: "", fileName: "" });
        // setUploadedCoverImage({ url: "", blob: "", fileName: "" });
        getStories(1);
        //document.getElementById(res.data.body.subscription_id).click()
        //onModelClose();
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        toast.error("System Failed");
      });
  };

  const getStories = async (storyPage) => {
    try {
      if (user.loginUser._id) {
        let storyKind = "userstories";
        if (menuType === "STORIES") {
          storyKind = "userstories";
        } else {
          storyKind = "userstafs";
        }

        let getStoryUrl =
          story.story_type === "stafs"
            ? `${url}/api/userProfile/getUserExperts?page=${storyPage}&user_id=${user.loginUser._id}`
            : `${url}/api/userProfile/getUserStories?page=${storyPage}&user_id=${user.loginUser._id}`;

        const response = await fetch(getStoryUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const responseData = await response.json();

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          } else {
            if (storyPage > 1) {
              toast.error("Something Went Wrong");
            } else {
              toast.error("Something Went Wrong");
            }
            //throw new Error(responseData.message || 'something went wrong')
          }
        } else {
          if (storyPage > 1) {
            dispatch({
              type: "sub_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({ type: "sub_group_stories", payload: responseData.body });
          }
          document.getElementById("story_box").scrollTop = 0;
        }
      }
    } catch (err) {
      if (storyPage > 1) {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
        console.log(err);
      }
    }
  };

  const selectStoryType = (event, type) => {
    document.querySelectorAll(".header-wrapper-each").forEach((div) => {
      div.style.backgroundColor = "#fff";
    });

    event.currentTarget.style.background = "#dbdbdb";
    setStoryType(type);
  };

  const AddCategory = async () => {
    if (language === "0") {
      toast.error("Please Select Langauge");
      return;
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

      const data = {
        category_name: newCategory,
      };

      const res = await getDataPostWithOutHeader(url, data);
      if (res.data.status === 200) {
        setNewCategory("");
        setShowAddCategory(false);
        setCategory(res.data.body._id);
        if (categoryRef) categoryRef.current.value = res.data.body._id;
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const onClose = () => {
    onModelClose();
  };

  const deleteStory = async () => {
    let url = "";
    if (story.story_type === "stafs") {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/deleteExpert`;
    } else {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/deleteStory`;
    }

    const data = {
      story_id: story._id,
      user_id: user.loginUser._id,
      stafType: story.stafType,
    };

    const res = await getDataPostWithHeader(url, data, user.token);
    if (res.data.status === 200) {
      if (stafType === "CV") {
        setCVAddedAlready(false);
      }
      onClose();
      getStories(1);
    }
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <Model show={showEditStoryModel} onCancel={onModelClose}>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ height: "550px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={
              story.aspectRatio
                ? story.aspectRatio.split(":")[0] /
                  story.aspectRatio.split(":")[1]
                : 16 / 9
            }
            ratio={story.aspectRatio ? story.aspectRatio : "16:9"}
            objectFit="horizontal-cover"
          />
        </div>
      </Modal>

      <div
        className="container-box-model"
        style={{
          background: "#fff",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #dbdbdb",
            padding: "15px",
          }}
        >
          <div ref={errormesseageRef}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <AiOutlineClose
              onClick={onClose}
              className="cursor-pointer"
              size={20}
            />
            <div style={{ cursor: "pointer", position: "relative" }}>
              <BsThreeDotsVertical
                onClick={() => {
                  menuBoxRef.current.style.display = "block";
                }}
              />

              <div className="menu-box-general display-none" ref={menuBoxRef}>
                <div className="menu-header">
                  <AiOutlineClose
                    className="cursor-pointer"
                    onClick={() => {
                      menuBoxRef.current.style.display = "none";
                    }}
                  />
                </div>
                <RiDeleteBin6Line size={20} onClick={deleteStory} />
              </div>
            </div>
          </div>
        </div>
        <div className="row-model pt-2">
          <div className="col-md-12">
            <div className="row">
              {isFromMobile && (
                <div className="col-md-5 mt-4">
                  <div>
                    {storyType === "video" && (
                      <div className="video-warapper-ratio-adujestment">
                        <video
                          width="100%"
                          controls
                          src={story.story_url}
                          height="100%"
                          ref={videoTempRef}
                          id="video-poster"
                          poster={coverUrl}
                          className="video-whereplayer-shows"
                        >
                          Your browser does not support the video tag.
                        </video>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef2}
                        >
                          Your browser does not support the video tag.
                        </video>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef3}
                        >
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}

                    {(storyType === "text" ||
                      storyType === "audio" ||
                      storyType === "image" ||
                      storyType === "stafs") && (
                      <>
                        {story.thumbnail_url === "" ? (
                          <div className="thumbnail-img-border mt-2 cursor-pointer">
                            {coverUrl === "" && (
                              <div
                                className="uploadCover"
                                onClick={openProfileHandler}
                                style={{ height: "150px" }}
                              >
                                <AiOutlineUpload />
                                <div>Upload thumbnail</div>
                              </div>
                            )}
                            <div ref={fileResiderRef}></div>
                          </div>
                        ) : (
                          <>
                            <div ref={fileResiderRef}>
                              <img
                                style={{
                                  width: "100%",
                                }}
                                src={coverUrl}
                                alt="im-story"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = defaultThumbmail;
                                }}
                              />
                            </div>
                            <span
                              className="mt-2 text-info cursor-pointer"
                              style={{ borderBottom: "1px solid" }}
                              onClick={openProfileHandler}
                            >
                              Change Cover
                            </span>
                          </>
                        )}
                      </>
                    )}

                    {storyType === "audio" && story.story_url !== "" && (
                      <div ref={audioResiderRef} className="mt-2">
                        <audio controls>
                          <source src={story.story_url} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                      </div>
                    )}

                    {storyType === "video_url" && (
                      <ReactPlayer
                        url={videUrl}
                        controls
                        width="100%"
                        height="unset"
                      />
                    )}

                    {storyType === "video_url" && (
                      <>
                        <div className="row-model">
                          <div className="col-md-6  mt-2 cursor-pointer">
                            <div
                              className="uploadCover thumbnail-img-border"
                              onClick={openProfileHandler}
                            >
                              <AiOutlineUpload />
                              <div>Upload thumbnail</div>
                            </div>
                          </div>
                          <div className="col-md-6  mt-2 cursor-pointer">
                            <div ref={fileResiderRef}>
                              {story.thumbnail_url === "" ? (
                                <img
                                  className="width-100 cursor-pointer"
                                  src={`https://img.youtube.com/vi/${getId(
                                    videUrl,
                                  )}/maxresdefault.jpg`}
                                  alt="im-story"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defualtImg;
                                  }}
                                />
                              ) : (
                                <img
                                  className="width-100 cursor-pointer"
                                  src={story.thumbnail_url}
                                  alt="im-story"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defualtImg;
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {videUrl !== "" && storyType === "video_url" && (
                      <div style={{ position: "relative" }}>
                        {!showvideoUrlLink && (
                          <BsThreeDotsVertical
                            onClick={() => {
                              menuBoxBioRef.current.style.display = "block";
                            }}
                            size={20}
                            style={{
                              marginRight: -4 + "px",
                              border: "1px solid",
                              borderRadius: "50%",
                              padding: "2px",
                              float: "right",
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                          />
                        )}
                        <div
                          className="menu-box-bio display-none"
                          style={{
                            position: "absolute",
                            right: "-4px",
                            top: "5px",
                          }}
                          ref={menuBoxBioRef}
                        >
                          <div className="menu-header">
                            <AiOutlineClose
                              className="cursor-pointer"
                              onClick={() => {
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            />
                          </div>
                          <div
                            className="menu-line cursor-pointer"
                            onClick={() => {
                              openProfileHandler();
                              menuBoxBioRef.current.style.display = "none";
                            }}
                          >
                            Select cover
                          </div>
                          <div
                            className="menu-line cursor-pointer"
                            onClick={() => {
                              setShowvideoUrlLink(true);
                              menuBoxBioRef.current.style.display = "none";
                            }}
                          >
                            Replace the Youtube link
                          </div>
                        </div>
                      </div>
                    )}

                    {<div style={{ clear: "both" }}></div>}

                    {storyType === "video_url" && showvideoUrlLink && (
                      <div className="mt-2 text-box">
                        <input
                          type="text"
                          id="Title"
                          name="Title"
                          placeholder="Enter Your Youtube Link"
                          className="story-title"
                          value={videUrl}
                          onChange={(e) => {
                            matchYoutubeUrl(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {storyType === "video" && (
                      <div className="row-model">
                        <div className="col-md-5  mt-2 cursor-pointer">
                          <div
                            className="uploadCover thumbnail-img-border"
                            onClick={openProfileHandler}
                          >
                            <AiOutlineUpload />
                            <div>Upload thumbnail</div>
                          </div>
                        </div>
                        <div
                          className="col-md-5  mt-2 cursor-pointer"
                          onClick={(e) => {
                            selectCoverImageForVideo("screenshot1", e);
                          }}
                        >
                          <img
                            src={cover1}
                            style={{ width: "100%" }}
                            alt="youtubelink"
                          />
                        </div>
                        {/* <div
                          className="col-md-4  mt-2 cursor-pointer"
                          onClick={(e) => {
                            selectCoverImageForVideo("screenshot2", e);
                          }}
                        >
                          <img
                            src={cover2}
                            style={{ width: "100%" }}
                            alt="youtubelink"
                          />
                        </div> */}
                      </div>
                    )}

                    {videoUploadPercentage > 0 && (
                      <div className="row mt-3">
                        <div className="col-md-12 pt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-success progress-bar-striped"
                              role="progressbar"
                              style={{
                                width: `${videoUploadPercentage}%`,
                                height: "100%",
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {videoUploadPercentage}%
                            </div>
                          </div>
                        </div>
                        {!videoprocessingUpload && (
                          <div className="col-md-12 pt-1">
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => cancelUpload()}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Abort Uploading
                              <AiOutlineClose
                                className="cursor-pointer"
                                size={20}
                              />
                            </span>
                          </div>
                        )}
                        {videoprocessingUpload && (
                          <div className="col-md-12 pt-1 loading">
                            Processing...
                          </div>
                        )}
                      </div>
                    )}

                    {imageUploadPercentage > 0 && storyType === "image" && (
                      <div className="row mt-3">
                        <div className="col-md-12 pt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-success progress-bar-striped"
                              role="progressbar"
                              style={{
                                width: `${imageUploadPercentage}%`,
                                height: "100%",
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {imageUploadPercentage}%
                            </div>
                          </div>
                        </div>
                        {!imageprocessingUpload && (
                          <div className="col-md-12 pt-1">
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => cancelUpload()}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Abort Uploading
                              <AiOutlineClose
                                className="cursor-pointer"
                                size={20}
                              />
                            </span>
                          </div>
                        )}
                        {imageprocessingUpload && (
                          <div className="col-md-12 pt-1 loading">
                            Processing...
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-md-7">
                {menuType === "STAFS" && story.stories && (
                  <div className="border-bottom-white mt-3">
                    {profiles.map((list, i) => {
                      if (
                        list.language_id._id ===
                        story.stories[0].story_language_id
                      ) {
                        return (
                          <div
                            key={i}
                            id={`lang_list_${i}`}
                            className="profile-bio-tab ml-2 language-select-border"
                          >
                            {list.language_id.nativeName}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            id={`lang_list_${i}`}
                            className="profile-bio-tab ml-2"
                          >
                            {list.language_id.nativeName}
                          </div>
                        );
                      }
                    })}

                    <div
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      className="tooltip"
                      //onClick={selectLanguage}
                    >
                      <span style={{ fontSize: "20px" }}>+</span>
                      <span
                        style={{ fontSize: "10px" }}
                        //ref={createProfileRefMenu}
                        className="tooltiptext"
                      >
                        Add Profiles, To see more language here
                      </span>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                )}

                {/* {menuType === 'STORIES' &&
                  story.stories &&
                  user.loginUser.accounttype === '2' && (
                    <div className='border-bottom-white mt-3'>
                      {profiles.map((list, i) => {
                        if (
                          list.language_id._id ===
                          story.stories[0].story_language_id
                        ) {
                          return (
                            <div
                              key={i}
                              id={`lang_list_${i}`}
                              className='profile-bio-tab ml-2 language-select-border'
                              onClick={(e) => {
                                langaugeHandler(list.language_id, i, e)
                              }}
                            >
                              {list.language_id.nativeName}
                            </div>
                          )
                        } else {
                          return (
                            <div
                              key={i}
                              id={`lang_list_${i}`}
                              className='profile-bio-tab ml-2'
                              onClick={(e) => {
                                langaugeHandler(list.language_id, i, e)
                              }}
                            >
                              {list.language_id.nativeName}
                            </div>
                          )
                        }
                      })}

                      <div
                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                        className='tooltip'
                        //onClick={selectLanguage}
                      >
                        <span style={{ fontSize: '20px' }}>+</span>
                        <span
                          style={{ fontSize: '10px' }}
                          //ref={createProfileRefMenu}
                          className='tooltiptext'
                        >
                          Add Profiles, To see more language here
                        </span>
                      </div>

                      <div className='clearfix'></div>
                    </div>
                  )} */}

                {menuType === "STORIES" && (
                  <>
                    {profiles.length > 1 ? (
                      <select
                        onChange={(e) => {
                          langaugeSelector(
                            e.target.id,
                            e.target.value,
                            e.target.selectedIndex - 1,
                            profiles,
                          );
                        }}
                        value={language}
                        ref={langaugeRef}
                        className="form-control mt-4"
                      >
                        <option value="0">Select Langauge</option>
                        {profiles.map((list, index) => {
                          return (
                            <option
                              key={list._id}
                              id={list.language_id.code}
                              value={list.language_id._id}
                            >
                              {list.language_id.nativeName}
                            </option>
                          );
                        })}
                        {/* {categories.length > 0 && (
                          <option value="00">All Language</option>
                        )} */}
                      </select>
                    ) : (
                      <div className="text-box" style={{ padding: "7px" }}>
                        {profiles[0].language_id.nativeName}
                      </div>
                    )}
                  </>
                )}

                <div style={{ position: "relative" }}>
                  <div class="mt-4 popup-form-area">
                    <div class="popup-lbl">Category</div>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter new category"
                      value={newCategory}
                      onChange={(e) =>
                        categgoryBasedOnLangaugeHandler(e.target.value)
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          setcategories([]);
                        }
                      }}
                    />
                  </div>
                  {categories.length > 0 && (
                    <CatgeorySearchBox
                      categories={categories}
                      selecetCategory={selecetCategory}
                    />
                  )}
                </div>

                <div className="mt-4 popup-form-area">
                  <div class="popup-lbl">Title(required):</div>
                  <input
                    type="text"
                    id="Title"
                    name="Title"
                    placeholder="Title"
                    className="form-control mt-2"
                    value={storyTitle}
                    onChange={(e) => {
                      setStoryTitle(e.target.value);
                    }}
                  />
                </div>

                <div className="mt-4">
                  <RichText data={storyDesc} setData={setStoryDesc} />
                </div>

                {/* {user.loginUser.accounttype !== "2" && (
                  <div
                    className="mt-4 popup-form-area text-box"
                    style={{ height: "280px" }}
                  >
                    <div class="popup-lbl">Description:</div>

                    <textarea
                      className="story-title text-area-desc h-100"
                      placeholder="Write Post"
                      maxlength="5000"
                      value={storyDesc ? parse(storyDesc) : storyDesc}
                      onChange={(e) => {
                        setStoryDesc(e.target.value);
                      }}
                    ></textarea>
                  </div>
                )} */}

                {storyType === "video" && (
                  <div className="row-model">
                    <div className="col-md-12">
                      {imageUploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${imageUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {imageUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!imageprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {imageprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="mt-3 text-box popup-form-area tag-box">
                  <div class="popup-lbl">Tags:</div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {tags &&
                      tags.length > 0 &&
                      tags.map((tagName, index) => {
                        return (
                          <div key={index} className="m-1 tags">
                            <div className="mr-1">{tagName}</div>
                            <div
                              className="tags-cancel-box cursor-pointer"
                              onClick={() => {
                                setTags(tags.filter((tag, i) => i !== index));
                              }}
                            >
                              <ImCancelCircle size={18} />
                            </div>
                          </div>
                        );
                      })}

                    {tags && tags.length < 25 && (
                      <input
                        type="text"
                        className="story-title text-area-desc"
                        placeholder="Enter coma after each tag"
                        maxlength="100"
                        style={{ width: "30%" }}
                        onKeyUp={(event) => {
                          let newTag = event.target.value.split(",")[0];
                          if (newTag.length < 100) {
                            tagErrorMessegesRef.current.innerHTML = "";
                            if (event.key === "Enter" || event.key === ",") {
                              if (event.target.value !== "") {
                                if (tags.length < 25) {
                                  if (newTag !== "")
                                    if (!tags.includes(newTag)) {
                                      setTags([...tags, newTag]);
                                    }

                                  event.target.value = "";
                                }
                              }
                            }
                          } else {
                            tagErrorMessegesRef.current.innerHTML =
                              "Tag length should be below 100";
                            tagErrorMessegesRef.current.style.color = "red";
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="tag-length-count-box">
                  <div ref={tagErrorMessegesRef}></div>
                  <span>
                    <span className="mr-1">{tags && tags.length}</span>/
                    <span className="ml-1">25 tags</span>
                  </span>
                </div>
              </div>

              {!isFromMobile && (
                <div className="col-md-5 mt-4">
                  <div>
                    {storyType === "video" && (
                      <div className="video-warapper-ratio-adujestment">
                        <video
                          width="100%"
                          controls
                          src={story.story_url}
                          height="100%"
                          ref={videoTempRef}
                          id="video-poster"
                          poster={coverUrl}
                          className="video-whereplayer-shows"
                        >
                          Your browser does not support the video tag.
                        </video>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef2}
                        >
                          Your browser does not support the video tag.
                        </video>

                        <video
                          width="100%"
                          controls
                          src=""
                          height="100%"
                          style={{ display: "none" }}
                          ref={videoTempRef3}
                        >
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}

                    {(storyType === "text" ||
                      storyType === "audio" ||
                      storyType === "image" ||
                      storyType === "stafs") && (
                      <>
                        {story.thumbnail_url === "" ? (
                          <div className="thumbnail-img-border mt-2 cursor-pointer">
                            {coverUrl === "" && (
                              <div
                                className="uploadCover"
                                onClick={openProfileHandler}
                                style={{ height: "150px" }}
                              >
                                <AiOutlineUpload />
                                <div>Upload thumbnail</div>
                              </div>
                            )}
                            <div ref={fileResiderRef}></div>
                          </div>
                        ) : (
                          <>
                            <div ref={fileResiderRef}>
                              <img
                                style={{
                                  width: "100%",
                                }}
                                src={coverUrl}
                                alt="im-story"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = defaultThumbmail;
                                }}
                              />
                            </div>
                            <span
                              className="mt-2 text-info cursor-pointer"
                              style={{ borderBottom: "1px solid" }}
                              onClick={openProfileHandler}
                            >
                              Change Cover
                            </span>
                          </>
                        )}
                      </>
                    )}

                    {storyType === "audio" && story.story_url !== "" && (
                      <div ref={audioResiderRef} className="mt-2">
                        <audio controls>
                          <source src={story.story_url} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                      </div>
                    )}

                    {storyType === "video_url" && (
                      <ReactPlayer
                        url={videUrl}
                        controls
                        width="100%"
                        height="unset"
                      />
                    )}

                    {storyType === "video_url" && (
                      <>
                        <div className="row-model">
                          <div className="col-md-6  mt-2 cursor-pointer">
                            <div
                              className="uploadCover thumbnail-img-border"
                              onClick={openProfileHandler}
                            >
                              <AiOutlineUpload />
                              <div>Upload thumbnail</div>
                            </div>
                          </div>
                          <div className="col-md-6  mt-2 cursor-pointer">
                            <div ref={fileResiderRef}>
                              {story.thumbnail_url === "" ? (
                                <img
                                  className="width-100 cursor-pointer"
                                  src={`https://img.youtube.com/vi/${getId(
                                    videUrl,
                                  )}/maxresdefault.jpg`}
                                  alt="im-story"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defualtImg;
                                  }}
                                />
                              ) : (
                                <img
                                  className="width-100 cursor-pointer"
                                  src={story.thumbnail_url}
                                  alt="im-story"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defualtImg;
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {videUrl !== "" && (
                      <div style={{ position: "relative" }}>
                        {!showvideoUrlLink && (
                          <BsThreeDotsVertical
                            onClick={() => {
                              menuBoxBioRef.current.style.display = "block";
                            }}
                            size={20}
                            style={{
                              marginRight: -4 + "px",
                              border: "1px solid",
                              borderRadius: "50%",
                              padding: "2px",
                              float: "right",
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                          />
                        )}
                        <div
                          className="menu-box-bio display-none"
                          style={{
                            position: "absolute",
                            right: "-4px",
                            top: "5px",
                          }}
                          ref={menuBoxBioRef}
                        >
                          <div className="menu-header">
                            <AiOutlineClose
                              className="cursor-pointer"
                              onClick={() => {
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            />
                          </div>
                          <div
                            className="menu-line cursor-pointer"
                            onClick={() => {
                              openProfileHandler();
                              menuBoxBioRef.current.style.display = "none";
                            }}
                          >
                            Select cover
                          </div>
                          <div
                            className="menu-line cursor-pointer"
                            onClick={() => {
                              setShowvideoUrlLink(true);
                              menuBoxBioRef.current.style.display = "none";
                            }}
                          >
                            Replace the Youtube link
                          </div>
                        </div>
                      </div>
                    )}

                    {<div style={{ clear: "both" }}></div>}

                    {storyType === "video_url" && showvideoUrlLink && (
                      <div className="mt-2 text-box">
                        <input
                          type="text"
                          id="Title"
                          name="Title"
                          placeholder="Enter Your Youtube Link"
                          className="story-title"
                          value={videUrl}
                          onChange={(e) => {
                            matchYoutubeUrl(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {storyType === "video" && (
                      <div className="row-model">
                        <div className="col-md-5  mt-2 cursor-pointer">
                          <div
                            className="uploadCover thumbnail-img-border"
                            onClick={openProfileHandler}
                          >
                            <AiOutlineUpload />
                            <div>Upload thumbnail</div>
                          </div>
                        </div>
                        <div
                          className="col-md-5  mt-2 cursor-pointer"
                          onClick={(e) => {
                            selectCoverImageForVideo("screenshot1", e);
                          }}
                        >
                          <img
                            src={cover1}
                            style={{ width: "100%" }}
                            alt="cover"
                          />
                        </div>
                      </div>
                    )}

                    {videoUploadPercentage > 0 && (
                      <div className="row mt-3">
                        <div className="col-md-12 pt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-success progress-bar-striped"
                              role="progressbar"
                              style={{
                                width: `${videoUploadPercentage}%`,
                                height: "100%",
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {videoUploadPercentage}%
                            </div>
                          </div>
                        </div>
                        {!videoprocessingUpload && (
                          <div className="col-md-12 pt-1">
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => cancelUpload()}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Abort Uploading
                              <AiOutlineClose
                                className="cursor-pointer"
                                size={20}
                              />
                            </span>
                          </div>
                        )}
                        {videoprocessingUpload && (
                          <div className="col-md-12 pt-1 loading">
                            Processing...
                          </div>
                        )}
                      </div>
                    )}

                    {imageUploadPercentage > 0 && storyType === "image" && (
                      <div className="row mt-3">
                        <div className="col-md-12 pt-1">
                          <div className="progress">
                            <div
                              className="progress-bar bg-success progress-bar-striped"
                              role="progressbar"
                              style={{
                                width: `${imageUploadPercentage}%`,
                                height: "100%",
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {imageUploadPercentage}%
                            </div>
                          </div>
                        </div>
                        {!imageprocessingUpload && (
                          <div className="col-md-12 pt-1">
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => cancelUpload()}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Abort Uploading
                              <AiOutlineClose
                                className="cursor-pointer"
                                size={20}
                              />
                            </span>
                          </div>
                        )}
                        {imageprocessingUpload && (
                          <div className="col-md-12 pt-1 loading">
                            Processing...
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-2"></div>
        </div>

        <div className="footer-upload">
          <div>
            {!videoprocessingUpload && videoUploadPercentage > 0 && (
              <>
                <span class="loading">Uplaoding....</span>
                <span> {videoUploadPercentage}%</span>
              </>
            )}
            {videoprocessingUpload && <div class="loading">Processing...</div>}
          </div>

          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className="push-right"
              onClick={() => {
                sendStory();
                onModelClose();
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Model>
  );
};

export default EditStoryModel;
