import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import Button from "@material-ui/core/Button";
import { AiOutlineClose, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { connect } from "react-redux";
import { IoMicOutline } from "react-icons/io5";
import { IoMicCircle } from "react-icons/io5";
import Model from "../../utils/models/DefaultModel";
import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
import media from "../../images/photos.png";
import YTlink from "../../images/youtube.png";
import attachment from "../../images/attachment.png";
import { toast } from "react-toastify";

import {
  selectValueValue,
  responseMessageDispaly,
  dataURLtoBlob,
  videoPickerHandler,
} from "../../utils/sharedfunction";

import useRecorder from "../../utils/hooks/useRecorder";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";
const url = process.env.REACT_APP_BACKEND_URL;

const AddSectionModel = ({
  group_info,
  UserRequiredDropDown,
  showChatModel,
  onModelClose,
  modelType,
  dispatch,
  getSections,
}) => {
  let [audioURL, isRecording, startRecording, stopRecording, recorder] =
    useRecorder();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [file, setFile] = useState({ type: "text" });
  const [showCrop, setShowCrop] = useState(false);
  const languageRef = useRef(null);
  const storyTitleRef = useRef(null);
  const storyRef = useRef(null);
  const errormesseageRef = useRef(null);
  const storyTypeProvideRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const mediaIconsRef = useRef(null);
  const filePickerRef = useRef(null);
  const fileResiderRef = useRef(null);
  const videofileResiderRef = useRef(null);
  const youtubeURLRef = useRef(null);
  const youtubeURL = useRef(null);
  const micRecoredStopRef = useRef(null);
  const micRecoredStartRef = useRef(null);
  const videoPickerRef = useRef(null);
  const videobioUpload = useRef(null);

  const startRecord = () => {
    micRecoredStopRef.current.style.display = "inline-block";
    micRecoredStartRef.current.style.display = "none";
    startRecording();
  };
  const stopRecord = () => {
    micRecoredStopRef.current.style.display = "none";
    micRecoredStartRef.current.style.display = "inline-block";
    stopRecording();
  };

  const addSection = () => {
    const sectionTittle = storyTitleRef.current.value;
    const language = selectValueValue(languageRef.current);
    const category_id = group_info.category_id._id;
    const subscription_id = group_info._id;
    const token = group_info.token;

    if (!sectionTittle) {
      responseMessageDispaly(errormesseageRef, "Please enter title^", "Red");
      return;
    }

    const data = {
      section_title: sectionTittle,
      language_id: language,
      category_id: category_id,
      subscription_id: subscription_id,
    };
    axios
      .post(`${url}/api/userProfile/addSubSections`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUploadPercentage(0);
        setFile({ type: "text" });
        errormesseageRef.current.innerHTML = "Success ";
        errormesseageRef.current.style.color = "green ";
        storyTitleRef.current.value = "";
        getSections();
        //document.getElementById(res.data.body.subscription_id).click()
        onModelClose();
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentage(0);
        responseMessageDispaly(errormesseageRef, "System Failed", "red");
      });
  };

  return (
    <Model show={showChatModel}>
      <div className="modal-container-content" style={{ width: "43%" }}>
        <header
          className="modal__header text-center"
          style={{ padding: "10px" }}
        >
          <h3>
            Add Section
            <AiOutlineClose
              style={{
                float: "right",
                cursor: "pointer",
                marginTop: "4px",
              }}
              onClick={onModelClose}
            />
            <div
              style={{
                display: "inline-block",
                position: "absolute",
                right: "25%",
              }}
              className="cursor-pointer "
            ></div>
          </h3>
        </header>
        <div className="modal__content">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
            <div ref={errormesseageRef}></div>
            <input
              id="story_title"
              style={{ width: "100%" }}
              placeholder="Add Section Title"
              type="text"
              ref={storyTitleRef}
            />
            <div style={{ marginTop: "1rem " }}>
              <select
                id="langauge"
                style={{ width: "150px", marginRight: "10px" }}
                ref={languageRef}
              >
                <option
                  id={group_info.language_id.code}
                  value={group_info.language_id._id}
                >
                  {group_info.language_id.name}
                </option>
              </select>
              <select
                id="storyTypeProvide"
                style={{ width: "150px" }}
                ref={storyTypeProvideRef}
              >
                <option value={group_info.category_id._id}>
                  {group_info.category_id.category_name}
                </option>
              </select>
            </div>
          </div>
        </div>
        <footer className="modal__footer modal-footer-border ">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center ">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className="push-right"
              onClick={addSection}
            >
              ADD Section
            </Button>
            <div className="clearfix"></div>
          </div>
        </footer>
      </div>
    </Model>
  );
};

const MapStateToProps = ({ UserRequiredDropDown }) => {
  return { UserRequiredDropDown };
};

export default connect(MapStateToProps)(AddSectionModel);
