import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsArrowLeftSquare } from "react-icons/bs";
import { getRequestedDataWithHeader } from "../../../functions/editProfile/editProfile";
import { useQuery } from "../../../utils/sharedfunction";
const ViewChannelsList = ({ user }) => {
  let query = useQuery();
  const history = useHistory();
  const [userPorifleLists, setUserPorifleLists] = useState([]);
  useEffect(() => {
    getchannelsList();
  }, []);

  const getchannelsList = async () => {
    const url = `${
      process.env.REACT_APP_BACKEND_URL
    }/api/userProfile/getCountiresUserPorifleLists?country_id=${query.get(
      "country_ID",
    )}`;

    console.log(user);

    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token);
      console.log(res.data.body);
      setUserPorifleLists(res.data.body);
    } catch (err) {
      console.log(err.message);
    }
  };

  const toProfile = (profile_id, user_id) => {
    console.log(profile_id);
    console.log(user_id);
    history.push(
      `/consultant-profile?user_id=${user_id}&profile_id=${profile_id}`,
    );
  };
  return (
    <div className="col-md-7">
      <h3 className="mt-1 mb-3">Channel Lists</h3>
      <span
        className="mb-2 cursor-pointer"
        onClick={() => {
          history.push(`/min`);
        }}
      >
        <BsArrowLeftSquare size={25} /> Back
      </span>
      <table className="table cursor-pointer mt-2">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">created Date</th>
          </tr>
        </thead>
        <tbody>
          {userPorifleLists.length > 0 &&
            userPorifleLists.map((list, indexUser) => {
              console.log(list);
              return (
                <tr
                  key={list._id}
                  onClick={() => toProfile(list.profiles[0]._id, list._id)}
                >
                  <th>{indexUser + 1}</th>
                  <td>{list.login_user_id}</td>
                  <td>
                    {list.createdAt
                      ? new Date(list.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : "NOT Available"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewChannelsList;
