let initialState = {
  sub_groups: [],
}

const SubscriptionGroups = (state = initialState, action) => {
  switch (action.type) {
    case 'sub_groups':
      return { ...state, sub_groups: action.payload }
    default:
      return state
  }
}

export default SubscriptionGroups
