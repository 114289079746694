import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchStory } from "../../functions/story/story";

import { Link } from "react-router-dom";

import defualtImg from "../../images/Read&ShareThumbnail.png";

import HlsPlayer from "../../Components/players/HlsPlayer";
import { getRequestedData } from "../../functions/editProfile/editProfile";

const ViewStoryMin = ({ story_id }) => {
  const dateoptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const [story, setStory] = useState({});
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [loading, setLoading] = useState();
  const [showSignin, setShowSignin] = useState(false);
  const [showDummyCard, setShowDummyCard] = useState(false);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [isFollow, setIsFollow] = useState(false);
  const [isNotifyMeAll, setIsNotifyMeAll] = useState(false);
  const dispatch = useDispatch();
  const { CatStories } = useSelector((state) => ({ ...state }));
  const playerRef = useRef(null);

  useEffect(() => {
    console.log(story_id);
    storyDetails(story_id);
  }, [story_id]);

  const storyDetails = async (story_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}`;

      const res = await getRequestedData(url);

      if (res.data.body.story.story_type === "video_url") {
        console.log(res.data.body.story.story_url);
        const videoId = getId(res.data.body.story.story_url);
        setYoutubeVideoID(videoId);
      }

      setStory(res.data.body.story);

      if (res.data.body.story.story_converted_url !== "") {
        setHLSVIDEOPLAYER(true);
      } else {
        setHLSVIDEOPLAYER(false);
      }
      setIsFollow(res.data.body.isFollow);
      setIsNotifyMeAll(res.data.body.isNotifyMeAll);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            {story._id && story.story_type == "video" && (
              <>
                {story.story_converted_url !== "" &&
                  HLSVIDEOPLAYER &&
                  story.video_encode_processed &&
                  story.video_encode_process_status === "completed" && (
                    <HlsPlayer
                      url={story.story_converted_url}
                      setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                    />
                  )}

                {story.story_url_quality_versions.url_720 !== "" &&
                  !HLSVIDEOPLAYER &&
                  story.video_encode_processed && (
                    <video
                      width="100%"
                      controls
                      src={story.story_url_quality_versions.url_720}
                      poster={story.thumbnail_url}
                      autoPlay
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}

                {!story.video_encode_processed && (
                  <video
                    width="100%"
                    controls
                    src={story.story_url}
                    poster={story.thumbnail_url}
                    autoPlay
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {story.story_converted_url === "" &&
                  story.story_url_quality_versions.url_720 === "" &&
                  story.video_encode_processed && (
                    <video
                      controls
                      style={{ width: "100%", borderRadius: "10px" }}
                      poster={story.thumbnail_url}
                      autoPlay
                    >
                      <source type="video/mp4" src={story.story_url} />
                    </video>
                  )}

                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#737373",
                  }}
                >
                  {story.read.count} Views -{" "}
                  {new Date(story.createdAt).toLocaleDateString(
                    "en-US",
                    dateoptions,
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && story.story_type == "text" && (
              <>
                {story.thumbnail_url !== "" && (
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                    }}
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                )}
                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#737373",
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    "en-US",
                    dateoptions,
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && story.story_type == "image" && (
              <>
                <img
                  style={{
                    objectFit: "cover",
                    width: "100%",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#737373",
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    "en-US",
                    dateoptions,
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && story.story_type == "audio" && (
              <>
                <audio
                  controls
                  src={story.story_url}
                  className="story-margin "
                  style={{ width: "100%" }}
                ></audio>
                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#737373",
                  }}
                >
                  {story.read.count} Views -{" "}
                  {new Date(story.createdAt).toLocaleDateString(
                    "en-US",
                    dateoptions,
                  )}
                </div>
                <hr />
              </>
            )}
            {story._id && story.story_type == "video_url" && (
              <>
                <iframe
                  width="100%"
                  height="604"
                  src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#737373",
                  }}
                >
                  {story.read.count} Views -{" "}
                  {new Date(story.createdAt).toLocaleDateString(
                    "en-US",
                    dateoptions,
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && (
              <div
                className="mt-2"
                style={{
                  fontSize: "14px",
                }}
              >
                <div>
                  <Link
                    to={`/consultant-profile?user_id=${story.user_id._id}&profile_id=${story.profile_id}`}
                    style={{ color: "black" }}
                  >
                    <img
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        float: "left",
                      }}
                      src={story.user_id.imageUrl}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                    <div
                      style={{
                        fontWeight: "500",
                        color: "black",
                        marginLeft: "10px",
                        float: "left",
                      }}
                    >
                      {story.user_id.user_name}
                    </div>
                  </Link>
                  <div style={{ clear: "both" }}></div>
                </div>
                <div
                  className={`${
                    story.story_language_id.code === "ar"
                      ? "text-right"
                      : "text-left"
                  } mt-3`}
                >
                  {story.story_text}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewStoryMin;
