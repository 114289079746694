import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import profileDefualt from "../../images/default.jpeg";

import parse from "html-react-parser";

import "./storyCard.css";

const weburl = process.env.REACT_APP_BACKEND_URL;

const CourseCard2 = ({ course, userid }) => {
  let descSmall = parse(course.group_description.substring(0, 150));
  let descFull = parse(course.group_description);

  return (
    <>
      <div className="card-box">
        <Link to={`/course/${course.group_name}/${course._id}`}>
          <div
            className="image-warapper-ratio-adujestment"
            style={{ backgroundColor: "black", borderRadius: "10px" }}
          >
            <img
              className="image-whereplayer-shows"
              style={{
                objectFit: "cover",
              }}
              src={course.group_video_thumbnail_url}
              alt="im-course"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = defualtImg;
              }}
            />
          </div>
        </Link>
        <div style={{ display: "flex" }}>
          {userid === course.user_id._id ? (
            <Link to={`/profile`}>
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={course.user_id.imageUrl}
                alt="im-course-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            </Link>
          ) : (
            <Link
              to={`/consultant-profile?user_id=${course.user_id._id}&profile_id=${course.profile_id}&fm=course`}
            >
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={course.user_id.imageUrl}
                alt="im-course-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            </Link>
          )}
          <Link to={`/course/${course.group_name}/${course._id}`}>
            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{parse(course.group_name)}</div>
            </div>
          </Link>
        </div>
        <Link to={`/course/${course.group_name}/${course._id}`}>
          <div
            className={`${
              course.language_id.code === "ar" ? "text-right" : "text-left"
            } description-box ml-2 mt-2`}
          >
            {course.group_description &&
              course.group_description.length <= 50 &&
              descFull}
            {course.group_description &&
              course.group_description.length > 50 &&
              descSmall + " ...."}
          </div>
        </Link>
      </div>
    </>
  );
};

export default CourseCard2;
