import React, { useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import "../css/profileNavbar.css";
import "../navbar/sidebar.css";
import { BsThreeDotsVertical, BsArrowLeft } from "react-icons/bs";
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";
import {
  HomeOutlined,
  ProfileOutlined,
  AppstoreOutlined,
  MessageOutlined,
  FundOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";
import profileDefualt from "../images/default.jpeg";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../images/sessionway.svg";
import EditProfile from "../Components/navbar/EditProfile";
import Model from "../utils/models/DefaultModel";
import SideBar from "./SideBar";
const url = process.env.REACT_APP_BACKEND_URL;

const ProfileNavbar = ({
  props: {
    profileImage,
    target,
    token,
    user_id,
    homeNavbarcollapse,
    setHomeNavbarcollapse,
  },
  socket,
}) => {
  const navbarMenuRef = useRef(null);

  useEffect(() => {
    chatNotificationHandler();
  }, []);

  function openNav() {
    document.getElementById("mySidenav").style.width = "25%";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  const chatNotificationHandler = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/messageNotification?reciverId=${user_id}`;
    try {
      let res = await getRequestedDataWithHeader(url, token);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const navbarMenuHandler = () => {
    // navbarMenuRef.current.style.display = 'block'
    let isDisplay = navbarMenuRef.current.style.display;
    if (isDisplay === "block") {
      navbarMenuRef.current.style.display = "none";
    } else {
      navbarMenuRef.current.style.display = "block";
    }
  };

  const logoutHandler = async () => {
    navbarMenuRef.current.style.display = "none";
    try {
      const response = await fetch(`${url}/api/users/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: user_id,
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        if (responseData.status === 401) {
          socket.emit("userOffline", { userId: user_id });
          localStorage.removeItem("userDataSessionway");
          window.location.replace("/");
        } else {
          throw new Error(responseData.message || "something went wrong");
        }
      } else {
        socket.emit("userOffline", { userId: user_id });
        localStorage.removeItem("userDataSessionWay");
        window.location.replace("/");
        console.log(responseData.body);
      }
    } catch {}
  };

  return (
    <>
      {/* <SideBar
        closeNav={closeNav}
        logoutHandler={logoutHandler}
        token={token}
        user_id={user_id}
      /> */}
      <div
        className={`profile_header_area ${
          target === "chat" && "d-md-block d-none d-sm-block"
        }`}
      >
        <div className="container-fluid">
          <div
            className="float-left cursor-pointer"
            style={{ padding: "5px 10px 0px 10px" }}
          >
            <span
              className="d-md-inline d-none d-sm-inline top-menu-icon"
              onClick={() => {
                setHomeNavbarcollapse(!homeNavbarcollapse);
              }}
              style={{
                cursor: "pointer",
                marginRight: "20px",
              }}
            >
              <MenuOutlined style={{ fontSize: "18px" }} />
            </span>
            <span>
              <Link
                to="/"
                style={{
                  color: "black",
                }}
              >
                <img src={Logo} alt="React Logo" style={{ width: "120px" }} />
              </Link>
            </span>
          </div>
          {/* <div
            className={target === 'profile' ? 'border-bottom' : 'float-left'}
          >
            <Link to='/profile'>
              <img
                id='profile_pic_small'
                className='profile-image'
                src={url + '/' + profileImage}
                onError={(e) => {
                  e.target.onError = null
                  e.target.src = profileDefualt
                }}
                alt='profile'
              />
            </Link>
          </div>
          <div
            className={target === 'explore' ? 'border-bottom' : 'float-left'}
          >
            <Link to='/'>
              <div
                className='profileNavBarList push-left '
                onClick={() => {
                  dispatch({ type: 'EMPTY_SEARCH_USER' })
                }}
              >
                Experts
              </div>
            </Link>
          </div>

          <div className={target === 'chat' ? 'border-bottom' : 'float-left'}>
            <Link to='/chat'>
              <div className='profileNavBarList push-left '>Chat</div>
              <sup
                id='message_notification_chat'
                style={{
                  color: 'Red',
                  top: '2px',
                  left: '-5px',
                  fontWeight: 'bold',
                }}
              ></sup>
            </Link>
          </div> */}
        </div>
        <div className="push-right profileNavBarList">
          <BsThreeDotsVertical
            onClick={() => {
              navbarMenuHandler();
            }}
          />

          <div className="dropdown-content-menu" ref={navbarMenuRef}>
            <EditProfile
              navbarMenuRef={navbarMenuRef}
              logoutHandler={logoutHandler}
              user_id={user_id}
              token={token}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileNavbar;
