import React, { useEffect } from "react";
import SectionType from "./SectionType";
import { useQuery } from "../../utils/sharedfunction";
import IntroImageVideo from "./IntroImageVideo";

const Section = ({
  menu,
  setMenu,
  title,
  setTitle,
  user,
  language,
  setProfileId,
  langaugeId,
  setLanguage,
  setLangaugeId,
  setCategory,
  category,
  description,
  setDescription,
  saveSubscriptionGroupHandler,
  setCurrency,
  currency,
  price,
  setPrice,
  groupDeatils,
  setSectionType,
  sectionType,
  inputFields,
  setInputFields,
  inputFieldsPreRequired,
  setInputFieldsPreRequired,
  setNext,
  setVideoFile,
  videoFile,
  getPresignedUrl,
  videoUploadPercentage,
  videoProcessingUpload,
  setNewCategory,
  newCategory,
}) => {
  let params = useQuery();
  useEffect(() => {
    if (params.get("type") === "COURSES") {
      setSectionType(1);
      setMenu("Section Course");
    } else {
      setSectionType(0);
      setMenu("Stories Course");
    }
  }, []);
  const menuSelect = (e) => {
    document.querySelectorAll(".checkbox").forEach((checkbox) => {
      checkbox.checked = false;
    });
    e.target.checked = true;
    if (e.target.name === "Section Course") {
      setSectionType(1);
    } else {
      setSectionType(0);
    }
  };

  const moveToLanding = () => {
    document.querySelectorAll(".checkbox").forEach((checkbox) => {
      if (checkbox.checked) {
        setMenu(checkbox.value);
      }
    });
  };

  return (
    <div
      style={{
        maxWidth: "1028px",
        width: "100%",
        float: "left",
        background: "#fff",
        boxShadow: "0 2px 4px rgba(0,0,0,.08),0 4px 12px rgba(0,0,0,.16)",
      }}
    >
      <div
        style={{
          padding: "2.4rem 4.8rem 1.4rem  4.8rem",
          borderBottom: "1px solid #d1d7dc",
          fontWeight: "700",
          fontSize: "1.4rem",
        }}
      >
        {menu}

        {menu === "Course Type" && (
          <div style={{ float: "right" }}>
            <button
              className="btn btn-primary"
              style={{ background: "#13A4D7" }}
              onClick={moveToLanding}
            >
              Next
            </button>
          </div>
        )}
      </div>

      {menu === "Course Type" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "600",
            fontSize: "1rem",
          }}
        >
          <div className="p-2 m-1">
            <input
              type="checkbox"
              className="checkbox"
              name="Section Course"
              value="Section Course"
              onChange={menuSelect}
            />
            <span className="ml-1">Section Course</span>
          </div>
          <div className="p-2 m-1">
            <input
              type="checkbox"
              className="checkbox"
              name="Stories Course"
              value="Stories Course"
              onChange={menuSelect}
            />
            <span className="ml-1" for="StoriesCourse">
              Stories Course
            </span>
          </div>
        </div>
      )}

      {(menu === "Section Course" || menu === "Stories Course") && (
        <IntroImageVideo
          user={user}
          groupID={123}
          setVideoFile={setVideoFile}
          videoFile={videoFile}
          getPresignedUrl={getPresignedUrl}
          videoUploadPercentage={videoUploadPercentage}
          videoProcessingUpload={videoProcessingUpload}
        />
      )}

      {videoUploadPercentage > 0 && videoUploadPercentage <= 100 && (
        <div class="blink_me mt-4 text-danger" style={{ textAlign: "center" }}>
          Please do not close the brower until its upload and process
        </div>
      )}

      {(menu === "Section Course" || menu === "Stories Course") && (
        <SectionType
          inputFields={inputFields}
          setInputFields={setInputFields}
          inputFieldsPreRequired={inputFieldsPreRequired}
          setInputFieldsPreRequired={setInputFieldsPreRequired}
          setNext={setNext}
          title={title}
          setTitle={setTitle}
          user={user}
          language={language}
          setProfileId={setProfileId}
          langaugeId={langaugeId}
          setLanguage={setLanguage}
          setLangaugeId={setLangaugeId}
          setCategory={setCategory}
          category={category}
          description={description}
          setDescription={setDescription}
          setCurrency={setCurrency}
          currency={currency}
          setPrice={setPrice}
          price={price}
          setNewCategory={setNewCategory}
          newCategory={newCategory}
        />
      )}
      {(menu === "Section Course" || menu === "Stories Course") && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "50px",
          }}
        >
          {videoUploadPercentage > 0 && videoUploadPercentage <= 100 && (
            <div
              className="ml-2 text-danger"
              style={{
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              Wait untill video is upload and process
            </div>
          )}
          {videoUploadPercentage > 0 && videoUploadPercentage <= 100 && (
            <button className="btn btn-light mr-4" disabled>
              Next
            </button>
          )}
          {videoUploadPercentage === 0 && !videoProcessingUpload && (
            <button
              className="btn btn-dark mr-4"
              onClick={() => saveSubscriptionGroupHandler("create")}
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Section;
