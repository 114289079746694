import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { HiCreditCard } from "react-icons/hi2";
import { useHistory } from "react-router-dom";
import { BsFilePdfFill, BsFillAlarmFill } from "react-icons/bs";
import { RiCalendarTodoLine } from "react-icons/ri";
import { FaUserGraduate, FaUser } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { PiHandCoinsFill } from "react-icons/pi";
import { IoDocument } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import axios, { CancelToken, isCancel } from "axios";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
  getRequestedData,
  getDataPostWithOutHeader,
} from "../functions/editProfile/editProfile";
import { useQuery, createImage } from "../utils/sharedfunction";
import "../css/chat2.css";
import profile from "../images/default.jpeg";
import LoadingCard from "../Components/cards/LoadingCard";
import KNETLogo from "../images/KNETLogo.svg";
import ModelV from "../utils/models/Modal";
import SideBar from "../Components/SideBar";
import search from "../images/search.svg";
import more from "../images/more.svg";
import photo from "../images/photo.svg";
import microphone from "../images/microphone.svg";
import file from "../images/file.svg";
import filePDF from "../images/pdf-sq.png";
import videocall from "../images/videocall.svg";
import videocall2 from "../images/video.svg";
import checkedImg from "../images/okay.png";
import phone from "../images/phone.svg";
import phone2 from "../images/phone2.svg";
import clip from "../images/clip.svg";
import attachment from "../images/attachment.svg";
import attchfile from "../images/attchfile.svg";
import attachaudio from "../images/attachaudio.svg";
import attchimage from "../images/attchimage.svg";
import attachmap from "../images/attachmap.svg";
import camera from "../images/camera.svg";
import videoIcon from "../images/video.svg";
import back from "../images/back-svgrepo-com.svg";
import playcircle from "../images/playcirclemajor-svgrepo-com.svg";
import defaultThumbmail from "../images/default-thumbnail.png";
import SideBarChatMenus from "../Components/chat/SideBarChatMenus";
import SidebarRemarksToDo from "../Components/chat/SidebarRemarksToDo";
import FooterMenu from "../navbar/FooterMenu";
import Model from "../utils/models/DefaultModel.js";
import ModelCardPay from "../utils/models/ModelNoDesign.js";

let roomOne = "";
let roomTwo = "";
let reciverId = "";
var load;
const weburl = process.env.REACT_APP_BACKEND_URL;

const Chat2 = (props) => {
  const history = useHistory();
  const dispatch = useDispatch({});
  const { ROOMCHAT } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [currencyLoader, setCurrencyLoader] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [askMoneyModel, setAskMoneyModel] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [chatedUsers, setChatedUsers] = useState([]);
  const [searchChatedUsers, setSearchChatedUsers] = useState([]);
  const [chatLanguages, setChatLanguages] = useState();
  const [searchChatedUsersTotalPage, setSearchChatedUsersTotalPage] = useState(
    [],
  );
  const [chatedUsersTotalPage, setChatedUsersTotalPage] = useState([]);
  const [chatedUsersPageNo, setChatedUsersPageNo] = useState(1);
  const [chatSessionID, setChatSessionID] = useState("");
  const [searchChatedUsersPageNo, setSearchChatedUsersPageNo] = useState(1);
  const [howManyPaymentRecived, setHowManyPaymentRecived] = useState(0);
  const [loadingUser, setLoadingUser] = useState(false);
  const [sidenavShow, setSidenavShow] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [remarkTodoShow, setRemarkTodoShow] = useState(false);
  const [typer, setIsTyper] = useState({ isTyping: false, userId: "" });
  const [showMedia, setshowMedia] = useState({
    isShowMedia: false,
    url: "",
    type: "",
  });

  const [isReciverUseronline, setIsReciverUseronline] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [videoProcessingUpload, setVideoProcessingUpload] = useState(false);
  const [imageProcessingUpload, setImageProcessingUpload] = useState(false);
  const [
    chatBodyInitlizingwithlanguageSettings,
    setChatBodyInitlizingwithlanguageSettings,
  ] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [docsUploadPercentage, setDocsUploadPercentage] = useState(0);
  const [consultantUSER, setConsultantUSER] = useState({});
  const [videoUploadBlobURL, setVideoUploadBlobURL] = useState("");
  const [imageUploadURL, setImageUploadURL] = useState("");
  const [docUploadUrl, setDocUploadUrl] = useState("");
  const [messageGrettingIfFromtheuser, setMessageGrettingIfFromtheuser] =
    useState(false);
  const [chats, setChats] = useState([]);
  const [chatRemarks, setChatRemarks] = useState([]);
  const [pageLoad, setPageLoad] = useState(1);
  const [totalChatUsersPage, setTotalChatUsersPage] = useState(1);
  const [user, setUser] = useState({});
  const [amount, setAmount] = useState("");
  const [convertedAmount, setConvertedAmount] = useState("");
  const [lastUsedCurrency, setLastUsedCurrency] = useState("");
  const [lastAmount, setLastAmount] = useState(0);
  const [countries, setCountires] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [countryCurrency, setCountryCurrency] = useState(
    props.userData.loginUser.country_id.currency_symbol,
  );
  const [messageText, setMessageText] = useState("");
  const menuRef = useRef(null);
  const imagePickerRef = useRef(null);
  const filePickerRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const videoTempRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [showPaymentType, setShowPaymentType] = useState("");
  const [paymentType, setPaymentType] = useState("");

  let query = useQuery();

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      props.socket.emit("leaveRoom", { roomOne });
      props.socket.emit("userOffline", {
        userId: props.userData.loginUser._id,
      });
    });

    return () => {
      // console.log("leaving");
      props.socket.emit("leaveRoom", { roomOne });
    };
  }, []);

  useEffect(() => {
    props.socket.off("new_message").on("new_message", (message) => {
      if (user.chat_session_id === message.chat_session_id._id) {
        setChats((oldArray) => [message, ...oldArray]);
      }

      let result = chatedUsers.filter(
        (user) => user.chat_session_id === message.chat_session_id._id,
      );

      if (result.length <= 0) {
        console.log("yes no chats");
        conversationUserHandler();
      }

      result = [{ ...result[0], unread: message.msg_notification_reciver }];
      const remaning = chatedUsers.filter(
        (user) => user.chat_session_id !== message.chat_session_id._id,
      );

      setChatedUsers([...result, ...remaning]);

      // function checkUser(user) {}

      // setChatedUsers;

      if (message.chat_session_id) {
        let el = document.getElementById(
          `notification_${message.chat_session_id._id}`,
        );

        if (
          message.msg_notification_reciver &&
          message.msg_notification_reciver !== "0"
        )
          if (el) {
            el.innerHTML = message.msg_notification_reciver;
            el.style.background = "#ff4d4f";
            el.style.color = "white";
          }
      }

      if (message.msg_notification_reciver) {
        let el = document.getElementById("message_notification_chat");

        if (el) {
          el.innerHTML = 1 + Number(el.innerText);
          el.style.background = "#ff4d4f";
          el.style.color = "white";
          el.style.borderRadius = "50%";
          el.style.padding = "2px 4px";
          el.style.marginRight = "7px";
        }
      }
    });

    //check online
    props.socket.on("isOn", (data) => {
      setIsReciverUseronline(data.isOn);
    });

    props.socket.on("typing", (data) => {
      setIsTyper({ isTyping: data.type, userId: data.typerUserId });
      setTimeout(function () {
        setIsTyper({ isTyping: false, userId: data.typerUserId });
      }, 1000);
    });

    props.socket.off("files").on("files", (data) => {
      if (user.chat_session_id === data.chat_session_id._id) {
        setChats((oldArray) => [data, ...oldArray]);
      }
      let result = chatedUsers.filter(
        (user) => user.chat_session_id === data.chat_session_id._id,
      );

      if (result.length <= 0) {
        console.log("yes no chats");
        conversationUserHandler();
      }

      result = [{ ...result[0], unread: data.msg_notification_reciver }];
      const remaning = chatedUsers.filter(
        (user) => user.chat_session_id !== data.chat_session_id._id,
      );

      setChatedUsers([...result, ...remaning]);

      if (data.chat_session_id) {
        let el = document.getElementById(
          `notification_${data.chat_session_id._id}`,
        );

        if (
          data.msg_notification_reciver &&
          data.msg_notification_reciver !== "0"
        )
          if (el) {
            el.innerHTML = data.msg_notification_reciver;
            el.style.background = "#ff4d4f";
            el.style.color = "white";
          }
      }

      if (data.msg_notification_reciver) {
        let el = document.getElementById("message_notification_chat");

        if (el) {
          el.innerHTML = 1 + Number(el.innerText);
          el.style.background = "#ff4d4f";
          el.style.color = "white";
          el.style.borderRadius = "50%";
          el.style.padding = "2px 4px";
          el.style.marginRight = "7px";
        }
      }
    });
  }, [props.socket, user, chatedUsers]);

  useEffect(() => {
    props.socket.off("isContentRead").on("isContentRead", (data) => {
      if (user._id === data.userId) {
        setChats([]);
        //userChats(user, 1, "read");

        let chatjj = chats.map((chat) => {
          chat.isRead = true;
          return chat;
        });
        setChats(chatjj);
        console.log(chatjj);
      }
    });

    props.socket.off("payment_satus").on("payment_satus", (data) => {
      if (user._id) {
        setChats([]);
        userChats(user, 1);
        console.log("status", user);
      }
    });
  }, [props.socket, user, chats]);

  useEffect(() => {
    if (query.get("user_id")) {
      if (query.get("user_id") !== props.userData.loginUser._id) {
        userForChatHandler(query.get("user_id"));
      }
    }
    getChatingLanguages();
  }, []);

  useEffect(() => {
    conversationUserHandler();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getCountries`;

        const res = await getRequestedData(url);

        setCountires(res.data.body);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    };
    getCountries();
  }, []);

  useEffect(() => {
    // const getCurrencies = async () => {
    //   try {
    //     const url = `https://api.currencybeacon.com/v1/currencies?api_key=se8heUgFNoscs7rAam4dSkCnjybFiXRa`;

    //     console.log(url);

    //     const res = await getRequestedData(url);

    //     console.log("res", res.data);
    //     setCurrencies(res.data.response);

    //     //setCountires(res.data.body);
    //   } catch (err) {
    //     console.log(err);
    //     toast.error(err.message);
    //   }
    // };

    const getCurrencies = async () => {
      try {
        const url = `${process.env.REACT_APP_CURRENCY_API_URL}/currencies?apikey=${process.env.REACT_APP_CURRENCY_API_KEY}&type=fiat`;

        console.log(url);

        const res = await getRequestedData(url);

        console.log("res currencies", res.data.data);

        const values = Object.values(res.data.data);
        setCurrencies(values);
        console.log("res currencies 2", values);
        // const values1 = Object.values(values[1]);
        // console.log(values1);
        //setCurrencies(res.data.response);

        //setCountires(res.data.body);
      } catch (err) {
        console.log(err);
      }
    };
    getCurrencies();
  }, []);

  // useEffect(() => {
  //   if (query.get("consultantpayid")) {
  //     payConsultant(query.get("consultantpayid"));
  //   }
  // }, []);

  const handleCurrecnyConverter = async (newCurrencyCode) => {
    try {
      setCurrencyLoader(true);
      console.log(lastUsedCurrency, newCurrencyCode);
      if (lastUsedCurrency === newCurrencyCode) {
        console.log("inside here", amount, lastAmount);
        if (amount == lastAmount) {
          console.log("already converted");
        } else {
          if (amount > 0 && newCurrencyCode !== "KWD") {
            console.log(newCurrencyCode, "KWD", amount);
            const url = `${process.env.REACT_APP_CURRENCY_API_URL}/convert?apikey=${process.env.REACT_APP_CURRENCY_API_KEY}&currencies=KWD&base_currency=${newCurrencyCode}&value=${amount}`;
            const res = await getRequestedData(url);
            console.log("code amouhnt", res.data.data["KWD"].code);
            setLastUsedCurrency(res.data.data["KWD"].code);
            setLastAmount(amount);
            setConvertedAmount(res.data.data["KWD"].value);
          } else {
            setConvertedAmount(amount);
            setLastUsedCurrency("");
            setLastAmount(0);
          }
        }
      } else {
        if (amount > 0 && newCurrencyCode !== "KWD") {
          console.log(newCurrencyCode, "KWD", amount);
          const url = `${process.env.REACT_APP_CURRENCY_API_URL}/convert?apikey=${process.env.REACT_APP_CURRENCY_API_KEY}&currencies=KWD&base_currency=${newCurrencyCode}&value=${amount}`;
          const res = await getRequestedData(url);
          console.log("code amouhnt", res.data.data["KWD"].code);
          setLastUsedCurrency(res.data.data["KWD"].code);
          setLastAmount(amount);
          setConvertedAmount(res.data.data["KWD"].value);
        } else {
          setConvertedAmount(amount);
          setLastUsedCurrency("");
          setLastAmount(0);
        }
      }
    } catch (err) {
      alert(err.message);
    }
    setCurrencyLoader(false);
  };

  const getChatingLanguages = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/getChattingLanguages`;
    try {
      let res = await getRequestedDataWithHeader(url, props.userData.token);

      if (res.data.body.length > 0) {
        setChatLanguages(res.data.body);
        return res.data.body;
      } else {
        setChatLanguages([]);
        throw new Error("please add your langauges in chat settings");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const userForChatHandler = async (user_id) => {
    setChatBodyInitlizingwithlanguageSettings(true);

    const url = `${
      process.env.REACT_APP_BACKEND_URL
    }/api/explore/userForChat?person_user_id=${user_id}&user_id=${
      props.userData.loginUser._id
    }&profile_id=${query.get("profile_id")}`;
    try {
      const settinglanguage = await getChatingLanguages();

      console.log("settinglangauge", settinglanguage);

      if (settinglanguage && settinglanguage.length > 0) {
        let res = await getRequestedDataWithHeader(url, props.userData.token);

        const urlcreateConsultantClientModel = `${process.env.REACT_APP_BACKEND_URL}/api/explore/createConsultantClientModel`;
        const datacreateConsultantClientModel = {
          consultant_user_id: user_id,
          client_user_id: props.userData.loginUser._id,
          consultant_user_name: res.data.body.users.user_name,
          client_user_name: props.userData.loginUser.user_name,
          consultant_profile_name: res.data.body.profile.profile_name,
          consultant_profile_id: res.data.body.profile._id,
        };

        let res2 = await getDataPostWithHeader(
          urlcreateConsultantClientModel,
          datacreateConsultantClientModel,
          props.userData.token,
        );

        if (!res2.data.body.chatExisted) {
          if (res2.data.body.userChatModel) {
            console.log(res2.data.body.userChatModel);
            setChatSessionID(res2.data.body.userChatModel.chat_session_id);
            userChatHandler(res2.data.body.userChatModel);

            if (res2.data.body.consultantLanguages) {
              const date = new Date();
            }
            setChats([
              {
                reciverId: user_id,
                createdAt: new Date(),
                messageType: "text",
                translated: true,
                translatedMessage:
                  res2.data.body.consultantLanguages.languages.map((lang) => {
                    return lang.language.nativeName + " | ";
                  }),
                message: res2.data.body.consultantLanguages.languages.map(
                  (lang) => {
                    if (
                      res2.data.body.consultantLanguages.languages.length > 1
                    ) {
                      return lang.language.nativeName + " | ";
                    } else {
                      return lang.language.nativeName;
                    }
                  },
                ),
                translatedfrom: "en",
                isRead: true,
              },

              {
                reciverId: user_id,
                createdAt: new Date(),
                messageType: "text",
                translated: true,
                translatedMessage:
                  res2.data.body.consultantLanguages.consultantSpeakText,
                message: res2.data.body.consultantLanguages.consultantSpeakText,
                translatedfrom: "en",
                isRead: true,
              },

              {
                reciverId: user_id,
                createdAt: new Date(),
                messageType: "text",
                translated: true,
                translatedMessage: res2.data.body.consultantLanguages.greeting,
                message: res2.data.body.consultantLanguages.greeting,
                translatedfrom: "en",
                isRead: true,
              },
            ]);
          } else {
            alert("something went wrong");
          }
        } else {
          setChatSessionID(res2.data.body.userChatModel.chat_session_id);
          userChatHandler(res2.data.body.userChatModel);
        }
      } else {
        alert("please add your langauge in chat settings");
      }
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
    setChatBodyInitlizingwithlanguageSettings(false);
  };

  const conversationUserHandler = async () => {
    setLoadingUser(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/chatedPersons?userId=${props.userData.loginUser._id}&page=${chatedUsersPageNo}`;

    try {
      let res = await getRequestedDataWithHeader(url, props.userData.token);

      if (chatedUsersPageNo === 1) {
        setChatedUsersTotalPage(res.data.body.Total_Page);
        if (res.data.body.chattedWith.length > 0) {
          setChatedUsers(res.data.body.chattedWith);
        }
      } else {
        setChatedUsers((oldArray) => [
          ...oldArray,
          ...res.data.body.chattedWith,
        ]);
        setChatedUsersPageNo(chatedUsersPageNo + 1);
      }

      setLoadingUser(false);
    } catch (err) {
      setLoadingUser(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const userChatHandler = (user) => {
    console.log("user", user);
    setChats([]);
    setUser({});
    setChatSessionID(user.chat_session_id);
    setConsultantUSER(user);
    if (user._id) {
      user = {
        _id: user.other_end_user_id._id,
        user_name: user.other_end_user_name,
        imageUrl: user.other_end_user_id.imageUrl,
        profile_name: user.user_name,
        chat_session_id: user.chat_session_id,
      };
    }
    setPageLoad(1);
    setUser(user);

    props.socket.emit("leaveRoom", { roomOne });
    dispatch({ type: "ROOMDISCONNECT", payload: "" });
    reciverId = user._id;
    roomOne =
      props.userData.loginUser._id +
      "." +
      reciverId +
      "." +
      user.chat_session_id;
    dispatch({ type: "ROOMCONNECT", payload: roomOne });
    roomTwo = reciverId + "." + props.userData.loginUser._id;
    //for cehck both are online
    props.socket.emit("connected_both", { roomOne });
    props.socket.emit("isOnline", { reciverId });
    setShowChat(true);
    userChats(user, 1);
    userMessageRead(user);
    chatPage("chat_box", "chat_page", "chat_users", "chat_page_cointainer");

    if (!props.homeNavbarcollapse) {
      document
        .getElementsByClassName("tab-chat-left")[0]
        .classList.add("tab-chat-left-hidden");
    }

    getChatRemarks(user);
  };

  const userMessageRead = async (user) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/messageRead`;
    const data = {
      chat_session_id: user.chat_session_id,
      reciverId: user._id,
      userId: props.userData.loginUser._id,
    };
    try {
      let res = await getDataPostWithHeader(url, data, props.userData.token);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const getChatRemarks = async (user) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/chatremarks?chat_session_id=${user.chat_session_id}&user_id=${props.userData.loginUser._id}`;
    try {
      let res = await getRequestedDataWithHeader(url, props.userData.token);
      setChatRemarks(res.data.body);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const userChats = async (user, pageLD, from) => {
    if (pageLD === 1) {
      setChats([]);
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/privateMessage?chat_session_id=${user.chat_session_id}&user_id=${props.userData.loginUser._id}&page=${pageLD}`;
    try {
      let res = await getRequestedDataWithHeader(url, props.userData.token);
      if (res.data.body)
        if (pageLD === 1) {
          setHowManyPaymentRecived(res.data.body.userPaymenttoConsultantCount);
          setTotalChatUsersPage(res.data.body.Total_Page);
          setChats((oldArray) => [
            ...oldArray,
            ...res.data.body.private_messages,
          ]);
        } else {
          setPageLoad(1);

          setChats((oldArray) => [
            ...oldArray,
            ...res.data.body.private_messages,
          ]);
        }
      conversationUserHandler();
      let el = document.getElementById("message_notification_chat");
      el.innerHTML = "";
      el.style = "";
      setPageLoad(pageLD + 1);
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const searchChatUserHandler = async (text) => {
    setChatedUsers([]);
    if (text) {
      setLoadingUser(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/searchChatedPersons?userId=${props.userData.loginUser._id}&search_text=${text}&page=${searchChatedUsersPageNo}`;

      try {
        let res = await getRequestedDataWithHeader(url, props.userData.token);

        if (searchChatedUsersPageNo === 1) {
          setSearchChatedUsers(res.data.body.chattedWith);
          setSearchChatedUsersTotalPage(res.data.body.Total_Page);
        } else {
          setSearchChatedUsers((oldArray) => [
            ...oldArray,
            ...res.data.body.messagesBtwUsers,
          ]);
          setSearchChatedUsersPageNo(searchChatedUsersPageNo + 1);
        }

        setLoadingUser(false);
      } catch (err) {
        setLoadingUser(false);
        console.log(err.message);
        toast.error(err.message);
      }
    } else {
      setSearchChatedUsers([]);
      conversationUserHandler();
    }
  };

  function closeNav() {
    // document.getElementById('mySidenav').style.width = '0'
    setSidenavShow(false);
  }

  function openNav() {
    setSidenavShow(true);
    //document.getElementById('mySidenav').style.width = '25%'
  }

  const sendMessageHandler = (user) => {
    //sending private messages

    props.socket.on("close", (code, reason) => {
      console.log(`Socket closed with code ${code} and reason: ${reason}`);
    });

    props.socket.emit(
      "privateMessage",
      {
        reciverId: user._id,
        userId: props.userData.loginUser._id,
        message: messageText,
        senderName: props.userData.loginUser.user_name,
        reciverName: user.user_name,
        reciverProfileUrl: user.imageUrl,
        senderProfileUrl: props.userData.loginUser.imageUrl,
        chat_session_id: chatSessionID,
        uufid: uuidv4(),
      },
      function (response) {
        console.log(response);
      },
    );
    //conversationUserHandler();
    setMessageText("");
  };

  const sendPaymentLink = () => {
    setPaymentLoading(true);
    if (amount === "" || amount === 0 || amount <= 0) {
      alert("enter valied amount");
      setPaymentLoading(false);
      return;
    }

    console.log("converted", convertedAmount);
    props.socket.emit(
      "paylinkmessage",
      {
        reciverId: user._id,
        userId: props.userData.loginUser._id,
        convertedAmount: Number(convertedAmount).toFixed(3),
        amount: amount.toString(),
        currency: countryCurrency,
        senderName: props.userData.loginUser.user_name,
        reciverName: user.user_name,
        reciverProfileUrl: user.imageUrl,
        senderProfileUrl: props.userData.loginUser.imageUrl,
        chat_session_id: chatSessionID,
        consultant_profile_name: user.profile_name,
        uufid: uuidv4(),
      },
      function (response) {
        setAmount(0);
        setConvertedAmount("");
        setAskMoneyModel(false);
        console.log(response);
        if (response.messageDelivered) {
          //loding fasle
          setPaymentLoading(false);
        } else {
          setPaymentLoading(false);
          alert(response.error);
        }
      },
    );
    //conversationUserHandler();
    setMessageText("");
  };

  const scrollHandler = (e) => {
    if (
      Math.ceil(e.target.offsetHeight - e.target.scrollTop) >=
      e.target.scrollHeight
    ) {
      if (pageLoad <= totalChatUsersPage) userChats(user, pageLoad);
    }
  };

  const closeChat = () => {
    props.socket.emit("leaveRoom", { roomOne });
    setShowChat(false);
  };

  const chatPage = (id1, id2, id3, id4) => {
    document.getElementById(id1).style.display = "none";
    document.getElementById(id2).style.display = "block";
    document.getElementById(id3).style.display = "none";
    document.getElementById(id4).style.display = "block";
  };

  const ImagePickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      setShowAttach(false);
      if (fileExttype === "image") {
        const FR = new FileReader();
        FR.addEventListener("load", function (evt) {
          var base64 = evt.target.result;
          pickedFile = event.target.files[0];
          // let fileName = pickedFile.name;
          // var type = "image";
          // var path = `${weburl}/api/chat/chatFileUpload`;
          // uploadFileHandler(type, path, pickedFile, fileName, base64);

          setImageUploadURL(base64);
          setImageUploadPercentage(1);
          const data = {
            user_id: props.userData.loginUser._id,
            keyname: "userFiles/chatFiles",
            filename: pickedFile.name,
            fileType: pickedFile.type,
          };
          getPresignedUrl(
            "getPresignedURLForCoverUpload",
            data,
            pickedFile,
            "image",
          );
        });
        FR.readAsDataURL(event.target.files[0]);
      } else if (fileExttype === "video") {
        pickedFile = event.target.files[0];
        let blobURL = URL.createObjectURL(pickedFile);
        setVideoUploadBlobURL(blobURL);

        const data = {
          user_id: props.userData.loginUser._id,
          keyname: "userFiles/chatFiles",
          filename: pickedFile.name,
          fileType: pickedFile.type,
        };
        setVideoUploadPercentage(1);
        getPresignedUrl(
          "getPresignedURLForVideUpload",
          data,
          pickedFile,
          "video",
        );
      } else {
        alert("Please Select Suitable file");
      }
    }
  };

  const filePickedHandler = (event) => {
    setShowAttach(false);
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];

      if (fileExttype === "image" || fileExttype === "video") {
        alert("Please Select Suitable file");
      } else {
        pickedFile = event.target.files[0];
        const data = {
          user_id: props.userData.loginUser._id,
          keyname: "userFiles/chatFiles",
          filename: pickedFile.name,
          fileType: pickedFile.type,
        };

        setDocUploadUrl(pickedFile.name);
        setDocsUploadPercentage(1);
        getPresignedUrl(
          "getPresignedURLForDocUpload",
          data,
          pickedFile,
          "docs",
        );
        // pickedFile = event.target.files[0];
        // let fileName = pickedFile.name;
        // var type = "docs";
        // var path = `${weburl}/api/chat/chatFileUpload`;
        // uploadFileHandler(type, path, pickedFile, fileName);
      }
    }
  };

  // const screenShotCreater = () => {
  //   videoTempRef.current.addEventListener("canplay", function () {
  //     var video = videoTempRef.current;
  //     var canvas = capture(video);
  //     var imgeval = canvas.toDataURL();
  //     var imgeFile = dataURLtoBlob(imgeval);

  //     const data = {
  //       user_id: props.userData.loginUser._id,
  //       keyname: "userFiles/chatFiles",
  //       filename: "screenshot2.png",
  //       fileType: imgeFile.type,
  //     };

  //     getPresignedUrl(
  //       "getPresignedURLForCoverUpload",
  //       data,
  //       imgeFile,
  //       "screenshot",
  //     );
  //   });
  // };

  // const capture = (video) => {
  //   var w = video.videoWidth;
  //   var h = video.videoHeight;
  //   var canvas = document.createElement("canvas");
  //   canvas.width = video.videoWidth;
  //   canvas.height = video.videoHeight;
  //   var ctx = canvas.getContext("2d");
  //   ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //   return canvas;
  // };

  const getPresignedUrl = async (apiName, data, pickedFile, type) => {
    // 500MB in bytes
    const maxSize = 500 * 1024 * 1024;
    if (pickedFile.size > maxSize) {
      alert("Only files of size 500MB or below are allowed.");
    } else {
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;
        const res = await getDataPostWithHeader(
          url,
          data,
          props.userData.token,
        );
        if (res.data.status === 200) {
          uploadToS3(
            pickedFile,
            res.data.body.preSignedurl,
            apiName,
            res.data.body.fileUrl,
          );
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
            } else if (apiName === "getPresignedURLForVideUpload") {
              setVideoProcessingUpload(true);
            }
          } else {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else if (apiName === "getPresignedURLForVideUpload") {
              setVideoUploadPercentage(percent);
            } else {
              setDocsUploadPercentage(percent);
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setImageProcessingUpload(false);
            props.socket.emit(
              "mediamessage",
              {
                message: pickedFile.name,
                userId: props.userData.loginUser._id,
                messageType: "image",
                fileURL: fileUrl,
                fileName: pickedFile.name,
                durationTime: "0",
                reciverId: user._id,
                senderName: props.userData.loginUser.user_name,
                reciverName: user.user_name,
                reciverProfileUrl: user.imageUrl,
                senderProfileUrl: props.userData.loginUser.imageUrl,
                chat_session_id: chatSessionID,
                uufid: uuidv4(),
              },
              function (response) {
                console.log(response);
              },
            );
            setImageUploadPercentage(0);
          } else if (apiName === "getPresignedURLForVideUpload") {
            if (pickedFile.type.split("/")[0] === "video") {
              setVideoUploadPercentage(0);
              setVideoProcessingUpload(false);
              props.socket.emit(
                "mediamessage",
                {
                  message: pickedFile.name,
                  userId: props.userData.loginUser._id,
                  messageType: "video",
                  fileURL: fileUrl,
                  fileName: pickedFile.name,
                  durationTime: "0",
                  reciverId: user._id,
                  senderName: props.userData.loginUser.user_name,
                  reciverName: user.user_name,
                  reciverProfileUrl: user.imageUrl,
                  senderProfileUrl: props.userData.loginUser.imageUrl,
                  chat_session_id: chatSessionID,
                  uufid: uuidv4(),
                },
                function (response) {
                  console.log(response);
                },
              );
            }
          } else {
            setDocsUploadPercentage(0);
            //setVideoProcessingUpload(false);
            props.socket.emit(
              "mediamessage",
              {
                message: pickedFile.name,
                userId: props.userData.loginUser._id,
                messageType: "docs",
                fileURL: fileUrl,
                fileName: pickedFile.name,
                durationTime: "0",
                reciverId: user._id,
                senderName: props.userData.loginUser.user_name,
                reciverName: user.user_name,
                reciverProfileUrl: user.imageUrl,
                senderProfileUrl: props.userData.loginUser.imageUrl,
                chat_session_id: chatSessionID,
                uufid: uuidv4(),
              },
              function (response) {
                console.log(response);
              },
            );
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else if (apiName === "getPresignedURLForVideUpload") {
          setVideoUploadPercentage(0);
          setVideoProcessingUpload(false);
        } else {
          setDocsUploadPercentage(0);
        }
      });
  };

  const uploadFileHandler = async (type, path, file, fileName, base64) => {
    let formData = new FormData();

    formData.append("senderId", props.userData.loginUser._id);
    formData.append("reciverId", reciverId);
    formData.append("senderName", props.userData.loginUser.user_name);
    formData.append("reciverName", user.user_name);
    formData.append("reciverProfileUrl", user.imageUrl);
    formData.append("senderProfileUrl", props.userData.loginUser.imageUrl);
    formData.append("displayFileName", fileName);
    formData.append("chat_session_id", chatSessionID);
    formData.append("durationTime", "0");
    formData.append("uufid", uuidv4());

    if (type === "image" || type === "docs") {
      formData.append("messageType", type);
    } else {
      formData.append("messageType", "audio");
    }

    if (type === "image") {
      formData.append("file", file);
      let image = await createImage(base64);
    } else if (type === "docs") {
      formData.append("file", file);
    } else if (type === "audio") {
      formData.append("file", file);
    }
    const options = {
      headers: {
        Authorization: `Bearer ${props.userData.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          console.log(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        if (res.data.status === 200) {
          //setChats((oldArray) => [res.data.body.fileData, ...oldArray]);

          setShowAttach(false);
        } else {
          toast.error("Updloded failed");
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setUploadPercentage(0);
        if (isCancel(err)) {
          toast.error("Aborted");
        } else {
          toast.error("System Failed");
        }
      });
  };

  const playVideo = (url) => {
    setshowMedia({ isShowMedia: true, url: url, type: "video" });
  };

  const showImage = (url) => {
    setshowMedia({ isShowMedia: true, url: url, type: "image" });
  };

  const closeVideoPlayer = () => {
    setshowMedia({ isShowMedia: false, url: "", type: "" });
  };

  const chatattachment = () => {
    if (
      imageUploadPercentage === 0 &&
      videoUploadPercentage === 0 &&
      docsUploadPercentage === 0
    ) {
      setShowAttach(!showAttach);
    } else {
      alert("Please wait until it gets uploaded");
    }
  };

  const scrollHandlerChastedUser = (e) => {
    if (
      Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
      e.target.scrollHeight
    ) {
      if (chatedUsersPageNo <= chatedUsersTotalPage) {
        conversationUserHandler();
      }
    }
  };

  const scrollHandlerSearchedUser = (e) => {
    if (
      Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
      e.target.scrollHeight
    ) {
      if (searchChatedUsersPageNo <= searchChatedUsersTotalPage) {
        searchChatUserHandler();
      }
    }
  };

  const openTodoList = (area) => {
    let chatarea = document.getElementById("chat_page_cointainer");
    let ramakArea = document.getElementById("chat_remark_container");

    if (area === "chatarea") {
      chatarea.classList.remove("active");
      chatarea.classList.add("hidden");
      ramakArea.classList.remove("hidden");
      ramakArea.classList.add("active");
    } else {
      chatarea.classList.remove("hidden");
      chatarea.classList.add("active");
      ramakArea.classList.remove("active");
      ramakArea.classList.add("hidden");
    }
  };

  const handlePaymentType = async (e, pay) => {
    console.log(pay, "pay");
    setPaymentType(e.target.value);
    if (e.target.value === "Cards") {
      await initiateChekout(pay, "Card");
    } else {
      setLoading(true);
      await initiateChekout(pay, "Knet");
    }
  };

  const initiateChekout = async (PAYUSERS, paymenttype) => {
    setLoading(true);

    console.log("PAYUSERS.userId", PAYUSERS);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkout/checkout-consultant-initiate`;
    const data = {
      consultantpayid: PAYUSERS._id,
      user_id: props.userData.loginUser._id,
      payment_type: paymenttype,
      consultant_id: PAYUSERS.user_id,
      money_In_KWD: PAYUSERS.money_In_KWD.toString(),
      tokenparam: props.userData.token,
    };
    try {
      let res = await getDataPostWithHeader(url, data, props.userData.token);
      if (paymenttype === "Card") {
        window.Checkout.configure({
          session: {
            id: res.data.body.redirect,
          },
        });
        document.getElementById("paymentidcard").style.display = "flex";
        window.Checkout.showEmbeddedPage("#embed-target");
      } else {
        console.log(res.data.body);
        window.location.href = res.data.body.redirect;
      }
      console.log(res.data.body.session_id);
    } catch (err) {
      document.getElementById("paymentidcard").style.display = "none";
      console.log(err.message);
      alert(err.message);
    }
    setLoading(false);
  };

  // const payConsultant = async (payid) => {
  //   console.log(payid);
  //   try {
  //     const data = {
  //       pay_id: payid,
  //     };
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/payConsultant`;
  //     const res = await getDataPostWithHeader(url, data, props.userData.token);
  //     if (res.data.status === 200) {
  //       history.replace("/chat");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     alert(err.message);
  //     //toast.error(err.message);
  //   }
  // };
  return (
    <>
      {askMoneyModel && (
        <Model show={askMoneyModel}>
          <div className="model-bg-inside">
            <div
              className="model-heading"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h3 className="model-title">Send Payment link</h3>
              <AiOutlineClose
                className="cursor-pointer"
                onClick={() => setAskMoneyModel(false)}
              />
            </div>
            <div className="display-flex mt-2">
              <input
                type="text"
                placeholder="enter amount"
                className="form-control model-form-control"
                value={amount}
                style={{ width: "70%" }}
                onChange={(e) => setAmount(e.target.value)}
                onBlur={() => handleCurrecnyConverter(countryCurrency)}
                onKeyUp={(e) => {
                  if (e.key === "Enter")
                    handleCurrecnyConverter(countryCurrency);
                }}
              />

              <span className="display-flex-align-center ml-1">
                In {countryCurrency}
              </span>
            </div>
            <select
              className="form-control mt-2 model-form-control"
              defaultValue={countryCurrency}
              onChange={(e) => {
                setCountryCurrency(e.target.value);
                handleCurrecnyConverter(e.target.value);
              }}
            >
              {currencies.length > 0 &&
                currencies.map((currency) => {
                  return (
                    <option value={currency.code}>
                      {currency.code + " " + currency.symbol_native}
                    </option>
                  );
                })}
            </select>
            {amount && amount >= "1" && convertedAmount && (
              <div className="display-flex mt-2">
                <input
                  type="text"
                  disabled
                  value={convertedAmount || 0}
                  className="form-control"
                  style={{ width: "70%" }}
                />
                {currencyLoader && (
                  <span className="loader-spiner loader-spiner-addtionl"></span>
                )}
                <span className="display-flex-align-center ml-1">In KWD</span>
              </div>
            )}

            <div className="mt-2">
              <button
                className="btn btn-primary model-btn"
                onClick={sendPaymentLink}
                disabled={convertedAmount && !currencyLoader ? false : true}
              >
                Send payment link
              </button>
            </div>
          </div>
        </Model>
      )}

      <ModelCardPay show={true}>
        <div
          className="model-bg"
          style={{ display: "none" }}
          id="paymentidcard"
        >
          <div className="model-bg-inside">
            <div
              className="model-heading"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <AiOutlineClose
                className="cursor-pointer"
                onClick={() =>
                  (document.getElementById("paymentidcard").style.display =
                    "none")
                }
              />
            </div>
            <div
              id="embed-target"
              className={`${
                paymentType === "Knet" ? "display-none" : "display-block"
              }`}
            ></div>
          </div>
        </div>
      </ModelCardPay>

      <ModelV
        show={showMedia.isShowMedia}
        containerStyle={{
          background: "none",
          width: "unset",
          boxShadow: "unset",
        }}
      >
        {showMedia.type === "video" && (
          <div style={{ position: "relative" }}>
            <video
              controls
              src={showMedia.url}
              ref={videoTempRef}
              style={{ height: "600px" }}
            >
              Your browser does not support the video tag.
            </video>
            <AiOutlineClose
              size={40}
              style={{ position: "absolute", color: "red", cursor: "pointer" }}
              onClick={closeVideoPlayer}
            />
          </div>
        )}

        {showMedia.type === "image" && (
          <div style={{ width: "70rem", position: "relative" }}>
            <div className="image-warapper-ratio-adujestment">
              <img
                src={showMedia.url}
                alt="image"
                className="image-whereplayer-shows"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defaultThumbmail;
                }}
              />
            </div>

            <AiOutlineClose
              size={40}
              style={{
                position: "absolute",
                color: "red",
                cursor: "pointer",
                top: "0",
                right: "0",
              }}
              onClick={closeVideoPlayer}
            />
          </div>
        )}
      </ModelV>
      <div className="container-fluid chat-box-wrapper">
        <div
          className={`row chat-section ${
            !props.homeNavbarcollapse && "tab-chat-section"
          }`}
        >
          <div
            className={`col-md-3 col-lg-3 col-xl-3 col-sm-12 com-xs-12 chat-left-area ${
              !props.homeNavbarcollapse && "tab-chat-left "
            }`}
            id="chat_users"
            style={{ padding: "0px", paddingRight: "2px" }}
          >
            <div
              className="chat-box chat-box-left-border chat-box-left-border"
              id="chat_box"
              style={{ marginRight: "2px" }}
            >
              <div className="chat-top-section chat-menu-border">
                <div className="chat-search-area" id="chat_search">
                  <input
                    type="search"
                    placeholder="Search"
                    className="chat-search-input"
                    onChange={(e) => searchChatUserHandler(e.target.value)}
                  />
                </div>
                <div className="chat-more">
                  <img
                    src={more}
                    alt="more"
                    className="chat-icon"
                    onClick={openNav}
                  />
                </div>
              </div>
              {sidenavShow && (
                <SideBar>
                  <SideBarChatMenus
                    token={props.userData.token}
                    user_id={props.userData.loginUser._id}
                    closeNav={closeNav}
                    setChatLanguages={setChatLanguages}
                    chatLanguages={chatLanguages}
                  />
                </SideBar>
              )}
              {!sidenavShow && (
                <div
                  className="chat-box-body"
                  onScroll={(e) => {
                    if (chatedUsers.length > 0) {
                      scrollHandlerChastedUser(e);
                    } else {
                      scrollHandlerSearchedUser(e);
                    }
                  }}
                >
                  {loadingUser && (
                    <div className="m-2">
                      <LoadingCard count={3} avatar defualt />
                    </div>
                  )}
                  {chatedUsers.length > 0 &&
                    chatedUsers.map((user, index) => {
                      var time = new Date(user.createdAt).toLocaleTimeString();
                      var fileType = "";
                      // if (user.body.messageType === "docs") {
                      //   fileType = user.body.displayFileName.split(".");
                      //   fileType = fileType[fileType.length - 1];
                      // }
                      return (
                        <div
                          className="chat-box-profile-section"
                          key={index}
                          onClick={() => {
                            userChatHandler(user);
                            //user.body.isRead = true;
                          }}
                        >
                          <>
                            <img
                              src={profile}
                              alt="profile"
                              className="chat-box-profile-img"
                            />

                            <div className={`chat-user-section`}>
                              <div className="chat-user-details">
                                <div className="chat-user">
                                  {user.other_end_user_name}
                                </div>
                                <div className="chat-user-msg  chat-text-chat-box">
                                  <span className="chat-your-msg display-flex-align-center">
                                    {user.other_end_isConsultant ? (
                                      <FaUserGraduate />
                                    ) : (
                                      <FaUser />
                                    )}

                                    {user.other_end_isConsultant ? (
                                      <span className="ml-1">Consultant</span>
                                    ) : (
                                      <span className="ml-1">Client</span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="chat-user-time-section">
                                <div className="chat-user-time">
                                  {/* {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                  {time.split(" ")[1]} */}

                                  {user.alarm > 0 && (
                                    <BsFillAlarmFill size={15} />
                                  )}
                                </div>

                                <div
                                  id={`notification_${user.chat_session_id}`}
                                  className={`${
                                    user.unread > 0 && "chat-user-notify"
                                  }`}
                                >
                                  {user.unread > 0 && user.unread}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      );
                    })}

                  {searchChatedUsers.length > 0 &&
                    searchChatedUsers.map((user, index) => {
                      var time = new Date(user.createdAt).toLocaleTimeString();

                      // var fileType = "";
                      // if (user.body.messageType === "docs") {
                      //   fileType = user.body.displayFileName.split(".");
                      //   fileType = fileType[fileType.length - 1];
                      // }
                      return (
                        <div
                          className="chat-box-profile-section"
                          key={user._id}
                          onClick={() => {
                            userChatHandler(user);
                            // user.body.isRead = true;
                          }}
                        >
                          <>
                            <img
                              src={profile}
                              alt="profile"
                              className="chat-box-profile-img"
                            />

                            <div className={`chat-user-section`}>
                              <div className="chat-user-details">
                                <div className="chat-user">
                                  {user.other_end_user_name}
                                </div>
                                <div className="chat-user-msg  chat-text-chat-box">
                                  <span className="chat-your-msg display-flex-align-center">
                                    {user.other_end_isConsultant ? (
                                      <FaUserGraduate />
                                    ) : (
                                      <FaUser />
                                    )}

                                    {user.other_end_isConsultant ? (
                                      <span className="ml-1">Consultant</span>
                                    ) : (
                                      <span className="ml-1">Client</span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="chat-user-time-section">
                                <div className="chat-user-time">
                                  {/* {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                  {time.split(" ")[1]} */}

                                  {user.alarm > 0 && (
                                    <BsFillAlarmFill size={15} />
                                  )}
                                </div>

                                <div
                                  id={`notification_${user.chat_session_id}`}
                                  className={`${
                                    user.unread > 0 && "chat-user-notify"
                                  }`}
                                >
                                  {user.unread > 0 && user.unread}
                                </div>
                              </div>
                            </div>
                          </>
                          {/* <>
                          {user.body.messageType === "text" && (
                            <>
                              {props.userData.loginUser._id ===
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`chat-user-section ${
                                      user.body.isRead
                                        ? "chat-open"
                                        : "chat-notopen"
                                    } `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.reciverName}
                                      </div>
                                      <div className="chat-user-msg  chat-text-chat-box">
                                        <span className="chat-you">You: </span>

                                        <span className="chat-your-msg">
                                          {user.body.message}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {props.userData.loginUser._id !==
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`${
                                      user.body.isRead
                                        ? "chat-get-open"
                                        : "chat-get-notopen"
                                    } chat-user-section chat-get `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.senderName}
                                      </div>
                                      <div className="chat-user-msg chat-text-chat-box">
                                        <span className="chat-get-msg ">
                                          {user.body.message}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {" "}
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                      {!user.body.isRead && (
                                        <div className="chat-user-notify">
                                          {user.notification}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {user.body.messageType === "image" && (
                            <>
                              {props.userData.loginUser._id ===
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`chat-user-section ${
                                      user.body.isRead
                                        ? "chat-open"
                                        : "chat-notopen"
                                    } `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.reciverName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          <img
                                            src={photo}
                                            className="chat-user-img mb-1"
                                          />
                                        </span>

                                        <span className="chat-your-msg">
                                          Photo
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {props.userData.loginUser._id !==
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`${
                                      user.body.isRead
                                        ? "chat-get-open"
                                        : "chat-get-notopen"
                                    } chat-user-section chat-get `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.senderName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          <img
                                            src={photo}
                                            className="chat-user-img mb-1"
                                          />
                                        </span>
                                        <span className="chat-your-msg">
                                          Photo
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {" "}
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                      {!user.body.isRead && (
                                        <div className="chat-user-notify">
                                          {user.notification}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {user.body.messageType === "audio" && (
                            <>
                              {props.userData.loginUser._id ===
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`chat-user-section ${
                                      user.body.isRead
                                        ? "chat-open"
                                        : "chat-notopen"
                                    } `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.reciverName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          <img
                                            src={microphone}
                                            className="chat-user-img mb-1"
                                          />
                                        </span>

                                        <span className="chat-your-msg">
                                          Photo
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {props.userData.loginUser._id !==
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`${
                                      user.body.isRead
                                        ? "chat-get-open"
                                        : "chat-get-notopen"
                                    } chat-user-section chat-get `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.senderName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          <img
                                            src={microphone}
                                            className="chat-user-img mb-1"
                                          />
                                        </span>
                                        <span className="chat-your-msg">
                                          1:30
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {" "}
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                      {!user.body.isRead && (
                                        <div className="chat-user-notify">
                                          {user.notification}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {user.body.messageType === "docs" && (
                            <>
                              {props.userData.loginUser._id ===
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`chat-user-section ${
                                      user.body.isRead
                                        ? "chat-open"
                                        : "chat-notopen"
                                    } `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.reciverName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          {fileType === "pdf" && (
                                            <BsFilePdfFill className="chat-user-img mb-1" />
                                          )}

                                          {fileType !== "pdf" && (
                                            <img
                                              src={file}
                                              className="chat-user-img mb-1"
                                            />
                                          )}
                                        </span>

                                        <span className="chat-your-msg">
                                          {user.body.displayFileName}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {props.userData.loginUser._id !==
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`${
                                      user.body.isRead
                                        ? "chat-get-open"
                                        : "chat-get-notopen"
                                    } chat-user-section chat-get `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.senderName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          {fileType === "pdf" && (
                                            <BsFilePdfFill className="chat-user-img mb-1" />
                                          )}

                                          {fileType !== "pdf" && (
                                            <img
                                              src={file}
                                              className="chat-user-img mb-1"
                                            />
                                          )}
                                        </span>
                                        <span className="chat-your-msg">
                                          {user.body.displayFileName}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {" "}
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                      {!user.body.isRead && (
                                        <div className="chat-user-notify">
                                          {user.notification}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {user.body.messageType === "video" && (
                            <>
                              {props.userData.loginUser._id ===
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`chat-user-section ${
                                      user.body.isRead
                                        ? "chat-open"
                                        : "chat-notopen"
                                    } `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.reciverName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          <img
                                            src={videoIcon}
                                            className="chat-user-img mb-1"
                                          />
                                        </span>

                                        <span className="chat-your-msg">
                                          Video
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {props.userData.loginUser._id !==
                                user.body.senderId && (
                                <>
                                  <img
                                    src={profile}
                                    alt="profile"
                                    className="chat-box-profile-img"
                                  />
                                  <div
                                    className={`${
                                      user.body.isRead
                                        ? "chat-get-open"
                                        : "chat-get-notopen"
                                    } chat-user-section chat-get `}
                                  >
                                    <div className="chat-user-details">
                                      <div className="chat-user">
                                        {user.body.senderName}
                                      </div>
                                      <div className="chat-user-msg">
                                        <span className="chat-you mr-1">
                                          <img
                                            src={photo}
                                            className="chat-user-img mb-1"
                                          />
                                        </span>
                                        <span className="chat-your-msg">
                                          Photo
                                        </span>
                                      </div>
                                    </div>
                                    <div className="chat-user-time-section">
                                      <div className="chat-user-time">
                                        {" "}
                                        {time.split(":")[0]}:
                                        {time.split(":")[1]}{" "}
                                        {time.split(" ")[1]}
                                      </div>
                                      {!user.body.isRead && (
                                        <div className="chat-user-notify">
                                          {user.notification}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          </> */}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div
            className={`col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 chat-center-area ${
              !props.homeNavbarcollapse && "tab-chat-center"
            }`}
            id="chat_page_cointainer"
            style={{ padding: "0px", paddingRight: "2px" }}
          >
            <div className="chat-box chat-box-display" id="chat_page">
              {showChat && !chatBodyInitlizingwithlanguageSettings && (
                <>
                  <div className="chat-nav chat-menu-border">
                    <div className="chat-nav-left">
                      <img
                        src={back}
                        alt="back"
                        className="chat-box-profile-svg-back"
                        onClick={() => {
                          closeChat();
                          chatPage(
                            "chat_page",
                            "chat_box",
                            "chat_page_cointainer",
                            "chat_users",
                          );
                          if (!props.homeNavbarcollapse) {
                            document
                              .getElementsByClassName("tab-chat-left")[0]
                              .classList.remove("tab-chat-left-hidden");
                          }
                        }}
                      />
                      <img
                        src={profile}
                        alt="profile"
                        className="chat-box-profile-img-nav"
                      />
                      <div>
                        <div>{user._id && user.user_name}</div>
                        <div className="display-flex">
                          {!typer.isTyping && (
                            <>{isReciverUseronline ? "online" : "offline"} </>
                          )}
                          {typer.isTyping && typer.userId !== user._id && (
                            <>{isReciverUseronline ? "online" : "offline"} </>
                          )}
                          {howManyPaymentRecived > 0 && (
                            <span className="display-flex ml-3">
                              <span>
                                {" "}
                                <BiDollar size={20} />
                              </span>
                              <span className="paid-notification">
                                {howManyPaymentRecived}
                              </span>
                            </span>
                          )}
                        </div>
                        <div>
                          {typer.isTyping && typer.userId === user._id
                            ? "typing"
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="chat-nav-right chat-nav-position-relative">
                      <div
                        className="chat-search"
                        onClick={() => setAskMoneyModel(!askMoneyModel)}
                      >
                        {props.userData.loginUser.is_consultant && (
                          <PiHandCoinsFill size={20} />
                        )}
                      </div>
                      {props.userData.loginUser.is_consultant && (
                        <div className="chat-search">
                          <img
                            src={videocall}
                            alt="vedio"
                            className="chat-icon"
                          />
                        </div>
                      )}

                      {!props.userData.loginUser.is_consultant && (
                        <div className="chat-search">
                          <img
                            src={videocall2}
                            alt="vedio"
                            className="chat-icon"
                            style={{ width: "18px", height: "18px" }}
                          />
                        </div>
                      )}

                      {props.userData.loginUser.is_consultant && (
                        <div className="chat-search">
                          <img src={phone} alt="phone" className="chat-icon" />
                        </div>
                      )}

                      {!props.userData.loginUser.is_consultant && (
                        <div className="chat-search">
                          <img src={phone2} alt="phone" className="chat-icon" />
                        </div>
                      )}
                      <div
                        className={`chat-search chat-remarks-icon ${
                          !props.homeNavbarcollapse && "chat-tab-remarks-icon"
                        } }`}
                      >
                        <RiCalendarTodoLine
                          onClick={() => {
                            openTodoList("chatarea");

                            if (!props.homeNavbarcollapse) {
                              document
                                .getElementsByClassName("tab-chat-center")[0]
                                .classList.add("tab-chat-center-hidden");
                            }
                          }}
                        />
                      </div>
                      <div className="chat-more">
                        <img
                          src={more}
                          alt="more"
                          className="chat-icon"
                          onClick={() =>
                            (menuRef.current.style.display = "block")
                          }
                        />
                      </div>
                      <div
                        ref={menuRef}
                        className="menubox-chat-nav"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <AiOutlineClose
                          onClick={() =>
                            (menuRef.current.style.display = "none")
                          }
                          className="text-black cursor-pointer float-right"
                        />
                        <div style={{ clear: "both" }}></div>
                        <div
                          className="cursor-pointer drop-down-navbar-menu"
                          onClick={closeChat}
                        >
                          Close
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="chat-page-body"
                    onScroll={(e) => scrollHandler(e)}
                    ref={chatBodyRef}
                  >
                    {videoUploadPercentage > 0 && (
                      <div className="chat-sent-msg">
                        <div className="chat-sent-video-section">
                          <video
                            className="chat-video-msg"
                            onClick={(e) => {
                              playVideo(videoUploadBlobURL);
                            }}
                          >
                            <source src={videoUploadBlobURL} type="video/mp4" />
                          </video>

                          <img
                            src={playcircle}
                            className="player-button-video"
                            onClick={(e) => {
                              playVideo(videoUploadBlobURL);
                            }}
                          />

                          <div>
                            <div
                              className="circular-progress"
                              style={{
                                background: `conic-gradient(
                                        #4d5bf9 ${
                                          videoUploadPercentage * 3.6
                                        }deg,
                                        #cadcff ${
                                          videoUploadPercentage * 3.6
                                        }deg
                                    )`,
                              }}
                            >
                              <div className="value-container">0%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {docsUploadPercentage > 0 && (
                      <div className="chat-sent-msg">
                        <div className="chat-sent-img-section">
                          <div
                            style={{
                              display: "flex",
                              gap: "2px",
                              alignItems: "center",
                            }}
                          >
                            <IoDocument size={25} />
                            {docUploadUrl}
                          </div>

                          <div className="chat-sent-text-msg">
                            <div className="mt-2">
                              <div
                                className="circular-progress"
                                style={{
                                  background: `conic-gradient(
                                        #4d5bf9 ${
                                          docsUploadPercentage * 3.6
                                        }deg,
                                        #cadcff ${docsUploadPercentage * 3.6}deg
                                    )`,
                                }}
                              >
                                <div className="value-container">0%</div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-sent-time"></div>
                        </div>
                      </div>
                    )}

                    {imageUploadPercentage > 0 && (
                      <div className="chat-sent-msg">
                        <div
                          className="chat-sent-img-section"
                          onClick={() => {
                            showImage(imageUploadURL);
                          }}
                        >
                          <img src={imageUploadURL} className="chat-sent-img" />

                          <div className="chat-sent-text-msg">
                            <div className="mt-2">
                              <div
                                className="circular-progress"
                                style={{
                                  background: `conic-gradient(
                                        #4d5bf9 ${
                                          imageUploadPercentage * 3.6
                                        }deg,
                                        #cadcff ${
                                          imageUploadPercentage * 3.6
                                        }deg
                                    )`,
                                }}
                              >
                                <div className="value-container">0%</div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-sent-time"></div>
                        </div>
                      </div>
                    )}

                    {chats.length > 0 &&
                      chats.map((message, index) => {
                        var time = new Date(
                          message.createdAt,
                        ).toLocaleTimeString();
                        var fileType = "";
                        if (message.messageType === "docs") {
                          fileType = message.displayFileName.split(".");
                          fileType = fileType[fileType.length - 1];
                        }
                        if (message.messageType === "text") {
                          return (
                            <div key={message.createdAt + index}>
                              {message.reciverId ===
                              props.userData.loginUser._id ? (
                                <div className="chat-received-msg">
                                  <div className="chat-received-text">
                                    <div className="chat-received-text-msg">
                                      {message.translated ? (
                                        <>
                                          <div className="chat-recived-translate-text">{`text is translated from "${message.translatedfrom}"`}</div>
                                          <div>{message.translatedMessage}</div>
                                        </>
                                      ) : (
                                        message.message
                                      )}
                                    </div>
                                    <div className="chat-received-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`chat-sent-msg ${
                                    message.isRead && "chat-sent-msg-open"
                                  }`}
                                >
                                  <div className="chat-sent-text">
                                    <div className="chat-sent-text-msg">
                                      {message.message}
                                    </div>
                                    <div className="chat-sent-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }

                        if (message.messageType === "image") {
                          return (
                            <div key={message.createdAt + index}>
                              {message.reciverId ===
                              props.userData.loginUser._id ? (
                                <div className="chat-received-msg">
                                  <div
                                    className="chat-received-img-section"
                                    onClick={() => {
                                      showImage(message.file);
                                    }}
                                  >
                                    <img
                                      src={message.file}
                                      className="chat-received-img"
                                      alt="user-image"
                                    />
                                    <div className="chat-received-text-msg">
                                      {message.message}
                                    </div>
                                    <div className="chat-received-time">
                                      {" "}
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`chat-sent-msg ${
                                    message.isRead && "chat-sent-msg-open"
                                  }`}
                                >
                                  <div
                                    className="chat-sent-img-section"
                                    onClick={() => {
                                      showImage(message.file);
                                    }}
                                  >
                                    <img
                                      src={message.file}
                                      className="chat-sent-img"
                                    />
                                    <div className="chat-sent-text-msg">
                                      {message.message}
                                    </div>
                                    <div className="chat-sent-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }

                        if (message.messageType === "audio") {
                          return (
                            <div key={message.createdAt + index}>
                              {message.reciverId ===
                              props.userData.loginUser._id ? (
                                <div className="chat-received-msg">
                                  <div className="chat-received-voice-section">
                                    <audio
                                      controls
                                      preload="metadata"
                                      className="chat-voice-msg"
                                    >
                                      <source
                                        src={message.file}
                                        type="audio/ogg"
                                      />
                                    </audio>
                                    <div className="chat-received-text-msg">
                                      {message.message}
                                    </div>
                                    <div className="chat-received-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`chat-sent-msg ${
                                    message.isRead && "chat-sent-msg-open"
                                  }`}
                                >
                                  <div className="chat-sent-voice-section">
                                    <audio
                                      controls
                                      preload="metadata"
                                      className="chat-voice-msg"
                                    >
                                      <source
                                        src={message.file}
                                        type="audio/ogg"
                                      />
                                    </audio>
                                    <div className="chat-sent-text-msg">
                                      {message.message}
                                    </div>
                                    <div className="chat-sent-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }

                        if (message.messageType === "video") {
                          return (
                            <div key={message.createdAt + index}>
                              {message.reciverId ===
                              props.userData.loginUser._id ? (
                                <div className="chat-received-msg">
                                  <div className="chat-received-video-section">
                                    <video
                                      className="chat-video-msg"
                                      onClick={(e) => {
                                        playVideo(message.file);
                                      }}
                                    >
                                      <source
                                        src={message.file}
                                        type="video/mp4"
                                      />
                                    </video>
                                    <img
                                      src={playcircle}
                                      className="player-button-video"
                                      onClick={(e) => {
                                        playVideo(message.file);
                                      }}
                                    />
                                    <div className="chat-received-text-msg">
                                      {message.message}
                                    </div>
                                    <div className="chat-received-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`chat-sent-msg ${
                                    message.isRead && "chat-sent-msg-open"
                                  }`}
                                >
                                  <div className="chat-sent-video-section">
                                    <video
                                      className="chat-video-msg"
                                      onClick={(e) => {
                                        playVideo(message.file);
                                      }}
                                    >
                                      <source
                                        src={message.file}
                                        type="video/mp4"
                                      />
                                    </video>

                                    <img
                                      src={playcircle}
                                      className="player-button-video"
                                      onClick={(e) => {
                                        playVideo(message.file);
                                      }}
                                    />

                                    <div className="chat-sent-text-msg">
                                      {message.message}
                                    </div>

                                    <div className="chat-sent-time">
                                      {time.split(":")[0]}:{time.split(":")[1]}{" "}
                                      {time.split(" ")[1]}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }

                        if (message.messageType === "docs") {
                          return (
                            <div key={message.createdAt + index}>
                              {message.reciverId ===
                              props.userData.loginUser._id ? (
                                <div className="chat-received-msg">
                                  <div className="chat-received-file-section">
                                    <div className="chat-received-text-msg">
                                      <div className="chat-pdf-display-image-section">
                                        {/* <img
                                    src={profile}
                                    className="chat-pdf-display-img"
                                  /> */}
                                      </div>
                                      <a
                                        href={message.file}
                                        download
                                        className="pdf-file"
                                      >
                                        <div className="display-flex">
                                          {fileType === "pdf" && (
                                            <img
                                              src={filePDF}
                                              className="chat-pdf-image-file"
                                            />
                                          )}

                                          {fileType !== "pdf" && (
                                            <img
                                              src={file}
                                              className="chat-pdf-img"
                                            />
                                          )}
                                          <span>{message.displayFileName}</span>
                                          <div className="chat-pdf-details">
                                            {/* <div>2 pages .</div>
                                    <div>195 KB .</div> */}
                                            <div>
                                              {/* {message.displayFileName.split['.'][1]} */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chat-sent-time">
                                          {time.split(":")[0]}:
                                          {time.split(":")[1]}{" "}
                                          {time.split(" ")[1]}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`chat-sent-msg ${
                                    message.isRead && "chat-sent-msg-open"
                                  }`}
                                >
                                  <div className="chat-sent-file-section">
                                    <div className="chat-sent-text-msg">
                                      <div className="chat-pdf-display-image-section">
                                        {/* <img
                                    src={profile}
                                    className="chat-pdf-display-img"
                                  /> */}
                                      </div>
                                      <a
                                        href={message.file}
                                        download
                                        className="pdf-file"
                                      >
                                        <div className="display-flex">
                                          {fileType === "pdf" && (
                                            <img
                                              src={filePDF}
                                              className="chat-pdf-image-file"
                                            />
                                          )}

                                          {fileType !== "pdf" && (
                                            <img
                                              src={file}
                                              className="chat-pdf-img mt-1"
                                            />
                                          )}
                                          <span>{message.displayFileName}</span>
                                          <div className="chat-pdf-details">
                                            {/* <div>2 pages .</div>
                                    <div>195 KB .</div> */}
                                            <div>
                                              {/* {message.displayFileName.split['.'][1]} */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="chat-sent-time">
                                          {time.split(":")[0]}:
                                          {time.split(":")[1]}{" "}
                                          {time.split(" ")[1]}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }

                        {
                          !paymentLoading && (
                            <div
                              className={`chat-sent-msg ${
                                message.isRead && "chat-sent-msg-open"
                              }`}
                            >
                              <div className="chat-sent-text">
                                <div className="chat-sent-text-msg">
                                  <span className="loader-spiner  "></span>{" "}
                                  payment link sending..
                                </div>
                              </div>
                            </div>
                          );
                        }

                        if (message.messageType === "paymentlink") {
                          return (
                            <div
                              key={message.createdAt + index}
                              className="flex-center"
                            >
                              {message.reciverId ===
                              props.userData.loginUser._id ? (
                                <div className="payment-cointainer">
                                  {message.payinfo && message.payinfo._id && (
                                    <div>
                                      <div className="display-flex flex-gap-3r p-2">
                                        <div>Consultant</div>

                                        {message.chat_session_id && (
                                          <div>
                                            {
                                              message.chat_session_id
                                                .consultant_profile_name
                                            }
                                          </div>
                                        )}
                                      </div>

                                      <hr style={{ margin: "0px" }} />
                                      <div className="display-flex flex-gap-3r p-2">
                                        <div>Amount</div>
                                        <div>
                                          {message.payinfo.amount + " "}
                                          {message.payinfo.currency + " "}/{" "}
                                          {message.payinfo.money_In_KWD +
                                            " KWD"}
                                        </div>
                                      </div>
                                      <hr style={{ margin: "0px" }} />
                                      <div className="flex-center m-2">
                                        {!showPaymentType && (
                                          <>
                                            {!message.payinfo.IsPaid ? (
                                              <button
                                                className={`payment-pay-buttun ${
                                                  !message.payinfo.IsPaid &&
                                                  "payment-button-effect"
                                                } `}
                                                id={`${message.payinfo._id}_paystatus`}
                                                onClick={() => {
                                                  if (!message.payinfo.IsPaid)
                                                    setShowPaymentType(
                                                      message.uufid,
                                                    );
                                                }}
                                              >
                                                Pay
                                              </button>
                                            ) : (
                                              <div className="display-flex">
                                                <img
                                                  src={checkedImg}
                                                  alt="payment done"
                                                  className="mr-1"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                />
                                                <span className="payment-dim-text">
                                                  Payment done
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}

                                        {showPaymentType === message.uufid && (
                                          <div
                                            className="display-flex"
                                            style={{
                                              justifyContent: "space-evenly",
                                              width: "100%",
                                            }}
                                          >
                                            <div className="display-flex">
                                              <input
                                                type="radio"
                                                name="payment"
                                                value="Cards"
                                                className="mr-1"
                                                onChange={(e) => {
                                                  handlePaymentType(
                                                    e,
                                                    message.payinfo,
                                                  );
                                                }}
                                              />
                                              <label
                                                for="Cards"
                                                className="display-flex-align-center"
                                              >
                                                <HiCreditCard
                                                  size={32}
                                                  className="mr-1 HiCreditCard"
                                                />
                                                Card
                                              </label>
                                            </div>
                                            <div className="display-flex">
                                              <input
                                                type="radio"
                                                name="payment"
                                                value="Knet"
                                                className="mr-1"
                                                onChange={(e) => {
                                                  handlePaymentType(
                                                    e,
                                                    message.payinfo,
                                                  );
                                                }}
                                              />
                                              <label
                                                for="Knet"
                                                className="display-flex-align-center"
                                              >
                                                <img
                                                  src={KNETLogo}
                                                  alt="knet"
                                                  className="knet-card mr-1"
                                                />
                                                Knet
                                              </label>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="payment-cointainer">
                                  {message.payinfo && message.payinfo._id && (
                                    <div>
                                      <div className="display-flex flex-gap-3r p-2">
                                        <div>Consultant</div>
                                        {message.chat_session_id && (
                                          <div>
                                            {
                                              message.chat_session_id
                                                .consultant_profile_name
                                            }
                                          </div>
                                        )}
                                      </div>

                                      <hr style={{ margin: "0px" }} />
                                      <div className="display-flex flex-gap-3r p-2">
                                        <div>Amount</div>
                                        <div>
                                          {message.payinfo.amount + " "}
                                          {message.payinfo.currency + " "}/{" "}
                                          {message.payinfo.money_In_KWD +
                                            " KWD"}
                                        </div>
                                      </div>
                                      <hr style={{ margin: "0px" }} />
                                      <div className="flex-center m-2">
                                        {!message.payinfo.IsPaid ? (
                                          <button
                                            className={`payment-pay-buttun ${
                                              !message.payinfo.IsPaid &&
                                              "payment-button-effect"
                                            } `}
                                            id={`${message.payinfo._id}_paystatus`}
                                            // onClick={() => {
                                            //   if (!message.payinfo.IsPaid)
                                            //     payConsultant(message.payinfo);
                                            // }}
                                          >
                                            Waiting for payment to be done
                                          </button>
                                        ) : (
                                          <div className="display-flex">
                                            <img
                                              src={checkedImg}
                                              alt="payment done"
                                              className="mr-1"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            />
                                            <span className="payment-dim-text">
                                              Payment done
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}

                    {isloading && (
                      <div className="d-flex justify-content-center margin-top-5">
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="chat-page-msg-section">
                    <div className="chat-msg-area">
                      <div className="chat-clip-art">
                        <img src={clip} alt="clip" />
                      </div>
                      <div className="chat-type-area">
                        <textarea
                          className="chat-type-input"
                          placeholder="Message"
                          rows="1"
                          value={messageText}
                          onChange={(e) => {
                            setMessageText(e.target.value);
                            props.socket.emit("isTyping", {
                              typing: true,
                              reciverId: user._id,
                              userId: props.userData.loginUser._id,
                              userName: props.userData.loginUser.user_name,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              sendMessageHandler(user);
                              setMessageText("");
                              console.log(e.target.value.length);
                              e.target.setSelectionRange(0, 0);
                            }
                          }}
                        ></textarea>
                      </div>
                      <div className="chat-attchement-area">
                        <div className="chat-attchement-main-section">
                          <img
                            src={attachment}
                            alt="attachment"
                            onClick={chatattachment}
                          />
                          {showAttach && (
                            <div
                              className="chat-attchement-section"
                              id="chat_attachment"
                            >
                              <ul className="chat-attchement-ul-section">
                                <li
                                  className="chat-attchement-li-section"
                                  onClick={() => filePickerRef.current.click()}
                                >
                                  <button className="chat-attchement-icon chat-attch-file">
                                    <img src={attchfile} />
                                  </button>
                                  <div className="chat-attch-text animated">
                                    Document
                                  </div>
                                </li>
                                <li className="chat-attchement-li-section">
                                  <button className="chat-attchement-icon chat-attch-audio">
                                    <img src={attachaudio} />
                                  </button>
                                  <div className="chat-attch-text animated">
                                    Audio
                                  </div>
                                </li>
                                <li
                                  className="chat-attchement-li-section"
                                  onClick={() => imagePickerRef.current.click()}
                                >
                                  <button className="chat-attchement-icon chat-attch-image">
                                    <img src={attchimage} />
                                  </button>
                                  <div className="chat-attch-text animated">
                                    Gallery
                                  </div>
                                </li>
                                <li className="chat-attchement-li-section">
                                  <button className="chat-attchement-icon chat-attch-map">
                                    <img src={attachmap} />
                                  </button>
                                  <div className="chat-attch-text animated">
                                    Location
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}

                          <input
                            ref={imagePickerRef}
                            style={{ display: "none" }}
                            type="file"
                            onChange={ImagePickedHandler}
                          />

                          <input
                            ref={filePickerRef}
                            style={{ display: "none" }}
                            type="file"
                            onChange={filePickedHandler}
                          />
                        </div>
                        <div>
                          <img src={camera} alt="Camera" />
                        </div>
                      </div>
                    </div>
                    <div className="chat-voice-area">
                      <img
                        src={microphone}
                        className="voice-recorder"
                        alt={microphone}
                      />
                    </div>
                  </div>
                </>
              )}
              {!showChat && <div className="chat-nav"></div>}
              {chatBodyInitlizingwithlanguageSettings && (
                <div className="chat-initailizer">
                  <span className="loader-spiner"></span>
                  <span>Chat initializing</span>
                </div>
              )}
            </div>
          </div>
          <div
            className={`col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 chat-right-area ${
              !props.homeNavbarcollapse && ""
            }`}
            id="chat_remark_container"
            style={{ padding: "0px" }}
          >
            <div
              className="chat-box-right-border chat-box chat-remarks chat-box-right-border"
              id="chat_remarks"
              style={{
                background: "white",
                padding: "0px",
              }}
            >
              <div className="chat-nav chat-menu-border ">
                <div className="chat-nav-left chat-box-right-border">
                  <img
                    src={back}
                    alt="back"
                    className="chat-box-profile-svg-back chat-box-remarks-svg-back"
                    onClick={() => {
                      openTodoList("remarksarea");

                      if (!props.homeNavbarcollapse) {
                        document
                          .getElementsByClassName("tab-chat-center")[0]
                          .classList.remove("tab-chat-center-hidden");
                      }
                    }}
                  />
                  <div>
                    <div>Remarks & todo </div>
                  </div>
                </div>
                <div className="chat-nav-right chat-nav-position-relative ">
                  <div className="chat-more">
                    <img
                      src={more}
                      alt="more"
                      className="chat-icon"
                      onClick={() => setRemarkTodoShow(true)}
                    />
                  </div>
                </div>
              </div>

              {remarkTodoShow && user._id && (
                <SideBar>
                  <SidebarRemarksToDo
                    closeNav={() => setRemarkTodoShow(false)}
                    setChatRemarks={setChatRemarks}
                    token={props.userData.token}
                    user_id={props.userData.loginUser._id}
                    client_id={user._id}
                    user={user}
                  />
                </SideBar>
              )}
              {!remarkTodoShow && (
                <div>
                  {chatRemarks.length > 0 &&
                    chatRemarks.map((chatRemark) => {
                      if (chatRemark.type === "todo") {
                        var alerttime = new Date(
                          chatRemark.remarks_time,
                        ).toLocaleTimeString();
                        var alertdate = new Date(
                          chatRemark.remarks_time,
                        ).toLocaleDateString();

                        return (
                          <div
                            style={{
                              margin: "30px",
                            }}
                            key={chatRemark._id}
                          >
                            <div className="remark-alert-time">
                              {alertdate} {alerttime}
                            </div>
                            <div className="chat-remarks-profile-box">
                              <img
                                src={chatRemark.client_id.imageUrl}
                                alt="profile"
                                className="chat-remarks-profile-img-nav"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = profile;
                                }}
                              />
                              <div>
                                <div className="chat-remarks-username ">
                                  {chatRemark.client_id.user_name}
                                </div>
                                <div>
                                  {alertdate} {alerttime}
                                </div>
                              </div>
                            </div>

                            <div className="remarks-text">
                              {chatRemark.remarks_text}
                            </div>
                          </div>
                        );
                      }
                    })}

                  {chatRemarks.length > 0 &&
                    chatRemarks.map((chatRemark) => {
                      if (chatRemark.type === "remarks") {
                        var time = new Date(
                          chatRemark.remarks_time,
                        ).toLocaleTimeString();
                        var date = new Date(
                          chatRemark.remarks_time,
                        ).toLocaleDateString();

                        return (
                          <div
                            style={{
                              margin: "30px",
                            }}
                            key={chatRemark._id}
                          >
                            <div className="chat-remarks-profile-box">
                              <img
                                src={chatRemark.client_id.imageUrl}
                                alt="profile"
                                className="chat-remarks-profile-img-nav"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = profile;
                                }}
                              />
                              <div>
                                <div className="chat-remarks-username ">
                                  {chatRemark.client_id.user_name}
                                </div>
                                <div>
                                  {date} {time}
                                </div>
                              </div>
                            </div>

                            <div className="remarks-text">
                              {chatRemark.remarks_text}
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom d-block d-sm-none">
        <FooterMenu user={props.user} />
      </div>
    </>
  );
};

export default Chat2;
