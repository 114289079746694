import React, { useState, useEffect, useRef } from 'react'

import HomeNavbarVerticle from '../navbar/HomeNavbarVerticle'

import Profile from './Profile'

import FooterMenu from '../navbar/FooterMenu'

const ProfilePage = (props) => {
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12' style={{ margin: '0px', padding: '0px' }}>
            <Profile />
          </div>
        </div>
        <div className='fixed-bottom d-block d-sm-none'>
          <FooterMenu user={props.user} />
        </div>
      </div>
    </>
  )
}

export default ProfilePage
