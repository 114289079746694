import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import Button from "@material-ui/core/Button";
import { AiOutlineClose, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { connect } from "react-redux";
import { IoMicOutline } from "react-icons/io5";
import { IoMicCircle } from "react-icons/io5";
import Model from "../../utils/models/DefaultModel";
import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
import media from "../../images/photos.png";
import YTlink from "../../images/youtube.png";
import attachment from "../../images/attachment.png";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";

import {
  selectValueValue,
  selectIdValue,
  responseMessageDispaly,
  dataURLtoBlob,
  videoPickerHandler,
} from "../../utils/sharedfunction";

import useRecorder from "../../utils/hooks/useRecorder";
import VideoUpload from "../../utils/sharedComponent/VideoUpload";
const url = process.env.REACT_APP_BACKEND_URL;

const EditUserStoryModel = ({
  user,
  UserRequiredDropDown,
  showChatModel,
  onModelClose,
  modelType,
  dispatch,
  story,
}) => {
  let [audioURL, isRecording, startRecording, stopRecording, recorder] =
    useRecorder();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [file, setFile] = useState({
    type: story.story_type,
    updateoredit: "edit",
  });
  const [showCrop, setShowCrop] = useState(false);
  const [storyTextTitile, setStoryTextTitile] = useState(
    story.stories[0].story_title,
  );
  const [storyTextDesc, setStoryTextDesc] = useState(
    story.stories[0].story_text,
  );
  const [categories, setCategories] = useState([]);
  const languageRef = useRef(null);
  const storyTitleRef = useRef(null);
  const storyRef = useRef(null);
  const errormesseageRef = useRef(null);
  const storyTypeProvideRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const mediaIconsRef = useRef(null);
  const categoryRef = useRef(null);
  const fileResiderRef = useRef(null);
  const youtubeURLRef = useRef(null);
  const youtubeURL = useRef(null);
  const micRecoredStopRef = useRef(null);
  const micRecoredStartRef = useRef(null);
  const videoPickerRef = useRef(null);
  const videobioUpload = useRef(null);
  const videofileResiderRef = useRef(null);
  const TextSizeRef = useRef(null);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [languagecd, setLanguagecd] = useState();
  const [newCategory, setNewCategory] = useState("");

  useEffect(() => {
    if (recorder) {
      micRecoredStopRef.current.style.pointerEvents = "auto";
      micRecoredStopRef.current.style.opacity = "1";
    }
  }, [recorder]);

  useEffect(() => {
    if (audioURL) {
      fileResiderRef.current.innerHTML = "";
      var sound = document.createElement("audio");
      sound.controls = "controls";
      sound.src = URL.createObjectURL(audioURL);
      sound.setAttribute("style", "height: 30px");
      fileResiderRef.current.appendChild(sound);
      setFile({ type: "audio", file: audioURL });
    }
  }, [audioURL]);

  useEffect(() => {
    const categoryBasedOnLangaugeHandler = async (value) => {
      try {
        let categories = await categgoryBasedLangaugeBYID(value);
        setCategories(categories);
      } catch (err) {
        console.log(err);
        toast.error("failed to load category");
      }
    };

    categoryBasedOnLangaugeHandler(story.stories[0].story_language_id);

    setFile({ type: story.story_type, updateoredit: "edit" });
  }, [UserRequiredDropDown]);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const showModelIcons = () => {
    if (mediaIconsRef.current.style.display === "inline-block") {
      mediaIconsRef.current.style.display = "none";
    } else {
      mediaIconsRef.current.style.display = "inline-block";
    }
  };

  //console.log(story.type)
  // const selectFileHandler = () => {
  //   filePickerRef.current.click()
  // }

  const openProfileHandler = () => {
    setShowCrop(true);
  };
  const closeProfileHandler = () => setShowCrop(false);

  const imagefileInputHandler = async (file) => {
    var imgeFile = dataURLtoBlob(file);
    fileResiderRef.current.innerHTML = "";
    setFile({ type: "image", file: imgeFile, updateoredit: "update" });
    videobioUpload.current.style.display = "none";
    //let image = await createImage(reader.result)
    let image = await createImage(file);
    fileResiderRef.current.appendChild(image);
    fileResiderRef.current.style.width = "300px";
    fileResiderRef.current.style.height = null;
    image.style.width = "100%";
  };

  const filePickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = false;

    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];

      if (filExt === "jpg" || filExt === "jpeg" || filExt === "png") {
        fileResiderRef.current.innerHTML = "";
        pickedFile = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", async () => {
          try {
            let image = await createImage(reader.result);
            if (image.naturalWidth >= image.naturalHeight) {
              fileResiderRef.current.style.width = "300px";
              fileResiderRef.current.style.height = null;
              image.style.width = "100%";
            } else {
              fileResiderRef.current.style.height = "200px";
              fileResiderRef.current.style.width = "300px";
              image.style.height = "100%";
            }
            console.log(image);
            fileResiderRef.current.appendChild(image);
            var imgeFile = dataURLtoBlob(reader.result);
            setFile({ type: "image", file: imgeFile });
          } catch (err) {
            console.log(err);
            responseMessageDispaly(
              errormesseageRef,
              "Something went wrong",
              "red",
            );
          }
        });
        fileIsValid = true;
      } else if (fileExttype === "video") {
        fileResiderRef.current.innerHTML = "";
        pickedFile = event.target.files[0];
        let blobURL = URL.createObjectURL(pickedFile);
        var video = document.createElement("VIDEO");
        video.setAttribute("controls", "controls");
        video.setAttribute("src", blobURL);
        video.setAttribute("style", "width: 448px ; height: 252px");
        fileResiderRef.current.appendChild(video);
        setFile({ type: "video", file: pickedFile });
      } else {
        responseMessageDispaly(
          errormesseageRef,
          "select suitable file : jepg, png, jgp, mp4 ",
          "red",
        );
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const urlHandler = () => {
    var youtube = youtubeURL.current.value;
    if (youtube.includes("youtu")) {
      setFile({ type: "video_url", file: youtube });
    } else {
      responseMessageDispaly(
        errormesseageRef,
        "Paste only YouTube Link*",
        "Red",
      );
    }
  };

  const cancelYTURLhandler = () => {
    youtubeURLRef.current.style.display = "none";
    youtubeURL.current.value = "";
    setFile({ type: "text" });
  };

  const startRecord = () => {
    micRecoredStopRef.current.style.display = "inline-block";
    micRecoredStartRef.current.style.display = "none";
    startRecording();
  };
  const stopRecord = () => {
    micRecoredStopRef.current.style.display = "none";
    micRecoredStartRef.current.style.display = "inline-block";
    stopRecording();
  };

  const categgoryBasedOnLangaugeHandler = async (value) => {
    try {
      if (value !== "0") {
        let categories = await categgoryBasedLangaugeBYID(value);
        setCategories(categories);
        if (categories.length === 0) {
          setShowAddCategory(true);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("failed to load category");
    }
  };

  const videofileInputHandler = (file, imgeval) => {
    var imgeblob = dataURLtoBlob(imgeval);
    setFile({
      type: "video",
      file: file,
      thumbnail: imgeblob,
      updateoredit: "update",
    });
    videobioUpload.current.style.display = "block";
    fileResiderRef.current.innerHTML = "";
  };

  const sendStory = () => {
    const storyTittle = storyTitleRef.current.value;
    const language = selectValueValue(languageRef.current);
    if (language === "0") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet language",
        "Red",
      );
      return;
    }
    const storyText = storyRef.current.value;
    const user_id = user.loginUser._id;
    const category_id = selectValueValue(categoryRef.current);

    if (category_id === "0") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet category",
        "Red",
      );
      return;
    }
    const token = user.token;

    if (!storyTittle) {
      responseMessageDispaly(errormesseageRef, "Please enter title^", "Red");
      return;
    }

    if (!storyText) {
      responseMessageDispaly(errormesseageRef, "Please enter story*", "Red");
      return;
    }

    let formData = new FormData();
    formData.append("story_id", story._id);
    formData.append("user_id", user_id);
    formData.append("category_id", category_id);
    formData.append("story_language_id", language);
    formData.append("story_title", storyTittle);
    formData.append("story_text", storyText);
    formData.append("story_type", file.type);
    formData.append("updateoredit", file.updateoredit);
    if (file.type !== "video_url") {
      formData.append("file", file.file);
      formData.append("thumbnail", file.thumbnail);
    } else {
      formData.append("video_url", file.file);
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(`${url}/api/userProfile/editUserStory`, formData, options)
      .then((res) => {
        setUploadPercentage(0);
        setFile({ type: file.type, updateoredit: "edit" });
        errormesseageRef.current.innerHTML = "Success ";
        errormesseageRef.current.style.color = "green ";
        storyTitleRef.current.value = "";
        storyRef.current.value = "";
        fileResiderRef.current.innerHTML = "";
        fileResiderRef.current.style.width = null;
        fileResiderRef.current.style.height = null;
        if (file.type !== "audio") {
          youtubeURL.current.value = "";
          youtubeURLRef.current.style.display = "none";
        }

        getStories(1);

        //document.getElementById(res.data.body.subscription_id).click()
        onModelClose();
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentage(0);
        responseMessageDispaly(errormesseageRef, "System Failed", "red");
      });
  };

  const getStories = async (storyPage) => {
    try {
      if (user.loginUser._id) {
        const response = await fetch(
          `${url}/api/userProfile/getUserStories?page=${storyPage}&user_id=${user.loginUser._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          },
        );
        const responseData = await response.json();

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          } else {
            if (storyPage > 1) {
              toast.error("Something Went Wrong");
            } else {
              toast.error("Something Went Wrong");
            }
            //throw new Error(responseData.message || 'something went wrong')
          }
        } else {
          if (storyPage > 1) {
            console.log(responseData.body.getStoryModel[0]);
            dispatch({
              type: "sub_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({ type: "sub_group_stories", payload: responseData.body });
          }
          document.getElementById("story_box").scrollTop = 0;
        }
      }
    } catch (err) {
      if (storyPage > 1) {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
        console.log(err);
      }
    }
  };

  if (modelType === "defaultstory") {
    return (
      <Model show={showChatModel}>
        <Modal
          show={showCrop}
          onCancel={closeProfileHandler}
          headerClass="text-center"
          containerStyle={{ width: "50%" }}
        >
          <div className="map-container">
            <ImageCrop
              close={closeProfileHandler}
              onSave={imagefileInputHandler}
              aspectRatio={16 / 9}
              objectFit="horizontal-cover"
            />
          </div>
        </Modal>
        <div className="modal-container-content" style={{ width: "43%" }}>
          <header
            className="modal__header text-center"
            style={{ padding: "10px" }}
          >
            <h3>
              Add Story
              <AiOutlineClose
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
                onClick={() => {
                  onModelClose();
                  console.log("closed");
                  setFile({
                    type: story.story_type,
                    updateoredit: "edit",
                  });
                }}
              />
              <div
                style={{
                  display: "inline-block",
                  position: "absolute",
                  right: "25%",
                }}
                className="cursor-pointer "
              >
                <img
                  src={attachment}
                  style={{ height: 20 + "px" }}
                  alt="attachment"
                  onClick={showModelIcons}
                />
                <div
                  className="story-model-media"
                  ref={mediaIconsRef}
                  style={{
                    position: "fixed",
                  }}
                >
                  <img
                    src={media}
                    style={{ height: 20 + "px", marginRight: "5px" }}
                    alt="media"
                    onClick={openProfileHandler}
                  />
                  {/* <input
                    ref={filePickerRef}
                    style={{ display: 'none' }}
                    type='file'
                    accept='.jpg,.png,.jpeg,video/*'
                    onChange={filePickedHandler}
                  /> */}
                  {/* <img
                    src={YTlink}
                    style={{ height: 20 + 'px' }}
                    alt='youtubelink'
                    onClick={() => {
                      youtubeURLRef.current.style.display = 'block'
                    }}
                  /> */}

                  <AiOutlineVideoCameraAdd
                    size={25}
                    onClick={() => {
                      videoPickerHandler(videoPickerRef.current);
                      setFile({
                        type: "video",
                        updateoredit: "update",
                      });
                    }}
                  />
                  <VideoUpload
                    videoFilePickerRef={videoPickerRef}
                    videourlref={videobioUpload}
                    onvideofileInput={videofileInputHandler}
                  />
                </div>
              </div>
            </h3>
          </header>
          <div className="modal__content">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
              <div ref={errormesseageRef}></div>
              <div
                ref={fileResiderRef}
                style={{
                  margin: "auto",
                  textAlign: "center",
                  marginBottom: "1rem",
                  width: "300px",
                }}
              >
                {console.log(file.updateoredit)}
                {file.type === "image" && file.updateoredit !== "update" && (
                  <img
                    src={story.story_url}
                    style={{ width: "100%" }}
                    dubuku=""
                  />
                )}
              </div>
              <div
                ref={videofileResiderRef}
                style={{
                  margin: "auto",
                  textAlign: "center",
                  marginBottom: "1rem",
                  display: "block ruby",
                }}
              >
                {file.type === "video" && file.updateoredit === "edit" && (
                  <>
                    {console.log(file.updateoredit, "1")}
                    <video
                      controls
                      ref={videobioUpload}
                      style={{ height: "200px" }}
                      src={`${story.story_url}`}
                    >
                      Your browser does not support the video tag.
                    </video>
                  </>
                )}

                {file.updateoredit === "edit" && file.type === "image" && (
                  <video
                    controls
                    ref={videobioUpload}
                    style={{ height: "200px", display: "none" }}
                    src={`${story.story_url}`}
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {file.updateoredit === "update" && (
                  <>
                    {console.log(file.updateoredit, "2")}
                    <video
                      controls
                      ref={videobioUpload}
                      style={{ height: "200px", display: "none" }}
                    >
                      Your browser does not support the video tag.
                    </video>
                  </>
                )}
              </div>
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <div className="progress">
                      <div
                        className="progress-bar bg-success progress-bar-striped"
                        role="progressbar"
                        style={{
                          width: `${uploadPercentage}%`,
                          height: "100%",
                        }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Abort Creating Group - Uploading
                    </span>
                  </div>
                </div>
              )}
              <div
                ref={youtubeURLRef}
                style={{ width: "100%", marginBottom: "10px", display: "none" }}
              >
                <input
                  id="youtube-url"
                  style={{ width: "95%" }}
                  placeholder="Paste YouTube URL"
                  type="text"
                  ref={youtubeURL}
                  onChange={urlHandler}
                />
                <AiOutlineClose
                  style={{
                    float: "right",
                    cursor: "pointer",
                    marginTop: "4px",
                  }}
                  onClick={cancelYTURLhandler}
                />
              </div>
              <input
                id="story_title"
                style={{ width: "100%" }}
                placeholder="Title"
                type="text"
                ref={storyTitleRef}
                value={storyTextTitile}
                onChange={(e) => {
                  setStoryTextTitile(e.target.value);
                }}
              />
            </div>

            <div
              className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
              style={{ marginTop: "1rem " }}
            >
              <select
                id="langauge"
                style={{ width: "150px", marginRight: "10px" }}
                ref={languageRef}
                onChange={(e) => {
                  categgoryBasedOnLangaugeHandler(e.target.value);
                }}
                onLoad={(e) => {
                  categgoryBasedOnLangaugeHandler(e.target.value);
                }}
              >
                <option value="0">Select Langauge</option>)
                {UserRequiredDropDown.languages.map((list, index) => {
                  if (list._id == story.stories[0].story_language_id) {
                    return (
                      <option
                        key={index}
                        id={list.code}
                        value={list._id}
                        selected
                      >
                        {list.nativeName}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} id={list.code} value={list._id}>
                        {list.nativeName}
                      </option>
                    );
                  }
                })}
              </select>

              <select
                name="category"
                style={{ width: "150px", marginRight: "10px" }}
                ref={categoryRef}
              >
                <option value="0">Select Category</option>)
                {categories.length > 0 &&
                  categories.map((c, index) => {
                    if (c._id == story.stories[0].category_id) {
                      return (
                        <option
                          key={index}
                          id={c.language_cd}
                          value={c._id}
                          selected
                        >
                          {c.category_name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} id={c.language_cd} value={c._id}>
                          {c.category_name}
                        </option>
                      );
                    }
                  })}
              </select>

              <div style={{ marginTop: "1rem " }}>
                <textarea
                  className="mystory_status1 height-textarea-popup"
                  id="story_text"
                  style={{ width: "100%", height: "160px" }}
                  placeholder="Write Post"
                  ref={storyRef}
                  value={storyTextDesc}
                ></textarea>
              </div>

              <div style={{ clear: "both" }}></div>
            </div>
          </div>
          <footer className="modal__footer modal-footer-border ">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="push-right"
                onClick={sendStory}
              >
                Send Story
              </Button>
              <div className="clearfix"></div>
            </div>
          </footer>
        </div>
      </Model>
    );
  } else {
    return (
      <Model show={showChatModel}>
        <div className="modal-container-content" style={{ width: "43%" }}>
          <header
            className="modal__header text-center"
            style={{ padding: "10px" }}
          >
            <h3>
              Add Voice Note
              <AiOutlineClose
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
                onClick={onModelClose}
              />
              <div
                style={{
                  display: "inline-block",
                  position: "absolute",
                  right: "25%",
                }}
                className="cursor-pointer "
              ></div>
            </h3>
          </header>
          <div className="modal__content">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
              <div ref={errormesseageRef}></div>
              <input
                id="story_title"
                style={{ width: "100%" }}
                placeholder="Title"
                type="text"
                ref={storyTitleRef}
              />
              <div style={{ marginTop: "1rem " }}>
                <select
                  id="langauge"
                  style={{ width: "150px", marginRight: "10px" }}
                  ref={languageRef}
                >
                  <option value="0">Select Langauge</option>)
                  {UserRequiredDropDown.languages.map((list, index) => {
                    return (
                      <option key={index} id={list.code} value={list._id}>
                        {list.nativeName}
                      </option>
                    );
                  })}
                </select>
                <select
                  name="category"
                  style={{ width: "150px", marginRight: "10px" }}
                  ref={categoryRef}
                >
                  <option value="0">Select Category</option>)
                  {categories.length > 0 &&
                    categories.map((c, index) => {
                      return (
                        <option key={index} id={c.language_cd} value={c._id}>
                          {c.category_name}
                        </option>
                      );
                    })}
                </select>
                <select
                  id="storyTypeProvide"
                  style={{ width: "150px" }}
                  ref={storyTypeProvideRef}
                >
                  <option value="1">For All Subscribers</option>
                </select>
              </div>
              <div style={{ marginTop: "1rem " }}>
                <textarea
                  className="mystory_status1 height-textarea-popup"
                  id="audio_text"
                  style={{ width: "100%", height: "160px" }}
                  placeholder="Write Post"
                  ref={storyRef}
                ></textarea>
              </div>
            </div>
          </div>
          <footer className="modal__footer modal-footer-border ">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center ">
              <div
                ref={micRecoredStartRef}
                onClick={startRecord}
                style={{ display: "inline-block", float: "left" }}
              >
                <IoMicOutline size="30px" />
              </div>
              <div
                ref={micRecoredStopRef}
                onClick={stopRecord}
                style={{
                  display: "none",
                  float: "left",
                  pointerEvents: "none",
                  opacity: "0.5",
                }}
              >
                <IoMicCircle size="30px" />
                <span id="audiominutes">00</span>:
                <span id="audioseonds">00</span>
              </div>

              <div ref={fileResiderRef} style={{ display: "inline" }}></div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="push-right"
                onClick={sendStory}
              >
                Send Story
              </Button>
              <div className="clearfix"></div>
            </div>
          </footer>
        </div>
      </Model>
    );
  }
};

const MapStateToProps = ({ UserRequiredDropDown }) => {
  return { UserRequiredDropDown };
};

export default connect(MapStateToProps)(EditUserStoryModel);
