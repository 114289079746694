const defaultState = {
  userCredentials: {
    login_user_id: '',
    email: '',
    user_password: '',
    isEmailAndUserIdValid: false,
  },
}

const RegFourthSectionReducers = (state = defaultState, action) => {
  switch (action.type) {
    case 'userCredentials':
      return { ...state, userCredentials: action.payload }
    default:
      return state
  }
}

export default RegFourthSectionReducers
