import React from "react";

const CatgeorySearchBox = ({ categories, selecetCategory }) => {
  return (
    <div
      style={{
        background: "white",
        padding: "10px",
        position: "absolute",
        width: "100%",
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        zIndex: "1",
      }}
    >
      {categories.map((list, index) => {
        return (
          <div
            key={list._id}
            id={list._id}
            className="show-catgories"
            onClick={() => selecetCategory(list._id, list.category_name)}
          >
            {list.category_name}
          </div>
        );
      })}
    </div>
  );
};

export default CatgeorySearchBox;
