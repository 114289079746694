import React, { useRef } from 'react'

import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThridSection from './ThridSection'
import FourthSection from './FourthSection'
import FifthSection from './FifthSection'
import SixsthSection from './SixsthSection'

import $ from 'jquery'

const Register = ({ from, setFunction }) => {
  const sheftSections = (new_section, current_section) => {
    current_section.current.style.display = 'none'
    $(new_section.current).slideToggle()
    // $(current_section.current).slideToggle()
    // $(new_section.current).animate({
    //   width: 'toggle',
    // })
  }

  const FirstSectionRef = useRef(null)
  const SecondSectionRef = useRef(null)
  const ThridSectionRef = useRef(null)
  const FourthSectionRef = useRef(null)
  const FifthSectionRef = useRef(null)
  const SixsthSectionRef = useRef(null)
  const errorSectionRef = useRef(null)
  const progressBarRef = useRef(null)

  return (
    <>
      <div className='registration-area'>
        <marquee
          className='progress-bar'
          direction='right'
          scrollamount='30'
          ref={progressBarRef}
        >
          <div className='load-bar'>dsaddsadsadasdsadsa</div>
        </marquee>
        {/* <FirstSection
          sectionHolder={{
            sheftSections,
            CurrentSection: FirstSectionRef,
            NextSection: SecondSectionRef,
            errorSection: errorSectionRef,
            progressBar: progressBarRef,
          }}
        /> */}
        <SecondSection
          sectionHolder={{
            sheftSections,
            CurrentSection: SecondSectionRef,
            NextSection: ThridSectionRef,
            PreviousSection: FirstSectionRef,
            errorSection: errorSectionRef,
            progressBar: progressBarRef,
          }}
        />
        <ThridSection
          sectionHolder={{
            sheftSections,
            CurrentSection: ThridSectionRef,
            NextSection: FourthSectionRef,
            PreviousSection: SecondSectionRef,
            errorSection: errorSectionRef,
          }}
        />
        <FourthSection
          sectionHolder={{
            sheftSections,
            CurrentSection: FourthSectionRef,
            NextSection: FifthSectionRef,
            PreviousSection: ThridSectionRef,
            ForewordSection: SixsthSectionRef,
            progressBar: progressBarRef,
            errorSection: errorSectionRef,
          }}
        />
        <FifthSection
          sectionHolder={{
            sheftSections,
            CurrentSection: FifthSectionRef,
            NextSection: SixsthSectionRef,
            PreviousSection: FourthSectionRef,
            errorSection: errorSectionRef,
            progressBar: progressBarRef,
          }}
        />
        <SixsthSection
          sectionHolder={{
            sheftSections,
            CurrentSection: SixsthSectionRef,
            PreviousSection: FifthSectionRef,
            BackwordSection: FourthSectionRef,
            errorSection: errorSectionRef,
            progressBar: progressBarRef,
            from: from,
            setFunction: setFunction,
          }}
        />
      </div>
    </>
  )
}

export default Register
