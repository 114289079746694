import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios, { CancelToken, isCancel } from "axios";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import {
  AiOutlineUpload,
  AiOutlineClose,
  AiOutlineAudio,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

import Model from "../../utils/models/ModelStory";

import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

import defualtImg from "../../images/Read&ShareThumbnail.png";
import ReactPlayer from "react-player";
import defaultThumbmail from "../../images/Read&ShareThumbnail.png";
import addcoverorVideo from "../../images/icons/icon set-01.png";
import mic from "../../images/icons/mic.png";
import camera from "../../images/icons/camera.png";
import youtube from "../../images/icons/youtube.png";
import "./addStoryModel.css";

import { responseMessageDispaly } from "../../utils/sharedfunction";
import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";

import Price from "../newCourse/Price";
const AddLocationModel = ({
  user,
  onModelClose,
  showAddLocationModel,
  menuType,
  setNewLocationsAdded,
  newLocationsAdded,
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("0");
  const [languagecd, setLanguagecd] = useState();
  const [profileId, setProfileId] = useState("");
  const [category, setCategory] = useState("0");
  const [country, setCountry] = useState("0");
  const [newCategory, setNewCategory] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [categories, setcategories] = useState([]);
  const [countries, setCountires] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const errormesseageRef = useRef(null);
  const categoryRef = useRef(null);

  const { UserRequiredDropDown, ProfileReducers, FileUploadPercentage } =
    useSelector((state) => state);

  const { profiles } = ProfileReducers;

  useEffect(() => {
    getCountries();
    if (profiles.length > 0) {
      setProfileId(profiles[0]._id);
    }
  }, [profiles]);

  const getCountries = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getCountries`;

      const res = await getRequestedData(url);

      setCountires(res.data.body);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const onClose = () => {
    setAddress("");
    setCountry("0");
    setCategory("0");
    setState("");
    setCity("");
    setLanguage("0");
    setPhone("");
    setEmail("");
    setLatitude("");
    setLongitude("");
    if (profiles.length > 0) setProfileId(profiles[0]._id);
    setOfficeName("");
    onModelClose();
  };

  const addLoactions = async () => {
    if (profileId === "") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet organization",
        "Red",
      );
      return;
    }

    if (country === "0") {
      responseMessageDispaly(errormesseageRef, "Please selecet country", "Red");
      return;
    }
    const user_id = user.loginUser._id;

    try {
      const data = {
        user_id: user_id,
        country: country,
        state: state,
        city: city,
        address: address,
        phone: phone,
        email: email,
        profile_id: profileId,
        user_id: user_id,
        office_name: officeName,
      };
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addLocations`;
      const res = await getDataPostWithHeader(url, data, user.token);
      onClose();
      setNewLocationsAdded(!newLocationsAdded);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const orgHandler = (list, i, profile) => {
    document
      .querySelectorAll(".profile-bio-tab-story")
      .forEach((div) => (div.style.borderBottom = "none"));

    setProfileId(profile._id);
    document.getElementById(`lang_list_stroy_${i}`).style.borderBottom =
      "solid 1px #118acf";
  };

  return (
    <>
      <Model show={showAddLocationModel} onCancel={onClose}>
        <div
          className="container-box-model"
          style={{
            background: "#fff",
            paddingBottom: "10px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              padding: "15px",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: "0px",
              background: "white",
              zIndex: "1",
              borderBottom: "1px solid #dbdbdb",
            }}
          >
            <AiOutlineClose
              onClick={onClose}
              className="cursor-pointer"
              size={20}
            />
          </div>

          {
            <>
              <div className="row-model pt-2">
                <div className="col-md-12">
                  <div ref={errormesseageRef}></div>
                  <h3>Details</h3>
                </div>
                <div className={"col-md-12"}>
                  <div className="border-bottom-white margin-top-5">
                    {profiles.map((list, i) => {
                      if (i == 0) {
                        return (
                          <div
                            key={i}
                            id={`lang_list_stroy_${i}`}
                            className="profile-bio-tab profile-bio-tab-story ml-2"
                            style={{
                              borderBottom: "solid 1px #118acf",
                            }}
                            onClick={() => {
                              orgHandler(list.language_id, i, list);
                            }}
                          >
                            {list.profile_name}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            id={`lang_list_stroy_${i}`}
                            className="profile-bio-tab profile-bio-tab-story ml-2"
                            style={{
                              borderBottom: "none",
                            }}
                            onClick={() => {
                              orgHandler(list.language_id, i, list);
                            }}
                          >
                            {list.profile_name}
                          </div>
                        );
                      }
                    })}

                    <div
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      className="tooltip"
                      //onClick={selectLanguage}
                    >
                      <span style={{ fontSize: "20px" }}>+</span>
                      <span
                        style={{ fontSize: "10px" }}
                        //ref={createProfileRefMenu}
                        className="tooltiptext"
                      >
                        Create New Language for Profile
                      </span>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </div>
                <div
                  className={"col-md-12"}
                  style={{ height: "50vh", overflow: "auto" }}
                >
                  <div className="row">
                    <div className="col-md-7">
                      <select
                        className="form-control mt-2"
                        defaultValue={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                      >
                        <option value="0">Select Country</option>
                        {countries.length > 0 &&
                          countries.map((country) => {
                            return (
                              <option value={country._id} key={country._id}>
                                {country.country_english}
                              </option>
                            );
                          })}
                      </select>

                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          Office Name:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="officeName"
                          name="officeName"
                          placeholder="office name"
                          className="story-title"
                          value={officeName}
                          onChange={(e) => {
                            setOfficeName(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          State:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="State"
                          name="State"
                          placeholder="State"
                          className="story-title"
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          City:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="city"
                          name="city"
                          placeholder="City"
                          className="story-title"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          Address:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="adress"
                          name="adress"
                          placeholder="Address"
                          className="story-title"
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          Phone:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="phone"
                          className="story-title"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          Email:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="Email"
                          name="Email"
                          placeholder="email"
                          className="story-title"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      {/* <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          Latitude:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="latitude"
                          name="latitude"
                          placeholder="latitude"
                          className="story-title"
                          value={latitude}
                          onChange={(e) => {
                            setLatitude(e.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-2 text-box">
                        <label for="Title" title="Title">
                          Longitude:
                        </label>
                        <br />
                        <input
                          type="text"
                          id="longitude"
                          name="longitude"
                          placeholder="longitude"
                          className="story-title"
                          value={longitude}
                          onChange={(e) => {
                            setLongitude(e.target.value);
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="push-right"
                      onClick={addLoactions}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </Model>
    </>
  );
};

export default AddLocationModel;
