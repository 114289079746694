import React, { useState } from "react";
import { useEffect } from "react";
import parse from "html-react-parser";
import defaultThumbnail from "../../images/default-thumbnail.png";
import { AiOutlinePlayCircle, AiOutlineClose } from "react-icons/ai";
import ModelV from "../../utils/models/DefaultModel";
import VideoModel from "../VideoModel";
import ListSubscriptionGroup from "./ListSubscriptionGroup";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import { Margin } from "@mui/icons-material";
const weburl = process.env.REACT_APP_BACKEND_URL;
const BioArea = ({ briefCV, briefIndex }) => {
  const [showVideo, setshowVideo] = useState({ isShowVideo: false, url: "" });
  const [categories, setCategories] = useState([]);

  const playVideo = (url) => {
    setshowVideo({ isShowVideo: true, url: url });
  };

  const closeVideoPlayer = () => {
    setshowVideo({ isShowVideo: false, url: "" });
  };

  useEffect(() => {
    setCategories(briefCV[briefIndex].categories);
  }, [briefIndex]);

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }
  return (
    <div className="row">
      <div className="m-1 mb-4 col-md-6 col-sm-12" id="bio_area">
        {(briefCV[briefIndex].video_url ||
          briefCV[briefIndex].video_thumbnail) && (
          <div>
            {showVideo.isShowVideo && (
              <div className="col-md-12">
                <VideoModel
                  src={briefCV[briefIndex].video_url}
                  video_encode_process_status={
                    briefCV[briefIndex].video_encode_process_status
                  }
                  video_encode_processed={
                    briefCV[briefIndex].video_encode_processed
                  }
                  hlsUrl={briefCV[briefIndex].video_converted_url}
                  videoQaulityVersion={
                    briefCV[briefIndex].video_url_quality_versions
                  }
                  onClose={closeVideoPlayer}
                  type={briefCV[briefIndex].profile_type}
                />
              </div>
            )}

            {!showVideo.isShowVideo && (
              <>
                {briefCV[briefIndex].profile_type !== "video_url" && (
                  <div id="thumbnail_div">
                    <img
                      id="thumnail_bio"
                      src={briefCV[briefIndex].video_thumbnail}
                      className="width-100 cursor-pointer bio-consultant"
                      alt="thumbnail"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defaultThumbnail;
                      }}
                      onClick={() => {
                        if (briefCV[briefIndex].video_url)
                          playVideo(briefCV[briefIndex].video_url);
                      }}
                    />
                    {briefCV[briefIndex].video_url && (
                      <AiOutlinePlayCircle
                        style={{
                          width: "50px",
                          height: "50px",
                          position: "absolute",
                          left: "45%",
                          top: "40%",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (briefCV[briefIndex].video_url)
                            playVideo(
                              briefCV[briefIndex].video_url,
                              briefCV[briefIndex].profile_type,
                            );
                        }}
                      />
                    )}
                  </div>
                )}

                {briefCV[briefIndex].profile_type === "video_url" && (
                  <div
                    id="thumbnail_div"
                    className="text-center mt-3"
                    style={{ position: "relative" }}
                  >
                    {briefCV[briefIndex].video_thumbnail === "" &&
                      briefCV[briefIndex].video_url === "" && (
                        <img
                          className="width-100 cursor-pointer bio-consultant"
                          src={`https://img.youtube.com/vi/${getId(
                            briefCV[briefIndex].video_url,
                          )}/maxresdefault.jpg`}
                          alt="im-story"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defualtImg;
                          }}
                          onClick={() => {
                            if (briefCV[briefIndex].video_url)
                              playVideo(
                                briefCV[briefIndex].video_url,
                                briefCV[briefIndex].profile_type,
                              );
                          }}
                        />
                      )}

                    {briefCV[briefIndex].video_thumbnail !== "" &&
                      briefCV[briefIndex].video_url === "" && (
                        <img
                          id="thumnail_bio"
                          src={briefCV[briefIndex].video_thumbnail}
                          className="width-100 cursor-pointer bio-consultant"
                          alt="thumbnail"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defaultThumbnail;
                          }}
                          onClick={() => {
                            if (briefCV[briefIndex].video_url)
                              playVideo(
                                briefCV[briefIndex].video_url,
                                briefCV[briefIndex].profile_type,
                              );
                          }}
                        />
                      )}
                    {briefCV[briefIndex].video_url && (
                      <VideoModel
                        src={briefCV[briefIndex].video_url}
                        hlsUrl={briefCV[briefIndex].video_converted_url}
                        videoQaulityVersion={
                          briefCV[briefIndex].video_url_quality_versions
                        }
                        onClose={closeVideoPlayer}
                        type={briefCV[briefIndex].profile_type}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}

        <div>
          <div className="profile-name-title">
            <span> {briefCV[briefIndex].profile_name}</span>
          </div>

          <div
            className=""
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div className="proifile-category">
              {categories.length > 0 &&
                categories.map((category, index) => {
                  return (
                    <div key={index} className="mr-1">
                      <div>
                        {category.category_name}
                        {index < categories.length - 1 && <span>,</span>}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div
            className={`${
              briefCV[briefIndex].language_id.code === "ar"
                ? "text-right"
                : "text-left"
            } mt-2 profile-breif `}
          >
            {briefCV[briefIndex].brief_cv
              ? parse(briefCV[briefIndex].brief_cv)
              : briefCV[briefIndex].brief_cv}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioArea;
