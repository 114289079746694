import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify'
import ReactPlayer from 'react-player'
import { fetchStory, buySubscriptionGroup } from '../functions/story/story'
import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from '../functions/editProfile/editProfile'
import LoadingCard from '../Components/cards/LoadingCard'

import defualtImg from '../images/Read&ShareThumbnail.png'
import defualtProfile from '../images/default.jpeg'
import Model from '../utils/models/DefaultModel'
const weburl = process.env.REACT_APP_BACKEND_URL

const ViewSubStories = ({ user }) => {
  const params = useParams()

  const dateoptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const [story, setStory] = useState({})
  const [storyPage, setStoryPage] = useState(1)
  const [totalStoryPage, setTotalStoryPage] = useState()
  const [loading, setLoading] = useState()
  const [showSignin, setShowSignin] = useState(false)
  const [showDummyCard, setShowDummyCard] = useState(false)
  const dispatch = useDispatch()
  const { CatStories } = useSelector((state) => ({ ...state }))

  useEffect(() => {
    document.body.style.backgroundColor = '#f6f6f6'
    storyDetails(params.id)
  }, [params])

  useEffect(() => {
    document.addEventListener('scroll', handleScrolListner)
    return () => {
      document.removeEventListener('scroll', handleScrolListner)
    }
  }, [storyPage, totalStoryPage])

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1
    const scrolled = window.scrollY
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight

    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        if (story.subscription_id._id) {
          loadStoriesBasedOnCategoryHandler(
            story.subscription_id._id,
            storyPage
          )
        }
      }
    }
  }

  const storyDetails = async (story_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getSubStory?story_id=${story_id}`
      const res = await getRequestedDataWithHeader(url, user.userData.token)
      console.log(res)
      setStory(res.data.body)
      loadStoriesBasedOnCategoryHandler(res.data.body.subscription_id._id, 1)
    } catch (err) {
      console.log(err.message)
      toast.error(err.message)
    }
  }

  const loadStoriesBasedOnCategoryHandler = async (
    subscription_id,
    storyPageNo
  ) => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getSubscribeBasedStories?subscription_id=${subscription_id}&page=${storyPageNo}`
    try {
      let res = await getRequestedDataWithHeader(url, user.userData.token)
      console.log(res.data.body)
      setStoryPage(storyPageNo + 1)
      if (storyPageNo > 1) {
        dispatch({
          type: 'cat_stories_append',
          payload: res.data.body.getStoryModel,
        })
      } else {
        dispatch({ type: 'cat_stories', payload: res.data.body })
      }
      setTotalStoryPage(res.data.body.Total_Page)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err.message)
      toast.error(err.message)
    }
  }

  const subscribeGroup = () => {
    setShowSignin(!user.isLogin)
  }

  const closeDummyCard = () => setShowDummyCard(false)

  // const buysubscribeGroup = async () => {
  //   if (user.isLogin) {
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/purchaseSubscriptionGroups`
  //     const data = {
  //       user_id: user.userData.loginUser._id,
  //       subscription_id: story.subscription_id._id,
  //     }
  //     try {
  //       let res = await buySubscriptionGroup(url, data, user.userData.token)
  //       console.log(res.data.body)
  //     } catch (err) {
  //       console.log(err)
  //       toast.error(err.message)
  //     }
  //   }
  // }

  return (
    <>
      <Model show={showDummyCard}>
        <div className='modal-container-content' style={{ width: '43%' }}>
          <header
            className='modal__header text-center'
            style={{ padding: '10px' }}
          >
            <h3>
              Enter Your debit card details(dummy)
              <AiOutlineClose
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  marginTop: '4px',
                }}
                onClick={closeDummyCard}
              />
              <div
                style={{
                  display: 'inline-block',
                  position: 'absolute',
                  right: '25%',
                }}
                className='cursor-pointer '
              ></div>
            </h3>
          </header>
          <div className='modal__content'>
            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 '>
              <input
                style={{ width: '100%' }}
                placeholder='4444 4444 4444 4444'
                type='text'
              />
            </div>
            <div className='mt-2 text-center'>
              <button className='btn btn-primary'>subscribe</button>
            </div>
          </div>
        </div>
      </Model>
      <div className='container-fluid'>
        <div className='row mt-3 mr-5 ml-5'>
          <div className='col-md-8'>
            {story._id && story.story_type == 'video_url' && (
              <>
                <ReactPlayer
                  url={story.story_url}
                  controls
                  height='500px'
                  width='100%'
                />
                <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -{' '}
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && story.story_type == 'text' && (
              <>
                <img
                  style={{
                    height: '500px',
                    objectFit: 'cover',
                    width: '100%',
                  }}
                  src={story.story_url}
                  alt='im-story'
                  onError={(e) => {
                    e.target.onError = null
                    e.target.src = defualtImg
                  }}
                />
                <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && story.story_type == 'image' && (
              <>
                <img
                  style={{
                    height: '500px',
                    objectFit: 'cover',
                    width: '100%',
                  }}
                  src={story.story_url}
                  alt='im-story'
                  onError={(e) => {
                    e.target.onError = null
                    e.target.src = defualtImg
                  }}
                />
                <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && story.story_type == 'audio' && (
              <>
                <audio
                  controls
                  src={story.story_url}
                  className='story-margin '
                  style={{ width: '100%' }}
                ></audio>
                <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -{' '}
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr />
              </>
            )}

            {story._id && (
              <div
                className='mt-2'
                style={{
                  fontSize: '14px',
                }}
              >
                <div>
                  <img
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: '50%',
                      float: 'left',
                    }}
                    src={story.subscription_id.group_image_url}
                    alt='im-story'
                    onError={(e) => {
                      e.target.onError = null
                      e.target.src = defualtImg
                    }}
                  />
                  <div
                    style={{
                      fontWeight: '500',
                      color: 'black',
                      marginLeft: '10px',
                      float: 'left',
                    }}
                  >
                    {story.subscription_id.group_name}
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
                <div className='mt-3'>{story.story_text}</div>
              </div>
            )}
          </div>
          <div className='col-md-4'>
            {CatStories.stories.getStoryModel.length > 0 &&
              CatStories.stories.getStoryModel.map((story, i) => {
                if (story._id !== params.id)
                  if (story.story_type == 'text') {
                    return (
                      <div
                        className='pb-3'
                        style={{ cursor: 'pointer' }}
                        key={i}
                        onClick={() => {
                          setStory(story)
                          loadStoriesBasedOnCategoryHandler(
                            story.subscription_id._id,
                            1
                          )
                        }}
                      >
                        <img
                          style={{
                            height: '108px',
                            objectFit: 'cover',
                            width: '192px',
                            float: 'left',
                          }}
                          src={story.story_url}
                          alt='im-story'
                          onError={(e) => {
                            e.target.onError = null
                            e.target.src = defualtImg
                          }}
                        />
                        <div
                          style={{
                            float: 'left',
                            marginLeft: '10px',
                          }}
                        >
                          <div
                            style={{
                              fontFamily: 'sans-serif',
                              fontWeight: '500',
                              fontSize: '18px',
                              color: 'black',
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: '#737373' }}>
                            {story.subscription_id.group_name}
                          </div>
                          <div
                            style={{
                              color: '#7373730',
                              fontSize: '14px',
                              fontWeight: '300',
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    )
                  } else if (story.story_type == 'audio') {
                    return (
                      <div
                        className='pb-3'
                        style={{ cursor: 'pointer' }}
                        key={i}
                        onClick={() => {
                          setStory(story)
                          loadStoriesBasedOnCategoryHandler(
                            story.subscription_id._id,
                            1
                          )
                        }}
                      >
                        <img
                          style={{
                            height: '108px',
                            objectFit: 'cover',
                            width: '192px',
                            float: 'left',
                          }}
                          src={story.story_url}
                          alt='im-story'
                          onError={(e) => {
                            e.target.onError = null
                            e.target.src = defualtImg
                          }}
                        />
                        <div
                          style={{
                            float: 'left',
                            marginLeft: '10px',
                          }}
                        >
                          <div
                            style={{
                              fontFamily: 'sans-serif',
                              fontWeight: '500',
                              fontSize: '18px',
                              color: 'black',
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: '#737373' }}>
                            {story.subscription_id.group_name}
                          </div>
                          <div
                            style={{
                              color: '#7373730',
                              fontSize: '14px',
                              fontWeight: '300',
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    )
                  } else if (story.story_type == 'video_url') {
                    return (
                      <div
                        className='pb-3'
                        style={{ cursor: 'pointer' }}
                        key={i}
                        onClick={() => {
                          setStory(story)
                          loadStoriesBasedOnCategoryHandler(
                            story.subscription_id._id,
                            1
                          )
                        }}
                      >
                        <ReactPlayer
                          url={story.story_url}
                          controls
                          height='108px'
                          width='192px'
                          style={{
                            float: 'left',
                          }}
                        />
                        <div
                          style={{
                            float: 'left',
                            marginLeft: '10px',
                          }}
                        >
                          <div
                            style={{
                              fontFamily: 'sans-serif',
                              fontWeight: '500',
                              fontSize: '18px',
                              color: 'black',
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: '#737373' }}>
                            {story.subscription_id.group_name}
                          </div>
                          <div
                            style={{
                              color: '#7373730',
                              fontSize: '14px',
                              fontWeight: '300',
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    )
                  }
              })}
            {loading && <LoadingCard count={3} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewSubStories
