import React, { useRef, useState } from "react";
import axios, { CancelToken, isCancel } from "axios";
import attachment from "../../images/attachment.png";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";

import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";
import { useEffect } from "react";
const url = process.env.REACT_APP_BACKEND_URL;
const UserStoryAttachments = ({ story, user, token }) => {
  const filePickerRef = useRef(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [attachments, setAttachemnts] = useState([]);
  const cancelFileUpload = useRef(null);

  useEffect(() => {
    getAttachments(story._id);
  }, [story]);

  const getAttachments = async (story_id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/attachments?story_id=${story_id}`;
    let res = await getRequestedDataWithHeader(url, token);
    setAttachemnts(res.data.body);
  };

  const deleteAttachment = async (attachment_id) => {
    let text = "Are you Sure you want to Delete ! ";
    if (window.confirm(text) == true) {
      const data = {
        attachment_id: attachment_id,
        story_id: story._id,
      };
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/delete-attachment`;
      let res = await getDataPostWithHeader(url, data, token);
      setAttachemnts(res.data.body);
    }
  };

  const selectFileHandler = () => {
    filePickerRef.current.click();
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
  };

  const filePickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = false;

    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];

      console.log(fileExttype, filExt, event.target.files[0].type);

      if (filExt === "pdf") {
        pickedFile = event.target.files[0];
        const reader = new FileReader();
        // reader.readAsDataURL(event.target.files[0])
        // reader.addEventListener('load', async () => {
        //   sendStory(reader.result)
        // })
        sendStory(pickedFile);
        fileIsValid = true;
      } else {
        toast.error("Select suitable file");
        return;
      }
    }
  };

  const sendStory = (file) => {
    let formData = new FormData();
    formData.append("story_id", story._id);
    formData.append("user_id", user._id);
    formData.append("story_type", "stafs");
    formData.append("display_doc_name", file.name);
    formData.append("file", file);

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .post(`${url}/api/userProfile/sendAttachments`, formData, options)
      .then((res) => {
        setAttachemnts(res.data.body);
        setUploadPercentage(0);

        //document.getElementById(res.data.body.subscription_id).click()
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentage(0);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={attachment}
            style={{ height: 20 + "px" }}
            alt="attachment"
            onClick={selectFileHandler}
          />
          <input
            ref={filePickerRef}
            style={{ display: "none" }}
            type="file"
            onChange={filePickedHandler}
          />
        </div>
        {uploadPercentage > 0 && (
          <div className="row mt-3">
            <div className="col pt-1">
              <div className="progress">
                <div
                  className="progress-bar bg-success progress-bar-striped"
                  role="progressbar"
                  style={{
                    width: `${uploadPercentage}%`,
                    height: "100%",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="col-auto">
              <span
                className="text-primary cursor-pointer"
                onClick={() => cancelUpload()}
              >
                Abort Creating Group - Uploading
              </span>
            </div>
          </div>
        )}
      </div>
      <table className="table table-striped mt-2" style={{ cursor: "pointer" }}>
        <tbody>
          {attachments.length >= 0 &&
            attachments.map((attachment, index) => {
              return (
                <tr key={index}>
                  <td>
                    {" "}
                    <Link
                      to={attachment.url}
                      download={attachment.url}
                      target="_blank"
                    >
                      {attachment.display_doc_name}
                    </Link>
                  </td>
                  <td
                    className="text-right"
                    onClick={() => deleteAttachment(attachment._id)}
                  >
                    <BsTrash />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default UserStoryAttachments;
