const initialState = {
  wishListTotal: 0,
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "WISHLIST":
      return { wishListTotal: action.payload };
    case "WISHLISTAPPEND":
      let wishLT = state.cartTotal + action.payload;
      return { wishListTotal: wishLT };
    default:
      return state;
  }
};

export default CartReducer;
