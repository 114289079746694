import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

import { BsArrowLeft, BsCheck2 } from "react-icons/bs";
import profileDefualt from "../../images/default.jpeg";
import addcover from "../../images/default-thumbnail.png";

import VideoJS from "../VideoJS";
import HlsPlayer from "../../Components/players/HlsPlayer";

const CourseIntroImageVideo = ({ group_info }) => {
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const bioImageRef = useRef(null);
  const videoRef = useRef(null);

  const history = useHistory();

  return (
    <>
      <div id="profile_area" style={{ paddingBottom: "2.5rem" }}>
        <div className="mt-3">
          <div
            className="profile-image-div"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <BsArrowLeft
                size={20}
                className="cursor-pointer mr-2"
                onClick={() => {
                  history.goBack();
                }}
              />
            </div>
            <div style={{ height: "80px" }}>
              <img
                className="user-image"
                src={group_info.group_image_url}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
                alt="profile"
              />
            </div>
          </div>
          <div className="profile-basic-details-div">
            <div>
              <span
                style={{
                  fontSize: "20px",
                  marginTop: "10px",
                  fontWeight: "600",
                }}
              >
                {group_info.group_name}
              </span>
              <div>
                {group_info.category_id && group_info.category_id.category_name}
              </div>
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
        <div>
          <div
            id="thumbnail_div"
            className="text-center"
            style={{ position: "relative" }}
          >
            {group_info.group_introduction_video_url && (
              <>
                {group_info.converted_url !== "" &&
                  HLSVIDEOPLAYER &&
                  group_info.video_encode_processed &&
                  group_info.video_encode_process_status === "completed" && (
                    <HlsPlayer
                      url={group_info.converted_url}
                      setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                    />
                  )}

                {!group_info.video_encode_processed && (
                  <div>
                    <video
                      id="play_video"
                      src={group_info.group_introduction_video_url}
                      poster={group_info.group_video_thumbnail_url}
                      width="100%"
                      controls
                      ref={videoRef}
                      autoPlay
                    ></video>
                  </div>
                )}

                {!group_info.converted_url &&
                  group_info.video_encode_processed && (
                    <video
                      controls
                      style={{ width: "100%", borderRadius: "10px" }}
                      poster={group_info.group_video_thumbnail_url}
                      src={`${group_info.group_introduction_video_url}`}
                    ></video>
                  )}
              </>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "24px",
            }}
          >
            <div
              id="text_brief"
              className="language-bio"
              style={{ height: "50px" }}
            >
              {group_info.group_description &&
                parse(group_info.group_description)}
            </div>
          </div>

          <div style={{ padding: "10px" }}>
            <h4 style={{ borderBottom: "1px solid #d1d7dc" }}>
              What You you Will Learn
            </h4>
            {group_info.learn_text.length > 0 &&
              group_info.learn_text.map((learn) => {
                return (
                  <div key={learn._id}>
                    <BsCheck2 size={20} style={{ marginRight: "10px" }} />
                    {learn.your_response}
                  </div>
                );
              })}
          </div>

          <div style={{ padding: "10px" }} className="mt-4">
            <h4 style={{ borderBottom: "1px solid #d1d7dc" }}>
              Pre Requisites to take the course
            </h4>
            {group_info.requirements.length > 0 &&
              group_info.requirements.map((req) => {
                return (
                  <div key={req._id}>
                    <BsCheck2 size={20} style={{ marginRight: "10px" }} />
                    {req.your_response}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseIntroImageVideo;
