import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Pagination } from "antd";

import { getRequestedData } from "../functions/editProfile/editProfile";

const Rpt = () => {
  const [users, SetUsers] = useState([]);
  const [totalUsers, SetTotalUsers] = useState("");
  const [totalPage, SetTotalPage] = useState("");
  const [load, setLoad] = useState(1);
  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/userLists?page=${load}`;
    getRequestedData(url)
      .then((res) => {
        console.log(res.data.body);
        SetUsers(res.data.body.users);
        SetTotalPage(res.data.body.Total_Page);
        SetTotalUsers(res.data.body.totalUsers);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, [load]);

  const pagination = (page) => {
    setLoad(page);
  };

  const userRegistered = (createdAt) => {
    var localDate = new Date(createdAt);
    return localDate.toLocaleDateString();
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <table className="table table-bordered table-striped">
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Country</th>
                <th scope="col">Date of Registration</th>
              </tr>
            </thead>
            <tbody>
              {users.map((u) => (
                <tr key={u._id}>
                  <td>{u.user_name}</td>
                  <td>
                    {u.country_id ? u.country_id.country_english : "null"}
                  </td>
                  <td>{u.createdAt ? userRegistered(u.createdAt) : "null"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalPage && (
            <Pagination
              total={totalUsers}
              defaultCurrent={1}
              onChange={pagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Rpt;
