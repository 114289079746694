import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

import { toast } from "react-toastify";
import parse from "html-react-parser";

import { getRequestedDataWithHeader } from "../functions/editProfile/editProfile";

import { Link } from "react-router-dom";

import defualtImg from "../images/Read&ShareThumbnail.png";

import UserStoryAttachments from "../Components/profile/UserStoryAttachments";

const ViewProfileStory = ({ user }) => {
  const params = useParams();

  const dateoptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const [story, setStory] = useState({});
  const [attachments, setAttachments] = useState(false);
  const [index, setIndex] = useState(0);

  //   const { CatStories } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    document.body.style.backgroundColor = "white";

    if (user.isLogin) {
      storyDetails(params.id);
    }
  }, [params, user]);

  const storyDetails = async (expert_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getUserExpert?expert_id=${expert_id}&user_id=${user.userData.loginUser._id}`;

      const res = await getRequestedDataWithHeader(url, user.userData.token);
      console.log("stories", res.data.body);

      setStory(res.data.body);

      //setLanguageStory(res.data.body.stories);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const langaugeHandler = (i) => {
    if (i >= 0) setIndex(i);

    document.querySelectorAll(".profile-bio-tab").forEach((div) => {
      div.classList.remove("language-select-border");
    });
    document
      .getElementById(`lang_list_${i}`)
      .classList.add("language-select-border");
  };

  return (
    <>
      <div className="container">
        <div className="row mt-2 mr-5 ml-5 no-margin-mobile-left no-margin-mobile-right">
          <div className="col-md-9">
            {story._id && story.story_type == "stafs" && (
              <>
                <div className="image-warapper-ratio-adujestment-user-story">
                  <img
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                    className="image-whereplayer-shows-user-story"
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
              </>
            )}

            {!attachments && story._id && (
              <>
                <div
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,.1)",
                  }}
                  className="mt-3"
                >
                  {/* {story._id &&
                    story.map((list, i) => {
                      if (i == 0) {
                        return (
                          <div
                            key={i}
                            id={`lang_list_${i}`}
                            className="profile-bio-tab ml-2 language-select-border"
                            onClick={(e) => {
                              langaugeHandler(i);
                            }}
                          >
                            {list.languages.name}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            id={`lang_list_${i}`}
                            className="profile-bio-tab ml-2"
                            onClick={(e) => {
                              langaugeHandler(i);
                            }}
                          >
                            {list.languages.name}
                          </div>
                        );
                      }
                    })} */}

                  {story.story_type === "stafs" && (
                    <div
                      className="add-attchment-tab profile-bio-tab ml-2"
                      id={`lang_list_${-1}`}
                      onClick={(e) => {
                        setAttachments(true);
                        langaugeHandler(-1);
                      }}
                    >
                      Attachments
                    </div>
                  )}
                  <div style={{ clear: "both" }}></div>
                </div>

                <>
                  <div
                    className={`${
                      story.languages.code === "ar" ? "text-right" : "text-left"
                    } mt-3`}
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    {story.story_title}
                  </div>

                  <div
                    className="mt-2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#737373",
                    }}
                  >
                    Posted -{" "}
                    {new Date(story.createdAt).toLocaleDateString(
                      "en-US",
                      dateoptions,
                    )}
                  </div>
                </>

                <hr />

                {story._id && (
                  <div
                    className="mt-2"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <Link
                        to={`/consultant-profile?user_id=${story.user_id._id}`}
                        style={{ color: "black" }}
                      >
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                            float: "left",
                          }}
                          src={story.user_id.imageUrl}
                          alt="im-story"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defualtImg;
                          }}
                        />
                        <div
                          style={{
                            fontWeight: "500",
                            color: "black",
                            marginLeft: "10px",
                            float: "left",
                          }}
                        >
                          {story.user_id.user_name}
                        </div>
                      </Link>
                      <div></div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                    <div
                      className={`${
                        story.languages.code === "ar"
                          ? "text-right"
                          : "text-left"
                      } mt-3`}
                    >
                      {parse(story.story_text)}
                    </div>
                  </div>
                )}
              </>
            )}

            {attachments && user.isLogin && (
              <div className="mt-3">
                <div className="attachment-tabs ">
                  <div>Attachments</div>
                  <div>
                    <AiOutlineClose
                      onClick={() => {
                        setAttachments(false);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
                <hr />
                <UserStoryAttachments
                  story={story}
                  user={user.userData.loginUser}
                  token={user.userData.token}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfileStory;
