import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { BsThreeDotsVertical } from "react-icons/bs";
import profileDefualt from "../../images/default.jpeg";
import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
} from "../../functions/editProfile/editProfile";
import ConsultantStoryCard from "../cards/ConsultantStoryCard";

import {
  responseMessage,
  responseMessageClear,
} from "../../utils/sharedfunction";

const url = process.env.REACT_APP_BACKEND_URL;

const UserStories = ({
  user,
  stories,
  dispatch,
  language_id,
  menuType,
  stafType,
}) => {
  console.log(language_id);
  let storyPagenum = 1;
  const [isloading, setIsLoading] = useState(false);
  const [storyPage, setStoryPage] = useState(1);
  //const [storyPageMobile, setstoryPageMobile] = useState(storyPagenum + 1)
  const [totalStoryPage, setTotalStoryPage] = useState();
  const intialLoadErrorMessageRef = useRef(null);
  const recursionLoadErrorMessageRef = useRef(null);
  const stroyBoxRef = useRef(null);

  const getSubscirptionGroupStories = async (storyPage) => {
    setIsLoading(true);
    setStoryPage(storyPage + 1);

    let allStoryUserLanURL = "";
    if (menuType === "STORIES") {
      allStoryUserLanURL = `${url}/api/explore/getPersonAllStoriesByLanguage?user_id=${user._id}&language_id=${language_id}&page=${storyPage}`;
    } else {
      allStoryUserLanURL = `${url}/api/explore/getAllExpertbyLanguageAndUser?user_id=${user._id}&language_id=${language_id}&page=${storyPage}`;
    }
    try {
      if (user._id) {
        const response = await fetch(allStoryUserLanURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            //window.location.replace('/')
          } else {
            console.log("Something Went Wrong");
          }
        } else {
          setTotalStoryPage(responseData.body.Total_Page);

          if (storyPage > 1) {
            setIsLoading(false);
            dispatch({
              type: "user_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({
              type: "user_group_stories",
              payload: responseData.body,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (language_id) getSubscirptionGroupStories(storyPagenum);
  }, [user, language_id, menuType]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    if (storyPage == 1) {
      setStoryPage(storyPagenum + 1);
    }
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  const handleScrolListner = () => {
    console.log(totalStoryPage);
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        console.log(storyPage);
        getSubscirptionGroupStories(storyPage);
      }
    }
  };

  const ImageFile = ({ imgsrc }) => {
    return (
      <>
        <div style={{ padding: 3 + "px", textAlign: "center" }}>
          <img src={imgsrc} className="width-100" alt="Image not found" />
        </div>
      </>
    );
  };

  const VideoUrl = ({ videolink, width, height }) => {
    return (
      <>
        <div className="text-center story-margin ">
          <ReactPlayer url={videolink} controls width={width} height={height} />
        </div>
      </>
    );
  };

  const AudioUrl = ({ audiosrc }) => {
    return (
      <>
        <div className="text-center">
          <audio controls src={audiosrc} className="story-margin "></audio>
        </div>
      </>
    );
  };

  if (stories.getStoryModel) {
    return (
      <>
        <div id="story_box" ref={stroyBoxRef} className="story-box">
          <div
            className="d-flex justify-content-center margin-top-5"
            ref={intialLoadErrorMessageRef}
          ></div>
          {!isloading && (
            <div className="d-flex justify-content-center margin-top-5">
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="container-fluid story-card-box-section">
            <div className="row">
              {stories.getStoryModel.map((story, index) => {
                return (
                  <div
                    className={`col-6 col-md-4 col-xl-3 col-xxl-2 story1-card-box`}
                    key={story._id}
                  >
                    <ConsultantStoryCard
                      story={story}
                      backgroundColor="#fafafa"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {!isloading && (
            <div className="d-flex justify-content-center margin-top-5">
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div
            className="d-flex justify-content-center margin-top-5"
            ref={recursionLoadErrorMessageRef}
          ></div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="story_box" className="story-box">
          <div
            className="d-flex justify-content-center margin-top-5"
            style={{ color: "red" }}
            ref={intialLoadErrorMessageRef}
          ></div>
        </div>
      </>
    );
  }
};

const MapStateToPorps = ({ UserStoriesReducers: { stories } }) => {
  return { stories };
};

export default connect(MapStateToPorps)(UserStories);
