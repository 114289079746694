import React, { useEffect, useState } from "react";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import ReactPlayer from "react-player";
import profileDefualt from "../../images/default.jpeg";
import Audio from "../../images/Audio.jpeg";

import "./storyCard.css";
import { getRequestedData } from "../../functions/editProfile/editProfile";

const weburl = process.env.REACT_APP_BACKEND_URL;

const StoryCardMin = ({ story, userid }) => {
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [youtubeThumbnail, setYoutubeThumbnail] = useState("");
  useEffect(() => {
    if (story.story_type === "video_url") {
      const videoId = getId(story.story_url);
      setYoutubeVideoID(videoId);
      getYoutbe(videoId);
    }
  }, [story.story_type]);

  const getYoutbe = async (videoId) => {
    let res = await getRequestedData(
      `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`,
    );

    let thumbnailurl = res.data.thumbnail_url.substring(
      0,
      res.data.thumbnail_url.lastIndexOf("/"),
    );
    setYoutubeThumbnail(thumbnailurl);
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <>
      {story.story_type === "text" && (
        <div className="card-box cursor-pointer">
          {story.thumbnail_url && (
            <div>
              <img
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                  borderRadius: "10px",
                }}
                src={story.thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defualtImg;
                }}
              />
            </div>
          )}

          <div style={{ display: "flex" }}>
            {userid === story.user_id._id ? (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            ) : (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            )}

            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{story.story_title}</div>
            </div>
          </div>

          {!story.thumbnail_url && (
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } description-box-with-no-image ml-2 mt-2`}
            >
              {story.story_text &&
                story.story_text.length <= 350 &&
                story.story_text}
              {story.story_text &&
                story.story_text.length > 350 &&
                story.story_text.substring(0, 600) + " ...."}
            </div>
          )}
          {story.thumbnail_url && (
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } description-box ml-2 mt-2`}
            >
              {story.story_text &&
                story.story_text.length <= 50 &&
                story.story_text}
              {story.story_text &&
                story.story_text.length > 50 &&
                story.story_text.substring(0, 200) + " ...."}
            </div>
          )}
        </div>
      )}

      {story.story_type == "image" && (
        <div className="card-box cursor-pointer">
          {story.thumbnail_url && (
            <div>
              <img
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                  borderRadius: "10px",
                }}
                src={story.thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defualtImg;
                }}
              />
            </div>
          )}

          <div style={{ display: "flex" }}>
            {userid === story.user_id._id ? (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            ) : (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            )}

            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{story.story_title}</div>
            </div>
          </div>

          {!story.thumbnail_url && (
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } ml-2`}
            >
              {story.story_text &&
                story.story_text.length <= 350 &&
                story.story_text}
              {story.story_text &&
                story.story_text.length > 350 &&
                story.story_text.substring(0, 350) + " ...."}
            </div>
          )}
          {story.thumbnail_url && (
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } description-box ml-2 mt-2`}
            >
              {story.story_text &&
                story.story_text.length <= 50 &&
                story.story_text}
              {story.story_text &&
                story.story_text.length > 50 &&
                story.story_text.substring(0, 200) + " ...."}
            </div>
          )}
        </div>
      )}

      {story.story_type == "audio" && (
        <div className="card-box cursor-pointer">
          <div>
            <img
              style={{
                height: "100%",
                objectFit: "cover",
                width: "100%",
                borderRadius: "10px",
              }}
              src={story.thumbnail_url}
              alt="im-story"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = Audio;
              }}
            />
          </div>

          <div style={{ display: "flex" }}>
            {userid === story.user_id._id ? (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            ) : (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            )}

            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{story.story_title}</div>
            </div>
          </div>

          <div
            className={`${
              story.language.code === "ar" ? "text-right" : "text-left"
            } description-box ml-2 mt-2`}
          >
            {story.story_text &&
              story.story_text.length <= 50 &&
              story.story_text}
            {story.story_text &&
              story.story_text.length > 50 &&
              story.story_text.substring(0, 200) + " ...."}
          </div>
        </div>
      )}

      {story.story_type == "video_url" && (
        <div className="card-box ">
          {/* <ReactPlayer
              url={story.story_url}
              controls
              width="100%"
              height="100%"
            /> */}

          <img
            style={{
              height: "100%",
              objectFit: "cover",
              width: "100%",
              borderRadius: "10px",
            }}
            src={`https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`}
            alt="im-story"
            onError={(e) => {
              e.target.onError = null;
              e.target.src = `${youtubeThumbnail}/maxresdefault.jpg`;
            }}
          />

          <div style={{ display: "flex" }}>
            {userid === story.user_id._id ? (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            ) : (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            )}

            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{story.story_title}</div>
            </div>
          </div>

          <div
            className={`${
              story.language.code === "ar" ? "text-right" : "text-left"
            } description-box ml-2 mt-2`}
          >
            {story.story_text &&
              story.story_text.length <= 50 &&
              story.story_text}
            {story.story_text &&
              story.story_text.length > 50 &&
              story.story_text.substring(0, 200) + " ...."}
          </div>
        </div>
      )}

      {story.story_type == "video" && (
        <div className="card-box cursor-pointer">
          <div>
            <img
              style={{
                height: "100%",
                objectFit: "cover",
                width: "100%",
                borderRadius: "10px",
              }}
              src={story.thumbnail_url}
              alt="im-story"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = defualtImg;
              }}
            />
          </div>

          <div style={{ display: "flex" }}>
            {userid === story.user_id._id ? (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            ) : (
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  float: "left",
                  marginTop: "12px",
                }}
                src={story.user_id.imageUrl}
                alt="im-story-profile"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = profileDefualt;
                }}
              />
            )}

            <div style={{ marginLeft: "8px" }}>
              <div className="titile-card">{story.story_title}</div>
            </div>
          </div>

          <div
            className={`${
              story.language.code === "ar" ? "text-right" : "text-left"
            } description-box ml-2 mt-2`}
          >
            {story.story_text &&
              story.story_text.length <= 50 &&
              story.story_text}
            {story.story_text &&
              story.story_text.length > 50 &&
              story.story_text.substring(0, 200) + " ...."}
          </div>
        </div>
      )}
    </>
  );
};

export default StoryCardMin;
