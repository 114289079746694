import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { getDataPostWithOutHeader } from "../functions/editProfile/editProfile";
import { responseMessageDispaly } from "../utils/sharedfunction";

import "../css/forgotpassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState({ message: "", class: "" });

  const uploadMSG = useRef(null);

  const dispatch = useDispatch({});

  const resetLink = async (e) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/passwordResetLink`;
    const data = {
      email: email,
    };
    try {
      let res = await getDataPostWithOutHeader(url, data);
      setText({ message: res.data.body, class: "text-success" });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      responseMessageDispaly(uploadMSG, err.message, "red");
      console.log(err);
    }
  };
  return (
    <div className="forgot-password-cointainer">
      {text.message && (
        <>
          <div className="flex-center">
            <div className={`${text.class} success-message-box`}>
              {text.message}
            </div>
          </div>
          <div className="margin-top-1 flex-center">
            Or
            <u
              className="or-login"
              onClick={() => {
                dispatch({ type: "OpenNavModel" });
              }}
            >
              Login
            </u>
          </div>
        </>
      )}

      {loading && (
        <div className="flex-center">
          <span className="loader-spiner loader-spiner-additional"></span>{" "}
          <span className="ml-2">sending to mail please wait...</span>
        </div>
      )}

      <div ref={uploadMSG} className="flex-center"></div>
      {text.message === "" && (
        <div className="forgot-box">
          <div className="forgot-form-wrapper">
            <div className="input-label">Forgot password</div>
            <input
              type="text"
              className="input-email"
              placeholder="Email"
              autoComplete="off"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="button-wrapper">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="button-reset-send"
                onClick={resetLink}
              >
                Reset Password
              </Button>

              <div className="flex-center margin-top-1">
                Or
                <u
                  className="or-login"
                  onClick={() => {
                    dispatch({ type: "OpenNavModel" });
                  }}
                >
                  Login
                </u>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
