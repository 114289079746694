import React, { useEffect, useState } from 'react'

import Register from '../Components/Registration/Register'
import '../css/Registration/register.css'

const SignUp = () => {
  const [isLogin, setisLogin] = useState(false)

  useEffect(() => {
    if (typeof Storage !== 'undefined') {
      if (localStorage.getItem('userDataSessionWay')) {
        setisLogin(true)
      }
    }
  }, [])

  return (
    <>
      {isLogin === true ? (
        <h1>Please logout and register new user</h1>
      ) : (
        <div className='signup-area'>
          <div style={{ width: '450px', margin: 'auto' }}>
            <Register from={'directregister'} />
          </div>
        </div>
      )}
    </>
  )
}

export default SignUp
