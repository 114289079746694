import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { IoAddCircle } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { BiArrowToTop } from "react-icons/bi";
import { BsTrash3 } from "react-icons/bs";
import {
  getRequestedData,
  editPersonalDetails,
  getDataPostWithHeader,
} from "../../functions/editProfile/editProfile";
import { responseMessageDispaly } from "../../utils/sharedfunction";

const LanguageDetails = (props) => {
  const dispatch = useDispatch({});
  const [languages, setLanguages] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const { UserRequiredDropDown } = useSelector((state) => state);

  console.log(UserRequiredDropDown);

  const uploadMSG = useRef(null);
  const langlist = useRef(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getLanguages`;
    getRequestedData(url)
      .then((res) => {
        setLanguages(res.data.body);
      })
      .catch((err) => {
        console.log(err);
        alert.error(err.message);
      });
  }, []);

  const handleLanguage = async (value, name) => {
    setLoading(true);
    var children = langlist.current.children;
    let flag = 0;

    if (value !== "0") {
      for (var i = 0; i < children.length; i++) {
        var child = children[i];
        var val = child.innerHTML.split("<")[0].trim();

        if (val === name.trim()) {
          flag = 0;
          break;
        } else {
          flag = 1;
        }
      }

      if (flag === 1) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserLanguage`;
        const data = {
          userLangauges: value,
          user_id: props.user._id,
        };
        try {
          let res = await editPersonalDetails(url, data, props.token);
          setLoading(false);

          dispatch({
            type: "updateLanguage",
            payload: res.data.body.userLangauges,
          });
          dispatch({
            type: "userlanguages",
            payload: res.data.body.userLangauges,
          });
          setAdd(false);
          responseMessageDispaly(uploadMSG, "Language has updated", "green");
        } catch (err) {
          setLoading(false);
          responseMessageDispaly(uploadMSG, "Some thing went Wrong", "red");
          langlist.current.removeChild(langlist.current.lastChild);
          console.log(err.message);
          //alert(err.message);
        }
      }
    }
  };

  const mnakeItDefault = async (value) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/makeLanguageDefault`;
      const data = {
        language_id: value,
        user_id: props.user._id,
      };
      let res = await getDataPostWithHeader(url, data, props.token);

      dispatch({
        type: "PROFILE",
        payload: res.data.body.profiles,
      });
      props.userDetails();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(err.message);
    }
  };

  const handleRemove = async (value) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/deleteUserLanguage`;
    const data = {
      userLangauges: value,
      user_id: props.user._id,
    };
    try {
      let res = await getDataPostWithHeader(url, data, props.token);
      dispatch({
        type: "updateLanguage",
        payload: res.data.body.userLangauges,
      });

      dispatch({
        type: "userlanguages",
        payload: res.data.body.userLangauges,
      });
      setLoading(false);
      responseMessageDispaly(
        uploadMSG,
        "Selected language removed successfully",
        "green",
      );
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      responseMessageDispaly(uploadMSG, err.message, "red");
      console.log(err.message);
    }
  };

  return (
    <div>
      {loading && (
        <>
          <Spin className="ml-3" />
          <span className="text-danger">....</span>
        </>
      )}
      <div ref={uploadMSG} className="ml-3"></div>
      <div ref={langlist} className="lang-list-wrapper">
        <div>
          {UserRequiredDropDown.languages.length > 1
            ? UserRequiredDropDown.languages.map((list, index) => {
                if (index === 0) {
                  return (
                    <div
                      className="display-flex"
                      style={{
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <div
                        key={index}
                        id={`${list._id}`}
                        className="item-lang-list flex-space-btw display-flex-align-center"
                        style={{ flex: 1 }}
                      >
                        {list.name}
                        {/* <img
                    src={remove}
                    alt="add"
                    className="float-right mt-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemove(list._id)}
                  /> */}
                        {/* <span className="for-user-understand">Default</span> */}
                      </div>
                      <BsTrash3
                        onClick={() => handleRemove(list._id)}
                        style={{ marginTop: "1px" }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="display-flex"
                      style={{
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <div
                        key={index}
                        id={`${list._id}`}
                        className="item-lang-list flex-space-btw display-flex-align-center lang-list-hover"
                        style={{ flex: 1 }}
                        onClick={() => mnakeItDefault(list._id)}
                      >
                        {list.name}
                        {/* <img
                          src={remove}
                          alt="add"
                          className="float-right mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove(list._id)}
                        /> */}

                        <div className="hover-item for-user-understand">
                          Make it default
                        </div>
                        <div>
                          <BiArrowToTop size={20} />
                        </div>
                      </div>
                      <BsTrash3
                        onClick={() => handleRemove(list._id)}
                        style={{ marginTop: "1px" }}
                      />
                    </div>
                  );
                }
              })
            : UserRequiredDropDown.languages.map((list, index) => {
                return (
                  <div
                    key={index}
                    id={`${list._id}`}
                    className="item-lang-list flex-space-btw display-flex-align-center"
                  >
                    {list.name}
                  </div>
                );
              })}
        </div>
        {!add && (
          <div onClick={() => setAdd(true)}>
            <IoAddCircle size={20} />
            <span className="ml-1" style={{ position: "relative", top: "3px" }}>
              Add New Language
            </span>
          </div>
        )}

        {add && languages.length > 0 && (
          <div className="display-flex display-flex-align-center">
            <select
              className="form-control registration-from-control-border"
              onChange={(e) => {
                handleLanguage(
                  e.target.value,
                  e.target.options[e.target.selectedIndex].text,
                );
              }}
            >
              <option value="0">Add Langauge</option>
              {languages.map((lang) => {
                return (
                  <option key={lang._id} value={lang._id}>
                    {lang.name}
                  </option>
                );
              })}
            </select>
            <AiOutlineClose size={20} onClick={() => setAdd(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageDetails;
