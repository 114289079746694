import React, { useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Cropper from "react-easy-crop";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import "../css/crop.css";

import getCroppedImg from "../utils/cropImage";
import { responseMessageDispaly } from "../utils/sharedfunction";
const ImageCrop = (props) => {
  const inputRef = React.useRef();
  const errRef = React.useRef(null);
  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  useEffect(() => {
    console.log(props.selectedFile, "props.selectedFile");
    if (props.selectedFile) {
      if (props.selectedFile.target.files[0].type.split("/")[0] === "image") {
        onSelectFile(props.selectedFile);
      } else {
        inputRef.current.click();
      }
    } else {
      inputRef.current.click();
    }
  }, []);

  const triggerFileSelectPopup = () => inputRef.current.click();

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    console.log(event.target);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };

  const onSave = async () => {
    try {
      console.log(image);
      let img = await getCroppedImg(
        image,
        croppedArea,
        props.cropSize,
        props.profile,
        props.ratio,
      );

      if (props.selectedFile) {
        if (props.selectedFile.target.files[0].type.split("/")[0] === "image") {
          let FileName = props.selectedFile.target.files[0].name;
          props.onSave(img, FileName);
        } else {
          let FileName = inputRef.current.files[0].name;
          props.onSave(img, FileName);
        }
      } else {
        let FileName = inputRef.current.files[0].name;
        props.onSave(img, FileName);
      }
      props.close();
    } catch (err) {
      console.log(err);
      responseMessageDispaly(errRef, "Select Image", "red");
    }
  };

  return (
    <div className="image-container">
      <div className="container-cropper">
        <div ref={errRef}></div>
        {image ? (
          <>
            <div className="cropper">
              {props.cropSize ? (
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={props.aspectRatio}
                  showGrid={false}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  cropSize={props.cropSize}
                  objectFit={props.objectFit}
                />
              ) : (
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={props.aspectRatio}
                  showGrid={false}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  objectFit={props.objectFit}
                />
              )}
            </div>

            <div
              style={{
                width: "60%",
                margin: "auto",
                position: "relative",
                top: "3%",
                left: "5px",
                fontSize: "16px",
              }}
            >
              Zoom
            </div>

            <div
              className="slider"
              onLoad={() => {
                document.querySelector("slider").style.color = "red";
              }}
            >
              <span style={{ marginRight: "10px", color: "#8C8C8C" }}>
                <AiOutlineMinus size={25} />
              </span>
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => {
                  setZoom(zoom);
                }}
              />
              <span>
                <AiOutlinePlus
                  size={25}
                  style={{ color: "#8C8C8C", marginBottom: "1.5px" }}
                />
              </span>
            </div>
          </>
        ) : null}
      </div>

      <div className="container-buttons">
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={triggerFileSelectPopup}
          style={{ marginRight: "10px", display: "none" }}
        >
          Choose
        </Button>
        {props.close && (
          <Button
            variant="contained"
            onClick={props.close}
            style={{ marginLeft: "10px", background: "#0A66C2" }}
          >
            close
          </Button>
        )}
        {props.pictureStandard && <div>{props.pictureStandard}</div>}
        <Button
          variant="contained"
          onClick={onSave}
          style={{ marginRight: "10px", background: "#0A66C2" }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
export default ImageCrop;
