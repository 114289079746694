import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "../css/home.css";
import "../pages/Carousel.css";
import {
  getRequestedData,
  getRequestedDataWithHeader,
  getDataPostWithOutHeader,
  getDataPostWithHeader,
} from "../functions/editProfile/editProfile";

import FooterMenu from "../navbar/FooterMenu";
import DropDownMenu from "../Components/home/DropDownMenu";
import  UserStoriesDisplay from "../Components/home/UserStoriesDisplay";
import UserSubscriptionStories from "../Components/home/UserSubscriptionStories";
import ViewStoryReelsMobile from "../Components/ViewStory/ViewStoryReelsMobile";
import HomeSearchStories from "../Components/home/HomeSearchStories";

const Home = (props) => {
  const [categories, setCategories] = useState([]);
  const [loadingCat, setLoadingCat] = useState(false);
  const [userID, setUserID] = useState("");
  const [subID, setsubID] = useState("");
  const [selectedLangisAllStories, setSelectedLang] = useState(false);
  const [languages, setLanguages] = useState(null);
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [coursePage, setCoursePage] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState();
  const [loadingUser, setLoadingUser] = useState(false);
  const [isSubStories, setSubStories] = useState(false);
  const [isUserProfileStories, setUserProfileStories] = useState(false);
  const [isAllStories, setAllStories] = useState(false);
  const [subMobilePage, setSubMobilePage] = useState(false);
  const [sessionMobilePage, setSessionMobilePage] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [isUserStoryPage, setIsUserStoryPage] = useState(true);
  const [coursesDetails, setCoursesDetails] = useState({});
  const [courses, setCourses] = useState([]);
  const [isFromMobile, setIsFromMobile] = useState(true);
  const categoryUserMsg = useRef(null);
  const categoryMsg = useRef(null);
  const [searchStories, setSearchStories] = useState(false);
  const [selectedStory, setSelectedStory] = useState("");
  const [category, setCategory] = useState({
    categoryID: "",
    categoryName: "",
  });

  const dispatch = useDispatch();
  const {
    BasicHomePageReducer: { component },
  } = useSelector((state) => ({
    ...state,
  }));

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    setSubMobilePage(false);
    setSessionMobilePage(false);
    setUserProfileStories(false);
    dispatch({ type: "EMPTY_SEARCH_USER" });
  }, []);

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    setSelectedLang(userLang.split("-")[0]);
    if (props.user.isLogin) {
      categgoryBasedOnUserLangauge();
    } else {
      getUserCategoryBasedOnMachineLanguage(userLang.split("-")[0]);
    }
  }, [props.user.isLogin]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getLanguages`;
    getRequestedData(url)
      .then((res) => {
        setLanguages(res.data.body);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, []);

  const getUserCategoryBasedOnMachineLanguage = async (lang) => {
    setLoadingCat(true);
    setSelectedLang(lang);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/categories?tagType=story_tag`;
    try {
      let res = await getRequestedData(url);
      if (categoryUserMsg.current) categoryUserMsg.current.innerHTML = "";
      setCategories(res.data.body);
      setLoadingCat(false);
      if (res.data.body.length <= 0) {
        if (categoryUserMsg.current)
          categoryMsg.current.innerHTML =
            "Sorry there are no categories found for your languages";
      } else {
        if (categoryUserMsg.current) categoryMsg.current.innerHTML = "";
      }
    } catch (err) {
      setLoadingCat(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const categgoryBasedOnUserLangauge = async () => {
    //setLoadingCat(true)
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/categories?tagType=story_tag`;

      let res = await getRequestedData(url);

      if (categoryUserMsg.current) categoryUserMsg.current.innerHTML = "";
      setCategories(res.data.body);
      setLoadingCat(false);
      if (res.length <= 0) {
        if (categoryUserMsg.current)
          categoryMsg.current.innerHTML =
            "Sorry there are no category for your language";
      } else {
        if (categoryUserMsg.current) categoryMsg.current.innerHTML = "";
      }
    } catch (err) {
      setLoadingCat(false);
      console.log(err);
      toast.error(err.message);
    }
  };

  const loadStoriesBasedOnSubscriptionGroup = async (
    sub_id,
    user_id,
    storyPageNo,
  ) => {
    setLoadingUser(true);
    setAllStories(false);
    setSubStories(true);
    setUserProfileStories(false);
    setUserID(user_id);
    setsubID(sub_id);
    dispatch({
      type: "cat_stories",
      payload: {
        getStoryModel: [],
      },
    });
    document.querySelectorAll(".list-category-carousel").forEach((div) => {
      div.style.backgroundColor = "white";
      div.style.color = "black";
    });
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/subscriptionStories?user_id=${user_id}&subscription_id=${sub_id}&page=${storyPageNo}`;
    try {
      let res = await getRequestedDataWithHeader(
        url,
        props.user.userData.token,
      );
      setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        dispatch({
          type: "all_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "all_stories", payload: res.data.body });
      }
      setTotalStoryPage(res.data.body.Total_Page);
      setLoadingUser(false);
      if (res.data.body.length <= 0) {
        if (categoryUserMsg.current)
          categoryUserMsg.current.innerHTML =
            " Sorry there are no consultant for this category registered in the system..";
      } else {
        if (categoryUserMsg.current) categoryUserMsg.current.innerHTML = "";
      }
    } catch (err) {
      setLoadingUser(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const loadStoriesBasedOnUserProfile = async (user_id, storyPageNo) => {
    setLoadingUser(true);
    setAllStories(false);
    setSubStories(false);
    setUserProfileStories(true);
    setUserID(user_id);

    dispatch({
      type: "cat_stories",
      payload: {
        getStoryModel: [],
      },
    });
    document.querySelectorAll(".list-category-carousel").forEach((div) => {
      div.style.backgroundColor = "white";
      div.style.color = "black";
    });
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getUserStories?page=${storyPageNo}&user_id=${user_id}`;

    try {
      let res = await getRequestedDataWithHeader(
        url,
        props.user.userData.token,
      );
      setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        dispatch({
          type: "all_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "all_stories", payload: res.data.body });
      }
      setTotalStoryPage(res.data.body.Total_Page);
      setLoadingUser(false);
      if (res.data.body.length <= 0) {
        if (categoryUserMsg.current)
          categoryUserMsg.current.innerHTML =
            " Sorry there are no consultant for this category registered in the system..";
      } else {
        if (categoryUserMsg.current) categoryUserMsg.current.innerHTML = "";
      }
    } catch (err) {
      setLoadingUser(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const laodSubscriptionGrpoup = async (storyPageNo, cat_id) => {
    console.log(cat_id);
    setIsUserStoryPage(false);
    let url = "";
    if (cat_id) {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscriptionGroups?page=${storyPageNo}&user_id=${props.user.userData.loginUser._id}&cat_id=${cat_id}`;
    } else {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscriptionGroups?page=${storyPageNo}&user_id=${props.user.userData.loginUser._id}`;
    }
    setCoursePage(storyPageNo + 1);
    try {
      let res = await getRequestedDataWithHeader(
        url,
        props.user.userData.token,
      );

      console.log(res.data.body);
      setTotalCoursePage(res.data.body.Total_Page);

      if (storyPageNo > 1) {
        setCourses((courses) => [...courses, ...res.data.body.courses]);
      } else {
        setCourses(res.data.body.courses);
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  return (
    <>
      {!isFromMobile && (
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-md-12`}
              style={{
                marginTop: "60px",
              }}
            >
              {component === "HOME_STORIES" && (
                <>
                  {/* {!isFromMobile && ( */}
                  <UserStoriesDisplay
                    {...props}
                    loadStoriesBasedOnSubscriptionGroup={
                      loadStoriesBasedOnSubscriptionGroup
                    }
                    loadStoriesBasedOnUserProfile={
                      loadStoriesBasedOnUserProfile
                    }
                    userID={userID}
                    subID={subID}
                    storyPage={storyPage}
                    setStoryPage={setStoryPage}
                    totalStoryPage={totalStoryPage}
                    setTotalStoryPage={setTotalStoryPage}
                    loadingUser={loadingUser}
                    setLoadingUser={setLoadingUser}
                    setSubStories={setSubStories}
                    isSubStories={isSubStories}
                    setUserProfileStories={setUserProfileStories}
                    isUserProfileStories={isUserProfileStories}
                    setAllStories={setAllStories}
                    isAllStories={isAllStories}
                    categoryUserMsg={categoryUserMsg}
                    categoryMsg={categoryMsg}
                    loadingCat={loadingCat}
                    categories={categories}
                    mobile={mobile}
                  />
                  {/* )} */}

                  {/* {isFromMobile && <ViewHomeStoryReels user={props.user} />} */}
                </>
              )}
              {component === "USER_SUB_STORIES" && (
                <UserSubscriptionStories {...props} />
              )}
            </div>

            {!loadingUser && (
              <div className="fixed-bottom d-block d-sm-none">
                <FooterMenu user={props.user} />
              </div>
            )}
          </div>
        </div>
      )}

      {isFromMobile && (
        <>
          {!searchStories && !selectedStory && !category.categoryID && (
            <ViewStoryReelsMobile
              user={props.user}
              homeNavbarcollapse={props.homeNavbarcollapse}
              setHomeNavbarcollapse={props.setHomeNavbarcollapse}
              target={props.target}
              searchStories={searchStories}
              setSearchStories={setSearchStories}
            />
          )}

          {!searchStories && selectedStory && (
            <ViewStoryReelsMobile
              user={props.user}
              params_id={selectedStory}
              homeNavbarcollapse={props.homeNavbarcollapse}
              setHomeNavbarcollapse={props.setHomeNavbarcollapse}
              target={props.target}
              searchStories={searchStories}
              setSearchStories={setSearchStories}
              fromMobile={isFromMobile}
            />
          )}

          {!searchStories && category.categoryID && (
            <ViewStoryReelsMobile
              user={props.user}
              homeNavbarcollapse={props.homeNavbarcollapse}
              setHomeNavbarcollapse={props.setHomeNavbarcollapse}
              target={props.target}
              searchStories={searchStories}
              setSearchStories={setSearchStories}
              fromMobile={isFromMobile}
              category={category}
            />
          )}

          {searchStories && (
            <HomeSearchStories
              user={props.user}
              setSearchStories={setSearchStories}
              userId={props.user.userData && props.user.userData.loginUser._id}
              categories={categories}
              setSelectedStory={setSelectedStory}
              setCategory={setCategory}
              category={category}
            />
          )}
        </>
      )}
      {!loadingUser && (
        <div className="fixed-bottom d-block d-sm-none">
          <FooterMenu
            user={props.user}
            footwrapperclassname="footer-menu-reel-bottom"
          />
        </div>
      )}
    </>
  );
};

export default Home;
