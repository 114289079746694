import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  selectValueValue,
  selectIdValue,
  selectTextValue,
} from "../../utils/sharedfunction";

const FifthSection = ({
  sectionHolder: {
    sheftSections,
    CurrentSection,
    NextSection,
    PreviousSection,
    progressBar,
    errorSection,
  },
  userLangauges,
  langCount,
  userCategories,
  dispatch,
}) => {
  const selectLanguageRef = useRef(null);
  const selectCategoryRef = useRef(null);
  const refSelectedCategory = useRef(null);
  const notSelectedCatRef = useRef(null);
  const selectedCategory = userCategories;
  const newCatgoryAddRef = useRef(null);
  const url = process.env.REACT_APP_BACKEND_URL;
  let langValue = "0";
  const nxtBtnRef = useRef(null);

  useEffect(() => {
    let languageList = `<option selected="selected" value="0">Select Language</option>`;
    userLangauges.forEach((list) => {
      languageList += `<option id=${list.id} value="${list.value}">${list.text}</option>`;
    });

    selectLanguageRef.current.innerHTML = languageList;
  }, [langCount, userLangauges]);

  const catagoryLangaugeHandler = (e) => {
    langValue = selectValueValue(e.target);
    notSelectedCatRef.current.style.display = "none";

    if (langValue !== "0") {
      getCategories();
    }
  };

  const getCategories = async () => {
    progressBar.current.style.display = "block";

    try {
      const response = await fetch(`${url}/api/users/getCategories`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      const responseData = await response.json();

      let categoryList = `<option id="0"value="0">Select category</option>`;
      categoryList += `<option id="00"value="00">Add new category</option>`;
      responseData.body.forEach((list) => {
        categoryList += `<option id=${list._id} value=${list.language_cd}>${list.category_name}</option>`;
      });
      selectCategoryRef.current.innerHTML = categoryList;

      if (!response.ok) {
        throw new Error(responseData.message || "something went wrong");
      }
      progressBar.current.style.display = "none";
    } catch (err) {
      progressBar.current.style.display = "none";
      console.log(err);
      CurrentSection.current.style.display = "none";
      errorSection.current.style.display = "block";
    }
  };

  const categorySelectionHandler = (e) => {
    let id = selectIdValue(e.target);
    let value = selectValueValue(e.target);
    let text = selectTextValue(e.target);

    notSelectedCatRef.current.style.display = "none";

    var obj = {};

    if (langValue !== "0") {
      if (value === "00") {
        newCatgoryAddRef.current.style.display = "block";
        selectCategoryRef.current.style.display = "none";
        selectLanguageRef.current.style.display = "none";
        nxtBtnRef.current.disabled = true;
      }
    }

    if (value !== "0" && value !== "00") {
      obj.id = id;
      obj.text = text;
      obj.value = value;

      const isLanguageCheck = selectedCategory.findIndex((category) => {
        return category.id === obj.id;
      });

      if (isLanguageCheck === -1) {
        selectedCategory.push(obj);
        refSelectedCategory.current.innerHTML =
          refSelectedCategory.current.innerHTML +
          `<li class = "ul-common" id="${obj.id}">${obj.text}</li>`;
      }
    }
  };

  const categoryValidateHandler = () => {
    if (selectedCategory.length > 0) {
      dispatch({ type: "userCategories", payload: selectedCategory });
      sheftSections(NextSection, CurrentSection);
    } else {
      notSelectedCatRef.current.style.display = "block";
    }
  };

  const addNewCategoryHandler = async () => {
    if (langValue !== "0") {
      let language_cd = langValue;
      try {
        const response = await fetch(`${url}/api/users/addCategories`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language_cd: langValue,
            category_name: newCatgoryAddRef.current.value,
          }),
        });

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message || "something went wrong");
        } else {
          newCatgoryAddRef.current.style.display = "none";
          selectCategoryRef.current.style.display = "block";
          selectLanguageRef.current.style.display = "block";
          newCatgoryAddRef.current.value = "";
          nxtBtnRef.current.disabled = false;
          getCategories(language_cd);
        }
      } catch (err) {
        console.log(err);
        CurrentSection.current.style.display = "none";
        errorSection.current.style.display = "block";
      }
    }
  };

  return (
    <div
      className="registration-model-container"
      style={{ display: "none" }}
      ref={CurrentSection}
    >
      <div className="registration-modal-header">
        <h5 className="registration-modal-title">Select Categories</h5>
      </div>
      {<ul className="list" ref={refSelectedCategory}></ul>}
      <div className="margin-battom-15">
        <select
          className="form-control register-select border-bottom-white"
          ref={selectLanguageRef}
          onChange={catagoryLangaugeHandler}
        ></select>
      </div>

      <span
        className="push-left display-none danger-red "
        ref={notSelectedCatRef}
      >
        Select Atleast one Category
      </span>

      <div className="margin-battom-15">
        <select
          className="form-control register-select border-bottom-white"
          ref={selectCategoryRef}
          onChange={categorySelectionHandler}
        ></select>
        <input
          placeholder="Enter new category"
          className="registration-form-control input-ht bor  border-bottom-white registration-input-90 display-none "
          type="text"
          ref={newCatgoryAddRef}
          onBlur={addNewCategoryHandler}
        />
      </div>

      <div>
        <button
          className=" btn-previous push-left"
          onClick={() => {
            sheftSections(PreviousSection, CurrentSection);
          }}
        >
          Back
        </button>
        <button
          className="btn-proceed push-right"
          onClick={categoryValidateHandler}
          ref={nxtBtnRef}
        >
          Next
        </button>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};
const MapStateToProps = ({
  RegFirstSection: { userLangauges },
  RegFifthSection: { userCategories },
}) => {
  return { userLangauges, langCount: userLangauges.length, userCategories };
};

export default connect(MapStateToProps)(FifthSection);
