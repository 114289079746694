import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

//css
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

//pages
import Profile from "../../pages/Profile";
import ProfilePage from "../../pages/ProfilePage";
import Explore from "../../pages/Explore";
import EditProfile from "../../pages/EditProfile";

import ProfileNavbar from "../../navbar/ProfileNavbar";
import Navbar from "../../navbar/Navbar";
import ViewSubStories from "../../pages/ViewSubStories";
import ViewProfileStory from "../../pages/ViewProfileStory";
import ViewUserStories from "../../pages/ViewUserStories";
import SubscriptioGroupCourse from "../../pages/SubscriptioGroupCourse";
import AddNewCourse from "../../pages/AddNewCourse";
import EditSubCourse from "../../pages/EditSubCourse";
import MyCourses from "../../pages/MyCourses";
import Subscriptions from "../../pages/Subscriptions";
import ChatPage from "../../pages/ChatPage";
import HomeNavbarVerticle from "../../navbar/HomeNavbarVerticle";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";
import MaintenanceStory from "../../pages/MaintenanceStory";
import SuperAdmin from "../../pages/SuperAdmin";
import CategoriesCourse from "../../pages/CategoriesCourse";
import Cart from "../../pages/Cart";
import WishLists from "../../pages/WishLists";
import CheckOut from "../../pages/CheckOut";
import PaymentValid from "../../pages/PaymentValid";
import KnetError from "../../pages/KnetError";
import KnetSuccess from "../../pages/KnetSuccess";
import ViewChannelsList from "../Admin/Metrics/ViewChannelsList";
import ViewExperStory from "../../pages/ViewExperStory";
import ViewProfileArticles from "../../pages/ViewProfileArticles";

const url = process.env.REACT_APP_BACKEND_URL;

let userData;

function ConsultlotLogged({ props, user, dispatch, socket }) {
  const [isLogin, setisLogin] = useState(false);
  const [userI, setUser] = useState({ isLogin: false });

  const { ROOMCHAT } = useSelector((state) => state);

  useEffect(() => {
    const userValidate = async () => {
      if (typeof Storage !== "undefined") {
        if (localStorage.getItem("userDataSessionWay")) {
          setUser({
            isLogin: true,
            userData: JSON.parse(localStorage.getItem("userDataSessionWay")),
          });
        } else {
          setUser({ isLogin: false });
        }
      } else {
        alert("Please update your browser");
      }
    };

    userValidate();
  }, [localStorage.getItem("userDataSessionWay")]);

  useEffect(() => {
    const userValidate = async () => {
      if (typeof Storage !== "undefined") {
        if (localStorage.getItem("userDataSessionWay")) {
          try {
            userData = JSON.parse(localStorage.getItem("userDataSessionWay"));

            console.log(userData);

            const response = await fetch(`${url}/api/users/getUserDetails`, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.token}`,
              },
              body: JSON.stringify({
                user_id: userData.loginUser._id,
              }),
            });
            const responseData = await response.json();
            if (!response.ok) {
              localStorage.removeItem("userDataSessionWay");
              window.location.replace("/");
            } else {
              const loginUser = { ...responseData.body.user };

              dispatch({ type: "user", payload: userData });
              dispatch({
                type: "userlanguages",
                payload: loginUser.userLangauges,
              });
              dispatch({
                type: "userCategories",
                payload: loginUser.userCategory,
              });
              setisLogin(true);
            }
          } catch (err) {
            console.log(err);
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        } else {
          window.location.replace("/");
        }
      } else {
        alert("Please update your browser");
      }
    };

    userValidate();
  }, [localStorage.getItem("userDataSessionWay")]);

  useEffect(() => {
    if (props.target === "chat") {
      props.setHomeNavbarcollapse(true);
    }
  }, [props.target]);

  useEffect(() => {
    if (props.target === "profile") {
      props.setHomeNavbarcollapse(false);
      if (window.outerWidth <= 428) {
        console.log(window.outerWidth);
        props.setHomeNavbarcollapse(true);
      }
    }
  }, [props.target]);

  useEffect(() => {
    if (isLogin) {
      socket.on("isLogin", function () {
        console.log("yea ! user is connected");
      });

      //to join users
      socket.emit(
        "join",
        { userId: userData.loginUser._id, deviceType: "web" },
        function (response) {
          console.log(response);
        },
      );
    }
  }, [isLogin]);

  useEffect(() => {
    console.log("hello iam from sessionway");
    window.addEventListener("beforeunload", function (e) {
      socket.emit("leaveRoom", { roomOne: ROOMCHAT.roomOne });
      socket.emit("userOffline", {
        userId: userData.loginUser._id,
      });
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      const getProfileUser = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getProfilesOfUser?user_id=${userData.loginUser._id}`;
        const res = await getRequestedDataWithHeader(url, userData.token);
        if (res.data.status === 200) {
          dispatch({
            type: "PROFILE",
            payload: res.data.body.profiles,
          });
          console.log("profiles", res.data);
        }
      };
      getProfileUser();
    }
  }, [isLogin]);

  return (
    <>
      {isLogin && (
        <>
          {props.target !== "min" && (
            <ProfileNavbar
              props={{
                profileImage: userData.loginUser.imageUrl,
                target: props.target,
                token: userData.token,
                user_id: userData.loginUser._id,
                homeNavbarcollapse: props.homeNavbarcollapse,
                setHomeNavbarcollapse: props.setHomeNavbarcollapse,
              }}
              socket={socket}
            />
          )}

          {(props.target === "profile" ||
            props.target === "chat" ||
            props.target === "profilestory" ||
            props.target === "expertTeam" ||
            props.target === "profilearticle" ||
            props.target === "profilescires" ||
            props.target === "courseCategory" ||
            props.target === "checkout" ||
            props.target === "cart" ||
            props.target === "wishlist" ||
            props.target === "checkoutorderid") && (
            <div style={{ display: "flex", overflow: "hidden" }}>
              <div className={`d-md-block d-none d-sm-block`}>
                <HomeNavbarVerticle
                  opionSelectedKey={`${props.target == "profile" ? "2" : "3"}`}
                  user={userI}
                  homeNavbarcollapse={props.homeNavbarcollapse}
                />
              </div>

              <div className="w-100">
                {props.target === "profile" && (
                  <ProfilePage
                    user={userI}
                    homeNavbarcollapse={props.homeNavbarcollapse}
                  />
                )}
                {props.target === "chat" && (
                  <ChatPage
                    userData={userData}
                    socket={socket}
                    user={userI}
                    homeNavbarcollapse={props.homeNavbarcollapse}
                  />
                )}
                {props.target === "profilestory" && (
                  <ViewProfileStory user={userI} />
                )}

                {props.target === "expertTeam" && (
                  <ViewExperStory user={userI} />
                )}

                {props.target === "profilearticle" && (
                  <ViewProfileArticles user={userI} type={"profilearticle"} />
                )}

                {props.target === "profilescires" && (
                  <ViewProfileArticles user={userI} type={"profilescires"} />
                )}

                {props.target === "courseCategory" && (
                  <CategoriesCourse user={userI} />
                )}

                {props.target === "cart" && <Cart user={userI} />}

                {props.target === "checkout" && <CheckOut user={userI} />}

                {props.target === "wishlist" && <WishLists user={userI} />}

                {props.target === "checkoutorderid" && (
                  <PaymentValid user={userI} />
                )}

                {props.target === "knetsuccess" && <KnetSuccess user={userI} />}

                {props.target === "kneterror" && <KnetError user={userI} />}
              </div>
            </div>
          )}

          <div className="w-100">
            {props.target === "min" && (
              <SuperAdmin user={{ userData: userData }} />
            )}
            {props.target === "minchannellist" && (
              <ViewChannelsList user={{ userData: userData }} />
            )}

            {props.target === "explore" && <Explore />}
            {props.target === "editprofile" && <EditProfile />}
            {props.target === "story" && (
              <ViewSubStories user={{ userData: userData }} />
            )}
            {props.target === "userstory" && (
              <ViewUserStories user={{ userData: userData }} />
            )}

            {props.target === "subscription_group" && (
              <SubscriptioGroupCourse user={{ userData: userData }} />
            )}

            {props.target === "newcourse" && (
              <AddNewCourse user={{ userData: userData }} />
            )}

            {props.target === "editcourse" && (
              <EditSubCourse user={{ userData: userData }} />
            )}

            {props.target === "editcourse" && (
              <EditSubCourse user={{ userData: userData }} />
            )}

            {props.target === "mycourse" && (
              <MyCourses
                user={userI}
                homeNavbarcollapse={props.homeNavbarcollapse}
                courseType={1}
              />
            )}

            {props.target === "private-training" && (
              <MyCourses
                user={userI}
                homeNavbarcollapse={props.homeNavbarcollapse}
                courseType={0}
              />
            )}

            {props.target === "mysubscriptions" && (
              <Subscriptions user={userI} />
            )}
          </div>
        </>
      )}
    </>
  );
}
const MapStateToProps = ({ BasicUserReducers: { user } }) => {
  return { user };
};
export default connect(MapStateToProps)(ConsultlotLogged);
