import React from "react";
import { Card, Skeleton } from "antd";

const LoadingCard = ({ count, avatar, defualt }) => {
  const cards = () => {
    let totalCards = [];

    for (let i = 0; i < count; i++) {
      if (defualt) {
        totalCards.push(
          <div key={i}>
            {avatar ? (
              <>
                <Skeleton active avatar></Skeleton>
              </>
            ) : (
              <Skeleton active></Skeleton>
            )}
          </div>,
        );
      } else {
        totalCards.push(
          <div key={i} className="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
            {avatar ? (
              <>
                <Skeleton active avatar></Skeleton>
              </>
            ) : (
              <Skeleton active></Skeleton>
            )}
          </div>,
        );
      }
    }

    return totalCards;
  };
  return <div className={`${!defualt && "row"}`}>{cards()}</div>;
};

export default LoadingCard;
