import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { BsThreeDotsVertical } from "react-icons/bs";
import profileDefualt from "../../images/default.jpeg";
import UserStoryCard from "../cards/UserStoryCard";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { FaPen } from "react-icons/fa";
import EditStoryModel from "../Models/EditStoryModel";
import addStory from "../../images/add_blue.png";

import {
  responseMessage,
  responseMessageClear,
} from "../../utils/sharedfunction";

const url = process.env.REACT_APP_BACKEND_URL;

const UserStories = ({
  user,
  stories,
  dispatch,
  menuType,
  stafType,
  setCVAddedAlready,
  CVAddedAlready,
  setShowAddStoryModel,
}) => {
  let storyPagenum = 1;
  const [isloading, setIsLoading] = useState(false);
  const [storyPage, setStoryPage] = useState(1);
  //const [storyPageMobile, setstoryPageMobile] = useState(storyPagenum + 1)
  const [totalStoryPage, setTotalStoryPage] = useState();
  const intialLoadErrorMessageRef = useRef(null);
  const recursionLoadErrorMessageRef = useRef(null);
  const stroyBoxRef = useRef(null);

  const { FileUploadPercentage, StoryImageCoverURL } = useSelector(
    (state) => state,
  );
  const [showEditStoryModel, setShowEditStoryModel] = useState(false);
  const [storyToEdit, setStoryToEdit] = useState({});
  const closeModelHandler = () => setShowEditStoryModel(false);

  const getSubscirptionGroupStories = async (storyPage, menuType) => {
    setStoryPage(storyPage + 1);

    try {
      if (user.loginUser._id) {
        let storiesUrl =
          menuType === "STORIES"
            ? `${url}/api/userProfile/getUserStories?page=${storyPage}&user_id=${user.loginUser._id}`
            : `${url}/api/userProfile/getUserExperts?page=${storyPage}&user_id=${user.loginUser._id}`;
        const response = await fetch(storiesUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const responseData = await response.json();

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            //window.location.replace('/')
          } else {
            if (storyPage > 1) {
              responseMessage(
                recursionLoadErrorMessageRef,
                "Something Went Wrong",
                "red",
              );
              responseMessageClear(recursionLoadErrorMessageRef);
            } else {
              responseMessage(
                intialLoadErrorMessageRef,
                "Something Went Wrong",
                "red",
              );
              responseMessageClear(intialLoadErrorMessageRef);
            }
            //throw new Error(responseData.message || 'something went wrong')
          }
        } else {
          setTotalStoryPage(responseData.body.Total_Page);
          if (storyPage > 1) {
            setIsLoading(false);
            dispatch({
              type: "sub_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({ type: "sub_group_stories", payload: responseData.body });
          }

          setIsLoading(true);
        }
      }
    } catch (err) {
      if (storyPage > 1) {
        responseMessage(
          recursionLoadErrorMessageRef,
          "Something Went Wrong",
          "red",
        );
        console.log(err);
        responseMessageClear(recursionLoadErrorMessageRef);
      } else {
        responseMessage(
          intialLoadErrorMessageRef,
          "Something Went Wrong",
          "red",
        );
        console.log(err);
        responseMessageClear(intialLoadErrorMessageRef);
      }
    }
  };

  useEffect(() => {
    getSubscirptionGroupStories(storyPagenum, menuType);
  }, [user, menuType]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    if (storyPage == 1) {
      setStoryPage(storyPagenum + 1);
    }
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        getSubscirptionGroupStories(storyPage);
      }
    }
  };

  if (stories.getStoryModel) {
    return (
      <>
        {storyToEdit._id && (
          <EditStoryModel
            user={user}
            onModelClose={closeModelHandler}
            showEditStoryModel={showEditStoryModel}
            story={storyToEdit}
            menuType={menuType}
            stafType={stafType}
            setCVAddedAlready={setCVAddedAlready}
          />
        )}

        <div id="story_box" ref={stroyBoxRef} className="story-box">
          <div>
            <div
              className="display-flex"
              style={{ alignItems: "center", cursor: "pointer" }}
              onClick={() => {
                if (
                  FileUploadPercentage.uploadVideoMeta.isVideoUploading ===
                  "YES"
                ) {
                  alert("Until Upload You can't add new one");
                  return;
                }

                setShowAddStoryModel(true);
              }}
            >
              <img src={addStory} alt="add" style={{ height: "40px" }} />
              <span>Add</span>
            </div>
          </div>
          <div
            className="d-flex justify-content-center"
            ref={intialLoadErrorMessageRef}
          ></div>
          <div className="container-fluid story-card-box-section mt-1">
            <div className="row">
              {/* {(menuType === "STORIES" || menuType === "STAFS") && (
                <div
                  className="col-6 col-md-4 col-xl-3 col-xxl-2 story1-card-box mb-3"
                  onClick={() => {
                    if (
                      FileUploadPercentage.uploadVideoMeta.isVideoUploading ===
                      "YES"
                    ) {
                      alert("Until Upload You can't add new one");
                      return;
                    }

                    setShowAddStoryModel(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <img src={addStory} alt="add" style={{ height: "50px" }} />
                    <span>Add Story</span>
                  </div>
                </div>
              )} */}

              {stories.getStoryModel.map((story, index) => {
                console.log(story, "story", index);
                return (
                  <div
                    className="col-6 col-md-4 col-xl-3 col-xxl-2 story1-card-box mb-3"
                    key={story._id}
                  >
                    <UserStoryCard
                      story={story}
                      iam={1}
                      user={user}
                      index={index}
                      setShowEditStoryModel={setShowEditStoryModel}
                      setStoryToEdit={setStoryToEdit}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {!isloading && (
            <div className="d-flex justify-content-center margin-top-5">
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div
            className="d-flex justify-content-center margin-top-5"
            ref={recursionLoadErrorMessageRef}
          ></div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="story_box" className="story-box">
          <div
            className="d-flex justify-content-center margin-top-5"
            style={{ color: "red" }}
            ref={intialLoadErrorMessageRef}
          ></div>
        </div>
      </>
    );
  }
};

const MapStateToPorps = ({ StoriesReducer: { stories } }) => {
  return { stories };
};

export default connect(MapStateToPorps)(UserStories);
