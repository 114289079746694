const initialState = {
  uploadVideoMeta: {
    videoUploadPercentage: 0,
    videoprocessingUpload: false,
    isVideoUploading: 'NO',
  },
  uploadImageMeta: {
    imageUploadPercentage: 0,
    imageprocessingUpload: false,
  },
  storyMetaData: {
    storyParentID: '',
  },
}

const StoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPLOADPERCENTAGEFROMVIDEOSOURCE':
      return {
        ...state,
        uploadVideoMeta: { ...state.uploadVideoMeta, ...action.payload },
      }

    case 'UPLOADPERCENTAGEFROMVIDEOPROCESS':
      return {
        ...state,
        uploadVideoMeta: { ...state.uploadVideoMeta, ...action.payload },
      }

    case 'STORYMETADATA':
      return {
        ...state,
        storyMetaData: { ...state.storyMetaData, ...action.payload },
      }

    default:
      return state
  }
}

export default StoriesReducer
