import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios, { CancelToken, isCancel } from "axios";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import {
  AiOutlineUpload,
  AiOutlineClose,
  AiOutlineAudio,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import Model from "../../utils/models/ModelStory";
import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
} from "../../functions/editProfile/editProfile";

import defualtImg from "../../images/Read&ShareThumbnail.png";
import ReactPlayer from "react-player";
import defaultThumbmail from "../../images/Read&ShareThumbnail.png";
import attachment from "../../images/attachment.png";
import addcoverorVideo from "../../images/icons/icon set-01.png";
import mic from "../../images/icons/mic.png";
import camera from "../../images/icons/camera.png";
import youtube from "../../images/icons/youtube.png";
import Modal from "../../utils/models/Modal";
import useRecorder from "../../utils/hooks/useRecorder";
import RichText from "../editProfile/RichText";
import ImageCrop from "../ImageCrop";
import "./addStoryModel.css";

import {
  responseMessageDispaly,
  dataURLtoBlob,
} from "../../utils/sharedfunction";
import { categgoryBasedLangaugeBYID } from "../../utils/apis/commonApis";

import CatgeorySearchBox from "./CatgeorySearchBox";

const AddDocAndImageModel = ({
  user,
  onModelClose,
  showAddStoryModel,
  menuType,
  stafType,
  setCVAddedAlready,
}) => {
  const dispatch = useDispatch();
  const [storyType, setStoryType] = useState("");
  const [storyTitle, setStoryTitle] = useState("");
  const [storyDesc, setStoryDesc] = useState("");
  const [language, setLanguage] = useState("0");
  const [languagecd, setLanguagecd] = useState();
  const [profileId, setProfileId] = useState("");
  const [category, setCategory] = useState("0");
  const [newCategory, setNewCategory] = useState("");
  const [storyID, setStoryID] = useState("");
  const [categories, setcategories] = useState([]);
  const [showCrop, setShowCrop] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [videoprocessingUpload, setVideoProcessingUpload] = useState(false);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [imageprocessingUpload, setImageProcessingUpload] = useState(false);
  const [docUploadPercentage, setDocUploadPercentage] = useState(0);
  const [docprocessingUpload, setDocProcessingUpload] = useState(false);
  const [videoHeight, setVideoHeight] = useState("");
  const [videoWidth, setVideoWidth] = useState("");
  const [videoRatio, setVideoRatio] = useState();
  const [videUrl, setVideoUrl] = useState("");
  const [showvideoUrlLink, setShowvideoUrlLink] = useState(true);
  const [coverUrl, setCoverUrl] = useState("");
  const [docUrl, setDocurl] = useState("");
  const [theDoc, setTheDoc] = useState("");
  const [coverUpload, setCoverUpload] = useState("");
  const [cover1, setCover1] = useState("");
  const [cover2, setCover2] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [uplaodVideoUrl, setUplaodVideoUrl] = useState("");
  const [uploadedCoverImage, setUploadedCoverImage] = useState({
    url: "",
    blob: "",
    fileName: "",
  });

  const [selectFile, setSelectFile] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState("");
  const [imageSelectType, setImageSelectType] = useState("");
  const [imagePriceType, setImagePriceType] = useState("");
  const [imagePrice, setImagePrice] = useState(0);
  const [currency, setCurrency] = useState("0");

  const [isFromMobile, setIsFromMobile] = useState(true);
  const errormesseageRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const fileResiderRef = useRef(null);
  const fileResiderRef1 = useRef(null);
  const audioResiderRef = useRef(null);
  const videoPickerRef = useRef(null);
  const videoTempRef = useRef(null);
  const videoTempRef2 = useRef(null);
  const videoTempRef3 = useRef(null);
  const audioPickerRef = useRef(null);
  const langaugeRef = useRef(null);
  const categoryRef = useRef(null);
  const tagErrorMessegesRef = useRef(null);
  const fileRef = useRef(null);
  const filePickerRef = useRef(null);
  const menuBoxBioRef = useRef(null);

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  const { UserRequiredDropDown, ProfileReducers, FileUploadPercentage } =
    useSelector((state) => state);

  const { profiles } = ProfileReducers;

  useEffect(() => {
    setCoverUrl("");
    setDocurl("");
    setSelectedDoc("");
    setTheDoc("");
    setVideoUrl("");
    setStoryType("");
    setCoverUpload("");
    setCover1("");
    setCover2("");
    setcategories([]);
    setTags([]);
    setStoryID("");
    setShowAddCategory(false);
    setUploadedCoverImage({ url: "", blob: "", fileName: "" });

    if (profiles.length > 0) {
      setLanguagecd(profiles[0].language_id.code);
      setProfileId(profiles[0]._id);
      console.log("language", profiles[0].language_id._id);
      setLanguage(profiles[0].language_id._id);
    }
  }, [profiles, showAddStoryModel]);

  //   useEffect(() => {
  //     if (showAddStoryModel) fileRef.current.click();
  //   }, [showAddStoryModel]);

  const url = process.env.REACT_APP_BACKEND_URL;

  const categgoryBasedOnLangaugeHandler = async (value) => {
    try {
      setNewCategory(value);
      if (value !== "") {
        let categories = await categgoryBasedLangaugeBYID(value);
        setcategories(categories);
        if (categories.length === 0) {
          setShowAddCategory(true);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("failed to load category");
    }
  };

  const selecetCategory = (cat_id, category_name) => {
    setCategory(cat_id);
    setNewCategory(category_name);
    setcategories([]);
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload");
    setVideoUploadPercentage(0);
    dispatch({
      type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
      payload: {
        videoUploadPercentage: 0,
        videoprocessingUpload: false,
        isVideoUploading: "NO",
      },
    });

    setCoverUpload("");
    setCover1("");
    setCover2("");
    videoTempRef.current.src = "";
    videoTempRef2.current.src = "";
    videoTempRef3.current.src = "";
    setUplaodVideoUrl("");
  };

  const openProfileHandler = () => {
    setShowCrop(true);
  };
  const closeProfileHandler = () => setShowCrop(false);

  const fileSelectHandler = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      let filExt = event.target.files[0].type.split("/")[0];
      if (filExt !== "image") {
        alert("Only Image");
        return;
      }
      setSelectFile(event);
      setShowCrop(true);
    }
  };

  const filePickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = false;

    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];

      console.log(fileExttype, filExt, event.target.files[0].type);

      if (filExt === "pdf") {
        pickedFile = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", async () => {
          setTheDoc(reader.result);
        });
        const data = {
          user_id: user.loginUser._id,
          keyname: "userFiles/storyFiles/doc",
          filename: pickedFile.name,
          fileType: pickedFile.type,
        };
        fileIsValid = true;

        getPresignedUrl("getPresignedURLForDocUpload", data, pickedFile, "doc");
        setSelectedDoc(pickedFile);
      } else {
        toast.error("Select suitable file");
        return;
      }
    }
  };

  const getPresignedUrl = async (apiName, data, pickedFile, fileExttype) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, user.token);
      if (res.data.status === 200) {
        if (fileExttype === "cover") {
          setCoverUrl(res.data.body.fileUrl);
          setCoverUpload(res.data.body.fileUrl);
        } else {
          setDocurl(res.data.body.fileUrl);
        }

        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const imagefileInputHandler = async (file, fileName) => {
    var imgeFile = dataURLtoBlob(file);

    fileResiderRef.current.innerHTML = "";
    setCoverUrl(file);
    fileResiderRef.current.style.width = "100%";
    fileResiderRef.current.innerHTML = `<img src=${file} alt="thumbnail" style="width:100%"/>`;

    const data = {
      user_id: user.loginUser._id,
      keyname: "userFiles/storyFiles",
      filename: fileName,
      fileType: imgeFile.type,
    };

    dispatch({
      type: "COVEREXIST",
      payload: {
        isThereCover: true,
        URL: file,
      },
    });
    getPresignedUrl("getPresignedURLForCoverUpload", data, imgeFile, "cover");
    setUploadedCoverImage({ url: file, blob: imgeFile, fileName: fileName });
  };

  const sendStory = async () => {
    console.log(menuType, docUrl, "menuType", docUploadPercentage);

    if (language === "0") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet language",
        "Red",
      );
      return;
    }

    if (!storyTitle) {
      responseMessageDispaly(errormesseageRef, "Please enter title^", "Red");
      return;
    }

    if (!storyDesc) {
      responseMessageDispaly(errormesseageRef, "Please enter story*", "Red");
      return;
    }

    if (docUploadPercentage > 0 || docprocessingUpload) {
      responseMessageDispaly(
        errormesseageRef,
        "Wait untill attachment upload*",
        "Red",
      );
      return;
    }

    const user_id = user.loginUser._id;

    const token = user.token;

    let categoryAdded = category;

    if (category === "0") {
      if (newCategory !== "") {
        try {
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

          const data = {
            category_name: newCategory,
          };

          const res = await getDataPostWithOutHeader(url, data);
          if (res.data.status === 200) {
            setShowAddCategory(false);
            setCategory(res.data.body._id);
            categoryAdded = res.data.body._id;
          }
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        alert("please enter category");
        return;
      }
    }

    if (!docUrl && menuType !== "ARTICLES") {
      alert("please add your pdf attachment");
      return;
    }

    const data = {
      user_id: user_id,
      category_id: categoryAdded,
      story_language_id: language,
      story_title: storyTitle,
      story_text: storyDesc,
      tags: tags,
      profileId: profileId,
      languagecd: languagecd,
      storyID: storyID,
      image_path: coverUrl,
      doc_attachment_url: docUrl,
    };

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let urlSend =
      menuType == "ARTICLES"
        ? `${url}/api/userProfile/sendArticles`
        : `${url}/api/userProfile/sendScienceRes`;
    axios
      .post(urlSend, data, options)
      .then(async (res) => {
        setStoryType("");
        setStoryTitle("");
        setStoryDesc("");
        setLanguage("0");
        setCategory("0");
        setNewCategory("");
        setVideoUrl("");
        setCoverUrl("");
        setDocurl("");
        setSelectedDoc("");
        setTheDoc("");
        setCoverUpload("");
        setCover1("");
        setCover2("");
        setAudioUrl("");
        setTags([]);
        setShowvideoUrlLink(true);
        setStoryID("");
        setcategories([]);
        setUploadedCoverImage({ url: "", blob: "", fileName: "" });
        getStories(1);

        onClose();

        const result = await getDataPostWithHeader(
          `${url}/api/userProfile/tagsForSearchKey`,
          { tags: tags },
          user.token,
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        if (isCancel(err)) {
          alert(err.message);
        }
        alert(err.message);
        //toast.error("System Failed");
      });
  };

  const getStories = async (storyPage) => {
    try {
      if (user.loginUser._id) {
        let storyKind = "userstories";
        if (menuType === "STORIES") {
          storyKind = "userstories";
        } else {
          storyKind = "userstafs";
        }

        let getStoryUrl =
          menuType == "ARTICLES"
            ? `${url}/api/userProfile/getUserArticles?page=${storyPage}&user_id=${user.loginUser._id}`
            : `${url}/api/userProfile/getUserSciRes?page=${storyPage}&user_id=${user.loginUser._id}`;

        const response = await fetch(getStoryUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const responseData = await response.json();

        if (!response.ok) {
          if (responseData.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          } else {
            if (storyPage > 1) {
              toast.error("Something Went Wrong");
            } else {
              toast.error("Something Went Wrong");
            }
            //throw new Error(responseData.message || 'something went wrong')
          }
        } else {
          if (storyPage > 1) {
            dispatch({
              type: "sub_group_stories_append",
              payload: responseData.body.getStoryModel,
            });
          } else {
            dispatch({ type: "sub_group_stories", payload: responseData.body });
          }
          document.getElementById("story_box").scrollTop = 0;
        }
      }
    } catch (err) {
      if (storyPage > 1) {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
        console.log(err);
      }
    }
  };

  const selectStoryType = (event, type) => {
    document.querySelectorAll(".header-wrapper-each").forEach((div) => {
      div.style.backgroundColor = "#fff";
    });

    event.currentTarget.style.background = "#dbdbdb";
    setStoryType(type);

    if (type === "video") {
      videoPickerRef.current.click();
    }
  };

  const onClose = () => {
    setCoverUrl("");
    setDocurl("");
    setSelectedDoc("");
    setTheDoc("");
    setStoryType("");
    setStoryTitle("");
    setAudioUrl("");
    setStoryDesc("");
    setLanguage("0");
    setCategory("0");
    setNewCategory("");
    setVideoUrl("");
    setCoverUpload("");
    setCover1("");
    setCover2("");
    setStoryID("");
    setVideoRatio("");
    setTags([]);
    setcategories([]);
    setShowAddCategory(false);
    setUploadedCoverImage({ url: "", blob: "", fileName: "" });
    setLanguagecd(profiles[0].language_id.code);
    setProfileId(profiles[0]._id);
    setShowvideoUrlLink(true);
    setImagePrice("");
    setImageSelectType("");
    setImagePriceType("");
    setCurrency("");
    onModelClose();
  };

  const handleCategory = (value) => {
    if (value !== "00") {
      setCategory(value);
    } else {
      setShowAddCategory(true);
    }
  };

  const AddCategory = async () => {
    if (newCategory !== "") {
      if (language === "0") {
        toast.error("Please Select Langauge");
        return;
      }

      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

        const data = {
          category_name: newCategory,
        };

        const res = await getDataPostWithOutHeader(url, data);
        if (res.data.status === 200) {
          setShowAddCategory(false);
          setCategory(res.data.body._id);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  };

  const langaugeHandler = (lang, i, profile) => {
    setLanguage(lang._id);
    document
      .querySelectorAll(".profile-bio-tab-story")
      .forEach((div) => (div.style.borderBottom = "none"));

    setProfileId(profile._id);
    document.getElementById(`lang_list_stroy_${i}`).style.borderBottom =
      "solid 1px #118acf";
    setLanguagecd(lang.code);
    setShowAddCategory(false);
    setNewCategory("");
  };

  const langaugeSelector = (langcode, langid, i, profiles) => {
    setLanguage(langid);
    setProfileId(profiles[i]._id);
    setLanguagecd(langcode);
    setShowAddCategory(false);
    setNewCategory("");
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl, story_id) => {
    if (apiName === "getPresignedURLForCoverUpload") {
      setImageUploadPercentage(1);
    } else if (apiName === "getPresignedURLForDocUpload") {
      setDocUploadPercentage(1);
    }
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        console.log(percent, "percent");
        if (percent <= 100) {
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
            } else if (apiName === "getPresignedURLForDocUpload") {
              setDocProcessingUpload(true);
              ///setDocUploadPercentage(0);
            } else {
              setVideoProcessingUpload(true);
              dispatch({
                type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
                payload: {
                  videoprocessingUpload: true,
                  videoUploadPercentage: 0,
                  isVideoUploading: "YES",
                },
              });
            }
          } else {
            console.log(percent, "percent");
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else if (apiName === "getPresignedURLForDocUpload") {
              console.log(percent, "percent2");
              setDocUploadPercentage(percent);
            } else {
              setVideoUploadPercentage(percent);
              dispatch({
                type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
                payload: {
                  videoUploadPercentage: percent,
                  videoprocessingUpload: false,
                  isVideoUploading: "YES",
                },
              });
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setImageUploadPercentage(0);
            setImageProcessingUpload(false);
          } else if (apiName === "getPresignedURLForDocUpload") {
            setDocUploadPercentage(0);
            setDocProcessingUpload(false);
          } else {
            setVideoUploadPercentage(0);
            setVideoProcessingUpload(false);
            dispatch({
              type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
              payload: {
                videoUploadPercentage: 0,
                videoprocessingUpload: false,
                isVideoUploading: "NO",
              },
            });
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else if (apiName === "getPresignedURLForDocUpload") {
          setDocUploadPercentage(0);
          setDocProcessingUpload(false);
        } else {
          setVideoUploadPercentage(0);
          setVideoProcessingUpload(false);
          dispatch({
            type: "UPLOADPERCENTAGEFROMVIDEOSOURCE",
            payload: {
              videoUploadPercentage: 0,
              videoprocessingUpload: false,
              isVideoUploading: "NO",
            },
          });
          dispatch({
            type: "COVEREXIST",
            payload: {
              isThereCover: false,
              URL: "",
            },
          });
        }
      });
  };

  return (
    <>
      <Model show={showAddStoryModel} onCancel={onModelClose}>
        <Modal
          show={showCrop}
          onCancel={closeProfileHandler}
          headerClass="text-center"
          containerStyle={{ height: "550px" }}
        >
          <div className="map-container">
            <ImageCrop
              close={closeProfileHandler}
              onSave={imagefileInputHandler}
              aspectRatio={16 / 9}
              objectFit="horizontal-cover"
              selectedFile={selectFile}
              ratio={"16:9"}
            />
          </div>
        </Modal>
        <div
          className="container-box-model"
          style={{
            background: "#fff",
            paddingBottom: "10px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              padding: "15px",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: "0px",
              background: "white",
              zIndex: "1",
              borderBottom: "1px solid #dbdbdb",
            }}
          >
            <AiOutlineClose
              onClick={onClose}
              className="cursor-pointer"
              size={20}
            />
          </div>

          {
            <>
              <div className="row-model pt-2">
                <div className="col-md-12">
                  <div ref={errormesseageRef}></div>
                </div>

                {isFromMobile && (
                  <div
                    className="col-md-5 mt-2"
                    style={{ height: "600px", overflow: "auto" }}
                  >
                    <div>
                      <div>
                        {storyType !== "video_url" && (
                          <div
                            ref={fileResiderRef}
                            className={`${
                              (storyType === "image" || storyType === "text") &&
                              "image-warapper-ratio-adujestment"
                            }`}
                          ></div>
                        )}
                        <div className="mt-2 cursor-pointer">
                          {coverUrl === "" && (
                            <div className="uploadCover">
                              <img
                                id="thumnail_bio"
                                src={addcoverorVideo}
                                className="width-100 cursor-pointer"
                                alt="thumbnail"
                                onClick={() => {
                                  fileRef.current.click();
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                ref={fileRef}
                                onChange={(e) => {
                                  fileSelectHandler(e);
                                  setSelectFile(e);
                                }}
                                style={{ display: "none" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        {!showvideoUrlLink && (
                          <BsThreeDotsVertical
                            onClick={() => {
                              menuBoxBioRef.current.style.display = "block";
                            }}
                            size={20}
                            style={{
                              marginRight: -4 + "px",
                              border: "1px solid",
                              borderRadius: "50%",
                              padding: "2px",
                              float: "right",
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                          />
                        )}
                        <div
                          className="menu-box-bio display-none"
                          style={{
                            position: "absolute",
                            right: "-4px",
                            top: "5px",
                          }}
                          ref={menuBoxBioRef}
                        >
                          <div className="menu-header">
                            <AiOutlineClose
                              className="cursor-pointer"
                              onClick={() => {
                                menuBoxBioRef.current.style.display = "none";
                              }}
                            />
                          </div>
                          <div
                            className="menu-line cursor-pointer"
                            onClick={() => {
                              openProfileHandler();
                              menuBoxBioRef.current.style.display = "none";
                            }}
                          >
                            Select cover
                          </div>
                          <div
                            className="menu-line cursor-pointer"
                            onClick={() => {
                              setShowvideoUrlLink(true);
                              menuBoxBioRef.current.style.display = "none";
                            }}
                          >
                            Replace the Youtube link
                          </div>
                        </div>
                      </div>

                      {imageUploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${imageUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {imageUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!imageprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {imageprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-7">
                  <>
                    {profiles.length > 1 ? (
                      <select
                        onChange={(e) => {
                          langaugeSelector(
                            e.target.id,
                            e.target.value,
                            e.target.selectedIndex - 1,
                            profiles,
                          );
                        }}
                        value={language}
                        ref={langaugeRef}
                        className="form-control mt-3"
                      >
                        {profiles.length > 1 && (
                          <option value="0">Select Langauge</option>
                        )}
                        {profiles.map((list, index) => {
                          return (
                            <option
                              key={list._id}
                              id={list.language_id.code}
                              value={list.language_id._id}
                            >
                              {list.language_id.nativeName}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <div className="text-box" style={{ padding: "7px" }}>
                        {profiles.length > 0 &&
                          profiles[0].language_id.nativeName}
                      </div>
                    )}
                  </>

                  {/* 
                  {menuType === "STORIES" &&
                    user.loginUser.accounttype === "2" && (
                      <div className="border-bottom-white margin-top-5">
                        {profiles.map((list, i) => {
                          if (i == 0) {
                            return (
                              <div
                                key={i}
                                id={`lang_list_stroy_${i}`}
                                className="profile-bio-tab profile-bio-tab-story ml-2"
                                style={{
                                  borderBottom: "solid 1px #118acf",
                                }}
                                onClick={() => {
                                  langaugeHandler(list.language_id, i, list);
                                }}
                              >
                                {list.language_id.nativeName}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={i}
                                id={`lang_list_stroy_${i}`}
                                className="profile-bio-tab profile-bio-tab-story ml-2"
                                style={{
                                  borderBottom: "none",
                                }}
                                onClick={() => {
                                  langaugeHandler(list.language_id, i, list);
                                }}
                              >
                                {list.language_id.nativeName}
                              </div>
                            );
                          }
                        })}

                        <div
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          className="tooltip"
                          //onClick={selectLanguage}
                        >
                          <span style={{ fontSize: "20px" }}>+</span>
                          <span
                            style={{ fontSize: "10px" }}
                            //ref={createProfileRefMenu}
                            className="tooltiptext"
                          >
                            Create New Language for Profile
                          </span>
                        </div>

                        <div className="clearfix"></div>
                      </div>
                    )} */}

                  <div style={{ position: "relative" }}>
                    <div className="mt-4 popup-form-area">
                      <div className="popup-lbl">Category</div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Enter category"
                        value={newCategory}
                        onChange={(e) =>
                          categgoryBasedOnLangaugeHandler(e.target.value)
                        }
                      />
                    </div>
                    {categories.length > 0 && (
                      <CatgeorySearchBox
                        categories={categories}
                        selecetCategory={selecetCategory}
                      />
                    )}
                  </div>

                  <div className="mt-4 popup-form-area">
                    <div className="popup-lbl">Title(required):</div>

                    <input
                      type="text"
                      id="Title"
                      name="Title"
                      placeholder="Title"
                      className="form-control mt-2"
                      value={storyTitle}
                      onChange={(e) => {
                        setStoryTitle(e.target.value);
                      }}
                      onBlur={(e) => {
                        setTags([e.target.value]);
                      }}
                    />
                  </div>

                  {/* {user.loginUser.accounttype !== "2" && (
                    <div
                      className="mt-3 popup-form-area text-box"
                      style={{ height: "280px" }}
                    >
                      <div className="popup-lbl">description</div>

                      <textarea
                        className="story-title text-area-desc h-100"
                        placeholder="Write Post"
                        maxlength="5000"
                        value={storyDesc}
                        onChange={(e) => {
                          setStoryDesc(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  )} */}

                  <div className="mt-2">
                    <RichText data={storyDesc} setData={setStoryDesc} />
                  </div>

                  <div className="mt-3 text-box popup-form-area tag-box">
                    <div className="popup-lbl">Tags:</div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {tags.length > 0 &&
                        tags.map((tagName, index) => {
                          return (
                            <div key={index} className="m-1 tags">
                              <div className="mr-1">{tagName}</div>
                              <div
                                className="tags-cancel-box cursor-pointer"
                                onClick={() => {
                                  setTags(tags.filter((tag, i) => i !== index));
                                }}
                              >
                                <ImCancelCircle size={18} />
                              </div>
                            </div>
                          );
                        })}

                      {tags.length < 25 && (
                        <input
                          type="text"
                          className="story-title text-area-desc"
                          placeholder="Enter coma after each tag"
                          maxLength="100"
                          style={{ width: "30%" }}
                          onKeyUp={(event) => {
                            let newTag = event.target.value.split(",")[0];
                            if (newTag.length < 100) {
                              tagErrorMessegesRef.current.innerHTML = "";
                              if (event.key === "Enter" || event.key === ",") {
                                if (event.target.value !== "") {
                                  if (tags.length < 25) {
                                    if (newTag !== "")
                                      if (!tags.includes(newTag)) {
                                        setTags([...tags, newTag]);
                                      }

                                    event.target.value = "";
                                  }
                                }
                              }
                            } else {
                              tagErrorMessegesRef.current.innerHTML =
                                "Tag length should be below 100";
                              tagErrorMessegesRef.current.style.color = "red";
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="tag-length-count-box">
                    <div ref={tagErrorMessegesRef}></div>
                    <span>
                      <span className="mr-1">{tags.length}</span>/
                      <span className="ml-1">25 tags</span>
                    </span>
                  </div>
                </div>

                {!isFromMobile && (
                  <div
                    className="col-md-5 mt-2"
                    style={{ height: "600px", overflow: "auto" }}
                  >
                    <div>
                      <div>
                        {storyType !== "video_url" && (
                          <div
                            ref={fileResiderRef}
                            className={`${
                              (storyType === "image" || storyType === "text") &&
                              "image-warapper-ratio-adujestment"
                            }`}
                          ></div>
                        )}
                        <div className="mt-2 cursor-pointer">
                          {coverUrl === "" && (
                            <div className="uploadCover">
                              <img
                                id="thumnail_bio"
                                src={addcoverorVideo}
                                className="width-100 cursor-pointer"
                                alt="thumbnail"
                                onClick={() => {
                                  fileRef.current.click();
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*,video/mp4,video/x-m4v,video/*,audio/*"
                                ref={fileRef}
                                onChange={(e) => {
                                  fileSelectHandler(e);
                                  setSelectFile(e);
                                }}
                                style={{ display: "none" }}
                              />
                            </div>
                          )}
                        </div>

                        {docUrl === "" && theDoc === "" && (
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <div onClick={() => filePickerRef.current.click()}>
                              <img
                                src={attachment}
                                style={{ height: 20 + "px" }}
                                alt="attachment"
                              />
                              <span className="ml-1">Add Attachment</span>
                            </div>
                            <input
                              ref={filePickerRef}
                              style={{ display: "none" }}
                              type="file"
                              onChange={filePickedHandler}
                            />
                          </div>
                        )}
                        {theDoc && selectedDoc && (
                          <a href={theDoc} target="_blank">
                            {selectedDoc.name}
                          </a>
                        )}
                      </div>

                      {imageUploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <span>Image uploading</span>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${imageUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {imageUploadPercentage}%
                              </div>
                            </div>
                          </div>
                          {!imageprocessingUpload && (
                            <div className="col-md-12 pt-1">
                              <span
                                className="text-primary cursor-pointer"
                                onClick={() => cancelUpload()}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Abort Uploading
                                <AiOutlineClose
                                  className="cursor-pointer"
                                  size={20}
                                />
                              </span>
                            </div>
                          )}
                          {imageprocessingUpload && (
                            <div className="col-md-12 pt-1 loading">
                              Processing...
                            </div>
                          )}
                        </div>
                      )}
                      {console.log("docUploadPercentage", docUploadPercentage)}
                      {docUploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col-md-12 pt-1">
                            <span>pdf uploading</span>
                            <div className="progress">
                              <div
                                className="progress-bar bg-success progress-bar-striped"
                                role="progressbar"
                                style={{
                                  width: `${docUploadPercentage}%`,
                                  height: "100%",
                                }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {docUploadPercentage}%
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {docprocessingUpload && (
                        <div className="col-md-12 pt-1 loading">
                          Processing...
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="footer-upload">
                  <div className="mb-2">
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="push-right"
                        onClick={sendStory}
                      >
                        Publish
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </Model>
    </>
  );
};

export default AddDocAndImageModel;
