import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";
import StoryCard from "../../Components/cards/StoryCard";
const UserSubscriptionStories = (props) => {
  const [AllUsers, setAllusers] = useState(false);
  const [storyPage, setStoryPage] = useState(1);
  const [storiesType, setStoriesType] = useState("ALL");
  const [totalStoryPage, setTotalStoryPage] = useState();
  const {
    Followers,
    SubscribedALLUserStories,
    SubscribedUserStories,
    BasicHomePageReducer: { component, user_id },
  } = useSelector((state) => ({
    ...state,
  }));

  const dispatch = useDispatch({});

  //   useEffect(() => {
  //     getSubscribedUserStories(1);
  //   }, []);

  useEffect(() => {
    if (user_id) {
      getUserStory(user_id, 1);
    } else {
      getSubscribedUserStories(1);
    }
  }, [user_id]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;

    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        if (storyPage == 1) {
          setStoryPage(storyPage + 1);

          if (storiesType === "ALL") {
            getSubscribedUserStories(storyPage + 1);
          } else {
            getUserStory(props.user.userData.loginUser._id, storyPage + 1);
          }
          return;
        }
        if (storiesType === "ALL") {
          getSubscribedUserStories(storyPage);
        } else {
          getUserStory(props.user.userData.loginUser._id, storyPage);
        }
      }
    }
  };

  const getSubscribedUserStories = async (storyPage) => {
    setStoryPage(storyPage + 1);
    setStoriesType("ALL");
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscribedALLUserStories?user_id=${props.user.userData.loginUser._id}&page=${storyPage}`;

    const res = await getRequestedDataWithHeader(
      url,
      props.user.userData.token,
    );

    setTotalStoryPage(res.data.body.Total_Page);

    if (storyPage > 1) {
      dispatch({
        type: "ALL_USER_SUB_STORIES_APPEND",
        payload: res.data.body.getStoryModel,
      });
    } else {
      dispatch({
        type: "ALL_USER_SUB_STORIES",
        payload: res.data.body,
      });
    }

    dispatch({
      type: "A_USER_SUB_STORIES",
      payload: {
        getStoryModel: [],
      },
    });
  };

  const getUserStory = async (user_id, storyPage) => {
    setStoryPage(storyPage + 1);
    setStoriesType("USER");
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscribedUserStories?user_id=${user_id}&page=${storyPage}`;

    const res = await getRequestedDataWithHeader(
      url,
      props.user.userData.token,
    );

    setTotalStoryPage(res.data.body.Total_Page);

    if (storyPage > 1) {
      dispatch({
        type: "A_USER_SUB_STORIES_APPEND",
        payload: res.data.body.getStoryModel,
      });
    } else {
      dispatch({
        type: "A_USER_SUB_STORIES",
        payload: res.data.body,
      });
    }

    dispatch({
      type: "ALL_USER_SUB_STORIES",
      payload: {
        getStoryModel: [],
      },
    });
  };

  return (
    <div className="row">
      {SubscribedALLUserStories.stories.getStoryModel.length > 0 &&
        SubscribedUserStories.stories.getStoryModel.length <= 0 &&
        SubscribedALLUserStories.stories.getStoryModel.map((story, i) => {
          return (
            <div
              className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5 padding-right-mobile-card padding-right-card"
              key={story._id}
            >
              <StoryCard
                story={story}
                userid={
                  props.user.userData ? props.user.userData.loginUser._id : ""
                }
                isLogin={props.user.isLogin}
              />
            </div>
          );
        })}
      {SubscribedUserStories.stories.getStoryModel.length > 0 &&
        SubscribedALLUserStories.stories.getStoryModel.length <= 0 &&
        SubscribedUserStories.stories.getStoryModel.map((story, i) => {
          return (
            <div
              className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5 padding-right-mobile-card padding-right-card"
              key={story._id}
            >
              <StoryCard
                story={story}
                userid={
                  props.user.userData ? props.user.userData.loginUser._id : ""
                }
                isLogin={props.user.isLogin}
              />
            </div>
          );
        })}
    </div>
  );
};

export default UserSubscriptionStories;
