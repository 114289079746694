import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import { BiArrowToTop } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { IoAddCircle } from "react-icons/io5";
import {
  getDataPostWithHeader,
  getRequestedDataWithHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";
import Register from "../Registration/Register";

const SideBarChatMenus = ({
  user_id,
  token,
  closeNav,
  setChatLanguages,
  chatLanguages,
}) => {
  const { ProfileReducers, FileUploadPercentage } = useSelector(
    (state) => state,
  );

  const { profiles } = ProfileReducers;

  const [showAddGreetingMessageModel, setShowAddGreetingMessageModel] =
    useState(false);
  const [language, setLanguage] = useState("0");
  const [add, setAdd] = useState(false);
  const [languages, setLanguages] = useState("0");
  const [languagecd, setLanguagecd] = useState();
  const [profileID, setProfileID] = useState("");
  const [greetingMessage, setGreetingMessage] = useState();
  const [greeings, setGreeting] = useState();
  const [manageChattingTeam, setMangeChattingTeam] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getLanguagesWithOutALL`;
    getRequestedData(url)
      .then((res) => {
        setLanguages(res.data.body);
      })
      .catch((err) => {
        console.log(err);
        alert.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (greeings && chatLanguages)
      if (greeings.length > 0 && chatLanguages.length > 0) {
        langaugeHandler(chatLanguages[0].language_id, 0, chatLanguages[0]);
      }
  }, [chatLanguages, greeings]);

  const greetingOfUser = async (isoOpen) => {
    if (isoOpen) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getGreetingOfUser?user_id=${user_id}`;
      try {
        let res = await getRequestedDataWithHeader(url, token);
        setGreeting(res.data.body);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const updateGreetings = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/UpdateChatGreeting`;
      const data = {
        user_id: user_id,
        language_id: language,
        profile_id: profileID,
        greeting: greetingMessage,
      };

      const res = await getDataPostWithHeader(url, data, token);
      console.log(res.data.body);
      setGreeting(res.data.body);
      setShowAddGreetingMessageModel(false);
      //onModelClose()
    } catch (err) {
      console.log(err.message);
      setShowAddGreetingMessageModel(false);
    }
  };

  const langaugeHandler = (list, i, profile) => {
    let theGreeting = {};
    greeings.forEach((greeting, i) => {
      if (greeting.language_id._id === list._id) {
        theGreeting = { ...greeting };
      }
    });

    if (theGreeting.language_id) {
      setLanguage(theGreeting.language_id._id);
      setLanguagecd(theGreeting.language_id.code);
      setGreetingMessage(theGreeting.greeting);
      setProfileID(theGreeting.profile_id);
    } else {
      setLanguage(list._id);
      setLanguagecd(list.code);
      setGreetingMessage("");
      setProfileID(profile._id);
    }
    document
      .querySelectorAll(".profile-bio-tab-story")
      .forEach((div) => (div.style.borderBottom = "none"));

    document.getElementById(`lang_list_stroy_${i}`).style.borderBottom =
      "solid 1px #118acf";
    setLanguagecd(list.code);
  };

  const showLanguageSelections = () => {
    setShowLanguage(!showLanguage);
  };

  const importFromProfiles = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/importLanguagefromProfile`;
    try {
      let res = await getRequestedDataWithHeader(url, token);
      console.log("chatlang", res.data.body);
      setChatLanguages(res.data.body);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleLanguage = async (value) => {
    setLoading(true);

    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/updatechattinglanguage`;
    const data = {
      language_cd: value,
      user_id: user_id,
    };
    try {
      let res = await getDataPostWithHeader(url, data, token);
      setChatLanguages(res.data.body);
      setLoading(false);
      setAdd(false);
    } catch (err) {
      setLoading(false);
      alert(err.message);
      console.log(err.message);
      //alert(err.message);
    }
  };

  const mnakeItDefault = async (value) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/makeChatLanguageDefault`;
      const data = {
        language_id: value,
        user_id: user_id,
      };
      let res = await getDataPostWithHeader(url, data, token);

      console.log(res.data.body);
      setChatLanguages(res.data.body);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(err.message);
    }
  };

  return (
    <>
      {!manageChattingTeam && !showAddGreetingMessageModel && !showLanguage && (
        <div
          className="closebtn cursor-pointer"
          onClick={() => {
            closeNav();
          }}
        >
          &times;
        </div>
      )}

      {!manageChattingTeam && (
        <div className="mt-3">
          <div>
            {!showLanguage && (
              <div className="flex-space-btw">
                <div
                  className="menu cursor-pointer"
                  onClick={() => {
                    greetingOfUser(!showAddGreetingMessageModel);
                    setShowAddGreetingMessageModel(
                      !showAddGreetingMessageModel,
                    );
                  }}
                >
                  <div>Greeting message</div>

                  <div
                    style={{
                      color: "#858585",
                      fontSize: "12px",
                      marginTop: "-2px",
                      marginLeft: "10px",
                    }}
                  >
                    Welcome new customers automatically
                  </div>
                </div>
                {showAddGreetingMessageModel && (
                  <AiOutlineClose
                    size={20}
                    className="cursor-pointer"
                    onClick={() => {
                      setShowAddGreetingMessageModel(false);
                    }}
                  />
                )}
              </div>
            )}
            {showAddGreetingMessageModel && (
              <div style={{ paddingLeft: "20px" }}>
                <div
                  className="border-bottom-white margin-top-5"
                  style={{ width: "100%" }}
                >
                  {chatLanguages.map((list, i) => {
                    if (i == 0) {
                      return (
                        <div
                          key={list._id}
                          id={`lang_list_stroy_${i}`}
                          className="profile-bio-tab profile-bio-tab-story ml-2"
                          style={{
                            borderBottom: "solid 1px #118acf",
                          }}
                          onClick={() => {
                            langaugeHandler(list.language_id, i, list);
                          }}
                        >
                          {list.language_id.nativeName}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={i}
                          id={`lang_list_stroy_${i}`}
                          className="profile-bio-tab profile-bio-tab-story ml-2"
                          style={{
                            borderBottom: "none",
                          }}
                          onClick={() => {
                            langaugeHandler(list.language_id, i, list);
                          }}
                        >
                          {list.language_id.nativeName}
                        </div>
                      );
                    }
                  })}

                  <div className="clearfix"></div>
                  <div
                    className="row-model pt-2"
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <div className="mt-2 text-box" style={{ width: "100%" }}>
                      <label for="Title" title="Title">
                        Greeting Message
                      </label>
                      <br />
                      <textarea
                        id="Title"
                        name="Title"
                        placeholder="Greetings"
                        className="story-title"
                        value={greetingMessage}
                        style={{ height: "120px", resize: "none" }}
                        onChange={(e) => {
                          setGreetingMessage(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className="pr-2 mr-10 margin-top-5"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          className="push-right"
                          style={{ background: "#1565C0", color: "white" }}
                          onClick={updateGreetings}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            <div>
              {!showAddGreetingMessageModel && (
                <div className="flex-space-btw">
                  <div
                    className="cursor-pointer mb-3"
                    onClick={showLanguageSelections}
                  >
                    Chat languages
                    <div
                      style={{
                        color: "#858585",
                        fontSize: "12px",
                        marginTop: "-2px",
                        marginLeft: "10px",
                      }}
                    >
                      Select in which langauges you can chat
                    </div>
                  </div>
                  {showLanguage && (
                    <AiOutlineClose
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setShowLanguage(false);
                      }}
                    />
                  )}
                </div>
              )}

              {showLanguage && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {chatLanguages.length <= 0 && (
                    <div className="flex-space-btw">
                      <div>No Languages found</div>
                      <div
                        className="cursor-pointer mb-3"
                        style={{ fontSize: "14px" }}
                      >
                        +
                        <span
                          style={{ color: "grey" }}
                          onClick={() => setAdd(true)}
                        >
                          Add Language
                        </span>
                      </div>
                    </div>
                  )}

                  {chatLanguages.length <= 0 && (
                    <button
                      className="btn btn-primary text-align-center"
                      onClick={importFromProfiles}
                    >
                      Import all Langauge from profiles
                    </button>
                  )}

                  {chatLanguages.length > 0 &&
                    chatLanguages.map((list, index) => {
                      if (list.defaultOne) {
                        return (
                          <div
                            key={index}
                            id={`${list._id}`}
                            className="item-lang-list flex-space-btw display-flex-align-center p-1"
                            onClick={() => mnakeItDefault(list.language_id._id)}
                          >
                            {list.language_id.nativeName}
                            {/* <img
                          src={remove}
                          alt="add"
                          className="float-right mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove(list._id)}
                        /> */}
                            <span className="for-user-understand">Default</span>
                          </div>
                        );
                      }
                    })}

                  {chatLanguages.length > 0 &&
                    chatLanguages.map((list, index) => {
                      if (!list.defaultOne) {
                        return (
                          <div
                            key={index}
                            id={`${list._id}`}
                            className="item-lang-list flex-space-btw display-flex-align-center p-1"
                            onClick={() => mnakeItDefault(list.language_id._id)}
                          >
                            {list.language_id.nativeName}
                            {/* <img
                          src={remove}
                          alt="add"
                          className="float-right mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove(list._id)}
                        /> */}
                            <span className="for-user-understand">
                              Make it default
                            </span>
                            <div>
                              <BiArrowToTop size={20} />
                            </div>
                          </div>
                        );
                      }
                    })}

                  {loading && (
                    <>
                      <span className="loader-spiner loader-spiner-addtionl"></span>
                      <span>ading...</span>
                    </>
                  )}

                  {!add && (
                    <div onClick={() => setAdd(true)} className="mt-2">
                      <IoAddCircle size={20} />
                      <span
                        className="ml-1"
                        style={{
                          position: "relative",
                          top: "3px",
                          cursor: "pointer",
                        }}
                      >
                        Add more Language
                      </span>
                    </div>
                  )}

                  {add && languages.length > 0 && (
                    <div className="display-flex display-flex-align-center">
                      <select
                        className="form-control registration-from-control-border"
                        onChange={(e) => {
                          handleLanguage(e.target.value);
                        }}
                      >
                        <option value="0">Add Langauge</option>
                        {languages.map((lang) => {
                          return (
                            <option key={lang._id} value={lang.code}>
                              {lang.name}
                            </option>
                          );
                        })}
                      </select>
                      <AiOutlineClose size={20} onClick={() => setAdd(false)} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* {!manageChattingTeam && (
        <div className="mt-2" onClick={() => setMangeChattingTeam(true)}>
          Manage Chatting Team
        </div>
      )}
      {manageChattingTeam && (
        <div>
          {
            <Register
              from={"chatregister"}
              setFunction={setMangeChattingTeam}
            />
          }
        </div>
      )} */}
    </>
  );
};

export default SideBarChatMenus;
