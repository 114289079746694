import React, { useEffect, useRef, useState } from "react";
import { getRequestedData } from "../../functions/editProfile/editProfile";
import { toast } from "react-toastify";

const Price = ({ selectStyle, currency, setCurrency, price, setPrice }) => {
  const currencyRef = useRef(null);
  console.log(currency, "currency");
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const url = `${process.env.REACT_APP_CURRENCY_API_URL}/currencies?apikey=${process.env.REACT_APP_CURRENCY_API_KEY}&type=fiat`;

        console.log(url);

        const res = await getRequestedData(url);

        console.log("res currencies", res.data.data);

        const values = Object.values(res.data.data);
        setCurrencies(values);
        console.log("res currencies 2", values);
        // const values1 = Object.values(values[1]);
        // console.log(values1);
        //setCurrencies(res.data.response);

        //setCountires(res.data.body);
      } catch (err) {
        console.log(err);
        alert("something went wrong");
      }
    };
    getCurrencies();
  }, []);

  const currencyHandaler = (e) => {
    setCurrency(e.target.value);
  };
  return (
    <select
      ref={currencyRef}
      defaultValue={currency || "KWD"}
      style={selectStyle}
      // onChange={(e) => {
      //   setCountryCurrency(e.target.value);
      //   handleCurrecnyConverter(e.target.value);
      // }}

      onChange={currencyHandaler}
    >
      {currencies?.map((currency) => {
        return (
          <option value={currency.code}>
            {currency.code + " " + currency.symbol_native}
          </option>
        );
      })}
    </select>
  );
};

export default Price;
