import { useEffect, useState } from 'react'

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [refreshIntervalId, setRefreshIntervalId] = useState(null)

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        if (!navigator.mediaDevices?.enumerateDevices) {
          console.log("enumerateDevices() not supported. Upadte Browser");
        } else {
          // List cameras and microphones.
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
    
              var mic = devices.find(function(device) {
                return device.kind === "audioinput"
              });
              
              if(mic === "audioinput")
              {
              requestRecorder()
              .then((stream) => {
                setRecorder(stream)
              })
              .catch((error) => {
                console.log(error)
              })
            }else{
              alert("Please Add Mic to the device")
              setIsRecording(false)
            }
            })
   
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
            });
        }

    
      
  

      
      }
      return
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start()
    } else {
      recorder.stop()
    }

    // Obtain the audio when ready.
    const handleData = (e) => {
      setAudioURL(e.data)
    }

    recorder.addEventListener('dataavailable', handleData)
    return () => recorder.removeEventListener('dataavailable', handleData)
  }, [recorder, isRecording])

  const startRecording = () => {
    setIsRecording(true)
   
  }

  const stopRecording = () => {
    clearInterval(refreshIntervalId)
    setIsRecording(false)
    setRefreshIntervalId('')
  }

  return [audioURL, isRecording, startRecording, stopRecording, recorder]
}

async function requestRecorder() {

  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  return new MediaRecorder(stream)
}
export default useRecorder
