import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";

import { AiOutlineClose, AiFillPlayCircle } from "react-icons/ai";
import { IoMdDocument, IoMdGrid } from "react-icons/io";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import { fetchStory, buySubscriptionGroup } from "../functions/story/story";
import {
  getDataPostWithOutHeader,
  getRequestedDataWithHeader,
  getDataPostWithHeader,
} from "../functions/editProfile/editProfile";
import LoadingCard from "../Components/cards/LoadingCard";
import { Link } from "react-router-dom";
import defualtImg from "../images/Read&ShareThumbnail.png";

import HlsPlayer from "../Components/players/HlsPlayer";
import VPlayer from "../Components/players/VPlayer";

import { hoursMinSec } from "../utils/sharedfunction";
const weburl = process.env.REACT_APP_BACKEND_URL;

const SubscriptioGroupCourse = ({ user }) => {
  const params = useParams();

  const [story, setStory] = useState({});
  const [storyid, setStoryID] = useState("");
  const [lession, setLession] = useState({});
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    intro: true,
    subscriptionInfo: [],
  });
  const [subInfo, setSubInfo] = useState([]);
  const [subscriptionStories, setSubscriptionStories] = useState([]);

  const [loading, setLoading] = useState();

  const [selectTab, setSelectTab] = useState("");

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    subDetails(params.id);
  }, [params]);

  useEffect(() => {
    setSelectTab("description");
    document.querySelectorAll(".chapter-tabs")[0].style.color = "black";
    document.querySelectorAll(".chapter-tabs")[0].style.borderBottom =
      "1px solid black";
  }, [params]);

  // useEffect(() => {
  //   document.addEventListener('scroll', handleScrolListner)
  //   return () => {
  //     document.removeEventListener('scroll', handleScrolListner)
  //   }
  // }, [storyPage, totalStoryPage])

  // const handleScrolListner = () => {
  //   const TotalPage = totalStoryPage || 1
  //   const scrolled = window.scrollY
  //   const scrollable =
  //     document.documentElement.scrollHeight - window.innerHeight

  //   if (Math.ceil(scrolled) >= scrollable) {
  //     if (storyPage <= TotalPage) {
  //       if (story.subscription_id._id) {
  //         loadStoriesBasedOnCategoryHandler(
  //           story.subscription_id._id,
  //           storyPage
  //         )
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (story.story_url_quality_versions)
  //     if (story.story_url_quality_versions.url_360) {
  //       let qualties = [];
  //       for (let i in story.story_url_quality_versions) {
  //         qualties.push({
  //           quality: i.split("_")[1] + "p",
  //           url: story.story_url_quality_versions[i],
  //         });
  //       }

  //       setQualities(qualties.reverse());
  //     }
  // }, [story]);

  const subDetails = async (sub_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscriptionGroup?subscription_id=${sub_id}`;

      const res = await getRequestedDataWithHeader(url, user.userData.token);

      setSubscriptionDetails({ intro: true, subscriptionInfo: res.data.body });
      setSubInfo(res.data.body);
      console.log(res.data.body);
      sectionAndStories(sub_id);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const sectionAndStories = async (sub_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getSubscriptionSectionsAndStories?subscription_id=${sub_id}`;

      const res = await getRequestedDataWithHeader(url, user.userData.token);
      setSubscriptionStories(res.data.body);
      console.log(res.data.body);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const toggleSubMenus = (section) => {
    if (
      document.getElementById(`${section._id}_sections`).style.display ==
      "block"
    ) {
      document.getElementById(`${section._id}_sections`).style.display = "none";
      document.getElementById(`${section._id}_arrow_down`).style.display =
        "block";
      document.getElementById(`${section._id}_arrow_up`).style.display = "none";
    } else {
      document.getElementById(`${section._id}_sections`).style.display =
        "block";
      document.getElementById(`${section._id}_arrow_down`).style.display =
        "none";
      document.getElementById(`${section._id}_arrow_up`).style.display =
        "block";
    }
  };

  const selectTabs = (e, tab) => {
    document.querySelectorAll(".chapter-tabs").forEach((div) => {
      div.style.color = "#6a6f73";
      div.style.borderBottom = "none";
    });

    e.target.style.color = "black";
    e.target.style.borderBottom = "1px solid black";

    setSelectTab(tab);
  };

  const updateEachUserLession = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/updateEachUserLession`;

      const res = await getDataPostWithHeader(url, data, user.userData.token);
      console.log(res.data.body);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const lessionForceComplete = (lession_id, user_id, e) => {
    let lession_finished = 0;
    if (e.target.checked) {
      lession_finished = 1;
    } else {
      lession_finished = 0;
    }

    const data = {
      lession_id: lession_id,
      user_id: user_id,
      lession_finished: lession_finished,
      lession_new: 0,
      lession_update: 0,
    };

    updateEachUserLession(data);
  };

  const videoTimeVideJS = (e) => {
    videoTimerUpdate(lession.lession_id, lession.user_id, e);
  };

  const videoTimerUpdate = async (lession_id, user_id, e) => {
    let data = "";

    if (e.target.currentTime >= e.target.duration - 20) {
      data = {
        lession_id: lession_id,
        user_id: user_id,
        video_position: e.target.currentTime,
        lession_finished: 1,
        lession_new: 0,
        lession_update: 0,
      };
    } else {
      data = {
        lession_id: lession_id,
        user_id: user_id,
        video_position: e.target.currentTime,
        lession_new: 0,
        lession_update: 0,
      };
    }

    updateEachUserLession(data);
  };

  return (
    <>
      {/* <Model show={showDummyCard}>
        <div className='modal-container-content' style={{ width: '43%' }}>
          <header
            className='modal__header text-center'
            style={{ padding: '10px' }}
          >
            <h3>
              Enter Your debit card details(dummy)
              <AiOutlineClose
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  marginTop: '4px',
                }}
                onClick={closeDummyCard}
              />
              <div
                style={{
                  display: 'inline-block',
                  position: 'absolute',
                  right: '25%',
                }}
                className='cursor-pointer '
              ></div>
            </h3>
          </header>
          <div className='modal__content'>
            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 '>
              <input
                style={{ width: '100%' }}
                placeholder='4444 4444 4444 4444'
                type='text'
              />
            </div>
            <div className='mt-2 text-center'>
              <button className='btn btn-primary'>subscribe</button>
            </div>
          </div>
        </div>
      </Model> */}
      <div className="container-fluid">
        <div className="row mt-3">
          <div
            className="col-md-9 padding-right-mobile"
            style={{ marginRight: "0px", paddingRight: "0px" }}
          >
            {subscriptionDetails.intro &&
              subscriptionDetails.subscriptionInfo.length > 0 && (
                <div>
                  {subscriptionDetails.subscriptionInfo[0].converted_url !==
                    "" &&
                    HLSVIDEOPLAYER &&
                    subscriptionDetails.subscriptionInfo[0]
                      .video_encode_processed &&
                    subscriptionDetails.subscriptionInfo[0]
                      .video_encode_process_status === "completed" && (
                      <HlsPlayer
                        url={
                          subscriptionDetails.subscriptionInfo[0].converted_url
                        }
                        setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                      />
                    )}

                  {/* {(subscriptionDetails.subscriptionInfo[0].converted_url ===
                    "" ||
                    !HLSVIDEOPLAYER) &&
                    subscriptionDetails.video_encode_processed && (
                      <>
                        <VPlayer qualities={qualities} />
                      </>
                    )} */}

                  {!subscriptionDetails.subscriptionInfo[0]
                    .video_encode_processed && (
                    <video
                      width="100%"
                      controls
                      src={
                        subscriptionDetails.subscriptionInfo[0]
                          .group_introduction_video_url
                      }
                      autoPlay
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}

                  {subscriptionDetails.subscriptionInfo[0].converted_url ===
                    "" &&
                    // story.story_url_quality_versions.url_360 === "" &&
                    subscriptionDetails.subscriptionInfo[0]
                      .video_encode_processed && (
                      <video
                        width="100%"
                        controls
                        src={
                          subscriptionDetails.subscriptionInfo[0]
                            .group_introduction_video_url
                        }
                        style={{ width: "100%" }}
                        autoPlay
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                </div>
              )}

            {story._id && story.story_type == "text" && (
              <>
                <img
                  style={{
                    height: "500px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  className="no-height-mobile"
                  src={story.story_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </>
            )}

            {story._id && story.story_type == "image" && (
              <>
                <img
                  style={{
                    height: "500px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  src={story.story_url}
                  alt="im-story"
                  className="no-height-mobile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </>
            )}

            {story._id && story.story_type == "audio" && (
              <>
                <audio
                  controls
                  src={story.story_url}
                  className="story-margin "
                  style={{ width: "100%" }}
                ></audio>
              </>
            )}

            {story._id && story.story_type == "video" && (
              <div>
                {story.converted_url !== "" &&
                  HLSVIDEOPLAYER &&
                  story.video_encode_processed &&
                  story.video_encode_process_status === "completed" && (
                    <HlsPlayer
                      url={story.converted_url}
                      setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                    />
                  )}

                {/* {(story.story_url_quality_versions.url_720 !== "" ||
                  !HLSVIDEOPLAYER) &&
                  story.video_encode_processed && (
                    <VPlayer qualities={qualities} />
                  )} */}

                {!story.video_encode_processed && (
                  <video
                    width="100%"
                    controls
                    src={story.story_url}
                    style={{ width: "100%" }}
                    onPause={(e) => {
                      videoTimerUpdate(lession.lession_id, lession.user_id, e);
                    }}
                    onLoadedMetadata={(e) => {
                      e.target.currentTime = lession.video_position;
                    }}
                  >
                    Your browser does not support the video tag.
                  </video>
                )}

                {story.story_converted_url === "" &&
                  // story.story_url_quality_versions.url_360 === "" &&
                  story.video_encode_processed && (
                    <video
                      width="100%"
                      controls
                      src={story.story_url}
                      style={{ width: "100%" }}
                      onPause={(e) => {
                        videoTimerUpdate(
                          lession.lession_id,
                          lession.user_id,
                          e,
                        );
                      }}
                      onLoadedMetadata={(e) => {
                        e.target.currentTime = lession.video_position;
                      }}
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}
              </div>
            )}
            <div
              style={{
                display: "flex",
                gap: "15px",
                padding: "5px",
                fontSize: "20px",
                color: "#6a6f73",
                fontWeight: "400",
                borderBottom: "1px solid #d1d7dc",
                marginTop: "10px",
              }}
            >
              <div
                className="chapter-tabs"
                onClick={(e) => {
                  selectTabs(e, "description");
                }}
              >
                Overview
              </div>
              <div
                className="chapter-tabs"
                onClick={(e) => {
                  selectTabs(e, "notes");
                }}
              >
                Notes
              </div>
              <div
                className="chapter-tabs"
                onClick={(e) => {
                  selectTabs(e, "attachment");
                }}
              >
                Announcements
              </div>
            </div>

            {subscriptionDetails.intro &&
              subscriptionDetails.subscriptionInfo.length > 0 &&
              selectTab === "description" && (
                <>
                  <div
                    className="mt-4"
                    style={{
                      fontSize: "1.6rem",
                      fontWeight: "700",
                      color: "black",
                    }}
                  >
                    About this course
                  </div>
                  <div className="mt-2">
                    {parse(
                      subscriptionDetails.subscriptionInfo[0].group_description,
                    )}
                  </div>{" "}
                </>
              )}
            {story._id && selectTab === "description" && (
              <div
                className="mt-2"
                style={{
                  fontSize: "14px",
                }}
              >
                <div>
                  <div
                    style={{
                      fontWeight: "500",
                      color: "black",
                      marginLeft: "10px",
                      float: "left",
                    }}
                  >
                    {story.subscription_id.group_name}
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>

                <div
                  className="mt-4"
                  style={{
                    fontSize: "1.6rem",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  About this chapter
                </div>
                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <div className="mt-3">{parse(story.story_text)}</div>
              </div>
            )}

            {story._id && selectTab === "notes" && (
              <div
                className="mt-2"
                style={{
                  fontSize: "14px",
                }}
              >
                <table
                  className="table table-striped"
                  style={{ cursor: "pointer" }}
                >
                  <tbody>
                    {story.attachment.length >= 0 &&
                      story.attachment.map((attachment, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {" "}
                              <Link to="#" download={attachment.attachment_url}>
                                {attachment.display_doc_name}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div
            className="col-md-3"
            style={{
              margin: "0px",
              padding: "0px",
              background: "white",
              fontFamily:
                "sf pro display,-apple-system,BlinkMacSystemFont,Roboto,segoe ui,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #d1d7dc",
                padding: "10px",
                fontSize: "1.2rem",
              }}
            >
              Course content{" "}
            </div>
            {subscriptionStories.length > 0 &&
              subscriptionStories.map((section, i) => {
                return (
                  <>
                    <div
                      style={{
                        padding: "15px",
                        background: "#f7f9fa",
                        borderBottom: "1px solid #d1d7dc",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        toggleSubMenus(section);
                      }}
                      key={i}
                    >
                      Section {i + 1} : {section.section_title}
                      <span
                        style={{ float: "right", cursor: "pointer" }}
                        id={`${section._id}_arrow_down`}
                      >
                        <BsChevronDown />
                      </span>
                      <span
                        style={{
                          float: "right",
                          display: "none",
                        }}
                        id={`${section._id}_arrow_up`}
                      >
                        <BsChevronUp />
                      </span>
                    </div>
                    <div
                      id={`${section._id}_sections`}
                      style={{ display: "none" }}
                    >
                      {section.stories_of_this_section.map((story, i) => {
                        let totalSeconds = story.story_duration;

                        let hours = Math.floor(totalSeconds / 3600);
                        totalSeconds %= 3600;
                        let minutes = Math.floor(totalSeconds / 60);
                        let seconds = totalSeconds % 60;

                        let format = "";
                        if (hours > 0) {
                          format = `${hours}hour ${minutes}min`;
                        } else {
                          format = `${minutes}min`;
                        }

                        if (format == "0min") {
                          format = `${Math.floor(seconds)}sec`;
                        }
                        return (
                          <div key={story._id}>
                            <div
                              style={{
                                padding: "10px 20px",
                                cursor: "pointer",
                                display: "flex",
                              }}
                              onClick={() => {
                                console.log("story click", story);
                                setStory(story);
                                setStoryID(story._id);
                                setLession(section.lession_id[i]);
                                setSubscriptionDetails({
                                  intro: false,
                                  subscriptionInfo: subInfo,
                                });
                              }}
                              className={`${
                                storyid === story._id ? "select-bg-color" : ""
                              }`}
                            >
                              <div
                                style={{
                                  margin: "5px",
                                  marginRight: "10px",
                                  float: "left",
                                }}
                              >
                                {section.lession_id[i].lession_finished ==
                                  1 && (
                                  <input
                                    type="checkbox"
                                    id={`${story._id}_checkbox`}
                                    style={{
                                      width: "1.2rem",
                                      height: "1.2rem",
                                    }}
                                    checked
                                  />
                                )}

                                {!section.lession_id[i].lession_finished && (
                                  <input
                                    type="checkbox"
                                    id={`${story._id}_checkbox`}
                                    style={{
                                      width: "1.2rem",
                                      height: "1.2rem",
                                    }}
                                    onClick={(e) =>
                                      lessionForceComplete(
                                        section.lession_id[i].lession_id,
                                        section.lession_id[i].user_id,
                                        e,
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "100px",
                                  float: "left",
                                }}
                              >
                                {i + 1}. {story.story_title}
                                {story.story_type == "text" && (
                                  <div>
                                    <IoMdDocument size={14} />{" "}
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "#1c1d1f;",
                                      }}
                                    >
                                      {format}
                                    </span>
                                  </div>
                                )}
                                {story.story_type == "video" && (
                                  <div>
                                    <AiFillPlayCircle size={14} />{" "}
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "#1c1d1f;",
                                      }}
                                    >
                                      {format}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div style={{ clear: "both" }}></div>

                              <div
                                style={{
                                  alignSelf: "center",
                                  marginLeft: "50%",
                                  color: "#6a6f73",
                                  fontWeight: "500",
                                }}
                              >
                                {section.lession_id[i].lession_update === 1 && (
                                  <span>Update</span>
                                )}

                                {section.lession_id[i].lession_new === 1 && (
                                  <span>New</span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            {loading && <LoadingCard count={3} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptioGroupCourse;
