import React, { useState, useEffect, useRef } from "react";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { IoAddCircle } from "react-icons/io5";
import { editPersonalDetails } from "../../../functions/editProfile/editProfile";

import remove from "../../../images/remove.png";
import { responseMessageDispaly } from "../../../utils/sharedfunction";
import { categgoryBasedLangauge } from "../../../utils/apis/commonApis";

const EditCategorySection = (props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(false);
  const list = useRef(null);
  const uploadMSG = useRef(null);

  const categgoryBasedOnLangaugeHandler = async (e) => {
    try {
      if (e.target.value !== "0") {
        let cats = await categgoryBasedLangauge();

        setCategories(cats);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const handleRemove = async (value) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/deleteUserCategory`;
    const data = {
      userCategory: value,
      user_id: props.user._id,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      props.dispatch({
        type: "userCategories",
        payload: res.data.body.userCategory,
      });
      setLoading(false);
      responseMessageDispaly(
        uploadMSG,
        "Selected Category removed successfully",
        "red",
      );
      toast.success("Selected Category removed successfully");
    } catch (err) {
      setLoading(false);
      responseMessageDispaly(uploadMSG, "somewthing went wrong", "red");
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const handleCategory = async (value, name) => {
    var children = list.current.children;
    let flag = 0;
    console.log(children);

    if (value !== "0") {
      if (value !== "new") {
        setLoading(true);

        if (children.length > 0) {
          for (var i = 0; i < children.length; i++) {
            var child = children[i];
            var val = child.innerHTML.split("<")[0].trim();

            if (val === name.trim()) {
              flag = 0;
              setLoading(false);
              break;
            } else {
              flag = 1;
            }
          }
        } else {
          flag = 1;
        }

        if (flag === 1) {
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserCategory`;
          const data = {
            userCategory: value,
            user_id: props.user._id,
          };
          try {
            let res = await editPersonalDetails(url, data, props.token);
            console.log(res.data.body.userCategory);
            props.dispatch({
              type: "userCategories",
              payload: res.data.body.userCategory,
            });
            setLoading(false);
            responseMessageDispaly(
              uploadMSG,
              "Category has updated successfully",
              "Green",
            );
            toast.success("Catgeory has updated");
          } catch (err) {
            setLoading(false);
            responseMessageDispaly(uploadMSG, "Something went wrong", "red");
            list.current.removeChild(list.current.lastChild);
            console.log(err.message);
            toast.error(err.message);
          }
        }
      } else if (value === "new") {
        //add new category
      }
    }
  };

  return (
    <>
      <div className="edit-content-widget">
        <div className="ui-tabs-title">
          <h4 className="float-left">Profession Category</h4>
          {loading && (
            <>
              <Spin className="ml-3" />
              <span className="text-danger">....</span>
            </>
          )}
          {showEdit || (
            <GoPencil
              className="float-right mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEdit(true);
              }}
            />
          )}
          {showEdit && (
            <AiOutlineClose
              className="float-right mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEdit(false);
              }}
            />
          )}
          <div style={{ clear: "both" }}></div>
        </div>

        {showEdit || (
          <div className="tab-inner-content-padding">
            {console.log(props.UserRequiredDropDown.categories)}
            {props.UserRequiredDropDown.categories.map((list, index) => {
              return <div key={index}>{list.category_name}</div>;
            })}
          </div>
        )}

        {showEdit && (
          <div className="tab-inner-content-padding">
            <div ref={list}>
              {props.UserRequiredDropDown.categories.length > 1
                ? props.UserRequiredDropDown.categories.map((list, index) => {
                    return (
                      <div key={index} id={`${list._id}`} className="item">
                        {list.category_name}
                        <img
                          src={remove}
                          alt="add"
                          className="float-right mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove(list._id)}
                        />
                      </div>
                    );
                  })
                : props.UserRequiredDropDown.categories.map((list, index) => {
                    return (
                      <div key={index} id={`${list._id}`} className="item">
                        {list.category_name}
                      </div>
                    );
                  })}
            </div>

            <div className=" mt-3">
              {add || (
                <Link
                  to="#"
                  onClick={() => {
                    setAdd(true);
                  }}
                >
                  <IoAddCircle size="20px" />
                  <span
                    className="ml-1"
                    style={{ position: "relative", top: "3px" }}
                  >
                    Add Category
                  </span>
                </Link>
              )}
              {add && props.UserRequiredDropDown.languages.length > 0 && (
                <div>
                  <h6>Add Category</h6>
                  <select
                    className="form-control registration-from-control-border"
                    onChange={(e) => {
                      categgoryBasedOnLangaugeHandler(e);
                    }}
                  >
                    <option value="0">Select Language</option>
                    {props.UserRequiredDropDown.languages.map((lang) => {
                      return (
                        <option key={lang._id} value={lang.code} id={lang._id}>
                          {lang.name}
                        </option>
                      );
                    })}
                  </select>

                  <select
                    className="form-control registration-from-control-border mt-3"
                    onChange={(e) => {
                      handleCategory(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text,
                      );
                    }}
                  >
                    <option value="0">Select Category</option>

                    {categories.length > 0 &&
                      categories.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.category_name}
                          </option>
                        );
                      })}
                    <option value="new">
                      Add new category If not avaible in the above list
                    </option>
                  </select>
                </div>
              )}
            </div>
          </div>
        )}
        <div ref={uploadMSG}></div>
      </div>
    </>
  );
};

const MapStateToProps = ({ UserRequiredDropDown }) => {
  return { UserRequiredDropDown };
};

export default connect(MapStateToProps)(EditCategorySection);
