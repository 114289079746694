import axios from "axios";

export const fetchStory = async (url, data) => {
  try {
    return await axios.post(url, data);
  } catch (error) {
    throw new Error(error);
  }
};

export const buySubscriptionGroup = async (url, data, authtoken) => {
  try {
    return await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};
