import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { selectValueValue, selectIdValue } from "../../utils/sharedfunction";

const Langauge = ({
  setProfileId,
  setLanguage,
  setLangaugeId,
  selectStyle,
}) => {
  const {
    ProfileReducers: { profiles },
  } = useSelector((state) => state);

  const categgoryBasedOnLangaugeHandler = (e) => {
    setLanguage(e.target.value);
    setLangaugeId(e.target.value);
    setProfileId(selectIdValue(e.target));
  };

  return (
    <>
      <select
        name="language"
        style={selectStyle}
        onChange={categgoryBasedOnLangaugeHandler}
      >
        <option value="0" id="0">
          Choose Langauge
        </option>
        {profiles.length > 0 &&
          profiles.map((profile) => {
            return (
              <option
                key={profile._id}
                value={profile.language_id._id}
                id={profile._id}
              >
                {profile.language_id.name}
              </option>
            );
          })}
      </select>
    </>
  );
};

export default Langauge;
