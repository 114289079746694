import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AiOutlineSave,
  AiOutlinePlusCircle,
  AiOutlineEdit,
  AiOutlineClose,
} from "react-icons/ai";
import { toast } from "react-toastify";

import Locations from "./Locations";
import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
  getRequestedDataWithHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";

import { responseMessageDispaly } from "../../utils/sharedfunction";

const AddLocations = ({ user }) => {
  const { UserRequiredDropDown, ProfileReducers, FileUploadPercentage } =
    useSelector((state) => state);

  const { profiles } = ProfileReducers;

  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [orgLocations, setOrgLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [locationID, setLocationID] = useState("");
  const [profileId, setProfileId] = useState("");
  const [officeHours, setOfficeHours] = useState({ start: "", end: "" });
  const [country, setCountry] = useState("0");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [postal, setPostal] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [countries, setCountires] = useState("");
  const [countryDailCode, setCountryDailCode] = useState("");

  const errormesseageRef = useRef(null);

  useEffect(() => {
    if (profiles.length > 0) {
      setProfileId(profiles[0]._id);
    }
    getCountries();
  }, []);

  useEffect(() => {
    if (location._id) {
      console.log(location.office_name);
      setState(location.state);
      setCity(location.city);
      setAddress(location.address);
      setPostal(location.postal);
      setPhone(location.phone);
      setWhatsapp(location.whatsapp);
      setEmail(location.email);
      setFax(location.fax);
      setLatitude(location.latitude);
      setLongitude(location.longitude);
      setLocationID(location._id);
      setCountry(location.country);
    }
  }, [location]);

  const getCountries = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getCountries`;
      const res = await getRequestedData(url);
      setCountires(res.data.body);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const getLocations = async (page_no) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getOrgLocations?user_id=${user.loginUser._id}&page=${page_no}`;

      setPageNo(page_no + 1);
      const res = await getRequestedDataWithHeader(url, user.token);

      if (page_no > 1) {
        setOrgLocations((oldArray) => [
          ...res.data.body.locations,
          ...oldArray,
        ]);
      } else {
        setOrgLocations(res.data.body.locations);
      }
      setTotalPage(res.data.body.Total_Page);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const editLocations = (location) => {
    console.log("country", location.country);
    setEditMode(true);
    setState(location.state);
    setCity(location.city);
    setAddress(location.address);
    setPostal(location.postal);
    setPhone(location.phone);
    setWhatsapp(location.whatsapp);
    setEmail(location.email);
    setFax(location.fax);
    setLatitude(location.latitude);
    setLongitude(location.longitude);
    setLocationID(location._id);
    setCountry(location.country._id);
    setCountryDailCode(location.country.dial_cd);
    setOfficeHours(location.office_hours);
  };

  const addLocation = () => {
    setEditMode(true);
    setLocationID("");
    setState("");
    setCity("");
    setAddress("");
    setPostal("");
    setPhone("");
    setWhatsapp("");
    setEmail("");
    setFax("");
    setLatitude("");
    setLongitude("");
    setLocationID("");
    setCountry("");
    setOfficeHours({ start: "", end: "" });
    setCountryDailCode("");
  };

  const saveLocation = async () => {
    if (profileId === "") {
      responseMessageDispaly(
        errormesseageRef,
        "Please selecet organization",
        "Red",
      );
      return;
    }

    if (country === "0") {
      responseMessageDispaly(errormesseageRef, "Please selecet country", "Red");
      return;
    }
    const user_id = user.loginUser._id;
    try {
      const data = {
        user_id: user_id,
        country: country,
        address: address,
        phone: phone,
        whatsapp: whatsapp,
        email: email,
        profile_id: profileId,
        user_id: user_id,
      };

      if (fax) {
        data.fax = fax;
      }

      if (state) {
        data.state = state;
      }

      if (city) {
        data.city = city;
      }

      if (postal) {
        data.postal = postal;
      }

      if (whatsapp) {
        data.whatsapp = whatsapp;
      }

      if (latitude) {
        data.latitude = latitude;
      }

      if (longitude) {
        data.longitude = longitude;
      }

      if (locationID) {
        data.location_id = locationID;
      }

      if (officeHours.start) {
        if (!officeHours.end) {
          alert("please provide office end time too");
          return;
        }
        data.office_hours = { start: officeHours.start, end: officeHours.end };
      }

      if (officeHours.end) {
        if (!officeHours.start) {
          alert("please provide office start time too");
          return;
        }
        data.office_hours = { start: officeHours.start, end: officeHours.end };
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addLocations`;
      const res = await getDataPostWithHeader(url, data, user.token);
      setEditMode(false);
      getLocations(1);
    } catch (err) {
      responseMessageDispaly(errormesseageRef, err.message, "Red");
    }
  };

  return (
    <div className="col-md-4 col-sm-12 mt-2">
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="mr-2 cursor-pointer"
      >
        <div ref={errormesseageRef}></div>
        <span>
          {editMode && (
            <AiOutlineSave size={24} onClick={saveLocation} className="mr-2" />
          )}
          {editMode && (
            <AiOutlineClose
              size={24}
              onClick={() => {
                setEditMode(false);
              }}
            />
          )}
        </span>

        {!editMode && (
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <span style={{ textDecoration: "underline" }}>Add New Contact</span>
            <AiOutlinePlusCircle size={25} onClick={addLocation} />
          </span>
        )}
      </div>

      {!editMode && (
        <Locations
          user_id={user.loginUser._id}
          setLocation={setLocation}
          editLocations={editLocations}
        />
      )}

      {editMode && (
        <table id="edit-profile-section">
          <tbody>
            {/* <tr>
              <td className="padding-5" style={{ width: "200px" }}>
                Office Name
              </td>
              <td
                className="padding-5 "
                id="langauge_view"
                style={{ width: "100%" }}
              >
                {
                  <input
                    type="text"
                    className="form-control"
                    value={officeName}
                    onChange={(e) => {
                      setOfficeName(e.target.value);
                    }}
                  />
                }
              </td>
            </tr> */}
            <tr>
              <td className="padding-5" style={{ width: "200px" }}>
                Country
              </td>
              <td className="padding-5">
                <select
                  className="form-control"
                  defaultValue={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    setCountryDailCode(
                      e.target.options[e.target.selectedIndex].id,
                    );
                  }}
                >
                  <option value="0">Select Country</option>
                  {countries.length > 0 &&
                    countries.map((country) => {
                      return (
                        <option
                          value={country._id}
                          key={country._id}
                          id={country.dial_cd}
                        >
                          {country.country_english}
                        </option>
                      );
                    })}
                </select>
              </td>
            </tr>
            {/* 
            <tr>
              <td className="padding-5 ">State</td>
              <td className="padding-5" id="cat_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                }
              </td>
            </tr>

            <tr>
              <td className="padding-5 ">City</td>
              <td className="padding-5" id="langauge_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                }
              </td>
            </tr> */}

            <tr>
              <td className="padding-5 ">Address</td>
              <td className="padding-5" id="cat_view">
                {
                  <textarea
                    id="address"
                    name="address"
                    rows="4"
                    cols="50"
                    className="form-control"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    value={address}
                  ></textarea>
                }
              </td>
            </tr>

            <tr>
              <td className="padding-5 ">Postal</td>
              <td className="padding-5" id="langauge_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={postal}
                    onChange={(e) => {
                      setPostal(e.target.value);
                    }}
                  />
                }
              </td>
            </tr>

            <tr>
              <td className="padding-5 ">Phone</td>
              <td className="padding-5" id="langauge_view">
                {
                  <div className="display-flex">
                    {countryDailCode && (
                      <input
                        type="text"
                        value={countryDailCode}
                        disabled
                        className="form-control"
                        style={{ width: "50px" }}
                      />
                    )}
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                }
              </td>
            </tr>

            <tr>
              <td className="padding-5 ">Whatsapp</td>
              <td className="padding-5" id="langauge_view">
                {
                  <div className="display-flex">
                    {countryDailCode && (
                      <input
                        type="text"
                        value={countryDailCode}
                        disabled
                        className="form-control"
                        style={{ width: "50px" }}
                      />
                    )}
                    <input
                      type="text"
                      className="form-control"
                      value={whatsapp}
                      onChange={(e) => {
                        setWhatsapp(e.target.value);
                      }}
                    />
                  </div>
                }
              </td>
            </tr>
            <tr>
              <td className="padding-5 ">Email</td>
              <td className="padding-5" id="langauge_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                }
              </td>
            </tr>
            <tr>
              <td className="padding-5 ">Fax</td>
              <td className="padding-5" id="langauge_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={fax}
                    onChange={(e) => {
                      setFax(e.target.value);
                    }}
                  />
                }
              </td>
            </tr>
            {/* <tr>
              <td className="padding-5 ">latitude</td>
              <td className="padding-5" id="langauge_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={latitude}
                    onChange={(e) => {
                      setLatitude(e.target.value);
                    }}
                  />
                }
              </td>
            </tr>
            <tr>
              <td className="padding-5 ">longitude</td>
              <td className="padding-5" id="langauge_view">
                {
                  <input
                    type="text"
                    className="form-control"
                    value={longitude}
                    onChange={(e) => {
                      setLongitude(e.target.value);
                    }}
                  />
                }
              </td>
            </tr> */}

            <tr>
              <td className="padding-5 ">Office Hours</td>
              <td
                className="padding-5 display-flex"
                id="langauge_view"
                style={{ gap: "2px" }}
              >
                <span>
                  {
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Sart time"
                      value={officeHours.start}
                      onChange={(e) => {
                        setOfficeHours({
                          ...officeHours,
                          start: e.target.value,
                        });
                      }}
                    />
                  }
                </span>
                <span>
                  {
                    <input
                      type="text"
                      className="form-control"
                      placeholder="End time"
                      value={officeHours.end}
                      onChange={(e) => {
                        setOfficeHours({ ...officeHours, end: e.target.value });
                      }}
                    />
                  }
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AddLocations;
