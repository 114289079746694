import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import profileDefualt from "../../images/default.jpeg";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
const UserCard = (props) => {
  const [isfollowing, setFollowing] = useState(props.profile.following);

  const userUnSubscribeHandler = async (follower_user_id, e) => {
    if (props.user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/unSubscribe`;
      const data = {
        user_id: props.user.userData.loginUser._id,
        following_user_id: follower_user_id,
      };
      try {
        let res = await getDataPostWithHeader(
          url,
          data,
          props.user.userData.token,
        );

        setFollowing(false);
        e.target.innerHTML = "Follow";

        toast.success(res.data.body.unsubscribeText);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    } else {
      alert("Login to un-follow any user");
    }
  };
  const userSubscribeHandler = async (follower_user_id, e) => {
    if (props.user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
      const data = {
        user_id: props.user.userData.loginUser._id,
        follower_user_id: follower_user_id,
      };
      try {
        let res = await getDataPostWithHeader(
          url,
          data,
          props.user.userData.token,
        );
        setFollowing(true);
        console.log(res);
        e.target.innerHTML = "Following";
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    } else {
      alert("Login to un-follow any user");
    }
  };
  return (
    <div className="p-3 serach-result" style={{ cursor: "pointer" }}>
      <div>
        <div className="row">
          <div className="col-md-5">
            <Link
              to={`/consultant-profile?user_id=${props.profile.user_id}&profile_id=${props.profile._id}`}
              style={{ color: "black" }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "20px",
                  }}
                  src={props.profile.users.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </div>
            </Link>
          </div>
          <div
            className="col-md-4 text-center-mobile"
            style={{ padding: "25px" }}
          >
            <Link
              to={`/consultant-profile?user_id=${props.profile.user_id}&profile_id=${props.profile._id}`}
              style={{ color: "black" }}
            >
              <div style={{ fontWeight: "700", fontSize: "20px" }}>
                {props.profile.profile_name}
              </div>

              <div
                style={{ color: "#606060", marginTop: "6px", fontSize: "12px" }}
              >
                {props.profile.categories &&
                  props.profile.categories.category_name}
              </div>

              <div
                style={{ color: "#606060", marginTop: "6px", fontSize: "12px" }}
              >
                {parse(props.profile.brief_cv.substring(0, 20))}
                {/* {props.profile.briefCV[0].brief_cv.substring(0, 20)} */}
              </div>
            </Link>
          </div>

          <div
            style={{
              padding: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="col-md-3 text-center-mobile"
          >
            {isfollowing && (
              <button
                className="btn btn-danger"
                style={{ width: "60%" }}
                onClick={(e) => {
                  userUnSubscribeHandler(props.profile._id, e);
                }}
              >
                Following
              </button>
            )}

            {isfollowing || (
              <button
                className="btn btn-danger"
                style={{ width: "60%" }}
                onClick={(e) => {
                  userSubscribeHandler(props.profile._id, e);
                }}
              >
                Follow
              </button>
            )}
          </div>
        </div>
      </div>

      <div style={{ clear: "both" }}></div>
    </div>
  );
};

export default UserCard;
