let initialState = {
  group_info: {},
}

const UserSubscriptionGroup = (state = initialState, action) => {
  switch (action.type) {
    case 'group_details':
      return { ...state, group_info: action.payload }
    default:
      return state
  }
}

export default UserSubscriptionGroup
