import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios, { CancelToken, isCancel } from "axios";
import { useHistory } from "react-router-dom";
import SideNavBar from "../Components/newCourse/SideNavBar";
import Langauge from "../Components/newCourse/Langauge";
import CategoryCourse from "../Components/newCourse/CategoryCourse";
import CourseTitile from "../Components/newCourse/CourseTitile";
import Section from "../Components/newCourse/Section";

import { setWithExpiry, calculateVideoRatio } from "../utils/sharedfunction";
import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
} from "../functions/editProfile/editProfile";

const url = process.env.REACT_APP_BACKEND_URL;
const AddNewCourse = ({ user }) => {
  const history = useHistory();
  const [language, setLanguage] = useState("0");
  const [profileId, setProfileId] = useState("0");
  const [category, setCategory] = useState("0");
  const [currency, setCurrency] = useState("KWD");
  const [langaugeId, setLangaugeId] = useState("0");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [next, setNext] = useState(1);
  const [groupDeatils, setGroupDetails] = useState("");
  const [menu, setMenu] = useState("Course Type");
  const [price, setPrice] = useState(0);
  const [sectionType, setSectionType] = useState(1);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [imageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [imageProcessingUpload, setImageProcessingUpload] = useState(0);
  const [videoProcessingUpload, setVideoProcessingUpload] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsPreRequired, setInputFieldsPreRequired] = useState([]);
  const [videoFile, setVideoFile] = useState({
    video: "",
    imageBlob: "",
    videoImg: "",
    videoUrl: "",
  });
  const [videoUrl, setVideoUrl] = useState("");
  const [hlsUrl, setHlsUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const videoTempRef = useRef(null);
  const cancelFileUpload = useRef(null);

  const getPresignedUrl = async (apiName, data, pickedFile) => {
    try {
      if (apiName !== "getPresignedURLForCoverUpload") {
        setVideoUploadPercentage(1);
      }
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;

      const res = await getDataPostWithHeader(url, data, user.userData.token);
      if (res.data.status === 200) {
        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl) => {
    if (apiName === "getPresignedURLForCoverUpload") {
      setImageUrl(fileUrl);
    } else {
      setVideoUrl(fileUrl);
      setVideoUploadPercentage(1);
    }

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          console.log(percent);
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
              setImageUploadPercentage(percent);
            } else {
              setVideoProcessingUpload(true);

              setVideoUploadPercentage(percent);
            }
          } else {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else {
              setVideoUploadPercentage(percent + 1);
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setImageUploadPercentage(0);
            setImageProcessingUpload(false);
          } else {
            setVideoUploadPercentage(0);
            setVideoProcessingUpload(false);
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else {
          console.log(err.message);

          if (err.message === "update error or suspended") {
            alert(err.message);
          } else {
            if (err.message !== "User has canceled the file upload") {
              alert(
                "While uploading something went wrong ! try again uploading",
              );
            }
          }
          setVideoUploadPercentage(0);
          setVideoProcessingUpload(false);
        }
      });
  };

  const saveSubscriptionGroupHandler = async (saveType) => {
    if (
      videoProcessingUpload === false &&
      videoUrl !== "" &&
      videoUploadPercentage === 0
    ) {
      if (!title) {
        toast.error("Please enter title");
        return;
      }

      if (langaugeId == "00") {
        toast.error("Please select the language");
        return;
      }

      let categoryAdded = category;
      if (category == "0") {
        if (newCategory !== "") {
          try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/addCategories`;

            const data = {
              category_name: newCategory,
            };

            const res = await getDataPostWithOutHeader(url, data);
            if (res.data.status === 200) {
              setCategory(res.data.body._id);
              categoryAdded = res.data.body._id;
            }
          } catch (err) {
            console.log(err);
            toast.error(err.message);
          }
        } else {
          alert("please enter category");
          return;
        }
      }

      if (saveType === "create") {
        const dataAdd = {
          user_id: user.userData.loginUser._id,
          group_name: title,
          language_id: langaugeId,
          profile_id: profileId,
          category_id: categoryAdded,
          section_type: sectionType,
          subscription_price: price,
          currency_id: currency,
          videoUrl: videoUrl,
          imageUrl: imageUrl,
          group_description: description,
          learn_text: inputFields,
          requirements: inputFieldsPreRequired,
          hlsUrl: "",
        };

        try {
          const res = await getDataPostWithHeader(
            `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/addSubscription`,
            dataAdd,
            user.userData.token,
          );

          setGroupDetails(res.data.body);
          //setWithExpiry("introImageVideo", videoFile.videoImg, 100000);
          console.log("bloburl", videoFile.videoUrl);
          let blobURL = videoFile.videoUrl;
          const { ratio } = await calculateVideoRatio(
            videoTempRef.current,
            blobURL,
          );
          console.log("ratio", ratio);

          const urlConvert = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createJobMediaCoveret`;
          const data = {
            url: videoUrl,
            user_id: user.userData.loginUser._id,
            whereToUpdate: "courseIntro",
            id: res.data.body._id,
            videoRatio: ratio,
          };

          const res1 = getDataPostWithHeader(
            urlConvert,
            data,
            user.userData.token,
          );

          history.push({
            pathname: "/profile",
            state: {
              from: "addnewcourse",
              user_id: res.data.body.user_id,
              _id: res.data.body._id,
            },
          });
        } catch (err) {
          toast.error("Some Thing Went Wrong");
          console.log(err);
          // videourlref.current.style.display = 'none'
          // uploadImgref.current.style.display = 'block'
        }
      } else {
        if (!description) {
          toast.error("Please enter description");
          return;
        }
        if (currency == "0") {
          toast.error("Please select currency");
          return;
        }

        if (!price) {
          toast.error("Please enter price");
          return;
        }

        if (sectionType == 1) {
          if (
            inputFields[0].your_response === "" &&
            inputFields[0].your_response === ""
          ) {
            toast.error("You must enter 2 learning objectives");
            return;
          }

          if (
            inputFieldsPreRequired[0].your_response === "" &&
            inputFieldsPreRequired[0].your_response === ""
          ) {
            toast.error("You must enter at least 1 pre requisites");
            return;
          }
        }

        try {
          const response = await fetch(
            `${url}/api/userProfile/updateSubscription`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.userData.token}`,
              },
              body: JSON.stringify({
                group_name: title,
                language_id: langaugeId,
                profile_id: profileId,
                category_id: categoryAdded,
                subscription_price: price,
                currency_id: currency,
                group_description: description,
                subscription_id: groupDeatils._id,
                section_type: sectionType,
                learn_text: inputFields,
                requirements: inputFieldsPreRequired,
              }),
            },
          );
          const responseData = await response.json();

          if (!response.ok) {
            if (response.status === 401) {
              localStorage.removeItem("userDataSessionway");
              window.location.replace("/");
            }
            throw new Error(responseData.message || "something went wrong");
          } else {
            toast.success("Successfully updated");
            history.push({
              pathname: "/profile",
              state: {
                from: "addnewcourse",
                user_id: responseData.body.user_id,
                id: responseData.body._id,
              },
            });
          }
        } catch (err) {
          console.log(err);
          toast.error("Some Thing Went Wrong");
        }
      }
    } else {
      if (videoUrl === "") {
        alert("please upload intro video");
      } else {
        alert("video is uploading please wait");
      }
    }
  };

  return (
    <>
      {groupDeatils == "" && (
        <div
          style={{
            width: "100%",
            maxWidth: "1028px",
            margin: "auto",
            padding: "3.2rem 2.4rem",
          }}
        >
          <Section
            menu={menu}
            setMenu={setMenu}
            title={title}
            setTitle={setTitle}
            user={user}
            language={language}
            setProfileId={setProfileId}
            langaugeId={langaugeId}
            setLanguage={setLanguage}
            setLangaugeId={setLangaugeId}
            category={category}
            setCategory={setCategory}
            currency={currency}
            setCurrency={setCurrency}
            price={price}
            setPrice={setPrice}
            description={description}
            setDescription={setDescription}
            saveSubscriptionGroupHandler={saveSubscriptionGroupHandler}
            groupDeatils={groupDeatils}
            setSectionType={setSectionType}
            sectionType={sectionType}
            inputFields={inputFields}
            setInputFields={setInputFields}
            inputFieldsPreRequired={inputFieldsPreRequired}
            setInputFieldsPreRequired={setInputFieldsPreRequired}
            CourseTitile={CourseTitile}
            setNext={setNext}
            setVideoFile={setVideoFile}
            videoFile={videoFile}
            getPresignedUrl={getPresignedUrl}
            videoUploadPercentage={videoUploadPercentage}
            videoProcessingUpload={videoProcessingUpload}
            videoUrl={videoUrl}
            setNewCategory={setNewCategory}
            newCategory={newCategory}
          />
          <div style={{ clear: "both" }}></div>
        </div>
      )}
      <video
        controls
        src=""
        ref={videoTempRef}
        style={{
          display: "none",
        }}
      >
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default AddNewCourse;
