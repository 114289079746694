const initialState = {
  search: {
    usersAndStories: [],
    stories: [],
    totalOverAllUsers: 0,
    clear: false,
  },
};

const searchUserReducers = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_USERS":
      return { ...state, search: action.payload };

    case "SEARCH_STORIES_APPEND":
      let newgetStoryModel = [
        ...state.search.usersAndStories,
        ...action.payload.usersAndStories,
      ];
      let newState = { ...state };
      newState.search.usersAndStories = newgetStoryModel;

      return newState;

    case "SEARCH_USERS_APPEND":
      let newgetChatUserModel = [
        ...state.search.users,
        ...action.payload.users,
      ];
      let newStateChat = { ...state };
      newStateChat.search.users = newgetChatUserModel;
      return newStateChat;
    case "EMPTY_SEARCH_USER":
      return {
        ...state,
        search: { usersAndStories: [], stories: [] },
      };
    default:
      return state;
  }
};

export default searchUserReducers;
