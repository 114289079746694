import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { BsThreeDotsVertical } from "react-icons/bs";
import profileDefualt from "../../images/default.jpeg";
import CourseCardMyProfile from "../cards/CourseCardMyProfile";
import { useHistory, Link } from "react-router-dom";
import {
  responseMessage,
  responseMessageClear,
} from "../../utils/sharedfunction";
import addStory from "../../images/add_blue.png";

import { getRequestedDataWithHeader } from "../../functions/editProfile/editProfile";
const url = process.env.REACT_APP_BACKEND_URL;
const UserCourses = ({ user, setProfile, authorize, menuType }) => {
  const [isloading, setIsLoading] = useState(false);
  const [coursePage, setCoursePage] = useState(1);
  const [courses, setCourses] = useState(1);
  const [totalCoursePage, setTotalCoursePage] = useState();
  const intialLoadErrorMessageRef = useRef(null);
  const recursionLoadErrorMessageRef = useRef(null);
  const stroyBoxRef = useRef(null);
  const dispatch = useDispatch({});
  const history = useHistory();

  useEffect(() => {
    geUserCourses(1);
  }, []);

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.from === "addnewcourse") {
        console.log(history.location.state.from);
        showSubscription(history.location.state, authorize.token);
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    if (coursePage == 1) {
      setCoursePage(coursePage + 1);
    }
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [coursePage, totalCoursePage]);

  const handleScrolListner = () => {
    const totalPage = totalCoursePage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    if (Math.ceil(scrolled) >= scrollable) {
      console.log(coursePage);
      if (coursePage <= totalPage) {
        geUserCourses(coursePage);
      }
    }
  };

  const geUserCourses = async (page_no) => {
    try {
      let coursetype = menuType === "COURSES" ? 1 : 0;
      setIsLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userCourses?user_id=${user.loginUser._id}&coursetype=${coursetype}&page=${page_no}`;
      setCoursePage(page_no + 1);
      let res = await getRequestedDataWithHeader(url, user.token);

      if (page_no > 1) {
        setCourses((oldArray) => [...res.data.body.courses, ...oldArray]);
      } else {
        setCourses(res.data.body.courses);
      }
      setTotalCoursePage(res.data.body.Total_Page);
      setIsLoading(false);
    } catch (errr) {
      setIsLoading(false);
      console.log(errr.message);
      responseMessage(intialLoadErrorMessageRef, "Something Went Wrong", "red");
    }
  };

  const showSubscription = async (list, token, id) => {
    if (document.getElementById("view-sub"))
      document.getElementById("view-sub").style.display = "block";
    setProfile(false);
    try {
      const response = await fetch(
        `${url}/api/userProfile/getSubscriptionGroup?subscription_id=${list._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const responseData = await response.json();

      if (!response.ok) {
        if (responseData.status === 401) {
          localStorage.removeItem("userDataSessionway");
          window.location.replace("/");
        } else {
          throw new Error(responseData.message || "something went wrong");
        }
      } else {
        dispatch({
          type: "group_details",
          payload: { ...responseData.body[0], token },
        });
      }
    } catch (err) {
      console.log(err);
      alert("something went wrong");
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-center margin-top-5"
        ref={intialLoadErrorMessageRef}
      ></div>
      <div>
        <div
          className="display-flex"
          style={{ alignItems: "center", cursor: "pointer" }}
        >
          <Link to={`/new/course/mange?type=${menuType}`}>
            <img src={addStory} alt="add" style={{ height: 40 + "px" }} />
          </Link>
          <span>Add</span>
        </div>
      </div>

      <div className="container-fluid story-card-box-section course-card-box-section">
        <div className="row">
          {/* {(menuType === "COURSES" || menuType === "PGROUPS") && (
            <div className="col-6 col-md-4 col-xl-3 col-xxl-2 story1-card-box mb-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  cursor: "pointer",
                }}
              >
                <Link to={`/new/course/mange?type=${menuType}`}>
                  <img src={addStory} alt="add" style={{ height: 50 + "px" }} />
                </Link>
                <span>Add</span>
              </div>
            </div>
          )} */}
          {courses.length > 0 &&
            courses.map((course, index) => {
              if (courses.length - 1 != index) {
                return (
                  <div
                    className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-3"
                    key={course._id}
                  >
                    <CourseCardMyProfile
                      course={course}
                      token={authorize.token}
                      showSubscription={showSubscription}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-3"
                    key={course._id}
                  >
                    <CourseCardMyProfile
                      course={course}
                      token={authorize.token}
                      showSubscription={showSubscription}
                    />
                  </div>
                );
              }
            })}
        </div>
        {isloading && (
          <div className="d-flex justify-content-center margin-top-5">
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserCourses;
