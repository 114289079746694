import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Courses from "./Courses";
const CourseCategory = ({ categoryWithCourse, index, user }) => {
  const [courses, setCourses] = useState(categoryWithCourse.publishedCourses);
  const [courseCount, setcourseCount] = useState(5);
  const [totalCourseCount, setTotalCourseCount] = useState(
    categoryWithCourse.publishedCourses.length,
  );
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {
    var isMobile =
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1;

    if (isMobile) {
      setcourseCount(1);
    } else {
      setcourseCount(5);
    }
  }, []);

  useEffect(() => {
    var isMobile =
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (!isMobile) {
      if (courseCount >= 5) {
        setNext(true);
        setPrev(true);
      }

      if (courseCount === 5) {
        setPrev(false);
      }

      if (totalCourseCount <= courseCount) {
        setNext(false);
        setPrev(true);

        if (totalCourseCount <= 5) {
          setPrev(false);
        }
      }
    } else {
      if (courseCount >= 1) {
        setNext(true);
        setPrev(true);
      }

      if (courseCount === 1) {
        setPrev(false);
      }

      if (totalCourseCount <= courseCount) {
        setNext(false);
        setPrev(true);
        if (totalCourseCount <= 1) {
          setPrev(false);
        }
      }
    }
  }, [courseCount]);

  let isDragStart = false,
    isDragging = false,
    prevPageX,
    prevScrollLeft,
    positionDiff;

  const dragStart = (e, index) => {
    // updatating global variables value on mouse down event
    isDragStart = true;
    prevPageX = e.pageX || e.touches[0].pageX;
    const carousel = document.querySelectorAll(".carousel")[index];
    prevScrollLeft = carousel.scrollLeft;
  };

  const dragging = (e, index) => {
    // scrolling images/carousel to left according to mouse pointer
    if (!isDragStart) return;
    e.preventDefault();
    isDragging = true;
    const carousel = document.querySelectorAll(".carousel")[index];
    carousel.classList.add("dragging");
    positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
    carousel.scrollLeft = prevScrollLeft - positionDiff;
    //showHideIcons();
  };

  const dragStop = (e, index) => {
    isDragStart = false;
    const carousel = document.querySelectorAll(".carousel")[index];
    carousel.classList.remove("dragging");
    if (!isDragging) return;
    isDragging = false;
    //autoSlide();
  };

  const TogleCourses = (id, index) => {
    const isShow = document
      .getElementById(`courses_${id}`)
      .classList.contains("display-block");
    if (isShow) {
      document.getElementById(`courses_${id}`).classList.add("display-none");
      document
        .getElementById(`courses_${id}`)
        .classList.remove("display-block");

      if (index <= 1) {
        document.getElementById(`coursesDown_${id}`).style.display = "flex";
        document.getElementById(`coursesUp_${id}`).style.display = "none";
      } else {
        document.getElementById(`coursesDown_${id}`).style.display = "flex";
        document.getElementById(`coursesUp_${id}`).style.display = "none";
      }
      if (index === 0) {
        document
          .getElementById(`carousel_${id}`)
          .classList.add("broder-bottom");
      } else {
        document
          .getElementById(`carousel_${id}`)
          .classList.add("broder-bottom");
      }
      // document.getElementById(`carousel_${id}`).style.padding =
      //   "0.5rem 1.5rem 0.5rem";
    } else {
      document.getElementById(`courses_${id}`).classList.add("display-block");
      document.getElementById(`courses_${id}`).classList.remove("display-none");
      if (index <= 1) {
        document.getElementById(`coursesDown_${id}`).style.display = "none";
        document.getElementById(`coursesUp_${id}`).style.display = "flex";
      } else {
        document.getElementById(`coursesDown_${id}`).style.display = "none";
        document.getElementById(`coursesUp_${id}`).style.display = "flex";
      }

      document
        .getElementById(`carousel_${id}`)
        .classList.remove("broder-bottom");
    }
    // document.getElementById(`carousel_${id}`).style.padding =
    //   "padding: 1rem 1.5rem 1rem 1rem";
  };

  const moveSlide = (e, id, index) => {
    const carousel = document.querySelectorAll(".carousel")[index];
    let firstImg = carousel.querySelectorAll(".box")[0];
    let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
    // if clicked icon is left, reduce width value from the carousel scroll left else add to it
    carousel.scrollLeft +=
      e.target.id == `left_${id}` ? -firstImgWidth : firstImgWidth;
    //showHideIcons(e, id, index); //calling showHideIcons after 60ms
  };

  //   const showHideIcons = (e, id, index) => {
  //     const carousel = document.querySelectorAll(".carousel")[index];
  //     // showing and hiding prev/next icon according to carousel scroll left value
  //     let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width

  //     document.getElementById(`left_${id}`).style.display =
  //       carousel.scrollLeft == 0 ? "none" : "block";

  //     document.getElementById(`right_${id}`).style.display =
  //       carousel.scrollLeft == scrollWidth ? "none" : "block";
  //   };

  return (
    <>
      <div className="flex-space-btw course-title-wrapper ">
        <h3 className="carousel-title" style={{ margin: "0px" }}>
          {categoryWithCourse.category_name.toUpperCase()}
        </h3>
        <div
          onClick={() => TogleCourses(categoryWithCourse._id, index)}
          className="display-flex-align-center"
        >
          <AiOutlineDown
            id={`coursesDown_${categoryWithCourse._id}`}
            className={`${index <= 1 && "display-none"}`}
          />
          <AiOutlineUp
            id={`coursesUp_${categoryWithCourse._id}`}
            className={`${index > 1 && "display-none"}`}
          />
        </div>
      </div>

      <div
        className={`${index > 1 && "broder-bottom"} carousel-section`}
        key={categoryWithCourse._id}
        id={`carousel_${categoryWithCourse._id}`}
      >
        {prev && (
          <div
            id={`left_${categoryWithCourse._id}`}
            className="carousel-nav carousel-prev"
            role="button"
            disabled={true}
            onClick={(e) => {
              setcourseCount(courseCount - 1);
              moveSlide(e, categoryWithCourse._id, index);
            }}
          ></div>
        )}
        <div
          className="carousel"
          onMouseDown={(e) => dragStart(e, index)}
          onMouseMove={(e) => dragging(e, index)}
          onMouseUp={(e) => dragStop(e, index)}
          onTouchStart={(e) => dragStart(e, index)}
          onTouchMove={(e) => dragging(e, index)}
          onTouchEnd={(e) => dragStop(e, index)}
        >
          <div
            id={`courses_${categoryWithCourse._id}`}
            className={`${index > 1 ? "display-none" : "display-block "}`}
          >
            <Courses coursesP={courses} user={user} />
          </div>
        </div>
        {next && (
          <div
            id={`right_${categoryWithCourse._id}`}
            className="carousel-nav carousel-next"
            role="button"
            disabled={true}
            onClick={(e) => {
              setcourseCount(courseCount + 1);
              moveSlide(e, categoryWithCourse._id, index);
            }}
          ></div>
        )}
      </div>
    </>
  );
};

export default CourseCategory;
