import React, { useEffect, useState, useRef } from "react";
import { getRequestedDataWithHeader } from "../../functions/editProfile/editProfile";
import { responseMessageDispaly } from "../../utils/sharedfunction";
import defualtImg from "../../images/default.jpeg";

const UserFollowers = ({ user, authorize, setProfile, menuType }) => {
  const errorMessageRef = useRef(null);
  const [page, setPage] = useState(1);
  const [follwers, setFollowers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    getFollowers(1);
    getSubCountires();
  }, []);

  const getFollowers = async (page_no, byCountry) => {
    try {
      setIsLoading(true);
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getUserSubscribers?page=${page_no}${
        byCountry ? `&country_id=${byCountry}` : ""
      }`;
      let res = await getRequestedDataWithHeader(url, user.token);
      if (page_no > 1) {
        setFollowers((oldArray) => [...res.data.body.followers, ...oldArray]);
      } else {
        setFollowers(res.data.body.followers);
      }

      setIsNext(res.data.body.isNext);
      setIsPrev(res.data.body.isPrev);
      setIsLoading(false);
    } catch (errr) {
      setIsLoading(false);
      console.log(errr.message);
      responseMessageDispaly(errorMessageRef, "Something Went Wrong", "red");
    }
  };

  const getSubCountires = async () => {
    try {
      setCountryLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getSubCountries`;
      let res = await getRequestedDataWithHeader(url, user.token);

      setCountries(res.data.body);
    } catch (errr) {
      setCountryLoading(false);
      console.log(errr.message);
      responseMessageDispaly(errorMessageRef, "Something Went Wrong", "red");
    }
  };

  const handleDropDown = (country) => {
    if (country !== "00") {
      setPage(1);
      getFollowers(1, country);
    } else {
      getFollowers(1);
    }
  };
  return (
    <div>
      <div className="mt-3 mb-3">
        <span>Filter by Country: </span>
        <select onChange={(e) => handleDropDown(e.target.value)}>
          <option value={"00"} id={"00"}>
            Select
          </option>
          {countries.length > 0 &&
            countries.map((list, i) => {
              return (
                <option
                  value={list._id}
                  id={list.countryInfo._id}
                  key={list._id}
                >
                  {list.countryInfo.country_english}
                </option>
              );
            })}
        </select>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Country</th>
            <th scope="col">Subscribed Date</th>
          </tr>
        </thead>
        <tbody>
          {follwers.length > 0 &&
            follwers.map((user, index) => {
              console.log(user.follower_user_id);

              return (
                <tr key={user._id}>
                  <th scope="row">{index + 1}</th>

                  <th className="user-follow-wrapper mt-1 pb-1">
                    <img
                      src={user.follower_user_id.imageUrl}
                      alt={user.follower_user_id.login_user_id}
                      className="user-follower-img"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                    <div className="user-sub-name ">
                      {user.follower_user_id.login_user_id}
                    </div>
                  </th>

                  <th>{user.follower_user_id.country_id.country_english}</th>
                  <th>
                    {user.createdAt
                      ? new Date(user.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : "NOT Available"}
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default UserFollowers;
