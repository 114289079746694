import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";

import {
  getRequestedData,
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";

import StoryCardMin from "../Components/cards/StoryCardMin";
import ViewStoryMin from "../Components/maintenence/ViewStoryMin";
import { height } from "@mui/system";

const MaintenanceStory = ({ user }) => {
  const {
    AllStoriesMinReducers: { stories },
  } = useSelector((state) => state);
  const dispatch = useDispatch({});

  const [storyId, setStoryId] = useState("");
  useEffect(() => {
    console.log("hello");
    getStories();
  }, []);

  const getStories = async () => {
    try {
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/userProfile/getFreeStories?page=${1}`;

      let res = await getRequestedDataWithHeader(url, user.userData.token);
      dispatch({ type: "all_stories_min", payload: res.data.body });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2" style={{ borderRight: "2px solid #eeeeee" }}>
          {stories.getStoryModel.length > 0 &&
            stories.getStoryModel.map((story, i) => {
              return (
                <div
                  className="mt-4"
                  key={story._id}
                  onClick={() => {
                    setStoryId(story._id);
                  }}
                >
                  <StoryCardMin
                    story={story}
                    isLogin={true}
                    setStoryId={setStoryId}
                  />
                </div>
              );
            })}
        </div>
        <div className="col-md-7" style={{ borderRight: "2px solid #eeeeee" }}>
          {storyId && <ViewStoryMin story_id={storyId} />}
        </div>
        <div className="col-md-3 ">
          {storyId && (
            <>
              <div className="mt-2">
                <Button variant="contained" style={{ background: "#11A3D6" }}>
                  Block
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: "10px", background: "#11A3D6" }}
                >
                  Approve
                </Button>
              </div>
              <textarea
                className="mt-4 text-area-desc"
                placeholder="Write Post"
                maxlength="5000"
                style={{ width: "100%", height: "170px" }}
              ></textarea>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceStory;
