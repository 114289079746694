import React, { useRef } from "react";
import { BsFillPersonFill, BsFillEnvelopeFill } from "react-icons/bs";
import { AiTwotoneLock } from "react-icons/ai";
import { connect } from "react-redux";

import {
  errorToggleforTextInput,
  isValidEmialAddress,
} from "../../utils/sharedfunction";
import SuccessSection from "./SuccessSection";

const FourthSection = ({
  sectionHolder: {
    sheftSections,
    CurrentSection,
    PreviousSection,
    BackwordSection,
    errorSection,
    progressBar,
    from,
    setFunction,
    NextSection,
    ForewordSection,
  },
  userData: { is_consultant, user_name, gender, lang },
  userLangauges,
  mobile_no,
  county,
  dispatch,
  userCredentials: {
    isEmailAndUserIdValid,
    login_user_id,
    email,
    user_password,
  },
  userCategories,
  briefCV,
}) => {
  const successRef = useRef(null);
  const url = process.env.REACT_APP_BACKEND_URL;
  const isEnterYourMailRef = useRef(null);
  const isEnterLoginUserIDRef = useRef(null);
  const isEnterPasswordRef = useRef(null);
  const isConfirmPasswordRef = useRef(null);
  const enterYourMailRef = useRef(null);
  const enterLoginUserIDRef = useRef(null);
  const enterPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const isPasswordMatch = useRef(null);
  const isValidEmailAddress = useRef(null);
  const isValidUserName = useRef(null);

  const validateUserHandler = () => {
    progressBar.current.style.display = "block";
    if (
      errorToggleforTextInput(
        enterYourMailRef,
        isEnterYourMailRef,
        "Enter your email address",
      ) &&
      isValidEmialAddress(
        enterYourMailRef.current.value,
        isValidEmailAddress.current,
      ) &&
      errorToggleforTextInput(
        enterLoginUserIDRef,
        isEnterLoginUserIDRef,
        "Enter login user id",
      ) &&
      errorToggleforTextInput(
        enterPasswordRef,
        isEnterPasswordRef,
        "Enter password",
      ) &&
      errorToggleforTextInput(
        confirmPasswordRef,
        isConfirmPasswordRef,
        "Retype password",
      ) &&
      isEmailAndUserIdValid
    ) {
      if (confirmPasswordRef.current.value === enterPasswordRef.current.value) {
        console.log("inside d");
        const userCredentials = {
          login_user_id: enterLoginUserIDRef.current.value,
          email: enterYourMailRef.current.value,
          user_password: enterPasswordRef.current.value,
          isEmailAndUserIdValid,
        };
        dispatch({ type: "userCredentials", payload: userCredentials });
        submitRegistration();
      } else {
        isPasswordMatch.current.style.display = "block";
      }
    }
    progressBar.current.style.display = "none";
  };

  const submitRegistration = async () => {
    if (!lang) {
      alert("langauge is required");
      return;
    }

    const langs = [lang];

    console.log(langs, "langs");

    progressBar.current.style.display = "block";

    let userCategory = userCategories.map((list) => list.id);
    try {
      const response = await fetch(`${url}/api/users/userRegister`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: enterYourMailRef.current.value,
          login_user_id: enterLoginUserIDRef.current.value,
          mobile_no: mobile_no,
          user_password: enterPasswordRef.current.value,
          user_name: user_name,
          country_id: county.id,
          gender: gender,
          userLangauges: langs,
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "something went wrong");
      } else {
        CurrentSection.current.style.display = "none";
        progressBar.current.style.display = "none";
        loginSubmit();

        if (from === "chatregister") {
          setFunction(false);
        }
      }
    } catch (err) {
      console.log(err);
      CurrentSection.current.style.display = "none";
      errorSection.current.style.display = "block";
    }
  };

  const loginSubmit = async () => {
    const url = process.env.REACT_APP_BACKEND_URL;
    successRef.current.style.display = "block";
    try {
      const response = await fetch(`${url}/api/users/userLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login_user_id: enterLoginUserIDRef.current.value,
          password: enterPasswordRef.current.value,
          isKeepLogin: false,
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "something went wrong");
      } else {
        if (typeof Storage !== "undefined") {
          localStorage.setItem(
            "userDataSessionWay",
            JSON.stringify(responseData.body),
          );
          window.location.replace("/profile");
        } else {
          alert("please update your browser");
        }
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const validateCredentials = async () => {
    if (enterLoginUserIDRef.current.value && enterYourMailRef.current.value) {
      const url = process.env.REACT_APP_BACKEND_URL;

      try {
        const response = await fetch(`${url}/api/users/userVerify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: enterYourMailRef.current.value,
            login_user_id: enterLoginUserIDRef.current.value,
          }),
        });

        const responseData = await response.json();
        if (!response.ok) {
          const isValid = responseData.message.split(":")[1].trim();
          if (isValid.localeCompare("Email already existed!!") === 0) {
            isEnterYourMailRef.current.innerHTML =
              responseData.message.split(":")[1];
            isEnterYourMailRef.current.style.display = "block";
          } else if (
            isValid.localeCompare("Login user name is already existed!!") === 0
          ) {
            isValidUserName.current.style.display = "block";
          } else {
            progressBar.current.style.display = "block";
            throw new Error(responseData.message || "something went wrong");
          }
        } else {
          isEmailAndUserIdValid = true;
        }
      } catch (err) {
        console.log(err);
        CurrentSection.current.style.display = "none";
        errorSection.current.style.display = "block";
        progressBar.current.style.display = "none";
      }
    }
  };

  return (
    <>
      <div
        className="registration-model-container"
        style={{ display: "none" }}
        ref={CurrentSection}
      >
        <div className="registration-modal-header">
          <h5 className="registration-modal-title" style={{ fontSize: "19px" }}>
            Please enter your login information
          </h5>
        </div>
        <div className="margin-battom-15 border-bottom-white ">
          <span className="icon-size model-font-color">
            <BsFillEnvelopeFill />
          </span>
          <input
            name="textinput"
            placeholder="Email"
            className="registration-form-control input-ht registration-input-90"
            type="text"
            ref={enterYourMailRef}
            onChange={() => {
              isEnterYourMailRef.current.style.display = "none";
              isValidEmailAddress.current.style.display = "none";
            }}
            onBlur={validateCredentials}
          />
        </div>
        <div className="display-none danger-red" ref={isEnterYourMailRef}>
          Enter your Email
        </div>
        <div className="display-none danger-red" ref={isValidEmailAddress}>
          Enter Valid Email Address
        </div>
        <div className="margin-battom-15 border-bottom-white ">
          <span className="icon-size model-font-color">
            <BsFillPersonFill />
          </span>
          <input
            name="textinput"
            placeholder="Login User Name(nick name)"
            className="registration-form-control input-ht registration-input-90"
            type="text"
            ref={enterLoginUserIDRef}
            onChange={() => {
              isEnterLoginUserIDRef.current.style.display = "none";
              isValidUserName.current.style.display = "none";
            }}
            onBlur={validateCredentials}
          />
        </div>
        <div className="display-none danger-red" ref={isEnterLoginUserIDRef}>
          Enter your unique user Name
        </div>
        <div className="display-none danger-red" ref={isValidUserName}>
          User name Already Existed ! Try another
        </div>
        <div className="margin-battom-15 border-bottom-white ">
          <span className="icon-size model-font-color">
            <AiTwotoneLock />
          </span>
          <input
            name="textinput"
            placeholder="Enter password"
            className="registration-form-control input-ht registration-input-90"
            autocomplete="off"
            type="password"
            ref={enterPasswordRef}
            onChange={() => {
              isEnterPasswordRef.current.style.display = "none";
              isPasswordMatch.current.style.display = "none";
            }}
          />
        </div>
        <div className="display-none danger-red" ref={isEnterPasswordRef}>
          Enter password
        </div>
        <div className="margin-battom-15 border-bottom-white ">
          <span className="icon-size model-font-color">
            <AiTwotoneLock />
          </span>
          <input
            name="textinput"
            placeholder="Confirm password"
            className="registration-form-control input-ht registration-input-90"
            type="password"
            ref={confirmPasswordRef}
            autocomplete="off"
            onChange={() => {
              isConfirmPasswordRef.current.style.display = "none";
              isPasswordMatch.current.style.display = "none";
            }}
          />
        </div>
        <div className="display-none danger-red" ref={isConfirmPasswordRef}>
          Confirm password
        </div>
        <div className="display-none danger-red" ref={isPasswordMatch}>
          Password not match
        </div>
        <button
          className="btn-previous push-left "
          onClick={() => {
            sheftSections(PreviousSection, CurrentSection);
          }}
        >
          Back
        </button>
        <button
          className="btn-proceed push-right "
          onClick={validateUserHandler}
        >
          Submit
        </button>
        <div className="clearfix"></div>
      </div>
      <div ref={successRef} style={{ display: "none" }}>
        <SuccessSection />
      </div>
    </>
  );
};
const MapStateToProps = ({
  RegFirstSection: { userLangauges, briefCV },
  RegSecondSection: { mobile_no, county },
  RegThirdSection: { userData },
  RegFourthSection: { userCredentials },
  RegFifthSection: { userCategories },
}) => {
  return {
    userLangauges,
    briefCV,
    mobile_no,
    county,
    userData,
    userCredentials,
    userCategories,
    userCategoriesLength: userCategories.length,
  };
};

export default connect(MapStateToProps)(FourthSection);
