const defaultState = {
  mobile_no: '',
  county: {},
}

const RegSecondSectionReducers = (state = defaultState, action) => {
  switch (action.type) {
    case 'county_info':
      return { ...state, county: action.payload }
    case 'mobile_no':
      return { ...state, mobile_no: action.payload }
    default:
      return state
  }
}

export default RegSecondSectionReducers
