import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoArrowBackSharp, IoSearch } from "react-icons/io5";
import SearchResult from "./SearchResult";
import { getRequestedData } from "../../functions/editProfile/editProfile";
import Carousel from "react-elastic-carousel";
import "../../pages/Carousel.css";
import "../../css/home.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 7 },
  { width: 1200, itemsToShow: 10 },
];

const HomeSearchStories = ({
  user,
  setSearchStories,
  userId,
  categories,
  setSelectedStory,
  setCategory,
  category,
}) => {
  const dispatch = useDispatch({});
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [searshBar, setSearshBar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const { searchUsers } = useSelector((state) => ({
    ...state,
  }));

  const searchUserHandler = async (text) => {
    let tagtype = "all_tags";
    console.log(text, "text");
    setLoadingSearchUser(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/searchUser?search_text=${text}&user_id=${userId}&page=1&tagtype=${tagtype}`;
    try {
      let res = await getRequestedData(url);
      dispatch({
        type: "SEARCH_USERS",
        payload: { text: text, clear: false, ...res.data.body },
      });
      setSearchSuggestions([]);
      setLoadingSearchUser(false);
    } catch (err) {
      setLoadingSearchUser(false);
      console.log(err.message);
      alert(err.message);
    }
  };

  const searchSuggestionsHandler = async (text) => {
    if (text) {
      setLoadingUser(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/searchSuggestions?search_text=${text}&page=1`;
      try {
        let res = await getRequestedData(url);
        setSearchSuggestions(res.data.body);
        setLoadingUser(false);
      } catch (err) {
        setLoadingUser(false);
        console.log(err.message);
        alert(err.message);
      }
    } else {
      setSearchSuggestions([]);
      dispatch({
        type: "EMPTY_SEARCH_USER",
      });
    }
  };

  const selectSearchHandler = (text) => {
    console.log(text, "safdsfdsfsd text");
    setSearchValue(text);
    setSearchSuggestions([]);
    searchUserHandler(text);
  };

  const selectCategory = (e, cat) => {
    if (cat === "ALL") {
      setCategory({
        categoryID: "",
        categoryName: "",
      });
    } else {
      setCategory({
        categoryID: cat.category_id._id,
        categoryName: cat.category_id.category_name,
      });
    }
    setSelectedStory("");
    setSearchStories(false);
    document.querySelectorAll(".list-category-carousel").forEach((div) => {
      div.style.backgroundColor = "rgb(242, 242, 242)";
      div.style.color = "black";
    });
    e.target.style.backgroundColor = "#515154";
    e.target.style.color = "white";
  };

  const highletTyle = {
    cursor: "pointer",
    backgroundColor: "#515154",
    color: "white",
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  return (
    <div>
      <div className="m-3">
        <div
          className="display-flex display-flex-align-center flex-space-btw"
          style={{ gap: "10px" }}
        >
          <IoArrowBackSharp
            size={25}
            onClick={() => {
              setSearchStories(false);
            }}
            className="cursor-pointer"
          />
          <div
            className="display-flex-align-center"
            style={{
              flexGrow: "1",
              borderRadius: "15px",
              border: "2px solid #c7c7c7",
              overflow: "hidden", // Add this line
            }}
          >
            <input
              type="text"
              style={{
                flexGrow: "1",
                padding: "4px",
                border: "none",
                outline: "none",
                borderRight: "2px solid #c7c7c7",
              }}
              placeholder="Search Stories"
              onChange={(e) => {
                searchSuggestionsHandler(e.target.value);
                setSearchValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchUserHandler(e.target.value);
                }
              }}
            />
            <IoSearch
              size={25}
              className="p-1"
              onClick={() => searchUserHandler(searchValue)}
            />
          </div>
        </div>
        <span id="width_check"></span>
        <div className="mt-2">
          {/* {props.loadingCat && (
                <LoadingCard count={10} avatar page={"home"} />
              )} */}
          {categories && categories.length > 0 && (
            <Carousel breakPoints={breakPoints}>
              <>
                {user.isLogin ? (
                  <span
                    style={
                      category && category.categoryID === ""
                        ? highletTyle
                        : { paddingLeft: "20px", paddingRight: "20px" }
                    }
                    className="list-category-carousel"
                    onClick={(e) => {
                      selectCategory(e, "ALL");
                    }}
                  >
                    ALL
                  </span>
                ) : (
                  <span
                    style={
                      category && category.categoryID === ""
                        ? highletTyle
                        : { paddingLeft: "20px", paddingRight: "20px" }
                    }
                    className="list-category-carousel"
                    onClick={(e) => {
                      selectCategory(e, "ALL");
                    }}
                  >
                    ALL
                  </span>
                )}
              </>
              {categories.map((cat, i) => {
                if (document.getElementById("width_check")) {
                  document.getElementById("width_check").innerHTML =
                    cat.category_id.category_name;
                  var width =
                    document.getElementById("width_check").offsetWidth + 50;

                  document.getElementById("width_check").innerHTML = "";
                }

                return (
                  <span
                    key={cat._id}
                    className="list-category-carousel"
                    value={cat._id}
                    style={
                      category && category.categoryID === cat.category_id._id
                        ? {
                            cursor: "pointer",
                            width: width + "px",
                            ...highletTyle,
                          }
                        : { cursor: "pointer", width: width + "px" }
                    }
                    onClick={(e) => {
                      selectCategory(e, cat);
                    }}
                  >
                    {cat.category_id.category_name}
                  </span>
                );
              })}
            </Carousel>
          )}
        </div>
      </div>
      {searchSuggestions.length > 0 && (
        <div className="display-flex flex-center">
          <div
            className="searchSuggestions"
            style={{ width: "90%", color: "black", margin: "auto", top: "6%" }}
          >
            {searchSuggestions.map((searchSuggestion) => {
              return (
                <div
                  className="searchSuggestionsLists cursor-pointer"
                  onClick={(e) => {
                    selectSearchHandler(searchSuggestion.tag_name);
                  }}
                  key={searchSuggestion._id}
                >
                  {searchSuggestion.tag_name}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {searchUsers.search.usersAndStories &&
        searchUsers.search.usersAndStories.length > 0 && (
          <div className="mt-2">
            <SearchResult
              loginUser={user}
              loadingSearchUser={loadingSearchUser}
              tagtype={"all_tags"}
              searchfromMobile={true}
              setSelectedStory={setSelectedStory}
              setSearchStories={setSearchStories}
            />
          </div>
        )}
    </div>
  );
};

export default HomeSearchStories;
