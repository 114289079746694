import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useQuery } from "../utils/sharedfunction";

import { TbBellFilled, TbBellRingingFilled } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import {
  getRequestedData,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";
import profileDefualt from "../images/default.jpeg";
import profilecoverDefualt from "../images/profilecoverdefault.png";
import { BsChatLeftText } from "react-icons/bs";
import { PiShareFat } from "react-icons/pi";
import BioArea from "../Components/consultlotProfile/BioArea";
import UserStories from "../Components/consultlotProfile/UserStories";
import UserCourses from "../Components/consultlotProfile/UserCourses";
import UserLocations from "../Components/consultlotProfile/UserLocations";
import "../css/consultantProfile.css";

const weburl = process.env.REACT_APP_BACKEND_URL;
const ConsultantProfile = (props) => {
  const [userData, setUserData] = useState({});
  const [isfollowing, setFollowing] = useState({
    isFollow: false,
    isEligiblefor24: false,
    isNotifyMeAll: false,
  });
  const [subGroups, setSubGroups] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [isCourse, setIsCourse] = useState(false);
  const [isPrivateGroups, setPrivateGroups] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [isStories, setIsStoties] = useState(false);
  const [isExperts, setIsExperts] = useState(false);
  const [briefIndex, setBriefIndex] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [subStories, setSubStories] = useState({ isPurchased: true });
  const [subStoryPage, setSubStoryPage] = useState(2);
  const [selctedCourseHighlight, setSeletedCourseHighlight] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const [languageID, setLanguageID] = useState(null);
  const [menuType, setMenuType] = useState("HOME");
  const [stafType, setStafType] = useState("");
  const languageRefselecter = useRef(null);
  const {
    ChannelHandleReducers: {
      IsCourseListCloseOFOtherProfile,
      IsShowProfileOfOtherProfile,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch({});

  const setListCourse = (iSclose) => {
    dispatch({ type: "IsCourseListCloseOtherProfile", payload: iSclose });
  };

  const setIsShowProfile = (isClose) => {
    dispatch({ type: "IsShowProfileOfOtherProfile", payload: isClose });
  };

  const followbtnRef = useRef(null);
  let query = useQuery();

  const history = useHistory();
  const url = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
    loadConsultantUserDetails();

    let visitLangs = [];
    if (props.user.isLogin) {
      getUserFollowedDetails();
    }
  }, [props.user.isLogin]);

  useEffect(() => {
    loadConsultantSubGroups();
  }, []);

  useEffect(() => {
    const getProfileUser = async () => {
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getProfilesOfUser?user_id=${query.get("user_id")}`;

      const res = await getRequestedDataWithHeader(url, userData.token);

      setProfiles(res.data.body.profiles);
      setIsCourse(res.data.body.courses);
      setIsLocation(res.data.body.location);
      setIsStoties(res.data.body.stories);
      setIsExperts(res.data.body.expertCV);
      setPrivateGroups(res.data.body.privateGroups);

      if (query.get("profile_id")) {
        let profiletarget = {};
        res.data.body.profiles.forEach((profile, i) => {
          if (profile._id === query.get("profile_id")) {
            profiletarget = {
              language_id: profile.language_id._id,
              profileName: profile.profile_name,
              index: i,
            };
          }
        });
        setLanguageID(profiletarget.language_id);
        setProfileName(res.data.body.profiles[0].user_id.user_name);
        setBriefIndex(profiletarget.index);

        if (query.get("fm") === "course")
          handleCourses(2, "COURSES", res.data.body.profiles);
      }
    };
    getProfileUser();
  }, []);

  const getScriptionStories = async (subscription_id) => {
    if (props.user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/isCoursePurchased`;
      const data = {
        user_id: props.user.userData.loginUser._id,
        subscription_id: subscription_id,
      };
      try {
        let res = await getDataPostWithHeader(
          url,
          data,
          props.user.userData.token,
        );

        if (res.data.body) {
          history.push(`/session/${subscription_id}`);
        } else {
          history.push(`course/name/${subscription_id}`);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err.message);
        toast.error(err.message);
      }
    } else {
      setIsLoading(false);
      alert("Login to check the subscription group post");
    }
  };

  const loadConsultantUserDetails = async () => {
    if (query.get("user_id")) {
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getPersonProfile?person_user_id=${query.get("user_id")}`;
      try {
        let res = await getRequestedData(url);
        setUserData(res.data.body);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const loadConsultantSubGroups = async () => {
    const url = `${
      process.env.REACT_APP_BACKEND_URL
    }/api/explore/userSubscriptionGroups?user_id=${query.get("user_id")}`;

    if (query.get("user_id")) {
      try {
        let res = await getRequestedData(url);

        setSubGroups(res.data.body);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const getUserFollowedDetails = async () => {
    if (query.get("user_id")) {
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/api/explore/getUserIsFollwedInfo?user_id=${
        props.user.userData.loginUser._id
      }&person_user_id=${query.get("user_id")}`;

      try {
        let res = await getRequestedDataWithHeader(
          url,
          props.user.userData.token,
        );

        setFollowing(res.data.body);
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const userUnSubscribeHandler = async () => {
    if (query.get("user_id")) {
      if (props.user.isLogin) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/unSubscribe`;
        const data = {
          user_id: props.user.userData.loginUser._id,
          following_user_id: query.get("user_id"),
        };
        try {
          let res = await getDataPostWithHeader(
            url,
            data,
            props.user.userData.token,
          );
          setFollowing({
            isFollow: false,
            isEligiblefor24: res.data.body.isEligiblefor24,
            isNotifyMeAll: false,
          });
          //toast.success(res.data.body.unsubscribeText);
        } catch (err) {
          setIsLoading(false);
          console.log(err.message);
          alert(err.message);
        }
      } else {
        alert("Login to UnSubscribe");
      }
    }
  };

  const userUnDoSubscribeHandler = async () => {
    if (query.get("user_id")) {
      if (props.user.isLogin) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/unDoUnSubscribe24`;
        const data = {
          user_id: props.user.userData.loginUser._id,
          undo_user_id: query.get("user_id"),
        };
        try {
          let res = await getDataPostWithHeader(
            url,
            data,
            props.user.userData.token,
          );

          console.log(res);
          setFollowing({
            isFollow: true,
          });
          // toast.success(res.data.body.unsubscribeText)
        } catch (err) {
          setIsLoading(false);
          console.log(err.message);
          alert(err.message);
        }
      } else {
        alert("Login to UnSubscribe");
      }
    }
  };

  const userSubscribeHandler = async () => {
    if (query.get("user_id")) {
      if (props.user.isLogin) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: props.user.userData.loginUser._id,
          follower_user_id: query.get("user_id"),
        };
        try {
          let res = await getDataPostWithHeader(
            url,
            data,
            props.user.userData.token,
          );
          setFollowing({ isFollow: true, isNotifyMeAll: false });
        } catch (err) {
          setIsLoading(false);
          console.log(err.message);
          toast.error(err.message);
        }
      } else {
        alert("Login to Subscribe");
      }
    }
  };

  const handleLanguageDropDown = (i, language_id) => {
    setLanguageID(language_id);
    setBriefIndex(i);

    // if (profiles.length > 1) {
    //   document.querySelectorAll(".profile-bio-tab-sub").forEach((div) => {
    //     div.style.borderBottom = "none";
    //     div.style.color = "#5e5e5e";
    //   });

    //   document.getElementById(`lang_list_profile_${i}`).style.borderBottom =
    //     "solid 2px #4d4341";
    //   document.getElementById(`lang_list_profile_${i}`).style.color = "black";
    // }
  };

  const handleLanguage = (i, profile) => {
    setLanguageID(profile.language_id._id);
    //setProfileName(profile.profile_name)
    setBriefIndex(i);
    if (languageRefselecter.current)
      languageRefselecter.current.value = profile._id;

    // if (profiles.length > 1) {
    //   document.querySelectorAll(".profile-bio-tab-sub").forEach((div) => {
    //     div.style.borderBottom = "none";
    //     div.style.color = "#5e5e5e";
    //   });

    //   document.getElementById(`lang_list_profile_${i}`).style.borderBottom =
    //     "solid 2px #0c9fd3";
    //   document.getElementById(`lang_list_profile_${i}`).style.color = "black";
    // }
  };

  const handleCourses = (i, menutype, list, stafType) => {
    setMenuType(menutype);

    if (stafType) {
      setStafType(stafType);
    }
    document.querySelectorAll(".profile-bio-tab-2").forEach((div) => {
      div.style.borderBottom = "none";
      div.style.color = "#5e5e5e";
    });

    if (menutype === "STORIES") {
      console.log(list[briefIndex]);
      handleLanguage(briefIndex, list[briefIndex]);
    }

    document.getElementById(
      `lang_list_profile_parent_tab_${i}`,
    ).style.borderBottom = "solid 4px #0c9fd3";

    document.getElementById(`lang_list_profile_parent_tab_${i}`).style.color =
      "black";
  };

  const toChat = () => {
    if (props.user.isLogin) {
      history.push(
        `/chat?user_id=${query.get("user_id")}&profile_id=${query.get(
          "profile_id",
        )}`,
      );
    } else {
      alert("login to navigate to chat");
    }
  };

  const notificationHandler = () => {
    //
  };

  return (
    <div className="profile-section">
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="row">
          <div className="col-md-12 profile-area" style={{ marginTop: "60px" }}>
            {userData.user && (
              <>
                <div id="profile_area">
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className="ups-bg"
                  >
                    <img
                      src={userData.user.cover}
                      style={{
                        width: "100%",
                        aspectRatio: "6/1",
                      }}
                      className="ups-bg-img"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = profilecoverDefualt;
                      }}
                    />

                    <div className="ups-profile">
                      <div className="ups-profile-area">
                        <img
                          className="ups-profile-img"
                          id="profile_pic_medium"
                          src={userData.user.imageUrl}
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = profileDefualt;
                          }}
                          alt="profile"
                        />
                      </div>
                      <div className="ups-profile-text">
                        <div className="ups-name">
                          {userData.user.user_name}
                        </div>
                        <div className="ups-subscribe-section">
                          <div className="ups-subscribe-area">
                            {isfollowing.isFollow && (
                              <div
                                className="btn-subscribe2"
                                onClick={userUnSubscribeHandler}
                              >
                                Subscribed
                              </div>
                            )}
                            {!isfollowing.isFollow &&
                              !isfollowing.isEligiblefor24 && (
                                <div
                                  className="btn-subscribe2"
                                  onClick={userSubscribeHandler}
                                >
                                  Subscribe
                                </div>
                              )}
                            {!isfollowing.isFollow &&
                              isfollowing.isEligiblefor24 && (
                                <div
                                  className="btn-subscribe2"
                                  onClick={userUnDoSubscribeHandler}
                                >
                                  Undo Unsubscribe
                                </div>
                              )}

                            {isfollowing.isFollow &&
                              !isfollowing.isNotifyMeAll && (
                                <div
                                  className="ml-3"
                                  onClick={() => notificationHandler(true)}
                                >
                                  <TbBellFilled size={20} />
                                </div>
                              )}

                            {isfollowing.isFollow &&
                              isfollowing.isNotifyMeAll && (
                                <div className="ml-3">
                                  <TbBellRingingFilled
                                    size={20}
                                    onClick={() => notificationHandler(false)}
                                  />
                                </div>
                              )}
                            <span className="ml-3 mb-1">
                              {profiles.length >= 1 &&
                                profiles[0].user_id.is_consultant === "1" && (
                                  <div onClick={toChat}>
                                    <BsChatLeftText
                                      size={20}
                                      style={{ color: "black" }}
                                    />
                                  </div>
                                )}
                            </span>
                            <span className="ml-3 mb-1">
                              <PiShareFat
                                className="ups-share-icon"
                                size={20}
                              />
                            </span>
                          </div>
                        </div>
                      </div>

                      {profiles.length > 1 && (
                        <div className="ups-language-section">
                          <div className="ups-language-selection-btn active">
                            <select
                              onChange={(e) =>
                                handleLanguageDropDown(
                                  e.target.selectedIndex,
                                  e.target.id,
                                )
                              }
                              style={{
                                backgroundColor: "white",
                                border: "none",
                              }}
                              ref={languageRefselecter}
                            >
                              {profiles.length > 1 &&
                                profiles.map((list, i) => {
                                  if (list._id === query.get("profile_id")) {
                                    return (
                                      <option
                                        value={list._id}
                                        id={list.language_id._id}
                                        selected
                                        key={list._id}
                                      >
                                        {list.language_id.nativeName}
                                      </option>
                                    );
                                  } else {
                                    return (
                                      <option value={list._id} key={list._id}>
                                        {list.language_id.nativeName}
                                      </option>
                                    );
                                  }
                                })}
                            </select>
                          </div>
                          <div className="ups-language-area">
                            {/* {profiles.length > 1 &&
                              profiles.map((list, i) => {
                                var id = i;
                                if (list._id === query.get("profile_id")) {
                                  return (
                                    <div
                                      key={i}
                                      id={`lang_list_profile_${i}`}
                                      className="profile-bio-tab-sub ml-2"
                                      style={{
                                        borderBottom: "solid 2px #4d4341",
                                        color: "black",
                                        padding: "5px",
                                      }}
                                      onClick={() => handleLanguage(i, list)}
                                    >
                                      {list.language_id.nativeName}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      key={i}
                                      id={`lang_list_profile_${i}`}
                                      className="profile-bio-tab-sub ml-2 language-select-border"
                                      style={{
                                        borderBottom: "none",
                                        padding: "5px",
                                      }}
                                      onClick={() => handleLanguage(i, list)}
                                    >
                                      {list.language_id.nativeName}
                                    </div>
                                  );
                                }
                              })} */}

                            <select
                              defaultValue={query.get("profile_id")}
                              onChange={(e) => {
                                handleLanguageDropDown(
                                  e.target.selectedIndex,
                                  e.target.id,
                                );
                              }}
                              style={{ padding: "5px" }}
                            >
                              {profiles.length > 1 &&
                                profiles.map((list) => {
                                  return (
                                    <option
                                      value={list._id}
                                      id={list.language_id._id}
                                    >
                                      {list.language_id.nativeName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div style={{ clear: "both" }}></div>
                </div>
              </>
            )}
          </div>
          <div className="col-md-12">
            <div>
              <div
                className="border-bottom-white2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="profilemenu-scroll">
                  <div
                    id={`lang_list_profile_parent_tab_${1}`}
                    className={`${"language-menu-select"} profile-bio-tab-2 ml-2 cursor-pointer profilemenulink`}
                    style={{
                      color: "black",
                      borderBottom: "solid 4px #0c9fd3",
                    }}
                    onClick={() => {
                      handleCourses(1, "HOME", profiles);
                    }}
                  >
                    HOME{" "}
                    {/* {menuType === "STORIES" && profiles.length > 1 && (
                    <span>:-</span>
                  )} */}
                  </div>
                  {isStories && (
                    <div
                      id={`lang_list_profile_parent_tab_${2}`}
                      className="profile-bio-tab-2 ml-2 cursor-pointer profilemenulink"
                      onClick={() => {
                        handleCourses(2, "STORIES", profiles, "STORIES");
                      }}
                    >
                      STORIES{" "}
                      {/* {menuType === "STORIES" && profiles.length > 1 && (
                    <span>:-</span>
                  )} */}
                    </div>
                  )}

                  {isExperts && (
                    <div
                      id={`lang_list_profile_parent_tab_${3}`}
                      className="profile-bio-tab-2 ml-2 cursor-pointer profilemenulink"
                      style={{
                        borderBottom: "none",
                      }}
                      onClick={() => {
                        handleCourses(3, "STAFFS", profiles, "CV");
                      }}
                    >
                      Expert CV
                    </div>
                  )}

                  {isLocation && (
                    <div
                      id={`lang_list_profile_parent_tab_${4}`}
                      className="profile-bio-tab-2 ml-2 cursor-pointer profilemenulink"
                      style={{
                        borderBottom: "none",
                      }}
                      onClick={() => {
                        handleCourses(4, "LOCATIONS", profiles);
                      }}
                    >
                      CONTACT
                    </div>
                  )}

                  {isPrivateGroups && (
                    <div
                      id={`lang_list_profile_parent_tab_5`}
                      className="profile-bio-tab-2 ml-2 cursor-pointer profilemenulink"
                      style={{
                        borderBottom: "none",
                      }}
                      onClick={() => {
                        handleCourses(5, "PGROUPS", profiles);
                      }}
                    >
                      PRIVATE GROUPS
                    </div>
                  )}
                  {isCourse && (
                    <div
                      id={`lang_list_profile_parent_tab_6`}
                      className="profile-bio-tab-2 ml-2 cursor-pointer profilemenulink"
                      style={{
                        borderBottom: "none",
                      }}
                      onClick={() => {
                        handleCourses(6, "COURSES", profiles);
                      }}
                    >
                      TRAINING COURSES
                    </div>
                  )}

                  <div className="clearfix"></div>
                </div>

                {/* <div className="mr-5">
                  {profiles.length > 1 &&
                    profiles.map((list, i) => {
                      var id = i;
                      if (list._id === query.get("profile_id")) {
                        return (
                          <div
                            key={i}
                            id={`lang_list_profile_${i}`}
                            className="profile-bio-tab-sub ml-2"
                            style={{
                              borderBottom: "solid 2px #4d4341",
                              color: "black",
                            }}
                            onClick={() => handleLanguage(i, list)}
                          >
                            {list.language_id.nativeName}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            id={`lang_list_profile_${i}`}
                            className="profile-bio-tab-sub ml-2 language-select-border"
                            style={{
                              borderBottom: "none",
                            }}
                            onClick={() => handleLanguage(i, list)}
                          >
                            {list.language_id.nativeName}
                          </div>
                        );
                      }
                    })}
                </div> */}
              </div>
            </div>

            {userData.user && menuType === "HOME" && (
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-12 mt-1">
                  {profiles.length > 0 && (
                    <BioArea
                      briefCV={profiles}
                      briefIndex={briefIndex}
                      userId={query.get("user_id")}
                      token={
                        props.user.userData && `${props.user.userData.token}`
                      }
                      selctedCourseHighlight={selctedCourseHighlight}
                      setSeletedCourseHighlight={setSeletedCourseHighlight}
                      setListCourse={setListCourse}
                      getScriptionStories={getScriptionStories}
                      islistCourseClose={IsCourseListCloseOFOtherProfile}
                    />
                  )}
                </div>
              </div>
            )}

            {userData.user &&
              (menuType === "STORIES" || menuType === "STAFFS") && (
                <UserStories
                  user={userData.user}
                  language_id={languageID}
                  menuType={menuType}
                  stafType={stafType}
                />
              )}
            {userData.user &&
              (menuType === "COURSES" || menuType === "PGROUPS") && (
                <UserCourses
                  user={userData.user}
                  language_id={languageID}
                  menuType={menuType}
                />
              )}

            {userData.user && menuType === "LOCATIONS" && (
              <UserLocations user_id={userData.user._id} />
            )}
          </div>

          {/* {userData.user && <ConsultlotStories userData={userData} />} */}
        </div>
      </div>
    </div>
  );
};

export default ConsultantProfile;
