import { useEffect, useState } from "react";

const useTimer = () => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let myInterval;

  // Utility function to add leading zero
  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  useEffect(() => {
    myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, minutes]);

  const startTimer = (min, sec) => {
    setMinutes(min);
    setSeconds(sec);
  };

  const stopTimer = () => {
    clearInterval(myInterval);
  };

  // Format minutes and seconds with leading zeros before returning
  return [formatTime(minutes), formatTime(seconds), stopTimer, startTimer];
};

export default useTimer;
