import React, { useEffect, useState } from "react";

import Navbar from "../../navbar/Navbar";
import ReelNavbar from "../../navbar/ReelNavbar";
import ConsultantNavbar from "../../navbar/ConsultantNavbar";

import Home from "../../pages/Home";
import CV from "../../pages/CV";
import ToLogin from "../../pages/ToLogin";
import Articles from "../../pages/Articles";
import ViewStory from "../../pages/ViewStory";
import ViewStaffStory from "../../pages/ViewStaffStory";
import ViewArticleStory from "../../pages/ViewArticleStory";
import Rpt from "../../pages/Rpt";
import SignUp from "../../pages/SignUp";
import ConsultantProfile from "../../pages/ConsultantProfile";
import CourseIntro from "../course/CourseIntro";
import Sessions from "../../pages/Sessions";
import HomeCourses from "../../pages/HomeCourses";
import ForgotPassword from "../../pages/ForgotPassword";
import PasswordLinkChange from "../../pages/PasswordLinkChange";
import PaymentValid from "../../pages/PaymentValid";
import HomeNavbarVerticle from "../../navbar/HomeNavbarVerticle";
import { useDispatch, useSelector } from "react-redux";

const ConsultlotCommon = ({
  props: { target, homeNavbarcollapse, setHomeNavbarcollapse },
  socket,
}) => {
  const [user, setUser] = useState({ isLogin: false });
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const dispatch = useDispatch({});

  const { ROOMCHAT } = useSelector((state) => state);

  useEffect(() => {
    const userValidate = async () => {
      if (typeof Storage !== "undefined") {
        if (localStorage.getItem("userDataSessionWay")) {
          setUser({
            isLogin: true,
            userData: JSON.parse(localStorage.getItem("userDataSessionWay")),
          });

          dispatch({
            type: "userlanguages",
            payload: JSON.parse(localStorage.getItem("userDataSessionWay"))
              .loginUser.userLangauges,
          });
          dispatch({
            type: "userCategories",
            payload: JSON.parse(localStorage.getItem("userDataSessionWay"))
              .loginUser.userCategory,
          });
        } else {
          setUser({ isLogin: false });
        }
      } else {
        alert("Please update your browser");
      }
    };
    userValidate();
  }, [localStorage.getItem("userDataSessionWay")]);

  useEffect(() => {
    console.log("userlogin", user.isLogin);
    if (user.isLogin) {
      console.log("hello iam from sessionway comon");
      window.addEventListener("beforeunload", function (e) {
        socket.emit("leaveRoom", { roomOne: ROOMCHAT.roomOne });
        socket.emit("userOffline", {
          userId: user.userData.loginUser._id,
        });
      });
    }
  }, [user]);

  useEffect(() => {
    if (target === "home") {
      setHomeNavbarcollapse(false);
      if (window.outerWidth <= 428) {
        setHomeNavbarcollapse(true);
      }
    }
  }, [target]);

  useEffect(() => {
    console.log("userlogin", user.isLogin);
    if (user.isLogin) {
      socket.on("isLogin", function () {
        console.log("yea ! user is connected");
      });

      //to join users
      socket.emit(
        "join",
        { userId: user.userData.loginUser._id, deviceType: "web" },
        function (response) {
          console.log(response);
        },
      );
    }
  }, [user]);
  return (
    <>
      {target !== "consultantProfile" && target !== "story" && (
        <>
          <div
            className={`${target === "home" && "navbar-home-normal"} ${
              (target === "stafstory" ||
                target === "article" ||
                target === "scienceresearch") &&
              "d-md-block d-none d-sm-block"
            }`}
          >
            <Navbar
              isLogin={user.isLogin}
              profileImage={
                user.userData &&
                user.userData.loginUser &&
                user.userData.loginUser.imageUrl
              }
              token={user.userData && user.userData.token}
              userId={user.userData && user.userData.loginUser._id}
              homeNavbarcollapse={homeNavbarcollapse}
              setHomeNavbarcollapse={setHomeNavbarcollapse}
              isSearchBar={true}
              target={target}
              setLoadingSearchUser={setLoadingSearchUser}
            />
          </div>
          {/* {target === "home" && (
            <div className={`${target === "home" && "navbar-home-reel"}`}>
              <ReelNavbar
                isLogin={user.isLogin}
                profileImage={
                  user.userData &&
                  user.userData.loginUser &&
                  user.userData.loginUser.imageUrl
                }
                token={user.userData && user.userData.token}
                userId={user.userData && user.userData.loginUser._id}
                homeNavbarcollapse={homeNavbarcollapse}
                setHomeNavbarcollapse={setHomeNavbarcollapse}
                isSearchBar={true}
                target={target}
                setLoadingSearchUser={setLoadingSearchUser}
              />
            </div>
          )} */}
        </>
      )}

      {target === "consultantProfile" && (
        //<ConsultantNavbar isLogin={user.isLogin} user={user} />
        <Navbar
          isLogin={user.isLogin}
          profileImage={
            user.userData &&
            user.userData.loginUser &&
            user.userData.loginUser.imageUrl
          }
          token={user.userData && user.userData.token}
          userId={user.userData && user.userData.loginUser._id}
          homeNavbarcollapse={homeNavbarcollapse}
          setHomeNavbarcollapse={setHomeNavbarcollapse}
          isSearchBar={false}
          className="d-md-block d-none d-sm-block"
          target={target}
        />
      )}

      {target === "forgotpassword" && <ForgotPassword />}

      {target === "emailpasswordchange" && <PasswordLinkChange />}

      {target !== "forgotpassword" && target !== "emailpasswordchange" && (
        <div style={{ display: "flex" }}>
          <div
            className={`d-md-block d-none d-sm-block`}
            style={{ marginTop: "60px" }}
          >
            <HomeNavbarVerticle
              opionSelectedKey={`${target === "home" ? "1" : ""}`}
              user={user}
              homeNavbarcollapse={homeNavbarcollapse}
            />
          </div>

          <div
            className={`${
              homeNavbarcollapse ? "ant-menu-vertical-container" : ""
            } w-100`}
          >
            {target === "tologin" && <ToLogin />}

            {target === "home" && (
              <Home
                user={user}
                loadingSearchUser={loadingSearchUser}
                homeNavbarcollapse={homeNavbarcollapse}
                setHomeNavbarcollapse={setHomeNavbarcollapse}
                target={target}
              />
            )}

            {target === "cv" && (
              <CV user={user} loadingSearchUser={loadingSearchUser} />
            )}

            {target === "articles" && (
              <Articles
                user={user}
                loadingSearchUser={loadingSearchUser}
                type={target}
              />
            )}

            {target === "science-research" && (
              <Articles
                user={user}
                loadingSearchUser={loadingSearchUser}
                type={target}
              />
            )}
            {target === "story" && (
              <ViewStory
                user={user}
                homeNavbarcollapse={homeNavbarcollapse}
                setHomeNavbarcollapse={setHomeNavbarcollapse}
                target={target}
              />
            )}
            {target === "stafstory" && <ViewStaffStory user={user} />}

            {target === "article" && (
              <ViewArticleStory user={user} type={target} />
            )}
            {target === "scienceresearch" && (
              <ViewArticleStory user={user} type={target} />
            )}
            {target === "register" && <SignUp />}
            {target === "courseIntro" && <CourseIntro user={user} />}
            {target === "rpt" && <Rpt />}
            {target === "courses" && <HomeCourses user={user} courseType={1} />}
            {target === "private-groups" && (
              <HomeCourses user={user} courseType={0} />
            )}
            {target === "consultantProfile" && (
              <ConsultantProfile user={user} />
            )}
            {target === "checkoutorderid" && <PaymentValid />}
          </div>
        </div>
      )}
    </>
  );
};

export default ConsultlotCommon;
