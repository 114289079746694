import "@ungap/global-this"; // Import the polyfill
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

//css

import "./css/Global/global.css";
import "antd/dist/antd.css";

//reducer import
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";

//reducer components

//navbar reducers
import NavbarReducer from "./Reducers/NavbarReducers";

//regestration reducers
import RegFirstSectionReducers from "./Reducers/RegFirstSectionReducers";
import RegSecondSectionReducers from "./Reducers/RegSecondSectionReducers";
import RegThirdSectionReducers from "./Reducers/RegThirdSectionReducers";
import RegFourthSectionReducers from "./Reducers/RegFourthSectionReducers";
import RegFifthSectionReducers from "./Reducers/RegFifthSectionReducers";

//user profile Reducers
import BasicUserReducers from "./Reducers/UserReducers/BasicUserReducers";

//subscription group reducers
import UserSubscriptionGroup from "./Reducers/UserReducers/SubscriptionReducers/UserSubscriptionGroupReducers";
import StoriesReducer from "./Reducers/UserReducers/SubscriptionReducers/SubscriptionGroupInfoReducers";
import UserStoriesReducers from "./Reducers/UserReducers/SubscriptionReducers/UserStoriesReducers";
//refreshReducers
import RefreshReducers from "./Reducers/RefreshReducers";

//import currency
import UserRequiredDropDown from "./Reducers/UserReducers/CurrencyReducer";

import AllStories from "./Reducers/stories/StoriesReducers";
import CatStories from "./Reducers/stories/CategoryStories";

import SubscriptionGroups from "./Reducers/UserReducers/SubscriptionReducers/SubscriptionGroups";

import searchUserReducers from "./Reducers/UserReducers/searchUserReducers";

import FollowersReducers from "./Reducers/UserReducers/FollowersReducers";
import FileUploadReducers from "./Reducers/fileUploadReducers/FileUploadReducers";
import StoryImageCoverURL from "./Reducers/fileUploadReducers/StoryImageCoverURL";
import ProfileReducers from "./Reducers/UserReducers/ProfileReducers";
import ChannelHandleReducers from "./Reducers/UserReducers/ChannelHandleReducers";
import AllStoriesMin from "./Reducers/stories/AllStoriesReducers";
import SubscribedALLUserStoriesReducers from "./Reducers/stories/SubscribedALLUserStoriesReducers";
import SubscribedAUserStoriesReducers from "./Reducers/stories/SubscribedAUserStoriesReducers";
import BasicHomePageReducers from "./Reducers/BasicHomePageReducers";
import videoProcessingReducers from "./Reducers/fileUploadReducers/videoProcessingReducers";
import videoUploadingPercentageReducers from "./Reducers/fileUploadReducers/videoUploadingPercentageReducers";
import CartReducer from "./Reducers/CartReducer";
import WishlistReducer from "./Reducers/WishlistReducer";
import checkoutLists from "./Reducers/checkoutLists";
import ROOMCHAT from "./Reducers/ChatReducers";

import ReactGA from "react-ga4";

ReactGA.initialize("G-YBQ3SWPZEP");

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

console.log(window.location.pathname, "window.location.pathname");

//reducer Store
const store = createStore(
  combineReducers({
    NavbarLogin: NavbarReducer,
    RegFirstSection: RegFirstSectionReducers,
    RegSecondSection: RegSecondSectionReducers,
    RegThirdSection: RegThirdSectionReducers,
    RegFourthSection: RegFourthSectionReducers,
    RegFifthSection: RegFifthSectionReducers,
    BasicUserReducers: BasicUserReducers,
    UserSubscriptionGroup: UserSubscriptionGroup,
    StoriesReducer: StoriesReducer,
    RefreshReducers: RefreshReducers,
    UserRequiredDropDown: UserRequiredDropDown,
    AllStories: AllStories,
    CatStories: CatStories,
    searchUsers: searchUserReducers,
    SubscriptionGroups: SubscriptionGroups,
    Followers: FollowersReducers,
    FileUploadPercentage: FileUploadReducers,
    StoryImageCoverURL: StoryImageCoverURL,
    ProfileReducers: ProfileReducers,
    UserStoriesReducers: UserStoriesReducers,
    ChannelHandleReducers: ChannelHandleReducers,
    AllStoriesMinReducers: AllStoriesMin,
    SubscribedALLUserStories: SubscribedALLUserStoriesReducers,
    SubscribedUserStories: SubscribedAUserStoriesReducers,
    BasicHomePageReducer: BasicHomePageReducers,
    videoProcessingReducer: videoProcessingReducers,
    videoUploadingPercentageReducers: videoUploadingPercentageReducers,
    cart: CartReducer,
    Wishlist: WishlistReducer,
    ROOMCHAT: ROOMCHAT,
    CheckoutLists: checkoutLists,
  }),
  composeWithDevTools(),
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
