import React, { useState, useEffect, useRef } from "react";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { IoAddCircle } from "react-icons/io5";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import {
  getRequestedData,
  editPersonalDetails,
} from "../../../functions/editProfile/editProfile";
import remove from "../../../images/remove.png";
import { responseMessageDispaly } from "../../../utils/sharedfunction";

const EditLangaugeSection = (props) => {
  const [languages, setLanguages] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const uploadMSG = useRef(null);
  const list = useRef(null);
  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getLanguages`;
    getRequestedData(url)
      .then((res) => {
        setLanguages(res.data.body);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, []);

  const handleRemove = async (value) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/deleteUserLanguage`;
    const data = {
      userLangauges: value,
      user_id: props.user._id,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      props.dispatch({
        type: "updateLanguage",
        payload: res.data.body.userLangauges,
      });

      props.dispatch({
        type: "userlanguages",
        payload: res.data.body.userLangauges,
      });
      setLoading(false);
      responseMessageDispaly(
        uploadMSG,
        "Selected language removed successfully",
        "red",
      );
      toast.success("Selected language removed successfully");
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      responseMessageDispaly(uploadMSG, err.message, "red");
      console.log(err.message);
    }
  };

  const handleLanguage = async (value, name) => {
    setLoading(true);
    var children = list.current.children;
    let flag = 0;

    if (value !== "0") {
      for (var i = 0; i < children.length; i++) {
        var child = children[i];
        var val = child.innerHTML.split("<")[0].trim();

        if (val === name.trim()) {
          flag = 0;
          break;
        } else {
          flag = 1;
        }
      }

      if (flag === 1) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserLanguage`;
        const data = {
          userLangauges: value,
          user_id: props.user._id,
        };
        try {
          let res = await editPersonalDetails(url, data, props.token);
          setLoading(false);
          console.log(res.data.body);

          props.dispatch({
            type: "updateLanguage",
            payload: res.data.body.userLangauges,
          });
          props.dispatch({
            type: "userlanguages",
            payload: res.data.body.userLangauges,
          });

          // responseMessageDispaly(uploadMSG, 'Language has updated', 'green')
          props.setUserProps({ user: res.data.body, token: props.token });

          toast.success("Language has updated");
        } catch (err) {
          setLoading(false);
          responseMessageDispaly(uploadMSG, "Some thing went Wrong", "red");
          list.current.removeChild(list.current.lastChild);
          console.log(err.message);
          toast.error(err.message);
        }
      }
    }
  };
  return (
    <>
      <div className="edit-content-widget">
        <div className="ui-tabs-title">
          <h4 className="float-left">Language Known</h4>
          {loading && (
            <>
              <Spin className="ml-3" />
              <span className="text-danger">....</span>
            </>
          )}
          {showEdit || (
            <GoPencil
              className="float-right mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEdit(true);
              }}
            />
          )}
          {showEdit && (
            <AiOutlineClose
              className="float-right mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEdit(false);
              }}
            />
          )}
          <div style={{ clear: "both" }}></div>
        </div>

        {showEdit || (
          <div className="tab-inner-content-padding">
            {props.UserRequiredDropDown.languages.map((list, index) => {
              return <div key={index}>{list.name}</div>;
            })}
          </div>
        )}

        {showEdit && (
          <div className="tab-inner-content-padding">
            <div ref={list}>
              {props.UserRequiredDropDown.languages.length > 1
                ? props.UserRequiredDropDown.languages.map((list, index) => {
                    return (
                      <div key={index} id={`${list._id}`} className="item">
                        {list.name}
                        <img
                          src={remove}
                          alt="add"
                          className="float-right mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove(list._id)}
                        />
                      </div>
                    );
                  })
                : props.UserRequiredDropDown.languages.map((list, index) => {
                    return (
                      <div key={index} id={`${list._id}`} className="item">
                        {list.name}
                      </div>
                    );
                  })}
            </div>
            <div className="mt-3">
              {add || (
                <Link
                  to="#"
                  onClick={() => {
                    setAdd(true);
                  }}
                >
                  <IoAddCircle size="20px" />
                  <span
                    className="ml-1"
                    style={{ position: "relative", top: "3px" }}
                  >
                    Add Language
                  </span>
                </Link>
              )}
              {add && languages.length > 0 && (
                <div>
                  <h6>Add Language</h6>
                  <select
                    className="form-control registration-from-control-border"
                    onChange={(e) => {
                      handleLanguage(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text,
                      );
                    }}
                  >
                    <option value="0">Add Langauge</option>
                    {languages.map((lang) => {
                      return (
                        <option key={lang._id} value={lang._id}>
                          {lang.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="col-sm-12 p-2">
          <div ref={uploadMSG}></div>
        </div>
      </div>
    </>
  );
};
const MapStateToProps = ({ UserRequiredDropDown }) => {
  return { UserRequiredDropDown };
};

export default connect(MapStateToProps)(EditLangaugeSection);
