import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";

import { connect } from "react-redux";

import {
  dataURLtoBlob,
  videoPickerHandler,
  responseMessageDispaly,
  selectValueValue,
  selectIdValue,
  selectTextValue,
} from "../../utils/sharedfunction";
import { categgoryBasedLangauge } from "../../utils/apis/commonApis";

const url = process.env.REACT_APP_BACKEND_URL;

function EditSubGropDetails({
  group_info,
  UserRequiredDropDown,
  setEditDesc,
  dispatch,
}) {
  const bioTextRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const errorMessage = useRef(null);
  const groupNameEdit = useRef(null);
  const subPriceEditRef = useRef(null);
  const languageEditRef = useRef(null);
  const currancyEditRef = useRef(null);
  const categoryEditRef = useRef(null);
  const subDecription = useRef(null);

  useEffect(() => {
    categgoryBasedOnLangaugeHandler();
  }, []);

  const categgoryBasedOnLangaugeHandler = async (language) => {
    try {
      let categories = await categgoryBasedLangauge();

      if (categories) {
        let category = "";
        category += `<option id="00" value="0">Select Category</option>`;
        categories.forEach((list) => {
          category += `<option id=${list.language_cd} value="${list._id}">${list.category_name}</option>`;
        });
        categoryEditRef.current.innerHTML = category;
        categoryEditRef.current.value = group_info.category_id._id;
      }
    } catch (err) {
      console.log(err);
      responseMessageDispaly(
        errorMessage,
        "something went wrong loading category",
        "red",
      );
    }
  };

  const saveSubscriptionGroupHandler = async () => {
    setLoading(true);
    const language = selectValueValue(languageEditRef.current);
    const currency = selectValueValue(currancyEditRef.current);
    const groupName = groupNameEdit.current.value;
    const subgroupPrice = subPriceEditRef.current.value;
    const subgroupDecription = subDecription.current.value;
    const language_id = selectIdValue(languageEditRef.current);

    if (!groupName) {
      setLoading(false);
      responseMessageDispaly(errorMessage, "Please enter group name", "red");
      return;
    }

    if (language == "0") {
      setLoading(false);
      responseMessageDispaly(errorMessage, "Please select language", "red");
      return;
    }

    const category = selectValueValue(categoryEditRef.current);

    if (category == "0") {
      setLoading(false);
      responseMessageDispaly(errorMessage, "Please select category", "red");
      return;
    }

    if (!subgroupPrice) {
      setLoading(false);
      responseMessageDispaly(
        errorMessage,
        "Please enter subscription price",
        "red",
      );
      return;
    }

    if (currency == "0") {
      setLoading(false);
      responseMessageDispaly(errorMessage, "Please select currency", "red");
      return;
    }

    if (!subgroupDecription) {
      setLoading(false);
      responseMessageDispaly(errorMessage, "Please enter description", "red");
      return;
    }

    try {
      const response = await fetch(
        `${url}/api/userProfile/updateSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${group_info.token}`,
          },
          body: JSON.stringify({
            group_name: groupName,
            language_id: language_id,
            category_id: category,
            subscription_price: subgroupPrice,
            currency_id: currency,
            group_description: subgroupDecription,
            subscription_id: group_info._id,
          }),
        },
      );
      const responseData = await response.json();

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("userDataSessionway");
          window.location.replace("/");
        }
        setLoading(false);
        throw new Error(responseData.message || "something went wrong");
      } else {
        //responseMessageDispaly(errorMessage, 'Success', 'green')
        dispatch({
          type: "group_details",
          payload: {
            ...responseData.body,
            user_id: group_info.user_id._id,
            token: group_info.token,
          },
        });
        setEditDesc(false);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      responseMessageDispaly(errorMessage, "Something went wrong", "red");
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="edit_area_header ">
        {isLoading && (
          <div className="d-flex push-left">
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div
          ref={errorMessage}
          className="push-left"
          style={{ marginTop: 10 + "px" }}
        ></div>
        <div
          style={{
            float: "right",
            marginTop: 8 + "px",
            marginLeft: 20 + "px",
          }}
        >
          <AiOutlineClose
            onClick={() => {
              setEditDesc(false);
            }}
            className="cursor-pointer"
          />
        </div>

        <div className="push-right">
          <input
            type="button"
            className="save_button"
            onClick={saveSubscriptionGroupHandler}
            value="Save"
          />
        </div>
        <div className="clearfix"></div>
      </div>
      <div style={{ marginTop: 5 + "px" }}>
        <table id="session_group_edit">
          <tbody>
            <tr>
              <td className="font-bold padding-5">Group name</td>
              <td className="padding-5" id="group_name_view">
                <input
                  type="text"
                  ref={groupNameEdit}
                  style={{ width: "200px", lineHeight: "10px" }}
                  defaultValue={group_info.group_name}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="padding-5 font-bold">Language</td>
              <td className="padding-5" id="langauge_view">
                <select
                  id="langauge_edit"
                  style={{ width: "200px" }}
                  ref={languageEditRef}
                  defaultValue={group_info.language_id.code}
                  onChange={(e) => {
                    categgoryBasedOnLangaugeHandler(e.target.value);
                  }}
                >
                  <option value="0">Select Language</option>
                  {UserRequiredDropDown.languages.map((list, index) => {
                    return (
                      <option key={index} id={list._id} value={list.code}>
                        {list.nativeName}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td className="padding-5 font-bold">Category</td>
              <td className="padding-5" id="cat_view">
                <select
                  id="currancy_edit"
                  style={{ width: "200px" }}
                  ref={categoryEditRef}
                  defaultValue={group_info.category_id._id}
                ></select>
              </td>
            </tr>
            <tr>
              <td className="padding-5 font-bold">Price</td>
              <td className="padding-5" id="price_view">
                <input
                  type="number"
                  ref={subPriceEditRef}
                  style={{ width: "200px", lineHeight: "10px" }}
                  defaultValue={group_info.subscription_price}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="padding-5 font-bold">Currency</td>
              <td className="padding-5" id="currency_view">
                {console.log(group_info)}
                <select
                  id="currancy_edit"
                  style={{ width: "200px" }}
                  ref={currancyEditRef}
                  defaultValue={
                    group_info.currency_id ? group_info.currency_id._id : ""
                  }
                >
                  <option value="0">Select Currency</option>
                  {UserRequiredDropDown.currencies.map((list, index) => {
                    return (
                      <option
                        key={index}
                        id={list.currency_cd}
                        value={list._id}
                      >
                        {list.currency_name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ verticalAlign: "text-top" }}>
        <textarea
          className="for_edit_field"
          ref={subDecription}
          style={{ height: 400 + "px" }}
          name="message"
          placeholder="Description"
          rows="5"
        >
          {group_info.group_description}
        </textarea>
      </div>
    </div>
  );
}

const MapStateToProps = ({ UserRequiredDropDown }) => {
  return { UserRequiredDropDown };
};

export default connect(MapStateToProps)(EditSubGropDetails);
