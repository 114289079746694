import React from "react";
import { toast } from "react-toastify";

const CourseTitile = ({ title, setTitle }) => {
  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  return (
    <>
      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          padding: "5px",
        }}
      >
        <span style={{ padding: "5px", width: "140px" }}>Course Title :</span>
        <input
          type="text"
          name="title"
          style={{
            background: "white",
            padding: "5px",
            border: "none",
            borderBottom: "1px solid #909090",
            flexGrow: "1",
          }}
          className="input-box-course"
          placeholder="Enter the Course title"
          value={title}
          onChange={titleHandler}
        />
      </div>
    </>
  );
};

export default CourseTitile;
