import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Spin } from "antd";

import { BsCheckLg } from "react-icons/bs";

import {
  getRequestedData,
  editPersonalDetails,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../../functions/editProfile/editProfile";
import { responseMessageDispaly } from "../../utils/sharedfunction";

const ProfileSetting = (props) => {
  const uploadMSG = useRef(null);
  const [consultant, setConsultant] = useState(props.user.is_consultant);
  const [accounttype, setAccounttype] = useState(props.user.accounttype);
  const [showAddChatLanguage, setShowAddChatLanguage] = useState("");
  const [loading, setLoading] = useState(false);

  const { UserRequiredDropDown } = useSelector((state) => state);

  const { languages } = UserRequiredDropDown;

  const getChatingLanguages = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/getChattingLanguages`;
    try {
      let res = await getRequestedDataWithHeader(url, props.token);

      if (res.data.body.length > 0) {
        return res.data.body;
      } else {
        return [];
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const handleSubmit = async (consultant, accounttype) => {
    setLoading(true);

    const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/upateUserChannelSettings`;
    const data = {
      consultant: consultant,
      accounttype: accounttype,
      user_id: props.user._id,
    };
    try {
      let res = await editPersonalDetails(url, data, props.token);
      props.userDetails();
      setLoading(false);
      props.setProfileSetting(false);
      //responseMessageDispaly(uploadMSG, "Changes updated", "green");
    } catch (err) {
      console.log(err.message);
      responseMessageDispaly(uploadMSG, "Some thing went wrong", "red");
      setLoading(false);
      //toast.error(err.message);
    }
  };

  const handleConsultantSubmit = async (consultant) => {
    setLoading(true);

    try {
      const chatlanguage = await getChatingLanguages();

      if (chatlanguage.length > 0) {
        await constltantUpdate(consultant);
      } else {
        setShowAddChatLanguage(consultant);
      }
      setLoading(false);
      //responseMessageDispaly(uploadMSG, "Changes updated", "green");
    } catch (err) {
      console.log(err.message);
      responseMessageDispaly(uploadMSG, "Some thing went wrong", "red");
      setLoading(false);
      //toast.error(err.message);
    }
  };

  const constltantUpdate = async (consultant) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/updateProvideConsultant`;
      const data = {
        consultant: consultant,
        user_id: props.user._id,
      };
      let res = await editPersonalDetails(url, data, props.token);
      props.userDetails();
      props.setProfileSetting(false);
    } catch (err) {
      console.log(err.message);
      responseMessageDispaly(uploadMSG, "Some thing went wrong", "red");
      setLoading(false);
    }
  };

  const handleLanguage = async (value) => {
    console.log("handleLanguage", value);
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/updatechattinglanguage`;
    const data = {
      language_cd: value,
      user_id: props.user._id,
      defaultOne: true,
    };
    try {
      let res = await getDataPostWithHeader(url, data, props.token);
      await constltantUpdate(showAddChatLanguage);
      setLoading(false);
      setShowAddChatLanguage("");
    } catch (err) {
      setLoading(false);
      alert(err.message);
      console.log(err.message);
      //alert(err.message);
    }
  };

  return (
    <div className="ml-5">
      {loading && (
        <>
          <Spin className="ml-3" />
          <span className="text-danger">....</span>
        </>
      )}
      <div ref={uploadMSG} className="ml-3"></div>
      <div>
        <div>
          <input
            className="input-md registration-from-control-border mr-3"
            type="checkbox"
            checked={props.user.is_consultant == "1" ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                handleConsultantSubmit("1");
              } else {
                handleConsultantSubmit("0");
              }
            }}
          />
          <span>I Provide Consultantations</span>
          {showAddChatLanguage && (
            <div className="mb-2">
              {languages.length > 0 && (
                <div className="display-flex display-flex-align-center">
                  <select
                    className="form-control registration-from-control-border"
                    onChange={(e) => handleLanguage(e.target.value)}
                  >
                    <option value="0">Add language from your languages</option>
                    {languages.map((lang) => {
                      console.log(lang);
                      return (
                        <option key={lang._id} value={lang.code}>
                          {lang.nativeName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          )}
        </div>
        {/* <div>
          <div className="display-flex">
            <input
              type="radio"
              name="payment"
              value="indivdual"
              className="mr-3"
              checked={props.user.accounttype === "1" && true}
              onChange={() => handleSubmit(props.user.is_consultant, "1")}
            />
            <span for="indivdual" className="display-flex-align-center">
              {props.user.accounttype === "2" && "Switch to "}Indivdual account
            </span>
          </div>

          <div className="display-flex display-flex-align-center">
            <input
              type="radio"
              name="payment"
              value="Organization"
              className="mr-3"
              checked={props.user.accounttype === "2" && true}
              onChange={() => handleSubmit(props.user.is_consultant, "2")}
            />
            <span for="indivdual" className="display-flex-align-center">
              {props.user.accounttype === "1" && "Switch to "}Organization
              account
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProfileSetting;
