import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IoCaretDown } from "react-icons/io5";
import styles from "./css/home.module.css";
import { IoArrowBackSharp } from "react-icons/io5";
const DropDownMenu = ({ contentClassName }) => {
  const history = useHistory();
  const path = history.location.pathname.split("/")[1];
  const [selectedOption, setSelectedOption] = useState(
    path === "/" ? "" : path,
  );

  const [selectedValue, setSelectedValue] = useState(
    path === "/"
      ? ""
      : path === "science-reasearch"
      ? "Scientific Research"
      : path.charAt(0).toUpperCase() + path.slice(1),
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => setIsOpen(!isOpen);

  const handleOptionClick = (path, event) => {
    history.push(path);
    // setSelectedOption(path);
    // setIsOpen(false);
  };

  const capitalizeFirstLetter = (string) => {
    if (string === "science-reasearch") {
      return "Scientific Research";
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  // useEffect(() => {
  //   history.push(selectedOption);
  // }, [selectedOption, history]);

  const options = [
    { path: "/", label: "Home" },
    { path: "/experts", label: "Experts" },
    { path: "/articles", label: "Articles" },
    { path: "/science-reasearch", label: "Scientific Research" },
  ];

  const filteredOptions = options.filter(
    (option) => option.path !== `/${selectedOption}`,
  );

  return (
    <div className={styles.dropdown}>
      {console.log(selectedValue)}
      {capitalizeFirstLetter(selectedValue) === "" ||
      capitalizeFirstLetter(selectedValue) === "Experts" ||
      capitalizeFirstLetter(selectedValue) === "Articles" ||
      capitalizeFirstLetter(selectedValue) === "Scientific Research" ? (
        <div
          onClick={handleButtonClick}
          className={`${styles.dropdownButton} display-flex`}
          style={{ gap: "8px", alignItems: "center" }}
        >
          <div>{capitalizeFirstLetter(selectedValue) || "Home"}</div>
          <IoCaretDown size={16} style={{ marginTop: "4px" }} />
        </div>
      ) : (
        <div
          style={{ gap: "8px", alignItems: "center", marginLeft: "25px" }}
          className="display-flex"
        >
          <IoArrowBackSharp onClick={(e) => handleOptionClick("/")} size={25} />
        </div>
      )}

      {isOpen && (
        <div className={`${styles.dropdownContent} ${contentClassName}`}>
          {filteredOptions.map((option) => (
            <a
              key={option.path}
              onClick={(e) => handleOptionClick(option.path, e)}
            >
              {option.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;
