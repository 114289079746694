import React from "react";
import { Link } from "react-router-dom";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import profileDefualt from "../../images/default.jpeg";
import { BsPlayFill } from "react-icons/bs";

const CourseHomePurchaseCard = ({ course, courseType }) => {
  return (
    <Link to={`/session/${course._id}`}>
      <div className="home-courses-pr-card mt-3">
        <div className="home-courses-pr-card-img-wrapper">
          <div className="img-course-wrapper-relative">
            <img
              src={course.group_video_thumbnail_url}
              alt="video cover"
              className="home-img-course"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = defualtImg;
              }}
            />
            <div>
              <div className="play-icon-wrapper-course"></div>
              <div className="play-icon-sub-wrapper-course">
                <BsPlayFill size={30} className="play-icon-course" />
              </div>
            </div>
          </div>
          <div className="home-courses-title mt-3">
            {course.group_name.substring(0, 100)}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseHomePurchaseCard;
