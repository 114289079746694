const initialState = {
  stories: {
    getStoryModel: [],
  },
}

const CatStories = (state = initialState, action) => {
  switch (action.type) {
    case 'cat_stories':
      return { ...state, stories: action.payload }
    case 'cat_stories_append':
      let newgetStoryModel = [...state.stories.getStoryModel, ...action.payload]
      let newState = { ...state }
      newState.stories.getStoryModel = newgetStoryModel
      return newState
    default:
      return state
  }
}

export default CatStories
