import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//import css
import "../css/profile/profile.css";

import ViewUserSubscriptionGroup from "../Components/subscriptionGroup/ViewUserSubscriptionGroup";

import ProfileSection from "../Components/profile/ProfileSection";

import FooterMenu from "../navbar/FooterMenu";

const Profile = () => {
  const [profile, setProfile] = useState(true);
  const [menuType, setMenuType] = useState("HOME");

  const dispatch = useDispatch({});

  const {
    BasicUserReducers: { user },
    ChannelHandleReducers: { isCourseListClose },
  } = useSelector((state) => ({ ...state }));

  const setIsCourseListClose = (isClose) => {
    dispatch({ type: "isCourseListClose", payload: isClose });
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);

  return (
    <>
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="row">
          <div className="col-md-12">
            {!profile && (
              <div id="view-sub" className="d-md-block d-none d-sm-block">
                <ViewUserSubscriptionGroup
                  setIsCourseListClose={setIsCourseListClose}
                  setProfile={setProfile}
                />
              </div>
            )}
          </div>
          {profile && (
            <>
              <ProfileSection
                setProfile={setProfile}
                profile={profile}
                setIsCourseListClose={setIsCourseListClose}
                isCourseListClose={isCourseListClose}
                setMenuType={setMenuType}
                menuType={menuType}
              />
            </>
          )}

          {profile && (
            <div className="fixed-bottom d-block d-sm-none">
              <FooterMenu user={{ isLogin: true, userData: user }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
