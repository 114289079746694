import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ViewStoryDesktop from "../Components/ViewStory/ViewStoryDesktop";
import ViewStoryMobile from "../Components/ViewStory/ViewStoryMobile";
import ViewStoryReelsMobile from "../Components/ViewStory/ViewStoryReelsMobile";
import HomeSearchStories from "../Components/home/HomeSearchStories";
import FooterMenu from "../navbar/FooterMenu";
import Navbar from "../navbar/Navbar";

const ViewStory = ({
  user,
  homeNavbarcollapse,
  setHomeNavbarcollapse,
  target,
}) => {
  const params = useParams();
  const [isFromMobile, setIsFromMobile] = useState(true);
  const [searchStories, setSearchStories] = useState(false);

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  return (
    <>
      {!isFromMobile && params.id && (
        <>
          <Navbar
            isLogin={user.isLogin}
            profileImage={
              user.userData &&
              user.userData.loginUser &&
              user.userData.loginUser.imageUrl
            }
            token={user.userData && user.userData.token}
            userId={user.userData && user.userData.loginUser._id}
            homeNavbarcollapse={homeNavbarcollapse}
            setHomeNavbarcollapse={setHomeNavbarcollapse}
            isSearchBar={true}
          />
          <ViewStoryDesktop
            params_id={params.id}
            user={user}
            setHomeNavbarcollapse={setHomeNavbarcollapse}
          />
          {/* <ViewStoryMobile params_id={params.id} user={user} /> */}
        </>
      )}
      {isFromMobile && params.id && (
        <>
          {!searchStories && (
            <ViewStoryReelsMobile
              params_id={params.id}
              user={user}
              searchStories={searchStories}
              setSearchStories={setSearchStories}
              homeNavbarcollapse={homeNavbarcollapse}
              setHomeNavbarcollapse={setHomeNavbarcollapse}
              target={target}
            />
          )}

          {searchStories && (
            <HomeSearchStories
              user={user}
              setSearchStories={setSearchStories}
              userId={user.userData && user.userData.loginUser._id}
              categories={[]}
            />
          )}
          <div className="fixed-bottom d-block d-sm-none">
            <FooterMenu
              user={user}
              footwrapperclassname="footer-menu-reel-bottom"
            />
          </div>
          {/* <ViewStoryMobile params_id={params.id} user={user} /> */}
        </>
      )}
    </>
  );
};

export default ViewStory;
